import React from 'react';
import URLS from '../../constants/urls';
import Policy from './Policy';

function PrivacyPolicy() {
  return <Policy url={URLS.API_FETCH_PRIVACY_POLICY} />;
}
function TermsOfUsage() {
  return <Policy url={URLS.API_FETCH_TERMS_OF_USAGES} />;
}
function ReturnPolicy() {
  return <Policy url={URLS.API_FETCH_RETURN_POLICY} />;
}
function ShippingPolicy() {
  return <Policy url={URLS.API_FETCH_SHIPPING_POLICY} />;
}

export { TermsOfUsage, PrivacyPolicy, ReturnPolicy, ShippingPolicy };
