import React from 'react';

export default function Mail() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.33341 2.8335H11.6667C12.3084 2.8335 12.8334 3.3585 12.8334 4.00016V11.0002C12.8334 11.6418 12.3084 12.1668 11.6667 12.1668H2.33341C1.69175 12.1668 1.16675 11.6418 1.16675 11.0002V4.00016C1.16675 3.3585 1.69175 2.8335 2.33341 2.8335Z"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8334 4L7.00008 8.08333L1.16675 4"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
