import React from 'react';

function WhatsApp() {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="black" />
        <path
          d="M14.6612 11.9161C14.5609 12.6783 14.1866 13.3779 13.6082 13.8843C13.0297 14.3907 12.2867 14.6692 11.518 14.6679C7.05172 14.6679 3.41797 11.0341 3.41797 6.56785C3.4166 5.79908 3.69511 5.05609 4.2015 4.47766C4.70789 3.89922 5.40752 3.5249 6.16972 3.4246C6.36247 3.40107 6.55765 3.4405 6.72614 3.53701C6.89463 3.63353 7.02739 3.78194 7.1046 3.9601L8.2926 6.61229V6.61904C8.35171 6.75542 8.37612 6.90432 8.36366 7.05244C8.35119 7.20055 8.30224 7.34327 8.22116 7.46785C8.21104 7.48304 8.20035 7.4971 8.1891 7.51117L7.01797 8.89942C7.43929 9.75554 8.33479 10.6432 9.20216 11.0656L10.5713 9.90067C10.5847 9.88936 10.5988 9.87885 10.6135 9.86917C10.738 9.78614 10.8812 9.73546 11.0302 9.72171C11.1791 9.70796 11.3292 9.73158 11.4668 9.79042L11.4741 9.79379L14.124 10.9812C14.3025 11.0582 14.4513 11.1908 14.5481 11.3593C14.645 11.5278 14.6846 11.7232 14.6612 11.9161Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default WhatsApp;
