import Verify from '../../../constants/verify';

const validatePincode = (pincode) => {
  let errors = '';
  if (!pincode) {
    errors = Verify.VERIFY_PINCODE_EMPTY;
    return errors;
  }
  if (pincode?.length !== 6) {
    errors = Verify.VERIFY_PINCODE_INVALID;
    return errors;
  }
  if (pincode?.length === 6 && pincode === '000000') {
    errors = Verify.VERIFY_PINCODE_INVALID;
    return errors;
  }
  return errors;
};
export default validatePincode;
