import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { generateUrl } from '../../utils/ImageUtils';
import { FaqTypes } from '../../reducers/faq/faq';
import './Faq.scss';

function Sidebar(props) {
  const { fetchFaq } = useSelector((state) => state);
  const { faqData: { faq = [] } = {}, activeFaq = {} } = fetchFaq;
  const { setActiveData } = props;
  const handleClickLink = (data) => {
    setActiveData(data);
  };
  return (
    <div className="app-faq-sidebar">
      {faq.map((item, index) => (
        <div key={`faq-${index.toString()}`}>
          <div className="section-title">{item.name}</div>
          <div className="list-box">
            {item.categories &&
              item.categories.length > 0 &&
              item.categories.map((data, ind) => (
                <button
                  type="button"
                  key={`faq-item-${ind.toString()}`}
                  onClick={() => handleClickLink(data)}
                  className={`${activeFaq.name === data.name ? 'link-flexbox-active' : 'link-flexbox'}`}>
                  {data.icon && data.icon.length > 0 && (
                    <img src={generateUrl(data.icon)} alt=" " className="icon" />
                  )}
                  <div className="title">{data.name}</div>
                </button>
              ))}

            {index !== faq.length - 1 && <div className="sidebar-divider" />}
          </div>
        </div>
      ))}
    </div>
  );
}
Sidebar.propTypes = {
  setActiveData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { fetchFaq } = state;
  return { fetchFaq };
};

const mapDispatchToProps = (dispatch) => ({
  setActiveData: (activeData) => dispatch({ type: FaqTypes.SET_ACTIVE_FAQ, activeData })
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
