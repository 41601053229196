import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePostApiCall } from '../../adapters';
import { InnovationTypes } from '../../reducers/home/home';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import STORAGE_KEYS from '../../constants/storageKey';

function* getInnovationKits() {
  try {
    yield put({ type: InnovationTypes.GET_INNOVATION_PRODUCT_INDICATOR });
    const params = {
      userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
    };
    const response = yield call(makePostApiCall, URLS.API_FETCH_HOME, params);
    if (response.status === 200) {
      yield put({
        type: InnovationTypes.GET_INNOVATION_PRODUCT_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: InnovationTypes.GET_INNOVATION_PRODUCT_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: InnovationTypes.GET_INNOVATION_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getShopByCategory(payload) {
  try {
    yield put({ type: InnovationTypes.GET_SHOP_BY_CATEGORY_INDICATOR });
    const { userId } = payload;
    const params = { userId };
    const response = yield call(makePostApiCall, URLS.API_FETCH_CATEGORY_WISE, params);
    if (response.status === 200) {
      yield put({
        type: InnovationTypes.GET_SHOP_BY_CATEGORY_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: InnovationTypes.GET_SHOP_BY_CATEGORY_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: InnovationTypes.GET_SHOP_BY_CATEGORY_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* homeSaga() {
  try {
    yield all([
      takeLatest(InnovationTypes.GET_INNOVATION_PRODUCT, getInnovationKits),
      takeLatest(InnovationTypes.GET_SHOP_BY_CATEGORY, getShopByCategory)
    ]);
  } catch (ex) {
    console.warn('Error while Info Saga: InfoSaga', ex);
  }
}
export default homeSaga;
