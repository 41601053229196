import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const LoginWithMobileTypes = mirrorKeyValue([
  'GET_LOGIN_USER_WITH_MOBILE',
  'GET_VERIFY_USER_WITH_MOBILE',
  'GET_LOGIN_USER_WITH_MOBILE_INDICATOR',
  'GET_LOGIN_USER_WITH_MOBILE_SUCCESS',
  'GET_LOGIN_USER_WITH_MOBILE_FAILED',
  'SEND_EMAIL_OTP',
  'SEND_EMAIL_OTP_INDICATOR',
  'SEND_EMAIL_OTP_SUCCESS',
  'SEND_EMAIL_OTP_FAILED',
  'UPDATE_OTP_VIEW_STATE',
  'RESET_LOGIN_WTIH_MOBILE_VIEW'
]);

const {
  GET_LOGIN_USER_WITH_MOBILE,
  GET_VERIFY_USER_WITH_MOBILE,
  GET_LOGIN_USER_WITH_MOBILE_INDICATOR,
  GET_LOGIN_USER_WITH_MOBILE_SUCCESS,
  GET_LOGIN_USER_WITH_MOBILE_FAILED,
  SEND_EMAIL_OTP,
  SEND_EMAIL_OTP_INDICATOR,
  SEND_EMAIL_OTP_SUCCESS,
  SEND_EMAIL_OTP_FAILED,
  UPDATE_OTP_VIEW_STATE,
  RESET_LOGIN_WTIH_MOBILE_VIEW
} = LoginWithMobileTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  lmloading: false,
  lmloginData: '',
  lmerror: '',
  lmerrorMsg: '',
  isOtpSent: false
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_LOGIN_USER_WITH_MOBILE:
      return { ...state, ...data };
    case GET_VERIFY_USER_WITH_MOBILE:
      return { ...state, ...data };
    case GET_LOGIN_USER_WITH_MOBILE_INDICATOR:
      return {
        ...state,
        lmloading: true,
        lmerror: false,
        isOtpSent: false
      };
    case GET_LOGIN_USER_WITH_MOBILE_SUCCESS:
      return {
        ...state,
        lmloading: false,
        lmloginData: action.response,
        lmerror: false,
        isOtpSent: true
      };
    case GET_LOGIN_USER_WITH_MOBILE_FAILED:
      return {
        ...state,
        lmloading: false,
        lmerror: true,
        lmerrorMsg: action.message,
        isOtpSent: false
      };
    case SEND_EMAIL_OTP:
      return { ...state, ...data };
    case SEND_EMAIL_OTP_INDICATOR:
      return {
        ...state,
        lmloading: true,
        lmerror: false,
        isOtpSent: false
      };
    case SEND_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        lmloading: false,
        lmloginData: action.response,
        lmerror: false,
        isOtpSent: true
      };
    case SEND_EMAIL_OTP_FAILED:
      return {
        ...state,
        lmloading: false,
        lmerror: true,
        lmerrorMsg: action.message,
        isOtpSent: false
      };
    case UPDATE_OTP_VIEW_STATE:
      return {
        ...state,
        isOtpSent: action.otpView
      };
    case RESET_LOGIN_WTIH_MOBILE_VIEW:
      return {
        ...state,
        lmerrorMsg: '',
        lmerror: false,
        isOtpSent: false
      };
    default:
      return state;
  }
}
