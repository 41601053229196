import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import InfoCard from '../../components/multicellular/home/infoCard';
import './Home.scss';
import RoutesPaths from '../../constants/routes';
import { ProductTypes } from '../../reducers/product/productList';
import { FilterTypes } from '../../reducers/filter/filter';
import kitsColor from '../../config/product/kits';

function Kits(props) {
  const { mainCategoriesData, loading, resetFilterApi, resetProductApi } = props;
  const handleReset = () => {
    resetProductApi();
    resetFilterApi();
  };
  return (
    <div className="container">
      <div className="app-home-kits-container ">
        {loading ? (
          <div className="col">
            <InfoCard loading={loading} />
          </div>
        ) : (
          mainCategoriesData &&
          mainCategoriesData.length > 0 &&
          mainCategoriesData.map((item, index) => (
            <div key={item.title} className="kits-wd">
              <NavLink
                to={`${RoutesPaths.PRODUCT_EXPLORER}/${item.categoryId}`}
                onClick={handleReset}>
                <InfoCard
                  title={item.title}
                  price={item.price}
                  imageSrc={item.icon}
                  boderBottomColor={kitsColor[index % 7]}
                />
              </NavLink>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
Kits.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mainCategoriesData: PropTypes.array,
  loading: PropTypes.bool,
  resetProductApi: PropTypes.func.isRequired,
  resetFilterApi: PropTypes.func.isRequired
};
Kits.defaultProps = {
  loading: false,
  mainCategoriesData: []
};
const mapStateToProps = (state) => {
  const { allCategory } = state;
  return { allCategory };
};

const mapDispatchToProps = (dispatch) => ({
  resetProductApi: () => dispatch({ type: ProductTypes.GET_PRODUCT_RESET }),
  resetFilterApi: () => dispatch({ type: FilterTypes.RESET_FILTER_API_CALL })
});
export default connect(mapStateToProps, mapDispatchToProps)(Kits);
