import Verify from '../../../../constants/verify';

export const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateReturnForm = (question, comment) => {
  let errors = '';
  if (!question) {
    errors = Verify.VERIFY_QUESTION_EMPTY;
    return errors;
  }
  if (!comment) {
    errors = Verify.VERIFY_COMMENT_EMPTY;
    return errors;
  }
  return errors;
};
