import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Close from '../../../../assets/svgs/Close';
import WhatsApp from '../../../../assets/svgs/WhatsApp';
import BlackEmail from '../../../../assets/svgs/BlackEmail';

import './Styles.scss';
import Images from '../../../../themes/Image';

const renderSuccessModal = () => (
  <div>
    <div>
      <div>
        <img src={Images.CheckGreen} alt="" srcSet="" />
      </div>
      <div className="modal-title">Thank You!</div>
      <div className="modal-description">Your bulk order request has been placed successfully.</div>
      <div>
        <div className="information-wrapper">
          <div>Reach Us:</div>
          <div>
            <WhatsApp />
          </div>
          <div>+91 86604 50262</div>
          <div>
            <BlackEmail />
          </div>
          <div>support@yuvipep.com</div>
        </div>
      </div>
    </div>
  </div>
);

function SuccessModal(props) {
  const { show, onClose } = props;
  return (
    <div>
      <Modal size="md" show={show} onHide={onClose} centered contentClassName="success-modal">
        <div className="success-modal-header-container">
          <button
            type="button"
            aria-label="success-model"
            onClick={onClose}
            className="success-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>
        <Modal.Body className="success-modal-body">
          <div className="success-modal-cmp">{renderSuccessModal()}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
SuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SuccessModal;
