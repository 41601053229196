import Routes from '../constants/routes';
import Address from '../assets/svgs/Address';
import Order from '../assets/svgs/Order';
import Profile from '../assets/svgs/Profile';
import InvitePeople from '../assets/svgs/InvitePeople';
import CustomerCare from '../assets/svgs/CustomerCare';

const accountSidebar = [
  {
    sectionTitle: 'ORDERS',
    links: [
      {
        navigateTo: Routes.ORDERS,
        title: 'My Orders',
        icon: <Order />
      }
    ]
  },
  {
    sectionTitle: 'ACCOUNT',
    links: [
      {
        navigateTo: Routes.PROFILE,
        title: 'Profile',
        icon: <Profile />
      },
      {
        navigateTo: Routes.ADDRESS,
        title: 'Addresses',
        icon: <Address />
      },
      {
        navigateTo: Routes.INVITE_FRIENDS,
        title: 'Invite Friends',
        icon: <InvitePeople />
      }
    ]
  },
  {
    sectionTitle: 'SUPPORT',
    links: [
      {
        navigateTo: Routes.CUSTOMER_CARE,
        title: 'Customer Care',
        icon: <CustomerCare />
      }
    ]
  }
];
export default accountSidebar;
