import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactImageMagnify from 'react-image-magnify';
import { generateUrl } from '../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../constants/defaults/product';

export default function ProductImage({ images, selectedImage, handleImageChange }) {
  useEffect(() => {
    if (images && images.length > 0) {
      handleImageChange(images[0]);
    }
  }, [images]);
  return (
    <div className="app-product-image-container">
      {selectedImage?.length > 0 ? (
        <div className="product-img-view">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: '',
                isFluidWidth: true,
                src: generateUrl(selectedImage)
              },
              largeImage: {
                src: generateUrl(selectedImage),
                width: 1600,
                height: 1600
              }
            }}
          />
        </div>
      ) : (
        <div className="product-img-view-nf">
          <img
            // className="product-image"
            src={generateUrl(selectedImage)}
            alt=""
            srcSet=""
            onError={({ currentTarget }) => {
              const currTrgt = currentTarget;
              currTrgt.onerror = null; // prevents looping
              currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
            }}
          />
        </div>
      )}
      <div className="small-img-wrapper">
        {images?.map((image) => (
          <button
            type="button"
            className={`${selectedImage === image ? 'small-img-btn-active' : 'small-img-btn'}`}
            onClick={() => {
              handleImageChange(image);
            }}
            key={image}>
            <img
              className="small-product-image"
              src={generateUrl(image)}
              alt={image}
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
          </button>
        ))}
      </div>
    </div>
  );
}

ProductImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleImageChange: PropTypes.func.isRequired,
  selectedImage: PropTypes.string
};
ProductImage.defaultProps = {
  selectedImage: ''
};
