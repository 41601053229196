import React from 'react';
import { connect, useSelector } from 'react-redux';
import STORAGE_KEYS from '../../constants/storageKey';
import './MyAccount.scss';

function SectionHeader() {
  const { login } = useSelector((state) => state);
  const { userName } = login;
  return (
    <div className="app-myaccount-header-layout">
      <div>
        <div className="app-myaccount-page-title">My Account</div>
        <div className="app-myaccount-user-name">
          {userName !== '' ? userName : localStorage.getItem(STORAGE_KEYS.USER_NAME)}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

export default connect(mapStateToProps)(SectionHeader);
