import Verify from '../../../../constants/verify';
import { checkMobileNumber } from '../../../auth/FormValidation';

export const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const validateRegisterForm = (fullName, email, password, mobile) => {
  let errors = '';
  if (!fullName) {
    errors = Verify.VERIFY_NAME_EMPTY;
    return errors;
  }
  if (!mobile) {
    errors = Verify.VERIFY_MOBILE_EMPTY;
    return errors;
  }
  if (mobile.length < 10 || mobile.length >= 11) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (mobile && !checkMobileNumber(mobile)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (email && !emailValidation(email)) {
    errors = Verify.VERIFY_EMAIL_INVALID;
    return errors;
  }
  if (!password) {
    errors = Verify.VERIFY_PASSWORD_EMPTY;
    return errors;
  }
  if (password && password.length < 6) {
    errors = Verify.VERIFY_PASSWORD_LIMIT;
    return errors;
  }
  return errors;
};

export const validateLoginFormWithEmail = (email) => {
  let errors = '';
  if (!email) {
    errors = Verify.VERIFY_EMAIL_EMPTY;
    return errors;
  }
  if (email && !emailValidation(email)) {
    errors = Verify.VERIFY_EMAIL_INVALID;
    return errors;
  }
  return errors;
};

export const validateLoginFormWithMobile = (mobileNumber, password) => {
  let errors = '';
  if (!mobileNumber) {
    errors = Verify.VERIFY_MOBILE_EMPTY;
    return errors;
  }
  if (mobileNumber.length < 10 || mobileNumber.length >= 15) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (mobileNumber && !checkMobileNumber(mobileNumber)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (!password) {
    errors = Verify.VERIFY_PASSWORD_EMPTY;
    return errors;
  }
  if (password && password.length < 6) {
    errors = Verify.VERIFY_PASSWORD_LIMIT;
    return errors;
  }
  return errors;
};

export const validateOtp = (otp) => {
  let errors = '';
  if (!otp) {
    errors = Verify.VERIFY_OTP_EMPTY;
    return errors;
  }
  if (otp && otp.length !== 4) {
    errors = Verify.VERIFY_OTP_EMPTY;
    return errors;
  }
  return errors;
};

export const validateLoginFormWithMobileOtp = (mobileNumber) => {
  let errors = '';
  if (!mobileNumber) {
    errors = Verify.VERIFY_MOBILE_EMPTY;
    return errors;
  }
  if (mobileNumber.length < 10 || mobileNumber.length >= 11) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (mobileNumber && !checkMobileNumber(mobileNumber)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  return errors;
};

export const validateOtpWithPassword = (otp, password) => {
  let errors = '';
  if (!otp) {
    errors = Verify.VERIFY_OTP_EMPTY;
    return errors;
  }
  if (otp && otp.length !== 4) {
    errors = Verify.VERIFY_OTP_EMPTY;
    return errors;
  }
  if (!password) {
    errors = Verify.VERIFY_PASSWORD_EMPTY;
    return errors;
  }
  if (password && password.length < 6) {
    errors = Verify.VERIFY_PASSWORD_LIMIT;
    return errors;
  }
  return errors;
};
