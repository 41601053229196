import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InnovationTypes } from '../../reducers/home/home';
// import Error from '../../components/multicellular/Error/Error';
import STORAGE_KEYS from '../../constants/storageKey';
// import String from '../../constants/strings';
// import PRODUCT_DEFAULTS from '../../constants/defaults/product';
// import { generateUrl } from '../../utils/ImageUtils';
// import Images from '../../themes/Image';
import KitCarousel from '../home/KitCarousel';
// import Loader from './loader';
import { CartTypes } from '../../reducers/cart/cart';
// import { InfoTypes } from '../../reducers/info/information';
// import Products from '../product/productDetail/Products';
// import Recommended from '../product/productDetail/Recommended';
import ProductString from '../../constants/strings/product';
// import Loader from '../cart-wagon/loader';
import { SimiliarTypes } from '../../reducers/info/similarproduct';
import { RecommendedTypes } from '../../reducers/info/recommended';

function Information(props) {
  const {
    callHomeApi,
    callSimilarProductApi,
    callCategoryWiseApi,
    resetAddToCart,
    callRelatedProductApi
  } = props;
  const {
    home,
    login,
    cart,
    similarproduct: { load, similarproducts = {} } = {},
    recommended: { loading: recommendedLoading, recommendedproducts: { content = [] } = {} } = {}
    // information: { productData: { product = {} } = {} } = {},
  } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('item');
  const { isUserLogin } = login;
  const { isSuccess, carts } = cart;
  const {
    loading,
    homeproducts,
    error,
    // errorMsg,
    isCallApi
  } = home;
  const { home: { dealsOfTheDay: { products = [], title = '' } = {}, popularProducts = {} } = {} } =
    homeproducts;
  useEffect(() => {
    if (!isCallApi || isUserLogin) {
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      callCategoryWiseApi(userId);
    }
  }, [isUserLogin]);
  useEffect(() => {
    if (isSuccess) {
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      callCategoryWiseApi(userId);
      resetAddToCart();
    }
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    // callProductApi(productId, userId);
    if (carts?.length > 0) {
      const categoryIds = carts?.map((obj) => obj?.product?.parentId?.toString());
      const productIds = carts?.map((obj) => obj?.productId.toString());
      callSimilarProductApi(null, null, userId, productIds, categoryIds);
      callRelatedProductApi(null, null, userId, productIds, categoryIds);
    }
    callHomeApi();
  }, [isUserLogin, productId, carts]);
  return (
    <div className="main-container-wrapper">
      {loading ? (
        <div className="container">
          <div className="row">
            <KitCarousel loading={loading} />
          </div>
        </div>
      ) : (
        <div>
          {error ? null : (
            <div>
              {similarproducts?.content &&
                similarproducts?.content?.length > 0 &&
                carts?.length > 0 && (
                  <KitCarousel
                    loading={load}
                    products={similarproducts?.content}
                    title={ProductString.RELATED_PRODUCT_SECTION_TITLE}
                  />
                )}
              <KitCarousel
                loading={loading}
                products={popularProducts.products}
                title={popularProducts.title}
              />
              <KitCarousel
                loading={loading}
                products={products}
                title={title}
                // bgImage={Images.BluePrimary}
              />
              {content && content?.length > 0 && carts?.length > 0 && (
                <KitCarousel
                  loading={recommendedLoading}
                  products={content}
                  title={ProductString.BOUGHT_TOGETHER}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
Information.propTypes = {
  callHomeApi: PropTypes.func.isRequired,
  callCategoryWiseApi: PropTypes.func.isRequired,
  resetAddToCart: PropTypes.func.isRequired,
  callSimilarProductApi: PropTypes.func.isRequired,
  callRelatedProductApi: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { home, information } = state;
  return { home, information };
};

const mapDispatchToProps = (dispatch) => ({
  callHomeApi: () =>
    dispatch({
      type: InnovationTypes.GET_INNOVATION_PRODUCT
    }),
  callCategoryWiseApi: (userId) =>
    dispatch({
      type: InnovationTypes.GET_SHOP_BY_CATEGORY,
      userId
    }),
  resetAddToCart: () =>
    dispatch({
      type: CartTypes.RESET_CART
    }),
  callSimilarProductApi: (categoryId, productId, userId, productIds, categoryIds) =>
    dispatch({
      type: SimiliarTypes.GET_SIMILIAR_PRODUCT,
      categoryId,
      productId,
      userId,
      productIds,
      categoryIds
    }),
  callRelatedProductApi: (categoryId, productId, userId, productIds, categoryIds) =>
    dispatch({
      type: RecommendedTypes.GET_RECOMMENDED_PRODUCT,
      categoryId,
      productId,
      userId,
      productIds,
      categoryIds
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Information);
