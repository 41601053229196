import mirrorKeyValue from 'mirror-key-value';

export const OrderTypes = mirrorKeyValue([
  'GET_ORDER_LIST',
  'GET_ORDER_LIST_INDICATOR',
  'GET_ORDER_LIST_SUCCESS',
  'GET_ORDER_LIST_FAILED'
]);

const { GET_ORDER_LIST, GET_ORDER_LIST_INDICATOR, GET_ORDER_LIST_SUCCESS, GET_ORDER_LIST_FAILED } =
  OrderTypes;

const INITIAL_STATE = {
  load: false,
  orderData: {},
  error: '',
  errorMsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_ORDER_LIST:
      return { ...state, ...data };
    case GET_ORDER_LIST_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        load: false,
        orderData: action.response,
        error: false
      };
    case GET_ORDER_LIST_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
