/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import StepButton from '@mui/material/StepButton';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import RoutesPaths from '../../../../constants/routes';
import './Stepper.scss';

const steps = ['CART', 'ADDRESS', 'PAYMENT'];

export default function HorizontalLinearStepper(props) {
  const { currentPath } = props;
  const navigate = useNavigate();
  const checkActiveStep = () => {
    if (RoutesPaths.CART === currentPath) {
      return 0;
    }
    if (`${RoutesPaths.CART}/${RoutesPaths.ADDRESS}` === currentPath) {
      return 1;
    }
    if (`${RoutesPaths.PAYMENT_SUMMARY}` === currentPath) {
      return 2;
    }
    return 0;
  };
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    setActiveStep(checkActiveStep());
  }, [activeStep, currentPath]);

  const handleStep = (step) => {
    if (step < activeStep) {
      navigate(
        step === 0 ? RoutesPaths.CART : step === 1 && `${RoutesPaths.CART}/${RoutesPaths.ADDRESS}`
      );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={checkActiveStep()}>
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'inherit',
                color: '#FFFFFF'
              },
              '& .Mui-completed': {
                color: '#FFFFFF !important'
              },
              '& .Mui-active': {
                color: '#FFFFFF !important'
              },
              '&. Mui-disabled': {
                color: '#FFFFFF !important'
              },
              '& .MuiSvgIcon-root': {
                width: '1.2em',
                height: '1.2em',
                display: 'none'
              },
              '& .MuiStepIcon-text': {
                fontSize: '12px !important'
              },
              '& .MuiStepButton-touchRipple': {
                color: 'transparent !important'
              }
            }}>
            <StepButton color="inherit" onClick={() => handleStep(index)}>
              <StepLabel>{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
HorizontalLinearStepper.propTypes = {
  currentPath: PropTypes.string.isRequired
};
