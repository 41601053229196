import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '../../../components/unicellular/button';
import Suggestion from '../../../components/multicellular/products/suggestionModal/Suggestion';
// import SuggestionModel from '../../../components/multicellular/changePassword/ChangePassword';

import './ProductExplorer.scss';
import { SuggestionTypes } from '../../../reducers/product/suggestion';
import ProductString from '../../../constants/strings/product';

const Alert = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
Alert.displayName = 'Alert';

function Notification(props) {
  const [suggestion, setSuggestion] = useState(false);
  const { resetSuggestionReducer } = props;
  const {
    suggestion: { isSuccessFullySubmited, suggestionData: { message } = {} }
  } = useSelector((state) => state);
  useEffect(() => {
    if (isSuccessFullySubmited) {
      setSuggestion(false);
      setInterval(() => {
        resetSuggestionReducer();
      }, 3000);
    }
  }, [isSuccessFullySubmited]);

  const suggestProduct = () => {
    setSuggestion(true);
  };
  const closesuggestProductModal = () => {
    setSuggestion(false);
  };
  const rendersuggestProduct = () => (
    <div>
      <div className="btn-wrapper">
        <Button
          buttonText={ProductString.QUERY_BTN_TEXT}
          className="app-btn-notify"
          onClick={suggestProduct}
        />
      </div>
      {suggestion && <Suggestion show={suggestion} onClose={closesuggestProductModal} />}
      <Snackbar
        open={isSuccessFullySubmited}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          <div className="alet-message-success">{message}</div>
        </Alert>
      </Snackbar>
    </div>
  );
  return (
    <div className="app-product-notify-wrapper">
      <div className="title">{ProductString.SUGGESTION_TITLE}</div>
      <div className="btn-wrapper">{rendersuggestProduct()}</div>
    </div>
  );
}
Notification.propTypes = {
  resetSuggestionReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { suggestion } = state;
  return { suggestion };
};
const mapDispatchToProps = (dispatch) => ({
  resetSuggestionReducer: () =>
    dispatch({
      type: SuggestionTypes.RESET_GET_SUGGESTION
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
