const Verify = {
  VERIFY_VIDEO_EMPTY: '* Please select video ',
  VERIFY_NAME_EMPTY: '* Please enter full name',
  VERIFY_MOBILE_EMPTY: '* Please enter mobile number',
  VERIFY_EMAIL_EMPTY: '* Please enter email address',
  VERIFY_EMAIL_INVALID: '* Please enter correct email address',
  VERIFY_PASSWORD_EMPTY: '* Please enter password ',
  ENTER_ALL_FIELD: '* Please enter all fields',
  VERIFY_SAME_PASSWORD: '* New password and Confirm password are not matching',
  VERIFY_DIFFERENT_PASSWORD: "* Old password and New password can't be same",
  VERIFY_PINCODE_EMPTY: '* Please enter pincode',
  VERIFY_ADDRESS_EMPTY: '* Please enter address',
  VERIFY_LANDMARK_EMPTY: '* Please enter landmark',
  VERIFY_PINCODE_INVALID: '* Please enter a valid pincode',
  VERIFY_OTP_EMPTY: '* Please enter a valid otp',
  VERIFY_MOBILE_INVALID: '* Please enter valid mobile number ',
  VERIFY_ONLY_MOBILE_ACCEPTED: '* Please enter a valid phone number',
  VERIFY_PASSWORD_LIMIT: '* Invalid current password',
  VERIFY_NEW_PASSWORD_EMPTY: '* Please enter new password',
  VERIFY_RETYPE_PASSWORD: '* Please re-enter new password',
  VERIFY_COMMENT_EMPTY: '* Please enter the comment',
  VERIFY_QUESTION_EMPTY: '* Please select the reason',
  VERIFY_EMPTY_PRODUCT: '* Please enter product name for suggestion',
  VERIFY_EMPTY_TITLE: '* Please enter the title',
  VERIFY_EMPTY_COMMENT: '* Please enter the comment',
  VERIFY_NEW_PASSWORD_LIMIT: '* New password should be minimum 6 characters',
  VERIFY_COLLEGE: '* Please enter college/school'
};
export default Verify;
