import React from 'react';
import PropTypes from 'prop-types';
import MegaMenu from '../../multicellular/navigation/megaMenu';
import './SearchField.scss';
import WhiteClose from '../../../assets/svgs/WhiteClose';

export default function SearchInputField(props) {
  const {
    className,
    isIcon,
    icon,
    type,
    value,
    onChange,
    placeholder,
    readOnly,
    minLength,
    maxLength,
    disabled,
    onPress,
    defaultValue,
    onFocus,
    onBlur,
    onKeyPress,
    onClick,
    onClickClear
  } = props;
  return (
    <div className={`app-search-input-container ${className}`}>
      <div className="app-input-field">
        <input
          type={type}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onPress(e);
            }
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={readOnly}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
        />
        {value?.length > 0 && (
          <button aria-label="white-close" type="button" onClick={onClickClear}>
            <WhiteClose />
          </button>
        )}
      </div>
      <MegaMenu />
      {isIcon && (
        <button
          type="button"
          onClick={() => {
            onClick(value);
          }}
          className="icon">
          {icon}
        </button>
      )}
    </div>
  );
}
SearchInputField.propTypes = {
  className: PropTypes.string,
  isIcon: PropTypes.bool,
  icon: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  defaultValue: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  onClickClear: PropTypes.func
};
SearchInputField.defaultProps = {
  className: '',
  isIcon: false,
  icon: null,
  type: 'text',
  placeholder: '',
  readOnly: false,
  minLength: 0,
  maxLength: 1000,
  disabled: false,
  onPress: () => {},
  defaultValue: '',
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
  onClick: () => {},
  onClickClear: () => {}
};
