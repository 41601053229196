import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall } from '../../adapters';
import { FilterTypes } from '../../reducers/filter/filter';
import { ProductTypes } from '../../reducers/product/productList';
import { SuggestionTypes } from '../../reducers/product/suggestion';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { BulkProductTypes } from '../../reducers/product/productBulkList';

function* getProductList(payload) {
  try {
    const { isFiltered, filterMap, sortMap, page, userId, size } = payload;
    yield put({
      type: ProductTypes.GET_PRODUCT_INDICATOR,
      isLoading: isFiltered
    });
    const params = {
      filterMap,
      sortMap,
      page: !isFiltered ? page : 1,
      size: size || 15,
      userId
    };
    const response = yield call(makePostApiCall, URLS.API_PRODUCT_DATA_TABLE, params);
    if (response.status === 200) {
      if (isFiltered) {
        yield put({
          type: ProductTypes.GET_PRODUCT_SUCCESS_WITH_FILTER,
          response: response.data
        });
      } else {
        yield put({
          type: ProductTypes.GET_PRODUCT_SUCCESS,
          response: response.data
        });
      }
      yield put({ type: FilterTypes.RESET_FILTER_API_CALL });
    } else {
      yield put({ type: FilterTypes.RESET_FILTER_API_CALL });
      yield put({
        type: ProductTypes.GET_PRODUCT_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({ type: FilterTypes.RESET_FILTER_API_CALL });
    yield put({
      type: ProductTypes.GET_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* searchProducts(payload) {
  try {
    const { queryString } = payload;
    const encoded = encodeURIComponent(queryString);
    const response = yield call(makeGetApiCall, URLS.API_SEARCH_SUGGESTIONS(encoded));
    if (response.status === 200) {
      yield put({
        type: ProductTypes.PRODUCT_SEARCH_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ProductTypes.PRODUCT_SEARCH_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: ProductTypes.GET_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* searchBulkProducts(payload) {
  try {
    const { queryString } = payload;
    const encoded = encodeURIComponent(queryString);
    const response = yield call(makeGetApiCall, URLS.API_SEARCH_SUGGESTIONS_BULK(encoded));
    if (response.status === 200) {
      yield put({
        type: BulkProductTypes.BULK_PRODUCT_SEARCH_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: BulkProductTypes.BULK_PRODUCT_SEARCH_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: BulkProductTypes.BULK_PRODUCT_SEARCH_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* suggestProduct(payload) {
  try {
    yield put({ type: SuggestionTypes.GET_SUGGESTION_INDICATOR });
    const params = {
      productName: payload.productName,
      userEmail: payload.userEmail,
      userMobile: payload.userMobile,
      name: payload.name
    };
    const response = yield call(makePostApiCall, URLS.API_SUGGEST_PRODUCT, params);
    if (response.status === 200) {
      yield put({
        type: SuggestionTypes.GET_SUGGESTION_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: SuggestionTypes.GET_SUGGESTION_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: SuggestionTypes.GET_SUGGESTION_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* ProductSaga() {
  try {
    yield all([
      takeLatest(ProductTypes.GET_PRODUCT, getProductList),
      takeLatest(ProductTypes.PRODUCT_SEARCH_FETCH, searchProducts),
      takeLatest(BulkProductTypes.BULK_PRODUCT_SEARCH_FETCH, searchBulkProducts),
      takeLatest(SuggestionTypes.GET_SUGGESTION, suggestProduct)
    ]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default ProductSaga;
