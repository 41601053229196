import mirrorKeyValue from 'mirror-key-value';
import RoutesPaths from '../../../constants/routes';
import STORAGE_KEYS from '../../../constants/storageKey';
import { createActions } from '../../../utils/saga/saga-utils';
/* ------------- LoginType ------------- */

export const LoginTypes = mirrorKeyValue([
  'GET_LOGIN_USER',
  'GET_LOGIN_USER_INDICATOR',
  'GET_LOGIN_USER_SUCCESS',
  'GET_LOGIN_USER_FAILED',
  'UPDATE_OTP_VIEW_STATE',
  'RESET_LOGIN_VIEW',
  'SET_USER_LOGIN',
  'RESET_GET_USER_FETCH',
  'RESET_USER_LOGIN_FROM_PRODUCT',
  'RESET_IS_USER_LOGIN',
  'RESET_LOGIN_FORM',
  ...createActions('GET_USER')
]);

const {
  GET_LOGIN_USER,
  GET_LOGIN_USER_INDICATOR,
  GET_LOGIN_USER_SUCCESS,
  GET_LOGIN_USER_FAILED,
  UPDATE_OTP_VIEW_STATE,
  RESET_LOGIN_VIEW,
  SET_USER_LOGIN,
  RESET_GET_USER_FETCH,
  RESET_USER_LOGIN_FROM_PRODUCT,
  RESET_IS_USER_LOGIN,
  RESET_LOGIN_FORM
} = LoginTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  loginData: '',
  error: '',
  errorMsg: '',
  isLoginSuccessfull: false,
  activePopUp: false,
  authView: true,
  userName: '',
  isUserFetched: false,
  isUserLogin: localStorage.getItem(STORAGE_KEYS.IS_LOGIN) === 'true',
  isLoginToProduct: false
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_LOGIN_USER:
      return { ...state, ...data };
    case GET_LOGIN_USER_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isLoginSuccessfull: false
      };
    case GET_LOGIN_USER_SUCCESS:
      localStorage.setItem(STORAGE_KEYS.USER_ID, action.response.userId);
      localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, action.response.token);
      if (action.response.token) {
        localStorage.setItem(STORAGE_KEYS.IS_LOGIN, true);
      }
      localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, action.response.refreshToken);
      localStorage.setItem(STORAGE_KEYS.USER_NAME, action.response.user.name);
      return {
        ...state,
        loading: false,
        loginData: action.response.message,
        error: false,
        isLoginSuccessfull: true,
        userName: action.response.token ? action.response.user.name : '',
        isUserLogin: !!action.response.token,
        isLoginToProduct: window.location.pathname === RoutesPaths.PRODUCT_EXPLORER
      };
    case GET_LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isLoginSuccessfull: false
      };
    case UPDATE_OTP_VIEW_STATE:
      return {
        ...state,
        isLoginSuccessfull: action.otpView
      };
    case RESET_LOGIN_VIEW:
      return {
        ...state,
        errorMsg: '',
        error: false,
        isLoginSuccessfull: false
      };
    case RESET_LOGIN_FORM:
      return {
        ...state,
        errorMsg: '',
        error: false
      };
    case SET_USER_LOGIN:
      return {
        ...state,
        isUserLogin: false
      };

    case LoginTypes.GET_USER_FETCH: {
      return { ...state, loading: true };
    }

    case LoginTypes.GET_USER_FAILED: {
      return { ...state, loading: false };
    }

    case LoginTypes.GET_USER_SUCCESS: {
      localStorage.setItem(STORAGE_KEYS.USER_NAME, action.data.user.name);
      return {
        ...state,
        ...data,
        isUserFetched: action.isUserFetched,
        loading: false
      };
    }
    case RESET_GET_USER_FETCH: {
      return {
        ...state,
        isUserFetched: false
      };
    }
    case RESET_USER_LOGIN_FROM_PRODUCT: {
      return {
        ...state,
        isLoginToProduct: false
      };
    }
    case RESET_IS_USER_LOGIN: {
      return {
        ...state,
        isUserLogin: false
      };
    }
    default:
      return state;
  }
}
