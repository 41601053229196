const String = {
  TERMS_OF_USES: ' Terms of Use ',
  PRIVACY_POLICY: ' Privacy Policy',
  VIDEO_CONTENT: 'Video / Content',
  TIMESHEET: 'Timesheet',
  LOADINGERROR: 'Loading Error',
  LOADING: 'Loading',
  SERVER_ERROR: 'Something went wrong. Please try again later.',
  SERVER_ERROR_HOME:
    'Something went wrong. Please try again later or click the below button to go to Home page.',
  SERVER_ERROR_TRY_LATER: 'Something went wrong. Please try again later.',
  SUCCESSFULL_PASSWORD_CHANGE: 'Password change successfully',
  CHARACTER_LIMIT: 'Content limited to 500 characters',
  NOT_SUPPORTED_DEVICE: 'This site is not available in mobile view. ',
  NOT_SUPPORTED_DEVICE_DETAIL: 'Please visit the site on your desktop.',
  FOOTER_COMPANY_TAG: '2024 YuviPep Education Pvt. Ltd. All Rights Reserved.',
  BUTTON_GETNOW: 'Get Now',
  BUTTON_BUYNOW: 'Buy Now',
  BUTTON_VIEWALL: 'View all',
  FOOTER_DESCRIPTION:
    'YuviPep Innovation aims to transform education from the ground up. Our pedagogical approach, connected learning, and online global collaboration have broken boundaries to include a network of students beyond the immediate classroom environment.',
  INVITE_FRIENDS_SHARE_MESSAGE:
    'YuviPep - Powering Innovation aims to transform education from the ground up. Our pedagogical approach, connected learning, and online global collaboration have broken boundaries to include a network of students beyond the immediate classroom environment. To purchase components required to build an innovative prototype, please visit: https://store.yuvipep.com. To know more about YuviPep courses, please visit: ',
  PRODUCT_SHARE_MESSAGE: 'Checkout this amazing product on YuviPep - Powering Innovation',
  ALL_CATEGORIES: 'All categories',
  TAWK_PROPERTY_ID: '60a7257eb1d5182476bb056f',
  TAWK_ACCOUNT_ID: '1f66ektn4',
  ADD_TO_CART: 'ADD TO CART',
  COMMENT_LIMIT_NOTE: 'Content limited to 500 characters',
  QUANTITY_NOTE: 'Quantity should be between 1 to 999'
};
export default String;
