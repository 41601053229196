import React from 'react';
import Images from '../../themes/Image';

import './Styles.scss';

function SectionHeader() {
  return (
    <div className="app-bulk-orders-header-layout">
      <div className="container">
        <div className="app-bulk-orders-header-inner-layout bulk-order-detail-wrapper">
          <div className="inner-layout">
            <div className="app-bulk-orders-page-title">Bulk Order Enquiry</div>
            <div className="title">Provide your customised products list for bulk order</div>
            <div className="description">
              You can choose any available product with any number of quantity required. Our experts
              will get in touch and share you with the quotation.
            </div>
          </div>
          <div className="image-container">
            <img src={Images.BulkOrder} alt="Bulk Order Enquiry" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;
