import React from 'react';
import PropTypes from 'prop-types';

export default function YuvipepLogo(props) {
  const { color } = props;
  return (
    <div>
      <svg
        width="160"
        height="90"
        viewBox="0 0 1088 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M263.194 243.033C259.408 246.797 256.396 250.808 252.381 253.967C244.75 259.97 236.103 263.172 226.457 263.775C218.094 264.298 209.804 263.891 201.767 261.269C184.607 255.671 176.362 242.815 173.147 225.982C171.962 219.779 171.523 213.486 171.523 207.167C171.524 185.013 171.492 162.858 171.542 140.704C171.558 133.741 173.976 128.053 180.857 125.145C188.123 122.075 196.986 123.526 201.992 128.506C205.393 131.889 206.398 136.105 206.395 140.719C206.379 163.181 206.383 185.643 206.393 208.105C206.394 211.332 206.506 214.532 207.104 217.743C209.985 233.215 219.228 236.683 230.8 236.675C248.01 236.664 260.507 224.835 262.055 207.684C262.72 200.315 262.368 192.925 262.394 185.545C262.445 170.776 262.415 156.006 262.406 141.237C262.402 135.07 263.978 129.68 269.633 126.222C277.637 121.327 293.611 122.448 296.706 135.703C297.086 137.329 297.17 139.054 297.171 140.733C297.197 175.964 297.156 211.196 297.221 246.427C297.233 253.018 294.836 258.196 288.948 261.261C282.486 264.624 275.829 264.566 269.551 260.729C265.106 258.013 263.49 253.54 263.21 248.545C263.108 246.713 263.194 244.871 263.194 243.033Z"
          fill="#EC1C24"
        />
        <path
          d="M89.4972 141.447C93.6842 136.293 97.7562 131.318 101.788 126.311C116.158 108.467 130.545 90.636 144.857 72.745C149.32 67.166 154.557 63.629 162.013 65.792C172.813 68.925 178.73 81.533 170.689 91.456C158.877 106.033 146.918 120.492 135.009 134.991C126.421 145.446 117.876 155.938 109.158 166.284C107.484 168.271 107.212 170.284 107.215 172.634C107.244 196.938 107.255 221.243 107.219 245.547C107.208 252.887 104.537 258.896 97.4132 261.851C90.0442 264.908 82.8852 264.245 76.6352 258.942C72.4442 255.386 71.3302 250.484 71.3332 245.216C71.3442 221.219 71.2922 197.222 71.3942 173.226C71.4072 170.128 70.6192 167.757 68.6032 165.324C48.4802 141.025 28.5642 116.556 8.42319 92.272C-0.323811 81.725 5.59219 69.137 16.4882 65.836C23.5192 63.705 28.7822 66.717 33.0772 71.994C39.7802 80.229 46.4122 88.522 53.0862 96.78C64.1132 110.424 75.1462 124.064 86.1892 137.695C87.1532 138.882 88.2112 139.994 89.4972 141.447Z"
          fill="#EC1C24"
        />
        <path
          d="M389.475 218.714C393.422 209.995 397.206 201.631 400.995 193.269C409.859 173.705 418.731 154.145 427.585 134.577C428.798 131.897 430.19 129.372 432.413 127.353C437.698 122.552 445.683 122.316 452.008 126.828C458.719 131.616 460.674 137.867 457.319 144.88C440.098 180.873 422.883 216.87 405.494 252.783C399.954 264.225 384.699 266.776 375.308 258.178C373.469 256.495 372.127 254.491 371.043 252.244C354.308 217.542 337.54 182.856 320.787 148.164C320.12 146.783 319.422 145.405 318.901 143.967C316.797 138.157 318.444 132.101 323.22 128.173C331.517 121.347 345.25 121.23 351.128 134.506C363.052 161.44 375.42 188.178 387.613 214.993C388.114 216.096 388.696 217.164 389.475 218.714Z"
          fill="#EC1C24"
        />
        <path
          d="M518.739 193.475C518.739 210.843 518.774 228.211 518.728 245.578C518.689 260.406 504.778 268.382 491.594 261.21C486.979 258.7 484.891 254.634 484.265 249.647C484.132 248.585 484.163 247.498 484.163 246.423C484.159 211.073 484.191 175.722 484.134 140.372C484.123 133.23 486.643 127.779 493.564 125.03C498.46 123.085 503.505 123.002 508.467 124.782C514.917 127.096 518.487 131.651 518.709 138.605C518.753 139.987 518.738 141.371 518.738 142.754C518.74 159.662 518.739 176.568 518.739 193.475Z"
          fill="#EC1C24"
        />
        <path
          d="M501.339 62.23C504.775 62.117 508.093 62.606 511.249 64.029C518.585 67.338 522.745 74.453 521.882 82.238C520.923 90.882 515.571 96.817 507.255 98.401C503.597 99.098 499.952 99.186 496.237 98.54C486.595 96.864 480.266 89.349 480.68 79.827C481.098 70.218 487.893 63.228 497.646 62.398C498.872 62.294 500.107 62.284 501.339 62.23Z"
          fill="#EC1C24"
        />
        <path
          d="M609.714 308.077C611.377 304.018 613.086 299.977 614.68 295.891C615.318 294.255 615.942 292.573 617.925 292.456C620.239 292.319 620.845 294.262 621.615 295.969C623.485 300.119 624.619 304.602 627.206 308.825C628.905 304.475 630.453 300.501 632.011 296.53C632.346 295.677 632.65 294.803 633.085 294.002C633.842 292.611 635.011 291.996 636.542 292.705C637.995 293.378 638.648 294.633 638.032 296.119C635.338 302.624 632.725 309.171 629.721 315.534C628.307 318.529 624.995 318.038 623.473 314.7C621.575 310.536 619.923 306.261 618.031 301.713C615.796 305.768 614.545 309.876 612.846 313.785C612.119 315.458 611.568 317.281 609.179 317.364C606.776 317.447 606.115 315.757 605.382 314.022C603.117 308.661 600.868 303.292 598.526 297.964C597.583 295.819 596.849 293.625 599.656 292.606C602.474 291.583 603.307 293.918 604.104 295.998C605.633 299.99 607.204 303.965 608.758 307.948C609.076 307.991 609.395 308.034 609.714 308.077Z"
          fill={color}
        />
        <path
          d="M823.395 295.885C824.924 294.279 826.422 293.241 828.219 292.677C834.427 290.727 839.422 294.046 839.779 300.562C840.014 304.844 839.884 309.147 839.927 313.44C839.949 315.661 839.463 317.519 836.703 317.419C833.91 317.317 833.736 315.282 833.728 313.164C833.715 309.638 833.794 306.107 833.616 302.589C833.42 298.716 832.191 297.276 829.401 297.117C826.46 296.949 823.752 299.084 823.395 302.307C823.093 305.036 823.286 307.819 823.262 310.578C823.25 311.958 823.318 313.343 823.211 314.716C823.07 316.528 822.014 317.446 820.165 317.439C818.319 317.432 817.175 316.517 817.154 314.697C817.08 308.258 817.089 301.818 817.124 295.378C817.132 293.852 817.908 292.673 819.501 292.427C821.787 292.072 822.874 293.414 823.395 295.885Z"
          fill={color}
        />
        <path
          d="M787.85 304.773C787.851 301.857 787.89 298.94 787.834 296.024C787.805 294.517 788.157 293.261 789.631 292.626C791.075 292.004 792.66 292.238 793.266 293.613C794.256 295.86 795.199 294.711 796.323 293.953C799.213 292.004 802.392 291.77 805.591 292.815C808.279 293.693 809.762 295.751 810.283 298.565C811.244 303.753 810.665 308.975 810.733 314.181C810.758 316.12 809.73 317.45 807.652 317.438C805.357 317.425 804.444 316.099 804.496 313.847C804.577 310.318 804.573 306.786 804.501 303.257C804.412 298.915 803.025 297.121 799.932 297.122C796.529 297.124 794.293 299.492 794.154 303.473C794.042 306.693 794.098 309.919 794.136 313.142C794.162 315.332 793.771 317.319 791.118 317.424C788.135 317.542 787.742 315.479 787.827 313.061C787.924 310.302 787.849 307.536 787.85 304.773Z"
          fill={color}
        />
        <path
          d="M700.737 304.653C700.737 301.732 700.761 298.811 700.727 295.89C700.71 294.397 701.029 293.147 702.6 292.58C703.893 292.114 705.358 292.265 705.923 293.389C706.954 295.439 707.81 294.775 709.311 293.933C716.611 289.838 722.72 292.898 723.409 301.065C723.757 305.188 723.612 309.357 723.591 313.504C723.58 315.675 723.016 317.54 720.207 317.376C717.671 317.227 717.363 315.53 717.388 313.498C717.43 310.116 717.465 306.732 717.378 303.352C717.267 299.005 715.848 297.151 712.825 297.142C709.366 297.132 707.195 299.378 707.01 303.478C706.858 306.854 706.981 310.241 706.955 313.623C706.939 315.664 706.366 317.318 703.907 317.389C701.834 317.449 700.752 316.45 700.758 314.337C700.768 311.109 700.761 307.88 700.761 304.652C700.754 304.653 700.746 304.653 700.737 304.653Z"
          fill={color}
        />
        <path
          d="M993.847 295.62C995.196 294.653 996.115 293.796 997.194 293.255C1003.48 290.105 1009.73 293.435 1010.21 300.358C1010.53 304.951 1010.35 309.578 1010.38 314.191C1010.39 316.193 1009.54 317.372 1007.37 317.413C1005.19 317.454 1004.32 316.291 1004.31 314.281C1004.3 310.59 1004.38 306.896 1004.28 303.207C1004.16 298.946 1002.65 297.105 999.584 297.142C996.383 297.182 994.051 299.419 993.82 303.138C993.601 306.663 993.746 310.21 993.743 313.747C993.741 315.819 993.207 317.383 990.688 317.413C988.142 317.443 987.624 315.89 987.629 313.802C987.643 307.957 987.633 302.112 987.635 296.267C987.636 294.494 987.831 292.777 990.023 292.422C992.343 292.046 993.398 293.404 993.847 295.62Z"
          fill={color}
        />
        <path
          d="M938.561 317.389C932.223 317.201 929.546 314.465 929.429 308.179C929.38 305.569 929.344 302.955 929.447 300.348C929.519 298.53 929.168 297.449 926.987 297.516C925.605 297.558 924.139 297.144 924.088 295.274C924.037 293.392 925.372 292.875 926.852 292.99C929.423 293.191 929.516 291.679 929.441 289.811C929.393 288.609 929.132 287.267 930.118 286.308C931.058 285.395 932.249 285.219 933.552 285.55C935.29 285.992 935.649 287.423 935.554 288.762C935.344 291.726 936.022 293.47 939.508 292.994C940.893 292.805 942.435 293.123 942.525 295.005C942.624 297.095 941.048 297.706 939.444 297.512C935.947 297.088 935.289 298.775 935.577 301.762C935.797 304.045 935.601 306.365 935.634 308.668C935.666 310.91 936.445 312.55 939.023 312.729C940.711 312.846 942.785 312.845 942.648 315.22C942.487 317.998 940.08 317.195 938.561 317.389Z"
          fill={color}
        />
        <path
          d="M884.762 309.423C886.847 304.825 888.726 300.667 890.621 296.517C891.064 295.547 891.44 294.51 892.068 293.667C892.969 292.457 894.209 291.9 895.683 292.791C897.036 293.609 897.613 294.751 896.923 296.289C895.983 298.384 895.035 300.476 894.044 302.547C892.196 306.41 890.321 310.261 888.442 314.11C887.626 315.783 886.834 317.434 884.498 317.394C882.388 317.359 881.652 315.899 880.903 314.35C878.235 308.839 875.548 303.338 872.888 297.824C871.992 295.967 871.098 294.005 873.679 292.75C876.176 291.536 877.336 293.175 878.238 295.147C880.334 299.733 882.43 304.32 884.762 309.423Z"
          fill={color}
        />
        <path
          d="M780.672 299.974C780.672 304.416 780.653 308.859 780.68 313.301C780.694 315.505 780.273 317.374 777.549 317.402C774.886 317.429 774.201 315.606 774.194 313.409C774.163 304.218 774.169 295.027 774.188 285.836C774.193 283.558 775.082 281.875 777.663 281.968C780.369 282.066 780.703 284.004 780.684 286.187C780.645 290.782 780.672 295.378 780.672 299.974Z"
          fill={color}
        />
        <path
          d="M675.269 295.859C677.219 293.716 679.428 292.458 682.212 292.344C683.808 292.279 684.944 292.824 685.159 294.534C685.364 296.158 684.592 297.193 683.017 297.57C682.274 297.748 681.489 297.744 680.732 297.877C676.705 298.586 675.194 300.246 675.053 304.374C674.948 307.443 674.99 310.519 675.041 313.59C675.076 315.683 674.553 317.298 672.108 317.392C669.406 317.495 668.733 315.733 668.726 313.487C668.71 308.11 668.728 302.734 668.715 297.357C668.71 295.316 668.615 293.146 671.127 292.478C673.757 291.78 674.514 293.876 675.269 295.859Z"
          fill={color}
        />
        <path
          d="M694.261 304.909C694.262 307.832 694.249 310.756 694.266 313.679C694.278 315.786 693.596 317.368 691.201 317.429C688.888 317.489 687.904 316.195 687.914 313.945C687.941 307.945 687.925 301.944 687.915 295.944C687.911 293.762 688.819 292.312 691.152 292.386C693.473 292.46 694.274 294.008 694.263 296.14C694.249 299.063 694.26 301.986 694.261 304.909Z"
          fill={color}
        />
        <path
          d="M946.171 304.786C946.171 301.867 946.215 298.946 946.156 296.028C946.112 293.852 946.882 292.355 949.242 292.381C951.543 292.406 952.454 293.916 952.457 296.046C952.465 302.038 952.481 308.031 952.423 314.022C952.402 316.186 951.329 317.531 948.988 317.397C946.884 317.276 946.105 316.034 946.152 314.004C946.225 310.933 946.171 307.859 946.171 304.786Z"
          fill={color}
        />
        <path
          d="M952.81 284.743C952.604 287.017 951.243 287.877 949.132 287.856C946.993 287.835 945.663 286.814 945.506 284.655C945.362 282.671 947.266 281.088 949.455 281.29C951.545 281.482 952.795 282.547 952.81 284.743Z"
          fill={color}
        />
        <path
          d="M687.305 284.86C687.53 282.731 688.542 281.355 690.774 281.306C692.844 281.261 694.457 282.063 694.602 284.359C694.744 286.611 693.458 287.732 691.25 287.832C689.102 287.929 687.75 286.966 687.305 284.86Z"
          fill={color}
        />
        <path
          d="M709.159 117.154C706.921 96.557 696.493 81.622 677.506 72.938C666.654 67.975 655.049 66.602 643.254 66.573C621.195 66.519 599.136 66.555 577.077 66.563C575.691 66.563 574.298 66.576 572.921 66.705C563.372 67.603 558.485 72.513 557.696 82.01C557.582 83.386 557.647 84.777 557.647 86.161C557.645 112.157 557.645 138.152 557.645 164.148C557.645 190.451 557.656 216.754 557.629 243.058C557.626 245.685 557.744 248.288 558.301 250.851C559.337 255.619 561.86 259.498 566.321 261.517C578.002 266.802 593.708 262.185 593.525 245.061C593.327 226.605 593.552 208.144 593.408 189.687C593.385 186.686 594.154 185.871 597.189 185.902C612.922 186.061 628.657 185.995 644.392 185.968C651.972 185.955 659.499 185.21 666.821 183.258C686.814 177.928 701.058 166.251 707.08 145.952C709.87 136.546 710.212 126.845 709.159 117.154ZM674.681 136.48C672.208 147.029 665.364 153.49 655.122 156.478C650.233 157.904 645.19 158.517 640.074 158.508C625.746 158.481 611.417 158.388 597.092 158.586C593.881 158.631 593.372 157.534 593.42 154.708C593.58 145.34 593.48 135.967 593.481 126.596C593.481 116.764 593.542 106.932 593.434 97.101C593.41 94.93 593.934 94.191 596.236 94.23C612.714 94.511 629.2 93.885 645.677 94.608C648.622 94.737 651.494 95.301 654.302 96.076C667.807 99.801 674.864 108.939 675.774 123.668C676.04 127.997 675.664 132.286 674.681 136.48Z"
          fill={color}
        />
        <path
          d="M1008.3 110.646C999.487 87.508 978.435 71.103 949.206 73.487C934.043 74.724 921.358 80.708 912.56 93.626C912.096 94.308 911.894 95.436 910.264 95.419C910.264 92.725 910.472 90.007 910.221 87.333C909.686 81.638 906.555 77.547 901.37 75.356C891.395 71.141 875.526 74.339 876.329 91.598C876.343 91.905 876.331 92.213 876.331 92.521C876.331 142.958 876.327 193.396 876.346 243.833C876.347 245.979 876.516 248.129 876.7 250.269C876.975 253.47 878.47 256.101 880.71 258.349C885.47 263.127 895.051 264.715 901.883 261.793C908.703 258.876 911.173 253.488 911.109 246.32C910.961 229.868 911.062 213.413 911.062 196.96C911.062 195.503 911.062 194.047 911.062 192.591C916.036 199.296 921.814 204.591 929.032 208.35C950.189 219.368 988.504 217.239 1005.51 183.806C1017.63 159.982 1017.7 135.332 1008.3 110.646ZM976.623 165.674C971.926 179.213 960.924 187.712 944.259 187.265C929.163 186.859 918.652 179.418 913.672 165.183C911.196 158.105 910.717 150.788 910.401 143.983C910.768 134.48 911.563 125.628 915.665 117.449C921.115 106.584 930.053 100.865 942.109 100.251C961.158 99.281 973.381 108.381 977.903 127.054C981.034 139.982 981.019 153.006 976.623 165.674Z"
          fill={color}
        />
        <path
          d="M837.719 225.183C835.048 225.908 832.567 227.153 830.065 228.357C817.163 234.567 803.959 239.235 789.196 236.952C778.488 235.296 769.998 230.619 764.967 220.714C762.295 215.454 760.929 209.787 760.191 203.981C759.726 200.321 759.847 200.269 763.785 200.264C776.745 200.246 789.706 200.257 802.666 200.257C815.318 200.257 827.97 200.285 840.621 200.247C848.817 200.223 852.066 197.095 852.245 188.845C852.368 183.153 851.941 177.469 850.842 171.866C847.294 153.777 838.437 139.25 821.965 130.119C810.988 124.034 798.971 122.419 786.62 123.318C761.421 125.152 743.044 137.265 732.388 160.247C725.03 176.117 724.102 192.96 727.007 209.904C731.119 233.896 744.372 251.232 767.651 259.287C793.533 268.242 818.626 264.781 842.437 251.514C844.197 250.533 845.671 249.166 847.055 247.707C851.59 242.925 852.508 235.989 849.36 230.236C846.822 225.599 842.819 223.799 837.719 225.183ZM782.719 148.716C797.17 144.551 810.973 149.431 817.702 161.204C820.811 166.643 821.92 172.647 822.399 178.837C822.577 181.136 821.921 181.746 819.676 181.715C811.658 181.601 803.638 181.671 795.618 181.671C794.076 181.671 792.534 181.671 790.991 181.671C781.583 181.671 772.176 181.684 762.768 181.655C761.516 181.651 760.086 182.191 760.025 179.791C759.688 166.472 769.857 152.422 782.719 148.716Z"
          fill={color}
        />
        <path
          d="M753.674 296.07C753.683 294.568 753.359 293.296 751.88 292.663C750.453 292.053 748.911 292.218 748.211 293.599C746.978 296.03 746.024 294.446 744.758 293.73C738.884 290.409 732.068 292.83 729.516 299.063C727.465 304.071 728.756 310.812 732.378 314.01C736.127 317.321 741.298 317.284 747.04 313.785C748.784 319.74 744.162 322.836 738.475 321.611C736.69 321.227 735 320.42 733.251 319.854C732.164 319.502 731.1 319.443 730.497 320.697C729.974 321.785 730.172 322.823 730.964 323.681C731.813 324.601 732.949 325.114 734.115 325.522C737.845 326.828 741.694 326.935 745.493 326.13C750.533 325.062 753.456 321.589 753.598 316.337C753.78 309.585 753.634 302.826 753.674 296.07ZM741.046 311.684C737.153 311.611 734.97 309.118 734.891 304.655C734.809 300.02 737.123 297.139 741.004 297.043C744.826 296.949 747.403 299.903 747.419 304.397C747.434 309.029 745.047 311.759 741.046 311.684Z"
          fill={color}
        />
        <path
          d="M556.912 282.199C552.616 281.966 548.298 282.168 543.992 282.068C541.06 281.999 539.78 283.311 539.826 286.213C539.896 290.661 539.844 295.111 539.844 299.56C539.843 304.163 539.843 308.765 539.844 313.368C539.844 313.521 539.845 313.675 539.848 313.828C539.892 315.945 540.69 317.404 543.076 317.397C545.427 317.39 546.325 316.037 546.316 313.854C546.306 311.553 546.461 309.241 546.289 306.953C546.101 304.447 546.836 303.276 549.577 303.49C552.021 303.681 554.503 303.587 556.956 303.428C563.424 303.01 567.249 299.031 567.293 292.815C567.333 286.77 563.336 282.547 556.912 282.199ZM558.193 297.906C556.007 298.934 553.695 298.678 552.177 298.699C550.33 298.699 549.255 298.678 548.182 298.704C546.803 298.738 546.274 298.029 546.322 296.729C546.35 295.965 546.196 295.175 546.34 294.438C546.814 292.015 544.842 288.211 547.451 287.388C550.912 286.297 555.071 286.322 558.695 288.056C560.762 289.045 561.104 291.026 561.092 293.136C561.08 295.343 560.243 296.941 558.193 297.906Z"
          fill={color}
        />
        <path
          d="M582.82 292.305C575.186 292.235 570.317 296.928 570.186 304.482C570.047 312.548 574.591 317.397 582.376 317.49C589.949 317.58 594.916 312.867 595.146 305.372C595.383 297.661 590.396 292.374 582.82 292.305ZM587.473 310.334C586.07 312.644 583.818 312.948 581.412 312.639C579.036 312.334 577.474 310.913 576.892 308.649C576.396 306.722 576.262 304.732 576.615 302.721C577.251 299.097 579.031 297.211 582.165 297C585.354 296.784 587.851 298.822 588.6 302.33C588.791 303.222 588.789 304.155 588.784 304.099C588.895 306.958 588.447 308.731 587.473 310.334Z"
          fill={color}
        />
        <path
          d="M970.177 292.306C962.486 292.214 957.503 297.096 957.423 304.802C957.341 312.631 962.094 317.478 969.887 317.514C977.625 317.549 982.362 312.743 982.358 304.862C982.354 297.288 977.605 292.394 970.177 292.306ZM975.9 307.531C975.136 311.517 972.015 313.522 968.087 312.55C965.217 311.84 963.747 309.211 963.774 304.633C963.854 303.316 963.8 301.813 964.56 300.37C965.862 297.897 967.882 296.803 970.579 297.045C974.819 297.426 976.999 301.794 975.9 307.531Z"
          fill={color}
        />
        <path
          d="M857.899 292.306C850.04 292.135 845.168 296.838 845.046 304.712C844.924 312.56 849.363 317.333 856.95 317.512C864.875 317.699 869.68 313.113 869.867 305.184C870.045 297.629 865.247 292.466 857.899 292.306ZM861.917 310.862C860.25 312.774 856.532 313.36 854.289 312.064C852.356 310.948 851.098 308.032 851.158 304.425C851.44 302.024 851.7 299.244 854.565 297.712C857.135 296.338 860.253 296.956 862.043 299.196C864.308 302.031 864.242 308.196 861.917 310.862Z"
          fill={color}
        />
        <path
          d="M921.248 307.492C921.243 307.492 921.238 307.492 921.233 307.492C921.233 305.346 921.291 303.199 921.219 301.055C921.116 297.984 920.025 295.392 917.291 293.696C913.681 291.456 905.179 292.001 901.864 294.635C900.606 295.634 900.1 296.813 900.932 298.224C901.735 299.586 902.996 299.174 904.189 298.754C905.344 298.347 906.515 297.981 907.667 297.565C909.448 296.921 911.239 296.666 913.052 297.399C914.93 298.158 915.436 299.863 915.292 301.543C915.138 303.344 913.406 302.585 912.321 302.736C909.888 303.074 907.39 303.099 905.013 303.655C902.573 304.226 900.379 305.441 899.907 308.306C899.39 311.442 900.185 314.227 902.927 315.949C906.451 318.162 910.16 318.313 913.672 315.763C914.605 315.086 915.157 314.324 915.834 316.077C916.445 317.66 918.083 317.562 919.438 317.217C921.081 316.798 921.277 315.394 921.256 313.928C921.225 311.784 921.248 309.637 921.248 307.492ZM914.36 310.699C913.511 312.056 912.182 312.824 910.587 313.078C908.234 313.452 905.998 312.024 905.773 309.947C905.507 307.49 907.323 306.857 909.152 306.378C910.03 306.148 910.979 306.189 911.853 306.112C913.05 306.336 914.676 305.537 915.253 306.808C915.816 308.047 915.094 309.526 914.36 310.699Z"
          fill={color}
        />
        <path
          d="M660.196 310.975C658.907 311.455 657.687 312.179 656.364 312.499C650.912 313.818 648.234 312.013 647.291 306.156C651.769 306.156 656.187 306.1 660.602 306.181C662.673 306.219 663.728 305.549 663.777 303.324C663.9 297.733 659.643 292.831 654.081 292.325C648.289 291.798 643.614 294.645 641.742 299.839C639.581 305.836 641.363 312.416 645.987 315.505C650.174 318.303 658.771 318.119 662.156 315.159C662.272 315.058 662.394 314.962 662.495 314.848C663.36 313.872 663.945 312.85 663.06 311.568C662.293 310.453 661.249 310.584 660.196 310.975ZM652.64 296.774C655.015 296.697 656.802 297.524 657.756 299.801C658.453 301.465 658.644 302.783 656.133 302.733C653.256 302.675 650.378 302.72 647.08 302.72C647.864 298.926 649.276 296.883 652.64 296.774Z"
          fill={color}
        />
        <path
          d="M1059.21 12.558C1055.2 12.558 1051.18 12.558 1047.17 12.558C1046.44 14.05 1044.82 13.82 1043.62 14.252C1030 19.119 1021.54 32.533 1023.23 46.732C1024.85 60.326 1036.25 71.691 1049.93 73.35C1064.11 75.07 1077.69 66.564 1082.53 53.041C1082.96 51.84 1082.68 50.207 1084.21 49.489C1084.21 45.488 1084.21 41.487 1084.21 37.487C1083.3 24.866 1071.87 13.459 1059.21 12.558ZM1053.54 69.023C1038.94 69.011 1027.58 57.578 1027.6 42.914C1027.62 28.352 1039.08 17.012 1053.77 17.029C1068.37 17.045 1079.72 28.475 1079.7 43.14C1079.68 57.709 1068.23 69.035 1053.54 69.023Z"
          fill={color}
        />
        <path
          d="M1066.39 33.545C1065.08 28.821 1060.73 25.686 1055.21 25.561C1052.59 25.502 1049.97 25.597 1047.35 25.532C1045.9 25.496 1045.02 25.783 1045.03 27.513C1045.07 37.814 1045.06 48.115 1045.03 58.415C1045.02 59.995 1045.62 60.532 1047.22 60.543C1048.99 60.556 1049.21 59.648 1049.19 58.247C1049.15 55.634 1049.38 53 1049.12 50.412C1048.86 47.689 1050.18 47.392 1052.38 47.44C1054 47.475 1054.86 48.173 1055.68 49.432C1057.69 52.519 1059.91 55.474 1061.9 58.573C1063 60.273 1063.88 59.75 1065.23 58.898C1066.89 57.853 1066.18 57.029 1065.43 55.949C1063.41 53.056 1061.45 50.122 1059.41 47.121C1060.07 46.693 1060.72 46.293 1061.35 45.861C1065.88 42.749 1067.71 38.272 1066.39 33.545ZM1055.86 43.019C1049.17 43.55 1049.17 43.55 1049.17 36.255C1049.17 29.505 1049.17 29.505 1055.96 30.046C1059.94 30.363 1062.23 32.751 1062.21 36.554C1062.18 40.258 1059.78 42.708 1055.86 43.019Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
YuvipepLogo.propTypes = {
  color: PropTypes.string
};
YuvipepLogo.defaultProps = {
  color: '#000000'
};
