import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePostApiCall } from '../../adapters';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { BulkOrderTypes } from '../../reducers/cart/bulkOrder';

function* postBulkOrdet(payload) {
  try {
    yield put({ type: BulkOrderTypes.ADD_BULK_ORDER_LIST_INDICATOR });
    const { params } = payload;
    const response = yield call(makePostApiCall, URLS.API_POST_BULK_ORDER, params);
    if (response.status === 200) {
      yield put({
        type: BulkOrderTypes.ADD_BULK_ORDER_LIST_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: BulkOrderTypes.ADD_BULK_ORDER_LIST_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: BulkOrderTypes.ADD_BULK_ORDER_LIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* verifyPinCodeAddress(payload) {
  try {
    yield put({ type: BulkOrderTypes.SHIPPING_VERIFY_PINCODE_INDICATOR });
    const params = {
      pincode: payload.pincode
    };
    const API_URL = `${URLS.API_ADDRESS_PINCODE}`;
    const response = yield call(makePostApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: BulkOrderTypes.SHIPPING_VERIFY_PINCODE_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: BulkOrderTypes.SHIPPING_VERIFY_PINCODE_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: BulkOrderTypes.SHIPPING_VERIFY_PINCODE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* bulkOrderSaga() {
  try {
    yield all([
      takeLatest(BulkOrderTypes.ADD_BULK_ORDER_LIST, postBulkOrdet),
      takeLatest(BulkOrderTypes.SHIPPING_VERIFY_PINCODE, verifyPinCodeAddress)
    ]);
  } catch (ex) {
    console.warn('Error while Faq Saga: faqSaga', ex);
  }
}
export default bulkOrderSaga;
