/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { connect, useSelector } from 'react-redux';
import Add from '../../assets/svgs/Add';
import Remove from '../../assets/svgs/Remove';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));
const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
  backgroundColor: 'rgba(0, 0, 0, .03)'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

function QuestionAnswer() {
  const [expanded, setExpanded] = React.useState(false);
  const { fetchFaq } = useSelector((state) => state);
  const { activeFaq = {} } = fetchFaq;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderAccordion = (title, answer) => (
    <div className="app-question-accordion">
      <Accordion expanded={expanded === title} onChange={handleChange(title)}>
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          expandIcon={expanded === title ? <Remove /> : <Add />}>
          <div className="question-text">{title}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="answer-text">{answer}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
  return (
    <div>
      <div className="app-question-container">
        <div className="section-title">{activeFaq.name}</div>
        <div className="app-question-accordion-section">
          {activeFaq.faqs &&
            activeFaq.faqs.length > 0 &&
            activeFaq.faqs.map((item) => renderAccordion(item.question, item.answer))}
        </div>
      </div>
    </div>
  );
}
// QuestionAnswer.propTypes = {
//   setActiveData: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => {
  const { fetchFaq } = state;
  return { fetchFaq };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer);
