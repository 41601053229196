import mirrorKeyValue from 'mirror-key-value';

/* ------------- Types ------------- */

export const couponTypes = mirrorKeyValue([
  'FETCH_COUPON_LIST',
  'FETCH_COUPON_LIST_INDICATOR',
  'FETCH_COUPON_LIST_SUCCESS',
  'FETCH_COUPON_LIST_FAILED'
]);

const {
  FETCH_COUPON_LIST,
  FETCH_COUPON_LIST_INDICATOR,
  FETCH_COUPON_LIST_SUCCESS,
  FETCH_COUPON_LIST_FAILED
} = couponTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  couponData: {},
  error: false,
  errorMsg: ''
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case FETCH_COUPON_LIST:
      return { ...state, ...data };
    case FETCH_COUPON_LIST_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_COUPON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        couponData: action.response,
        error: false
      };
    case FETCH_COUPON_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        couponData: action.response
      };

    default:
      return state;
  }
}
