import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProductDetailLoader from '../product/productDetail/Loader';
import Error from '../../components/multicellular/Error/Error';
import String from '../../constants/strings';
import { InfoTypes } from '../../reducers/info/information';
import STORAGE_KEYS from '../../constants/storageKey';
import { CartTypes } from '../../reducers/cart/cart';
import { AddressTypes } from '../../reducers/account/address/address';

import Hero from './Hero';
import './Style.scss';
import Description from './Description';
import FAQs from './Faqs';
import About from './About';
import Details from './Details';
import Breadcrumb from './Breadcrumb';
import Courses from './Courses';
import Ratings from './Ratings';
import Community from './Community';
import Meta from './Meta';
import BulkOrderBanner from './BulkOrderBanner';
import NewChapters from './NewChapters';
import { trackEvent, trackPageView } from '../../analytics/googleAnalytics';
import { LoginTypes } from '../../reducers/auth/login/login';

function Book(props) {
  const { callProductApi, getUserCart, getAddressApi, getUserInfo } = props;
  const {
    information,
    login,
    login: { user: { mobile } = {} }
  } = useSelector((state) => state);
  const { loading, error, errorMsg } = information;

  const params = useParams();
  const { id } = params;
  const { isUserLogin } = login;

  const location = useLocation();

  useEffect(() => {
    if (isUserLogin) {
      getUserInfo();
    }
    trackPageView(location.pathname);
  }, [location, isUserLogin]);

  // Check if user is logged in then get the user name from local storage otherwise set it as User
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
  const userDetails =
    userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;

  useEffect(() => {
    const handleScroll = () => {
      trackEvent('Scroll', 'Page Scroll', `${userDetails}, scrolled the page ${location.pathname}`);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mobile]);

  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
    getUserCart();
    callProductApi(id, userId);
    getAddressApi(userId);
  }, [isUserLogin, id]);
  return (
    <div>
      {loading ? (
        <ProductDetailLoader value={String.LOADING} />
      ) : (
        <div>
          {error ? (
            <Error description={String.SERVER_ERROR_TRY_LATER} title={errorMsg} />
          ) : (
            <div className="app-book-wrapper">
              <div className="app-primary-space" />

              <Meta />
              <div className="container my-5">
                <Breadcrumb />
                <div className="grid-view-hero-section gap-5">
                  <Details />
                  <Hero />
                </div>
                <Description />
                <BulkOrderBanner />
                {/* <Chapters /> */}
                <NewChapters />
                <About />
                <Courses />
                <section id="ratings">
                  <Ratings />
                </section>
                {/* <Testimonials /> */}
                <Community />
                <FAQs />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Book.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  callProductApi: PropTypes.func.isRequired,
  getUserCart: PropTypes.func.isRequired,
  getAddressApi: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { information, login, rating } = state;
  return { information, login, rating };
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH }),
  callProductApi: (id, userId) =>
    dispatch({
      type: InfoTypes.FETCH_PRODUCT,
      id,
      userId
    }),
  getAddressApi: (userId) => dispatch({ type: AddressTypes.GET_ADDRESS, userId }),
  getUserCart: () => {
    dispatch({ type: CartTypes.GET_USER_CART_FETCH });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Book);
