import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Key from '../../../assets/svgs/Key';
import Mail from '../../../assets/svgs/Mail';
import Mobile from '../../../assets/svgs/Mobile';
import InputField from '../../../components/unicellular/inputField';
import Button from '../../../components/unicellular/button';
import AuthString from '../../../constants/strings/auth/Auth';
import {
  validateLoginFormWithEmail,
  validateLoginFormWithMobile,
  validateLoginFormWithMobileOtp
} from '../FormValidation';
import { LoginTypes } from '../../../reducers/auth/login/login';
import Otp from '../otp';
import SetPassword from '../setPassword';
import { LoginWithMobileTypes } from '../../../reducers/auth/login/loginWithMobile';
import Verify from '../../../constants/verify';
import './Login.scss';
import { OtpTypes } from '../../../reducers/auth/otp/otp';
import { forgetPasswordTypes } from '../../../reducers/auth/login/forgetPassword';
import PlaceholderString from '../../../constants/strings/PlaceholderString';

const Alert = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

function Login(props) {
  const [userId, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setError] = useState('');
  const [isForgot, setisForgot] = useState(false);
  const [isLoginWithMobileLoading, setisLoginWithMobileLoading] = useState(false);
  const {
    onClickSignUp,
    callUserLogin,
    callUserLoginWithMobile,
    resetLoginWithMobile,
    resetOtpReducer,
    resetPasswordReducer,
    callResetForm,
    callEmailOtpVerificationApi
  } = props;
  const { login, loginWithMobile, forgetPassword } = useSelector((state) => state);
  const { error, errorMsg, loading } = login;
  const { isSuccess, forgetPasswordData } = forgetPassword;
  const { message = '' } = forgetPasswordData;
  const { lmerror, lmerrorMsg, isOtpSent, lmloading } = loginWithMobile;

  const resetError = () => {
    setError('');
    callResetForm();
    resetLoginWithMobile();
  };
  const handleOtpSend = useCallback(() => {
    resetLoginWithMobile();
    resetOtpReducer();
    setisForgot(false);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setisForgot(false);
      setInterval(() => {
        resetPasswordReducer();
      }, 3000);
    }
    if (isOtpSent) {
      setisLoginWithMobileLoading(false);
    }
  }, [isSuccess, isOtpSent]);

  const isValidate = useCallback(() => {
    let errors = '';
    const re = /^[0-9\b]+$/;
    if (re.test(userId)) {
      errors = validateLoginFormWithMobile(userId, password);
    } else {
      errors = validateLoginFormWithEmail(userId, password);
    }
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  }, [userId, password]);
  const isValidateForgetPassword = useCallback(() => {
    let errors = '';
    const re = /^[0-9\b]+$/;
    if (re.test(userId)) {
      errors = validateLoginFormWithMobileOtp(userId);
    }
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  }, [userId]);

  const isValidateOnlyMobile = () => {
    let errors = '';
    const re = /^[0-9\b]+$/;
    if (re.test(userId)) {
      errors = validateLoginFormWithMobileOtp(userId);
    } else {
      errors = Verify.VERIFY_ONLY_MOBILE_ACCEPTED;
    }
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    if (valid) {
      callUserLogin(userId, password);
      setError('');
    }
  };
  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      const valid = isValidate();
      if (valid) {
        callUserLogin(userId, password);
        setError('');
      }
    }
  };
  const handleKeypressForgetPassword = (event) => {
    if (event.key === 'Enter') {
      const valid = isValidateForgetPassword();
      if (valid) {
        const re = /^[0-9\b]+$/;
        if (re.test(userId)) {
          callUserLoginWithMobile(userId);
        } else {
          callEmailOtpVerificationApi(userId, true);
        }
        setError('');
      }
    }
  };
  const submitFormwithMobile = () => {
    const valid = isValidateOnlyMobile();
    if (valid) {
      callUserLoginWithMobile(userId);
      setError('');
    }
    setisLoginWithMobileLoading(true);
  };
  const handleForgetPassword = () => {
    const valid = isValidateForgetPassword();
    if (valid) {
      const re = /^[0-9\b]+$/;
      if (re.test(userId)) {
        callUserLoginWithMobile(userId);
      }
      setError('');
    }
  };
  const handleClickForget = () => {
    setisForgot(true);
    resetError();
    const re = /^[0-9\b]+$/;
    if (!re.test(userId)) {
      setEmail('');
    }
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    resetError();
  };
  const handleMobile = (e) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setEmail(e.target.value);
      resetError();
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    resetError();
  };
  const renderFormForgetPassword = () => (
    <>
      <div className="app-auth-login-input-flield">
        <InputField
          isIcon
          icon={<Mobile />}
          maxLength={10}
          value={userId}
          onChange={handleMobile}
          onKeyPress={handleKeypressForgetPassword}
          placeholder={PlaceholderString.MOBILE_NO}
        />
        {err !== '' && <div className="app-auth-error">{err}</div>}
        {err === '' && error && <div className="app-auth-error">{errorMsg}</div>}
        {err === '' && lmerror && <div className="app-auth-error">{lmerrorMsg}</div>}
      </div>
      <div className="app-auth-action-button">
        <Button
          buttonText={AuthString.PROCEED}
          className="app-auth-login-btn"
          onClick={handleForgetPassword}
          loading={lmloading}
        />
      </div>
    </>
  );
  const renderForm = () => (
    <>
      <div className="app-auth-login-input-flield">
        <InputField
          isIcon
          icon={<Mail />}
          maxLength={250}
          value={userId}
          onChange={handleEmail}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.LOGIN_USER_ID_INPUT}
        />
      </div>
      <div className="app-auth-login-input-flield">
        <InputField
          isIcon
          icon={<Key />}
          value={password}
          type="password"
          onChange={handlePassword}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.PASSWORD}
        />
        {err !== '' && <div className="app-auth-error">{err}</div>}
        {err === '' && error && <div className="app-auth-error">{errorMsg}</div>}
        {err === '' && lmerror && <div className="app-auth-error">{lmerrorMsg}</div>}
      </div>
      <div className="app-auth-action-link">
        <button type="button" className="link-btn" onClick={handleClickForget}>
          {AuthString.FORGET_PASSWORD}
        </button>
      </div>
      <div className="app-auth-action-button">
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.LOGIN_WITH_OTP}
            className="app-auth-otp-btn"
            onClick={submitFormwithMobile}
            loading={lmloading && isLoginWithMobileLoading}
            spinnerColor="primary"
          />
        </div>
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.LOGIN}
            className="app-auth-login-btn"
            onClick={submitForm}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
  const renderFooterSection = () => (
    <div className="app-bottom-text-container">
      <div className="app-auth-bottom-link">
        {AuthString.LOGIN_MODAL_BOTTOM_SWITCH_TEXT}
        <span>
          <button type="button" onClick={onClickSignUp}>
            {AuthString.SIGN_UP}
          </button>
        </span>
      </div>
    </div>
  );

  const renderFooterSectionForgetPassword = () => (
    <div className="app-bottom-text-container">
      <div className="app-auth-bottom-link">
        {AuthString.RETURN_TO_LOGIN}
        <span>
          <button
            type="button"
            onClick={() => {
              setisForgot(false);
              resetError();
            }}>
            {AuthString.SIGN_IN}
          </button>
        </span>
      </div>
    </div>
  );
  return (
    <div className="app-auth-login-container">
      {isOtpSent ? (
        (isForgot && <SetPassword userId={userId} onClickResend={handleOtpSend} />) || (
          <Otp mobile={userId} onClickResend={handleOtpSend} />
        )
      ) : (
        <>
          {isForgot ? renderFormForgetPassword() : renderForm()}
          {isForgot ? renderFooterSectionForgetPassword() : renderFooterSection()}
          <Snackbar
            open={isSuccess}
            autoHideDuration={50}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Alert severity="success" sx={{ width: '100%' }}>
              <div className="alet-message-success">{message}</div>
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}
Login.propTypes = {
  onClickSignUp: PropTypes.func.isRequired,
  resetLoginWithMobile: PropTypes.func.isRequired,
  callUserLogin: PropTypes.func.isRequired,
  callUserLoginWithMobile: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetPasswordReducer: PropTypes.func.isRequired,
  callEmailOtpVerificationApi: PropTypes.func.isRequired,
  callResetForm: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { login, loginWithMobile, forgetPassword } = state;
  return { login, loginWithMobile, forgetPassword };
};

const mapDispatchToProps = (dispatch) => ({
  callUserLogin: (userId, password) =>
    dispatch({
      type: LoginTypes.GET_LOGIN_USER,
      userId,
      password
    }),
  callResetForm: () =>
    dispatch({
      type: LoginTypes.RESET_LOGIN_FORM
    }),
  callEmailOtpVerificationApi: (email, isForgot) =>
    dispatch({
      type: LoginWithMobileTypes.SEND_EMAIL_OTP,
      email,
      isForgot
    }),
  callUserLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE,
      mobile
    }),
  resetLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW,
      mobile
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetPasswordReducer: () =>
    dispatch({
      type: forgetPasswordTypes.RESET_FORGET_PASSWORD
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
