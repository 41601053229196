import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import navLinks from '../../../../config/navigationBar/links';
import User from '../../../../assets/svgs/User';
import STORAGE_KEYS from '../../../../constants/storageKey';
import '../header/Header.scss';
import './Styles.scss';
import RoutesPaths from '../../../../constants/routes';

const checkName = (name) => {
  if (name.length > 12) {
    return `${name.substring(0, 12)}...`;
  }
  return name;
};
const userLogin = (isUserLogin, userName, onClick, handleLogout, cartLabel) =>
  isUserLogin ? (
    <Dropdown className="user-dropdown">
      <Dropdown.Toggle id="user-dropdown-btn">
        <User color="#000" />
        <div className="nav-user-name">
          {userName !== ''
            ? checkName(userName)
            : checkName(`${localStorage.getItem(STORAGE_KEYS.USER_NAME)}`)}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="user-dropdown-inset">
        <Dropdown.Item className="app-user-dropdown-item">
          <NavLink to={RoutesPaths.DIRECT_TO_ADDRESS}>My Account</NavLink>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="app-user-dropdown-item">
          <NavLink to={RoutesPaths.CART}>{cartLabel}</NavLink>
        </Dropdown.Item>
        <Dropdown.Divider />
        <button type="button" onClick={handleLogout} className="app-logout-btn">
          <Dropdown.Item className="app-user-dropdown-item">Logout</Dropdown.Item>
        </button>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <button type="button" className="nav-links-btn" onClick={onClick}>
      <User color="#000" />
      <div className="nav-links-txt">Login</div>
    </button>
  );

function NavBar(props) {
  const { onClick, userName, isUserLogin, handleLogout, cartLabel, isHideLogin } = props;
  return (
    <div className="app-nav-bar">
      <div className="container">
        <div className="inner-flex">
          <div className="logo">
            Welcome to <span className="red">Yuvi</span>
            <span>Pep</span> Store
          </div>
          <div className="nav-links-container">
            {navLinks?.map(
              (item) =>
                (item?.isExternal && (
                  <a
                    href={item?.value}
                    className="nav-links"
                    key={item?.key}
                    target="_blank"
                    rel="noreferrer">
                    {' '}
                    {item?.title}
                  </a>
                )) || (
                  <NavLink to={item.value} key={item?.key}>
                    <div className="nav-links">{item?.title}</div>
                  </NavLink>
                )
            )}
            {isUserLogin && (
              <NavLink to={`/account/${RoutesPaths.ORDERS}`}>
                <div className="nav-links">Track Orders</div>
              </NavLink>
            )}
            {isHideLogin
              ? null
              : userLogin(isUserLogin, userName, onClick, handleLogout, cartLabel)}
          </div>
        </div>
      </div>
    </div>
  );
}
NavBar.propTypes = {
  onClick: PropTypes.func.isRequired,
  isUserLogin: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  cartLabel: PropTypes.string.isRequired,
  isHideLogin: PropTypes.bool
};

NavBar.defaultProps = {
  isHideLogin: false
};

export default NavBar;
