import React from 'react';
import Card from '../../../../components/multicellular/order/trackCard';
import './Styles.scss';

export default function Loader() {
  return (
    <div className="container">
      <div className="app-breadcrumb-wrapper ">
        <div className="track-loader-breadcrumb" />
      </div>
      <div className="track-load-height">
        <div className="row">
          <div className="col-md-4">
            <Card>
              <div className="app-track-card">
                <div className="track-loader-title" />
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <div className="app-track-card">
                <div className="track-loader-title" />
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <div className="app-track-card">
                <div className="track-loader-title" />
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="track-loader-breadcrumb" />
          </div>
          <div className="col-md-6">
            <div className="track-loader-title" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6">
            <div className="track-loader-breadcrumb" />
          </div>
        </div>
      </div>
    </div>
  );
}
