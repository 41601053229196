import React from 'react';
import { NavLink } from 'react-router-dom';
import RoutesPaths from '../../constants/routes';

import './Faq.scss';

function SectionHeader() {
  return (
    <div className="app-faq-header-layout">
      <div className="container">
        <div className="app-faq-header-inner-layout">
          <div className="app-faq-page-title">How may we help you?</div>
          <div className="app-faq-contact-us-container">
            <div className="text">Still need help?</div>
            <NavLink to={RoutesPaths.CONTACT_US}>
              <button type="button" className="contact-us-btn">
                {' '}
                Contact Us
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;
