import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DownArrow from '../../../assets/svgs/DownArrow';

import './SelectDropdown.scss';

function DropdownIndicator() {
  return (
    <components.DropdownIndicator>
      <div className="selected-dropdown-icon">
        <DownArrow />
      </div>
    </components.DropdownIndicator>
  );
}
const customDateField = {
  valueContainer: (provided) => ({
    ...provided,
    height: '40px',
    padding: 0
  }),
  control: (provided) => ({
    ...provided,
    padding: '0 0 0 10px',
    border: '1px solid #a5a3a3',
    width: '110px',
    fontSize: '14px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#666262'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#333',
    fontWeight: '500',
    backgroundColor: state.isSelected ? '#999999' : null,
    textTransform: 'uppercase'
  })
};
const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: 0
  }),
  control: (provided) => ({
    ...provided,
    padding: '0 0 0 15px ',
    border: '1px solid rgba(136, 152, 170, 0.5)',
    minHeight: '30px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#8898AA'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    color: state.isSelected ? '#fff' : '#333',
    fontWeight: '500',
    backgroundColor: state.isSelected ? '#999999' : null
  })
};
const customStylesOrder = {
  valueContainer: (provided) => ({
    ...provided,
    height: '40px',
    padding: 0,
    minWidth: '120px'
  }),
  control: (provided) => ({
    ...provided,
    padding: '0 0 0 15px ',
    border: '1px solid rgba(136, 152, 170, 0.5)',
    minHeight: '50px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#8898AA'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    color: state.isSelected ? '#fff' : '#333',
    fontWeight: '500',
    backgroundColor: state.isSelected ? '#999999' : null
  })
};
function CreatableDropdown(props) {
  const {
    mainClass,
    isIndicatorHide,
    placeholder,
    options,
    isSortView,
    onChange,
    isStyled,
    isStyledMonth,
    isClearable,
    isSearchable,
    value,
    defaultValue,
    isDisabled,
    closeMenuOnSelect,
    isStyledOrder,
    isValidNewOption
  } = props;
  return (
    <div className={mainClass}>
      <CreatableSelect
        closeMenuOnSelect={closeMenuOnSelect}
        isValidNewOption={isValidNewOption}
        components={isIndicatorHide ? {} : DropdownIndicator()}
        placeholder={placeholder || 'Select Options *'} // change the default text to Select *
        options={options}
        className={`select-dropdown-view ${isSortView ? 'is-sortting-view' : ''}`}
        isMulti={false}
        onChange={onChange} // onChange name change
        styles={
          (isStyled && customStyles) ||
          (isStyledMonth && customDateField) ||
          (isStyledOrder && customStylesOrder)
        }
        isClearable={!!isClearable}
        isSearchable={!!isSearchable}
        value={value}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default CreatableDropdown;
CreatableDropdown.propTypes = {
  mainClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  isIndicatorHide: PropTypes.bool,
  isSortView: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isStyled: PropTypes.bool,
  isValidNewOption: PropTypes.bool,
  isStyledMonth: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isStyledOrder: PropTypes.bool
};
CreatableDropdown.defaultProps = {
  mainClass: '',
  placeholder: '',
  isIndicatorHide: false,
  isSortView: false,
  isClearable: false,
  isSearchable: false,
  isStyled: false,
  isStyledMonth: false,
  defaultValue: {},
  isDisabled: false,
  closeMenuOnSelect: false,
  isStyledOrder: false,
  isValidNewOption: true
};
