/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import KitCard from '../../components/multicellular/products/kitCardV2';
import Button from '../../components/unicellular/button';
import RoutesPaths from '../../constants/routes';
import String from '../../constants/strings';
import './Home.scss';
import STORAGE_KEYS from '../../constants/storageKey';
import { CartTypes } from '../../reducers/cart/cart';
import createSlug from '../../utils/conversions/createSlug';

const initialState = {
  responsive: {
    0: { items: 1 },
    550: { items: 2 },
    600: { items: 2 },
    1000: { items: 4 },
    1400: { items: 4 }
  }
};

function KitCarousel(props) {
  const [activeProductId, setActiveProductId] = useState([]);
  const [activeId, setActiveId] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state);
  const { isSuccess } = cart;
  const navigate = useNavigate();
  const { title, products, loading, category, bgImage, callAddToCartApi } = props;
  const handleCart = (id) => {
    sessionStorage.removeItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED);
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const arr = activeProductId;
    setActiveId(id);
    arr.push(id);
    setActiveProductId([...arr]);
    callAddToCartApi(userId, id, 1, null);
  };
  const handleRedirection = (product) => {
    navigate(
      `${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product?.name)}/${product._id}`
    );
  };
  useEffect(() => {
    setActiveProductId([]);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setIsAdded(true);
      dispatch({ type: CartTypes.RESET_CART });
    }
  }, [isSuccess]);
  useEffect(() => {
    let timeout;
    if (isAdded) {
      timeout = setTimeout(() => {
        setIsAdded(false);
        setActiveId('');
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isAdded]);
  return (
    <div className="app-kit-carousel-container" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="container">
        <div className="app-section-head">
          <div className="title">{loading ? '' : title}</div>
          {category && category.length !== '' && (
            <div className="wb-view">
              <NavLink
                className="app-header-dropdown-item"
                to={`${RoutesPaths.PRODUCT_EXPLORER}/${category}`}>
                <Button buttonText={String.BUTTON_VIEWALL} className="app-view-all-btn" />
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="container wb-view">
        {loading ? (
          <div className="col-sm-4">
            <KitCard loading={loading} />
          </div>
        ) : (
          <OwlCarousel
            className="owl-theme"
            navText={[
              "<div className='nav-btn prev-slide'> <span class=' nav-icon-module-left'></span></div>",
              "<div className='nav-btn next-slide'><span class=' nav-icon-module-right'></span></div>"
            ]}
            loop={false}
            margin={10}
            nav
            responsive={initialState.responsive}
            dotsClass="hidden-owl-dots">
            {products.length > 0 &&
              products.map((product) => (
                <div key={product._id} className="app-home-kit-section">
                  <KitCard
                    title={product.name}
                    rating={product.avgRating}
                    price={product.price}
                    isFreeDelivery={product.isFreeDelivery}
                    discount={product.discount}
                    description={product.description}
                    image={product.images ? product.images[0] : ''}
                    productId={product._id}
                    wishlist={product.wishlist}
                    isWishlisted={product.isWishlisted}
                    calculatedPrices={product.calculatedPrices}
                    onClickCart={() => handleCart(product._id)}
                    onClickBuyNow={() => handleRedirection(product)}
                    // isAddedToCart={
                    //   activeProductId.includes(product._id)
                    //   || product.isAddedToCart
                    // }
                    isAddedToCart={isAdded && product._id === activeId}
                    loadingCart={cart?.loading && product._id === activeId}
                  />
                </div>
              ))}
          </OwlCarousel>
        )}
      </div>
      <div className="container mb-view">
        <div className="row">
          {loading ? (
            <div className="col-sm-4">
              <KitCard loading={loading} />
            </div>
          ) : (
            products.length > 0 &&
            products.map((product) => (
              <div key={product._id} className="col-sm-6 app-home-kit-section">
                <KitCard
                  title={product.name}
                  rating={product.avgRating}
                  price={product.price}
                  isFreeDelivery={product.isFreeDelivery}
                  discount={product.discount}
                  description={product.description}
                  image={product.images ? product.images[0] : ''}
                  productId={product._id}
                  wishlist={product.wishlist}
                  isWishlisted={product.isWishlisted}
                  calculatedPrices={product.calculatedPrices}
                  onClickCart={() => handleCart(product._id)}
                  onClickBuyNow={() => handleRedirection(product)}
                  // isAddedToCart={
                  //   activeProductId.includes(product._id)
                  //   || product.isAddedToCart
                  // }
                  isAddedToCart={isAdded && product._id === activeId}
                  loadingCart={cart?.loading && product._id === activeId}
                  // loadingCart={
                  //   activeProductId.includes(product._id) && cart?.loading
                  // }
                />
              </div>
            ))
          )}
        </div>
        {category && category.length !== '' && (
          <div className="mb-view">
            <div className="view-all-container">
              <NavLink
                className="app-header-dropdown-item"
                to={`${RoutesPaths.PRODUCT_EXPLORER}/${category}`}>
                <Button buttonText={String.BUTTON_VIEWALL} className="app-view-all-btn" />
              </NavLink>
            </div>
          </div>
        )}
      </div>
      {sessionStorage.getItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED) === 'clicked' ? null : (
        <Snackbar
          open={isAdded}
          autoHideDuration={500}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            <div className="alet-message-success">Product has been successfully added to cart!</div>
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

KitCarousel.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array,
  loading: PropTypes.bool,
  category: PropTypes.string,
  bgImage: PropTypes.string,
  callAddToCartApi: PropTypes.func.isRequired
};
KitCarousel.defaultProps = {
  title: '',
  products: [],
  loading: false,
  category: '',
  bgImage: ''
};

const mapStateToProps = (state) => {
  const { information, delivery, rating, cart } = state;
  return {
    information,
    delivery,
    rating,
    cart
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(KitCarousel);
