import React from 'react';

import './ContactUs.scss';

function SectionHeader() {
  return (
    <div className="app-contact-us-header-layout">
      <div className="container">
        <div className="app-contact-us-header-inner-layout">
          <div className="app-contact-us-page-title">How may we help you?</div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;
