import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Breadcrumb() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { name, categoryName, parentId } = {} } = productData;
  return (
    <div className="fs-4 my-5">
      Home {categoryName ? '/' : ''}{' '}
      {categoryName && (
        <Link to={`/category/${parentId}`}>
          <span>{categoryName} </span>
        </Link>
      )}
      / <span className="text-primary-100">{name}</span>{' '}
    </div>
  );
}

export default Breadcrumb;
