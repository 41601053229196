/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import Clock from '../../../assets/svgs/Clock';
import SearchIcon from '../../../assets/svgs/SearchIcon';
import Trending from '../../../assets/svgs/Trending';
import { ProductTypes } from '../../../reducers/product/productList';
import { FilterTypes } from '../../../reducers/filter/filter';
import RoutesPaths from '../../../constants/routes';
import './Style.scss';

// function SearchSuggestion(item) {
//   const { name, categoryName, mongoId } = item;
//   return (
//     <NavLink
//       to={`${RoutesPaths.PRODUCT_DETAIL}/${mongoId}`}
//     >
//       <div className="app-search-suggestion-box">
//         <div className="inner-box">
//           <Clock />
//           <div className="app-suggestion-text">
//             <div>{name}</div>
//             <div className="category-text">
//               in
//               {' '}
//               {categoryName}
//             </div>
//           </div>
//           <Trending />
//         </div>
//       </div>
//     </NavLink>
//   );
// }

function Search(props) {
  const {
    productList: { searchResults = [] }
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');

  const { setSearched, searchProduct, resetInitialFilter } = props;
  const searchWithDebounce = _.debounce((searchQuery) => {
    const encodeText = encodeURIComponent(searchQuery);
    resetInitialFilter();
    navigate(`/category?q=${encodeText}`, { replace: false });
  }, 500);
  return (
    <>
      <div className="web-view-sr-header">
        <div className="app-search-detail-container">
          <div className="container">
            <div className="app-search-na">Not accessible for this screen</div>
          </div>
        </div>
      </div>
      <div className="mb-view-sr-header">
        <div className="app-search-detail-container">
          <div className="app-search-pg-input-field">
            <button
              aria-label="search"
              type="button"
              onClick={() => {
                setSearched(true);
                searchWithDebounce(inputValue);
              }}>
              <SearchIcon />
            </button>
            <input
              type="text"
              placeholder="Search for Products, Categories and More"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearched(true);
                  searchWithDebounce(e.target.value);
                }
              }}
              value={inputValue}
              onChange={(searchValue) => {
                setInputValue(searchValue.target?.value);
                searchProduct(searchValue.target?.value);
              }}
            />
          </div>
          {inputValue.length > 0 && searchResults.length > 0 && (
            <div>
              {/* <div className="other-search">All Others</div> */}
              {searchResults.map(({ _source: item }, index) => (
                <NavLink
                  key={`searchResults-${index.toString()}`}
                  to={`${RoutesPaths.PRODUCT_EXPLORER}?q=${item.value}`}>
                  <div className="app-search-suggestion-box">
                    <div className="inner-box">
                      <Clock />
                      <div className="app-suggestion-text">
                        <div>{item.value}</div>
                      </div>
                      <Trending />
                    </div>
                  </div>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Search.propTypes = {
  searchProduct: PropTypes.func.isRequired,
  setSearched: PropTypes.func.isRequired,
  resetInitialFilter: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { productList } = state;
  return { productList };
};

const mapDispatchToProps = (dispatch) => ({
  searchProduct: (queryString) =>
    dispatch({
      type: ProductTypes.PRODUCT_SEARCH_FETCH,
      queryString
    }),
  resetInitialFilter: () =>
    dispatch({
      type: FilterTypes.RESET_INITIAL_FILTER
    }),
  setSearched: (isSearched) =>
    dispatch({
      type: ProductTypes.SET_SEARCH_PRODUCT,
      isSearched
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
