import mirrorKeyValue from 'mirror-key-value';
import { createActions } from '../../utils/saga/saga-utils';
/* ------------- LoginType ------------- */

export const ProductTypes = mirrorKeyValue([
  'GET_PRODUCT',
  'GET_PRODUCT_INDICATOR',
  'GET_PRODUCT_SUCCESS',
  'GET_PRODUCT_FAILED',
  'GET_PRODUCT_RESET',
  'GET_PRODUCT_SUCCESS_WITH_FILTER',
  'SET_SEARCH_PRODUCT',
  'RESET_PRODUCTS',
  ...createActions('PRODUCT_SEARCH')
]);

const {
  GET_PRODUCT,
  GET_PRODUCT_INDICATOR,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_RESET,
  GET_PRODUCT_SUCCESS_WITH_FILTER,
  SET_SEARCH_PRODUCT,
  RESET_PRODUCTS,
  PRODUCT_SEARCH_FETCH,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAILED
} = ProductTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  loadingShow: false,
  productData: {},
  error: '',
  errorMsg: '',
  productListArr: [],
  isMoreProducts: false,
  previousCategoryId: '',
  isSuccess: false,
  searchLoading: false,
  searchResults: [],
  isSearched: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_PRODUCT:
      return { ...state, ...data };
    case GET_PRODUCT_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_PRODUCT_SUCCESS: {
      const { content: { products, categoryId } = {}, page, totalRecords } = action.response;
      let { productListArr } = state;
      if ((categoryId && state.previousCategoryId !== categoryId) || state.isSearched) {
        productListArr = []; // clear the array.
      }
      if (productListArr.length !== totalRecords || productListArr.length === 0) {
        productListArr = productListArr.concat(products);
      } else {
        productListArr = products;
      }
      return {
        ...state,
        loading: false,
        productData: action.response,
        productListArr,
        isMoreProducts: !(products.length < 15 || totalRecords === 15),
        ...(categoryId && { previousCategoryId: categoryId }),
        error: false,
        isSuccess: true,
        page,
        isSearched: false
      };
    }
    case GET_PRODUCT_SUCCESS_WITH_FILTER:
      return {
        ...state,
        loading: false,
        productData: action.response,
        productListArr: action.response.content.products,
        isMoreProducts: !(
          action.response.content.products.length < 15 || action.response.totalRecords === 15
        ),
        error: false,
        isSuccess: true,
        page: action.response.page
      };
    case GET_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case GET_PRODUCT_RESET:
      return {
        ...state,
        isSuccess: false
      };
    case PRODUCT_SEARCH_FETCH: {
      return { ...state, searchLoading: true };
    }

    case PRODUCT_SEARCH_FAILED: {
      return { ...state, searchLoading: false };
    }

    case PRODUCT_SEARCH_SUCCESS: {
      return { ...state, searchResults: data?.data, searchLoading: false };
    }
    case SET_SEARCH_PRODUCT: {
      return {
        ...state,
        isSearched: action.isSearched,
        previousCategoryId: ''
      };
    }
    case RESET_PRODUCTS: {
      return {
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
}
