import React from 'react';
import './ReviewLoader.scss';

const n = 4;
const rating = 2;
function ProductReviewLoader() {
  return (
    <div>
      <div className="app-rating-banner  ">
        <div>
          <div className="container align-container">
            <div className="row top-space-row">
              <div className="col-md-5">
                <div className="rating-lab-banner-image banner-home-skeleton-bg" />
                <div className="rating-skel-register-button" />
                <div className="row rating-skeleton-box">
                  {[...Array(rating)].map((item) => (
                    <span className="rating-skeleton-loader-description " key={item} />
                  ))}
                </div>
              </div>
              <div className="col-md-6 skel-right-align-center ">
                <div className="row rating-skeleton-box">
                  <div className="rating-skeleton-loader-description " />
                  {[...Array(n)].map((item) => (
                    <span className="rating-skeleton-loader-description " key={item} />
                  ))}
                  <div className="rating-skel-register-button" />
                </div>
                <div className="row rating-skeleton-box">
                  {[...Array(n)].map((item) => (
                    <span className="rating-skeleton-loader-description " key={item} />
                  ))}
                  <div className="rating-skel-register-button" />
                </div>
                <div className="row rating-skeleton-box">
                  {[...Array(n)].map((item) => (
                    <span className="rating-skeleton-loader-description " key={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReviewLoader;
