import mirrorKeyValue from 'mirror-key-value';

export const DeliveryTypes = mirrorKeyValue([
  'ADD_PINCODE',
  'ADD_PINCODE_INDICATOR',
  'ADD_PINCODE_SUCCESS',
  'ADD_PINCODE_FAILED',
  'RESET_PINCODE',
  'RESET_ERROR',
  'RESET_PINCODE_REDUCER'
]);

const {
  ADD_PINCODE,
  ADD_PINCODE_INDICATOR,
  ADD_PINCODE_SUCCESS,
  ADD_PINCODE_FAILED,
  RESET_PINCODE,
  RESET_ERROR,
  RESET_PINCODE_REDUCER
} = DeliveryTypes;

const INITIAL_STATE = {
  load: false,
  pincodeData: {},
  error: '',
  errorMsg: '',
  isSuccessPincode: false
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case ADD_PINCODE:
      return { ...state, ...data };
    case ADD_PINCODE_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case ADD_PINCODE_SUCCESS:
      return {
        ...state,
        load: false,
        pincodeData: action.response,
        error: false,
        isSuccessPincode: true
      };
    case ADD_PINCODE_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message
      };
    case RESET_PINCODE:
      return {
        ...state,
        isSuccessPincode: false,
        pincodeData: {}
      };
    case RESET_ERROR:
      return {
        ...state,
        error: false
      };
    case RESET_PINCODE_REDUCER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
