import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const OtpTypes = mirrorKeyValue([
  'VERIFY_OTP',
  'VERIFY_OTP_FOR_COD',
  'VERIFY_OTP_COD_SUCCESS',
  'VERIFY_OTP_INDICATOR',
  'VERIFY_OTP_SUCCESS',
  'VERIFY_OTP_FAILED',
  'UPDATE_OTP_VIEW_STATE',
  'RESET_OTP_VIEW',
  'RESET_ERROR_MSG',
  'VERIFY_EMAIL_OTP_SUCCESS',
  'VERIFY_EMAIL_OTP',
  'RESET_OTP_REDUCER'
]);

const {
  VERIFY_OTP,
  VERIFY_OTP_FOR_COD,
  VERIFY_OTP_COD_SUCCESS,
  VERIFY_OTP_INDICATOR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  UPDATE_OTP_VIEW_STATE,
  RESET_OTP_VIEW,
  RESET_ERROR_MSG,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP,
  RESET_OTP_REDUCER
} = OtpTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  otpData: [],
  error: '',
  errorMsg: '',
  isOtpSuccess: false,
  userFormData: {},
  isOtpVerified: false,
  isEmailOtpVerified: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case VERIFY_OTP:
      return { ...state, ...data };
    case VERIFY_OTP_FOR_COD:
      return { ...state, ...data };
    case VERIFY_EMAIL_OTP:
      return { ...state, ...data };
    case VERIFY_OTP_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isOtpSuccess: false,
        isOtpVerified: false,
        isEmailOtpVerified: false
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpData: action.response,
        error: false,
        isOtpSuccess: true
      };
    case VERIFY_OTP_COD_SUCCESS:
      return {
        ...state,
        loading: false,
        otpData: action.response,
        error: false,
        isOtpVerified: true
      };
    case VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpData: action.response,
        error: false,
        isEmailOtpVerified: true
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isOtpSuccess: false,
        isOtpVerified: false,
        isEmailOtpVerified: false
      };
    case UPDATE_OTP_VIEW_STATE:
      return {
        ...state,
        isOtpSuccess: action.otpView
      };
    case RESET_OTP_VIEW:
      return {
        ...state,
        isOtpSuccess: false,
        errorMsg: '',
        error: false,
        isOtpVerified: false
      };
    case RESET_ERROR_MSG:
      return {
        ...state,
        errorMsg: '',
        error: false
      };
    case RESET_OTP_REDUCER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
