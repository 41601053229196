import React from 'react';

function Product() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="product">
        <path
          fill="#FF8000"
          d="M28 42H4a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1V43a1 1 0 0 0-1-1zM20 36h24a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1H20a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1zM60 42H36a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1V43a1 1 0 0 0-1-1z"
        />
        <path
          fill="#4D4D4D"
          d="M29 36H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zM18.115 17.668a1.002 1.002 0 0 0 1.277.609L43.9 9.599a1 1 0 0 0 .609-1.277l-2.002-5.656a.998.998 0 0 0-.512-.568 1.01 1.01 0 0 0-.765-.041l-24.509 8.678a1.005 1.005 0 0 0-.609 1.277l2.003 5.656zM61 36H35a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z"
        />
        <path
          fill="#CCC"
          d="M49.04 11.091h4v2h-4zM55.04 11.091h2v2h-2zM51.035 5.22l.958 1.755-3.51 1.916-.959-1.756zM54.546 3.301l.958 1.756-1.756.958-.958-1.756zM48.48 15.287l3.512 1.917-.958 1.756-3.512-1.918zM53.744 18.16l1.756.96-.959 1.754-1.755-.958z"
        />
        <path
          fill="#FFF"
          d="M19.02 52h-6.04A2.984 2.984 0 0 1 10 49.02c0-1.683 1.337-3.02 2.98-3.02h6.04A2.984 2.984 0 0 1 22 48.98c0 1.683-1.337 3.02-2.98 3.02zm-6.04-4a.98.98 0 0 0-.98.98c0 .581.439 1.02.98 1.02h6.04a.98.98 0 0 0 .98-.98c0-.581-.439-1.02-.98-1.02h-6.04zM51.02 52h-6.04A2.984 2.984 0 0 1 42 49.02c0-1.683 1.337-3.02 2.98-3.02h6.04A2.984 2.984 0 0 1 54 48.98c0 1.683-1.337 3.02-2.98 3.02zm-6.04-4a.98.98 0 0 0-.98.98c0 .581.439 1.02.98 1.02h6.04a.98.98 0 0 0 .98-.98c0-.581-.439-1.02-.98-1.02h-6.04zM35.02 26h-6.04A2.984 2.984 0 0 1 26 23.02c0-1.683 1.337-3.02 2.98-3.02h6.04A2.984 2.984 0 0 1 38 22.98c0 1.683-1.337 3.02-2.98 3.02zm-6.04-4a.98.98 0 0 0-.98.98c0 .581.439 1.02.98 1.02h6.04a.98.98 0 0 0 .98-.98c0-.581-.439-1.02-.98-1.02h-6.04z"
        />
        <path
          fill="#E57300"
          d="M28 33a23.8 23.8 0 0 1-9-1.756V35a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1v-9.462C41.184 30.052 35.001 33 28 33zM12 59a23.8 23.8 0 0 1-9-1.756V61a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1v-9.462C25.184 56.052 19.001 59 12 59zM44 59a23.8 23.8 0 0 1-9-1.756V61a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1v-9.462C57.184 56.052 51.001 59 44 59z"
        />
        <path fill="#FFF" d="M37 30h4v2h-4zM21 56h4v2h-4zM53 56h4v2h-4z" />
      </svg>
    </div>
  );
}

export default Product;
