import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from './SectionHeader';
import Detail from './Detail';
import { ContactUsTypes } from '../../reducers/contactUs/contactUs';
import './ContactUs.scss';
import Loader from './loading';

function ContactUs() {
  const dispatch = useDispatch();
  const { contactUs } = useSelector((state) => state);
  const { loading } = contactUs;
  const callContactUsAPI = () => {
    dispatch({ type: ContactUsTypes.GET_CONTACT_US });
  };
  useEffect(() => {
    callContactUsAPI();
  }, []);
  return (
    <div>
      <div className="app-contact-us-header" />
      <div className="app-contact-us-layout">
        <SectionHeader />
        <div className="container">{loading ? <Loader /> : <Detail />}</div>
      </div>
    </div>
  );
}

export default ContactUs;
