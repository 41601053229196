export default function GreenCheck() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.07188 0 0 8.07188 0 18C0 27.9281 8.07188 36 18 36C27.9281 36 36 27.9281 36 18C36 8.07188 27.9281 0 18 0Z"
        fill="#69C444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9661 12.1958C27.4053 12.627 27.4053 13.3364 26.9661 13.7676L16.3407 24.1997C16.1211 24.4153 15.8306 24.5265 15.5402 24.5265C15.2498 24.5265 14.9593 24.4153 14.7398 24.1997L9.42704 18.9836C8.98786 18.5524 8.98786 17.843 9.42704 17.4118C9.86623 16.9807 10.5888 16.9807 11.0279 17.4118L15.5402 21.842L25.3652 12.1958C25.8044 11.7577 26.5269 11.7577 26.9661 12.1958Z"
        fill="white"
      />
    </svg>
  );
}
