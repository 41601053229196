import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const ContactUsTypes = mirrorKeyValue([
  'GET_CONTACT_US',
  'GET_CONTACT_US_INDICATOR',
  'GET_CONTACT_US_SUCCESS',
  'GET_CONTACT_US_FAILED'
]);

const { GET_CONTACT_US, GET_CONTACT_US_INDICATOR, GET_CONTACT_US_SUCCESS, GET_CONTACT_US_FAILED } =
  ContactUsTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  contactData: {},
  error: '',
  errorMsg: ''
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_CONTACT_US:
      return { ...state, ...data };
    case GET_CONTACT_US_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        contactData: action.response.contactUs,
        error: false
      };
    case GET_CONTACT_US_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
