import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall, makeDeleteApiCall } from '../../adapters';
import { WishlistTypes } from '../../reducers/wishlist/wishlist';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { CartTypes } from '../../reducers/cart/cart';

function* getWishlist(payload) {
  try {
    yield put({ type: WishlistTypes.GET_WISHLIST_INDICATOR });
    const params = {};
    const API_URL = URLS.API_FETCH_WISHLIST(payload.userId);
    const response = yield call(makeGetApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: WishlistTypes.GET_WISHLIST_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: WishlistTypes.GET_WISHLIST_FAILED,
        message: response.data.message || String.SERVER_ERROR
      });
    }
  } catch (e) {
    yield put({
      type: WishlistTypes.GET_WISHLIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* addToWishlist(payload) {
  try {
    yield put({ type: WishlistTypes.ADD_WISHLIST_INDICATOR });
    const params = {
      userId: payload.userId,
      productId: payload.productId
    };
    const API_URL = URLS.API_WISHLIST;
    const response = yield call(makePostApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: WishlistTypes.ADD_WISHLIST_SUCCESS,
        response: response.data
      });
      yield put({ type: CartTypes.GET_USER_SAVE_LATER_FETCH, data: {} });
    } else {
      yield put({
        type: WishlistTypes.ADD_WISHLIST_FAILED,
        message: response.data.message || String.SERVER_ERROR
      });
    }
  } catch (e) {
    yield put({
      type: WishlistTypes.ADD_WISHLIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* removeWishlist(payload) {
  try {
    yield put({ type: WishlistTypes.REMOVE_WISHLIST_INDICATOR });
    const { productId, userId } = payload;
    const params = { productId, userId };
    const API_URL = URLS.API_DELETE_WISHLIST();
    const response = yield call(makeDeleteApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: WishlistTypes.REMOVE_WISHLIST_SUCCESS,
        response: response.data
      });
      yield put({ type: CartTypes.GET_USER_SAVE_LATER_FETCH, data: {} });
    } else {
      yield put({
        type: WishlistTypes.REMOVE_WISHLIST_FAILED,
        message: response.data.message || String.SERVER_ERROR
      });
    }
  } catch (e) {
    yield put({
      type: WishlistTypes.REMOVE_WISHLIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* WishlistSaga() {
  try {
    yield all([
      takeLatest(WishlistTypes.GET_WISHLIST, getWishlist),
      takeLatest(WishlistTypes.ADD_WISHLIST, addToWishlist),
      takeLatest(WishlistTypes.REMOVE_WISHLIST, removeWishlist)
    ]);
  } catch (ex) {
    console.warn('Error while Wishlist Saga: WishlistSaga', ex);
  }
}
export default WishlistSaga;
