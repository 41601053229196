import React from 'react';

export default function Edit() {
  return (
    <div>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#8898aa"
          stroke="none">
          <path
            d="M4090 5091 c-124 -40 -156 -64 -390 -297 l-225 -224 547 -547 548
-548 211 210 c225 224 277 290 310 394 45 146 35 280 -33 421 -38 80 -52 97
-257 301 -230 230 -270 259 -401 294 -77 21 -241 19 -310 -4z"
          />
          <path
            d="M1743 2837 l-1422 -1422 -162 -650 c-119 -477 -161 -658 -155 -678 9
-36 46 -74 81 -83 27 -7 1276 296 1325 322 14 7 661 649 1438 1426 l1412 1413
-548 548 -547 547 -1422 -1423z"
          />
        </g>
      </svg>
    </div>
  );
}
