import PaymentStatus from '../components/multicellular/payment/callBack/PaymentStatus';
import RoutesPaths from '../constants/routes';
import Book from '../containers/book';
import BulkOrders from '../containers/bulkOrders';
import Cart from '../containers/cart';
import CartWagon from '../containers/cart-wagon';
import AddressList from '../containers/cart/address';
import PaymentSummary from '../containers/cart/paymentSummary';
import ContactUs from '../containers/contactUs';
import Faq from '../containers/faq';
// import Home from '../containers/home';
import HomeNew from '../containers/home';
import MyAccount from '../containers/myAccount';
import TrackOrder from '../containers/myAccount/trackOrder';
import { PrivacyPolicy, TermsOfUsage, ReturnPolicy, ShippingPolicy } from '../containers/policy';
import ProductDetail from '../containers/product/productDetail';
import ProductExplorer from '../containers/product/productExplorer';
import ProductReview from '../containers/product/productReview';
import Search from '../containers/product/search';

const routeConfig = [
  {
    path: '/',
    page: <HomeNew />
  },
  // {
  //   path: '/',
  //   page: <Home />,
  // },
  {
    path: RoutesPaths.PRODUCT_EXPLORER,
    page: <ProductExplorer />,
    subPage: [
      {
        path: ':id',
        page: <ProductExplorer />
      }
    ]
  },
  {
    path: RoutesPaths.PRODUCT_DETAIL,
    page: <ProductDetail />,
    subPage: [
      {
        path: ':slug/:id',
        page: <ProductDetail />
      },
      {
        path: ':id',
        page: <ProductDetail />
      }
    ]
  },
  {
    path: RoutesPaths.PAYMENT_STATUS,
    page: <PaymentStatus />,
    subPage: [
      {
        path: ':merchantId/:transactionId',
        page: <PaymentStatus />
      },
      {
        path: ':transactionId',
        page: <PaymentStatus />
      }
    ]
  },
  {
    path: RoutesPaths.REVIEWS,
    page: <ProductReview />,
    subPage: [
      {
        path: ':id',
        page: <ProductReview />
      }
    ]
  },
  {
    path: RoutesPaths.ACCOUNT,
    page: <MyAccount />
  },
  {
    path: RoutesPaths.CART,
    page: <Cart />
  },
  {
    path: RoutesPaths.CART_WAGON,
    page: <CartWagon />
  },
  {
    path: RoutesPaths.ADDRESS_LIST,
    page: <AddressList />
  },
  {
    path: RoutesPaths.PAYMENT_SUMMARY,
    page: <PaymentSummary />
  },
  {
    path: RoutesPaths.TERMS_OF_USAGE,
    page: <TermsOfUsage />
  },
  {
    path: RoutesPaths.RETURN_POLICY,
    page: <ReturnPolicy />
  },
  {
    path: RoutesPaths.CONTACT_US,
    page: <ContactUs />
  },
  {
    path: RoutesPaths.PRIVACY_POLICY,
    page: <PrivacyPolicy />
  },
  {
    path: RoutesPaths.SHIPPING_POLICY,
    page: <ShippingPolicy />
  },
  {
    path: RoutesPaths.TRACK_ORDER,
    page: <TrackOrder />,
    subPage: [
      {
        path: ':id/:invoiceId',
        page: <TrackOrder />
      }
    ]
  },
  {
    path: RoutesPaths.FAQ,
    page: <Faq />
  },
  {
    path: RoutesPaths.SEARCH,
    page: <Search />
  },
  {
    path: RoutesPaths.BULK_ORDERS,
    page: <BulkOrders />
  }
];
export default routeConfig;

export const primaryRoutes = [
  {
    path: '/',
    page: <Book />,
    subPage: [
      {
        path: ':slug/:id',
        page: <Book />
      },
      {
        path: ':id',
        page: <Book />
      }
    ]
  }
];
