import mirrorKeyValue from 'mirror-key-value';

export const VoteTypes = mirrorKeyValue([
  'GET_VOTE',
  'GET_VOTE_INDICATOR',
  'GET_VOTE_SUCCESS',
  'GET_VOTE_FAILED',
  'RESET_GET_VOTE'
]);

const { GET_VOTE, GET_VOTE_INDICATOR, GET_VOTE_SUCCESS, GET_VOTE_FAILED, RESET_GET_VOTE } =
  VoteTypes;

const INITIAL_STATE = {
  load: false,
  VoteData: {},
  error: '',
  errorMsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_VOTE:
      return { ...state, ...data };
    case GET_VOTE_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case GET_VOTE_SUCCESS:
      return {
        ...state,
        load: false,
        VoteData: action.response,
        error: false
      };
    case GET_VOTE_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message
      };
    case RESET_GET_VOTE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
