/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './TestimonialCard.scss';

export default function TestimonialCard(props) {
  const { description, name } = props;
  return (
    <div className="app-testimonial-card">
      <div className="app-testimonial-text">
        {description.map((item) => (
          <div key={item}>{item}</div>
        ))}
      </div>
      <div className="user-name">{name}</div>
    </div>
  );
}

TestimonialCard.propTypes = {
  description: PropTypes.array,
  name: PropTypes.string
};
TestimonialCard.defaultProps = {
  description: [],
  name: ''
};
