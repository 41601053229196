import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const WishlistTypes = mirrorKeyValue([
  'GET_WISHLIST',
  'GET_WISHLIST_INDICATOR',
  'GET_WISHLIST_SUCCESS',
  'GET_WISHLIST_FAILED',
  'ADD_WISHLIST',
  'ADD_WISHLIST_INDICATOR',
  'ADD_WISHLIST_SUCCESS',
  'ADD_WISHLIST_FAILED',
  'ADD_WISHLIST_ERROR_RESET',
  'REMOVE_WISHLIST',
  'REMOVE_WISHLIST_INDICATOR',
  'REMOVE_WISHLIST_SUCCESS',
  'REMOVE_WISHLIST_FAILED',
  'RESET_WISHLIST_DATA',
  'RESET_WISHLIST_FLAG'
]);

const {
  GET_WISHLIST,
  GET_WISHLIST_INDICATOR,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAILED,
  ADD_WISHLIST,
  ADD_WISHLIST_INDICATOR,
  ADD_WISHLIST_SUCCESS,
  ADD_WISHLIST_FAILED,
  ADD_WISHLIST_ERROR_RESET,
  REMOVE_WISHLIST,
  REMOVE_WISHLIST_INDICATOR,
  REMOVE_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAILED,
  RESET_WISHLIST_DATA,
  RESET_WISHLIST_FLAG
} = WishlistTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  wishlistData: {},
  error: false,
  errorMsg: '',
  wishlistloading: false,
  addWishlistData: {},
  addError: false,
  addErrorMsg: '',
  removeloading: false,
  removeaddressData: {},
  removeError: false,
  removeErrorMsg: '',
  isWishlistAdded: false,
  isWishlistRemoved: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_WISHLIST:
      return { ...state, ...data };
    case GET_WISHLIST_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        addressData: action.response,
        error: false
      };
    case GET_WISHLIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case ADD_WISHLIST:
      return { ...state, ...data };
    case ADD_WISHLIST_INDICATOR:
      return {
        ...state,
        wishlistloading: true,
        addError: false,
        isWishlistAdded: false
      };
    case ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlistloading: false,
        addWishlistData: action.response,
        addError: false,
        isWishlistAdded: true
      };
    case ADD_WISHLIST_FAILED:
      return {
        ...state,
        wishlistloading: false,
        addError: true,
        addErrorMsg: action.message,
        isWishlistAdded: false
      };
    case ADD_WISHLIST_ERROR_RESET:
      return {
        ...state,
        wishlistloading: false,
        addError: false,
        addErrorMsg: ''
      };
    case REMOVE_WISHLIST:
      return { ...state, ...data };
    case REMOVE_WISHLIST_INDICATOR:
      return {
        ...state,
        removeloading: true,
        removeError: false,
        isWishlistRemoved: false
      };
    case REMOVE_WISHLIST_SUCCESS: {
      return {
        ...state,
        removeloading: false,
        removeaddressData: action.response,
        removeError: false,
        isWishlistRemoved: true
      };
    }
    case REMOVE_WISHLIST_FAILED:
      return {
        ...state,
        removeloading: false,
        removeError: true,
        isWishlistRemoved: false,
        removeErrorMsg: action.message
      };
    case RESET_WISHLIST_DATA: {
      return {
        ...state,
        addWishlistData: {}
      };
    }
    case RESET_WISHLIST_FLAG: {
      return {
        ...state,
        isWishlistAdded: false,
        isWishlistRemoved: false
      };
    }
    default:
      return state;
  }
}
