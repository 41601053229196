import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePostApiCall } from '../../adapters';
import { couponTypes } from '../../reducers/payment/coupon';
import String from '../../constants/strings';
import URLS from '../../constants/urls';

function* getCouponList(payload) {
  try {
    yield put({ type: couponTypes.FETCH_COUPON_LIST_INDICATOR });
    const params = {
      userId: payload.userId,
      couponCode: payload.couponCode,
      totalPayablePrice: payload.totalPayablePrice
    };
    const response = yield call(makePostApiCall, URLS.API_FETCH_COUPON_LIST, params);
    if (response.status === 200) {
      yield put({
        type: couponTypes.FETCH_COUPON_LIST_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: couponTypes.FETCH_COUPON_LIST_FAILED,
        response: response?.data,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: couponTypes.FETCH_COUPON_LIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* CouponSaga() {
  try {
    yield all([takeLatest(couponTypes.FETCH_COUPON_LIST, getCouponList)]);
  } catch (ex) {
    console.warn('Error while Coupon Saga: faqSaga', ex);
  }
}
export default CouponSaga;
