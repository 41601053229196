import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function Meta() {
  const { information } = useSelector((state) => state);

  const location = window?.location?.href;
  const { productData } = information;
  const { product: { name, description } = {} } = productData;
  const title = name || 'YuviPep Store';
  const metaDescription =
    description ||
    'YuviPep Innovation aims to transform education from the ground up. Our pedagogical approach, connected learning, and online global collaboration have broken boundaries to include a network of students beyond the immediate classroom environment.';
  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>{title} - YuviPeP Store</title>
      <meta name="description" content={metaDescription} />
      <meta
        name="keywords"
        content={`${title}, Book, artificial intelligence, Machine Learning, Comic Book`}
      />
      <meta name="author" content="YuviPep" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={location} />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:site" content="@YourTwitterHandle" />
      <meta name="twitter:creator" content="@YourTwitterHandle" />
      <link rel="canonical" href={location} />
      <meta httpEquiv="cache-control" content="max-age=31536000, public" />
      <meta httpEquiv="expires" content="Tue, 01 Jan 2025 12:00:00 GMT" />
    </Helmet>
  );
}

export default Meta;
