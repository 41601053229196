import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import Heart from '../../../../assets/svgs/Heart';
import Button from '../../../unicellular/button';
import STORAGE_KEYS from '../../../../constants/storageKey';
import { WishlistTypes } from '../../../../reducers/wishlist/wishlist';
import './Wishlist.scss';
import { trackEvent } from '../../../../analytics/googleAnalytics';

function Wishlist(props) {
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const {
    productId,
    productName,
    userDetails,
    callAddToWishlistApi,
    callRemoveWishlistApi,
    resetWishlistData,
    isWishlisted = false,
    setIsOpenAuthModal,
    className,
    resetisWishlistReducer,
    isHideSnackbar
  } = props;
  const {
    wishlist: {
      addWishlistData = {},
      isWishlistAdded = false,
      isWishlistRemoved = false,
      removeaddressData = {}
    },
    login
  } = useSelector((state) => state);
  const { isUserLogin } = login;
  const [initialWishlist, setInitialWishlist] = useState(isWishlisted);

  const addToWishlist = () => {
    callAddToWishlistApi(userId, productId);
  };
  const removeFromWishlist = () => {
    callRemoveWishlistApi(userId, productId);
  };
  const handleWishlist = useCallback(
    (event) => {
      if (!initialWishlist) {
        trackEvent(
          'Wishlist',
          'Add to Wishlist',
          `${userDetails} added ${productName} to their wishlist`
        );
      } else {
        trackEvent(
          'Wishlist',
          'Remove from Wishlist',
          `${userDetails} removed ${productName} from their wishlist`
        );
      }

      event.preventDefault();
      if (!isUserLogin) {
        setIsOpenAuthModal(true);
      } else if (!initialWishlist) {
        setInitialWishlist(true);
        addToWishlist();
      } else {
        setInitialWishlist(false);
        removeFromWishlist();
      }
    },
    [initialWishlist]
  );

  useEffect(() => {
    if (!isUserLogin) {
      resetWishlistData();
      setInitialWishlist(false);
    }
  }, [isUserLogin]);
  useEffect(() => {
    setInitialWishlist(isWishlisted);
  }, [isWishlisted]);
  useEffect(() => {
    if (isWishlistAdded || isWishlistRemoved) {
      setTimeout(() => {
        resetisWishlistReducer();
      }, 1000);
    }
  }, [isWishlistAdded, isWishlistRemoved]);
  return (
    <div>
      <Button
        isIcon
        icon={initialWishlist ? <Heart isFilled /> : <Heart />}
        className={className}
        onClick={handleWishlist}
      />
      {!isHideSnackbar && (
        <>
          <Snackbar
            open={isWishlistAdded}
            autoHideDuration={50}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Alert severity="success" sx={{ width: '100%' }}>
              <div className="alet-message-success">{addWishlistData?.message}</div>
            </Alert>
          </Snackbar>
          <Snackbar
            open={isWishlistRemoved}
            autoHideDuration={50}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Alert severity="warning" sx={{ width: '100%' }}>
              <div className="alet-message-success">{removeaddressData?.message}</div>
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}
Wishlist.propTypes = {
  callAddToWishlistApi: PropTypes.func.isRequired,
  callRemoveWishlistApi: PropTypes.func.isRequired,
  resetWishlistData: PropTypes.func.isRequired,
  resetisWishlistReducer: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  userDetails: PropTypes.string,
  className: PropTypes.string.isRequired,
  isWishlisted: PropTypes.bool,
  setIsOpenAuthModal: PropTypes.func,
  isHideSnackbar: PropTypes.bool
};
Wishlist.defaultProps = {
  setIsOpenAuthModal: () => {},
  isWishlisted: false,
  isHideSnackbar: true,
  userDetails: 'User'
};
const mapStateToProps = (state) => {
  const { information, delivery } = state;
  return { information, delivery };
};

const mapDispatchToProps = (dispatch) => ({
  callAddToWishlistApi: (userId, productId) =>
    dispatch({
      type: WishlistTypes.ADD_WISHLIST,
      userId,
      productId
    }),
  callRemoveWishlistApi: (userId, productId) =>
    dispatch({
      type: WishlistTypes.REMOVE_WISHLIST,
      userId,
      productId
    }),
  resetWishlistData: () =>
    dispatch({
      type: WishlistTypes.RESET_WISHLIST_DATA
    }),
  resetisWishlistReducer: () =>
    dispatch({
      type: WishlistTypes.RESET_WISHLIST_FLAG
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
