import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

export default function Detail() {
  const { contactUs } = useSelector((state) => state);
  const { contactData } = contactUs;
  const { title, description, contactDetails: { data = [] } = {}, action } = contactData;
  return (
    <div className="app-contact-us-detail-layout">
      <div className="section-title">{title}</div>
      <div className="detail-text-section">
        <div className="text">{description}</div>
        <div className="text">{action?.description}</div>
      </div>
      {action?.link?.text?.length > 0 && (
        <div>
          <NavLink to={`/${action?.link?.link}`}>
            <button type="button" className="faq-btn-contact-us">
              {action?.link?.text}
            </button>
          </NavLink>
        </div>
      )}
      <div className="separator-hr" />
      {data?.map((item, index) => (
        <div className="info-section-layout" key={index?.toString()}>
          <div className="sub-section-title">{item?.title}</div>
          <div className="sub-section-detail">{item?.details}</div>
        </div>
      ))}
    </div>
  );
}
