import mirrorKeyValue from 'mirror-key-value';

export const ReturnOrderTypes = mirrorKeyValue([
  'GET_ORDER_RETURN',
  'GET_ORDER_RETURN_INDICATOR',
  'GET_ORDER_RETURN_SUCCESS',
  'GET_ORDER_RETURN_FAILED',
  'GET_ORDER_RETURN_OPTIONS',
  'GET_ORDER_RETURN_OPTIONS_INDICATOR',
  'GET_ORDER_RETURN_OPTIONS_SUCCESS',
  'GET_ORDER_RETURN_OPTIONS_FAILED',
  'SET_GET_ORDER_RETURN'
]);

const {
  GET_ORDER_RETURN,
  GET_ORDER_RETURN_INDICATOR,
  GET_ORDER_RETURN_SUCCESS,
  GET_ORDER_RETURN_FAILED,
  GET_ORDER_RETURN_OPTIONS,
  GET_ORDER_RETURN_OPTIONS_INDICATOR,
  GET_ORDER_RETURN_OPTIONS_SUCCESS,
  GET_ORDER_RETURN_OPTIONS_FAILED,
  SET_GET_ORDER_RETURN
} = ReturnOrderTypes;

const INITIAL_STATE = {
  load: false,
  cancelOrderData: {},
  options: {},
  error: '',
  errorMsg: '',
  isOptionSuccess: true,
  isReturnedSuccess: false,
  loadingOption: true
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_ORDER_RETURN:
      return { ...state, ...data };

    case GET_ORDER_RETURN_OPTIONS:
      return { ...state, ...data };

    case GET_ORDER_RETURN_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };

    case GET_ORDER_RETURN_OPTIONS_INDICATOR:
      return {
        ...state,
        loadingOption: true,
        error: false
      };

    case GET_ORDER_RETURN_SUCCESS:
      return {
        ...state,
        load: false,
        cancelOrderData: action.response,
        isReturnedSuccess: true,
        error: false
      };

    case GET_ORDER_RETURN_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingOption: false,
        options: action.response,
        isOptionSuccess: true,
        error: false
      };
    case GET_ORDER_RETURN_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        isReturnedSuccess: false,
        errorMsg: action.message
      };
    case SET_GET_ORDER_RETURN:
      return {
        ...state,
        isReturnedSuccess: false
      };
    case GET_ORDER_RETURN_OPTIONS_FAILED:
      return {
        ...state,
        loadingOption: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
