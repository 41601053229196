import React from 'react';
import { useSelector } from 'react-redux';
import ProjectAssistanceLink from '../../components/multicellular/home/projectAssistanceLink/ProjectAssistanceLink';

function ProjectLink() {
  const { home } = useSelector((state) => state);
  const { homeproducts: { home: { projectAssistance = {} } = {} } = {} } = home;
  return projectAssistance && projectAssistance?.isActive ? (
    <div>
      <ProjectAssistanceLink
        description={projectAssistance?.description}
        subTitle={projectAssistance?.subTitle}
        mediaType={projectAssistance?.mediaType}
        media={projectAssistance?.media}
        title={projectAssistance?.title}
        action={projectAssistance?.action}
        mediaArray={projectAssistance?.mediaArray}
        mediaOverlay={projectAssistance?.mediaOverlay}
        style={projectAssistance?.style}
        tag={projectAssistance?.tag}
      />
    </div>
  ) : null;
}

export default ProjectLink;
