/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { ReturnOrderTypes } from '../../../../reducers/order/returnOrder';
import SelectDropdown from '../../../unicellular/selectDropdown';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import convertDropdownOptions from '../../../../utils/dropdownOptionsUtils/convertDropdownOptions';
import AuthString from '../../../../constants/strings/auth/Auth';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import { validateReturnForm } from './FormValidation';

import './Return.scss';

function ReturnModal(props) {
  const { show, onClose, callReturnOptions, orderId, callReturnOrderApi, products, createdAt } =
    props;
  const { returnOrder } = useSelector((state) => state);
  const {
    options: { questions = [], title = '' },
    loadingOption,
    load
  } = returnOrder;
  const [returnType, setReturnType] = useState('Replace');
  const [question, setQuestion] = useState('');
  const [comment, setComment] = useState('');
  const [cancelProductList, setCancelProductList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [err, setError] = useState('');
  const handleChange = (event) => {
    setReturnType(event.target.value);
  };
  const getReturnWindowDetails = (createdat, returnDay) => {
    let isReturnAvailable = false;
    let diffDays = 0;
    const endDate = moment(createdat).add(returnDay, 'd').format('DD MMM YYYY');
    const startDate = moment().format('DD MMM YYYY');
    const startDateTT = moment(startDate, 'DD MMM YYYY');
    const endDateTT = moment(endDate, 'DD MMM YYYY');
    diffDays = endDateTT.diff(startDateTT, 'days');
    if (diffDays > 0) {
      isReturnAvailable = true;
    }
    return isReturnAvailable;
  };
  const onChangeQuestion = (e) => {
    setQuestion(e);
    setError('');
  };
  const handleComment = (e) => {
    setComment(e.target.value);
    setError('');
  };
  const isValidate = () => {
    let errors = '';
    errors = validateReturnForm(question.value, comment);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  const handleOrderReturn = () => {
    const reason = { question: question?.value, comment };
    const productsList = cancelProductList.map((item) => ({
      id: item.product._id,
      quantity: item.quantity
    }));
    const valid = isValidate();
    if (valid) {
      callReturnOrderApi(productsList, orderId, returnType, reason);
      setError('');
    }
  };
  useEffect(() => {
    callReturnOptions(orderId);
  }, []);

  const handleCancelItems = useCallback(
    (orderedProductId, data) => {
      const arr = cancelProductList;
      const index = arr.findIndex((item) => item._id === orderedProductId);

      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(data);
      }
      setCancelProductList([...arr]);
    },
    [cancelProductList]
  );

  const handleConfirmation = useCallback(() => {
    setShowConfirmation(!showConfirmation);
  }, [showConfirmation]);

  const selectProducts = () => (
    <div className="app-order-return-container">
      {products &&
        products?.length > 0 &&
        products?.map((item) => (
          <button
            onClick={
              item?.orderDetails?.isCancelled ||
              item?.orderDetails?.isReplace ||
              item?.orderDetails?.isReturn ||
              !getReturnWindowDetails(createdAt, item?.product?.returnWindow)
                ? null
                : () => handleCancelItems(item?._id, item)
            }
            type="button"
            className={`app-return-product-flex ${
              item?.orderDetails?.isCancelled ||
              item?.orderDetails?.isReplace ||
              item?.orderDetails?.isReturn ||
              !getReturnWindowDetails(createdAt, item?.product?.returnWindow)
                ? 'not-allowed'
                : 'allowed'
            }`}
            key={item?.product?.name}>
            <div className="product-detail-layout ">
              {item?.orderDetails?.isCancelled ||
              item?.orderDetails?.isReplace ||
              item?.orderDetails?.isReturn ||
              !getReturnWindowDetails(createdAt, item?.product?.returnWindow) ? (
                <div className="empty-check" />
              ) : (
                <Checkbox
                  checked={
                    cancelProductList &&
                    cancelProductList?.length > 0 &&
                    cancelProductList?.some((val) => val._id === item?._id)
                  }
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
              <img
                className="product-image"
                src={
                  item?.product?.images && item?.product?.images?.length > 0
                    ? generateUrl(item?.product?.images[0])
                    : ''
                }
                alt=""
                srcSet=""
                onError={({ currentTarget }) => {
                  const currTrgt = currentTarget;
                  currTrgt.onerror = null; // prevents looping
                  currTrgt.src = PRODUCT_DEFAULTS.productImage;
                }}
              />
              <div>
                <div className="product-name text-start">{item?.product?.name}</div>
                <div className="title-section text-start">
                  Quantity:
                  {item?.quantity}
                </div>
              </div>
            </div>
            <div className="product-detail-layout">
              <div>
                <div>
                  {(item?.orderDetails?.isCancelled && (
                    <div className="app-delivered-status">Cancelled</div>
                  )) ||
                    (item?.orderDetails?.isReplace && (
                      <div className="app-delivered-status">Replace requested</div>
                    )) ||
                    (item?.orderDetails?.isReturn && (
                      <div className="app-delivered-status">Return requested</div>
                    )) ||
                    (!getReturnWindowDetails(createdAt, item?.product?.returnWindow) && (
                      <div className="app-delivered-status">Not Available for Return</div>
                    ))}
                </div>
              </div>
            </div>
          </button>
        ))}
    </div>
  );
  const selectedProducts = () => (
    <div className="app-order-return-container">
      {cancelProductList &&
        cancelProductList?.length > 0 &&
        cancelProductList?.map((item) => (
          <div className="app-return-product-flex" key={item?.product?.name}>
            <div className="product-detail-layout mt-4">
              <img
                className="product-image"
                src={
                  item?.product?.images && item?.product?.images?.length > 0
                    ? generateUrl(item?.product?.images[0])
                    : ''
                }
                alt=""
                srcSet=""
                onError={({ currentTarget }) => {
                  const currTrgt = currentTarget;
                  currTrgt.onerror = null; // prevents looping
                  currTrgt.src = PRODUCT_DEFAULTS.productImage;
                }}
              />
              <div>
                <div className="product-name text-start">{item?.product?.name}</div>
                <div className="title-section text-start">
                  Quantity:
                  {item?.quantity}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
  const returnView = () => (
    <>
      <div className="return-order-product-detail ">
        <div className="inner-layout">
          <div>{selectedProducts()}</div>
        </div>
      </div>
      <div className="row return-order-question-section">
        <div className="col-md-6">
          <div className="return-dropdown-title">{title}</div>
          {loadingOption ? (
            <div>
              <Spinner animation="border" />
            </div>
          ) : (
            <SelectDropdown
              placeholder="Please choose the reason"
              onChange={onChangeQuestion}
              options={convertDropdownOptions(questions)}
              value={question !== '' && question}
              isStyled
              closeMenuOnSelect
            />
          )}
        </div>
        <div className="col-md-6">
          <div className="return-dropdown-title">Comments</div>
          <div className=" form-group">
            <textarea
              className="form-control app-return-text-area"
              id="exampleFormControlTextarea1"
              rows="5"
              value={comment}
              onChange={handleComment}
              placeholder="Describe whats wrong with the product"
            />
          </div>
          <div className="container return-comment-title">Maxlength up to 200 characters</div>
        </div>
      </div>
      <div className="app-return-radio">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={returnType}
            onChange={handleChange}>
            <FormControlLabel
              value="Replace"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    }
                  }}
                />
              }
              label="Return and Replace"
            />
            <div className="app-return-desc">
              You have to return all items in original condition, with MRP tags attached, user
              manual, warranty, and original accessories.
            </div>
            <FormControlLabel
              value="Return"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    }
                  }}
                />
              }
              label="Return and Refund"
            />
            <div className=" container app-return-desc">
              You have to return all items in original condition, with MRP tags attached, user
              manual, warranty, and original accessories.
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      <div className="app-return-desc">
        NOTE: We aren’t able to offer policy exceptions in response to comments. Do not include
        personal information as these comments may be shared with external service providers to
        identify product defects.
      </div>
      <div>{err !== '' && <div className="return-model-error-message">{err}</div>}</div>
    </>
  );
  const renderMainLayout = () => (
    <div className="app-return-dialog-title">
      {showConfirmation ? returnView() : selectProducts()}
    </div>
  );
  return (
    <Modal size="lg" show={show} onHide={onClose} centered contentClassName="changepassword-model">
      <Modal.Body className="return-modal-body">
        <div className="return-modal-header-container">
          <div>Return Or Replace</div>
          <button
            aria-label="close"
            type="button"
            onClick={onClose}
            className="return-model-close-btn">
            <Close color="#666" />
          </button>
        </div>
      </Modal.Body>
      <div>{renderMainLayout()}</div>
      <div className="rfd-md-auth-action-button">
        <div className="app-auth-btn-view">
          <Button
            buttonText="Back"
            className="app-auth-cancel"
            onClick={showConfirmation ? () => setShowConfirmation(false) : onClose}
          />
        </div>
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.CONTINUE}
            loading={load}
            disabled={showConfirmation ? false : cancelProductList?.length === 0}
            className="app-auth-login-btn"
            onClick={showConfirmation ? handleOrderReturn : handleConfirmation}
          />
        </div>
      </div>
    </Modal>
  );
}

ReturnModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  callReturnOrderApi: PropTypes.func.isRequired,
  callReturnOptions: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  createdAt: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { returnOrder } = state;
  return { returnOrder };
};

const mapDispatchToProps = (dispatch) => ({
  callReturnOrderApi: (products, orderId, returnType, reason) =>
    dispatch({
      type: ReturnOrderTypes.GET_ORDER_RETURN,
      products,
      orderId,
      returnType,
      reason
    }),
  callReturnOptions: (orderId) =>
    dispatch({
      type: ReturnOrderTypes.GET_ORDER_RETURN_OPTIONS,
      orderId
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnModal);
