/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import YuvipepLogo from '../../../../assets/svgs/YuvipepLogo';
import Routes from '../../../../constants/routes';
import Button from '../../../unicellular/button';
import Sidebar from './Sidebar';
import User from '../../../../assets/svgs/User';
import Cart from '../../../../assets/svgs/Cart';
import Wishlist from '../../../../assets/svgs/Wishlist';
import NavToggle from '../../../../assets/svgs/NavToggle';
import Auth from '../../../../containers/auth';
import { RegisterTypes } from '../../../../reducers/auth/register/register';
import { LoginTypes } from '../../../../reducers/auth/login/login';
import { CartTypes } from '../../../../reducers/cart/cart';
import STORAGE_KEYS from '../../../../constants/storageKey';
import logout from '../../../../utils/logoutSession/logoutSession';
import { OtpTypes } from '../../../../reducers/auth/otp/otp';
import { LoginWithMobileTypes } from '../../../../reducers/auth/login/loginWithMobile';
import CartHeader from './CartHeader';
import SearchField from '../../../unicellular/searchField';
import './Header.scss';
import NavBar from '../navBar/NavBar';
import { FilterTypes } from '../../../../reducers/filter/filter';

function renderLogo() {
  return (
    <div className="app-logo">
      <NavLink to={Routes.HOME}>
        <YuvipepLogo color="#FFFFFF" />
      </NavLink>
    </div>
  );
}

function renderNavLinks() {
  return (
    <div className="col-sm-6 col-lg-3 centered-logo">
      <div className="app-start-section">{renderLogo()}</div>
    </div>
  );
}

function renderLoginAndCart(
  handleOnClickWishlist,
  handleOnClickCart,
  isUserLogin,
  savedForLater,
  carts
) {
  return (
    <div className="app-end-section">
      <div className="app-header-btn-view">
        <Button
          buttonText="Wishlist"
          isIcon
          isShowNumber={isUserLogin}
          number={savedForLater?.length}
          icon={<Wishlist color="#FFFFFF" />}
          className="app-cart-btn"
          onClick={handleOnClickWishlist}
        />
      </div>
      <div className="app-header-btn-view">
        <Button
          buttonText="Cart"
          isIcon
          isShowNumber={isUserLogin}
          number={carts?.length}
          icon={<Cart />}
          className="app-cart-btn"
          onClick={handleOnClickCart}
        />
      </div>
    </div>
  );
}
function renderMbLoginDetail(
  handleLoginBtnClick,
  isUserLogin,
  userName,
  handleLogout,
  cartLabel,
  carts,
  handleOnClickCart
) {
  const checkName = (name) => {
    if (name.length > 12) {
      return `${name.substring(0, 12)}...`;
    }
    return name;
  };
  return (
    <div className="app-mb-logindetail">
      <Button
        buttonText=""
        isIcon
        isShowNumber={isUserLogin}
        number={carts?.length}
        icon={<Cart />}
        className="app-cart-btn"
        onClick={handleOnClickCart}
      />
      {isUserLogin ? (
        <Dropdown className="user-dropdown">
          <Dropdown.Toggle id="user-dropdown-btn">
            <div className="user-name">
              {userName !== ''
                ? checkName(userName)
                : checkName(`${localStorage.getItem(STORAGE_KEYS.USER_NAME)}`)}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-dropdown-inset">
            <Dropdown.Item className="app-user-dropdown-item">
              <NavLink to={Routes.DIRECT_TO_ADDRESS}>My Account</NavLink>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="app-user-dropdown-item">
              <NavLink to={Routes.CART}>{cartLabel}</NavLink>
            </Dropdown.Item>
            <Dropdown.Divider />
            <button type="button" onClick={handleLogout} className="app-logout-btn">
              <Dropdown.Item className="app-user-dropdown-item">Logout</Dropdown.Item>
            </button>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <button
          type="button"
          aria-label="user"
          className="cart-wrapper-header"
          onClick={handleLoginBtnClick}>
          <User color="#FFF" />
        </button>
      )}
    </div>
  );
}
function renderMobileViewHeader(
  isSidebarActive,
  closeSidebar,
  openSidebar,
  handleLoginBtnClick,
  isUserLogin,
  userName,
  handleLogout,
  cartLabel,
  carts,
  handleOnClickCart
) {
  return (
    <div className="app-mb-header-wrapper">
      <div className="app-mb-logodetail">
        <button
          aria-label="nav-toggle"
          type="button"
          className="side-bar-nav-toggle"
          onClick={openSidebar}>
          <NavToggle />
        </button>
        {isSidebarActive && <Sidebar onClose={closeSidebar} />}
        {renderLogo()}
      </div>
      {renderMbLoginDetail(
        handleLoginBtnClick,
        isUserLogin,
        userName,
        handleLogout,
        cartLabel,
        carts,
        handleOnClickCart
      )}
    </div>
  );
}

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const searchPath = new URLSearchParams(location.search).get('q') || '';

  const [isSidebarActive, setisSidebarActive] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  const {
    resetRegisterReducer,
    resetLoginReducer,
    resetOtpReducer,
    resetuserLoginState,
    resetLoginWithMobile,
    getUserCart,
    resetCart,
    resetIsUserLogin,
    resetInitialFilter
  } = props;
  const {
    login,
    cart: { savedForLater, carts }
  } = useSelector((state) => state);
  let cartLabel = 'My Cart';
  if (carts && carts?.length > 0) {
    cartLabel = cartLabel.concat(`(${carts?.length})`);
  }
  const { userName, isUserLogin } = login;
  const checkHeader = () => {
    const cartRoutes = [
      Routes.CART,
      `${Routes.CART}/${Routes.ADDRESS}`,
      `${Routes.PAYMENT_SUMMARY}`
    ];
    return cartRoutes.includes(currentPath);
  };
  const openSidebar = () => {
    setisSidebarActive(true);
  };
  const closeSidebar = () => {
    setisSidebarActive(false);
  };
  const handleLoginBtnClick = () => {
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
    setisLogin(true);
    resetRegisterReducer();
    resetLoginReducer();
    resetOtpReducer();
    resetLoginWithMobile();
  };
  const handleLogout = () => {
    logout();
    resetuserLoginState();
    setisLogin(false);
  };
  const handleOnClickCart = () => {
    resetCart();
    if (isUserLogin) {
      navigate(Routes.CART);
    } else {
      handleLoginBtnClick();
    }
  };
  const handleOnClickWishlist = () => {
    if (isUserLogin) {
      navigate(Routes.CART);
    } else {
      handleLoginBtnClick();
    }
  };
  const handleSearch = (querySearch) => {
    const encodeText = encodeURIComponent(querySearch);
    resetInitialFilter();
    navigate(`/category?q=${encodeText}`, { replace: true });
  };

  useEffect(() => {
    const paymentRoutes = [Routes.CART, Routes.ADDRESS_LIST, Routes.PAYMENT_SUMMARY];
    if (!paymentRoutes.includes(window.location.pathname)) {
      sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
    }
    getUserCart();
    if (localStorage.getItem(STORAGE_KEYS.IS_LOGIN) !== 'true') {
      resetIsUserLogin();
    }
  }, [localStorage.getItem(STORAGE_KEYS.IS_LOGIN)]);

  return checkHeader() ? (
    <CartHeader
      children={
        <NavBar
          onClick={handleLoginBtnClick}
          userName={userName}
          isUserLogin={isUserLogin}
          handleLogout={handleLogout}
          cartLabel={cartLabel}
          isHideLogin
        />
      }
    />
  ) : (
    <div className="app-header-fx">
      <NavBar
        onClick={handleLoginBtnClick}
        userName={userName}
        isUserLogin={isUserLogin}
        handleLogout={handleLogout}
        cartLabel={cartLabel}
      />
      <div className="app-store-header">
        <div className="container">
          <div className="web-view-header">
            <div className="row">
              {renderNavLinks(currentPath)}
              <div className="header-side-action col-sm-6 col-lg-9">
                <div className="row wd-full">
                  <div className="col-md-7">
                    <SearchField onSearch={(e) => handleSearch(e)} value={searchPath} />
                  </div>
                  <div className="col-md-5">
                    {renderLoginAndCart(
                      handleOnClickWishlist,
                      handleOnClickCart,
                      isUserLogin,
                      savedForLater,
                      carts
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-view-header">
            {renderMobileViewHeader(
              isSidebarActive,
              closeSidebar,
              openSidebar,
              handleLoginBtnClick,
              isUserLogin,
              userName,
              handleLogout,
              cartLabel,
              carts,
              handleOnClickCart
            )}
          </div>
          {isLogin && !isUserLogin && <Auth show={isLogin} setShow={setisLogin} />}
        </div>
      </div>
    </div>
  );
}
Header.propTypes = {
  resetRegisterReducer: PropTypes.func.isRequired,
  resetLoginReducer: PropTypes.func.isRequired,
  resetuserLoginState: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetLoginWithMobile: PropTypes.func.isRequired,
  getUserCart: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  resetIsUserLogin: PropTypes.func.isRequired,
  resetInitialFilter: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { register, login } = state;
  return { register, login };
};

const mapDispatchToProps = (dispatch) => ({
  resetRegisterReducer: () =>
    dispatch({
      type: RegisterTypes.RESET_REGISTER_VIEW
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetLoginReducer: () =>
    dispatch({
      type: LoginTypes.RESET_LOGIN_VIEW
    }),
  resetuserLoginState: () =>
    dispatch({
      type: LoginTypes.SET_USER_LOGIN
    }),
  resetLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW,
      mobile
    }),
  getUserCart: () => {
    dispatch({ type: CartTypes.GET_USER_CART_FETCH });
    dispatch({ type: CartTypes.GET_USER_SAVE_LATER_FETCH });
  },
  resetInitialFilter: () =>
    dispatch({
      type: FilterTypes.RESET_INITIAL_FILTER
    }),
  resetCart: () =>
    dispatch({
      type: CartTypes.RESET_ALL_CART
    }),
  resetIsUserLogin: () => dispatch({ type: LoginTypes.RESET_IS_USER_LOGIN })
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
