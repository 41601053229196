/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const common =
  'd-inline-flex align-items-center text-nowrap rounded-2 fs-5 fw-500 outline-none disabled-pointer-events-none disabled-opacity-50 active-scale-95 transition';

const variants = {
  primary: 'bg-gradient-red text-white border-primary-100 px-5 py-2',
  neutral: 'bg-neutral-100 text-white px-3 py-2',
  'neutral-inverted': 'text-neutral-100 bg-white px-3 py-2',
  outlined: 'border-primary-100 px-4 py-2',
  icon: 'fs-2 rounded-circle hover-bg-neutral-300 p-3'
};

const createClasses = (variant) => {
  return `${common} ${variants[variant] || variants.primary}`;
};

const cn = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const Button = forwardRef(({ className, variant, pill, asChild, ...props }, ref) => {
  const Component = asChild ? 'span' : 'button';

  return (
    <Component
      ref={ref}
      {...props}
      className={cn(createClasses(variant), pill && 'rounded-pill px-4', className)}
    />
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'neutral', 'neutral-inverted', 'outlined', 'icon']),
  pill: PropTypes.bool,
  asChild: PropTypes.bool
};

Button.defaultProps = {
  className: '',
  variant: 'primary',
  pill: false,
  asChild: false
};

export default Button;
