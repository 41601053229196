import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePostApiCall, makePatchApiCall } from '../../adapters';
import { RegisterTypes } from '../../reducers/auth/register/register';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { LoginTypes } from '../../reducers/auth/login/login';
import { CartTypes } from '../../reducers/cart/cart';
import { OtpTypes } from '../../reducers/auth/otp/otp';
import { LoginWithMobileTypes } from '../../reducers/auth/login/loginWithMobile';
import { editProfileTypes } from '../../reducers/auth/editUser/editUser';
import STORAGE_KEYS from '../../constants/storageKey';

function* addUserDetail(payload) {
  try {
    yield put({ type: RegisterTypes.ADD_USER_INDICATOR });
    const params = {
      email: payload.email,
      password: payload.password,
      name: payload.user,
      mobile: payload.mobile,
      countryCode: '+91'
    };

    const response = yield call(makePostApiCall, URLS.API_USER_REGISTER, params);
    if (response.status === 200) {
      yield put({
        type: RegisterTypes.ADD_USER_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: RegisterTypes.ADD_USER_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: RegisterTypes.ADD_USER_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* verifyUserOtp(payload) {
  try {
    yield put({ type: OtpTypes.VERIFY_OTP_INDICATOR });
    const params = {
      mobile: payload.mobile,
      otp: payload.otp,
      countryCode: '+91'
    };
    const id = sessionStorage.getItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
    const response = yield call(makePostApiCall, URLS.API_USER_OTP, params);
    if (response.status === 200) {
      yield put({ type: OtpTypes.VERIFY_OTP_SUCCESS, response: response.data });
      yield put({
        type: LoginTypes.GET_LOGIN_USER_SUCCESS,
        response: response.data
      });
      if (id !== null && id !== undefined && id?.trim() !== '') {
        yield put({
          type: CartTypes.ADD_TO_CART_FETCH,
          userId: response.data.userId,
          productId: id,
          quantity: 1,
          navigate: null
        });
      }
      yield put({ type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW });
      yield put({ type: RegisterTypes.RESET_REGISTER_VIEW });
    } else {
      yield put({
        type: OtpTypes.VERIFY_OTP_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: OtpTypes.VERIFY_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* verifyUserOtpForEmail(payload) {
  try {
    yield put({ type: OtpTypes.VERIFY_OTP_INDICATOR });
    const params = {
      email: payload.userId,
      otp: payload.otp,
      ...(localStorage.getItem(STORAGE_KEYS.USER_ID) && {
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
      })
    };

    const response = yield call(makePostApiCall, URLS.API_EMAIL_OTP_VERIFY, params);
    if (response.status === 200) {
      yield put({
        type: OtpTypes.VERIFY_EMAIL_OTP_SUCCESS,
        response: response.data
      });
      yield put({ type: LoginTypes.GET_USER_FETCH });
    } else {
      yield put({
        type: OtpTypes.VERIFY_OTP_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: OtpTypes.VERIFY_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* verifyUserOtpForDelivery(payload) {
  try {
    yield put({ type: OtpTypes.VERIFY_OTP_INDICATOR });
    const params = {
      mobile: payload.mobile,
      otp: payload.otp,
      countryCode: '+91'
    };

    const response = yield call(makePostApiCall, URLS.API_USER_OTP_VALIDATE, params);
    if (response.status === 200) {
      yield put({
        type: OtpTypes.VERIFY_OTP_COD_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: OtpTypes.VERIFY_OTP_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: OtpTypes.VERIFY_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* editUserProfile(payload) {
  try {
    yield put({ type: editProfileTypes.EDIT_USER_PROFILE_INDICATOR });
    const params = {
      name: payload.name,
      email: payload.email
    };
    const userid = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const response = yield call(makePatchApiCall, URLS.API_USER_DETAILS(userid), params);
    if (response.status === 200) {
      yield put({
        type: editProfileTypes.EDIT_USER_PROFILE_SUCCESS,
        response: response.data
      });
      yield put({ type: LoginTypes.GET_USER_FETCH });
    } else {
      yield put({
        type: editProfileTypes.EDIT_USER_PROFILE_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: editProfileTypes.EDIT_USER_PROFILE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* authSaga() {
  try {
    yield all([
      takeLatest(RegisterTypes.ADD_USER, addUserDetail),
      takeLatest(OtpTypes.VERIFY_OTP, verifyUserOtp),
      takeLatest(editProfileTypes.EDIT_USER_PROFILE, editUserProfile),
      takeLatest(OtpTypes.VERIFY_OTP_FOR_COD, verifyUserOtpForDelivery),
      takeLatest(OtpTypes.VERIFY_EMAIL_OTP, verifyUserOtpForEmail)
    ]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default authSaga;
