import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectDropdown from '../../../unicellular/selectDropdown';
import convertDropdownOptions from '../../../../utils/dropdownOptionsUtils/convertDropdownOptions';
import './Sort.scss';

export default function SortBy(props) {
  const { options, setSort } = props;
  const [option, setOptions] = useState(convertDropdownOptions([options[0]]));
  const onChangeOption = (e) => {
    setOptions(e);
    setSort({ type: e.key, [e.key]: e.value });
  };

  return (
    <div className="app-sort-dropdown">
      <SelectDropdown
        placeholder=" Select option"
        onChange={onChangeOption}
        options={convertDropdownOptions(options)}
        value={option !== '' && option}
        isStyledOrder
        isSortView
        closeMenuOnSelect
      />
    </div>
  );
}

SortBy.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  setSort: PropTypes.func.isRequired
};

SortBy.defaultProps = {
  options: {
    value: '',
    title: '',
    key: ''
  }
};
