import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BsDownload } from 'react-icons/bs';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { IoCloseOutline } from 'react-icons/io5';
import moment from 'moment';
import { generateUrl } from '../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../constants/defaults/product';
import Button from '../../components/unicellular/button/Button';
import downloadPdf from './utils';
import { StarRating } from './Ratings';
import { Medel } from '../../assets/svgs/GeneralIcons';

export default function ProductImage({ images, selectedImage, handleImageChange }) {
  const { information } = useSelector((state) => state);
  const [isDownload, setIsDownload] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { productData, loading } = information;
  const {
    product: {
      name,
      bookDetails: { tag, publishedDate, pdf = {}, ratings = {}, youtubeUrl } = {}
    } = {}
  } = productData;

  useEffect(() => {
    if (isDownload) {
      setTimeout(() => {
        setIsDownload(false);
      }, 3000);
    }

    return () => null;
  }, [isDownload]);

  useEffect(() => {
    if (images && images.length > 0) {
      handleImageChange(images[0]);
    }
  }, [images]);

  const scrollToRatings = () => {
    const element = document.getElementById('ratings');
    if (element) {
      const yOffset = -150; // Offset value
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  return (
    <div className="app-book-image-container">
      <div className="my-4 book-h-mobile-view">
        <h1 className="fs-28 fw-600 h-book-heading">
          {loading ? '' : name} <span className="age-badge">For Age 7+</span>
        </h1>
        {tag && (
          <div className="fw-600 mt-4 h-text-container">
            <Medel className="icon-size" />
            <span className="highlighted-text">{tag}</span>
          </div>
        )}
        <button type="button" onClick={scrollToRatings}>
          <div className="fs-4 fw-light d-flex align-items-center gap-3 flex-lg-row">
            <span className="d-flex gap-3 align-items-center">
              <span className="mt-1 fs-4 fw-semibold">{ratings?.totalRating}</span>{' '}
              <StarRating rating={ratings?.totalRating} />
            </span>

            <span>{`(${ratings?.numberOfRatings} ratings)`}</span>
          </div>
        </button>
        <div className="fw-600 fs-4 mt-4">
          Published on {moment(publishedDate).format('DD-MM-YYYY')}
        </div>
      </div>
      {selectedImage?.length > 0 ? (
        <div className="book-img-card">
          <div className="book-img-view">
            <img
              className="book-image"
              src={generateUrl(selectedImage)}
              alt=""
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
              }}
            />
          </div>
        </div>
      ) : (
        <div className="book-img-view-nf">
          <img
            // className="book-image"
            src={generateUrl(selectedImage)}
            alt=""
            srcSet=""
            onError={({ currentTarget }) => {
              const currTrgt = currentTarget;
              currTrgt.onerror = null; // prevents looping
              currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
            }}
          />
        </div>
      )}
      <div className="small-img-wrapper">
        {images?.map((image) => (
          <button
            type="button"
            className={`${selectedImage === image ? 'small-img-btn-active' : 'small-img-btn'}`}
            onClick={() => {
              handleImageChange(image);
            }}
            key={image}>
            <img
              className="small-book-image"
              src={generateUrl(image)}
              alt={image}
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
          </button>
        ))}
        {youtubeUrl && (
          <button
            type="button"
            className="small-img-btn position-relative"
            onClick={() => {
              setIsShow(true);
            }}>
            <img
              className="small-book-image"
              src={generateUrl(images[0])}
              alt={images[0]}
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
            <div className="play-btn">
              <FaRegCirclePlay className="icon fs-1" />
            </div>
          </button>
        )}

        {isShow && (
          <Modal
            size="lg"
            show={isShow}
            onHide={() => {
              setIsShow(false);
            }}
            centered>
            <Modal.Header className="d-flex justify-content-between align-items-center">
              <Modal.Title className="fs-2 fw-semibold">{name}</Modal.Title>
              <button type="button" onClick={() => setIsShow(false)} aria-label="Close">
                <IoCloseOutline className="icon fs-1" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div>
                <iframe
                  width="100%"
                  height="500"
                  src={youtubeUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      {pdf?.isActive && (
        <Button
          variant="outlined"
          onClick={() => {
            downloadPdf(pdf?.url, name);
            setIsDownload(true);
          }}
          className="w-100 d-flex align-items-center justify-content-center p-2 fs-3 mt-4 text-primary-100 border-primary-100">
          <BsDownload />
          <div className="px-3">Download sample</div>
        </Button>
      )}
      {isDownload && (
        <div
          className="alert alert-success alert-dismissible fade show snack-bar-modal-animation "
          role="alert">
          The sample of the {name} book will download shortly.
        </div>
      )}
    </div>
  );
}

ProductImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleImageChange: PropTypes.func.isRequired,
  selectedImage: PropTypes.string
};
ProductImage.defaultProps = {
  selectedImage: ''
};
