import React from 'react';
// import ProgressBar from 'react-bootstrap/ProgressBar';
import PropTypes from 'prop-types';
// >
import './FlashSale.scss';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
// import ProductString from '../../../../constants/strings/product';
// import gstAmount from '../../../../utils/conversions/gstAmount';

export default function FlashSaleCard(props) {
  const { title, price, image, description } = props;
  return (
    <div className="app-flash-sale-card">
      <img
        src={generateUrl(image)}
        className="image"
        alt={image}
        onError={({ currentTarget }) => {
          const currTrgt = currentTarget;
          currTrgt.onerror = null; // prevents looping
          currTrgt.src = PRODUCT_DEFAULTS.productImage;
        }}
      />
      <div className="title">{title}</div>
      <div className="detail">
        {Array.isArray(description)
          ? description.slice(0, 3).map((d) => (
              <div key={`description-${d}`}>
                {d} <br />
              </div>
            ))
          : description}
      </div>
      <div className="btn-price-container">
        {/* <Button buttonText={String.BUTTON_BUYNOW} className="app-btn-buy-now" /> */}
        <div className="price">{`₹ ${price}`}</div>
        {/* <div className="gst-number">{`₹ ${gstAmount(price, ProductString.gstPrice)}
        (+${ProductString.gstPrice}% GST extra)`}</div> */}
      </div>
      {/* <div className="progress-bar-container">
        <ProgressBar now={60} label={`${60}%`} visuallyHidden />
      </div> */}
      {/* <div className="sale-update">15 sold, 25 Left</div> */}
    </div>
  );
}
FlashSaleCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
FlashSaleCard.defaultProps = {
  title: '',
  price: 0,
  description: ''
};
