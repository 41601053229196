import React from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

export function StarRating({ rating, size }) {
  const fullStars = Math.floor(Number(rating));
  const hasHalfStar = rating % 1 !== 0;

  return (
    <span className="d-flex gap-1">
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar key={index} color="#ffc052" size={size} />
      ))}
      {hasHalfStar && <FaStarHalfAlt color="#ffc052" size={size} />}
    </span>
  );
}

function Ratings() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { bookDetails: { ratings = {} } = {} } = {} } = productData;

  const calculatePercentage = (number) => {
    return parseFloat((number / (ratings?.numberOfRatings || 1000)) * 100, 2);
  };

  const renderRating = () => {
    return (
      <div>
        <div className="d-flex gap-4 align-items-center justify-content-center p-5">
          <div className="fs-40 fw-semibold">{ratings?.totalRating}</div>
          <div>
            <StarRating rating={ratings?.totalRating} size={28} />
            <div className="fs-5 mt-1">Based on {ratings?.numberOfRatings} Ratings</div>
          </div>
        </div>
      </div>
    );
  };

  const renderRatingDetails = () => {
    return (
      <div className="d-flex flex-column w-100 gap-1">
        {ratings?.details?.map((item) => {
          return (
            <div className="d-flex gap-5 w-100" key={item?.key}>
              <div className="w-50 fs-5">{item?.key}</div>
              <div className="d-flex w-50 align-items-center justify-content-end gap-4">
                <StarRating rating={item?.value?.toString()} size={14} />
                <span>{item?.value}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRatingsNumber = () => {
    return (
      <div className="w-100 p-4">
        {ratings?.numbers?.map((item) => {
          return (
            <div
              className="d-flex gap-4 align-items-center w-100 justify-content-center"
              key={item?.rating}>
              <div className="prg-wrapper d-flex align-items-center">
                <span className="rating-w-5">{item?.rating}</span>
                <ProgressBar
                  striped
                  className="prg-w-15"
                  variant="warning"
                  now={calculatePercentage(item?.totalNumber)}
                />
              </div>
              <span className="rating-number">{item?.totalNumber}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="mt-5">
      <h3 className="text-secondary-300 fs-2 fw-semibold mb-4">Ratings</h3>
      <div className="rating-container-mb">
        {renderRating()}
        <hr className="w-100 my-5" />
        {renderRatingDetails()}
        <hr className="w-100 my-5" />
        {renderRatingsNumber()}
      </div>
      <div className="rating-container-lg gap-5">
        {renderRating()}
        <div className="vr" />
        {renderRatingDetails()}
        <div className="vr" />
        {renderRatingsNumber()}
      </div>
    </div>
  );
}

StarRating.propTypes = {
  rating: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default Ratings;
