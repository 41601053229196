import React from 'react';

export default function Mobile() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.91663 1.6665H4.08329C3.43896 1.6665 2.91663 2.18884 2.91663 2.83317V12.1665C2.91663 12.8108 3.43896 13.3332 4.08329 13.3332H9.91663C10.561 13.3332 11.0833 12.8108 11.0833 12.1665V2.83317C11.0833 2.18884 10.561 1.6665 9.91663 1.6665Z"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 11H7.00583"
          stroke="#888"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
