/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import PaymentItem from '../../../components/multicellular/payment/paymentItem';
import Callback from '../../../components/multicellular/payment/callBack';
import Button from '../../../components/unicellular/button';
import STORAGE_KEYS from '../../../constants/storageKey';
import { PaymentTypes } from '../../../reducers/payment/paymentGateway';
import { callRazorPayApi } from './lib';
import { makePostApiCall } from '../../../adapters';
import { PaymentMethodTypes } from '../../../reducers/payment/paymentMethod';
import URLS from '../../../constants/urls';
import './Payment.scss';
import CashOnDeliveryOtpVerify from './cashOnDeliveryOtpVerify';
import AuthString from '../../../constants/strings/auth/Auth';
import LoginModalIcon from '../../../assets/svgs/LoginModalIcon';
import Close from '../../../assets/svgs/Close';
import { OtpTypes } from '../../../reducers/auth/otp/otp';
import { LoginWithMobileTypes } from '../../../reducers/auth/login/loginWithMobile';
import PaymentString from '../../../constants/strings/payment';
import { trackEvent } from '../../../analytics/googleAnalytics';
import { LoginTypes } from '../../../reducers/auth/login/login';

function Payment(props) {
  const [showCallback, setShowCallback] = useState(false);
  const [isSuccessCallback, setIsSuccessCallback] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [callbackDetail, setCallbackDetail] = useState({});
  const {
    addressDetails,
    changeActivePaymentOption,
    priceSummary,
    resetOtpReducer,
    resetLoginWithMobileReducer,
    getUserInfo
  } = props;
  const {
    paymentMethod,
    otp,
    login,
    login: { user: { mobile } = {} }
  } = useSelector((state) => state);
  const { isUserLogin } = login;
  const { isOtpVerified } = otp;
  const { paymentMethodData, loading, activePaymentOption } = paymentMethod;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const callCODPayment = async () => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const txnType = 'ECOMMERCE';
    const apiUrl =
      priceSummary?.totalPricePayable === 0 ? 'payment/free' : activePaymentOption.method;
    const response = await callRazorPayApi(userId, txnType, apiUrl, addressDetails, priceSummary);
    if (response.status && response.status === 200) {
      const { payment } = response.data;
      setShowCallback(true);
      setCallbackDetail(payment);
      setIsSuccessCallback(true);
      sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
      sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
      resetOtpReducer();
      resetLoginWithMobileReducer();
      setopenModal(false);
    } else {
      setIsSuccessCallback(false);
      setShowCallback(true);
      setCallbackDetail(response.data);
      sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
      sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
      resetOtpReducer();
      resetLoginWithMobileReducer();
      setopenModal(false);
    }
  };
  useEffect(() => {
    if (isUserLogin) {
      getUserInfo();
    }
  }, [isUserLogin]);
  useEffect(() => {
    if (isOtpVerified) {
      callCODPayment();
    }
  }, [isOtpVerified]);
  const handleOpenModal = () => {
    setopenModal(!openModal);
  };
  const handleOnClose = () => {
    setShowCallback(false);
  };
  useEffect(() => {
    const { getPaymentMethod } = props;
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    getPaymentMethod();
  }, []);
  const handleOnChangePaymentMethod = (item) => {
    changeActivePaymentOption(item);
  };
  const razorpayOptions = (order, name, description, key, user, color, image) => {
    const options = {
      key,
      amount: order.amount,
      name,
      description,
      order_id: order.id,
      image,
      handler(response) {
        const values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id
        };
        const urlCallback = URLS.API_RAZORPAY_CALLBACK;
        const res = makePostApiCall(urlCallback, values);
        res.then((result) => {
          const { payment } = result.data;
          if (result.status === 200) {
            setShowCallback(true);
            setCallbackDetail(payment);
            setIsSuccessCallback(true);
            sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
            sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
          } else {
            setIsSuccessCallback(false);
            setShowCallback(true);
            setCallbackDetail(result.data);
            sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
            sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
          }
        });
      },
      modal: {
        ondismiss() {
          localStorage.setItem(STORAGE_KEYS.IS_PAYMENT_LOADING, 'false');
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile
      },
      theme: {
        color
      }
    };
    return options;
  };
  // eslint-disable-next-line no-unused-vars
  const paytmConfigOption = (paytmData, merchant) => {
    const config = {
      root: '',
      flow: 'DEFAULT',
      merchant: {
        name: merchant.name,
        logo: merchant.logo,
        redirect: false
      },
      style: {
        headerBackgroundColor: '#8dd8ff',
        headerColor: '#3f3f40'
      },
      data: {
        orderId: paytmData.orderId,
        token: paytmData.token,
        tokenType: 'TXN_TOKEN',
        amount: paytmData.amount
      },
      handler: {
        notifyMerchant(eventName) {
          if (eventName === 'APP_CLOSED') {
            // TODO: Handle
          }
          if (eventName === 'SESSION_EXPIRED') {
            // TODO: Handle
          }
        },
        transactionStatus(data) {
          const urlCallback = URLS.API_PAYTM_CALLBACK;
          const res = makePostApiCall(urlCallback, data);
          res.then((result) => {
            window.Paytm.CheckoutJS.close();
            if (result.status === 200) {
              const { payment = {} } = result.data;
              setShowCallback(true);
              setCallbackDetail(payment);
              setIsSuccessCallback(true);
              sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
              sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
            } else {
              setIsSuccessCallback(false);
              setShowCallback(true);
              setCallbackDetail(result.data);
              sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
              sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
            }
          });
        }
      }
    };
    return config;
  };
  const openRazorpayModal = (razorpayData) => {
    const {
      order = {},
      name = '',
      description = '',
      key = '',
      user = {},
      color = '',
      image = ''
    } = razorpayData;

    const rzp1 = new window.Razorpay(
      razorpayOptions(order, name, description, key, user, color, image)
    );
    rzp1.open();
  };
  const openPaytmModal = (paytmData) => {
    const { data: { data = {}, merchant = {} } = {} } = paytmData;
    setLoadingScreen(true);
    const scriptElement = document.createElement('script');
    document.body.appendChild(scriptElement);
    scriptElement.async = true;
    scriptElement.crossorigin = 'anonymous';
    scriptElement.type = 'application/javascript';
    scriptElement.src = `https://${data.hostname}/merchantpgpui/checkoutjs/merchants/${data.mid}.js`;
    const config = paytmConfigOption(data, merchant);
    scriptElement.onload = () => {
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.onLoad(() => {
          window.Paytm.CheckoutJS.init(config)
            .then(() => {
              window.Paytm.CheckoutJS.invoke();
              setLoadingScreen(false);
            })
            .catch(() => {
              setLoadingScreen(false);
            });
        });
      }
    };
  };
  async function simulateTransaction(info) {
    try {
      setLoadingScreen(false);
      window.location.href = info.url;
    } catch (error) {
      setLoadingScreen(false);
      console.error('Error:', error);
    }
  }
  const handlePaymentClick = async () => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const txnType = 'ECOMMERCE';
    const apiUrl = activePaymentOption.method;
    if (activePaymentOption.name.toLowerCase() === 'razorpay') {
      const response = await callRazorPayApi(userId, txnType, apiUrl, addressDetails, priceSummary);
      if (response.status && response.status === 200) {
        openRazorpayModal(response.data);
      }
    }
    if (activePaymentOption.name.toLowerCase() === 'phonepe') {
      const response = await callRazorPayApi(userId, txnType, apiUrl, addressDetails, priceSummary);
      if (response.status && response.status === 200) {
        setLoadingScreen(true);
        const {
          order: {
            data: { data: { instrumentResponse: { redirectInfo = {} } = {} } = {} } = {}
          } = {}
        } = response.data;
        simulateTransaction(redirectInfo);
      }
    }
    if (activePaymentOption.name.toLowerCase() === 'paytm') {
      const response = await callRazorPayApi(userId, txnType, apiUrl, addressDetails, priceSummary);
      if (response.status && response.status === 200) {
        openPaytmModal(response.data);
      }
    }
    if (activePaymentOption.name.toLowerCase() === 'cash on delivery') {
      resetLoginWithMobileReducer();
      handleOpenModal();
    }
  };
  const handleFreePaymentClick = async () => {
    resetLoginWithMobileReducer();
    handleOpenModal();
  };
  const renderMainLayout = () => (
    <div className="app-auth-wrapper">
      <div className="image-container">
        <div>
          <div className="title">{AuthString.VERIFY}</div>
          <div className="description">
            {priceSummary?.totalPricePayable === 0
              ? AuthString.VERIFY_FREE_DESCRIPTION
              : AuthString.VERIFY_COD_DESCRIPTION}
          </div>
        </div>
        <div>
          <LoginModalIcon />
        </div>
      </div>
      <div className="form-container">
        <CashOnDeliveryOtpVerify />
      </div>
    </div>
  );
  const renderCODVerifyModal = () => (
    <Modal
      size="lg"
      show={openModal}
      onHide={handleOpenModal}
      centered
      contentClassName="auth-modal">
      <Modal.Body className="auth-modal-body">
        <button
          aria-label="close"
          type="button"
          onClick={handleOpenModal}
          className="auth-modal-close-btn">
          <Close color="#666" />
        </button>
        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
  const getText = (isSuccess) => {
    if (isSuccess) {
      if (
        activePaymentOption.name.toLowerCase() === 'cash on delivery' ||
        priceSummary?.totalPricePayable === 0
      ) {
        return PaymentString.COD_PAYMENT_SUCCESSFUL;
      }
      return PaymentString.PAYMENT_SUCCESSFUL;
    }
    if (
      activePaymentOption.name.toLowerCase() === 'cash on delivery' ||
      priceSummary?.totalPricePayable === 0
    ) {
      return PaymentString.COD_PAYMENT_FAILURE;
    }
    return PaymentString.PAYMENT_FAILURE;
  };

  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
  const userDetails =
    userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;
  const location = useLocation();

  useEffect(() => {
    if (paymentMethodData.length > 0) {
      const firstPaymentMethod = paymentMethodData[0].name;
      setSelectedPaymentMethod(firstPaymentMethod);
    }
  }, [paymentMethodData]);

  const handleButtonClick = () => {
    trackEvent(
      'Payment',
      'Proceed to Buy from Payment page',
      `${userDetails} clicked "Make Payment" btn and selected ${selectedPaymentMethod} option on ${location.pathname}.`
    );

    if (priceSummary?.totalPricePayable === 0) {
      handleFreePaymentClick();
    } else {
      handlePaymentClick();
    }
  };
  return loading ? (
    <div>...</div>
  ) : (
    <>
      {priceSummary?.totalPricePayable !== 0 && (
        <Form className="payment-options-details">
          {paymentMethodData.map((item) => (
            <PaymentItem
              value={item.name}
              key={item.name}
              onChange={() => {
                setSelectedPaymentMethod(item.name);
                handleOnChangePaymentMethod(item);
              }}
              checked={item.name === activePaymentOption.name}
              imgSrc={URLS.BASE_MEDIA_URL + URLS.BASE_IMAGE_URL + item.image}
            />
          ))}
        </Form>
      )}
      {showCallback && (
        <Callback
          show={showCallback}
          onClose={handleOnClose}
          isSuccess={isSuccessCallback}
          address={addressDetails}
          transactionDetail={callbackDetail}
          title={getText(isSuccessCallback)}
        />
      )}
      <div className="payment-summary-action">
        {paymentMethodData && paymentMethodData.length > 0 && (
          <Button
            className="app-gradient-btn"
            buttonText={
              activePaymentOption.name.toLowerCase() === 'cash on delivery' ||
              priceSummary?.totalPricePayable === 0
                ? 'Place Order'
                : 'Make payment'
            }
            onClick={loadingScreen ? null : handleButtonClick}
            loading={loadingScreen}
          />
        )}
      </div>
      {openModal && renderCODVerifyModal()}
    </>
  );
}
Payment.propTypes = {
  addressDetails: PropTypes.arrayOf(
    PropTypes.shape({
      street: PropTypes.string.isRequired,
      locality: PropTypes.string.isRequired,
      landmark: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired
    })
  ),
  getPaymentMethod: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetLoginWithMobileReducer: PropTypes.func.isRequired,
  changeActivePaymentOption: PropTypes.func.isRequired,
  priceSummary: PropTypes.object.isRequired,
  getUserInfo: PropTypes.func.isRequired
};
Payment.defaultProps = {
  addressDetails: {}
};
const mapStateToProps = (state) => {
  const { paymentGateway, paymentMethod } = state;
  return { paymentGateway, paymentMethod };
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH }),
  resetRazorPayApi: () =>
    dispatch({
      type: PaymentTypes.RAZORPAY_PAYMENT_GATEWAY_RESET
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetLoginWithMobileReducer: () =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW
    }),
  getPaymentMethod: () => dispatch({ type: PaymentMethodTypes.GET_PAYMENT_METHOD }),
  changeActivePaymentOption: (activePayment) =>
    dispatch({
      type: PaymentMethodTypes.SET_ACTIVE_PAYMENT_OPTION,
      activePayment
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
