// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';
import { Cookies } from 'react-cookie-consent';

export const trackPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page });
};
export const trackEvent = (category, action, label) => {
  const consent = Cookies.get('CookieConsent');
  if (consent === 'false') {
    return;
  }
  ReactGA.event({
    category,
    action,
    label
  });
};
