/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './TrackOrder.scss';
import ORDER_STATUS from '../../../constants/strings/myAccount/Trackorder';

export default function TrackingStatus(props) {
  const { orderStatus: { createdAt, status = [], estimatedDelivery } = {} } = props;
  const orderedStatus = status.length > 0 && status.find((o) => o.status === ORDER_STATUS.ORDERED);
  const shippedStatus = status.length > 0 && status.find((o) => o.status === ORDER_STATUS.DISPATCH);
  const confirmedStatus =
    status.length > 0 && status.find((o) => o.status === ORDER_STATUS.CONFIRMED);
  const outForDeliveryStatus =
    status.length > 0 && status.find((o) => o.status === ORDER_STATUS.OUT_FOR_DELIVERY);
  const deliveredStatus =
    status.length > 0 && status.find((o) => o.status === ORDER_STATUS.DELIVERIED);
  const orderDate = moment(createdAt).format('DD MMM YYYY');
  const confirmedDate = confirmedStatus?.date
    ? moment(confirmedStatus?.date).format('DD MMM YYYY')
    : '';
  const deliveryDate = moment(estimatedDelivery, 'DD-MM-YYYY').format('DD MMM YYYY');
  const deliveryDateValid = moment(estimatedDelivery, 'DD-MM-YYYY', true).isValid();
  const shippedDate = shippedStatus?.date ? moment(createdAt).format('DD MMM YYYY') : '';

  const renderTrack = (title, isCompleted, showProgressBar, progressPercentage) => (
    <>
      <div className="status-stopage-flex">
        <div className={`icon ${isCompleted && 'active-status'}`} />
        <div className="title">{title}</div>
      </div>
      {showProgressBar && (
        <div className="progress-bar-container">
          <div className="custom-progress">
            <div
              className="custom-progress-bar"
              style={{
                height: deliveredStatus?.isCompleted ? '100%' : progressPercentage
              }}
            />
          </div>
        </div>
      )}
    </>
  );
  return (
    <div className="app-track-status-wrapper">
      {renderTrack(
        `Ordered, ${orderDate}`,
        deliveredStatus?.isCompleted ? true : !!orderedStatus?.isCompleted,
        true,
        orderedStatus?.isCompleted ? (confirmedStatus?.isCompleted && '100%') || '60%' : '0%'
      )}
      {renderTrack(
        `Confirmed ${confirmedDate}`,
        deliveredStatus?.isCompleted ? true : !!confirmedStatus?.isCompleted,
        true,
        confirmedStatus?.isCompleted ? (shippedStatus?.isCompleted && '100%') || '60%' : '0%'
      )}
      {renderTrack(
        `Dispatched ${shippedDate}`,
        deliveredStatus?.isCompleted ? true : !!shippedStatus?.isCompleted,
        true,
        shippedStatus?.isCompleted ? (outForDeliveryStatus?.isCompleted && '100%') || '60%' : '0%'
      )}
      {renderTrack(
        'Out for delivery',
        deliveredStatus?.isCompleted ? true : !!outForDeliveryStatus?.isCompleted,
        true,
        outForDeliveryStatus?.isCompleted ? (deliveredStatus?.isCompleted && '100%') || '60%' : '0%'
      )}
      {renderTrack(
        deliveredStatus?.isCompleted
          ? `Arrived, ${moment(deliveredStatus?.date).format('DD MMM YYYY')}`
          : `${deliveryDateValid ? `Arriving on ${deliveryDate}` : 'Arriving Soon'}`,
        deliveredStatus?.isCompleted ? true : !!deliveredStatus?.isCompleted,
        false,
        '100%'
      )}
    </div>
  );
}
TrackingStatus.propTypes = {
  orderStatus: PropTypes.object.isRequired
};
