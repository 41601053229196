/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Rating } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import InputField from '../../../unicellular/inputField';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import AuthString from '../../../../constants/strings/auth/Auth';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import { validateReturnForm } from './FormValidation';
import STORAGE_KEYS from '../../../../constants/storageKey';
import { ReviewTypes } from '../../../../reducers/order/reviewDetails';
import { makePostMediaApiCall, makeDeleteMediaApiCall } from '../../../../adapters';
import URLS from '../../../../constants/urls';
import './Rating.scss';

function RatingModal(props) {
  const { show, onClose, productId, callReviewOrderApi, name, categoryName, image } = props;
  const { reviewDetails } = useSelector((state) => state);
  const { load } = reviewDetails;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const [rating, setRating] = useState('');
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [images, setImages] = useState([]);
  const [err, setError] = useState('');
  const handleComment = (e) => {
    setComment(e.target.value);
    setError('');
  };
  const handleRating = (e) => {
    setRating(e.target.value);
    setError('');
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
    setError('');
  };
  const isValidate = () => {
    let errors = '';
    errors = validateReturnForm(title, comment);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  const handleOrderRating = () => {
    const valid = isValidate();
    const imagesArray = images && images.length > 0 ? images.map((item) => item.fileName) : [];
    if (valid) {
      callReviewOrderApi(rating, title, comment, productId, userId, imagesArray);
      setError('');
    }
  };

  async function uploadSingleFile(e) {
    const img = e.target.files[0];
    const pic = window.URL.createObjectURL(img);
    const imagesArray = images;
    const formData = new FormData();
    formData.append('image', img);
    formData.append('path', 'images/reviews/');
    try {
      const response = await makePostMediaApiCall(URLS.API_ADD_IMAGE, formData);
      if (response.status === 200) {
        const {
          data: { flieName }
        } = response;
        imagesArray.push({ url: pic, fileName: flieName });
        setImages(() => [...imagesArray]);
      }
    } catch (error) {
      console.warn('image upload error>>', error);
    }
  }
  async function removeFile(item) {
    const imagesArray = images;
    const index = imagesArray.indexOf(item);
    if (index > -1) {
      const fileName = item?.fileName;
      try {
        const payload = {
          path: 'images/reviews/'
        };
        const response = await makeDeleteMediaApiCall(URLS.API_DELETE_IMAGE(fileName), payload);
        if (response.status === 200) {
          imagesArray.splice(index, 1);
          setImages(() => [...imagesArray]);
        }
      } catch (error) {
        console.warn('image deleted error>>', error);
      }
    }
  }
  async function removeAllFile() {
    const imagesArray = images && images.length > 0 ? images.map((item) => item.fileName) : [];
    if (imagesArray?.length > 0) {
      try {
        const payload = {
          path: 'images/reviews/',
          files: imagesArray
        };
        const response = await makeDeleteMediaApiCall(URLS.API_DELETE_ALL_IMAGE(null), payload);
        if (response.status === 200) {
          setImages(() => []);
        }
      } catch (error) {
        console.warn('image deleted error>>', error);
      }
    }
  }
  const handleOnHideModal = () => {
    removeAllFile();
    onClose();
  };
  const renderMainLayout = () => (
    <div className="app-rating-dialog">
      <div className="rating-order-product-detail ">
        <div className="inner-layout">
          <div className="product-image">
            <img
              className="product-image"
              src={generateUrl(image)}
              alt=""
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
          </div>
          <div className="app-myorder-details">
            <div className="app-product-name">{name}</div>
            <div className="kitname">{categoryName}</div>
          </div>
        </div>
      </div>
      <div className="row rating-order-bar-section">
        <div className="rating-title ">Rate The Product</div>
        <Rating
          name="size-large"
          defaultValue={2}
          value={rating}
          onChange={handleRating}
          size="large"
          sx={{
            fontSize: '4rem'
          }}
        />
        <div className="col-md-6 review-title">
          <div className="review-title">Review The Product</div>
          <div className=" review-area">
            <InputField
              className="form-control app-rating-input-text"
              value={title}
              onChange={handleTitle}
              placeholder="Title"
            />
          </div>
          <div className=" review-area">
            <textarea
              className="form-control app-rating-text-area"
              id="exampleFormControlTextarea1"
              rows="5"
              value={comment}
              onChange={handleComment}
              placeholder="Comments"
            />
          </div>
          <div className="app-upload-image">
            <div className="image-group-wrapper">
              {images.map((item) => (
                <div key={`images-${item}`} className="uploaded-images">
                  <button
                    aria-label="remove"
                    type="button"
                    className="remove-img-btn"
                    onClick={() => removeFile(item)}>
                    <Close color="#fff" />
                  </button>
                  <img src={item?.url} alt="notfound" />
                </div>
              ))}
            </div>
            {images.length < 4 && (
              <div className="review-img-upload-input-container">
                <label htmlFor="review-img-upload" className="review-img-upload-label">
                  <input
                    type="file"
                    disabled={images.length === 4}
                    className="input-file-control"
                    id="review-img-upload"
                    onChange={uploadSingleFile}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>{err !== '' && <div className="rating-modal-error-message">{err}</div>}</div>
      <div className="app-auth-action-button">
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.ADD_REVIEW}
            loading={load}
            className="app-auth-login-btn"
            onClick={handleOrderRating}
          />
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleOnHideModal}
      centered
      contentClassName="rating-model">
      <Modal.Body className="rating-modal-body">
        <div className="rating-modal-header-container">
          <div>Rating and Review</div>
          <button
            aria-label="rate-review"
            type="button"
            onClick={handleOnHideModal}
            className="rating-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>
        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}

RatingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  callReviewOrderApi: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { reviewDetails } = state;
  return { reviewDetails };
};

const mapDispatchToProps = (dispatch) => ({
  callReviewOrderApi: (rating, title, comments, productId, userId, images) =>
    dispatch({
      type: ReviewTypes.SET_REVIEW,
      rating,
      title,
      comments,
      productId,
      userId,
      images
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingModal);
