import mirrorKeyValue from 'mirror-key-value';

/* ------------- Types ------------- */

export const makePaymentTypes = mirrorKeyValue([
  'MAKE_PAYMENT_LIST',
  'MAKE_PAYMENT_LIST_INDICATOR',
  'MAKE_PAYMENT_LIST_SUCCESS',
  'MAKE_PAYMENT_LIST_FAILED'
]);

const {
  MAKE_PAYMENT_LIST,
  MAKE_PAYMENT_LIST_INDICATOR,
  MAKE_PAYMENT_LIST_SUCCESS,
  MAKE_PAYMENT_LIST_FAILED
} = makePaymentTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  makePaymentData: [],
  error: false,
  errorMsg: ''
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case MAKE_PAYMENT_LIST:
      return { ...state, ...data };
    case MAKE_PAYMENT_LIST_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case MAKE_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        makePaymentData: action.response,
        error: false
      };
    case MAKE_PAYMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };

    default:
      return state;
  }
}
