import React from 'react';
import Details from './Details';
import SectionHeader from './SectionHeader';

function BulkOrders() {
  return (
    <div>
      <div className="app-bulk-orders-header" />
      <SectionHeader />
      <Details />
    </div>
  );
}

export default BulkOrders;
