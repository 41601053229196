import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputField from '../../../../components/unicellular/inputField';
import Button from '../../../../components/unicellular/button';
import AuthString from '../../../../constants/strings/auth/Auth';
import { validateLoginFormWithEmail } from './FormValidation';
import EmailOtp from '../../../auth/otpEmail';
import { LoginWithMobileTypes } from '../../../../reducers/auth/login/loginWithMobile';
import { OtpTypes } from '../../../../reducers/auth/otp/otp';
import PlaceholderString from '../../../../constants/strings/PlaceholderString';
import './Styles.scss';

// eslint-disable-next-line react/jsx-props-no-spreading

function EmailOtpVerify(props) {
  const [userId, setEmail] = useState('');
  const [err, setError] = useState('');
  const [isLoginWithMobileLoading, setisLoginWithMobileLoading] = useState(false);
  const { callEmailOtpVerificationApi, resetLoginWithMobile, resetOtpReducer, editedEmail } = props;
  const { loginWithMobile } = useSelector((state) => state);
  const { lmerror, lmerrorMsg, isOtpSent, lmloading } = loginWithMobile;
  const {
    login: { user: { email } = {} }
  } = useSelector((state) => state);
  const resetError = () => {
    setError('');
  };
  const handleOtpSend = () => {
    resetLoginWithMobile();
    resetOtpReducer();
  };
  useEffect(() => {
    if (editedEmail) {
      setEmail(editedEmail);
    } else {
      setEmail(email);
    }
    if (isOtpSent) {
      setisLoginWithMobileLoading(false);
    }
  }, [isOtpSent]);

  const isValidateOnlyEmail = () => {
    let errors = '';
    errors = validateLoginFormWithEmail(userId);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      const valid = isValidateOnlyEmail();
      if (valid) {
        callEmailOtpVerificationApi(userId, false);
        setError('');
      }
    }
  };
  const submitFormwithEmail = () => {
    const valid = isValidateOnlyEmail();
    if (valid) {
      callEmailOtpVerificationApi(userId, false);
      setError('');
    }
    setisLoginWithMobileLoading(true);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    resetError();
  };
  const renderForm = () => (
    <>
      <div className="app-cod-verify-input-flield">
        <InputField
          isIcon={false}
          value={userId}
          onChange={handleEmail}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.EMAIL_ADDRESS}
          disabled={editedEmail}
        />
      </div>
      <div className="app-cod-verify-input-flield">
        {err !== '' && <div className="app-auth-error">{err}</div>}
        {lmerror && <div className="app-auth-error">{lmerrorMsg}</div>}
      </div>
      <div className="app-auth-action-button">
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.VERIFY}
            className="app-auth-otp-btn"
            onClick={submitFormwithEmail}
            loading={lmloading && isLoginWithMobileLoading}
            spinnerColor="primary"
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="app-cod-verify-container">
      {isOtpSent ? <EmailOtp userId={userId} onClickResend={handleOtpSend} /> : <>{renderForm()}</>}
    </div>
  );
}
EmailOtpVerify.propTypes = {
  resetLoginWithMobile: PropTypes.func.isRequired,
  callEmailOtpVerificationApi: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  editedEmail: PropTypes.string
};
EmailOtpVerify.defaultProps = {
  editedEmail: null
};

const mapStateToProps = (state) => {
  const { login, loginWithMobile, forgetPassword } = state;
  return { login, loginWithMobile, forgetPassword };
};

const mapDispatchToProps = (dispatch) => ({
  callEmailOtpVerificationApi: (email, isForgot) =>
    dispatch({
      type: LoginWithMobileTypes.SEND_EMAIL_OTP,
      email,
      isForgot
    }),
  resetLoginWithMobile: () =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailOtpVerify);
