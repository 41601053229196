import Verify from '../../constants/verify';
import { checkMobileNumber } from '../auth/FormValidation';

export const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateAddressForm = (purchaserInfo, products) => {
  let errors = '';
  if (!purchaserInfo?.name) {
    errors = Verify.VERIFY_NAME_EMPTY;
    return errors;
  }
  if (!purchaserInfo?.mobile) {
    errors = Verify.VERIFY_MOBILE_EMPTY;
    return errors;
  }
  if (purchaserInfo?.mobile && purchaserInfo?.mobile?.length < 10) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (purchaserInfo?.mobile && !checkMobileNumber(purchaserInfo?.mobile)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (purchaserInfo?.email && !emailValidation(purchaserInfo?.email)) {
    errors = Verify.VERIFY_EMAIL_INVALID;
    return errors;
  }
  if (products && products?.length === 0) {
    errors = '* Please add atleast one product';
    return errors;
  }
  return errors;
};
