/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OwlCarousel from 'react-owl-carousel';

import './Styles.scss';
import { generateUrl } from '../../../../utils/ImageUtils';

function ProjectAssistanceLink(props) {
  const { mediaType, media, description, title, mediaOverlay, action, mediaArray, subTitle, tag } =
    props;
  const responsive = {
    0: { items: 1 },
    550: { items: 1 },
    600: { items: 1 },
    1000: { items: 1 }
  };
  const [isReadMore, setIsReadMore] = useState(true);
  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const checkLimitOfDescription = (data) => {
    if (isReadMore) {
      return data?.substring(0, 400);
    }
    return data?.substring(0, data?.length);
  };
  const renderMediaContainer = () => (
    <div
      className={`${
        mediaType === 'image-slider' ? 'media-container-slider' : 'media-image-container'
      }`}>
      {mediaType === 'image-slider' ? (
        <div className="media-box">
          <OwlCarousel
            className="owl-theme"
            navText={[
              "<div class='app-yp-testimonial-nav-left' />",
              "<div  class='app-yp-testimonial-nav-right' />"
            ]}
            loop={false}
            margin={20}
            nav
            responsive={responsive}
            autoplay={5000}
            dotsClass="app-yp-banner-owl-dots">
            {mediaArray?.map((item, index) => (
              <img src={generateUrl(item?.image)} alt={title} srcSet="" key={index?.toString()} />
            ))}
          </OwlCarousel>
          <div className="media-circle-icon">
            <img src={generateUrl(mediaOverlay)} alt="Media Icon" srcSet="" />
          </div>
        </div>
      ) : (
        <img src={generateUrl(media)} alt={title} srcSet="" />
      )}
    </div>
  );
  const renderActionButton = () => (
    <a href={action?.navigateTo} target="_blank" rel="noreferrer">
      <button
        type="button"
        className="action-btn"
        style={{ background: action?.background, color: action?.textColor }}>
        {action?.text}
      </button>
    </a>
  );
  return (
    <div className="app-pals-container">
      <div className="container align-container">
        <h3 className="app-pal-page-tag-line">{tag}</h3>
        <h1 className="app-pal-page-title">{title}</h1>
        <div className="row column-reverse-row">
          <div className="col-lg-5 col-sm-12 align-self-center">{renderMediaContainer()}</div>
          <div className="col-lg-1 col-sm-12" />
          <div className="col-lg-6 col-sm-12">
            <div className="app-pal-page-sub-title">{subTitle}</div>
            {description?.length > 450 ? (
              <div className="app-pal-page-description">
                {checkLimitOfDescription(description)}{' '}
                <span onClick={handleReadMore}>{isReadMore ? 'Read more' : 'Read less'}</span>
              </div>
            ) : (
              <div className="app-pal-page-description">{description}</div>
            )}
            <div className="app-ibc-pal-info">{renderActionButton()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectAssistanceLink.propTypes = {
  description: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  media: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  mediaOverlay: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  mediaArray: PropTypes.array,
  tag: PropTypes.string,
  subTitle: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    background: PropTypes.string,
    text: PropTypes.string,
    textColor: PropTypes.string,
    navigateTo: PropTypes.string
  })
};
ProjectAssistanceLink.defaultProps = {
  mediaOverlay: '',
  tag: null,
  subTitle: '',
  mediaArray: [],
  action: {
    type: '',
    background: '',
    text: '',
    textColor: '',
    navigateTo: ''
  }
};

export default ProjectAssistanceLink;
