import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Information from './Information';
import Rating from './Rating';
import STORAGE_KEYS from '../../../constants/storageKey';
import { InfoTypes } from '../../../reducers/info/information';
import ProductReviewLoader from './Loader';

function Details(props) {
  const { callProductApi } = props;
  const { information, rating } = useSelector((state) => state);
  const { loading, productData } = information;
  const { ratingsData = {} } = rating;
  const { reviews: { avgRating = 0, totalRatings = 0, totalReviews = 0 } = {} } = ratingsData;
  const { product: { name, images = [] } = {} } = productData;

  const params = useParams();
  const { id } = params;
  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callProductApi(id, userId);
  }, [id]);
  return (
    <div>
      {loading ? (
        <ProductReviewLoader value={String.LOADING} />
      ) : (
        <div>
          <div className="row">
            <div className="col-md-4">
              <Information
                images={images}
                name={name}
                avgRating={avgRating}
                totalRatings={totalRatings}
                totalReviews={totalReviews}
                productId={id}
              />
            </div>
            <div className="col-md-8">
              <Rating name={name} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Details.propTypes = {
  callProductApi: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { information, rating } = state;
  return { information, rating };
};
const mapDispatchToProps = (dispatch) => ({
  callProductApi: (id, userId) =>
    dispatch({
      type: InfoTypes.FETCH_PRODUCT,
      id,
      userId
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
