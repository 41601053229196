import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { SuggestionTypes } from '../../../../reducers/product/suggestion';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import InputField from '../../../unicellular/inputField';
import ProductString from '../../../../constants/strings/product';
import { validateSuggestProductForm } from './FormValidation';

import './Suggestion.scss';
import { LoginTypes } from '../../../../reducers/auth/login/login';
import STORAGE_KEYS from '../../../../constants/storageKey';

function SuggestionModal(props) {
  const { show, onClose, callSuggestproductApi, resetLogin } = props;
  const {
    login: { user: { name, email, mobile } = {}, isUserLogin, isUserFetched },
    suggestion: { load }
  } = useSelector((state) => state);
  const [productName, setProduct] = useState('');
  const [userEmail, setEmail] = useState('');
  const [userMobile, setMobile] = useState('');
  const [userName, setName] = useState('');
  const [err, setError] = useState('');
  const [isNew, setIsNew] = useState(true);
  useEffect(() => {
    const { getUserInfo } = props;
    if (!isUserFetched && isUserLogin && isNew) {
      setIsNew(false);
      getUserInfo(localStorage.getItem(STORAGE_KEYS.USER_ID));
    }
    if (isUserFetched && isUserLogin) {
      setEmail(email);
      setMobile(mobile);
      setName(name);
      resetLogin();
    }
  }, [isUserFetched, isUserLogin, localStorage.getItem(STORAGE_KEYS.USER_ID)]);
  const handleProduct = (e) => {
    setProduct(e.target.value);
    setError('');
  };
  const handleName = (e) => {
    const regexp = /^[a-zA-Z ]*$/;
    if (regexp.test(e.target.value)) {
      setName(e.target.value);
      setError('');
    }
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError('');
  };
  const handleMobile = (e) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setMobile(e.target.value);
    }
    setError('');
  };

  const isValidate = () => {
    let errors = '';
    errors = validateSuggestProductForm(productName, userEmail, userMobile);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    if (valid) {
      callSuggestproductApi(productName, userEmail, userMobile, userName);
      setError('');
    }
  };
  const renderMainLayout = () => (
    <div className="suggestion-modal-body-container">
      <div className="row">
        <div className="col-sm-7 col-lg-6">
          <div className="title" />
          <div className="detail">{ProductString.SUGGESTION_DESC}</div>
        </div>
        <div className="col-sm-7 col-lg-6 app-home-about-info-container">
          <div className="suggestion-modal-input-view">
            <InputField
              placeholder="Product you are looking for*"
              value={productName}
              onChange={handleProduct}
              type="text"
              className="suggestion-modal-input"
            />
          </div>
          <div className="suggestion-modal-input-view">
            <InputField
              value={userEmail}
              onChange={handleEmail}
              placeholder="Email*"
              className="suggestion-modal-input"
            />
          </div>
          <div className="suggestion-modal-input-view">
            <InputField
              value={userMobile}
              onChange={handleMobile}
              placeholder="Mobile Number (Optional)"
              className="suggestion-modal-input"
            />
          </div>
          <div className="suggestion-modal-input-view">
            <InputField
              placeholder="Name (Optional)"
              value={userName}
              onChange={handleName}
              className="suggestion-modal-input"
            />

            <div>{err !== '' && <div className="suggestion-model-error-message">{err}</div>}</div>
          </div>
          <Button buttonText="Submit" loading={load} onClick={submitForm} className="submit-btn" />
        </div>
      </div>
    </div>
  );
  return (
    <Modal size="lg" show={show} onHide={onClose} centered contentClassName="suggestion-model">
      <Modal.Body className="suggestion-model-body">
        <div className="suggestion-model-header-container">
          <div>{ProductString.SUGGESTION_TITLE}</div>
          <button
            aria-label="close"
            type="button"
            onClick={onClose}
            className="suggestion-model-close-btn">
            <Close color="#666" />
          </button>
        </div>

        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}
SuggestionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callSuggestproductApi: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { suggestion, login } = state;
  return { suggestion, login };
};
const mapDispatchToProps = (dispatch) => ({
  callSuggestproductApi: (productName, userEmail, userMobile, name) =>
    dispatch({
      type: SuggestionTypes.GET_SUGGESTION,
      productName,
      userEmail,
      userMobile,
      name
    }),
  getUserInfo: (userId) => dispatch({ type: LoginTypes.GET_USER_FETCH, userId }),
  resetLogin: () => dispatch({ type: LoginTypes.RESET_GET_USER_FETCH })
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionModal);
