import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall } from '../../adapters';
import { CategoryTypes } from '../../reducers/category/allCategory';
import String from '../../constants/strings';
import URLS from '../../constants/urls';

function* getCategoryList() {
  try {
    yield put({ type: CategoryTypes.GET_ALL_CATEGORY_INDICATOR });
    const params = {
      page: 1,
      size: 100
    };

    const response = yield call(makeGetApiCall, URLS.API_ALL_CATEGORY, params);
    if (response.status === 200) {
      yield put({
        type: CategoryTypes.GET_ALL_CATEGORY_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: CategoryTypes.GET_ALL_CATEGORY_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CategoryTypes.GET_ALL_CATEGORY_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* CategorySaga() {
  try {
    yield all([takeLatest(CategoryTypes.GET_ALL_CATEGORY, getCategoryList)]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default CategorySaga;
