import mirrorKeyValue from 'mirror-key-value';
import Sorting from '../../constants/sort';

const { ASC } = Sorting;

/* ------------- LoginType ------------- */

export const FilterTypes = mirrorKeyValue([
  'SET_PRICE_FILTER',
  'SET_COLOR_FILTER',
  'SET_DISCOUNT_FILTER',
  'SET_SORT_MAP_FILTER',
  'CLEAR_FILTERS',
  'RESET_FILTER_API_CALL',
  'SEARCH_QUERY_FILTER',
  'RESET_INITIAL_FILTER'
]);

const {
  SET_PRICE_FILTER,
  SET_COLOR_FILTER,
  SET_DISCOUNT_FILTER,
  SET_SORT_MAP_FILTER,
  CLEAR_FILTERS,
  RESET_FILTER_API_CALL,
  SEARCH_QUERY_FILTER,
  RESET_INITIAL_FILTER
} = FilterTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  filterColorIn: [],
  filterPrice: [],
  filterDiscount: [],
  sortMap: { name: ASC },
  isCallApi: true,
  doClearFilter: false,
  searchQuery: null
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case SET_PRICE_FILTER:
      return {
        ...state,
        filterPrice: action.priceFilters,
        isCallApi: true
      };
    case SET_COLOR_FILTER:
      return {
        ...state,
        filterColorIn: action.colorIn,
        isCallApi: true
      };
    case SET_DISCOUNT_FILTER:
      return {
        ...state,
        filterDiscount: action.discountFilters,
        isCallApi: true
      };
    case SET_SORT_MAP_FILTER:
      return {
        ...state,
        sortMap: action.sortMap,
        isCallApi: true
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        doClearFilter: true
      };
    case RESET_FILTER_API_CALL:
      return {
        ...state,
        isCallApi: false,
        doClearFilter: false
      };
    case RESET_INITIAL_FILTER:
      return {
        ...state,
        filterColorIn: [],
        filterPrice: [],
        filterDiscount: [],
        sortMap: { name: ASC },
        isCallApi: true,
        doClearFilter: false,
        searchQuery: null
      };
    case SEARCH_QUERY_FILTER:
      return {
        ...state,
        searchQuery: action.searchQuery,
        isCallApi: true
      };
    default:
      return state;
  }
}
