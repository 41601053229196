/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import HTMLFlipBook from 'react-pageflip';
import { MdOutlineArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { IoIosCloseCircle } from 'react-icons/io';
import { generateUrl } from '../../utils/ImageUtils';

function SinglePageView({ content, page, setPage, totalPages }) {
  return (
    <div className="single-page-view">
      <img width={600} height={600} src={generateUrl(content[page])} alt="Book" />

      <div className="single-page-navigation-buttons">
        <button
          type="button"
          onClick={() => setPage(Math.max(0, page - 1))}
          disabled={page <= 0}
          className={page <= 0 ? 'disabled' : ''}>
          <MdOutlineArrowBackIos /> Previous Page
        </button>

        <button
          type="button"
          onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
          disabled={page + 1 >= totalPages}
          className={page + 1 >= totalPages ? 'disabled' : ''}>
          Next Page <MdArrowForwardIos />
        </button>
      </div>
    </div>
  );
}

function BookView({ content, totalPages, pageDimensions }) {
  const flipBookRef = useRef(null);
  const [page, setPage] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const onResize = () => {
      const PADDING = 16;
      const h = window.innerHeight - PADDING;

      setHeight(h);
      setWidth(Math.round((pageDimensions.width / pageDimensions.height) * h));
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [pageDimensions]);

  const handlePrevPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipPrev();
      setPage((prevPage) => prevPage - 2);
    }
  };

  const handleNextPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipNext();
      setPage((prevPage) => prevPage + 2);
    }
  };

  return (
    <div className="bookview-container">
      <HTMLFlipBook
        key={`reload-${height}-${width}`}
        ref={flipBookRef}
        size="fixed"
        width={width}
        height={height}
        minHeight={0}
        minWidth={0}
        maxHeight={height}
        maxWidth={width}
        onFlip={(evt) => setPage(evt.data)}
        mobileScrollSupport
        clickEventForward
        useMouseEvents
        maxShadowOpacity={0.6}
        startPage={0}
        flippingTime={500}
        startZIndex={0}
        swipeDistance={0}
        drawShadow={false}
        usePortrait={false}
        autoSize={false}
        showCover={false}
        showPageCorners={false}
        disableFlipByClick={false}
        className="flipbook">
        {content.map((item, index) => (
          <img key={index} src={generateUrl(item)} alt={`Page ${index + 1}`} className="page-img" />
        ))}
      </HTMLFlipBook>
      <div className="navigation-buttons">
        <button
          className="prev-btn"
          type="button"
          aria-label="Previous Page"
          onClick={handlePrevPage}
          disabled={page <= 0}>
          <MdOutlineArrowBackIos />
        </button>
        <button
          className="next-btn"
          type="button"
          aria-label="Next Page"
          onClick={handleNextPage}
          disabled={page + 2 >= totalPages}>
          <MdArrowForwardIos />
        </button>
      </div>
    </div>
  );
}

function PreviewbookPopup({ onClose, data }) {
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth >= 980);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(null);
  const [isFetchingPageDimensions, setIsFetchingPageDimensions] = useState(false);
  const [pageDimensions, setPageDimensions] = useState(null);

  useEffect(() => {
    setIsFetchingPageDimensions(true);

    const img = new Image();
    img.src = generateUrl(data[0]);

    const onLoad = () => {
      setError(null);
      setPageDimensions({ width: img.naturalWidth, height: img.naturalHeight });
      setIsFetchingPageDimensions(false);
    };

    const onError = () => {
      setIsFetchingPageDimensions(false);
      setError(new Error('Something went wrong!'));
    };

    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);

    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [data, generateUrl]);

  const totalPages = useMemo(() => data.length, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth >= 980);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="preview-book-overlay">
      <div className="preview-book-popup-content">
        <div className="close-btn-header">
          <div aria-hidden="true" onClick={onClose}>
            <IoIosCloseCircle className="fs-1 close-btn" />
          </div>
        </div>
        {isFetchingPageDimensions && (
          <div className="loading-container">
            <div className="loading-status">Loading...</div>
          </div>
        )}

        {!isFetchingPageDimensions && error && error.message && (
          <div className="error-container">
            <div className="error-message">{error.message}</div>
          </div>
        )}
        {!error &&
          data.length > 0 &&
          (isLargeDevice && pageDimensions ? (
            <BookView content={data} totalPages={totalPages} pageDimensions={pageDimensions} />
          ) : (
            <SinglePageView content={data} page={page} setPage={setPage} totalPages={totalPages} />
          ))}
      </div>
    </div>
  );
}

PreviewbookPopup.displayName = 'PreviewbookPopup';

PreviewbookPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired
};
SinglePageView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};
BookView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalPages: PropTypes.number.isRequired,
  pageDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired
};

export default PreviewbookPopup;
