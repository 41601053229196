import Routes from '../constants/routes';

const footerValue = [
  {
    sectionTitle: 'Useful Links ',
    footerLinks: [
      {
        navigateTo: Routes.BULK_ORDERS,
        title: 'Bulk Order Enquiry'
      },
      {
        title: 'Project Assistance',
        key: 'Project Assistance',
        isExternal: true,
        navigateTo: 'https://yuvipep.com/project-assistance'
      },
      {
        navigateTo: Routes.CONTACT_US,
        title: 'Contact Us'
      },
      {
        navigateTo: Routes.FAQ,
        title: 'FAQ'
      }
    ]
  },
  {
    sectionTitle: 'Policy',
    footerLinks: [
      {
        navigateTo: Routes.PRIVACY_POLICY,
        title: 'Privacy Policy'
      },
      {
        navigateTo: Routes.TERMS_OF_USAGE,
        title: 'Terms of Use'
      },
      {
        navigateTo: Routes.RETURN_POLICY,
        title: 'Return Policy'
      },
      {
        navigateTo: Routes.SHIPPING_POLICY,
        title: 'Shipping Policy'
      }
    ]
  },
  {
    sectionTitle: 'Help',
    footerLinks: [
      {
        navigateTo: Routes.CONTACT_US,
        title: 'Contact Us'
      }
    ]
  }
  // {
  //   sectionTitle: 'Online Selling',
  //   footerLinks: [
  //     {
  //       navigateTo: Routes.FAQ,
  //       title: 'FAQ',
  //     },
  //   ],
  // },
];
export default footerValue;
