/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import momentTz from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import RoutesPaths from '../../constants/routes';
import FlashSaleCard from '../../components/multicellular/home/flashSaleCard';
import PromoCard from '../../components/multicellular/home/promoCard';
import './Home.scss';
import { CartTypes } from '../../reducers/cart/cart';
import STORAGE_KEYS from '../../constants/storageKey';
import createSlug from '../../utils/conversions/createSlug';

const initialState = {
  responsive: {
    0: { items: 2 },
    550: { items: 2 },
    600: { items: 2 },
    1000: { items: 4 },
    1400: { items: 4 }
  }
};
function FlashSale(props) {
  const { flaseSaleData, popularProductsData, loading, callAddToCartApi, callResetCart } = props;
  const { title = '', products = [], saleStart, saleEnd } = flaseSaleData;
  const { cart } = useSelector((state) => state);
  const [activeProductId, setActiveProductId] = useState([]);
  const [activeId, setActiveId] = useState('');
  const navigate = useNavigate();
  const { isSuccess } = cart;
  useEffect(() => {
    if (isSuccess) {
      setActiveId('');
      callResetCart();
    }
  }, [isSuccess]);

  // eslint-disable-next-line max-len
  const saleStartTime = momentTz(new Date(saleStart).setHours(0, 0, 0, 0)).tz(
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Kolkata'
  );
  const saleStartTimeString = saleStartTime.format();
  const saleEndTime = momentTz(new Date(saleEnd).setHours(23, 59, 59, 999)).tz(
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Kolkata'
  );
  const saleEndTimeString = saleEndTime.format();
  const currentTime = momentTz()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Kolkata')
    ?.format();
  const currentTitle =
    currentTime >= saleStartTimeString && currentTime <= saleEndTimeString
      ? title
      : popularProductsData.title;
  const currentProducts =
    currentTime >= saleStartTimeString && currentTime <= saleEndTimeString
      ? products
      : popularProductsData.products;
  let seconds = moment(saleEndTime).diff(moment(currentTime), 'seconds');
  seconds = Math.abs(Number(seconds));
  const [days, setRemainingDay] = useState(Math.floor(seconds / (3600 * 24)));
  const [hours, setRemaininghours] = useState(Math.floor((seconds % (3600 * 24)) / 3600));
  const [mins, setRemainingmins] = useState(Math.floor((seconds % 3600) / 60));
  const [second, setRemainingsecond] = useState(Math.floor((seconds % 3600) / 60));

  const countdown = useMemo(
    () => ({
      day: Math.floor(seconds / (3600 * 24)),
      min: Math.floor((seconds % 3600) / 60),
      hour: Math.floor((seconds % (3600 * 24)) / 3600),
      second: Math.floor(seconds % 60)
    }),
    [seconds]
  );
  useEffect(() => {
    if (currentTime >= saleStartTimeString && currentTime <= saleEndTimeString) {
      const timer = setInterval(() => {
        setRemainingDay(countdown.day);
        setRemaininghours(countdown.hour);
        setRemainingmins(countdown.min);
        setRemainingsecond(countdown.second);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return 'Sale Ended';
  });
  const handleCart = (id) => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const arr = activeProductId;
    arr.push(id);
    setActiveId(id);
    setActiveProductId([...arr]);
    callAddToCartApi(userId, id, 1, null);
  };
  const handleRedirection = (product) => {
    navigate(
      `${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product?.name)}/${product._id}`
    );
  };
  return (
    <div className="app-flash-sale-container app-bg-flash-sale">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
      <div className="container">
        <div className="app-flash-sale-head">
          <div className="title">{currentTitle}</div>
          {currentTime >= saleStartTimeString && currentTime <= saleEndTimeString && (
            <div className="countdown-timer">
              {`${days !== 0 ? `${days < 10 ? `0${days}` : days}:` : ''}${
                hours < 10 ? `0${hours}` : hours
              }:${mins < 10 ? `0${mins}` : mins}:${second < 10 ? `0${second}` : second}`}
            </div>
          )}
        </div>
        <div>
          <OwlCarousel
            className="owl-theme"
            navText={[
              "<div className='nav-btn prev-slide'> <span class=' nav-icon-module-left'></span></div>",
              "<div className='nav-btn next-slide'><span class=' nav-icon-module-right'></span></div>"
            ]}
            loop={false}
            margin={0}
            nav
            responsive={initialState.responsive}
            dotsClass="hidden-owl-dots">
            {loading ? (
              <div className="col">
                <FlashSaleCard loading={loading} />
              </div>
            ) : (
              currentProducts &&
              currentProducts.length > 0 &&
              currentProducts.map((item) => (
                <div key={item._id} className="app-home-kit-section">
                  <PromoCard
                    title={item.name}
                    price={item.price}
                    image={item.images ? item.images[0] : ''}
                    description={item.description}
                    isUserLogin
                    onClickCart={() => handleCart(item._id)}
                    onClick={() => handleRedirection(item)}
                    isAddedToCart={activeProductId.includes(item._id) || item.isAddedToCart}
                    loadingCart={activeId === item._id && cart?.loading}
                  />
                </div>
              ))
            )}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
FlashSale.propTypes = {
  flaseSaleData: PropTypes.object,
  popularProductsData: PropTypes.object,
  loading: PropTypes.bool,
  callAddToCartApi: PropTypes.func.isRequired,
  callResetCart: PropTypes.func.isRequired
};
FlashSale.defaultProps = {
  loading: false,
  flaseSaleData: {},
  popularProductsData: {}
};
const mapStateToProps = (state) => {
  const { cart } = state;
  return { cart };
};

const mapDispatchToProps = (dispatch) => ({
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    }),
  callResetCart: () =>
    dispatch({
      type: CartTypes.RESET_CART
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(FlashSale);
