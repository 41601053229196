import FileSaver from 'file-saver';
import URLS from '../../constants/urls';

const downloadPdf = async (pdfUrl, name) => {
  fetch(URLS.BASE_MEDIA_URL + URLS.BASE_IMAGE_URL + pdfUrl).then((response) => {
    response.blob().then((blob) => {
      const blobData = new File([blob], `${name}.pdf`);
      FileSaver.saveAs(blobData, `${name}.pdf`);
    });
  });
};

export default downloadPdf;
