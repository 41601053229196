import mirrorKeyValue from 'mirror-key-value';

/* ------------- Types ------------- */

export const PaymentStatusTypes = mirrorKeyValue([
  'GET_PAYMENT_STATUS',
  'GET_PAYMENT_STATUS_INDICATOR',
  'GET_PAYMENT_STATUS_SUCCESS',
  'GET_PAYMENT_STATUS_FAILED'
]);

const {
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_INDICATOR,
  GET_PAYMENT_STATUS_SUCCESS,
  GET_PAYMENT_STATUS_FAILED
} = PaymentStatusTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  paymentStatusData: {},
  orderData: {},
  error: false,
  errorMsg: ''
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_PAYMENT_STATUS:
      return { ...state, ...data };
    case GET_PAYMENT_STATUS_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatusData:
          action.response?.status !== 200 ? action.response.message : action.response.data,
        orderData: action.response?.order || {},
        error: false
      };
    case GET_PAYMENT_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
