import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const resendOtpTypes = mirrorKeyValue([
  'RESEND_OTP',
  'RESEND_OTP_VERIFY_WITH_MOBILE',
  'RESEND_OTP_INDICATOR',
  'RESEND_OTP_SUCCESS',
  'RESEND_OTP_FAILED',
  'RESEND_EMAIL_OTP',
  'RESET_FORGET_PASSWORD'
]);

const {
  RESEND_OTP,
  RESEND_OTP_VERIFY_WITH_MOBILE,
  RESEND_EMAIL_OTP,
  RESEND_OTP_INDICATOR,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILED,
  RESET_FORGET_PASSWORD
} = resendOtpTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  resendOtpData: [],
  error: '',
  errorMsg: '',
  isSuccess: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case RESEND_OTP:
      return { ...state, ...data };
    case RESEND_OTP_VERIFY_WITH_MOBILE:
      return { ...state, ...data };
    case RESEND_EMAIL_OTP:
      return { ...state, ...data };
    case RESEND_OTP_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        resendOtpData: action.response,
        error: false
      };
    case RESEND_OTP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case RESET_FORGET_PASSWORD:
      return {
        ...state,
        errorMsg: '',
        error: false
      };
    default:
      return state;
  }
}
