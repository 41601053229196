import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Close from '../../assets/svgs/Close';
import LoginModalIcon from '../../assets/svgs/LoginModalIcon';
import Login from './login';
import Register from './register';
import { RegisterTypes } from '../../reducers/auth/register/register';
import { LoginTypes } from '../../reducers/auth/login/login';
import { LoginWithMobileTypes } from '../../reducers/auth/login/loginWithMobile';
import { OtpTypes } from '../../reducers/auth/otp/otp';
import './Auth.scss';
import AuthString from '../../constants/strings/auth/Auth';

function Auth(props) {
  const { show, setShow } = props;
  const [switchIsLogin, setSwitchIsLogin] = useState(true);
  const handleSignUp = () => {
    setSwitchIsLogin(!switchIsLogin);
  };
  const { login } = useSelector((state) => state);
  const { isUserLogin } = login;

  const { resetRegisterReducer, resetLoginReducer, resetOtpReducer, resetLoginWithMobile } = props;
  const handleLoginClose = () => {
    setShow(false);
    resetRegisterReducer();
    resetLoginReducer();
    resetOtpReducer();
    resetLoginWithMobile();
  };
  useEffect(() => {
    if (isUserLogin) {
      setShow(false);
    }
  }, [isUserLogin]);
  const renderMainLayout = () => (
    <div className="app-auth-wrapper">
      <div className="image-container">
        <div>
          <div className="title">{switchIsLogin ? AuthString.LOGIN : AuthString.SIGN_UP}</div>
          <div className="description">
            {switchIsLogin ? AuthString.LOGIN_DESCRIPTION : AuthString.SIGNUP_DESCRIPTION}
          </div>
        </div>
        <div>
          <LoginModalIcon />
        </div>
      </div>
      <div className="form-container">
        {switchIsLogin ? (
          <Login onClickSignUp={handleSignUp} />
        ) : (
          <Register onClickSignUp={handleSignUp} />
        )}
      </div>
    </div>
  );
  return (
    <Modal size="lg" show={show} onHide={handleLoginClose} centered contentClassName="auth-modal">
      <Modal.Body className="auth-modal-body">
        <button
          aria-label="close"
          type="button"
          onClick={handleLoginClose}
          className="auth-modal-close-btn">
          <Close color="#666" />
        </button>
        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}
Auth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  resetRegisterReducer: PropTypes.func.isRequired,
  resetLoginReducer: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetLoginWithMobile: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};
const mapDispatchToProps = (dispatch) => ({
  resetRegisterReducer: () =>
    dispatch({
      type: RegisterTypes.RESET_REGISTER_VIEW
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetLoginReducer: () =>
    dispatch({
      type: LoginTypes.RESET_LOGIN_VIEW
    }),
  resetLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW,
      mobile
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
