import React from 'react';
import PropTypes from 'prop-types';
import Star from '../../../assets/svgs/Star';
import './Rating.scss';

export default function Rating(props) {
  const { avgRating } = props;
  return (
    <div className="app-rating-container" href>
      <div className="number">{Number(avgRating).toFixed(1)}</div>
      <div className="icon">
        <Star color="#ffffff" />
      </div>
    </div>
  );
}
Rating.propTypes = {
  avgRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
Rating.defaultProps = {
  avgRating: 5
};
