import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Coupon from '../coupon';
import RoutesPaths from '../../../../constants/routes';
import Button from '../../../unicellular/button';
import Auth from '../../../../containers/auth';
import PaymentString from '../../../../constants/strings/payment';
import './PaymentSummaryCard.scss';
import { trackEvent } from '../../../../analytics/googleAnalytics';

const renderPriceSplitUp = (heading, price, loading, isNegative = false) => (
  <div className="price-summary">
    <div className="heading-price">{heading}</div>
    {loading ? (
      <div className="ps-title-ld loading-animation" />
    ) : (
      <div className={`label-price ${isNegative ? 'discount' : ''}`}>
        {`${isNegative ? '-' : ''}${'\u20B9'}${price}`}
      </div>
    )}
  </div>
);

export default function PaymentSummaryCard(props) {
  const [isDisabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    priceSummary,
    //  onCouponClick,
    userDetails,
    loading
  } = props;
  const { login } = useSelector((state) => state);
  const { isUserLogin } = login;
  const location = useLocation();
  const handleContinue = () => {
    if (isUserLogin) {
      trackEvent(
        'Payment',
        'Continue to Buy from Cart',
        `${userDetails} clicked the "continue" button on ${location.pathname}`
      );
      navigate(RoutesPaths.ADDRESS_LIST);
    } else {
      setIsOpenModal(true);
    }
  };
  useEffect(() => {
    setDisabled(priceSummary?.totalQuantity === 0);
  }, [priceSummary]);
  return (
    <div className="cart-summary-container">
      <div className="cart-summary-coupon">
        <div className="apply-coupon-header">
          <i className="fa fa-tag fa-lg" />
          <div className="apply-coupon-label">{PaymentString.APPLY_COUPON}</div>
        </div>
        {loading ? null : <Coupon priceSummary={priceSummary} userDetails={userDetails} />}
      </div>
      <div className="cart-summary-pricedetails">
        {loading ? (
          <div className="ps-title-ld loading-animation" />
        ) : (
          <div className="cart-summary-pricedetails-head">
            PRICE DETAILS ({priceSummary?.totalQuantity || 0}{' '}
            {priceSummary?.totalQuantity === 1 ? 'item' : 'items'})
          </div>
        )}
        <div className="cart-summary-pricedetails-splits">
          {renderPriceSplitUp('Total MRP', priceSummary?.totalPrice || 0, loading, false)}
          {renderPriceSplitUp(
            'Discount on MRP',
            priceSummary?.totalSavedAmount || 0,
            loading,
            true
          )}
          {renderPriceSplitUp('Coupon Discount', priceSummary?.couponDiscount || 0, loading, true)}
          {renderPriceSplitUp(
            'Delivery Charges',
            priceSummary?.deliveryCharge?.price || 0,
            loading,
            false
          )}

          <div className="divider-price" />
          <div className="price-summary">
            <div className="heading-price bold">{PaymentString.AMOUNT_PAYABLE}</div>
            {loading ? (
              <div className="ps-title-ld loading-animation" />
            ) : (
              <div className="label-price bold">
                {`${'\u20B9'}${priceSummary?.totalPricePayable || 0}`}
              </div>
            )}
          </div>
        </div>
        <Button
          buttonText={isUserLogin ? PaymentString.CONTINUE : PaymentString.LOGIN_AND_CONTINUE}
          disabled={isDisabled || loading}
          className={
            isDisabled || loading ? 'app-gradient-btn-disabled' : 'app-gradient-btn app-cart-btn'
          }
          onClick={loading ? null : handleContinue}
          loading={loading}
        />
        {isOpenModal && !isUserLogin && <Auth show={isOpenModal} setShow={setIsOpenModal} />}
      </div>
    </div>
  );
}
PaymentSummaryCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  priceSummary: PropTypes.object.isRequired,
  // onCouponClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  userDetails: PropTypes.string
};

PaymentSummaryCard.defaultProps = {
  // onCouponClick: () => {},
  userDetails: 'User'
};
