import axios from 'axios';
import logout from '../utils/logoutSession/logoutSession';
import STORAGE_KEYS from '../constants/storageKey';
import URLS from '../constants/urls';

const instance = axios.create({
  baseURL: URLS.BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

const mediaInstance = axios.create({
  baseURL: URLS.BASE_MEDIA_URL,
  headers: { 'Content-Type': 'application/json' }
});

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
    // eslint-disable-next-line no-underscore-dangle
    if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      return axios
        .post(`${URLS.BASE_URL}/auth/refreshToken`, {
          refreshToken,
          userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, res.data.token);
            originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
            return axios(originalRequest);
          }
          logout();
          Promise.reject(error);
          return null;
        })
        .catch((err) => {
          logout();
          Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

export function makePostApiCall(apiUrl, params, token) {
  const headers = {
    'contest-access-token': token
  };
  return instance
    .post(apiUrl, params, { headers })
    .then((responseJson) => responseJson)
    .catch((error) => {
      console.log('POST REQ ERROR: ', error);
      return error.response;
    });
}

export function makeGetApiCall(apiUrl, params = {}, data = {}) {
  return instance
    .get(apiUrl, { params, data })
    .then((responseJson) => responseJson)
    .catch((error) => {
      console.log('GET REQ ERROR: ', error);
      return error.response;
    });
}

export function makePutApiCall(apiUrl, params, token) {
  const headers = {
    'contest-access-token': token
  };
  return instance
    .put(apiUrl, params, { headers })
    .then((responseJson) => responseJson)
    .catch((error) => {
      console.log('PUT REQ ERROR: ', error);
      return error.response;
    });
}

export function makePatchApiCall(apiUrl, params, token) {
  const headers = {
    'contest-access-token': token
  };
  return instance
    .patch(apiUrl, params, { headers })
    .then((responseJson) => responseJson)
    .catch((error) => {
      console.log('PATCH REQ ERROR: ', error);
      return error.response;
    });
}

export function makeDeleteApiCall(apiUrl, params) {
  return instance
    .delete(apiUrl, { data: params })
    .then((response) => response)
    .catch((error) => console.log('DELETE REQ ERROR: ', error));
}

export function makePostMediaApiCall(apiUrl, params, token) {
  const headers = {
    'contest-access-token': token
  };
  return mediaInstance
    .post(apiUrl, params, { headers })
    .then((responseJson) => responseJson)
    .catch((error) => {
      console.log('POST REQ ERROR: ', error);
      return error.response;
    });
}

export function makeDeleteMediaApiCall(apiUrl, params) {
  return mediaInstance
    .delete(apiUrl, { data: params })
    .then((response) => response)
    .catch((error) => console.log('DELETE REQ ERROR: ', error));
}
