import mirrorKeyValue from 'mirror-key-value';

export const InnovationTypes = mirrorKeyValue([
  'GET_INNOVATION_PRODUCT',
  'GET_INNOVATION_PRODUCT_INDICATOR',
  'GET_INNOVATION_PRODUCT_SUCCESS',
  'GET_INNOVATION_PRODUCT_FAILED',
  'GET_SHOP_BY_CATEGORY',
  'GET_SHOP_BY_CATEGORY_INDICATOR',
  'GET_SHOP_BY_CATEGORY_SUCCESS',
  'GET_SHOP_BY_CATEGORY_FAILED'
]);

const {
  GET_INNOVATION_PRODUCT,
  GET_INNOVATION_PRODUCT_INDICATOR,
  GET_INNOVATION_PRODUCT_SUCCESS,
  GET_INNOVATION_PRODUCT_FAILED,
  GET_SHOP_BY_CATEGORY,
  GET_SHOP_BY_CATEGORY_INDICATOR,
  GET_SHOP_BY_CATEGORY_SUCCESS,
  GET_SHOP_BY_CATEGORY_FAILED
} = InnovationTypes;

const INITIAL_STATE = {
  loading: false,
  homeproducts: {},
  homeCategoryWise: {},
  isCallApi: false,
  error: '',
  errorMsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_INNOVATION_PRODUCT:
      return { ...state, ...data, isCallApi: true };
    case GET_INNOVATION_PRODUCT_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_INNOVATION_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        homeproducts: action.response,
        error: false
      };
    case GET_INNOVATION_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case GET_SHOP_BY_CATEGORY:
      return { ...state, ...data };
    case GET_SHOP_BY_CATEGORY_INDICATOR:
      return {
        ...state,
        error: false
      };
    case GET_SHOP_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        homeCategoryWise: action.response,
        error: false
      };
    case GET_SHOP_BY_CATEGORY_FAILED:
      return {
        ...state,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
