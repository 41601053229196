import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Button from '../../../unicellular/button';
import AddressModal from '../addressModal';
import { AddressTypes } from '../../../../reducers/account/address/address';
import Loader from './Loader';
import './AddressCard.scss';
import STORAGE_KEYS from '../../../../constants/storageKey';
import { trackEvent } from '../../../../analytics/googleAnalytics';

function AddressCard(props) {
  const {
    name,
    addressType,
    street,
    locality,
    addressId,
    city,
    state,
    pincode,
    mobile,
    removeAddressApi,
    landmark,
    isDefault,
    resetEditAddressApi,
    resetVerifyPinCode,
    hasDevliveryAction,
    isDeliveryAddressSelected,
    onClickSelection,
    onClickDelivery,
    loading,
    tagColor,
    userDetails
  } = props;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const handleClose = () => {
    setOpenRemoveDialog(false);
  };
  const handleOpen = () => {
    setOpenRemoveDialog(true);
  };
  const onClickEdit = () => {
    setopenEdit(true);
  };
  const handleCloseEdit = () => {
    setopenEdit(false);
    resetVerifyPinCode();
    resetEditAddressApi();
  };

  const formData = {
    name,
    mobile,
    pincode,
    address: street,
    landmark,
    addressType,
    city,
    isDefault,
    addressId,
    state,
    locality
  };
  const address = `${street}, ${locality}, ${landmark}, ${city}, ${state}`;
  const handleConfirmDelete = () => {
    removeAddressApi(userId, addressId);
    setOpenRemoveDialog(false);
  };
  const renderConfirm = () => (
    <Dialog
      open={openRemoveDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className="confirm-text-title">
        Delete Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="confirm-text-description">
          Are you sure you want to delete this address?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="app-confirm-footer">
        <Button buttonText="Cancel" className="app-action-btn-cancel" onClick={handleClose} />
        <Button
          buttonText="Delete"
          className="app-action-btn-delete"
          onClick={handleConfirmDelete}
        />
      </DialogActions>
    </Dialog>
  );

  const location = useLocation();

  return loading ? (
    <Loader />
  ) : (
    <div className="address-list-item-body app-address-card">
      {hasDevliveryAction ? (
        <div className="action-button">
          <Form.Check
            inline
            type="radio"
            className="input-radio"
            checked={isDeliveryAddressSelected}
            onChange={() => {}}
            onClick={() => onClickSelection(addressId)}
          />
        </div>
      ) : null}
      <div className="address-details-item">
        <div className="app-title-main-wrap">
          <div className="app-title-flexbox">
            <div className="user-name">{name}</div>
            <div className="tag" style={{ color: tagColor, borderColor: tagColor }}>
              {addressType}
            </div>
          </div>
          <div className="address-action-wrapper">
            <Button buttonText="REMOVE" className="app-action-btn-remove" onClick={handleOpen} />
            <Button buttonText="EDIT" className="app-action-btn" onClick={onClickEdit} />
          </div>
        </div>

        <div className="app-address-info-section">
          <div className="text">{address}</div>
          <div className="text">
            India - <span>{pincode}</span>
          </div>
          <div className="text mt-20">
            Mobile: <span>{mobile}</span>
          </div>
        </div>
        <div className="app-address-card-btn-flexbox">
          {hasDevliveryAction
            ? (pincode && pincode !== null && (
                <Button
                  buttonText="Deliver Here"
                  className="delivery-action-btn app-gradient-btn"
                  onClick={() => {
                    trackEvent(
                      'Payment',
                      'Deliver Here Button Clicked',
                      `${userDetails} clicked the "Deliver Here" button on ${location.pathname}`
                    );
                    onClickDelivery(addressId);
                  }}
                />
              )) || (
                <div className="app-address-card-error">
                  * Please update the pincode for this address to facilitate the delivery.
                </div>
              )
            : null}
        </div>
      </div>
      {renderConfirm()}

      {openEdit && (
        <AddressModal show={openEdit} onClose={handleCloseEdit} isEdit editForm={formData} />
      )}
    </div>
  );
}
AddressCard.propTypes = {
  name: PropTypes.string,
  addressType: PropTypes.string,
  street: PropTypes.string,
  locality: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  pincode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobile: PropTypes.string,
  landmark: PropTypes.string,
  isDefault: PropTypes.bool,
  addressId: PropTypes.string,
  resetVerifyPinCode: PropTypes.func.isRequired,
  removeAddressApi: PropTypes.func.isRequired,
  resetEditAddressApi: PropTypes.func.isRequired,
  hasDevliveryAction: PropTypes.bool,
  isDeliveryAddressSelected: PropTypes.bool,
  onClickSelection: PropTypes.func,
  onClickDelivery: PropTypes.func,
  loading: PropTypes.bool,
  tagColor: PropTypes.string,
  userDetails: PropTypes.string
};
AddressCard.defaultProps = {
  name: '',
  addressType: '',
  street: '',
  locality: '',
  city: '',
  state: '',
  pincode: '',
  mobile: '',
  landmark: '',
  isDefault: false,
  addressId: '',
  hasDevliveryAction: false,
  isDeliveryAddressSelected: false,
  onClickSelection: () => {},
  onClickDelivery: () => {},
  loading: false,
  tagColor: '#2FB82C',
  userDetails: 'User'
};

const mapStateToProps = (state) => {
  const { address } = state;
  return { address };
};

const mapDispatchToProps = (dispatch) => ({
  resetVerifyPinCode: () => dispatch({ type: AddressTypes.RESET_VERIFY_PINCODE }),
  resetEditAddressApi: () => dispatch({ type: AddressTypes.UPDATE_ADDRESS_ERROR_RESET }),
  removeAddressApi: (userId, addressId) =>
    dispatch({
      type: AddressTypes.REMOVE_ADDRESS,
      userId,
      addressId
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(AddressCard);
