/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react';
import Header from '../../../components/multicellular/navigation/header';
import './Loader.scss';
import '../Home.scss';

const n = 5;
const infoBox = 6;
const Banner = 1;
const transform = 4;
const webinar = 4;
// eslint-disable-next-line no-unused-vars
function Loader(props) {
  return (
    <div>
      <Header isLoadCategory={false} homeActive />
      <div className="app-banner-container skel-home-banner">
        <div>
          <div className="container-fluid align-container">
            {/* Home Banner */}
            <div className="row top-space-row">
              <div>
                <div className="home-lab-banner-image banner-home-skeleton-bg" />
              </div>
            </div>
            {/* Kits */}
            <div className="skel-benefit-card-hm">
              <div className="HomeDetailCard container">
                <div className="row">
                  {[...Array(infoBox)].map((item, index) => (
                    <div key={`loading-0-${index}`} className="col-md-2">
                      <div className="homeDetail-flex skel-benefit-section">
                        <div className=" skel-vertical-body skel-ic">
                          <p className="icon" />
                          <div className="title" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* transform section */}
      <div className="app-home-banner  ">
        <div className="app-top-space" />
        <div>
          <div className="container align-container">
            <div className="row top-space-row">
              <div className="col-md-6">
                <div className="home-lab-banner-image banner-home-skeleton-bg" />
              </div>
              <div className="col-md-6 skel-right-align-center ">
                <div />
                <div className="row home-skeleton-box">
                  {[...Array(n)].map((item, index) => (
                    <span className="home-skeleton-loader-description " key={index} />
                  ))}
                  <div className="home-skel-register-button" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-space" />
      <div className="app-top-space" />
      <div className="app-path-container">
        <div className="container" />
      </div>
      <div className="app-top-space" />
      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-1-${index}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-10-${index}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>
      {/* Banner */}
      <div className="HomeDetailCard skel-benefit-card">
        <div className="col">
          <div className="homeDetail-flex skel-benefit-section">
            <p className="banner" />
          </div>
        </div>
      </div>
      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-2-${index}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-20-${index}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>
      {/* Banner */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-3-${index}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-30-${index}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>

      {/* Banner */}
      <div className="HomeDetailCard skel-benefit-card">
        <div className="col">
          <div className="homeDetail-flex skel-benefit-section">
            <p className="image" />
          </div>
        </div>
      </div>
      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-4-${index}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-40-${index}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>
      {/* Banner */}
      <div className="HomeDetailCard skel-benefit-card">
        <div className="col">
          <div className="homeDetail-flex skel-benefit-section">
            <p className="banner" />
          </div>
        </div>
      </div>
      <div className="app-top-space" />
    </div>
  );
}

export default Loader;
