/* eslint-disable react/forbid-prop-types */
import React from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { generateUrl } from '../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../constants/defaults/product';
import Bus from '../../assets/svgs/Bus';
import Rating from '../../components/unicellular/rating';
import discountToPercentage from '../../utils/conversions/discountToPercentage';
// import ProductString from '../../constants/strings/product';
// import gstAmount from '../../utils/conversions/gstAmount';
import Button from '../../components/unicellular/button';
// import String from '../../constants/strings';
// import CartString from '../../constants/strings/cart';
import RoutesPaths from '../../constants/routes';
import GreenCheck from '../../assets/svgs/GreenCheck';
import createSlug from '../../utils/conversions/createSlug';

export default function SuccessCard(props) {
  const navigate = useNavigate();
  const { product } = props;
  const {
    name: title,
    description,
    loading,
    images: [image = ''] = [],
    _id: productId,
    calculatedPrices = {},
    avgRating: rating,
    // isAddedToCart,
    loadingCart,
    isFreeDelivery
  } = product;
  // const { login } = useSelector((state) => state);
  // const { isUserLogin } = login;
  const { basePrice, netPrice, savedAmount } = calculatedPrices;

  return loading ? (
    <div className="app-kc-container ">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  ) : (
    <div className="cart-item-added-success-container">
      <div className="cart-item-added-success-container-inner app-kc-container">
        <div className="image-wrapper">
          <NavLink
            to={`${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product?.name)}/${productId}`}>
            <img
              className="image"
              src={generateUrl(image)}
              alt={title}
              srcSet=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
          </NavLink>
        </div>

        <div className="inner-body">
          <div className="kc-btn">
            <div className="success-msg">
              <GreenCheck />
              <span>Item is successfully added to your cart</span>
            </div>
            <div className="title">{title}</div>
            <div className="detail">
              {Array.isArray(description)
                ? description.slice(0, 3).map((d) => (
                    <div key={d}>
                      {d}
                      <br />
                    </div>
                  ))
                : description}
            </div>
          </div>
          <div className="app-kit-rating">
            <div className="app-kit-rating-wrapper">
              {rating > 0 && <Rating avgRating={rating} />}
            </div>
          </div>
          <div>
            <div className="btn-price-container">
              <div className="price">
                {`₹ ${netPrice}`}
                {basePrice !== netPrice && <span className="base-price">{`₹ ${basePrice}`}</span>}
                {savedAmount !== 0 && (
                  <span className="discount">
                    {`(${discountToPercentage(savedAmount, basePrice)}% off)`}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="gst-number">
              {`₹ ${gstAmount(basePrice, ProductString.gstPrice)}
              (+${ProductString.gstPrice}% GST extra)`}
            </div> */}
            {isFreeDelivery && (
              <div className="btn-fd-container app-free-delivery-kit-card">
                <div className="fd-svg">
                  <Bus />
                </div>
                <div className="text-fd">Free Delivery</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="btn-wrap-container">
        <Button
          buttonText="Continue Shopping"
          className="app-btn-add-to-cart"
          onClick={() => navigate(RoutesPaths.PRODUCT_EXPLORER)}
          loading={loadingCart}
        />
        <Button
          buttonText="Go To Cart"
          className="app-btn-buy-now"
          onClick={() => {
            navigate(RoutesPaths.CART);
          }}
        />
      </div>
    </div>
  );
}

SuccessCard.propTypes = {
  product: PropTypes.object
};

SuccessCard.defaultProps = {
  product: {}
};
