import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const AddressTypes = mirrorKeyValue([
  'GET_ADDRESS',
  'GET_ADDRESS_INDICATOR',
  'GET_ADDRESS_SUCCESS',
  'GET_ADDRESS_FAILED',
  'ADD_ADDRESS',
  'ADD_ADDRESS_INDICATOR',
  'ADD_ADDRESS_SUCCESS',
  'ADD_ADDRESS_FAILED',
  'ADD_ADDRESS_ERROR_RESET',
  'UPDATE_ADDRESS',
  'UPDATE_ADDRESS_INDICATOR',
  'UPDATE_ADDRESS_SUCCESS',
  'UPDATE_ADDRESS_FAILED',
  'UPDATE_ADDRESS_ERROR_RESET',
  'REMOVE_ADDRESS',
  'REMOVE_ADDRESS_INDICATOR',
  'REMOVE_ADDRESS_SUCCESS',
  'REMOVE_ADDRESS_FAILED',
  'VERIFY_PINCODE',
  'VERIFY_PINCODE_INDICATOR',
  'VERIFY_PINCODE_SUCCESS',
  'VERIFY_PINCODE_FAILED',
  'RESET_VERIFY_PINCODE',
  'RESET_ADD_ADDRESS',
  'RESET_FETCH_ADDRESS'
]);

const {
  GET_ADDRESS,
  GET_ADDRESS_INDICATOR,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILED,
  ADD_ADDRESS,
  ADD_ADDRESS_INDICATOR,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILED,
  ADD_ADDRESS_ERROR_RESET,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_INDICATOR,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILED,
  UPDATE_ADDRESS_ERROR_RESET,
  REMOVE_ADDRESS,
  REMOVE_ADDRESS_INDICATOR,
  REMOVE_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_FAILED,
  VERIFY_PINCODE,
  VERIFY_PINCODE_INDICATOR,
  VERIFY_PINCODE_SUCCESS,
  VERIFY_PINCODE_FAILED,
  RESET_VERIFY_PINCODE,
  RESET_ADD_ADDRESS,
  RESET_FETCH_ADDRESS
} = AddressTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  addressData: {},
  error: false,
  errorMsg: '',
  addloading: false,
  isAddAddressSuccess: false,
  addaddressData: {},
  adderror: false,
  adderrorMsg: '',
  updateloading: false,
  updateaddressData: {},
  updateerror: false,
  updateerrorMsg: '',
  removeloading: false,
  removeaddressData: {},
  removeerror: false,
  removeerrorMsg: '',
  pincodeloading: false,
  pincodeData: {},
  pincodeerror: false,
  pincodeerrorMsg: '',
  isSuccessFullyVerified: false,
  isFetchAddressSuccess: false,
  isRemoveAddressSuccess: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_ADDRESS:
      return { ...state, ...data };
    case GET_ADDRESS_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressData: action.response,
        error: false,
        isFetchAddressSuccess: true
      };
    case GET_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case ADD_ADDRESS:
      return { ...state, ...data };
    case ADD_ADDRESS_INDICATOR:
      return {
        ...state,
        addloading: true,
        adderror: false
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addloading: false,
        addaddressData: action.response,
        isAddAddressSuccess: true,
        adderror: false
      };
    case RESET_ADD_ADDRESS:
      return {
        ...state,
        isAddAddressSuccess: false,
        isRemoveAddressSuccess: false
      };
    case RESET_FETCH_ADDRESS:
      return {
        ...state,
        isFetchAddressSuccess: false
      };
    case ADD_ADDRESS_FAILED:
      return {
        ...state,
        addloading: false,
        adderror: true,
        adderrorMsg: action.message
      };
    case ADD_ADDRESS_ERROR_RESET:
      return {
        ...state,
        addloading: false,
        adderror: false,
        adderrorMsg: ''
      };
    case UPDATE_ADDRESS:
      return { ...state, ...data };
    case UPDATE_ADDRESS_INDICATOR:
      return {
        ...state,
        updateloading: true,
        updateerror: false
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updateloading: false,
        updateaddressData: action.response,
        updateerror: false
      };
    case UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        updateloading: false,
        updateerror: true,
        updateerrorMsg: action.message
      };
    case UPDATE_ADDRESS_ERROR_RESET:
      return {
        ...state,
        updateloading: false,
        updateerror: false,
        updateerrorMsg: 'action.message'
      };
    case REMOVE_ADDRESS:
      return { ...state, ...data };
    case REMOVE_ADDRESS_INDICATOR:
      return {
        ...state,
        removeloading: true,
        removeerror: false
      };
    case REMOVE_ADDRESS_SUCCESS:
      return {
        ...state,
        removeloading: false,
        removeaddressData: action.response,
        removeerror: false,
        isRemoveAddressSuccess: true
      };
    case REMOVE_ADDRESS_FAILED:
      return {
        ...state,
        removeloading: false,
        removeerror: true,
        removeerrorMsg: action.message
      };
    case VERIFY_PINCODE:
      return { ...state, ...data };
    case VERIFY_PINCODE_INDICATOR:
      return {
        ...state,
        pincodeloading: true,
        pincodeerror: false,
        isSuccessFullyVerified: false
      };
    case VERIFY_PINCODE_SUCCESS:
      return {
        ...state,
        pincodeloading: false,
        pincodeData: action.response,
        pincodeerror: false,
        isSuccessFullyVerified: true
      };
    case VERIFY_PINCODE_FAILED:
      return {
        ...state,
        pincodeloading: false,
        pincodeerror: true,
        pincodeerrorMsg: action.message,
        isSuccessFullyVerified: false
      };
    case RESET_VERIFY_PINCODE:
      return {
        ...state,
        pincodeloading: false,
        pincodeerror: false,
        pincodeerrorMsg: '',
        isSuccessFullyVerified: false
      };
    default:
      return state;
  }
}
