import mirrorKeyValue from 'mirror-key-value';

export const InfoTypes = mirrorKeyValue([
  'FETCH_PRODUCT_SUCCESS',
  'FETCH_PRODUCT_FAILED',
  'FETCH_PRODUCT',
  'FETCH_PRODUCT_INDICATOR'
]);

const { FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILED, FETCH_PRODUCT, FETCH_PRODUCT_INDICATOR } =
  InfoTypes;

const INITIAL_STATE = {
  productData: {},
  error: false,
  loading: false,
  errormsg: '',
  cartExists: false
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case FETCH_PRODUCT:
      return { ...state, ...data };
    case FETCH_PRODUCT_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        errormsg: ''
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.response,
        cartExists: action.response.isAddedToCart,
        error: false,
        errormsg: ''
      };
    case FETCH_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errormsg: action.message
      };
    default:
      return state;
  }
}
