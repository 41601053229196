const AuthString = {
  FORGET_PASSWORD: 'Forgot Password?',
  DISCLAIMER: 'By continuing, you agree to YuviPep',
  AND: 'and',
  REGISTER_MODAL_BOTTOM_SWITCH_TEXT: 'Returning User?',
  LOGIN_MODAL_BOTTOM_SWITCH_TEXT: "Don't have an account?",
  SIGN_UP: 'Sign Up',
  SIGN_IN: 'Sign In',
  LOGIN_WITH_OTP: 'Login with OTP',
  LOGIN: 'Login',
  LOGIN_DESCRIPTION: 'Get access to your Orders, Wishlist and Recommendations',
  SIGNUP_DESCRIPTION: "Looks like you're new here!",
  OTP_TITLE: 'Please enter the OTP sent to',
  OTP_BOTTOM_TEXT: " Didn't receive code yet?",
  VERIFY: 'Verify',
  CHANGE: 'Change',
  RESEND: 'Resend',
  CREATE_ACCOUNT: 'Create Account',
  CHANGE_PASSWORD: 'Change password',
  CONTINUE: 'Continue',
  ADD_REVIEW: 'Add Review',
  VERIFY_COD_DESCRIPTION:
    'Please verify with registered mobile number to continue placing the order with Pay on Delivery.',
  EMAIL: 'email',
  MOBILE: 'mobile',
  PROCEED: 'Proceed',
  RETURN_TO_LOGIN: 'Return to login?',
  VERIFY_EMAIL: 'Verify Email',
  VERIFY_EMAIL_DESCRIPTION:
    'Please verify your email to receive order updates and personalized offers.',
  VERIFY_FREE_DESCRIPTION:
    'Please verify with registered mobile number to continue placing the order.'
};
export default AuthString;
