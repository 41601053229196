import React from 'react';
import { string } from 'prop-types';

export default function Like({ color }) {
  return (
    <div>
      <svg width="20" height="15" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={color}
          d="M9.58.006c-.41.043-.794.32-1.01.728-.277.557-2.334 4.693-2.74 5.1-.41.407-.944.6-1.544.6v8.572h7.5c.45 0 .835-.28 1.007-.665 0 0 2.207-6.234 2.207-6.834 0-.6-.47-1.072-1.07-1.072h-3.216c-.6 0-1.07-.535-1.07-1.07 0-.537.835-3.387 1.006-3.944.17-.557-.107-1.157-.664-1.35-.15-.043-.257-.086-.407-.064zM0 6.434v8.572h2.143V6.434H0z"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}

Like.propTypes = {
  color: string
};
Like.defaultProps = {
  color: '#888'
};
