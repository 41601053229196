import mirrorKeyValue from 'mirror-key-value';
import { createActions } from '../../utils/saga/saga-utils';
/* ------------- LoginType ------------- */

export const CartTypes = mirrorKeyValue([
  ...createActions('GET_CART_WISHLIST'),
  ...createActions('GET_USER_CART'),
  ...createActions('GET_USER_SAVE_LATER'),
  ...createActions('DELETE_CART'),
  ...createActions('ADD_TO_CART'),
  ...createActions('MOVE_CART'),
  ...createActions('UPDATE_CART'),
  ...createActions('CART_ESTIMATION'),
  'RESET_CART',
  'RESET_ALL_CART',
  'GET_USER_COUPON_ERROR',
  'GET_USER_COUPON_SUCCESS',
  'RESET_CART_ESTIMATION',
  'RESET_COUPON_CODE',
  'BULK_ADD_TO_CART'
]);

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  carts: [],
  summary: {},
  savedForLater: [],
  error: '',
  errorMsg: '',
  authView: true,
  deliveryEstimate: [],
  isSuccess: false,
  cartExists: false,
  isCouponAppliedSuccess: false,
  isCouponAppliedError: false,
  couponAppliedErrorMsg: ''
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case CartTypes.GET_USER_CART_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.GET_USER_CART_SUCCESS: {
      const carts = data?.content[0]?.carts;
      const summary = data.content[1]?.summary;
      return {
        ...state,
        carts,
        summary,
        loading: false
      };
    }

    case CartTypes.GET_USER_CART_FAILED: {
      return { ...state, ...data, loading: false };
    }

    case CartTypes.GET_USER_SAVE_LATER_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.GET_USER_SAVE_LATER_SUCCESS: {
      const savedForLater = data?.content;
      return {
        ...state,
        savedForLater,
        loading: false
      };
    }
    case CartTypes.GET_USER_SAVE_LATER_FAILED: {
      return { ...state, ...data, loading: false };
    }
    case CartTypes.DELETE_CART_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.DELETE_CART_FAILED: {
      return { ...state, loading: false };
    }

    case CartTypes.DELETE_CART_SUCCESS: {
      return { ...state, loading: false, isSuccess: true };
    }
    case CartTypes.MOVE_CART_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.MOVE_CART_FAILED: {
      return { ...state, loading: false, isSuccess: false };
    }

    case CartTypes.MOVE_CART_SUCCESS: {
      return { ...state, loading: false, isSuccess: true };
    }

    case CartTypes.UPDATE_CART_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.ADD_TO_CART_FAILED: {
      return { ...state, loading: false, isSuccess: false };
    }

    case CartTypes.ADD_TO_CART_SUCCESS: {
      return { ...state, loading: false, isSuccess: true };
    }

    case CartTypes.ADD_TO_CART_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.BULK_ADD_TO_CART: {
      return { ...state, loading: true };
    }

    case CartTypes.UPDATE_CART_FAILED: {
      return { ...state, loading: false };
    }

    case CartTypes.UPDATE_CART_SUCCESS: {
      return { ...state, loading: false };
    }

    case CartTypes.CART_ESTIMATION_FETCH: {
      return { ...state, loading: true };
    }

    case CartTypes.CART_ESTIMATION_FAILED: {
      return { ...state, loading: false };
    }

    case CartTypes.CART_ESTIMATION_SUCCESS: {
      return { ...state, deliveryEstimate: data?.data, loading: false };
    }
    case CartTypes.RESET_CART_ESTIMATION: {
      return { ...state, deliveryEstimate: [], loading: false };
    }
    case CartTypes.RESET_CART: {
      return { ...state, isSuccess: false };
    }
    case CartTypes.GET_USER_COUPON_ERROR: {
      return {
        ...state,
        isCouponAppliedSuccess: false,
        isCouponAppliedError: true,
        couponAppliedErrorMsg: action.message
      };
    }
    case CartTypes.GET_USER_COUPON_SUCCESS: {
      return {
        ...state,
        isCouponAppliedSuccess: true,
        isCouponAppliedError: false,
        couponAppliedErrorMsg: ''
      };
    }
    case CartTypes.RESET_COUPON_CODE: {
      return {
        ...state,
        isCouponAppliedSuccess: false,
        isCouponAppliedError: false,
        couponAppliedErrorMsg: ''
      };
    }
    case CartTypes.RESET_ALL_CART: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
