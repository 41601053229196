import Verify from '../../../../constants/verify';
import { checkMobileNumber } from '../../../../containers/auth/FormValidation';

export const emailValidation = (userEmail) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(userEmail);
};

export const validateSuggestProductForm = (productName, userEmail, mobile) => {
  let errors = '';
  if (!productName) {
    errors = Verify.VERIFY_EMPTY_PRODUCT;
    return errors;
  }
  if (!userEmail) {
    errors = Verify.VERIFY_EMAIL_EMPTY;
    return errors;
  }
  if (userEmail && !emailValidation(userEmail)) {
    errors = Verify.VERIFY_EMAIL_INVALID;
    return errors;
  }
  if (mobile && mobile.length !== 10) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (mobile && !checkMobileNumber(mobile)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  return errors;
};
