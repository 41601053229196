/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CartItem from '../../components/multicellular/cart/cartItem';
import PaymentSummaryCard from '../../components/multicellular/payment/paymentSummaryCard';
import { CartTypes } from '../../reducers/cart/cart';
import Loader from './Loader/index';
import ConfirmationBox from '../../components/multicellular/cart/confirmationBox';
import { generateUrl } from '../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../constants/defaults/product';
import Button from '../../components/unicellular/button';
import EmptyCart from '../../components/multicellular/cart/emptycart';
import CartFooter from '../../components/multicellular/navigation/footer/CartFooter';
import Info from '../../assets/svgs/Info';
import './Cart.scss';
import RoutesPaths from '../../constants/routes';
import CartCard from '../../components/multicellular/cart/cartCard';
import Information from './Information';
import STORAGE_KEYS from '../../constants/storageKey';
import { trackEvent, trackPageView } from '../../analytics/googleAnalytics';
import { LoginTypes } from '../../reducers/auth/login/login';

class CartClass extends Component {
  constructor() {
    super();
    this.myRef = React.createRef(null);
    this.state = {
      wantToDelete: undefined,
      deleteConfirmation: false,
      activeId: '',
      isValueMore: false
    };
  }

  componentDidMount() {
    const { getUserCart = () => {} } = this.props;
    const { getUserInfo = () => {} } = this.props;

    getUserInfo();
    getUserCart();
  }

  handleClick = () => this.myRef.current.scrollIntoView();

  handleClickProceed = () => {
    // eslint-disable-next-line react/prop-types
    const { navigate } = this.props;
    navigate(RoutesPaths.ADDRESS_LIST);
  };

  onCartValueChange(cartValue, cart) {
    const { updateCart } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    this.setState({
      activeId: cart._id,
      isValueMore: cartValue?.value === '10'
    });
    if (cartValue?.value !== '10') {
      updateCart(cart._id, cartValue?.value);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  onEditCartQuantity(cart, value) {
    this.setState({ activeId: !value ? '' : cart._id, isValueMore: value });
  }

  onCustomCartValueChange(cartValue, cart) {
    const { updateCart } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    updateCart(cart._id, cartValue);
    this.setState({ activeId: '', isValueMore: false });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  removeFromList(cart, isSaveforLater) {
    sessionStorage.setItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED, 'clicked');
    if (isSaveforLater) {
      const { removeCart } = this.props;
      // eslint-disable-next-line no-underscore-dangle
      removeCart(cart._id, isSaveforLater);
      return;
    }
    this.setState({
      wantToDelete: {
        data: cart,
        isSaveforLater
      },
      deleteConfirmation: true
    });
  }

  moveCarttoSaveFromLater(cart, isSaveforLater) {
    const { moveCart } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    moveCart(cart._id, isSaveforLater);
    if (!isSaveforLater) {
      sessionStorage.setItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED, 'clicked');
    } else {
      sessionStorage.removeItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED);
    }
  }

  render() {
    const {
      cart: { loading = false, carts, savedForLater = [], summary = {} },
      removeCart,
      // eslint-disable-next-line no-unused-vars
      moveCart,
      mobile,
      location
    } = this.props;
    const { deleteConfirmation, wantToDelete } = this.state;
    // if (loading) {
    //   return <Loader loading={loading} />;
    // }
    const { isValueMore, activeId } = this.state;
    const cartQuantity = carts?.length ?? 0;
    const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
    const userDetails =
      userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;
    return (
      <div className="main-container-wrapper">
        <div
          className={`container cart-wrapper ${carts?.length === 0 && savedForLater?.length === 0 && 'pg-centered'}`}>
          {/* {carts?.length === 0 && savedForLater?.length === 0 ? (
            <div className="row">
              <div className="col-lg-12">
                <EmptyCart />
              </div>
            </div>
          )
            : ( */}
          <div className="row cart-w-100">
            <div
              className={`${carts?.length > 0 || loading ? 'col-sm-12 col-lg-8' : 'col-sm-12 col-lg-12'}`}>
              <div className="main-section">
                <div className={`cart-section ${savedForLater?.length === 0 ? 'hide-border' : ''}`}>
                  <div className="cart-heading">
                    <div>{`My Cart (${carts?.length})`}</div>
                    {carts?.length > 0 && (
                      <>
                        <div className="cart-heading-price">
                          <span>Amount Payable: </span>
                          {`${'\u20B9'}${summary?.totalPricePayable || 0}`}
                          <div>
                            <button aria-label="info" type="button" onClick={this.handleClick}>
                              <Info />
                            </button>
                          </div>
                        </div>
                        <div className="app-proceed-buy-btn">
                          <Button
                            onClick={() => {
                              trackEvent(
                                'Payment',
                                'Continue to Buy from Cart',
                                `${userDetails} clicked the "continue" button on ${location.pathname}`
                              );
                              this.handleClickProceed();
                            }}
                            buttonText={`Proceed to Buy (${carts?.length} items)`}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="divider" />
                  <div className="app-cart-card">
                    {loading ? (
                      <Loader />
                    ) : carts?.length === 0 ? (
                      <EmptyCart />
                    ) : (
                      carts?.map((cart) => (
                        <CartItem
                          key={cart.productId}
                          cartLength={carts?.length}
                          userDetails={userDetails}
                          cart={cart}
                          removeFromList={(cartItem, isSaveforLater) => {
                            this.removeFromList(cartItem, isSaveforLater);
                          }}
                          changeFromList={(cartItem, isSavedForLater) => {
                            this.moveCarttoSaveFromLater(cartItem, isSavedForLater);
                          }}
                          activeId={activeId}
                          isValueMore={isValueMore && cart._id === activeId}
                          onCartValueChange={(cartValue, cartItem) => {
                            this.onCartValueChange(cartValue, cartItem);
                          }}
                          onCustomCartValueChange={(cartValue, cartItem) => {
                            this.onCustomCartValueChange(cartValue, cartItem);
                          }}
                          onEditCartQuantity={(cartItem, value) => {
                            this.onEditCartQuantity(cartItem, value);
                          }}
                        />
                      ))
                    )}
                  </div>
                  {/* </div> */}
                  {loading ? (
                    <div className="save-later-section">
                      <div className="main-section">
                        <div className="cart-heading">Saved for later</div>
                        <div className="divider" />
                        <div className="row">
                          <div className={`${carts?.length > 0 ? 'col-sm-4' : 'col-sm-3'}`}>
                            <CartCard loading={loading} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : savedForLater?.length > 0 ? (
                    <div className="save-later-section">
                      <div className="main-section">
                        <div className="cart-heading">
                          Saved for later ({savedForLater?.length})
                        </div>
                        <div className="divider" />
                        <div className="row">
                          {savedForLater?.map((saved) => (
                            <div
                              className={`${carts?.length > 0 ? 'col-sm-4' : 'col-sm-3'}`}
                              key={saved?.productId}>
                              <CartCard
                                cart={saved}
                                isSaveforLater
                                removeFromList={(cartItem, isSaveforLater) => {
                                  this.removeFromList(cartItem, isSaveforLater);
                                }}
                                changeFromList={(cartItem, isSavedForLater) => {
                                  this.moveCarttoSaveFromLater(cartItem, isSavedForLater);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {carts?.length > 0 || loading ? (
              <div className="col-sm-12 col-lg-4">
                <div className="cart-details-section" ref={this.myRef}>
                  <PaymentSummaryCard
                    priceSummary={summary}
                    loading={loading}
                    userDetails={userDetails}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {/* )} */}

          {deleteConfirmation ? (
            <ConfirmationBox
              title="Wanna Delete ?"
              show={deleteConfirmation}
              handleClose={() => this.setState({ deleteConfirmation: false })}
              actions={
                <div className="action-wrapper">
                  <Button
                    className="action-btn"
                    buttonText="Remove"
                    onClick={() => {
                      trackEvent(
                        'Cart',
                        'Remove From Cart',
                        `${userDetails}, Removed ${wantToDelete?.data?.product?.name} from cart (cart qty now: ${cartQuantity - 1})`
                      );
                      removeCart(wantToDelete?.data?._id, wantToDelete?.isSaveforLater);
                      this.setState({ deleteConfirmation: false });
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    }}
                  />
                  {!wantToDelete?.isSaveforLater ? (
                    <>
                      <div className="divider-small" />
                      <Button
                        className="action-btn positive-action"
                        buttonText="Save for Later"
                        onClick={() => {
                          trackEvent(
                            'Cart',
                            'Save for Later',
                            `${userDetails} clicked on Save for Later for ${wantToDelete?.data?.product?.name} (cart qty now: ${cartQuantity - 1})`
                          );
                          moveCart(wantToDelete?.data?._id, wantToDelete?.isSaveforLater);
                          this.setState({ deleteConfirmation: false });
                        }}
                      />
                    </>
                  ) : null}
                </div>
              }>
              <div className="confirmation-details">
                <div className="cart-item-image-container-confirmation">
                  <img
                    src={generateUrl(
                      wantToDelete?.data?.product?.images
                        ? wantToDelete?.data?.product?.images[0]
                        : ''
                    )}
                    alt=""
                    srcSet=""
                    onError={({ currentTarget }) => {
                      const currTrgt = currentTarget;
                      currTrgt.onerror = null; // prevents looping
                      currTrgt.src = PRODUCT_DEFAULTS.productImage;
                    }}
                    className="image"
                  />
                </div>
                <div className="confirmation-details-text">
                  <div className="confirmation-details-text-heading">Remove from cart</div>
                  <div className="confirmation-details-text-label">
                    Are you sure you want to remove this item from cart?
                  </div>
                </div>
              </div>
            </ConfirmationBox>
          ) : null}
        </div>
        <Information />
        <CartFooter />
      </div>
    );
  }
}
function Cart(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);
  return <CartClass {...props} params={params} navigate={navigate} location={location} />;
}
CartClass.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getUserCart: PropTypes.func.isRequired,
  removeCart: PropTypes.func,
  moveCart: PropTypes.func,
  cart: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  mobile: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  updateCart: PropTypes.func,
  location: PropTypes.object.isRequired
};

CartClass.defaultProps = {
  loading: false,
  removeCart: () => {},
  moveCart: () => {},
  updateCart: () => {}
};

const mapStateToProps = (state) => {
  const { cart } = state;
  return {
    cart,
    mobile: state.login?.user?.mobile
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH }),
  getUserCart: () => {
    dispatch({ type: CartTypes.GET_USER_CART_FETCH });
    dispatch({ type: CartTypes.GET_USER_SAVE_LATER_FETCH });
  },
  removeCart: (cartId, isSavedForLater) =>
    dispatch({
      type: CartTypes.DELETE_CART_FETCH,
      cartId,
      isSavedForLater
    }),
  moveCart: (cartId, isSavedForLater) =>
    dispatch({
      type: CartTypes.MOVE_CART_FETCH,
      cartId,
      isSavedForLater
    }),
  updateCart: (cartId, quantity) =>
    dispatch({
      type: CartTypes.UPDATE_CART_FETCH,
      cartId,
      quantity
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
