/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import AddressDropdown from './AddressDropdown';
import Button from '../../../unicellular/button';
import DownArrow from '../../../../assets/svgs/DownArrow';
import RoutesPaths from '../../../../constants/routes';
import URLS from '../../../../constants/urls';
import { CancelOrderTypes } from '../../../../reducers/order/cancelOrder';
import { ReturnOrderTypes } from '../../../../reducers/order/returnOrder';
import { generateUrl } from '../../../../utils/ImageUtils';
import MyAccountString from '../../../../constants/strings/myAccount';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import Loader from './Loader';
import ReturnModal from '../returnModal/ReturnModal';
import './OrderCard.scss';
import ORDER_STATUS from '../../../../constants/strings/myAccount/Trackorder';
import { ReviewTypes } from '../../../../reducers/order/reviewDetails';
import createSlug from '../../../../utils/conversions/createSlug';

const Alert = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
Alert.displayName = 'Alert';
function OrderCard(props) {
  const {
    price,
    address,
    orderStatus,
    invoiceId,
    callCancelOrderApi,
    loading,
    createdAt,
    resetReturnReducer,
    paymentMethod,
    sortDays,
    statusOfList,
    products,
    resetCancelOrder
  } = props;
  const { returnOrder, cancelOrder } = useSelector((state) => state);
  const { isOrderedCancel, load, error, errorMsg } = cancelOrder;
  const { isReturnedSuccess } = returnOrder;
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [cancelProductList, setCancelProductList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [returnorreplace, setShowReturn] = useState(false);
  const { resetReviewReducer } = props;
  const {
    reviewDetails: { isSuccessFullySubmited }
  } = useSelector((state) => state);
  useEffect(() => {
    if (isSuccessFullySubmited) {
      setInterval(() => {
        resetReviewReducer();
      }, 3000);
    }
  }, [isSuccessFullySubmited]);
  useEffect(() => {
    if (isOrderedCancel) {
      setInterval(() => {
        resetCancelOrder();
      }, 2000);
      setOpenRemoveDialog(false);
      setShowConfirmation(false);
      setCancelProductList([]);
    }
    if (error) {
      setInterval(() => {
        resetCancelOrder();
      }, 2000);
    }
  }, [isOrderedCancel, error]);
  const { isDelivered, status } = orderStatus;
  const deliveredStatus =
    status?.length > 0 && status?.find((o) => o.status === ORDER_STATUS.DELIVERIED);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const addressDetails = [
    address?.street,
    address?.locality,
    address?.landmark,
    address?.city,
    address?.state
  ];
  useEffect(() => {
    if (isReturnedSuccess) {
      setShowReturn(false);
      resetReturnReducer();
    }
  }, [isReturnedSuccess]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOrderCancel = () => {
    const productsList = cancelProductList.map((item) => ({
      id: item.product._id,
      quantity: item.quantity
    }));
    callCancelOrderApi(productsList, orderStatus.orderId, sortDays, statusOfList);
  };
  const handleCloseCancel = () => {
    setOpenRemoveDialog(false);
    setShowConfirmation(false);
  };
  const handleCloseOpen = () => {
    setOpenRemoveDialog(true);
  };
  const confrimReturnProduct = () => {
    setShowReturn(true);
  };
  const closereturnModal = () => {
    setShowReturn(false);
  };
  const handleConfirmation = useCallback(() => {
    setShowConfirmation(!showConfirmation);
  }, [showConfirmation]);

  const handleCancelItems = useCallback(
    (orderedProductId, data) => {
      const arr = cancelProductList;
      const index = arr.findIndex((item) => item._id === orderedProductId);

      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(data);
      }
      setCancelProductList([...arr]);
    },
    [cancelProductList]
  );
  const confirmReturn = () => (
    <div>
      {returnorreplace && (
        <ReturnModal
          show={returnorreplace}
          onClose={closereturnModal}
          orderId={orderStatus.orderId}
          createdAt={createdAt}
          products={products}
          isAvailableToReturn={isDelivered}
        />
      )}
    </div>
  );
  const renderConfirmCancel = () => (
    <Dialog
      open={openRemoveDialog}
      onClose={handleCloseCancel}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="order-cancel-text-title">Cancellation</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="order-cancel-text-description">
          {showConfirmation ? (
            <div className="app-order-cancel-container">
              <div className="title-section">Are you sure you want to cancel below products ?</div>
              <div className="app-order-cancel-container">
                {cancelProductList &&
                  cancelProductList?.length > 0 &&
                  cancelProductList?.map((item) => (
                    <div className="app-cancel-product-flex" key={item?.product?.name}>
                      <div className="product-detail-layout mt-4">
                        <img
                          className="product-image"
                          src={
                            item?.product?.images && item?.product?.images?.length > 0
                              ? generateUrl(item?.product?.images[0])
                              : ''
                          }
                          alt=""
                          srcSet=""
                          onError={({ currentTarget }) => {
                            const currTrgt = currentTarget;
                            currTrgt.onerror = null; // prevents looping
                            currTrgt.src = PRODUCT_DEFAULTS.productImage;
                          }}
                        />
                        <div>
                          <div className="product-name text-start">{item?.product?.name}</div>
                          <div className="title-section text-start">
                            Quantity:
                            {item?.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="app-order-cancel-container">
              {products &&
                products?.length > 0 &&
                products?.map((item) => (
                  <button
                    onClick={
                      item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched
                        ? null
                        : () => handleCancelItems(item?._id, item)
                    }
                    type="button"
                    className={`app-cancel-product-flex ${
                      item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched
                        ? 'not-allowed'
                        : 'allowed'
                    }`}
                    key={item?.product?.name}>
                    <div className="product-detail-layout ">
                      {item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched ? (
                        <div className="empty-check" />
                      ) : (
                        <Checkbox
                          checked={
                            cancelProductList &&
                            cancelProductList?.length > 0 &&
                            cancelProductList?.some((val) => val._id === item?._id)
                          }
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      )}
                      <img
                        className="product-image"
                        src={
                          item?.product?.images && item?.product?.images?.length > 0
                            ? generateUrl(item?.product?.images[0])
                            : ''
                        }
                        alt=""
                        srcSet=""
                        onError={({ currentTarget }) => {
                          const currTrgt = currentTarget;
                          currTrgt.onerror = null; // prevents looping
                          currTrgt.src = PRODUCT_DEFAULTS.productImage;
                        }}
                      />
                      <div>
                        <div className="product-name text-start">{item?.product?.name}</div>
                        <div className="title-section text-start">
                          Quantity:
                          {item?.quantity}
                        </div>
                      </div>
                    </div>
                    <div className="product-detail-layout">
                      <div>
                        <div>
                          {(item?.orderDetails?.isCancelled && (
                            <div className="app-delivered-status">Cancelled</div>
                          )) ||
                            (item?.orderDetails?.isReplace && (
                              <div className="app-delivered-status">Replace requested</div>
                            )) ||
                            (item?.orderDetails?.isReturn && (
                              <div className="app-delivered-status">Return requested</div>
                            )) ||
                            (item?.orderDetails?.isDispatched && (
                              <div className="app-delivered-status">Dispatched</div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="app-order-cancel-footer">
        <div className="app-action-btn-layout">
          <Button
            buttonText="Back"
            className="app-action-btn-cancel app-btn-width "
            onClick={showConfirmation ? handleConfirmation : handleCloseCancel}
          />
          <Button
            buttonText={showConfirmation ? 'Yes' : 'Cancel Order'}
            className="app-action-btn-delete app-btn-width "
            loading={load}
            disabled={showConfirmation ? false : cancelProductList?.length === 0}
            onClick={showConfirmation ? handleOrderCancel : handleConfirmation}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
  const checkIsCancelled = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isCancelled === true);
  const checkIsReplace = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isReplace === true);
  const checkIsReturn = () =>
    products && products?.length > 0 && products.every((obj) => obj.orderDetails.isReturn === true);
  const checkIsDispatched = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isDispatched === true);

  return loading ? (
    <Loader />
  ) : (
    <div className="app-myorderpage">
      <div className="app-myorder">
        <div className="app-order-card-sub-header">
          <div className="sub-title">
            ORDER:
            <span>{orderStatus.orderId}</span>
          </div>
          <div className="sub-title">
            TOTAL:
            <span>{price}</span>
          </div>
          <div className="sub-title">
            ADDRESS:
            <span>
              <button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                type="button">
                <div>{address.name}</div>
                <DownArrow />
              </button>
            </span>
            <AddressDropdown
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={handleClose} disableRipple>
                {addressDetails?.join(', ')}
              </MenuItem>
            </AddressDropdown>
          </div>
        </div>
        <NavLink to={`${RoutesPaths.TRACK_ORDER}/${orderStatus.orderId}/${invoiceId}`}>
          <div className="app-myorder_header">
            <div>View Order Details </div>
          </div>
        </NavLink>
      </div>
      <div className="app-inner-container">
        <div className="app-delivery">
          {(checkIsCancelled() && <div className="app-delivered-status">Order is Cancelled</div>) ||
            (checkIsReplace() && <div className="app-delivered-status"> Replace requested </div>) ||
            (checkIsReturn() && <div className="app-delivered-status"> Return requested </div>) ||
            (!checkIsCancelled() && isDelivered ? (
              <div className="app-delivered-status">
                {`Delivered on ${
                  moment(deliveredStatus?.date).format('DD-MM-YYYY') ||
                  orderStatus.estimatedDelivery
                }`}
              </div>
            ) : (
              <div>
                {` ${
                  orderStatus.estimatedDelivery !== 'NaN'
                    ? `Arriving on ${orderStatus.estimatedDelivery}`
                    : ''
                }`}
              </div>
            ))}
        </div>
        {paymentMethod !== 'PAID' && (
          <div className="payment-method">
            Payment Method:
            <span>{paymentMethod}</span>
          </div>
        )}
        <div className="order-card-flex-box">
          <div className="order-product-detail">
            <div className="product-image-layout">
              {products &&
                products?.length > 0 &&
                products?.map((item, index) =>
                  index < 3 ? (
                    <div key={item?.product?.name} className="image-ptn">
                      <NavLink
                        to={`${item?.product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(item?.product?.name)}/${item?.product?._id}`}>
                        <img
                          className="product-image"
                          title={item?.product?.name}
                          src={
                            item?.product?.images && item?.product?.images?.length > 0
                              ? generateUrl(item?.product?.images[0])
                              : ''
                          }
                          alt=""
                          srcSet=""
                          onError={({ currentTarget }) => {
                            const currTrgt = currentTarget;
                            currTrgt.onerror = null; // prevents looping
                            currTrgt.src = PRODUCT_DEFAULTS.productImage;
                          }}
                        />
                      </NavLink>
                      {products?.length > 3 && index === 2 && (
                        <NavLink
                          to={`${RoutesPaths.TRACK_ORDER}/${orderStatus.orderId}/${invoiceId}`}
                          className="overlay-image">
                          +{products?.length - 3}
                        </NavLink>
                      )}
                    </div>
                  ) : null
                )}
            </div>
          </div>
          {isDelivered ? (
            <div className="app-action-btn-view">
              <Button
                buttonText="Return or replace"
                className="app-order-action-btn"
                onClick={confrimReturnProduct}
              />
              <div className="app-btn-caer">
                <a
                  aria-label="download"
                  href={URLS.BASE_URL + URLS.API_DOWNLOAD_INVOICE(orderStatus.orderId)}
                  download>
                  <Button
                    buttonText={MyAccountString.DOWNLOAD_INVOICE}
                    className="app-order-action-btn"
                  />
                </a>
              </div>
            </div>
          ) : (
            <div className="app-action-btn-view">
              <div className="app-btn-caer">
                {!checkIsCancelled() &&
                  !checkIsReplace() &&
                  !checkIsReturn() &&
                  !checkIsDispatched() && (
                    <div className="app-action-btn-view">
                      <div className="app-btn-caer">
                        <Button
                          buttonText="CANCEL"
                          className="app-order-action-btn"
                          onClick={handleCloseOpen}
                        />
                      </div>
                      <div className="app-btn-caer">
                        <NavLink
                          to={`${RoutesPaths.TRACK_ORDER}/${orderStatus.orderId}/${invoiceId}`}>
                          <Button buttonText="TRACK ORDER" className="app-order-action-btn" />
                        </NavLink>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {renderConfirmCancel()}
      {confirmReturn()}
      <Snackbar
        open={isOrderedCancel}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          <div className="alet-message-success">Item Cancelled Successfully</div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          <div className="alet-message-success">{errorMsg}</div>
        </Alert>
      </Snackbar>
    </div>
  );
}
OrderCard.propTypes = {
  price: PropTypes.string.isRequired,
  name: PropTypes.string,
  invoiceId: PropTypes.string,
  orderStatus: PropTypes.shape({
    estimatedDelivery: PropTypes.string,
    orderId: PropTypes.string,
    isCancelled: PropTypes.bool,
    isDispatched: PropTypes.bool,
    isDelivered: PropTypes.bool,
    isReplace: PropTypes.bool,
    isReturn: PropTypes.bool,
    status: PropTypes.array,
    _id: PropTypes.string
  }),
  address: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  products: PropTypes.array,
  callCancelOrderApi: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  createdAt: PropTypes.string,
  resetReturnReducer: PropTypes.func.isRequired,
  resetReviewReducer: PropTypes.func.isRequired,
  resetCancelOrder: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  sortDays: PropTypes.string.isRequired,
  statusOfList: PropTypes.string.isRequired
};
OrderCard.defaultProps = {
  orderStatus: {
    estimatedDelivery: '',
    orderId: '',
    isCancelled: false,
    isDispatched: false,
    isDelivered: false,
    isReplace: false,
    isReturn: false,
    _id: '',
    loading: false,
    status: []
  },
  products: [],
  createdAt: '',
  name: '',
  invoiceId: '',
  address: [],
  // productId: '',
  loading: false,
  paymentMethod: ''
};

const mapStateToProps = (state) => {
  const { cancelOrder, returnOrder, reviewDetails } = state;
  return { cancelOrder, returnOrder, reviewDetails };
};

const mapDispatchToProps = (dispatch) => ({
  callCancelOrderApi: (productsList, orderId, sortDays, status) =>
    dispatch({
      type: CancelOrderTypes.GET_ORDER_CANCELED,
      productsList,
      orderId,
      sortDays,
      status
    }),
  resetReturnReducer: () =>
    dispatch({
      type: ReturnOrderTypes.SET_GET_ORDER_RETURN
    }),
  resetCancelOrder: () =>
    dispatch({
      type: CancelOrderTypes.RESET_ORDER_CANCEL
    }),
  resetReviewReducer: () =>
    dispatch({
      type: ReviewTypes.RESET_SET_REVIEW
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
