import { makePostApiCall } from '../../../adapters';
import STORAGE_KEYS from '../../../constants/storageKey';
import URLS from '../../../constants/urls';

export const callRazorPayApi = (userId, txnType, apiUrl, addressDetails, priceSummary) => {
  const productId = sessionStorage.getItem(STORAGE_KEYS.PRODUCT_ID);

  const params = {
    isBuyNow: '',
    userId,
    txnType,
    address: addressDetails,
    deliveryCharge: {},
    deliveryEstimate: {},
    couponInfo: priceSummary.couponInfo,
    ...(productId && { productId })
  };

  const response = makePostApiCall(apiUrl, params);
  return response.then((result) => result);
};
export const razorpayOptions = (order, name, description, key, user, color, image) => {
  const options = {
    key,
    amount: order.amount,
    name,
    description,
    order_id: order.id,
    image,
    handler(response) {
      const values = {
        razorpay_signature: response.razorpay_signature,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id
      };
      const urlCallback = URLS.API_RAZORPAY_CALLBACK;
      const res = makePostApiCall(urlCallback, values);
      res.then((result) => {
        if (result.status === 200) {
          // localStorage.setItem(STORAGE_KEYS.CALLBACK, 'true');
          // localStorage.setItem(STORAGE_KEYS.CALLBACK_DETAIL, payment);
        } else {
          localStorage.removeItem(STORAGE_KEYS.CALLBACK);
        }
      });
    },
    modal: {
      ondismiss() {
        localStorage.setItem(STORAGE_KEYS.IS_PAYMENT_LOADING, 'false');
      }
    },
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.phone
    },
    theme: {
      color
    }
  };
  return options;
};
