import STORAGE_KEYS from '../../constants/storageKey';

const logout = () => {
  localStorage.removeItem(STORAGE_KEYS.USER_ID);
  localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.IS_LOGIN);
  localStorage.removeItem(STORAGE_KEYS.USER_NAME);
  sessionStorage.removeItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
};
export default logout;
