const convertDropdownOptions = (array) =>
  array.reduce((arr, item) => {
    arr.push({
      label: item.title,
      key: item.key,
      value: item.value && item.value !== null ? item.value : ''
    });
    return arr;
  }, []);
export default convertDropdownOptions;
