import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { PasswordTypes } from '../../../../reducers/changepassword/changepassword';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import InputField from '../../../unicellular/inputField';
import MyAccountString from '../../../../constants/strings/myAccount';
import PlaceholderString from '../../../../constants/strings/PlaceholderString';
import { validateAddressForm } from './FormValidation';

import './Changepassword.scss';

function ChangePasswordModal(props) {
  const { show, onClose, callChangepasswordApi, mobile, callResetPasswordReducer } = props;
  const [oldPassword, setPassword] = useState('');
  const [newPassword, setNewpassword] = useState('');
  const [retypepassword, setRetype] = useState('');
  const [err, setError] = useState('');
  const { changepassword } = useSelector((state) => state);
  const { passwordloading, passworderror, updatepassworderrorMsg } = changepassword;
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError('');
    callResetPasswordReducer();
  };
  const handleNewPassword = (e) => {
    setNewpassword(e.target.value);
    setError('');
    callResetPasswordReducer();
  };
  const handleretypePassword = (e) => {
    setRetype(e.target.value);
    setError('');
    callResetPasswordReducer();
  };

  const isValidate = () => {
    let errors = '';
    errors = validateAddressForm(oldPassword, newPassword, retypepassword);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    if (valid) {
      callChangepasswordApi(mobile, oldPassword, newPassword);
      setError('');
      callResetPasswordReducer();
    }
  };
  const renderMainLayout = () => (
    <div className="changepassword-modal-body-container">
      <div className="row">
        <div className="col-sm-7 col-lg-6">
          <div className="title">{MyAccountString.CHANGE_PASSWORD_SECTION_TITLE}</div>
          <div className="detail">
            {MyAccountString.CHANGE_PASSWORD_GUIDE.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </div>
        </div>
        <div className="col-sm-7 col-lg-6 app-home-about-info-container">
          <div className="app-change-password">{MyAccountString.CHANGE_PASSWORD_SMALL_TEXT}</div>
          <div className="changepassword-modal-input-view">
            <InputField
              placeholder={PlaceholderString.CURRENT_PASSWORD}
              value={oldPassword}
              onChange={handlePassword}
              type="password"
              className="changepassword-modal-input"
            />
          </div>
          <div className="changepassword-modal-input-view">
            <InputField
              value={newPassword}
              onChange={handleNewPassword}
              placeholder={PlaceholderString.NEW_PASSWORD}
              type="password"
              className="changepassword-modal-input"
            />
          </div>
          <div className="changepassword-modal-input-view">
            <InputField
              value={retypepassword}
              onChange={handleretypePassword}
              placeholder={PlaceholderString.RETYPE_PASSWORD}
              type="password"
              className="changepassword-modal-input"
            />
            <div>
              {err !== '' && <div className="changepassword-model-error-message">{err}</div>}
              {passworderror && (
                <div className="changepassword-model-error-message">{updatepassworderrorMsg}</div>
              )}
            </div>
          </div>
          <Button
            buttonText="Change Password"
            loading={passwordloading}
            onClick={submitForm}
            className="change-pwd-btn"
          />
        </div>
      </div>
    </div>
  );
  return (
    <Modal size="lg" show={show} onHide={onClose} centered contentClassName="changepassword-model">
      <Modal.Body className="changepassword-model-body">
        <div className="changepassword-model-header-container">
          <div>{MyAccountString.CHANGE_PASSWORD}</div>
          <button
            type="button"
            aria-label="change-password"
            onClick={onClose}
            className="changepassword-model-close-btn">
            <Close color="#666" />
          </button>
        </div>

        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}
ChangePasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callChangepasswordApi: PropTypes.func.isRequired,
  mobile: PropTypes.string.isRequired,
  callResetPasswordReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { changepassword } = state;
  return { changepassword };
};
const mapDispatchToProps = (dispatch) => ({
  callChangepasswordApi: (mobile, oldPassword, newPassword) =>
    dispatch({
      type: PasswordTypes.UPDATE_PASSWORD,
      mobile,
      oldPassword,
      newPassword
    }),
  callResetPasswordReducer: () =>
    dispatch({
      type: PasswordTypes.RESET_GET_PASSWORD
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
