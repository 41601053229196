import React from 'react';
import PropTypes from 'prop-types';
import './SpecificationCard.scss';

export default function SpecificationCard(props) {
  const { children } = props;
  return <div className="app-specification-card">{children}</div>;
}
SpecificationCard.propTypes = {
  children: PropTypes.node.isRequired
};
