import mirrorKeyValue from 'mirror-key-value';

export const FaqTypes = mirrorKeyValue([
  'GET_FAQ_LIST',
  'GET_FAQ_LIST_INDICATOR',
  'GET_FAQ_LIST_SUCCESS',
  'GET_FAQ_LIST_FAILED',
  'SET_ACTIVE_FAQ'
]);

const {
  GET_FAQ_LIST,
  GET_FAQ_LIST_INDICATOR,
  GET_FAQ_LIST_SUCCESS,
  GET_FAQ_LIST_FAILED,
  SET_ACTIVE_FAQ
} = FaqTypes;

const INITIAL_STATE = {
  loading: false,
  faqData: {},
  activeFaq: {},
  error: '',
  errorMsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_FAQ_LIST:
      return { ...state, ...data };
    case GET_FAQ_LIST_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_FAQ_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        faqData: action.response,
        activeFaq: action.response?.faq[0]?.categories[0],
        error: false
      };
    case GET_FAQ_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case SET_ACTIVE_FAQ:
      return {
        ...state,
        activeFaq: action.activeData
      };
    default:
      return state;
  }
}
