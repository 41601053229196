import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import Button from '../../../components/unicellular/button';
import './Otp.scss';
import AuthString from '../../../constants/strings/auth/Auth';
import { OtpTypes } from '../../../reducers/auth/otp/otp';
import { validateOtp } from '../FormValidation';
import { resendOtpTypes } from '../../../reducers/auth/login/resendOtp';

function EmailOtp(props) {
  const [otpCode, setOtpCode] = useState('');
  const [err, setError] = useState('');
  const { otp } = useSelector((state) => state);
  const { error, errorMsg, loading } = otp;
  const { onClickResend, userId, verifyUserOtp, resetOtpReducer, resendOtpForVerifyClick } = props;
  const focusStyle = {
    border: '1px solid #CFD3DB',
    outline: 'none'
  };
  const inputStyle = {
    border: '1px solid #CFD3DB',
    borderRadius: '8px',
    width: '54px',
    height: '74px',
    fontSize: '28px',
    color: '#151d56',
    fontWeight: '700',
    caretColor: '#151d56',
    background: '#ececec'
  };
  const handleChange = (checkOtp) => {
    setOtpCode(checkOtp);
    setError('');
  };

  const isValidate = () => {
    let errors = '';
    errors = validateOtp(otpCode);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  const handleresendOtpClick = () => {
    setOtpCode('');
    resendOtpForVerifyClick(userId);
  };
  const submitOtp = () => {
    const valid = isValidate();
    if (valid) {
      verifyUserOtp(userId, otpCode);
      setError('');
      resetOtpReducer();
    }
  };
  return (
    <div className="app-auth-otp-container">
      <div className="detail-info-text">
        <div>
          <div className="text">{AuthString.OTP_TITLE}</div>
          <div className="number">{userId}</div>
        </div>
        <button type="button" className="link-btn" onClick={onClickResend}>
          {AuthString.CHANGE}
        </button>
      </div>
      <div className="app-auth-otp-input-flield">
        <OtpInput
          value={otpCode}
          onChange={handleChange}
          numInputs={4}
          separator={<span style={{ width: '20px' }} />}
          isInputNum
          shouldAutoFocus
          inputStyle={inputStyle}
          focusStyle={focusStyle}
        />
      </div>
      <div className="app-auth-otp-error">{err !== '' ? err : ''}</div>
      <div className="app-auth-otp-error">{error ? errorMsg : ''}</div>
      <div className="app-auth-otp-action-button">
        <div className="app-auth-otp-btn-view">
          <Button
            buttonText={AuthString.VERIFY}
            className="app-auth-otp-btn"
            loading={loading}
            onClick={submitOtp}
          />
        </div>
      </div>
      <div className="app-bottom-text-container">
        <div className="app-auth-otp-bottom-link">
          {AuthString.OTP_BOTTOM_TEXT}
          <span>
            <button type="button" onClick={handleresendOtpClick}>
              {AuthString.RESEND}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
EmailOtp.propTypes = {
  onClickResend: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  verifyUserOtp: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resendOtpForVerifyClick: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { otp } = state;
  return { otp };
};

const mapDispatchToProps = (dispatch) => ({
  verifyUserOtp: (userId, otp) =>
    dispatch({
      type: OtpTypes.VERIFY_EMAIL_OTP,
      userId,
      otp
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resendOtpForVerifyClick: (userId) =>
    dispatch({
      type: resendOtpTypes.RESEND_EMAIL_OTP,
      userId
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailOtp);
