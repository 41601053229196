import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/multicellular/navigation/header';
import Footer from '../components/multicellular/navigation/footer';
import { primaryRoutes } from '../routes/routeconfig';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
function PrimaryLayout() {
  return (
    <div className="main-wrapper">
      <Header />
      <Wrapper>
        <Routes>
          {primaryRoutes.map((route) => {
            if (route.subPage) {
              return (
                <Route path={route.path} key={route.path} element={route.page}>
                  {route.subPage.map((subRoute) => (
                    <Route key={subRoute.path} exact path={subRoute.path} element={subRoute.page} />
                  ))}
                </Route>
              );
            }
            return <Route key={route.path} exact path={route.path} element={route.page} />;
          })}
          {/* <Route component={NotFound} /> */}
        </Routes>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default PrimaryLayout;
