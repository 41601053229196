import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const forgetPasswordTypes = mirrorKeyValue([
  'GET_FORGET_PASSWORD',
  'GET_FORGET_PASSWORD_INDICATOR',
  'GET_FORGET_PASSWORD_SUCCESS',
  'GET_FORGET_PASSWORD_FAILED',
  'UPDATE_FORGET_PASSWORD_STATE',
  'RESET_FORGET_PASSWORD',
  'RESET_ERROR_MSG'
]);

const {
  GET_FORGET_PASSWORD,
  GET_FORGET_PASSWORD_INDICATOR,
  GET_FORGET_PASSWORD_SUCCESS,
  GET_FORGET_PASSWORD_FAILED,
  UPDATE_FORGET_PASSWORD_STATE,
  RESET_FORGET_PASSWORD,
  RESET_ERROR_MSG
} = forgetPasswordTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  forgetPasswordData: [],
  error: '',
  errorMsg: '',
  isSuccess: false,
  userFormData: {}
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_FORGET_PASSWORD:
      return { ...state, ...data };
    case GET_FORGET_PASSWORD_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isSuccess: false
      };
    case GET_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgetPasswordData: action.response,
        error: false,
        isSuccess: true
      };
    case GET_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isSuccess: false
      };
    case UPDATE_FORGET_PASSWORD_STATE:
      return {
        ...state,
        isSuccess: action.otpView
      };
    case RESET_FORGET_PASSWORD:
      return {
        ...state,
        isSuccess: false,
        errorMsg: '',
        error: false
      };
    case RESET_ERROR_MSG:
      return {
        ...state,
        errorMsg: '',
        error: false
      };
    default:
      return state;
  }
}
