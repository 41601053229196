import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeDeleteApiCall, makeGetApiCall, makePostApiCall, makePutApiCall } from '../../adapters';
import { AddressTypes } from '../../reducers/account/address/address';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { getRequest } from '../../adapters/request-page';

function* getUserAddress(payload) {
  try {
    yield put({ type: AddressTypes.GET_ADDRESS_INDICATOR });

    const API_URL = URLS.API_USER + payload.userId + URLS.API_ADDRESS;
    const response = yield call(makeGetApiCall, API_URL, getRequest());
    if (response.status === 200) {
      yield put({
        type: AddressTypes.GET_ADDRESS_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: AddressTypes.GET_ADDRESS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: AddressTypes.GET_ADDRESS_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* addUserAddress(payload) {
  try {
    yield put({ type: AddressTypes.ADD_ADDRESS_INDICATOR });
    const params = {
      userId: payload.userId,
      name: payload.name,
      street: payload.address,
      mobile: payload.mobile,
      addressType: payload.addressType,
      city: payload.city,
      state: payload.state,
      locality: payload.district,
      isDefault: payload.isDefault,
      landmark: payload.landmark,
      pincode: payload.pincode
    };
    const API_URL = URLS.API_ADDRESS;
    const response = yield call(makePostApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: AddressTypes.ADD_ADDRESS_SUCCESS,
        response: response.data
      });
      yield put({ type: AddressTypes.GET_ADDRESS, userId: payload.userId });
    } else {
      yield put({
        type: AddressTypes.ADD_ADDRESS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: AddressTypes.ADD_ADDRESS_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* updateUserAddress(payload) {
  try {
    yield put({ type: AddressTypes.UPDATE_ADDRESS_INDICATOR });
    const params = {
      userId: payload.userId,
      name: payload.name,
      street: payload.address,
      mobile: payload.mobile,
      addressType: payload.addressType,
      city: payload.city,
      state: payload.state,
      district: payload.district,
      isDefault: payload.isDefault,
      landmark: payload.landmark,
      pincode: payload.pincode
    };
    const API_URL = `${URLS.API_ADDRESS}/${payload.addressId}`;
    const response = yield call(makePutApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: AddressTypes.UPDATE_ADDRESS_SUCCESS,
        response: response.data
      });
      yield put({ type: AddressTypes.GET_ADDRESS, userId: payload.userId });
    } else {
      yield put({
        type: AddressTypes.UPDATE_ADDRESS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: AddressTypes.UPDATE_ADDRESS_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* removeUserAddress(payload) {
  try {
    yield put({ type: AddressTypes.REMOVE_ADDRESS_INDICATOR });
    const params = {};
    const API_URL = `${URLS.API_ADDRESS}/${payload.addressId}${URLS.API_USER}${payload.userId}`;
    const response = yield call(makeDeleteApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: AddressTypes.REMOVE_ADDRESS_SUCCESS,
        response: response.data
      });
      yield put({ type: AddressTypes.GET_ADDRESS, userId: payload.userId });
    } else {
      yield put({
        type: AddressTypes.REMOVE_ADDRESS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: AddressTypes.REMOVE_ADDRESS_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* verifyPinCodeAddress(payload) {
  try {
    yield put({ type: AddressTypes.VERIFY_PINCODE_INDICATOR });
    const params = {
      pincode: payload.pincode
    };
    const API_URL = `${URLS.API_ADDRESS_PINCODE}`;
    const response = yield call(makePostApiCall, API_URL, params);
    if (response.status === 200) {
      yield put({
        type: AddressTypes.VERIFY_PINCODE_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: AddressTypes.VERIFY_PINCODE_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: AddressTypes.VERIFY_PINCODE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* AddressSaga() {
  try {
    yield all([
      takeLatest(AddressTypes.GET_ADDRESS, getUserAddress),
      takeLatest(AddressTypes.ADD_ADDRESS, addUserAddress),
      takeLatest(AddressTypes.UPDATE_ADDRESS, updateUserAddress),
      takeLatest(AddressTypes.REMOVE_ADDRESS, removeUserAddress),
      takeLatest(AddressTypes.VERIFY_PINCODE, verifyPinCodeAddress)
    ]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default AddressSaga;
