import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  makeDeleteApiCall,
  makeGetApiCall,
  makePatchApiCall,
  makePostApiCall
} from '../../adapters';
import { getRequest } from '../../adapters/request-page';
import STORAGE_KEYS from '../../constants/storageKey';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import RoutesPaths from '../../constants/routes';
import { CartTypes } from '../../reducers/cart/cart';
import { InnovationTypes } from '../../reducers/home/home';

function* getCartListSummary(payload) {
  try {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const productId = sessionStorage.getItem(STORAGE_KEYS.PRODUCT_ID);
    const params = {
      isCouponApplied: payload.isCouponApplied,
      couponCode: payload.couponCode,
      netPrice: payload.netPrice,
      basePrice: payload.basePrice,
      savedAmount: payload.savedAmount,
      ...(productId && { productId }),
      page: 1,
      size: 100
    };
    if (!payload.isCouponApplied) {
      sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
      sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
    }
    const response = yield call(makePostApiCall, URLS.API_USER_CART(userId), params);
    if (response.status === 200) {
      yield put({ type: CartTypes.GET_USER_CART_SUCCESS, data: response.data });
      if (payload.isCouponApplied) {
        yield put({ type: CartTypes.GET_USER_COUPON_SUCCESS });
      }
    } else {
      yield put({
        type: CartTypes.GET_USER_CART_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
      if (payload.isCouponApplied) {
        yield put({
          type: CartTypes.GET_USER_COUPON_ERROR,
          message: response?.data?.message
        });
      }
    }
  } catch (e) {
    yield put({
      type: CartTypes.GET_USER_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getSaveLaterDetails() {
  try {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const response = yield call(makeGetApiCall, URLS.API_SAVED_FOR_LATER(userId), getRequest());
    if (response.status === 200) {
      yield put({
        type: CartTypes.GET_USER_SAVE_LATER_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: CartTypes.GET_USER_SAVE_LATER_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.GET_USER_SAVE_LATER_FAILED,
      message: String.LOADINGERROR
    });
  }
}

/* -----Add Product to Cart-------- */
function* addProductToCart(payload) {
  try {
    const { userId, productId, quantity } = payload;
    const params = {
      userId,
      productId,
      quantity
    };
    const response = yield call(makePostApiCall, URLS.API_CART, params);
    if (response.status === 200) {
      sessionStorage.removeItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
      yield put({
        type: CartTypes.ADD_TO_CART_SUCCESS,
        response: response.data
      });
      yield put({ type: CartTypes.GET_USER_CART_FETCH });
      yield put({ type: InnovationTypes.GET_SHOP_BY_CATEGORY, userId });
      // navigate(`${RoutesPaths.CART_WAGON}?item=${productId}`);
    } else {
      yield put({
        type: CartTypes.ADD_TO_CART_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.ADD_TO_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* bulkAddProductsToCart(payload) {
  try {
    const { userId, products, navigate } = payload;
    const response = yield call(makePostApiCall, URLS.API_BULK_CART, {
      userId,
      products
    });
    if (response.status === 200) {
      // sessionStorage.removeItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
      yield put({
        type: CartTypes.ADD_TO_CART_SUCCESS,
        response: response.data
      });
      yield put({ type: CartTypes.GET_USER_CART_FETCH });
      yield put({ type: InnovationTypes.GET_SHOP_BY_CATEGORY, userId });
      navigate(RoutesPaths.CART);
    } else {
      yield put({
        type: CartTypes.ADD_TO_CART_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.ADD_TO_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* removeCart(payload) {
  try {
    const { cartId, isSavedForLater } = payload;
    let response;
    if (isSavedForLater) {
      response = yield call(makeDeleteApiCall, URLS.API_DELETE_SAVE_LATER(cartId));
    } else {
      response = yield call(makeDeleteApiCall, URLS.API_DELETE_CART(cartId));
    }
    if (response.status === 200) {
      yield put({ type: CartTypes.DELETE_CART_SUCCESS, data: response.data });
      yield put({
        type: isSavedForLater ? CartTypes.GET_USER_SAVE_LATER_FETCH : CartTypes.GET_USER_CART_FETCH,
        data: {}
      });
    } else {
      yield put({
        type: CartTypes.DELETE_CART_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.DELETE_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* moveCart(payload) {
  try {
    const { cartId, isSavedForLater } = payload;
    let url = '';
    if (isSavedForLater) {
      url = URLS.API_MOVE_CART(null, cartId);
    } else {
      url = URLS.API_MOVE_CART(cartId, null);
    }
    const response = yield call(makePostApiCall, url);

    if (response.status === 200) {
      yield put({ type: CartTypes.MOVE_CART_SUCCESS, data: response.data });
      yield put({
        type: CartTypes.GET_USER_CART_FETCH,
        data: {}
      });
      yield put({
        type: CartTypes.GET_USER_SAVE_LATER_FETCH,
        data: {}
      });
    } else {
      yield put({
        type: CartTypes.DELETE_CART_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.DELETE_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* updateCart(payload) {
  try {
    const { cartId, quantity } = payload;
    const response = yield call(makePatchApiCall, URLS.API_UPDATE_CART(cartId), { quantity });

    if (response.status === 200) {
      yield put({ type: CartTypes.UPDATE_CART_SUCCESS, data: response.data });
      yield put({
        type: CartTypes.GET_USER_CART_FETCH,
        data: {}
      });
    } else {
      yield put({
        type: CartTypes.UPDATE_CART_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.UPDATE_CART_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getDeliveryEstimate(payload) {
  const { pincode } = payload;
  const productId = sessionStorage.getItem(STORAGE_KEYS.PRODUCT_ID);
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const params = {
    pincode,
    userId,
    ...(productId && { productId })
  };

  try {
    const response = yield call(makePostApiCall, URLS.API_DELIVERY_ESTIMATE, params);

    if (response.status === 200) {
      yield put({
        type: CartTypes.CART_ESTIMATION_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: CartTypes.CART_ESTIMATION_FAILED,
        message: response?.data?.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CartTypes.CART_ESTIMATION_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* CartSaga() {
  try {
    yield all([
      takeLatest(CartTypes.GET_USER_CART_FETCH, getCartListSummary),
      takeLatest(CartTypes.GET_USER_SAVE_LATER_FETCH, getSaveLaterDetails),
      takeLatest(CartTypes.DELETE_CART_FETCH, removeCart),
      takeLatest(CartTypes.MOVE_CART_FETCH, moveCart),
      takeLatest(CartTypes.UPDATE_CART_FETCH, updateCart),
      takeLatest(CartTypes.CART_ESTIMATION_FETCH, getDeliveryEstimate),
      takeLatest(CartTypes.ADD_TO_CART_FETCH, addProductToCart),
      takeLatest(CartTypes.BULK_ADD_TO_CART, bulkAddProductsToCart)
    ]);
  } catch (ex) {
    console.warn('Error while Loading Saga: CartSaga', ex);
  }
}

export default CartSaga;
