import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './PaymentItem.scss';

function PaymentItem(props) {
  const { imgSrc, value, checked, onChange } = props;
  return (
    <Form.Check
      type="radio"
      name="payment-method"
      className="payment-item"
      value={value}
      checked={checked}
      onChange={onChange}
      label={
        <div className="payment-item-image">
          <img src={imgSrc} alt={value} />
        </div>
      }
    />
  );
}
PaymentItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PaymentItem;
