import React from 'react';
import './loader.scss';

const n = 4;
function Loader() {
  return [...Array(n)].map((item, index) => (
    <div className="cart-skel-body" key={`Loader-1-${index.toString()}`}>
      <div className="">
        <div className="container loading-view">
          <div className="container" />
          <div>
            <div>
              <div className="container align-container">
                <div className="row top-space-row">
                  <div className="col-md-3">
                    <div className="image loading-animation" />
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-6 ">
                    <div className="row">
                      {[...Array(n)].map((val, ind) => (
                        <span
                          className="title-ld loading-animation "
                          key={`Loader-1-${ind.toString()}`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
}
export default Loader;
