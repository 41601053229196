import React, { useState } from 'react';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';

import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from 'react-redux';

const FAQs = React.memo(() => {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { bookDetails: { faqs = [] } = {} } = {} } = productData;
  const [openAccordion, setOpenAccordion] = useState({});

  const handleAccordionToggle = (eventKey) => {
    setOpenAccordion(eventKey === openAccordion ? null : eventKey);
  };
  if (!faqs) {
    return null;
  }

  if (faqs?.length === 0) {
    return null;
  }

  return (
    <div className="container book-faq pt-5">
      <div className="mb-4 mt-4 text-center fs-2 fw-semibold">Frequently Asked Questions</div>

      <Accordion>
        {faqs?.map((item) => (
          <Accordion.Item
            eventKey={item.question}
            className="mb-4 bg-neutral-300 rounded-4 py-2"
            key={item.question}
            onClick={() => handleAccordionToggle(item.question)}>
            <Accordion.Header
              className={`d-flex justify-content-between align-items-center ${
                openAccordion === item.question ? 'rounded-bottom-0' : 'rounded-bottom-4'
              }`}>
              <div className="fs-4 flex-grow-1 fw-medium text-left">{item.question}</div>
              <div className="d-flex align-items-center">
                <AiFillPlusCircle
                  className={`fs-1 text-primary-100 transition-transform duration-200 ease-in ${
                    openAccordion === item.question ? 'd-none' : 'd-block'
                  }`}
                />
                <AiFillMinusCircle
                  className={`fs-1 text-primary-100 transition-transform duration-200 ease-in ${
                    openAccordion === item.question ? 'd-block' : 'd-none'
                  }`}
                />
              </div>
            </Accordion.Header>
            <Accordion.Body className="space-y-6 px-4 pb-4 rounded-bottom-4">
              <div className="whitespace-pre-line fs-5 fw-light">{item.answer}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
});

FAQs.displayName = 'FAQs';

export default FAQs;
