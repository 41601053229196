import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import './button.scss';

function Button(props) {
  const {
    className,
    onClick,
    opacity,
    backgroundColor,
    disabled,
    loading,
    isIcon,
    icon,
    textColor,
    buttonText,
    spinnerColor,
    isShowNumber,
    number
  } = props;
  return (
    <button
      type="button"
      className={`app-button ${className}`}
      onClick={onClick}
      style={{
        backgroundColor,
        opacity,
        cursor: disabled && 'not-allowed'
      }}
      disabled={disabled}>
      {loading ? (
        <Spinner
          animation="border"
          size="lg"
          variant={spinnerColor.length > 0 ? spinnerColor : 'light'}
        />
      ) : (
        <div className="app-button-container">
          <div className="app-button-icon">
            {isIcon && icon}
            {isShowNumber && number > 0 && <div className="app-button-number">{number}</div>}
          </div>
          <p className="app-button-text" style={{ color: textColor }}>
            {buttonText}
          </p>
        </div>
      )}
    </button>
  );
}

export default Button;
Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  opacity: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isIcon: PropTypes.bool,
  icon: PropTypes.node,
  textColor: PropTypes.string,
  buttonText: PropTypes.string,
  spinnerColor: PropTypes.string,
  isShowNumber: PropTypes.bool,
  number: PropTypes.number
};
Button.defaultProps = {
  className: '',
  onClick: () => {},
  opacity: '',
  backgroundColor: '',
  disabled: false,
  loading: false,
  isIcon: false,
  icon: null,
  textColor: '',
  buttonText: '',
  spinnerColor: '',
  isShowNumber: false,
  number: 0
};
