import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const editProfileTypes = mirrorKeyValue([
  'EDIT_USER_PROFILE',
  'EDIT_USER_PROFILE_INDICATOR',
  'EDIT_USER_PROFILE_SUCCESS',
  'EDIT_USER_PROFILE_FAILED',
  'RESET_EDIT_USER_PROFILE',
  'VERIFY_USER_PROFILE',
  'VERIFY_USER_PROFILE_INDICATOR',
  'VERIFY_USER_PROFILE_SUCCESS',
  'VERIFY_USER_PROFILE_FAILED'
]);

const {
  EDIT_USER_PROFILE,
  EDIT_USER_PROFILE_INDICATOR,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILED,
  RESET_EDIT_USER_PROFILE,
  VERIFY_USER_PROFILE,
  VERIFY_USER_PROFILE_INDICATOR,
  VERIFY_USER_PROFILE_SUCCESS,
  VERIFY_USER_PROFILE_FAILED
} = editProfileTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  editProfileData: {},
  error: false,
  isEditSuccess: true
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case EDIT_USER_PROFILE:
      return { ...state, ...data };
    case EDIT_USER_PROFILE_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isEditSuccess: false
      };
    case EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        editProfileData: action.response.message,
        error: false,
        isEditSuccess: true
      };
    case EDIT_USER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isEditSuccess: false
      };
    case VERIFY_USER_PROFILE:
      return { ...state, ...data };
    case VERIFY_USER_PROFILE_INDICATOR:
      return {
        ...state,
        verifyLoading: true,
        verifyerror: false,
        isVerifySuccess: false
      };
    case VERIFY_USER_PROFILE_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
        verifyProfileData: action.response.message,
        verifyerror: false,
        isVerifySuccess: true
      };
    case VERIFY_USER_PROFILE_FAILED:
      return {
        ...state,
        verifyLoading: true,
        verifyerror: false,
        isVerifySuccess: false,
        verifyerrorMsg: action.message
      };
    case RESET_EDIT_USER_PROFILE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
