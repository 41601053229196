const ProductString = {
  RELATED_PRODUCT_SECTION_TITLE: 'Similar Products',
  RECOMMENDED_PRODUCT_SECTION_TITLE: 'You May Also Like',
  BANNER_INFO: '50% off on Innovation Base Course Beginner Kit',
  NO_PRODUCTS: 'No Products Found',
  AVAILABLE_PRODUCTS: 'Available Products',
  TOTAL_PRODUCTS: (totalRecords) =>
    `${totalRecords} ${totalRecords === 1 ? 'product' : 'products'}`,
  VIEW_MORE: (categoryName) => `View More ${categoryName || 'Products'}`,
  BUY_NOW: 'BUY NOW',
  SHIP_TO: 'Ship To',
  TAX_INCLUSIVE: 'Inclusive of all taxes',
  QUERY_BTN_TEXT: 'Share details with us',
  SUGGESTION_TITLE: "Can't find what you were looking for?",
  SUGGESTION_DESC:
    'Suggest the product you are looking for. So that more enthusiasts as you would find it available soon on our platform.',
  PRODUCT_DETAIL_TITLE: 'Product Details',
  PRODUCT_SKU_TITLE: 'SKU',
  gstPrice: 18,
  RATING_REVIEW_SECTION_TITLE: 'Rating & Review',
  DISABLE_TOOLTIP: 'Please enter a serviceable pincode or clear the pincode in below input.',
  BOUGHT_TOGETHER: 'Bought Together',
  QUANTITY: 'Quantity'
};
export default ProductString;
