import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall } from '../../adapters';
import { LoginTypes } from '../../reducers/auth/login/login';
import { LoginWithMobileTypes } from '../../reducers/auth/login/loginWithMobile';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { forgetPasswordTypes } from '../../reducers/auth/login/forgetPassword';
import { RegisterTypes } from '../../reducers/auth/register/register';
import { resendOtpTypes } from '../../reducers/auth/login/resendOtp';
import STORAGE_KEYS from '../../constants/storageKey';
import { getRequest } from '../../adapters/request-page';
import { CartTypes } from '../../reducers/cart/cart';

function* getUserDetail(payload) {
  try {
    yield put({ type: LoginTypes.GET_LOGIN_USER_INDICATOR });
    const re = /^[0-9\b]+$/;
    let params = {};
    if (re.test(payload.userId)) {
      params = {
        mobile: payload.userId,
        password: payload.password
      };
    } else {
      params = {
        email: payload.userId,
        password: payload.password
      };
    }

    const response = yield call(makePostApiCall, URLS.API_USER_LOGIN, params);
    const id = sessionStorage.getItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: LoginTypes.GET_LOGIN_USER_SUCCESS,
        response: responseData
      });
      if (id !== null && id !== undefined && id?.trim() !== '') {
        yield put({
          type: CartTypes.ADD_TO_CART_FETCH,
          userId: responseData.userId,
          productId: id,
          quantity: 1,
          navigate: null
        });
      }
    } else {
      yield put({
        type: LoginTypes.GET_LOGIN_USER_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: LoginTypes.GET_LOGIN_USER_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getUserDetailByMobile(payload) {
  try {
    yield put({
      type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_INDICATOR
    });
    let params = {};
    params = {
      mobile: payload.mobile,
      countryCode: '+91'
    };
    const response = yield call(makePostApiCall, URLS.API_USER_OTP_RESEND, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getUserVerifyByMobile(payload) {
  try {
    yield put({
      type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_INDICATOR
    });
    let params = {};
    params = {
      mobile: payload.mobile,
      countryCode: '+91'
    };
    const response = yield call(makePostApiCall, URLS.API_USER_VERIFY_WITH_MOBILE, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* sendEmailVerificationOtp(payload) {
  try {
    yield put({ type: LoginWithMobileTypes.SEND_EMAIL_OTP_INDICATOR });
    let params = {};
    params = {
      email: payload.email,
      isForgot: payload.isForgot,
      ...(!payload.isForgot && {
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
      })
    };
    const response = yield call(makePostApiCall, URLS.API_USER_VERIFY_WITH_EMAIL, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: LoginWithMobileTypes.SEND_EMAIL_OTP_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: LoginWithMobileTypes.SEND_EMAIL_OTP_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: LoginWithMobileTypes.SEND_EMAIL_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getresendOtpVerifyMobile(payload) {
  try {
    yield put({ type: resendOtpTypes.RESEND_OTP_INDICATOR });
    let params = {};
    params = {
      mobile: payload.mobile,
      countryCode: '+91'
    };
    const response = yield call(makePostApiCall, URLS.API_USER_VERIFY_WITH_MOBILE, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: resendOtpTypes.RESEND_OTP_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: resendOtpTypes.RESEND_OTP_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: resendOtpTypes.RESEND_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getresendOtpVerifyEmail(payload) {
  try {
    yield put({ type: resendOtpTypes.RESEND_OTP_INDICATOR });
    let params = {};
    params = {
      email: payload.userId,
      isForgot: false,
      userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
    };
    const response = yield call(makePostApiCall, URLS.API_USER_VERIFY_WITH_EMAIL, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: resendOtpTypes.RESEND_OTP_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: resendOtpTypes.RESEND_OTP_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: resendOtpTypes.RESEND_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getresendOtp(payload) {
  try {
    yield put({ type: resendOtpTypes.RESEND_OTP_INDICATOR });
    let params = {};
    params = {
      mobile: payload.mobile,
      countryCode: '+91'
    };
    const response = yield call(makePostApiCall, URLS.API_USER_OTP_RESEND, params);
    const responseData = response.data;
    if (response.status === 200) {
      yield put({
        type: resendOtpTypes.RESEND_OTP_SUCCESS,
        response: responseData
      });
    } else {
      yield put({
        type: resendOtpTypes.RESEND_OTP_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: resendOtpTypes.RESEND_OTP_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getUpdateForgetPassword(payload) {
  try {
    yield put({ type: forgetPasswordTypes.GET_FORGET_PASSWORD_INDICATOR });
    let params = {};
    params = {
      ...(payload.loginType === 'mobile'
        ? {
            mobile: payload.userId,
            countryCode: '+91'
          }
        : { email: payload.userId }),
      otp: payload.otp,
      newPassword: payload.password
    };
    const response = yield call(
      makePostApiCall,
      payload.loginType === 'mobile' ? URLS.API_USER_OTP : URLS.API_USER_EMAIL_OTP,
      params
    );
    const responseData = response.data;
    const id = sessionStorage.getItem(STORAGE_KEYS.SESSION_PRODUCT_ID);
    if (response.status === 200) {
      yield put({
        type: forgetPasswordTypes.GET_FORGET_PASSWORD_SUCCESS,
        response: responseData
      });
      yield put({
        type: LoginTypes.GET_LOGIN_USER_SUCCESS,
        response: response.data
      });
      if (id !== null && id !== undefined && id?.trim() !== '') {
        yield put({
          type: CartTypes.ADD_TO_CART_FETCH,
          userId: response.data.userId,
          productId: id,
          quantity: 1,
          navigate: null
        });
      }
      yield put({ type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW });
      yield put({ type: RegisterTypes.RESET_REGISTER_VIEW });
    } else {
      yield put({
        type: forgetPasswordTypes.GET_FORGET_PASSWORD_FAILED,
        message: responseData.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: forgetPasswordTypes.GET_FORGET_PASSWORD_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getUserFromId(payload) {
  try {
    const userId = payload.userId ? payload.userId : localStorage.getItem(STORAGE_KEYS.USER_ID);
    const response = yield call(makeGetApiCall, URLS.API_USER_DETAILS(userId), getRequest());
    if (response.status === 200) {
      yield put({
        type: LoginTypes.GET_USER_SUCCESS,
        data: response.data,
        isUserFetched: true
      });
    } else {
      yield put({
        type: LoginTypes.GET_USER_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (ex) {
    yield put({
      type: LoginTypes.GET_USER_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* LoginSaga() {
  try {
    yield all([
      takeLatest(LoginTypes.GET_LOGIN_USER, getUserDetail),
      takeLatest(LoginWithMobileTypes.GET_LOGIN_USER_WITH_MOBILE, getUserDetailByMobile),
      takeLatest(LoginWithMobileTypes.SEND_EMAIL_OTP, sendEmailVerificationOtp),
      takeLatest(LoginWithMobileTypes.GET_VERIFY_USER_WITH_MOBILE, getUserVerifyByMobile),
      takeLatest(forgetPasswordTypes.GET_FORGET_PASSWORD, getUpdateForgetPassword),
      takeLatest(resendOtpTypes.RESEND_OTP, getresendOtp),
      takeLatest(resendOtpTypes.RESEND_OTP_VERIFY_WITH_MOBILE, getresendOtpVerifyMobile),
      takeLatest(resendOtpTypes.RESEND_EMAIL_OTP, getresendOtpVerifyEmail),
      takeLatest(LoginTypes.GET_USER_FETCH, getUserFromId)
    ]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default LoginSaga;
