import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../components/unicellular/button';
import './SetPassword.scss';
import AuthString from '../../../constants/strings/auth/Auth';
import { validateOtpWithPassword } from '../FormValidation';
import InputField from '../../../components/unicellular/inputField';
import Key from '../../../assets/svgs/Key';
import Mobile from '../../../assets/svgs/Mobile';
import { forgetPasswordTypes } from '../../../reducers/auth/login/forgetPassword';
import PlaceholderString from '../../../constants/strings/PlaceholderString';
import { resendOtpTypes } from '../../../reducers/auth/login/resendOtp';

function SetPassword(props) {
  const [otpCode, setOtpCode] = useState('');
  const [password, setPassword] = useState('');
  const [err, setError] = useState('');
  const { forgetPassword } = useSelector((state) => state);
  const { error, errorMsg, loading } = forgetPassword;
  const { onClickResend, userId, changeUserPassword, resetPasswordReducer, resendOtpClick } = props;

  const handleChange = (e) => {
    setError('');
    resetPasswordReducer();
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setOtpCode(e.target.value);
    }
  };
  const handleResendOtpClick = () => {
    resendOtpClick(userId);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError('');
    resetPasswordReducer();
  };
  const isValidate = () => {
    let errors = '';
    errors = validateOtpWithPassword(otpCode, password);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  const submitOtp = () => {
    const valid = isValidate();
    if (valid) {
      changeUserPassword(userId, otpCode, password, 'mobile');
      setError('');
      resetPasswordReducer();
    }
  };
  return (
    <div className="app-auth-setpassword-container">
      <div className="detail-info-text">
        <div>
          <div className="text">{AuthString.OTP_TITLE}</div>
          <div className="number">{userId}</div>
        </div>
        <button type="button" className="link-btn" onClick={onClickResend}>
          {AuthString.CHANGE}
        </button>
      </div>
      <div className="app-auth-setpassword-input-flield">
        <InputField
          isIcon
          icon={<Mobile />}
          value={otpCode}
          type="text"
          minLength={4}
          maxLength={4}
          onChange={handleChange}
          placeholder={PlaceholderString.SET_PASSWORD_OTP_INPUT('mobile number')}
        />
      </div>
      <div className="app-auth-setpassword-input-flield">
        <InputField
          isIcon
          icon={<Key />}
          value={password}
          type="password"
          onChange={handlePassword}
          placeholder={PlaceholderString.SET_PASSWORD_INPUT}
        />
      </div>
      {err !== '' && <div className="app-auth-setpassword-error">{err}</div>}
      {error && <div className="app-auth-setpassword-error">{errorMsg}</div>}
      <div className="app-auth-setpassword-action-button">
        <div className="app-auth-setpassword-btn-view">
          <Button
            buttonText={AuthString.CHANGE_PASSWORD}
            className="app-auth-setpassword-btn"
            loading={loading}
            onClick={submitOtp}
          />
        </div>
      </div>
      <div className="app-bottom-text-container">
        <div className="app-auth-setpassword-bottom-link">
          {AuthString.OTP_BOTTOM_TEXT}
          <span>
            <button type="button" onClick={handleResendOtpClick}>
              {AuthString.RESEND}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
SetPassword.propTypes = {
  onClickResend: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  changeUserPassword: PropTypes.func.isRequired,
  resetPasswordReducer: PropTypes.func.isRequired,
  resendOtpClick: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { forgetPassword } = state;
  return { forgetPassword };
};

const mapDispatchToProps = (dispatch) => ({
  changeUserPassword: (userId, otp, password, loginType) =>
    dispatch({
      type: forgetPasswordTypes.GET_FORGET_PASSWORD,
      userId,
      loginType,
      otp,
      password
    }),
  resetPasswordReducer: () =>
    dispatch({
      type: forgetPasswordTypes.RESET_FORGET_PASSWORD
    }),
  resendOtpClick: (mobile) =>
    dispatch({
      type: resendOtpTypes.RESEND_OTP,
      mobile
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
