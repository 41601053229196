import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './DynamicTimedPopup.scss';
import { makeGetApiCall } from '../../../adapters';
import useTimedPopup from '../../../hooks/useTimedPopup';
import URLS from '../../../constants/urls';
import Close from '../../../assets/svgs/Close';
import Button from '../../unicellular/button';
import { generateUrl } from '../../../utils/ImageUtils';

export default function DynamicTimedPopup({ id }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    makeGetApiCall(URLS.API_GET_TIMED_POPUP_DATA(id))
      .then((res) => setData(res.data))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <>
      {data?.isActive && <Wrapper id={id} data={data} />}

      {/* OTHER LOGIC */}
      {error && null}
      {isLoading && null}
    </>
  );
}

function Wrapper({ id, data }) {
  const { closePopup, showPopup } = useTimedPopup({
    id,
    delay: data.delay,
    interval: data.interval
  });

  return (
    <Modal
      size="lg"
      show={showPopup}
      onHide={closePopup}
      centered
      contentClassName="timed-popup-modal">
      <Modal.Body className="timed-popup-modal-body">
        <button
          aria-label="close"
          type="button"
          onClick={closePopup}
          className="timed-popup-modal-close-btn">
          <Close color="#666" />
        </button>
        <div className="timed-popup-content">
          <picture>
            <source media="(max-width: 600px)" srcSet={generateUrl(data.content?.images?.small)} />
            <img
              className="banner-home-skeleton-bg"
              src={generateUrl(data.content?.images?.base)}
              alt={data.content?.title}
            />
          </picture>
          <div className="timed-popup-content-data">
            <div className="title">{data.content?.title}</div>
            <div className="description">{data.content?.description}</div>
            {data.content?.action &&
              (data.content.action.url?.startsWith?.('/') ? (
                <Link to={data.content?.action.url}>
                  <Button
                    onClick={closePopup}
                    className="action-btn"
                    buttonText={data.content?.action.label}
                  />
                </Link>
              ) : (
                <a
                  aria-label="close"
                  href={data.content?.action.url}
                  rel="noopener noreferrer"
                  target="_blank">
                  <Button
                    onClick={closePopup}
                    className="action-btn"
                    buttonText={data.content?.action.label}
                  />
                </a>
              ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const commonPropTypes = {
  id: PropTypes.string.isRequired
};

DynamicTimedPopup.propTypes = {
  ...commonPropTypes
};

DynamicTimedPopup.defaultProps = {};

Wrapper.propTypes = {
  ...commonPropTypes,
  data: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    isActive: PropTypes.bool,
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      action: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string
      }),
      images: PropTypes.shape({
        base: PropTypes.string,
        small: PropTypes.string
      })
    }),
    delay: PropTypes.number,
    interval: PropTypes.number
    // styles: PropTypes.shape({
    //   content: PropTypes
    //     .objectOf(PropTypes.oneOf([PropTypes.number, PropTypes.string])),
    // }),
  })
};

Wrapper.defaultProps = {
  data: {}
};
