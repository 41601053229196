const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
  IS_LOGIN: 'isLogin',
  VIDEO_TITLE: 'title',
  USER_NAME: 'name',
  USER_EMAIL: 'email',
  INSTRUCTOR_ID: 'instructorId',
  CALLBACK: 'paymentCallback',
  CALLBACK_DETAIL: 'callbackDetail',
  COUPON_CODE: 'storeCouponCode',
  IS_COUPON_CODE: 'isCouponApplied',
  PRODUCT_ID: 'buyProductId',
  SESSION_PRODUCT_ID: 'productId',
  IS_SAVED_FOR_LATER_CLICKED: 'isSavedForLaterClicked'
};
export default STORAGE_KEYS;
