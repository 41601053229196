import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddressCard from '../../../components/multicellular/address/addressCard';
import AddressModal from '../../../components/multicellular/address/addressModal';
import Button from '../../../components/unicellular/button';
import STORAGE_KEYS from '../../../constants/storageKey';
import { AddressTypes } from '../../../reducers/account/address/address';
import './Address.scss';

function Address(props) {
  const [showAddAddress, setShowAddAddress] = useState(false);
  const { address } = useSelector((state) => state);
  const { getAddressApi, removeAddressApi, resetAddressApi, resetVerifyPinCode, resetAddAddress } =
    props;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const { addressData: { content = [] } = {}, loading, isAddAddressSuccess } = address;
  useEffect(() => {
    getAddressApi(userId);
    if (isAddAddressSuccess) {
      setShowAddAddress(false);
    }
  }, [isAddAddressSuccess]);
  const showAddressModal = () => {
    resetAddAddress();
    setShowAddAddress(true);
  };
  const closeAddressModal = () => {
    setShowAddAddress(false);
    resetAddressApi();
    resetVerifyPinCode();
  };
  const handleClickRemove = (addressId) => {
    removeAddressApi(userId, addressId);
  };
  const renderNoData = () => (
    <div className="app-address-no-data">
      <div className="title">No Data Found</div>
      <button type="button" className="btn-add-address-link" onClick={showAddressModal}>
        + Add address
      </button>
    </div>
  );
  return (
    <div>
      <div className="app-address-header-flexbox">
        <div>
          <Button
            buttonText="Add address"
            className="app-add-address-btn"
            onClick={showAddressModal}
          />
        </div>
        {showAddAddress && <AddressModal show={showAddAddress} onClose={closeAddressModal} />}
      </div>
      <div>
        {loading ? (
          <AddressCard loading={loading} />
        ) : (
          (content.length > 0 &&
            content.map((item, index) => (
              <div key={`content-${index.toString()}`}>
                <AddressCard
                  name={item.name}
                  addressType={item.addressType}
                  flat={item.flat}
                  street={item.street}
                  locality={item.locality}
                  city={item.city}
                  state={item.state}
                  pincode={item.pincode}
                  mobile={item.mobile}
                  isDefault={item.isDefault}
                  landmark={item.landmark}
                  // eslint-disable-next-line no-underscore-dangle
                  addressId={item._id}
                  // eslint-disable-next-line no-underscore-dangle
                  onClickRemove={() => handleClickRemove(item._id)}
                />
              </div>
            ))) ||
          renderNoData()
        )}
      </div>
    </div>
  );
}
Address.propTypes = {
  getAddressApi: PropTypes.func.isRequired,
  removeAddressApi: PropTypes.func.isRequired,
  resetAddressApi: PropTypes.func.isRequired,
  resetVerifyPinCode: PropTypes.func.isRequired,
  resetAddAddress: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { address } = state;
  return { address };
};

const mapDispatchToProps = (dispatch) => ({
  getAddressApi: (userId) => dispatch({ type: AddressTypes.GET_ADDRESS, userId }),
  removeAddressApi: (userId, addressId) =>
    dispatch({
      type: AddressTypes.REMOVE_ADDRESS,
      userId,
      addressId
    }),
  resetAddressApi: () => dispatch({ type: AddressTypes.ADD_ADDRESS_ERROR_RESET }),
  resetVerifyPinCode: () => dispatch({ type: AddressTypes.RESET_VERIFY_PINCODE }),
  resetAddAddress: () => dispatch({ type: AddressTypes.RESET_ADD_ADDRESS })
});
export default connect(mapStateToProps, mapDispatchToProps)(Address);
