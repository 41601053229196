import mirrorKeyValue from 'mirror-key-value';

export const TestimonialsTypes = mirrorKeyValue([
  'FETCH_TESTIMONIALS',
  'FETCH_TESTIMONIALS_INDICATOR',
  'FETCH_TESTIMONIALS_SUCCESS',
  'FETCH_TESTIMONIALS_FAILED'
]);

const {
  FETCH_TESTIMONIALS,
  FETCH_TESTIMONIALS_INDICATOR,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAILED
} = TestimonialsTypes;

const INITIAL_STATE = {
  testimonialsData: {},
  error: false,
  loading: false,
  errormsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case FETCH_TESTIMONIALS:
      return { ...state, ...data };
    case FETCH_TESTIMONIALS_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        errormsg: ''
      };
    case FETCH_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonialsData: action.response,
        error: false,
        errormsg: ''
      };
    case FETCH_TESTIMONIALS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errormsg: action.message
      };
    default:
      return state;
  }
}
