import mirrorKeyValue from 'mirror-key-value';
import { createActions } from '../../utils/saga/saga-utils';
/* ------------- LoginType ------------- */

export const BulkProductTypes = mirrorKeyValue([
  'SET_SEARCH_PRODUCT',
  'RESET_PRODUCTS',
  ...createActions('BULK_PRODUCT_SEARCH')
]);

const {
  SET_SEARCH_PRODUCT,
  RESET_PRODUCTS,
  BULK_PRODUCT_SEARCH_FETCH,
  BULK_PRODUCT_SEARCH_SUCCESS,
  BULK_PRODUCT_SEARCH_FAILED
} = BulkProductTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  previousCategoryId: '',
  searchLoading: false,
  searchResults: [],
  isSearched: false
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case BULK_PRODUCT_SEARCH_FETCH: {
      return { ...state, searchLoading: true };
    }

    case BULK_PRODUCT_SEARCH_FAILED: {
      return { ...state, searchLoading: false };
    }

    case BULK_PRODUCT_SEARCH_SUCCESS: {
      return { ...state, searchResults: data?.data, searchLoading: false };
    }
    case SET_SEARCH_PRODUCT: {
      return {
        ...state,
        isSearched: action.isSearched,
        previousCategoryId: ''
      };
    }
    case RESET_PRODUCTS: {
      return {
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
}
