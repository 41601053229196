/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';
import SelectDropdown from '../../components/unicellular/selectDropdown';
import './Style.scss';
import ProductString from '../../constants/strings/product';
import Button from '../../components/unicellular/button/Button';
import {
  Cart,
  Delivered,
  DeliveryNotification,
  FreeDelivery,
  Medel,
  PreviewBook,
  Thunder
} from '../../assets/svgs/GeneralIcons';
import STORAGE_KEYS from '../../constants/storageKey';
import Auth from '../auth';
import { DeliveryTypes } from '../../reducers/info/delivery';
import { CartTypes } from '../../reducers/cart/cart';
import RoutesPaths from '../../constants/routes';
import validatePincode from '../product/productDetail/FormValidation';
import discountToPercentage from '../../utils/conversions/discountToPercentage';
// import PrimaryWishlist from '../../components/multicellular/products/wishlist/PrimaryWishlist';
import { StarRating } from './Ratings';
import PreviewbookPopup from './PreviewbookPopup';
import { trackEvent } from '../../analytics/googleAnalytics';

function Hero(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const [pincode, setPincode] = useState('');
  const [err, setError] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quantityOfProduct, setCount] = useState(1);
  const {
    callPincodeApi,
    callAddToCartApi,
    resetAddToCart,
    resetPincode,
    resetIsError,
    resetPincodeReducer
  } = props;
  const {
    information,
    delivery,
    // cart: { isSuccess },
    login,
    login: { user: { mobile } = {} },
    // rating,
    address
  } = useSelector((state) => state);
  const { addressData: { content = [] } = {} } = address;
  const activeAddress = content?.find((item) => item.isDefault);
  const {
    loading,
    productData,
    // cartExists = false,
    wishlistExists = false
  } = information;
  const { load, error, errorMsg, pincodeData } = delivery;
  const { isUserLogin } = login;
  // const { ratingsData = {} } = rating;
  // const { reviews: { avgRating = 0, totalRatings = 0, totalReviews = 0 } = {} } = ratingsData;
  const {
    product: {
      name,
      description,
      // whatIsInTheBox = [],
      freeDelivery,
      // eslint-disable-next-line no-unused-vars
      bookDetails: { tag, age, publishedDate, ratings = {}, preview = [] } = {},
      calculatedPrices: { basePrice = 0, netPrice = 0, savedAmount = 0 } = {},
      // categoryName,
      freeDelivery: freeDeliveryStatus,
      // wishlist = {},
      // isWishlisted = false,
      quantity: productQuantity = {},
      otherBuyOptions: { title, isActive = false, data = [] } = {}
    } = {},
    cart = {}
  } = productData;
  const [customQuantity, setCustomQuantity] = useState();
  const [errorQtyMsg, setErrorMsg] = useState('');
  const [errorQty, setErrorQty] = useState(false);
  const { data: { deliveryEstimate } = {} } = pincodeData;
  const [isFlipbookOpen, setIsFlipbookOpen] = useState(false);

  const handlePreviewBook = () => {
    setIsFlipbookOpen(true);
  };

  const handleCloseFlipbook = () => {
    setIsFlipbookOpen(false);
  };

  // eslint-disable-next-line no-shadow
  const HtmlTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={() => setErrorQty(false)}>
      <Tooltip {...props} classes={{ popper: className }} />
    </ClickAwayListener>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }));

  const resetError = () => {
    setError('');
    resetIsError();
  };

  const handleCheck = (e) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setPincode(e.target.value);
      resetError();
    }
  };

  const isvalidatePincode = () => {
    let errors = '';
    errors = validatePincode(pincode);
    if (errors && errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };

  const onClickChange = () => {
    const valid = isvalidatePincode();
    if (valid) {
      callPincodeApi(id, pincode);
      setError('');
    }
  };

  const handleInput = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    } else if (object.target.value.length === 0) {
      resetError();
      resetPincode();
    }
  };

  const addToCart = () => {
    callAddToCartApi(userId, id, customQuantity || quantityOfProduct, navigate);
  };

  const gotoCart = () => {
    navigate(RoutesPaths.CART);
  };

  const gotoWagon = (productId) => {
    navigate(`${RoutesPaths.CART_WAGON}?item=${productId}`);
  };

  // Check if user is logged in then get the user name from local storage otherwise set it as User
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
  const userDetails =
    userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;

  const handleCart = () => {
    trackEvent(
      'Add to Cart',
      'Product Add to Cart',
      `${userDetails} clicked on Add to Cart for product ${name}`
    );
    if (!isUserLogin) {
      setIsOpenModal(true);
      sessionStorage.setItem(STORAGE_KEYS.SESSION_PRODUCT_ID, id);
    } else {
      addToCart();
      gotoWagon(id);
    }
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
  };

  const handleBuyNow = () => {
    trackEvent(
      'Buy Now',
      'Product Buy Now',
      `${userDetails} clicked on Buy Now for product ${name}`
    );

    if (!isUserLogin) {
      setIsOpenModal(true);
    } else {
      if (cart) {
        const { quantity } = cart;
        if (!quantity || quantity === 0) {
          addToCart();
        }
      } else {
        addToCart();
      }
      gotoCart();
    }
    if (id && id !== '') {
      sessionStorage.setItem(STORAGE_KEYS.PRODUCT_ID, id);
    }
  };

  useEffect(() => {
    if (isUserLogin) {
      setIsOpenModal(false);
      if (activeAddress?.pincode) {
        setPincode(activeAddress?.pincode);
        callPincodeApi(id, activeAddress?.pincode);
      }
    }

    resetAddToCart();
    resetPincode();
  }, [isUserLogin, wishlistExists, activeAddress]);

  useEffect(() => {
    if (!isUserLogin) {
      setPincode('');
      resetPincode();
    }
    return () => {
      resetPincodeReducer();
    };
  }, []);

  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      onClickChange();
    }
  };

  const handleCustomQuantity = (e) => {
    setErrorQty(false);
    const numberPattern = /^[1-9]\d*$/;
    if (e.target.value === '' || numberPattern.test(e.target.value)) {
      if (productQuantity && e.target.value !== '') {
        // eslint-disable-next-line radix
        if (parseInt(e.target.value) < parseInt(productQuantity?.maximum) + 1) {
          setCustomQuantity(e.target.value);
        } else {
          setErrorQty(true);
          setErrorMsg(`Maximum quantity of this product per order is ${productQuantity?.maximum}.`);
        }
      } else {
        setCustomQuantity(e.target.value);
      }
    }
  };

  const scrollToRatings = () => {
    const element = document.getElementById('ratings');
    if (element) {
      const yOffset = -150; // Offset value
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div>
      {/* {tag && (
        <div className="rounded-full bg-gradient-velvet py-2 px-5 width-max-content text-white">
          {tag}
        </div>
      )} */}
      <div className="my-4">
        <h1 className="fs-28 fw-600 h-book-heading hidden-for-mobile">
          {loading ? '' : name} <span className="age-badge">{age}</span>
        </h1>
        <div className="fw-light">{loading ? '' : description}</div>
        <div className="fw-500 fs-4 mt-4 hidden-for-mobile">
          Published on {moment(publishedDate).format('DD-MM-YYYY')}
        </div>
        {tag && (
          <div className="fw-600 mt-4 h-text-container hidden-for-mobile">
            <Medel className="icon-size" />
            <span className="highlighted-text">{tag}</span>
          </div>
        )}
        <button type="button" onClick={scrollToRatings} className="hidden-for-mobile">
          <div className="fs-4 fw-light d-flex align-items-center gap-3 flex-lg-row">
            <span className="d-flex gap-3 align-items-center">
              <span className="mt-1 fs-4 fw-semibold">{ratings?.totalRating}</span>{' '}
              <StarRating rating={ratings?.totalRating} />
            </span>

            <span>{`(${ratings?.numberOfRatings} ratings)`}</span>
          </div>
        </button>

        {/* {ratings?.information && <div className="fw-light">{ratings?.information}</div>} */}

        <div>
          {!loading && (
            <div className="mt-4">
              {!(savedAmount === 0) && (
                <span className="fs-2 price-gap text-primary-200 ">{`-${discountToPercentage(savedAmount, basePrice)}%`}</span>
              )}
              <span className="fs-28 fw-600 price-gap">{`₹ ${netPrice}`}</span>
              {!(basePrice === netPrice) && (
                <span className="fs-4 price-gap text-decoration-line-through">{`MRP: ₹ ${basePrice}`}</span>
              )}
              <span className="fs-5 fw-lighter">(Inclusive of all taxes)</span>
            </div>
          )}
          <div className="fs-3 text-secondary-200 pt-4"> In Stock</div>
          <div>
            <div className="d-flex align-items-center gap-4 py-3">
              <div>{ProductString.QUANTITY}</div>

              {quantityOfProduct > 9 ? (
                <div>
                  <HtmlTooltip
                    title={<div className="cart-error-tooltip">{`${errorQtyMsg}`}</div>}
                    onClose={() => setErrorQty(false)}
                    open={errorQty}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener>
                    <div className="qty-input">
                      <input
                        type="text"
                        maxLength={3}
                        onBlur={() => {
                          if (!customQuantity) {
                            setCustomQuantity(1);
                          }
                        }}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        placeholder="Qty"
                        value={customQuantity}
                        onChange={handleCustomQuantity}
                      />
                    </div>
                  </HtmlTooltip>
                </div>
              ) : (
                <div>
                  <SelectDropdown
                    placeholder="Qty"
                    onChange={(changedValue) => {
                      setCount(changedValue.value);
                      setCustomQuantity(changedValue.value);
                    }}
                    options={Array.from([...Array(10).keys()].map((x) => x + 1)).map((e) => ({
                      label: e === 10 ? `${e}+` : `${e}`,
                      value: `${e}`
                    }))}
                    value={[{ label: quantityOfProduct, value: quantityOfProduct }]}
                    isSearchable={false}
                    isStyled
                    closeMenuOnSelect
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <section id="btn-section">
          <div className="button-container">
            <div className="pt-4 d-flex gap-4">
              <Button
                variant="outlined"
                className={`text-primary-100 border-primary-100 ac-btn ${error || err !== '' ? 'disabled-btn' : ''}`}
                disabled={error || err !== ''}
                onClick={error || err !== '' ? null : handleCart}>
                <Cart className="price-gap mb-1" /> Add to cart
              </Button>
              <Button
                variant="outlined"
                className={`text-primary-100 pb-btn ${error || err !== '' ? 'disabled-btn' : ''}`}
                disabled={error || err !== ''}
                onClick={handlePreviewBook}>
                <PreviewBook className="price-gap mb-1" /> Preview the Book
              </Button>

              {isFlipbookOpen && <PreviewbookPopup onClose={handleCloseFlipbook} data={preview} />}
            </div>
            <div className="mt-4 d-flex gap-4">
              <Button
                variant="primary"
                className={`bn-btn ${error || err !== '' ? 'disabled-btn' : ''}`}
                disabled={error || err !== ''}
                onClick={error || err !== '' ? null : handleBuyNow}>
                <Thunder className="price-gap" /> Buy Now
              </Button>
              {/* <PrimaryWishlist
                className="wishlist-btn"
                productId={id}
                productName={name}
                userDetails={userDetails}
                setIsOpenAuthModal={setIsOpenModal}
                wishlist={wishlist}
                isWishlisted={isWishlisted}
                isHideSnackbar={false}
              /> */}
            </div>
            {freeDeliveryStatus && (
              <p className="delivery-charges-para">
                <FreeDelivery className="delivery-icon" /> No Delivery Charges
              </p>
            )}
          </div>
        </section>

        {/* 
        {bulkOrder && (
          <div className="d-flex justify-content-between align-items-center bg-white rounded-5 book-bulk-order">
            {bulkOrder?.images?.length > 0 && bulkOrder?.images[0] && (
              <img src={generateUrl(bulkOrder?.images[0])} alt="" srcSet="" className="" />
            )}
            <div className="bb-info d-flex flex-column align-items-center justify-content-center">
              <div className="bb-text fw-semibold text-center">{bulkOrder?.title}</div>
              {bulkOrder?.action && (
                <a href={bulkOrder?.action?.url} className="bb-link">
                  <Button variant="primary">{bulkOrder?.action?.text}</Button>
                </a>
              )}
            </div>
            {bulkOrder?.images?.length > 1 && bulkOrder?.images[1] && (
              <img src={generateUrl(bulkOrder?.images[1])} alt="" srcSet="" className="" />
            )}
          </div>
        )} */}
        {/* <div className="mt-5 d-flex gap-4">
          <div className="d-flex flex-column rounded-3 gap-2 bg-light-white p-3 align-items-center justify-content-center">
            <Delivery className="text-primary-100 fs-28" />
            <div className="fs-5 text-center">Free Delivery</div>
          </div>
          <div className="d-flex flex-column rounded-3 gap-2 bg-light-white p-3 align-items-center justify-content-center">
            <PayOnDelivery className="text-primary-100 fs-28" />
            <div className="fs-5 text-center">Pay On Delivery</div>
          </div>
          <div className="d-flex flex-column rounded-3 gap-2 bg-light-white p-3 align-items-center justify-content-center">
            <SecurePayment className="text-primary-100 fs-28" />
            <div className="fs-5 text-center">Secure Payment</div>
          </div>
        </div> */}

        {isActive && (
          <section id="other-buy-options">
            <div className="mt-5 d-flex flex-column buying-options-container">
              <span className="fw-600">{title}</span>
              <div className="d-flex flex-column options-list">
                {data
                  .filter((option) => option?.isActive && option.logo)
                  .map((option) => (
                    <div
                      key={option.partner}
                      className="d-flex justify-content-start align-items-center buying-options">
                      <img src={option.logo} alt={option.partner} className="ob-icon-size" />
                      <Button
                        variant="outlined"
                        className="text-primary-100 border-primary-100 other-bn-btn"
                        onClick={() => window.open(option.link, '_blank')}>
                        Buy Now
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        )}

        <div className="app-delivery-detail-wrapper">
          <div className="app-delivery-flexbox">
            <div className="pincode-wrapper">
              <div className="pincode-input">
                <IoLocationSharp className="fs-1 icon" />
                <input
                  type="text"
                  value={pincode}
                  maxLength={6}
                  onInput={handleInput}
                  onChange={handleCheck}
                  onKeyPress={handleKeypress}
                  placeholder="Enter PIN Code (e.g 560066)"
                />
              </div>
              {pincode?.length === 6 && (
                <button type="button" className="change-btn pn-btn" onClick={onClickChange}>
                  {load ? 'Checking' : 'Check'}
                </button>
              )}
            </div>
          </div>
          {err !== '' && <div className="app-information-error">{err}</div>}
          {error ? (
            <div className="app-information-error">{errorMsg}</div>
          ) : (
            <div className="d-flex mt-4 mb-2 align-items-center gap-4">
              {deliveryEstimate && (
                <div>
                  <Delivered className="fs-3 text-primary-100" />
                </div>
              )}
              {deliveryEstimate && (
                <div className="fs-4">
                  Get it by
                  {'  '}
                  <span className="fw-semibold">
                    {load ? '...' : moment().add(deliveryEstimate, 'd').format('Do MMM')}
                  </span>{' '}
                </div>
              )}
            </div>
          )}

          <div className="d-flex align-items-center gap-4">
            {!freeDelivery && (
              <>
                <div>
                  <DeliveryNotification className="fs-2 text-primary-100" />
                </div>
                <div className="fs-4">Free shipping on orders above ₹ 500/-</div>
              </>
            )}
          </div>
        </div>
      </div>
      {isOpenModal && !isUserLogin && <Auth show={isOpenModal} setShow={setIsOpenModal} />}
    </div>
  );
}

Hero.propTypes = {
  callPincodeApi: PropTypes.func.isRequired,
  callAddToCartApi: PropTypes.func.isRequired,
  resetAddToCart: PropTypes.func.isRequired,
  resetPincode: PropTypes.func.isRequired,
  resetIsError: PropTypes.func.isRequired,
  resetPincodeReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { information, delivery, rating } = state;
  return { information, delivery, rating };
};

const mapDispatchToProps = (dispatch) => ({
  callPincodeApi: (id, pincode) =>
    dispatch({
      type: DeliveryTypes.ADD_PINCODE,
      id,
      pincode
    }),
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    }),
  resetAddToCart: () =>
    dispatch({
      type: CartTypes.RESET_CART
    }),
  resetPincode: () => {
    dispatch({ type: DeliveryTypes.RESET_PINCODE });
  },
  resetPincodeReducer: () => {
    dispatch({ type: DeliveryTypes.RESET_PINCODE_REDUCER });
  },
  resetIsError: () => {
    dispatch({ type: DeliveryTypes.RESET_ERROR });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Hero);
