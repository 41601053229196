import React from 'react';
import PropTypes from 'prop-types';

function Trending(props) {
  const { color } = props;

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M352 144h112v112"
        />
        <path
          d="M48 368l121.37-121.37a32 32 0 0145.26 0l50.74 50.74a32 32 0 0045.26 0L448 160"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
        />
      </svg>
    </div>
  );
}

Trending.propTypes = {
  color: PropTypes.string
};

Trending.defaultProps = {
  color: '#677483'
};

export default Trending;
