import mirrorKeyValue from 'mirror-key-value';

export const SuggestionTypes = mirrorKeyValue([
  'GET_SUGGESTION',
  'GET_SUGGESTION_INDICATOR',
  'GET_SUGGESTION_SUCCESS',
  'GET_SUGGESTION_FAILED',
  'RESET_GET_SUGGESTION'
]);

const {
  GET_SUGGESTION,
  GET_SUGGESTION_INDICATOR,
  GET_SUGGESTION_SUCCESS,
  GET_SUGGESTION_FAILED,
  RESET_GET_SUGGESTION
} = SuggestionTypes;

const INITIAL_STATE = {
  load: false,
  suggestionData: {},
  error: '',
  errorMsg: '',
  isSuccessFullySubmited: false
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_SUGGESTION:
      return { ...state, ...data };
    case GET_SUGGESTION_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case GET_SUGGESTION_SUCCESS:
      return {
        ...state,
        load: false,
        suggestionData: action.response,
        isSuccessFullySubmited: true,
        error: false
      };
    case GET_SUGGESTION_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message
      };
    case RESET_GET_SUGGESTION:
      return INITIAL_STATE;
    default:
      return state;
  }
}
