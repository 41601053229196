/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ProtoTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AddressCard from '../../../components/multicellular/address/addressCard/AddressCard';
import Button from '../../../components/unicellular/button';
import RoutesPaths from '../../../constants/routes';
import { AddressTypes } from '../../../reducers/account/address/address';
import './AddressList.scss';
import AddressModal from '../../../components/multicellular/address/addressModal';
import { generateUrl } from '../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../constants/defaults/product';
import STORAGE_KEYS from '../../../constants/storageKey';
import { CartTypes } from '../../../reducers/cart/cart';
import { addDaysToCurrentDate } from '../../../utils/DateTimeUtils';
import CartFooter from '../../../components/multicellular/navigation/footer/CartFooter';
import { trackEvent } from '../../../analytics/googleAnalytics';
import { LoginTypes } from '../../../reducers/auth/login/login';

const tagColor = ['#BD8800', '#BD0000'];

const renderDeliveryItems = (index, estimate = {}) => {
  const { deliveryEstimate, product: { images = [] } = {} } = estimate;
  return (
    <div className="delivery-estimate-item" key={index.toString()}>
      {images.length > 0 && (
        <img
          src={generateUrl(estimate?.product?.images ? estimate?.product?.images[0] : '')}
          className="image"
          alt=""
          onError={({ currentTarget }) => {
            const currTrgt = currentTarget;
            currTrgt.onerror = null; // prevents looping
            currTrgt.src = PRODUCT_DEFAULTS.productImage;
          }}
        />
      )}
      <div className="delivery-estimate-label">
        Estimated delivery by <span>{addDaysToCurrentDate(deliveryEstimate)}</span>
      </div>
    </div>
  );
};
function AddressList(props) {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState(false);

  const {
    address = {},
    cart: { deliveryEstimate = [] },
    getDeliveryEstimation,
    resetAddAddress,
    resetFetchAddress,
    resetDeliveryEstimation,
    getUserInfo
  } = props;
  const {
    login,
    login: { user: { mobile } = {} }
  } = useSelector((state) => state);
  const { isUserLogin } = login;
  const { getAddressApi } = props;
  const { isAddAddressSuccess, isRemoveAddressSuccess } = address;
  const navigate = useNavigate();
  const {
    addressData: { content = [] } = {},
    loading,
    isFetchAddressSuccess,
    addaddressData: { addresses = [] } = {},
    removeaddressData
  } = address;
  const [defaultAddress, setDefaultAddress] = useState(
    content?.find((addr) => addr?.isDefault === true)
  );
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);

  const compareAdrress = (newArray, oldArray) => {
    const isSameUser = (a, b) => a._id === b._id;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(newArray, oldArray, isSameUser);
    const onlyInB = onlyInLeft(newArray, oldArray, isSameUser);

    const result = [...onlyInA, ...onlyInB];
    return result[0];
  };
  useEffect(() => {
    if (isUserLogin) {
      getUserInfo();
    }
    getAddressApi(userId);

    return () => resetDeliveryEstimation();
  }, [isUserLogin]);
  useEffect(() => {
    if (isAddAddressSuccess) {
      setShowAddAddress(false);
      const saddress = compareAdrress(addresses, content);
      setSelectedAddress(saddress?._id);
      getDeliveryEstimation(saddress?.pincode);
      setDefaultAddress(content?.find((addr) => addr.isDefault === true));
      getAddressApi(userId);
      resetAddAddress();
    }
  }, [isAddAddressSuccess, defaultAddress]);

  useEffect(() => {
    if (isFetchAddressSuccess) {
      setDefaultAddress(content?.find((addr) => addr?.isDefault === true));
      const fetchAddress = content?.find((addr) => addr?.isDefault === true);
      if (deliveryEstimate?.length > 0) {
        getDeliveryEstimation(fetchAddress?.pincode);
      }
      if (!selectedAddress) {
        setSelectedAddress(fetchAddress?._id);
        getDeliveryEstimation(fetchAddress?.pincode);
      }
      resetFetchAddress();
    }
    if (isRemoveAddressSuccess) {
      getAddressApi(userId);
      const fetchAddress = removeaddressData?.addresses?.find((addr) => addr?.isDefault === true);
      setSelectedAddress(fetchAddress ? fetchAddress?._id : null);
      getDeliveryEstimation(fetchAddress ? fetchAddress?.pincode : null);
      setDefaultAddress(fetchAddress || null);
      resetAddAddress();
      resetFetchAddress();
    }
  }, [isFetchAddressSuccess, isRemoveAddressSuccess]);

  const onChangeAddress = (addressId, deliveryHere = false) => {
    setSelectedAddress(addressId);
    // eslint-disable-next-line no-underscore-dangle
    const selectedAddressObj = content?.find((addr) => addr._id === addressId);
    if (
      selectedAddressObj &&
      selectedAddressObj !== null &&
      selectedAddressObj?.pincode &&
      selectedAddressObj !== null
    ) {
      getDeliveryEstimation(selectedAddressObj?.pincode);
    } else {
      resetDeliveryEstimation();
    }
    if (deliveryHere) {
      navigate(`${RoutesPaths.PAYMENT_SUMMARY}?address-id=${addressId}`);
    }
  };

  const location = useLocation();
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
  const userDetails =
    userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;

  if (loading) {
    return <div>loading</div>;
  }
  return (
    <div className="main-container-wrapper">
      <div className="container base-container section cart-payment-page-hg">
        <div className="address-details-wrapper">
          <div className="address-details-container">
            {(defaultAddress || content?.some((addr) => !addr.isDefault)) && (
              <div className="address-heading">
                <div className="address-heading-label">Select a delivery address</div>

                <Button
                  className="action-btn-new-address"
                  buttonText="Add New Address"
                  onClick={() => setShowAddAddress(!showAddAddress)}
                />
              </div>
            )}
            {defaultAddress ? (
              <div className="address-list">
                <div className="address-label-heading">DEFAULT ADDRESS</div>

                <AddressCard
                  {...defaultAddress}
                  // eslint-disable-next-line no-underscore-dangle
                  addressId={defaultAddress._id}
                  userDetails={userDetails}
                  hasDevliveryAction
                  onClickSelection={(addressId) => onChangeAddress(addressId)}
                  // eslint-disable-next-line no-underscore-dangle
                  isDeliveryAddressSelected={defaultAddress._id === selectedAddress}
                  onClickDelivery={(addressId) => onChangeAddress(addressId, true)}
                />
              </div>
            ) : null}
            {content?.some((addr) => !addr.isDefault) ? (
              <div className="address-list">
                <div className="address-label-heading">OTHER ADDRESS</div>
                {content
                  ?.filter((addr) => !addr.isDefault)
                  .map((addressItem, index) => (
                    <AddressCard
                      {...addressItem}
                      addressId={addressItem._id}
                      userDetails={userDetails}
                      hasDevliveryAction
                      onClickSelection={(addressId) => onChangeAddress(addressId)}
                      // eslint-disable-next-line no-underscore-dangle
                      isDeliveryAddressSelected={addressItem._id === selectedAddress}
                      onClickDelivery={(addressId) => onChangeAddress(addressId, true)}
                      key={addressItem._id}
                      tagColor={tagColor[index % 2]}
                    />
                  ))}
              </div>
            ) : null}
            <div className="address-list address-list-ending">
              <button
                type="button"
                className="add-new-address-card"
                onClick={() => setShowAddAddress(!showAddAddress)}>
                + Add New Address
              </button>
            </div>
          </div>
        </div>
        {(defaultAddress || selectedAddress) && deliveryEstimate?.length > 0 ? (
          <div className="address-details-estimate">
            <div className="estimate-details-container">
              <div className="action-details-wrapper">
                <div className="delivery-estimate-heading">DELIVERY ESTIMATES</div>
                <div className="delivery-estimate-items">
                  {deliveryEstimate?.map((estimate, index) => renderDeliveryItems(index, estimate))}
                </div>
              </div>
              <div className="action-button-container">
                <NavLink to={`${RoutesPaths.PAYMENT_SUMMARY}?address-id=${selectedAddress}`}>
                  <Button
                    buttonText="Proceed to Buy"
                    className="proceed-action-button app-gradient-btn"
                    onClick={() => {
                      trackEvent(
                        'Payment',
                        'Proceed to Buy from Address page',
                        `${userDetails} clicked the "Proceed to Buy" button on ${location.pathname}`
                      );
                    }}
                  />
                </NavLink>
              </div>
            </div>
          </div>
        ) : null}
        {showAddAddress && (
          <AddressModal show={showAddAddress} onClose={() => setShowAddAddress(!showAddAddress)} />
        )}
      </div>
      <div>
        <CartFooter />
      </div>
    </div>
  );
}

AddressList.propTypes = {
  getAddressApi: ProtoTypes.func.isRequired,
  address: ProtoTypes.objectOf(
    ProtoTypes.oneOfType([ProtoTypes.bool, ProtoTypes.object, ProtoTypes.array, ProtoTypes.string])
  ).isRequired,
  cart: ProtoTypes.objectOf(
    ProtoTypes.oneOfType([ProtoTypes.bool, ProtoTypes.object, ProtoTypes.array, ProtoTypes.string])
  ).isRequired,
  getDeliveryEstimation: ProtoTypes.func.isRequired,
  resetAddAddress: ProtoTypes.func.isRequired,
  resetFetchAddress: ProtoTypes.func.isRequired,
  resetDeliveryEstimation: ProtoTypes.func.isRequired,
  getUserInfo: ProtoTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { address, cart } = state;
  return { address, cart };
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH }),
  getAddressApi: (userId) => dispatch({ type: AddressTypes.GET_ADDRESS, userId }),
  getDeliveryEstimation: (pincode) => dispatch({ type: CartTypes.CART_ESTIMATION_FETCH, pincode }),
  resetDeliveryEstimation: () => dispatch({ type: CartTypes.RESET_CART_ESTIMATION }),
  removeAddressApi: (userId, addressId) =>
    dispatch({
      type: AddressTypes.REMOVE_ADDRESS,
      userId,
      addressId
    }),
  resetAddAddress: () =>
    dispatch({
      type: AddressTypes.RESET_ADD_ADDRESS
    }),
  resetFetchAddress: () =>
    dispatch({
      type: AddressTypes.RESET_FETCH_ADDRESS
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressList);
