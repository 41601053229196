import Sorting from '../../constants/sort';

const { ASC, DESC } = Sorting;

const options = [
  { title: 'Name: A to Z', key: 'name', value: ASC },
  { title: 'Name: Z to A', key: 'name', value: DESC },
  { title: 'Price: Low to High', key: 'price', value: ASC },
  { title: 'Price: High to Low', key: 'price', value: DESC }
];

export default options;
