import React from 'react';

export default function CircleForwardArrow() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        className="ionicon"
        viewBox="0 0 512 512">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M262.62 336L342 256l-79.38-80M330.97 256H170"
        />
        <path
          d="M256 448c106 0 192-86 192-192S362 64 256 64 64 150 64 256s86 192 192 192z"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="32"
        />
      </svg>
    </div>
  );
}
