import mirrorKeyValue from 'mirror-key-value';

export const BulkOrderTypes = mirrorKeyValue([
  'ADD_BULK_ORDER_LIST',
  'ADD_BULK_ORDER_LIST_INDICATOR',
  'ADD_BULK_ORDER_LIST_SUCCESS',
  'ADD_BULK_ORDER_LIST_FAILED',
  'SHIPPING_VERIFY_PINCODE',
  'SHIPPING_VERIFY_PINCODE_INDICATOR',
  'SHIPPING_VERIFY_PINCODE_SUCCESS',
  'SHIPPING_VERIFY_PINCODE_FAILED',
  'SHIPPING_RESET_VERIFY_PINCODE',
  'RESET_REDUCER'
]);

const {
  ADD_BULK_ORDER_LIST,
  ADD_BULK_ORDER_LIST_INDICATOR,
  ADD_BULK_ORDER_LIST_SUCCESS,
  ADD_BULK_ORDER_LIST_FAILED,
  SHIPPING_VERIFY_PINCODE,
  SHIPPING_VERIFY_PINCODE_INDICATOR,
  SHIPPING_VERIFY_PINCODE_SUCCESS,
  SHIPPING_VERIFY_PINCODE_FAILED,
  SHIPPING_RESET_VERIFY_PINCODE,
  RESET_REDUCER
} = BulkOrderTypes;

const INITIAL_STATE = {
  loading: false,
  bulkOrderData: {},
  error: '',
  errorMsg: '',
  isSuccessFullOrdered: false,
  shippingPincodeloading: false,
  shippingPincodeData: {},
  shippingPincodeerror: false,
  shippingPincodeerrorMsg: '',
  isShippingPincodeVerified: false
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case ADD_BULK_ORDER_LIST:
      return { ...state, ...data };
    case ADD_BULK_ORDER_LIST_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ADD_BULK_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkOrderData: action.response,
        error: false,
        isSuccessFullOrdered: true
      };
    case ADD_BULK_ORDER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isSuccessFullOrdered: false
      };
    case RESET_REDUCER:
      return INITIAL_STATE;
    case SHIPPING_VERIFY_PINCODE:
      return { ...state, ...data };
    case SHIPPING_VERIFY_PINCODE_INDICATOR:
      return {
        ...state,
        shippingPincodeloading: true,
        shippingPincodeerror: false,
        isShippingPincodeVerified: false
      };
    case SHIPPING_VERIFY_PINCODE_SUCCESS:
      return {
        ...state,
        shippingPincodeloading: false,
        shippingPincodeData: action.response,
        shippingPincodeerror: false,
        isShippingPincodeVerified: true
      };
    case SHIPPING_VERIFY_PINCODE_FAILED:
      return {
        ...state,
        shippingPincodeloading: false,
        shippingPincodeerror: true,
        shippingPincodeerrorMsg: action.message,
        isShippingPincodeVerified: false
      };
    case SHIPPING_RESET_VERIFY_PINCODE:
      return {
        ...state,
        shippingPincodeloading: false,
        shippingPincodeerror: false,
        shippingPincodeerrorMsg: '',
        isShippingPincodeVerified: false,
        shippingPincodeData: {}
      };
    default:
      return state;
  }
}
