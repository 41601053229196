import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { connect, useSelector } from 'react-redux';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import InputField from '../../../unicellular/inputField';
import MyAccountString from '../../../../constants/strings/myAccount';
import PlaceholderString from '../../../../constants/strings/PlaceholderString';
import { validateAddressForm } from './FormValidation';
import { AddressTypes } from '../../../../reducers/account/address/address';
import STORAGE_KEYS from '../../../../constants/storageKey';

import './AddressModal.scss';
import STATE_CODES from '../../../../config/order/StateCodes';

function AddressModal(props) {
  const {
    show,
    onClose,
    callAddAddressApi,
    isEdit,
    editForm,
    callEditAddressApi,
    getVerifyPinCode,
    resetVerifyPinCode
  } = props;

  const [name, setName] = useState(isEdit ? editForm.name : '');
  const [mobile, setMobile] = useState(
    isEdit ? ((editForm?.mobile || editForm?.mobile !== null) && editForm.mobile) || '' : ''
  );
  const [pincode, setPincode] = useState(
    isEdit
      ? ((editForm?.pincode || editForm?.pincode !== null) && editForm.pincode.toString()) || ''
      : ''
  );
  const [addressMark, setAddress] = useState(isEdit ? editForm.address : '');
  const [landmark, setLandMark] = useState(isEdit ? editForm.landmark : '');
  const [addressType, setaddressType] = useState(isEdit ? editForm.addressType : 'Home');
  const [city, setCity] = useState(isEdit ? editForm.city : '');
  const [State, setState] = useState(isEdit ? editForm.state : '');
  const [district, setDistrict] = useState(isEdit ? editForm.locality : '');
  const [isEditPincodeApiCall, setIsEditPincodeApiCall] = useState(true);
  const [isDefault, setisDefault] = useState(isEdit ? editForm.isDefault : false);
  const addressTypeList = ['Home', 'Work'];
  const [err, setError] = useState('');
  const { address } = useSelector((state) => state);
  const {
    addloading,
    addError,
    addErrorMsg,
    updateloading,
    updateError,
    updateErrorMsg,
    isSuccessFullyVerified,
    pincodeData,
    pincodeerror,
    pincodeerrorMsg,
    pincodeloading
  } = address;
  const checkBoxSize = { '& .MuiSvgIcon-root': { fontSize: 22 } };
  useEffect(() => {
    if (isSuccessFullyVerified) {
      const { content: { stateCode = '' } = {} } = pincodeData;
      const fetchStateFromStateCode = STATE_CODES.find((x) => x.value === stateCode)?.label;
      setState(fetchStateFromStateCode);
    }
    if (isEdit && isEditPincodeApiCall) {
      if (editForm?.pincode || editForm?.pincode !== null) {
        getVerifyPinCode(pincode);
        setIsEditPincodeApiCall(false);
      }
    }
  }, [State, district, pincodeData, isEdit]);
  const handleNameChange = (e) => {
    const regex = /^[A-Za-z ]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setName(e.target.value);
      setError('');
    }
  };
  const handleMobileChange = (event) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (event.target.value === '' || regexp.test(event.target.value)) {
      setMobile(event.target.value);
    }
    setError('');
  };
  const handlePinCodeChange = (event) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    resetVerifyPinCode();
    if (event.target.value === '' || regexp.test(event.target.value)) {
      setPincode(event.target.value);
      if (event.target.value.length === 6) {
        getVerifyPinCode(event.target.value);
      }
    }
    setError('');
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setError('');
  };
  const handleLocality = useCallback(
    (event) => {
      setDistrict(event.target.value);
    },
    [district]
  );

  const handleLandmarkChange = (event) => {
    setLandMark(event.target.value);
    setError('');
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
    setError('');
  };
  const handleChangeDefaultCheckbox = (event) => {
    setisDefault(event.target.checked);
    setError('');
  };
  const isValidate = () => {
    let errors = '';
    errors = validateAddressForm(name, mobile, pincode, addressMark, landmark);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);

    if (isEdit) {
      if (valid && !pincodeerror) {
        callEditAddressApi(
          userId,
          name,
          mobile,
          pincode,
          addressMark,
          landmark,
          State,
          district,
          addressType,
          isDefault,
          editForm.addressId,
          city
        );
        setError('');
      }
    } else if (valid && !pincodeerror) {
      callAddAddressApi(
        userId,
        name,
        mobile,
        pincode,
        addressMark,
        landmark,
        State,
        district,
        addressType,
        isDefault,
        city
      );
      setError('');
    }
  };
  useEffect(() => {
    // Add an event listener to the document
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        submitForm();
      }
    };
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [
    name,
    pincodeerror,
    mobile,
    pincode,
    addressMark,
    landmark,
    State,
    district,
    addressType,
    isDefault,
    editForm.addressId,
    city
  ]);
  const renderMainLayout = () => (
    <div className="address-modal-body-container">
      <div className="address-modal-input-view">
        <InputField
          placeholder={PlaceholderString.NAME}
          value={name}
          onChange={handleNameChange}
          className="address-modal-input"
        />
      </div>
      <div className="address-modal-input-view">
        <InputField
          value={mobile}
          maxLength={10}
          minLength={10}
          onChange={handleMobileChange}
          placeholder={PlaceholderString.ADDRESS_MOBILE_NUMBER}
          className="address-modal-input"
        />
      </div>
      <div className="address-modal-input-view">
        <InputField
          value={pincode}
          onChange={handlePinCodeChange}
          maxLength={6}
          placeholder={PlaceholderString.ADDRESS_PINCODE}
          className="address-modal-input"
        />
        {pincodeloading ? (
          <div className="admo-loading">
            <Spinner animation="border" size="md" variant="secondary" />
          </div>
        ) : null}
        <div className="note">
          <span>Note: </span>
          Pincode must be of 6 digits
        </div>
      </div>
      <div className="address-modal-input-view">
        <InputField
          value={addressMark}
          onChange={handleAddressChange}
          placeholder={PlaceholderString.ADDRESS}
          className="address-modal-input"
        />
      </div>
      <div className="address-modal-input-view">
        <InputField
          value={landmark}
          onChange={handleLandmarkChange}
          placeholder={PlaceholderString.ADDRESS_LANDMARK}
          className="address-modal-input"
        />
      </div>
      <div className="address-modal-input-view">
        <InputField
          value={city}
          onChange={handleCityChange}
          placeholder={PlaceholderString.ADDRESS_CITY}
          className="address-modal-input"
        />
      </div>
      <div className="address-modal-input-flex">
        <InputField
          placeholder={PlaceholderString.ADDRESS_DISTRICT}
          value={district}
          className="address-modal-input-box"
          onChange={handleLocality}
        />
        <InputField
          placeholder={PlaceholderString.ADDRESS_STATE}
          className="address-modal-input-box"
          value={State}
          readOnly
        />
      </div>
      <div className="address-form-section-title">{MyAccountString.SAVE_ADDRESS_SECTION_TITLE}</div>
      <div className="address-modal-select-flex">
        {addressTypeList.map((item, index) => (
          <button
            type="button"
            key={`address-list-${index.toString()}`}
            value={item}
            onClick={() => {
              setaddressType(item);
            }}
            className={`${addressType === item ? 'address-modal-select-box-active' : 'address-modal-select-box'}`}>
            {item}
          </button>
        ))}
      </div>
      <div>
        <FormControlLabel
          control={<Checkbox sx={checkBoxSize} />}
          onChange={handleChangeDefaultCheckbox}
          checked={isDefault}
          label={
            <Typography className="app-address-control-label">
              {PlaceholderString.DEFAULT_ADDRESS}
            </Typography>
          }
        />
      </div>
    </div>
  );
  return (
    <Modal
      size="md"
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      contentClassName="address-modal">
      <Modal.Body className="address-modal-body">
        <div className="address-modal-header-container">
          <div>
            {isEdit
              ? MyAccountString.ADDRESS_MODAL_HEADER_TEXT_EDIT
              : MyAccountString.ADDRESS_MODAL_HEADER_TEXT}
          </div>
          <button
            type="button"
            aria-label="close"
            onClick={onClose}
            className="address-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>

        <div>{renderMainLayout()}</div>
        <div className="address-modal-footer-container">
          <div>
            {err !== '' && <div className="address-modal-error-message">{err}</div>}
            {addError && <div className="address-modal-error-message">{addErrorMsg}</div>}
            {updateError && <div className="address-modal-error-message">{updateErrorMsg}</div>}
            {pincodeerror && <div className="address-modal-error-message">{pincodeerrorMsg}</div>}
          </div>
          {isEdit ? (
            <Button buttonText="Edit Address" loading={updateloading} onClick={submitForm} />
          ) : (
            <Button buttonText="Add Address" loading={addloading} onClick={submitForm} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
AddressModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callAddAddressApi: PropTypes.func.isRequired,
  callEditAddressApi: PropTypes.func.isRequired,
  getVerifyPinCode: PropTypes.func.isRequired,
  resetVerifyPinCode: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  editForm: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    pincode: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    landmark: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    locality: PropTypes.string.isRequired,
    addressType: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
    addressId: PropTypes.string.isRequired
  })
};
AddressModal.defaultProps = {
  isEdit: '',
  editForm: {}
};
const mapStateToProps = (state) => {
  const { address } = state;
  return { address };
};

const mapDispatchToProps = (dispatch) => ({
  callAddAddressApi: (
    userId,
    name,
    mobile,
    pincode,
    address,
    landmark,
    state,
    district,
    addressType,
    isDefault,
    city
  ) =>
    dispatch({
      type: AddressTypes.ADD_ADDRESS,
      userId,
      name,
      mobile,
      pincode,
      address,
      landmark,
      state,
      district,
      addressType,
      isDefault,
      city
    }),
  getVerifyPinCode: (pincode) => dispatch({ type: AddressTypes.VERIFY_PINCODE, pincode }),
  resetVerifyPinCode: () => dispatch({ type: AddressTypes.RESET_VERIFY_PINCODE }),
  callEditAddressApi: (
    userId,
    name,
    mobile,
    pincode,
    address,
    landmark,
    state,
    district,
    addressType,
    isDefault,
    addressId,
    city
  ) =>
    dispatch({
      type: AddressTypes.UPDATE_ADDRESS,
      userId,
      name,
      mobile,
      pincode,
      address,
      landmark,
      state,
      district,
      addressType,
      isDefault,
      addressId,
      city
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(AddressModal);
