import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import './Breadcrum.scss';

const StyledBreadCrumb = styled((props) => (
  <Breadcrumbs
    aria-label="breadcrumb"
    className="app-breadcrumb-text"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(() => ({
  '& .MuiBreadcrumbs-separator': {
    lineHeight: '1',
    fontSize: '2rem',
    fontWeight: '500',
    marginLeft: '4px',
    marginRight: '4px'
  }
}));

export default function Breadcrum(props) {
  const { links } = props;
  return (
    <div>
      <StyledBreadCrumb aria-label="breadcrumb" className="app-breadcrumb-text">
        {links?.map((link, index) =>
          index + 1 !== links.length ? (
            (link?.notClickable && (
              <div className="app-breadcrumb-text-in" key={`link-0-${index.toString()}`}>
                {link?.label}
              </div>
            )) || (
              <Link
                color="#666"
                className="app-breadcrumb-link"
                href={link?.link}
                key={`link-1-${index.toString()}`}>
                {link?.label}
              </Link>
            )
          ) : (
            <div className="app-breadcrumb-text" key={`link-2-${index.toString()}`}>
              {link?.label}
            </div>
          )
        )}
      </StyledBreadCrumb>
    </div>
  );
}
Breadcrum.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  )
};
Breadcrum.defaultProps = {
  links: {
    link: '',
    label: ''
  }
};
