/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../../components/multicellular/products/productCard';
import FrequentlyBoughtItemCard from '../../../components/multicellular/products/frequentlyBoughtItemCard';
import './ProductDetail.scss';
import { CartTypes } from '../../../reducers/cart/cart';
import Button from '../../../components/unicellular/button';
import RoutesPaths from '../../../constants/routes';
import STORAGE_KEYS from '../../../constants/storageKey';
import createSlug from '../../../utils/conversions/createSlug';

function FrequentlyBoughtTogether(props) {
  const navigate = useNavigate();

  const handleRedirection = (product) => {
    navigate(
      `${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product?.name)}/${product._id}`
    );
  };

  const {
    frequentlyBoughtTogether: {
      load,
      frequentlyBoughtTogetherProducts: { content = [] } = {}
    } = {},
    cart,
    callBulkAddToCartApi
  } = props;

  const [selected, setSelected] = useState([]);
  // const [productsInCart, setProductsInCart] = useState([]);

  const handleCart = () => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    // setProductsInCart(selected.map((it) => it._id));
    callBulkAddToCartApi(
      userId,
      selected.map((it) => ({ productId: it._id, qty: 1 })),
      navigate
    );
  };

  const renderButtonText = () => {
    if (!selected.length) {
      return 'Select items to buy';
    }
    if (selected.length === 1) {
      return 'Add to Cart';
    }
    return `Add ${selected.length} items to cart`;
  };
  useEffect(() => {
    setSelected(content);
  }, [content]);

  const showCardBottomInfo = content?.some?.((it) => it.isWishlisted || it.price > 500);

  return (
    <div className="app-product-carousel-wrapper">
      {content.length > 0 && (
        <div className="section-title container">Frequently bought together</div>
      )}
      <div className="container">
        {load ? (
          <div className="col-md-3">
            <ProductCard loading={load} />
          </div>
        ) : (
          <div className="frequently-bought-together-wrapper">
            <div className="frequently-bought-together-items">
              {content.length > 0 &&
                content.map((item, index) => (
                  <>
                    {index !== 0 && <div className="item-seperator">+</div>}
                    <div
                      key={`content-${index.toString()}`}
                      className="app-product-padding-section">
                      <FrequentlyBoughtItemCard
                        title={item.name}
                        rating={item.avgRating}
                        price={item.price}
                        isFreeDelivery={item.isFreeDelivery}
                        discount={item.discount}
                        description={item.description}
                        image={item.images ? item.images[0] : ''}
                        productId={item._id}
                        wishlist={item.wishlist}
                        isWishlisted={item.isWishlisted}
                        calculatedPrices={item.calculatedPrices}
                        isSelected={selected.some((it) => it._id === item._id)}
                        onToggleSelect={(val) =>
                          setSelected((prev) => {
                            if (val) return [...prev, item];
                            return prev.filter((it) => it._id !== item._id);
                          })
                        }
                        // isAddedToCart={
                        //   productsInCart.includes(item._id) || item.isAddedToCart
                        // }
                        showBottomInfo={showCardBottomInfo}
                        onClickBuyNow={() => handleRedirection(item)}
                      />
                    </div>
                  </>
                ))}
            </div>

            <div className="btn-wrap-container">
              {selected.length > 0 && (
                <span className="total-price">
                  Total Price: ₹ {Math.round(selected.reduce((acc, it) => acc + +it.price, 0))}
                </span>
              )}
              <Button
                disabled={!selected.length}
                // eslint-disable-next-line no-nested-ternary
                buttonText={renderButtonText()}
                className="app-btn-add-to-cart"
                onClick={handleCart}
                loading={cart.loading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FrequentlyBoughtTogether.propTypes = {
  frequentlyBoughtTogether: PropTypes.shape({
    frequentlyBoughtTogetherProducts: PropTypes.shape({
      content: PropTypes.array
    }),
    load: PropTypes.bool.isRequired
  }),
  callBulkAddToCartApi: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired
  })
};

FrequentlyBoughtTogether.defaultProps = {
  frequentlyBoughtTogether: {
    frequentlyBoughtTogetherProducts: {}
  },
  cart: {}
};

const mapStateToProps = (state) => {
  const { frequentlyBoughtTogether, cart } = state;
  return { frequentlyBoughtTogether, cart };
};

const mapDispatchToProps = (dispatch) => ({
  callBulkAddToCartApi: (userId, products, navigate) =>
    dispatch({
      type: CartTypes.BULK_ADD_TO_CART,
      userId,
      products,
      navigate
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequentlyBoughtTogether);
