/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import moment from 'moment';
import { connect } from 'react-redux';
import momentTz from 'moment-timezone';
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import CheckMark from '../../../../assets/svgs/CheckMark';
import './UserRatingCard.scss';
import STORAGE_KEYS from '../../../../constants/storageKey';
import Like from '../../../../assets/svgs/Like';
import { VoteTypes } from '../../../../reducers/vote/vote';

function UserRatingCard(props) {
  const {
    username,
    isUserVerified,
    title,
    comment,
    date,
    rating,
    images,
    likes,
    dislikes,
    isLike,
    isDislike
  } = props;
  // const { vote } = useSelector((state) => state);
  const [noOfLikes, setNoOfLikes] = useState(likes);
  const [noOfDislikes, setNoOfDislikes] = useState(dislikes);
  const [isLiked, setIsLiked] = useState(isLike);
  const [isDisliked, setIsDisiked] = useState(isDislike);
  const handleOnClickLikes = (item) => {
    const { callLikeReview, reviewId } = props;
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    if (isLiked) {
      setNoOfLikes(item - 1);
      setIsLiked(false);
      setIsDisiked(false);
      callLikeReview(reviewId, userId, true, true);
    } else {
      setNoOfLikes(item + 1);
      setIsLiked(true);
      setIsDisiked(false);
      if (isDisliked) {
        setNoOfDislikes(noOfDislikes - 1);
      }
      callLikeReview(reviewId, userId, true, false);
    }
  };
  const handleOnClickDislikes = (item) => {
    const { callLikeReview, reviewId } = props;
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    if (isDisliked) {
      setNoOfDislikes(item - 1);
      setIsLiked(false);
      setIsDisiked(false);
      callLikeReview(reviewId, userId, false, true);
    } else {
      setNoOfDislikes(item + 1);
      setIsLiked(false);
      setIsDisiked(true);
      if (isLiked) {
        setNoOfLikes(noOfLikes - 1);
      }
      callLikeReview(reviewId, userId, false, false);
    }
  };
  const reviewDateAsPerTimeZone = momentTz(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Kolkata')
    ?.format();
  const reviewDate = moment(reviewDateAsPerTimeZone).format('MMM DD,YYYY HH:MM A');
  const renderImageGroup = () => (
    <div className="app-product-review-image-container ">
      <div className="rating-image-group">
        <img
          src="https://i.gadgets360cdn.com/products/large/iqoo-z5-800x800-1632388071.jpg"
          alt=""
          srcSet=""
        />
      </div>
    </div>
  );
  return (
    <div className="app-user-rating-card ">
      <div className="user-info-container">
        <div>
          <div className="user-name">
            {username}
            {isUserVerified && (
              <span className="certified-text">
                <div className="checkmark">
                  <CheckMark />
                </div>
                <div>Certified User</div>
              </span>
            )}
          </div>
          <div className="date-time-text">{reviewDate}</div>
        </div>
        <div>
          <div className="app-like-action-btn-container">
            <button
              type="button"
              onClick={() => handleOnClickLikes(noOfLikes)}
              className="app-like-btn">
              <Like color={(isLiked && '#9aaaff') || '#888'} />
              <span className="like-number">{noOfLikes}</span>
            </button>
            <button
              type="button"
              onClick={() => handleOnClickDislikes(noOfDislikes)}
              className="app-like-btn rotate">
              <Like color={(isDisliked && '#9aaaff') || '#888'} />
              <span className="like-number">{noOfDislikes}</span>
            </button>
          </div>
        </div>
      </div>
      <div>
        <Rating name="read-only" value={rating} readOnly size="large" />
      </div>
      <div className="user-rating-comment-conatiner">
        <div className="user-rating-title">{title}</div>
        <div className="user-rating-description">
          <ReactReadMoreReadLess charLimit={250} readMoreText="Read more" readLessText="Read less">
            {comment}
            {/* .split('\n').map((i) =>
          <div className="user-rating-description" key={i}>{i}</div>) */}
          </ReactReadMoreReadLess>
        </div>
      </div>
      {images.length > 0 && <div>{renderImageGroup()}</div>}
    </div>
  );
}

UserRatingCard.propTypes = {
  username: PropTypes.string,
  likes: PropTypes.number,
  dislikes: PropTypes.number,
  isLike: PropTypes.bool,
  isDislike: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  title: PropTypes.string,
  comment: PropTypes.string,
  date: PropTypes.string,
  reviewId: PropTypes.string,
  images: PropTypes.array,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callLikeReview: PropTypes.func.isRequired
};
UserRatingCard.defaultProps = {
  username: '',
  isUserVerified: false,
  title: '',
  comment: '',
  date: '',
  reviewId: '',
  images: [],
  rating: 0,
  likes: 0,
  dislikes: 0,
  isLike: false,
  isDislike: false
};

const mapStateToProps = (state) => {
  const { vote } = state;
  return { vote };
};
const mapDispatchToProps = (dispatch) => ({
  callLikeReview: (reviewId, userId, isLiked, isRemoved) =>
    dispatch({
      type: VoteTypes.GET_VOTE,
      reviewId,
      userId,
      isLiked,
      isRemoved
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRatingCard);
