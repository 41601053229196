import React from 'react';

export default function NavToggle() {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="2" x2="17" y2="2" stroke="#FFF" strokeWidth="2" strokeLinecap="round" />
        <line x1="1" y1="8" x2="17" y2="8" stroke="#FFF" strokeWidth="2" strokeLinecap="round" />
        <line x1="1" y1="14" x2="17" y2="14" stroke="#FFF" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
}
