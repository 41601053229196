import React from 'react';
import PropTypes from 'prop-types';

export default function Cart(props) {
  const { color } = props;
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.9059 16.6554C19.8554 16.645 20.6856 15.9796 20.9292 15.0254L23.2706 5.97787C23.3451 5.68385 23.1811 5.38467 22.8977 5.30214C22.853 5.29183 22.8082 5.28151 22.7635 5.28151H5.15083L3.95279 0.690702C3.89314 0.453424 3.68435 0.283203 3.44574 0.283203H0.453125V1.3819H3.02816L4.18644 5.86954C4.18146 5.90565 4.18146 5.94176 4.18644 5.97787L6.57257 15.0563C6.58749 15.1285 6.61234 15.2059 6.6372 15.2781L7.44252 18.3215C6.66702 18.7496 6.14009 19.5955 6.14009 20.5704C6.14009 21.9786 7.2387 23.1186 8.59582 23.1186C9.95293 23.1186 11.0516 21.9786 11.0516 20.5704C11.0516 20.132 10.9422 19.7142 10.7533 19.3531C10.7085 19.2706 10.6638 19.188 10.6091 19.1055H16.8578C16.5794 19.5182 16.4104 20.0237 16.4104 20.5704C16.4104 21.9786 17.509 23.1186 18.8661 23.1186C20.2233 23.1186 21.3219 21.9786 21.3219 20.5704C21.3219 19.905 21.0783 19.3015 20.6756 18.8476C20.2431 18.3369 19.6068 18.012 18.896 18.0068H8.50137L8.12853 16.5883C8.28761 16.6296 8.45166 16.6554 8.6157 16.6554H18.9059ZM18.8711 19.1055C19.6516 19.1055 20.2829 19.7606 20.2829 20.5704C20.2829 21.3803 19.6516 22.0354 18.8711 22.0354C18.0907 22.0354 17.4593 21.3803 17.4593 20.5704C17.4593 19.7606 18.0907 19.1055 18.8711 19.1055ZM8.59582 19.1055C9.37628 19.1055 10.0026 19.7658 10.0026 20.5704C10.0026 21.3803 9.37131 22.0354 8.59085 22.0354C7.81038 22.0354 7.18402 21.3803 7.18402 20.5704C7.18402 19.7606 7.81535 19.1055 8.59582 19.1055ZM7.65131 14.8603L6.46321 10.352L5.40436 6.39052H22.0526L19.9001 14.7778C19.7808 15.2575 19.3682 15.5928 18.891 15.6031H8.60576C8.17327 15.5773 7.79547 15.2884 7.65131 14.8603Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
Cart.propTypes = {
  color: PropTypes.string
};
Cart.defaultProps = {
  color: '#FFFFFF'
};
