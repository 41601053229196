/* eslint-disable no-underscore-dangle */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Accordion from 'react-bootstrap/Accordion';
import DownArrow from '../../../../assets/svgs/DownArrow';
import RoutesPaths from '../../../../constants/routes';
import { CategoryTypes } from '../../../../reducers/category/allCategory';
import { ProductTypes } from '../../../../reducers/product/productList';
import { FilterTypes } from '../../../../reducers/filter/filter';
import './Styles.scss';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& .MuiMenu-list': {
      padding: '0'
    }
  }
}));

const chunk = (arr, size) =>
  arr.reduce((acc, val, ind) => {
    const subIndex = ind % size;
    if (!Array.isArray(acc[subIndex])) {
      acc[subIndex] = [val];
    } else {
      acc[subIndex].push(val);
    }
    return acc;
  }, []);

function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { allCategory } = useSelector((state) => state);
  const navigate = useNavigate();
  const { callAllCategory, resetProductApi, resetFilterApi } = props;
  const { categoryData: { content = [] } = {} } = allCategory;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    callAllCategory();
  }, []);

  const handleReset = () => {
    resetProductApi();
    resetFilterApi();
  };
  // [col, item_index, item_index, ...] <- array values
  //  0  , level     , level       ...  <- denotes the array index
  const [openAccordionPath, setOpenAccordionPath] = React.useState([]);

  const handleClickLink = (productId) => {
    handleReset();
    setOpenAccordionPath([]);
    setAnchorEl(null);
    navigate(`${RoutesPaths.PRODUCT_EXPLORER}/${productId}`);
  };

  const renderMenu = (array, col, lvl = 1) =>
    array.map((item, index) =>
      item.children && item.children.length > 0 ? (
        <Accordion
          key={`Accordion-${index?.toString()}`}
          activeKey={openAccordionPath}
          onSelect={(_, val) => {
            if (val) {
              setOpenAccordionPath((prev) => {
                if (prev.includes(item.name)) {
                  return prev.slice(0, lvl);
                }
                const newpath = [...prev];
                newpath[0] = col;
                newpath[lvl] = item.name;
                return newpath;
              });
            } else {
              setOpenAccordionPath((prev) => prev.slice(0, lvl));
            }
          }}>
          <Accordion.Item eventKey={item.name}>
            <Accordion.Header>
              <div>{item.name}</div>
            </Accordion.Header>
            <Accordion.Body>{renderMenu(item.children, col, lvl + 1)}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <button
          type="button"
          key={`Accordion-btn-${index?.toString()}`}
          aria-label="nav-btn"
          className="menu-items"
          onClick={() => handleClickLink(item._id)}>
          <div>{item.name}</div>
        </button>
      )
    );
  return (
    <div className="app-mega-menu-wrapper">
      <Button
        id="app-mega-menu-btn"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<DownArrow color="#FFFFFF" />}>
        All Categories
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'app-mega-menu-btn'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <div className="app-mega-menu">
          <div className="top-border">
            <div className="border-wd border-red" />
            <div className="border-wd border-blue" />
            <div className="border-wd border-orange" />
          </div>
          <div className="app-mm-flex">
            {chunk(content, 4)?.map((item, index) => (
              <div className="app-mega-menu-section" key={index?.toString()}>
                {renderMenu(item, index)}
              </div>
            ))}
          </div>
        </div>
      </StyledMenu>
    </div>
  );
}
CustomizedMenus.propTypes = {
  callAllCategory: PropTypes.func.isRequired,
  resetProductApi: PropTypes.func.isRequired,
  resetFilterApi: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { allCategory } = state;
  return { allCategory };
};

const mapDispatchToProps = (dispatch) => ({
  callAllCategory: () => dispatch({ type: CategoryTypes.GET_ALL_CATEGORY }),
  resetProductApi: () => dispatch({ type: ProductTypes.RESET_PRODUCTS }),
  resetFilterApi: () => dispatch({ type: FilterTypes.RESET_INITIAL_FILTER })
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomizedMenus);
