import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import Payment from '../payment';
import Routes from '../../../constants/routes';
import Button from '../../../components/unicellular/button';
import { AddressTypes } from '../../../reducers/account/address/address';
import STORAGE_KEYS from '../../../constants/storageKey';
import { LoginTypes } from '../../../reducers/auth/login/login';
import './PaymentSummary.scss';
import CartFooter from '../../../components/multicellular/navigation/footer/CartFooter';

const renderPriceSplitUp = (heading, price, isNegative = false) => (
  <div className="price-summary">
    <div className="heading-price">{heading}</div>
    <div className={`label-price ${isNegative ? 'discount' : ''}`}>
      {`${isNegative ? '-' : ''}${'\u20B9'}${price}`}
    </div>
  </div>
);

function PaymentSummary(props) {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const addressId = params.get('address-id');
  const {
    address,
    login: { user = {} },
    cart: { carts = [], summary = {} }
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const { addressData: { content = [] } = {} } = address;
  // eslint-disable-next-line no-underscore-dangle
  const selectedAddress = content?.find((addr) => addr._id === addressId);

  useEffect(() => {
    const { getAddressApi, getUserInfo } = props;
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    getAddressApi(userId);
    getUserInfo();
    const goToCartPage = () => navigate(Routes.CART);
    if (carts.length === 0) {
      goToCartPage();
    }
  }, [carts, navigate]);

  const addressDetails = [
    selectedAddress?.street,
    selectedAddress?.locality,
    selectedAddress?.landmark,
    selectedAddress?.city,
    selectedAddress?.state
  ];
  return (
    <div className="main-container-wrapper">
      <div className="container cart-payment-page-hg cart-wrapper payment-summary-container cart-payment-page-hg">
        <div className="payment-summary-wrapper">
          <div className="payment-summary-address border-with-shadow">
            <div className="address-detail-heading">
              <div className="address-details-heading-label">Delivery Address</div>
              <NavLink to={Routes.ADDRESS_LIST}>
                <div className="address-details-heaing-action">
                  <Button buttonText="CHANGE" />
                </div>
              </NavLink>
            </div>
            <div className="address-details">
              <div className="address-detail-name">{selectedAddress?.name}</div>
              <div className="address-details-info">{addressDetails?.join(', ')}</div>
              <div className="address-detail-location">
                India - <span>{selectedAddress?.pincode}</span>
              </div>
              <div className="address-detail-mobile">
                Mobile: <span>{selectedAddress?.mobile}</span>
              </div>
            </div>
          </div>
          <div className="payment-summary-confirmation-mail border-with-shadow">
            Order confirmation email will be sent to <span>{user?.email}</span>
          </div>
          <div className="mb-view">
            <div className="cart-summary-container-payment-summary">
              <div className="cart-summary-pricedetails">
                <div className="cart-summary-pricedetails-head">
                  PRICE DETAILS ({summary?.totalQuantity || 0}{' '}
                  {summary?.totalQuantity === 1 ? 'item' : 'items'}
                </div>
                <div className="cart-summary-pricedetails-splits">
                  {renderPriceSplitUp('Total MRP', summary?.totalPrice || 0, false)}
                  {renderPriceSplitUp('Discount on MRP', summary?.totalSavedAmount || 0, true)}
                  {renderPriceSplitUp('Coupon Discount', summary?.couponDiscount || 0, true)}
                  {renderPriceSplitUp(
                    'Delivery Charges',
                    summary?.deliveryCharge?.price || 0,
                    false
                  )}

                  <div className="divider-price" />
                  <div className="price-summary">
                    <div className="heading-price bold">Amount Payable</div>
                    <div className="label-price bold">
                      {`${'\u20B9'}${summary?.totalPricePayable || 0}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-summary-payment">
            {summary?.totalPricePayable !== 0 && (
              <div className="payment-options-heading">Payment Options</div>
            )}
            <Payment addressDetails={selectedAddress} priceSummary={summary} />
          </div>
        </div>
        <div className="wb-view">
          <div className="cart-summary-container-payment-summary">
            <div className="cart-summary-pricedetails">
              <div className="cart-summary-pricedetails-head">
                PRICE DETAILS ({summary?.totalQuantity || 0}{' '}
                {summary?.totalQuantity === 1 ? 'item' : 'items'})
              </div>
              <div className="cart-summary-pricedetails-splits">
                {renderPriceSplitUp('Total MRP', summary?.totalPrice || 0, false)}
                {renderPriceSplitUp('Discount on MRP', summary?.totalSavedAmount || 0, true)}
                {renderPriceSplitUp('Coupon Discount', summary?.couponDiscount || 0, true)}
                {renderPriceSplitUp('Delivery Charges', summary?.deliveryCharge?.price || 0, false)}

                <div className="divider-price" />
                <div className="price-summary">
                  <div className="heading-price bold">Amount Payable</div>
                  <div className="label-price bold">
                    {`${'\u20B9'}${summary?.totalPricePayable || 0}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CartFooter />
      </div>
    </div>
  );
}

PaymentSummary.propTypes = {
  getAddressApi: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { address, cart, login } = state;
  return { address, cart, login };
};

const mapDispatchToProps = (dispatch) => ({
  getAddressApi: (userId) => dispatch({ type: AddressTypes.GET_ADDRESS, userId }),
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH })
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSummary);
