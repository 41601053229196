import mirrorKeyValue from 'mirror-key-value';
/* ------------- PasswordTypes ------------- */

export const PasswordTypes = mirrorKeyValue([
  'UPDATE_PASSWORD',
  'UPDATE_PASSWORD_INDICATOR',
  'UPDATE_PASSWORD_SUCCESS',
  'UPDATE_PASSWORD_FAILED',
  'RESET_GET_PASSWORD'
]);

const {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_INDICATOR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  RESET_GET_PASSWORD
} = PasswordTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  passwordloading: false,
  updatepasswordData: {},
  passworderror: false,
  updatepassworderrorMsg: '',
  isSuccessFullySubmited: false
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case UPDATE_PASSWORD:
      return { ...state, ...data };
    case UPDATE_PASSWORD_INDICATOR:
      return {
        ...state,
        passwordloading: true,
        passworderror: false
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordloading: false,
        updatepasswordData: action.response,
        isSuccessFullySubmited: true,
        passworderror: false
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        passwordloading: false,
        passworderror: true,
        updatepassworderrorMsg: action.message
      };
    case RESET_GET_PASSWORD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
