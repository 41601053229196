import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import String from '../../../constants/strings';
import './InviteFriends.scss';

export default function InviteFriend() {
  const baseurl = window.location.origin;
  const yuvipepBaseurl = 'https://yuvipep.com/';

  return (
    <div className="app-account-invite-friends ">
      <div className="if-section-title-first">Invite Friends</div>
      <div className="if-share-section">
        <FacebookShareButton
          url={baseurl}
          title={String.INVITE_FRIENDS_SHARE_MESSAGE}
          className="share-link">
          <FacebookIcon size={42} round className="share-webinar-icon" />
        </FacebookShareButton>
        <TwitterShareButton
          url={yuvipepBaseurl}
          title={String.INVITE_FRIENDS_SHARE_MESSAGE}
          className="share-link">
          <TwitterIcon size={42} round className="share-webinar-icon" />
        </TwitterShareButton>
        <WhatsappShareButton
          url={yuvipepBaseurl}
          title={String.INVITE_FRIENDS_SHARE_MESSAGE}
          className="share-link">
          <WhatsappIcon size={42} round className="share-webinar-icon" />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={baseurl}
          title={String.INVITE_FRIENDS_SHARE_MESSAGE}
          className="share-link">
          <LinkedinIcon size={42} round className="share-webinar-icon" />
        </LinkedinShareButton>
      </div>
    </div>
  );
}
