import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Key from '../../../assets/svgs/Key';
import Mail from '../../../assets/svgs/Mail';
import InputField from '../../../components/unicellular/inputField';
import Button from '../../../components/unicellular/button';
import User from '../../../assets/svgs/User';
import PlaceholderString from '../../../constants/strings/PlaceholderString';
import AuthString from '../../../constants/strings/auth/Auth';
import String from '../../../constants/strings';
import { validateRegisterForm } from '../FormValidation';
import { RegisterTypes } from '../../../reducers/auth/register/register';
import Mobile from '../../../assets/svgs/Mobile';
import Otp from '../otp';
import './Register.scss';
import RoutesPaths from '../../../constants/routes';

function Register(props) {
  // states
  const [email, setEmail] = useState('');
  const [user, setUser] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [err, setError] = useState('');

  // props
  const { onClickSignUp, callUserRegister, resetRegisterReducer } = props;
  const { register } = useSelector((state) => state);
  const {
    error,
    errorMsg,
    loading
    // registerData: { message = '' } = {},
  } = register;

  // Input handlers
  const resetError = () => {
    setError('');
  };
  const handleOtpSend = () => {
    resetRegisterReducer();
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    resetError();
    resetRegisterReducer();
  };
  const handleMobile = (e) => {
    resetError();
    resetRegisterReducer();
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    resetError();
    resetRegisterReducer();
  };
  const handleUserName = (e) => {
    resetError();
    resetRegisterReducer();
    const regexp = /^[a-zA-Z ]*$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setUser(e.target.value);
    }
  };

  const isValidate = () => {
    let errors = '';
    errors = validateRegisterForm(user, email, password, mobile);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    if (valid) {
      callUserRegister(user, email, password, mobile);
      setError('');
    }
  };
  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      const valid = isValidate();
      if (valid) {
        callUserRegister(user, email, password, mobile);
        setError('');
      }
    }
  };
  // render functions
  const renderDisclaimer = () => (
    <div className="disclaimer">
      {AuthString.DISCLAIMER}
      <span>
        <a href={RoutesPaths.TERMS_OF_USAGE} target="_blank" rel="noreferrer">
          {String.TERMS_OF_USES}
        </a>
      </span>
      {AuthString.AND}
      <span>
        <a href={RoutesPaths.PRIVACY_POLICY} target="_blank" rel="noreferrer">
          {String.PRIVACY_POLICY}
        </a>
      </span>
      .
    </div>
  );
  const renderForm = () => (
    <>
      <div className="app-auth-register-input-flield">
        <InputField
          isIcon
          icon={<User color="#888" />}
          value={user}
          maxLength={30}
          onChange={handleUserName}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.FULL_NAME}
        />
      </div>
      <div className="app-auth-register-input-flield">
        <InputField
          isIcon
          icon={<Mobile />}
          value={mobile}
          onChange={handleMobile}
          onKeyPress={handleKeypress}
          maxLength={10}
          minLength={10}
          placeholder={PlaceholderString.MOBILE_NO}
        />
      </div>
      <div className="app-auth-register-input-flield">
        <InputField
          isIcon
          icon={<Mail />}
          value={email}
          onChange={handleEmail}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.EMAIL}
        />
      </div>
      <div>
        <InputField
          isIcon
          icon={<Key />}
          value={password}
          type="password"
          onChange={handlePassword}
          onKeyPress={handleKeypress}
          placeholder={`${PlaceholderString.PASSWORD}*`}
        />
      </div>
      {err !== '' && <div className="app-auth-error">{err}</div>}
      {error && <div className="app-auth-error">{errorMsg}</div>}
    </>
  );
  const renderBottom = () => (
    <div className="app-auth-register-action-button">
      <div className="app-auth-btn-view">
        <Button
          buttonText={AuthString.CREATE_ACCOUNT}
          className="app-auth-register-btn"
          loading={loading}
          onClick={submitForm}
        />
      </div>
    </div>
  );

  const renderBottomNav = () => (
    <div className="app-bottom-text-container">
      <div className="app-auth-bottom-link">
        {AuthString.REGISTER_MODAL_BOTTOM_SWITCH_TEXT}
        <span>
          <button type="button" onClick={onClickSignUp}>
            {AuthString.SIGN_IN}
          </button>
        </span>
      </div>
    </div>
  );
  return (
    <div className="app-auth-register-container">
      {register.isRegisterSuccess ? (
        <Otp mobile={mobile} onClickResend={handleOtpSend} />
      ) : (
        <>
          {renderForm()}
          {renderDisclaimer()}
          {renderBottom()}
          {renderBottomNav()}
        </>
      )}
    </div>
  );
}
Register.propTypes = {
  onClickSignUp: PropTypes.func.isRequired,
  callUserRegister: PropTypes.func.isRequired,
  resetRegisterReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { register } = state;
  return { register };
};

const mapDispatchToProps = (dispatch) => ({
  callUserRegister: (user, email, password, mobile) =>
    dispatch({
      type: RegisterTypes.ADD_USER,
      user,
      email,
      password,
      mobile
    }),
  resetRegisterReducer: () =>
    dispatch({
      type: RegisterTypes.RESET_REGISTER_VIEW
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
