import mirrorKeyValue from 'mirror-key-value';
/* ------------- PasswordTypes ------------- */

export const ReviewTypes = mirrorKeyValue([
  'SET_REVIEW',
  'SET_REVIEW_INDICATOR',
  'SET_REVIEW_SUCCESS',
  'SET_REVIEW_FAILED',
  'RESET_SET_REVIEW'
]);

const {
  SET_REVIEW,
  SET_REVIEW_INDICATOR,
  SET_REVIEW_SUCCESS,
  SET_REVIEW_FAILED,
  RESET_SET_REVIEW
} = ReviewTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  load: false,
  reviewData: {},
  error: '',
  errorMsg: '',
  isSuccessFullySubmited: false
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case SET_REVIEW:
      return { ...state, ...data };
    case SET_REVIEW_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SET_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewData: action.response,
        isSuccessFullySubmited: true,
        error: false
      };
    case SET_REVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case RESET_SET_REVIEW:
      return INITIAL_STATE;
    default:
      return state;
  }
}
