import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import InputField from '../../../../components/unicellular/inputField';
import Button from '../../../../components/unicellular/button';
import AuthString from '../../../../constants/strings/auth/Auth';
import { validateLoginFormWithMobileOtp } from './FormValidation';
import { LoginTypes } from '../../../../reducers/auth/login/login';
import Otp from '../../../auth/otp';
import { LoginWithMobileTypes } from '../../../../reducers/auth/login/loginWithMobile';
import Verify from '../../../../constants/verify';
import './Styles.scss';
import { OtpTypes } from '../../../../reducers/auth/otp/otp';
import { forgetPasswordTypes } from '../../../../reducers/auth/login/forgetPassword';
import PlaceholderString from '../../../../constants/strings/PlaceholderString';

const Alert = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

function CODOtpVerify(props) {
  const [userId, setEmail] = useState('');
  const [err, setError] = useState('');
  const [isLoginWithMobileLoading, setisLoginWithMobileLoading] = useState(false);
  const { callUserLoginWithMobile, resetLoginWithMobile, resetOtpReducer, resetPasswordReducer } =
    props;
  const { login, loginWithMobile, forgetPassword } = useSelector((state) => state);
  const { error, errorMsg } = login;
  const { isSuccess, forgetPasswordData } = forgetPassword;
  const { message = '' } = forgetPasswordData;
  const { lmerror, lmerrorMsg, isOtpSent, lmloading } = loginWithMobile;
  const {
    login: { user: { mobile } = {} }
  } = useSelector((state) => state);
  const resetError = () => {
    setError('');
  };
  const handleOtpSend = () => {
    resetLoginWithMobile();
    resetOtpReducer();
  };
  useEffect(() => {
    setEmail(mobile);
    if (isSuccess) {
      setInterval(() => {
        resetPasswordReducer();
      }, 3000);
    }
    if (isOtpSent) {
      setisLoginWithMobileLoading(false);
    }
  }, [isSuccess, isOtpSent]);

  const isValidateOnlyMobile = () => {
    let errors = '';
    const re = /^[0-9\b]+$/;
    if (re.test(userId)) {
      errors = validateLoginFormWithMobileOtp(userId);
    } else {
      errors = Verify.VERIFY_ONLY_MOBILE_ACCEPTED;
    }
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  // Submit functionality
  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      const valid = isValidateOnlyMobile();
      if (valid) {
        callUserLoginWithMobile(userId);
        setError('');
      }
    }
  };
  const submitFormwithMobile = () => {
    const valid = isValidateOnlyMobile();
    if (valid) {
      callUserLoginWithMobile(userId);
      setError('');
    }
    setisLoginWithMobileLoading(true);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    resetError();
  };
  const renderForm = () => (
    <>
      <div className="app-cod-verify-input-flield">
        <InputField
          isIcon={false}
          value={userId}
          onChange={handleEmail}
          onKeyPress={handleKeypress}
          placeholder={PlaceholderString.LOGIN_USER_ID_INPUT_FOR_MOBILE}
        />
      </div>
      <div className="app-cod-verify-input-flield">
        {err !== '' && <div className="app-auth-error">{err}</div>}
        {error && <div className="app-auth-error">{errorMsg}</div>}
        {lmerror && <div className="app-auth-error">{lmerrorMsg}</div>}
      </div>
      <div className="app-auth-action-button">
        <div className="app-auth-btn-view">
          <Button
            buttonText={AuthString.VERIFY}
            className="app-auth-otp-btn"
            onClick={submitFormwithMobile}
            loading={lmloading && isLoginWithMobileLoading}
            spinnerColor="primary"
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="app-cod-verify-container">
      {isOtpSent ? (
        <Otp mobile={userId} isVerify onClickResend={handleOtpSend} />
      ) : (
        <>
          {renderForm()}
          <Snackbar
            open={isSuccess}
            autoHideDuration={50}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Alert severity="success" sx={{ width: '100%' }}>
              <div className="alet-message-success">{message}</div>
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}
CODOtpVerify.propTypes = {
  resetLoginWithMobile: PropTypes.func.isRequired,
  callUserLoginWithMobile: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetPasswordReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { login, loginWithMobile, forgetPassword } = state;
  return { login, loginWithMobile, forgetPassword };
};

const mapDispatchToProps = (dispatch) => ({
  callUserLogin: (userId, password) =>
    dispatch({
      type: LoginTypes.GET_LOGIN_USER,
      userId,
      password
    }),
  callUserLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.GET_VERIFY_USER_WITH_MOBILE,
      mobile
    }),
  resetLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW,
      mobile
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetPasswordReducer: () =>
    dispatch({
      type: forgetPasswordTypes.RESET_FORGET_PASSWORD
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(CODOtpVerify);
