import { all, fork } from 'redux-saga/effects';
import authSaga from './auth/AuthSaga';
import CategorySaga from './category/CategorySaga';
import LoginSaga from './auth/LoginSaga';
import CartSaga from './cart/cartSaga';
import AddressSaga from './account/addressSaga';
import ProductSaga from './product/ProductSaga';
import PaymentSaga from './payment/PaymentSaga';
import infoSaga from './info/InfoSaga';
import changepasswordSaga from './changepassword/changepassword';
import orderSaga from './account/orderSaga';
import homeSaga from './home/homeSaga';
import faqSaga from './faq/FaqSaga';
import WishlistSaga from './wishlist/WishlistSaga';
import CouponSaga from './payment/CouponSaga';
import ContactUsSaga from './contactUs/contactUsSaga';
import bulkOrderSaga from './cart/bulkOrderSaga';

export default function* rootSaga() {
  try {
    yield all([
      fork(authSaga),
      fork(LoginSaga),
      fork(CartSaga),
      fork(CategorySaga),
      fork(AddressSaga),
      fork(ProductSaga),
      fork(PaymentSaga),
      fork(infoSaga),
      fork(changepasswordSaga),
      fork(orderSaga),
      fork(homeSaga),
      fork(WishlistSaga),
      fork(faqSaga),
      fork(CouponSaga),
      fork(ContactUsSaga),
      fork(bulkOrderSaga)
    ]);
  } catch (ex) {
    console.warn('Error while Loading Saga: rootSaga', ex);
  }
}
