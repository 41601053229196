import React from 'react';

function CircleChecked() {
  return (
    <div>
      <svg
        fill="#00a36c"
        viewBox="0 0 64 64"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2
        }}
        stroke="#00a36c">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <g transform="matrix(1,0,0,1,-640,-320)">
            <rect id="Icons" x="0" y="0" width="1280" height="800" style={{ fill: 'none' }} />

            <g id="Icons1">
              <g id="Strike"> </g>
              <g id="H1"> </g>

              <g id="H2"> </g>

              <g id="H3"> </g>

              <g id="list-ul"> </g>

              <g id="hamburger-1"> </g>

              <g id="hamburger-2"> </g>

              <g id="list-ol"> </g>

              <g id="list-task"> </g>

              <g id="trash"> </g>

              <g id="vertical-menu"> </g>

              <g id="horizontal-menu"> </g>

              <g id="sidebar-2"> </g>

              <g id="Pen"> </g>

              <g id="Pen1"> </g>

              <g id="clock"> </g>

              <g id="external-link"> </g>

              <g id="hr"> </g>

              <g id="info"> </g>

              <g id="warning"> </g>

              <g id="plus-circle"> </g>

              <g id="minus-circle"> </g>

              <g id="vue"> </g>

              <g id="cog"> </g>

              <g id="logo"> </g>

              <g id="radio-check" transform="matrix(1,0,0,0.99911,0,0.24916)">
                <path
                  id="radio-check1"
                  d="M672.021,328C685.279,328 696.043,338.764 696.043,352.021C696.043,365.279 685.279,376.043 672.021,376.043C658.764,376.043 648,365.279 648,352.021C648,338.764 658.764,328 672.021,328ZM687.751,342.828L665.123,365.456L656,356.332L658.767,353.566L665.062,359.861L684.922,340C685.865,340.943 686.808,341.886 687.751,342.828Z"
                />
              </g>

              <g id="eye-slash"> </g>

              <g id="eye"> </g>

              <g id="toggle-off"> </g>

              <g id="shredder"> </g>

              <g id="spinner--loading--dots-"> </g>

              <g id="react"> </g>

              <g id="check-selected"> </g>

              <g id="turn-off"> </g>

              <g id="code-block"> </g>

              <g id="user"> </g>

              <g id="coffee-bean"> </g>

              <g transform="matrix(0.638317,0.368532,-0.368532,0.638317,785.021,-208.975)">
                <g id="coffee-beans">
                  <g id="coffee-bean1"> </g>
                </g>
              </g>

              <g id="coffee-bean-filled"> </g>

              <g transform="matrix(0.638317,0.368532,-0.368532,0.638317,913.062,-208.975)">
                <g id="coffee-beans-filled">
                  <g id="coffee-bean2"> </g>
                </g>
              </g>

              <g id="clipboard"> </g>

              <g transform="matrix(1,0,0,1,128.011,1.35415)">
                <g id="clipboard-paste"> </g>
              </g>

              <g id="clipboard-copy"> </g>

              <g id="Layer1"> </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default CircleChecked;
