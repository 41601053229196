import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Close from '../../../../assets/svgs/Close';
import './Styles.scss';
import '../refundModal/Styles.scss';

const renderPriceSplitUp = (heading, price, isNegative = false) => (
  <div className="price-summary">
    <div className="heading-price">{heading}</div>
    <div className={`label-price ${isNegative ? 'discount' : ''}`}>
      {`${isNegative ? '-' : ''}${price}`}
    </div>
  </div>
);

function AmountBreakDownModal(props) {
  const { show, onClose, priceSummary } = props;
  const handleOnHideModal = () => {
    onClose();
  };
  const renderLayout = () => (
    <div className="abd-summary-container">
      <div className="abd-summary-pricedetails">
        <div className="abd-summary-pricedetails-splits">
          {renderPriceSplitUp('Total MRP', priceSummary?.grossAmount || '₹0', false)}
          {renderPriceSplitUp('Discount on MRP', priceSummary?.savedAmount || '₹0', true)}
          {renderPriceSplitUp('Coupon Discount', priceSummary?.couponInfo?.discount || '₹0', true)}
          {renderPriceSplitUp(
            'Delivery Charges',
            priceSummary?.deliveryCharge?.price || '₹0',
            false
          )}

          <div className="divider-price" />
          <div className="price-summary">
            <div className="heading-price bold">Amount Paid</div>
            <div className="label-price bold">{`${priceSummary?.price || '₹0'}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      size="md"
      show={show}
      onHide={handleOnHideModal}
      centered
      contentClassName="refund-model">
      <Modal.Body className="refund-modal-body">
        <div className="refund-modal-header-container">
          <div className="modal-title-text">PRICE DETAILS</div>

          <button
            aria-label="close"
            type="button"
            onClick={handleOnHideModal}
            className="refund-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>
        <div>{renderLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}
AmountBreakDownModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  priceSummary: PropTypes.object.isRequired
};
export default AmountBreakDownModal;
