import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Filter from '../../../components/multicellular/products/filter';
import Breadcrum from '../../../components/multicellular/navigation/breadcrum';
import Routes from '../../../constants/routes';
import Images from '../../../themes/Image';
import KitCard from '../../../components/multicellular/products/kitCardV2';
import Button from '../../../components/unicellular/button';
import ScrollTopIcon from '../../../assets/svgs/ScrollTopIcon';
import STORAGE_KEYS from '../../../constants/storageKey';
import Notification from './Notification';
import SortBy from './SortBy';
import { ProductTypes } from '../../../reducers/product/productList';
import { FilterTypes } from '../../../reducers/filter/filter';
import ProductString from '../../../constants/strings/product';
import './ProductExplorer.scss';
import { LoginTypes } from '../../../reducers/auth/login/login';
import { CartTypes } from '../../../reducers/cart/cart';
import createSlug from '../../../utils/conversions/createSlug';

function Detail(props) {
  const [showScrollButton, setshowScrollButton] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (cart.isSuccess) {
      setIsAdded(true);
      dispatch({ type: CartTypes.RESET_CART });
    }
  }, [cart.isSuccess]);
  useEffect(() => {
    let timeout;
    if (isAdded) {
      timeout = setTimeout(() => {
        setIsAdded(false);
        setActiveId('');
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isAdded]);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const urlQueryString = searchParams.get('q');
  const queryString =
    urlQueryString && urlQueryString !== null
      ? decodeURIComponent(urlQueryString?.replace(/%/g, '%25'))
      : null;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);

  const { id } = params;
  const {
    callProductApi,
    callClearFilters,
    resetProducts,
    resetLoginProduct,
    callAddToCartApi,
    resetInitialFilter
  } = props;
  const { productList, filter, login } = useSelector((state) => state);
  const { isLoginToProduct } = login;
  const {
    filterPrice = [],
    filterDiscount = [],
    filterColorIn = [],
    isCallApi,
    sortMap = {}
  } = filter;
  const {
    loading,
    productData,
    isMoreProducts,
    productListArr,
    page,
    isSuccess,
    previousCategoryId,
    isSearched
  } = productList;
  const {
    content: { categoryName = null, breadCrumbs = [] } = {},
    totalRecords = '',
    filters: { priceFilters = [], discountFilters = [] } = {}
  } = productData;
  const [activeProductId, setActiveProductId] = useState([]);
  const navigate = useNavigate();

  const handleCart = (productid) => {
    sessionStorage.removeItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED);
    const arr = activeProductId;
    setActiveId(productid);
    arr.push(productid);
    setActiveProductId([...arr]);
    callAddToCartApi(userId, productid, 1, null);
  };
  const handleRedirection = (product) => {
    navigate(
      `${product?.isBook ? Routes.BOOKS : Routes.PRODUCT_DETAIL}/${createSlug(product?.name)}/${product.mongoId}`
    );
  };
  useEffect(() => {
    setActiveProductId([]);
    return () => {
      resetProducts();
      resetInitialFilter();
    };
  }, []);
  const getProductsWithCategory = (withFilter, goToNextPage) => {
    const filterMap = {
      ...(id && { category: id }),
      ...(queryString && queryString !== '' && { searchParams: queryString }),
      ...(filterPrice.length !== 0 && { priceFilters: filterPrice }),
      ...(filterDiscount.length !== 0 && { discountFilters: filterDiscount }),
      ...(filterColorIn.length !== 0 && { colorIn: filterColorIn })
    };
    let currentPage = page;
    if (isSearched) {
      currentPage = 1;
    } else if (goToNextPage) {
      currentPage = page + 1;
    }
    callProductApi(filterMap, sortMap, withFilter, currentPage, userId);
  };

  const fetchProducts = () => {
    if ((!isSuccess && !isCallApi) || isSearched) {
      // Onload Api call
      const filterMap = {
        ...(id && { category: id }),
        ...(queryString && queryString !== '' && { searchParams: queryString })
      };
      if (
        (previousCategoryId !== id && (queryString === null || queryString === undefined)) ||
        isSearched
      ) {
        callClearFilters();
      } else {
        Object.assign(filterMap, {
          ...(filterPrice.length !== 0 && { priceFilters: filterPrice }),
          ...(filterDiscount.length !== 0 && {
            discountFilters: filterDiscount
          }),
          ...(filterColorIn.length !== 0 && { colorIn: filterColorIn })
        });
      }
      callProductApi(filterMap, sortMap, isCallApi, 1, userId);
    } else if (isCallApi) {
      // Goto next page
      getProductsWithCategory(isCallApi, false);
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setshowScrollButton(true);
      } else {
        setshowScrollButton(false);
      }
    });
  };

  const renderNoProducts = () => (
    <div className="app-products-no-data">
      <img src={Images.NotFound} alt="" srcSet="" />
      <div className="title">{ProductString.NO_PRODUCTS}</div>
    </div>
  );

  const breadCrumbsLinks = [
    { label: 'Home', link: '/' },
    ...breadCrumbs.map((breadCrumb) => ({
      label: breadCrumb.label,
      link: `${Routes.PRODUCT_EXPLORER}/${breadCrumb.id}`
    })),
    { label: categoryName || ProductString.AVAILABLE_PRODUCTS, link: '#' }
  ];
  useEffect(() => {
    resetInitialFilter();
  }, [queryString]);
  useEffect(() => {
    if (isLoginToProduct) {
      resetProducts();
      fetchProducts();
      resetLoginProduct();
    } else {
      fetchProducts();
    }
  }, [
    isLoginToProduct,
    params,
    id,
    filterPrice,
    filterDiscount,
    filterColorIn,
    isCallApi,
    sortMap,
    previousCategoryId,
    queryString
  ]);
  return (
    <div className="app-product-detail-container container">
      <div className="container">
        {loading ? null : <Breadcrum links={breadCrumbsLinks} />}
        <div className="row">
          {(priceFilters.length !== 0 || discountFilters.length !== 0) && (
            <div className="col-sm-12 col-lg-3 filter-section-container">
              <Filter priceFilters={priceFilters} discountFilters={discountFilters} />
            </div>
          )}
          <div
            className={`col-sm-12 ${priceFilters.length !== 0 || discountFilters.length !== 0 ? 'col-lg-9' : 'col-lg-12'}`}>
            <div className="app-pdw container-mt">
              {productListArr.length > 0 && totalRecords !== 0 && (
                <SortBy>
                  <div className="app-product-name-wrapper">
                    <div className="title">
                      {categoryName ? `"${categoryName}"` : ProductString.AVAILABLE_PRODUCTS}
                    </div>
                    <div className="total-product">
                      {`(${ProductString.TOTAL_PRODUCTS(totalRecords)})`}
                    </div>
                  </div>
                </SortBy>
              )}
              <div className="row">
                {!loading && productListArr.length > 0
                  ? productListArr.map(({ _source: item }, index) => (
                      <div
                        className={`${priceFilters.length !== 0 || discountFilters.length !== 0 ? 'col-sm-4' : 'col-sm-3'}`}
                        key={`productList-${index.toString()}`}>
                        <KitCard
                          item={item}
                          rating={item.avgRating}
                          title={item.name}
                          description={item.categoryName}
                          price={item.price}
                          isFreeDelivery={item.isFreeDelivery}
                          discount={item.discount}
                          discountPrice={item.discount}
                          image={item.images ? item.images[0] : ''}
                          calculatedPrices={item.calculatedPrices}
                          productId={item.mongoId}
                          wishlist={item.wishlist}
                          isWishlisted={item.isWishlisted}
                          onClickCart={() => handleCart(item.mongoId)}
                          onClickBuyNow={() => handleRedirection(item)}
                          // isAddedToCart={
                          //     activeProductId.includes(item.mongoId)
                          //     || item.isAddedToCart
                          //   }
                          isAddedToCart={isAdded && item.mongoId === activeId}
                          loadingCart={cart?.loading && item.mongoId === activeId}
                        />
                      </div>
                    ))
                  : !loading && productListArr.length === 0 && renderNoProducts()}
                {loading &&
                  [...Array(6)].map((index) => (
                    <div key={`skelton-kitcard-${index}`} className="col-sm-3">
                      <KitCard loading={loading} />
                    </div>
                  ))}
              </div>
              {isMoreProducts && (
                <div className="app-view-more-btn-wrapper">
                  <Button
                    buttonText={ProductString.VIEW_MORE(categoryName)}
                    className="app-view-more-btn"
                    onClick={() => getProductsWithCategory(false, true)}
                  />
                </div>
              )}
              {!loading && totalRecords !== 0 && <Notification />}
              {showScrollButton && (
                <div
                  role="button"
                  aria-label="scroll-btn"
                  tabIndex={-1}
                  className="app-scroll-top-btn"
                  onClick={() => scrollToTop()}
                  onKeyPress={() => scrollToTop()}>
                  <ScrollTopIcon />
                </div>
              )}
              {sessionStorage.getItem(STORAGE_KEYS.IS_SAVED_FOR_LATER_CLICKED) ===
              'clicked' ? null : (
                <Snackbar
                  open={isAdded}
                  autoHideDuration={500}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}>
                  <Alert severity="success" sx={{ width: '100%' }}>
                    <div className="alet-message-success">
                      Product has been successfully added to cart!
                    </div>
                  </Alert>
                </Snackbar>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Detail.propTypes = {
  callProductApi: PropTypes.func.isRequired,
  callClearFilters: PropTypes.func.isRequired,
  resetProducts: PropTypes.func.isRequired,
  resetLoginProduct: PropTypes.func.isRequired,
  callAddToCartApi: PropTypes.func.isRequired,
  resetInitialFilter: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { productList, filter, login } = state;
  return { productList, filter, login };
};

const mapDispatchToProps = (dispatch) => ({
  callProductApi: (filterMap, sortMap, isFiltered, page, userId) =>
    dispatch({
      type: ProductTypes.GET_PRODUCT,
      filterMap,
      sortMap,
      isFiltered,
      page,
      userId
    }),
  callClearFilters: () =>
    dispatch({
      type: FilterTypes.CLEAR_FILTERS
    }),
  resetProducts: () =>
    dispatch({
      type: ProductTypes.RESET_PRODUCTS
    }),
  resetInitialFilter: () =>
    dispatch({
      type: FilterTypes.RESET_INITIAL_FILTER
    }),
  resetLoginProduct: () =>
    dispatch({
      type: LoginTypes.RESET_USER_LOGIN_FROM_PRODUCT
    }),
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
