import React from 'react';

function BlackEmail() {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 15C2.5875 15 2.2345 14.8533 1.941 14.5597C1.647 14.2657 1.5 13.9125 1.5 13.5V4.5C1.5 4.0875 1.647 3.7345 1.941 3.441C2.2345 3.147 2.5875 3 3 3H15C15.4125 3 15.7657 3.147 16.0597 3.441C16.3533 3.7345 16.5 4.0875 16.5 4.5V13.5C16.5 13.9125 16.3533 14.2657 16.0597 14.5597C15.7657 14.8533 15.4125 15 15 15H3ZM9 9.61875C9.0625 9.61875 9.128 9.60925 9.1965 9.59025C9.2655 9.57175 9.33125 9.54375 9.39375 9.50625L14.7 6.1875C14.8 6.125 14.875 6.047 14.925 5.9535C14.975 5.8595 15 5.75625 15 5.64375C15 5.39375 14.8938 5.20625 14.6813 5.08125C14.4688 4.95625 14.25 4.9625 14.025 5.1L9 8.25L3.975 5.1C3.75 4.9625 3.53125 4.95925 3.31875 5.09025C3.10625 5.22175 3 5.40625 3 5.64375C3 5.76875 3.025 5.878 3.075 5.9715C3.125 6.0655 3.2 6.1375 3.3 6.1875L8.60625 9.50625C8.66875 9.54375 8.7345 9.57175 8.8035 9.59025C8.872 9.60925 8.9375 9.61875 9 9.61875Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default BlackEmail;
