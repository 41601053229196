import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { FilterTypes } from '../../../../reducers/filter/filter';
import FilterString from '../../../../constants/strings/product/filter';
import './Filter.scss';

function Filter(props) {
  const checkBoxSize = { '& .MuiSvgIcon-root': { fontSize: 18 } };
  const { priceFilters, discountFilters, setPriceFilter, setDiscountFilter } = props;
  const { filter } = useSelector((state) => state);
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const { filterPrice = [], filterDiscount = [], doClearFilter = false } = filter;

  const handleCheckBoxValues = (e, index, checkBoxKey) => {
    checkBoxValues[index] = {
      ...checkBoxValues[index],
      [checkBoxKey]: e.target.checked
    };
    setCheckBoxValues(checkBoxValues);
  };

  const handleOnChangeFilter = (e, value, selectedFilters, setFilter) => {
    if (e.target.checked) {
      const selectedFilter = selectedFilters;
      selectedFilter.push(value);
      setFilter(selectedFilter);
    } else {
      const filteredData = selectedFilters.filter((item) => item.name !== value.name);
      setFilter(filteredData);
    }
  };

  const clearFilters = () => {
    if (filterPrice?.length > 0 || filterDiscount?.length > 0) {
      setPriceFilter([]);
      setDiscountFilter([]);
      setCheckBoxValues([]);
    }
  };

  useEffect(() => {
    if (doClearFilter) {
      setCheckBoxValues([]);
    }
  }, [doClearFilter]);

  const renderSelectFilter = (title, filters, checkBoxKey, selectedFilters, setFilter) => (
    <FormGroup>
      {filters.length > 0 && (
        <div className="app-filter-control">
          <div className="app-filter-control-title">{title}</div>
          {filters &&
            filters.map((value, index) => (
              <div key={`filter-${index.toString()}`}>
                <FormControlLabel
                  checked={!!checkBoxValues[index]?.[checkBoxKey]}
                  control={<Checkbox sx={checkBoxSize} />}
                  value={value.name}
                  onChange={(e) => {
                    handleCheckBoxValues(e, index, checkBoxKey);
                    handleOnChangeFilter(e, value, selectedFilters, setFilter);
                  }}
                  label={<Typography className="app-filter-control-label">{value.name}</Typography>}
                />
              </div>
            ))}
        </div>
      )}
    </FormGroup>
  );

  return (
    <div className="app-filter-container">
      <div className="app-filter-head">
        <div className="app-filter-title">{FilterString.FILTER}</div>
        <div
          role="button"
          tabIndex={-1}
          className={`app-filter-clearall ${!(filterPrice?.length > 0 || filterDiscount?.length > 0) ? 'cursor-na' : ''}`}
          onClick={() => clearFilters()}
          onKeyPress={() => clearFilters()}>
          {FilterString.CLEAR_ALL}
        </div>
      </div>
      <div className="app-filter-card">
        {renderSelectFilter(
          FilterString.PRICE,
          priceFilters,
          FilterString.PRICE_FILTER,
          filterPrice,
          setPriceFilter
        )}
        {renderSelectFilter(
          FilterString.DISCOUNT,
          discountFilters,
          FilterString.DISCOUNT_FILTER,
          filterDiscount,
          setDiscountFilter
        )}
      </div>
    </div>
  );
}
Filter.propTypes = {
  priceFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      priceFrom: PropTypes.number.isRequired,
      priceTo: PropTypes.number.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  discountFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      discountFrom: PropTypes.number.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  setPriceFilter: PropTypes.func.isRequired,
  setDiscountFilter: PropTypes.func.isRequired
};
Filter.defaultProps = {};
const mapStateToProps = (state) => {
  const { filter } = state;
  return { filter };
};

const mapDispatchToProps = (dispatch) => ({
  setPriceFilter: (priceFilters) => dispatch({ type: FilterTypes.SET_PRICE_FILTER, priceFilters }),
  setDiscountFilter: (discountFilters) =>
    dispatch({
      type: FilterTypes.SET_DISCOUNT_FILTER,
      discountFilters
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
