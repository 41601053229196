import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { useSelector } from 'react-redux';

function Community() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { promotion } = {} } = productData;
  return promotion?.whatsAppInfo && promotion?.whatsAppInfo?.isActive ? (
    <div className="mt-5 bg-gradient-velvet d-flex flex-column align-items-center gap-4 rounded-4 px-5 py-5 text-white flex-lg-row community-promotion">
      <h4 className="cp-text fs-3">{promotion?.whatsAppInfo?.title}</h4>
      <div className="w-full cp-wp">
        <a
          href={promotion?.whatsAppInfo?.action?.url}
          target="_blank"
          className="d-flex align-items-center justify-content-center gap-3 p-3 cp-button fs-3"
          rel="noreferrer">
          <BsWhatsapp className="shrink-0 text-2xl" /> {promotion?.whatsAppInfo?.action?.text}
        </a>
      </div>
    </div>
  ) : null;
}

export default Community;
