import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ProductImage from './ProductImage';

function Details() {
  const { information } = useSelector((state) => state);
  const [selectedImage, setSelectedImage] = useState(null);
  const { productData } = information;
  const { product: { images = [] } = {} } = productData;
  function handleImageChange(image) {
    setSelectedImage(image);
  }
  return (
    <div>
      <ProductImage
        images={images}
        selectedImage={selectedImage || images[0]}
        handleImageChange={(image) => handleImageChange(image)}
      />
    </div>
  );
}

export default Details;
