import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './InfoBanner.scss';

export default function InfoBanner(props) {
  const { subBannerData, isMobile } = props;
  const bannerData = subBannerData;
  return bannerData?.action && Object.keys(bannerData?.action).length > 0 ? (
    <NavLink to={`${bannerData.action?.navigateTo}/${bannerData.action?.id}`}>
      <div className={isMobile ? 'app-info-banner-mb-wrapper' : 'app-info-banner-wrapper'}>
        <div
          className={
            isMobile ? 'banner-container-mb container-fluid' : 'banner-container container-fluid'
          }>
          <div
            className={isMobile ? 'banner-img-wrapper-mb' : 'banner-img-wrapper'}
            style={{
              backgroundImage: `url(${isMobile ? bannerData.images?.mobile : bannerData.images?.web})`
            }}
          />
        </div>
      </div>
    </NavLink>
  ) : (
    <div className={isMobile ? 'app-info-banner-mb-wrapper' : 'app-info-banner-wrapper'}>
      <div
        className={
          isMobile ? 'banner-container-mb container-fluid' : 'banner-container container-fluid'
        }>
        <div
          className={isMobile ? 'banner-img-wrapper-mb' : 'banner-img-wrapper'}
          style={{
            backgroundImage: `url(${isMobile ? bannerData.images?.mobile : bannerData.images?.web})`
          }}
        />
      </div>
    </div>
  );
}
InfoBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  subBannerData: PropTypes.object,
  isMobile: PropTypes.bool
};
InfoBanner.defaultProps = {
  subBannerData: {},
  isMobile: false
};
