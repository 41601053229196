/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import RoutesPaths from '../../../constants/routes';
import OrderCard from '../../../components/multicellular/order/orderCard';
import SortBy from '../../../components/multicellular/order/sort/SortBy';
import { OrderTypes } from '../../../reducers/order/order';
import { statusOptions, daysOptions } from '../../../config/order/order';
import STORAGE_KEYS from '../../../constants/storageKey';
import './Order.scss';
import { CartTypes } from '../../../reducers/cart/cart';

function Order(props) {
  const { getOrderList, getUserCart } = props;
  const { order } = useSelector((state) => state);
  const [sortDays, setSortDays] = useState({
    type: daysOptions[0].key,
    days: daysOptions[0].value
  });
  const [sortStatus, setSortStatus] = useState({
    status: statusOptions[0].value
  });
  const { orderData: { orders = [] } = {}, load } = order;
  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    getUserCart();
    getOrderList(userId, sortDays, sortStatus.status);
  }, [sortDays, sortStatus]);

  const renderNoData = () => (
    <div className="app-address-no-data">
      <div className="title">No Data Found</div>
      <NavLink to={RoutesPaths.HOME}>
        <button type="button" className="btn-add-address-link">
          + Shop Now
        </button>
      </NavLink>
    </div>
  );
  return (
    <div className="app-order-list-container">
      <div className="app-order-header">
        <div className="app-order-section-title">{`${orders.length} Orders`}</div>
        <div className="app-order-list-sort-container ">
          <SortBy options={daysOptions} setSort={setSortDays} />
          <SortBy options={statusOptions} setSort={setSortStatus} />
        </div>
      </div>
      {load ? (
        <OrderCard loading={load} />
      ) : (
        (orders.length > 0 &&
          orders.map((item, index) => (
            <div key={item?.product?._id}>
              <OrderCard
                key={`OrderCard-${index.toString()}`}
                products={item?.productSummary?.products}
                name={item?.product?.name}
                price={item.price}
                address={item?.address}
                orderStatus={item.orderStatus}
                invoiceId={item.invoiceId}
                quantity={item.quantity}
                createdAt={item.createdAt}
                returnDays={item?.returnDays}
                paymentMethod={item?.paymentMethod}
                sortDays={sortDays}
                statusOfList={sortStatus.status}
              />
            </div>
          ))) ||
        renderNoData()
      )}
      <div />
    </div>
  );
}
Order.propTypes = {
  getOrderList: PropTypes.func.isRequired,
  getUserCart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { order } = state;
  return { order };
};

const mapDispatchToProps = (dispatch) => ({
  getOrderList: (userId, sortDays, status) =>
    dispatch({
      type: OrderTypes.GET_ORDER_LIST,
      userId,
      sortDays,
      status
    }),
  getUserCart: () => {
    dispatch({ type: CartTypes.GET_USER_CART_FETCH });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
