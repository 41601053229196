import React from 'react';
import Detail from './Detail';
import './ProductExplorer.scss';

export default function ProductExplorer() {
  return (
    <div className="main-container-wrapper">
      <Detail />
    </div>
  );
}
