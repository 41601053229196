/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { Cookies } from 'react-cookie-consent';
import reducer from './reducers';
import watcherSaga from './sagas';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Cookie } from './assets/svgs/GeneralIcons';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create a redux store with our reducer above and middleware
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

// Google Analytics initialization function
const initGoogleAnalytics = () => {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }
};

// Custom ConsentBanner component
function ConsentBanner() {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const handleAccept = () => {
    Cookies.set('CookieConsent', 'true', { expires: 365 });
    setIsBannerVisible(false);
  };

  const handleDecline = () => {
    Cookies.set('CookieConsent', 'false', { expires: 365 });
    setIsBannerVisible(false);
  };

  useEffect(() => {
    if (Cookies.get('CookieConsent') === 'false' || Cookies.get('CookieConsent') === undefined) {
      Cookies.set('CookieConsent', 'false', { expires: 365 });
    }
    initGoogleAnalytics();
  }, []);

  useEffect(() => {
    const consent = Cookies.get('CookieConsent');
    if (consent === 'true') {
      initGoogleAnalytics();
      setIsBannerVisible(false);
    } else {
      setIsBannerVisible(true);
    }
  }, []);

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="message">
        <Cookie />
        <span>
          YuviPep uses cookies to personalize your experience. By accepting, you can enhance your
          browsing experience.
        </span>
        <a href="/privacy-policy">Read more</a>
      </div>
      <div className="buttons">
        <button type="button" className="decline" onClick={handleDecline}>
          Decline
        </button>
        <button type="button" className="accept" onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
}

// run the saga
sagaMiddleware.run(watcherSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ConsentBanner />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
