import React from 'react';
import { useSelector } from 'react-redux';

function About() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { bookDetails, bookDetails: { specifications = [] } = {}, sku } = {} } =
    productData;

  return (
    <div className="mt-5 row">
      <div className="col-12 col-lg-12">
        <div>
          <h3 className="text-secondary-300 fs-2 fw-semibold">About the Book</h3>
        </div>
        <div className="fw-light text-justify pr-4 about-book-pre-line">{bookDetails?.about}</div>
      </div>
      <div className="col-12 col-md-6 col-lg-5 mt-4">
        <div className="rounded-4 book-details-table">
          <div className="px-3 py-4 fs-3 fw-semibold">Book Details</div>
          {specifications.map((item, index) => {
            return (
              <div
                className={`${index % 2 === 0 ? 'bg-neutral-300' : ''} information px-3 py-3`}
                key={item?.key}>
                <span>{item?.key}:</span>
                <span>{item?.value}</span>
              </div>
            );
          })}
          <div
            className={`${specifications.length % 2 === 0 ? 'bg-neutral-300' : ''} information px-3 py-3`}>
            <span>SKU:</span>
            <span>{sku}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
