import React from 'react';

export default function Bus() {
  return (
    <div>
      <svg
        width="39"
        height="22"
        viewBox="0 0 39 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.4452 21.0946C32.7866 21.095 32.1426 20.9002 31.5948 20.5346C31.0469 20.1691 30.6198 19.6492 30.3674 19.0409C30.1151 18.4325 30.0488 17.763 30.177 17.117C30.3052 16.471 30.6221 15.8775 31.0876 15.4116C31.5532 14.9457 32.1464 14.6284 32.7923 14.4997C33.4383 14.371 34.1078 14.4368 34.7164 14.6888C35.3249 14.9407 35.845 15.3674 36.211 15.915C36.577 16.4626 36.7723 17.1064 36.7723 17.7651C36.7719 18.6475 36.4213 19.4938 35.7975 20.118C35.1737 20.7423 34.3277 21.0935 33.4452 21.0946ZM33.4452 15.6501C33.0268 15.6496 32.6177 15.7733 32.2696 16.0054C31.9214 16.2375 31.65 16.5676 31.4895 16.954C31.3291 17.3404 31.2869 17.7658 31.3682 18.1762C31.4495 18.5866 31.6508 18.9637 31.9464 19.2597C32.2421 19.5557 32.619 19.7574 33.0293 19.8392C33.4396 19.921 33.865 19.8793 34.2516 19.7192C34.6382 19.5592 34.9686 19.2882 35.2011 18.9403C35.4336 18.5925 35.5577 18.1835 35.5577 17.7651C35.5575 17.2046 35.335 16.6672 34.9389 16.2707C34.5429 15.8741 34.0057 15.651 33.4452 15.6501Z"
          fill="black"
        />
        <path
          d="M15.8992 21.0946C15.2405 21.0952 14.5965 20.9005 14.0485 20.535C13.5005 20.1696 13.0733 19.6498 12.8208 19.0414C12.5683 18.4331 12.5019 17.7636 12.63 17.1175C12.7581 16.4714 13.0749 15.8778 13.5405 15.4119C14.006 14.9459 14.5992 14.6285 15.2452 14.4998C15.8911 14.371 16.5607 14.4368 17.1693 14.6887C17.7779 14.9406 18.2981 15.3673 18.6641 15.9149C19.0301 16.4625 19.2254 17.1064 19.2254 17.7651C19.2248 18.6473 18.8742 19.4933 18.2507 20.1175C17.6271 20.7417 16.7814 21.0931 15.8992 21.0946ZM15.8992 15.6501C15.4807 15.6495 15.0715 15.773 14.7233 16.005C14.375 16.237 14.1035 16.5671 13.9429 16.9535C13.7823 17.3399 13.74 17.7652 13.8212 18.1757C13.9024 18.5861 14.1036 18.9633 14.3993 19.2594C14.6949 19.5555 15.0718 19.7573 15.4821 19.8391C15.8925 19.921 16.3179 19.8793 16.7045 19.7193C17.0912 19.5593 17.4217 19.2883 17.6542 18.9404C17.8867 18.5925 18.0109 18.1835 18.0109 17.7651C18.0104 17.2048 17.7879 16.6676 17.3921 16.2712C16.9962 15.8747 16.4594 15.6514 15.8992 15.6501Z"
          fill="black"
        />
        <path
          d="M37.8266 18.3728H36.1659C36.0048 18.3728 35.8504 18.3088 35.7365 18.1949C35.6226 18.081 35.5586 17.9266 35.5586 17.7655C35.5586 17.6044 35.6226 17.45 35.7365 17.3361C35.8504 17.2222 36.0048 17.1582 36.1659 17.1582H37.6549V13.938C37.6545 13.5026 37.5431 13.0746 37.3311 12.6943L34.0437 6.80047C34.0156 6.75017 33.9746 6.70827 33.9249 6.67909C33.8752 6.64992 33.8187 6.63452 33.7611 6.63448H29.281V17.1607H30.7271C30.8882 17.1607 31.0426 17.2246 31.1565 17.3385C31.2704 17.4524 31.3344 17.6069 31.3344 17.7679C31.3344 17.929 31.2704 18.0835 31.1565 18.1973C31.0426 18.3112 30.8882 18.3752 30.7271 18.3752H28.6737C28.5126 18.3752 28.3582 18.3112 28.2443 18.1973C28.1304 18.0835 28.0664 17.929 28.0664 17.7679V6.0272C28.0664 5.86614 28.1304 5.71168 28.2443 5.59779C28.3582 5.4839 28.5126 5.41992 28.6737 5.41992H33.7611C34.035 5.41979 34.3041 5.49282 34.5404 5.63146C34.7767 5.77011 34.9716 5.96934 35.1052 6.20857L38.3918 12.1032C38.7042 12.6643 38.8684 13.2958 38.8687 13.938V17.3307C38.8683 17.6069 38.7583 17.8717 38.563 18.0671C38.3677 18.2624 38.1028 18.3724 37.8266 18.3728Z"
          fill="black"
        />
        <path
          d="M13.176 18.3732H7.35337C7.19231 18.3732 7.03785 18.3092 6.92396 18.1954C6.81008 18.0815 6.74609 17.927 6.74609 17.7659V13.0956C6.74609 12.9345 6.81008 12.78 6.92396 12.6661C7.03785 12.5523 7.19231 12.4883 7.35337 12.4883C7.51443 12.4883 7.6689 12.5523 7.78278 12.6661C7.89667 12.78 7.96065 12.9345 7.96065 13.0956V17.1587H13.176C13.337 17.1587 13.4915 17.2226 13.6054 17.3365C13.7193 17.4504 13.7832 17.6049 13.7832 17.7659C13.7832 17.927 13.7193 18.0815 13.6054 18.1954C13.4915 18.3092 13.337 18.3732 13.176 18.3732Z"
          fill="black"
        />
        <path
          d="M7.35337 10.9407C7.19231 10.9407 7.03785 10.8767 6.92396 10.7628C6.81008 10.6489 6.74609 10.4945 6.74609 10.3334V6.25572C6.74609 6.09466 6.81008 5.94019 6.92396 5.8263C7.03785 5.71242 7.19231 5.64844 7.35337 5.64844C7.51443 5.64844 7.6689 5.71242 7.78278 5.8263C7.89667 5.94019 7.96065 6.09466 7.96065 6.25572V10.3358C7.96001 10.4965 7.89575 10.6503 7.78193 10.7637C7.66811 10.877 7.51401 10.9407 7.35337 10.9407Z"
          fill="black"
        />
        <path
          d="M28.6767 18.4042H18.6226C18.4615 18.4042 18.307 18.3403 18.1932 18.2264C18.0793 18.1125 18.0153 17.958 18.0153 17.797C18.0153 17.6359 18.0793 17.4814 18.1932 17.3675C18.307 17.2537 18.4615 17.1897 18.6226 17.1897H28.0694V1.30245H7.96846V3.80363C7.96846 3.96469 7.90448 4.11915 7.7906 4.23304C7.67671 4.34693 7.52225 4.41091 7.36119 4.41091C7.20012 4.41091 7.04566 4.34693 6.93177 4.23304C6.81789 4.11915 6.75391 3.96469 6.75391 3.80363V1.19071C6.75434 0.898215 6.87077 0.617835 7.07767 0.411086C7.28458 0.204337 7.56504 0.0881047 7.85753 0.0878906H28.1811C28.4735 0.088319 28.7538 0.204646 28.9605 0.411372C29.1672 0.618097 29.2835 0.898355 29.284 1.19071V17.797C29.284 17.958 29.22 18.1125 29.1061 18.2264C28.9922 18.3403 28.8377 18.4042 28.6767 18.4042Z"
          fill="black"
        />
        <path
          d="M9.75237 13.7028H2.04478C1.88372 13.7028 1.72925 13.6389 1.61537 13.525C1.50148 13.4111 1.4375 13.2566 1.4375 13.0956C1.4375 12.9345 1.50148 12.78 1.61537 12.6661C1.72925 12.5523 1.88372 12.4883 2.04478 12.4883H9.75237C9.91343 12.4883 10.0679 12.5523 10.1818 12.6661C10.2957 12.78 10.3596 12.9345 10.3596 13.0956C10.3596 13.2566 10.2957 13.4111 10.1818 13.525C10.0679 13.6389 9.91343 13.7028 9.75237 13.7028Z"
          fill="black"
        />
        <path
          d="M15.8978 10.9392H5.14244C4.98137 10.9392 4.82691 10.8752 4.71302 10.7613C4.59914 10.6474 4.53516 10.4929 4.53516 10.3319C4.53516 10.1708 4.59914 10.0164 4.71302 9.90248C4.82691 9.78859 4.98137 9.72461 5.14244 9.72461H15.8978C16.0588 9.72461 16.2133 9.78859 16.3272 9.90248C16.4411 10.0164 16.505 10.1708 16.505 10.3319C16.505 10.4929 16.4411 10.6474 16.3272 10.7613C16.2133 10.8752 16.0588 10.9392 15.8978 10.9392Z"
          fill="black"
        />
        <path
          d="M4.80722 7.73409H0.607279C0.446219 7.73409 0.291755 7.67011 0.177868 7.55622C0.063981 7.44233 0 7.28787 0 7.12681C0 6.96575 0.063981 6.81129 0.177868 6.6974C0.291755 6.58351 0.446219 6.51953 0.607279 6.51953H4.80722C4.96828 6.51953 5.12275 6.58351 5.23663 6.6974C5.35052 6.81129 5.4145 6.96575 5.4145 7.12681C5.4145 7.28787 5.35052 7.44233 5.23663 7.55622C5.12275 7.67011 4.96828 7.73409 4.80722 7.73409Z"
          fill="black"
        />
        <path
          d="M11.5444 4.38057H4.13072C3.96966 4.38057 3.81519 4.31659 3.70131 4.20271C3.58742 4.08882 3.52344 3.93436 3.52344 3.77329C3.52344 3.61223 3.58742 3.45777 3.70131 3.34388C3.81519 3.23 3.96966 3.16602 4.13072 3.16602H11.5444C11.7054 3.16602 11.8599 3.23 11.9738 3.34388C12.0877 3.45777 12.1517 3.61223 12.1517 3.77329C12.1517 3.93436 12.0877 4.08882 11.9738 4.20271C11.8599 4.31659 11.7054 4.38057 11.5444 4.38057Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
