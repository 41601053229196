import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionAnswer from './QuestionAnswer';
import SectionHeader from './SectionHeader';
import Sidebar from './Sidebar';
import { FaqTypes } from '../../reducers/faq/faq';
import './Faq.scss';

function Faq(props) {
  useEffect(() => {
    const { getFaqList } = props;
    getFaqList('Faq');
  }, []);
  return (
    <div>
      <div className="app-faq-header" />
      <div className="app-faq-layout">
        <SectionHeader />
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <Sidebar />
            </div>
            <div className="col-sm-9 col-lg-10">
              <div className="app-faq-main-wrapper ">
                <QuestionAnswer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Faq.propTypes = {
  getFaqList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { fetchFaq } = state;
  return { fetchFaq };
};

const mapDispatchToProps = (dispatch) => ({
  getFaqList: (componentType) =>
    dispatch({
      type: FaqTypes.GET_FAQ_LIST,
      componentType
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
