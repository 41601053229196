/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button from '../../components/unicellular/button';
import './Home.scss';
import PRODUCT_DEFAULTS from '../../constants/defaults/product';
import { generateUrl } from '../../utils/ImageUtils';
import RoutesPaths from '../../constants/routes';
import createSlug from '../../utils/conversions/createSlug';

export default function About(props) {
  const { specialProductData } = props;
  return (
    <div className="app-home-about">
      {/* <hr /> */}
      <div className="app-home-about-container">
        <div className="container">
          <div className="app-home-about-inner">
            <div className="row">
              <div className="col-sm-5 col-lg-6">
                <div className="app-home-about-image">
                  <img
                    className="image"
                    src={generateUrl(specialProductData.images ? specialProductData.images[0] : '')}
                    alt={specialProductData.name}
                    srcSet=""
                    onError={({ currentTarget }) => {
                      const currTrgt = currentTarget;
                      currTrgt.onerror = null; // prevents looping
                      currTrgt.src = PRODUCT_DEFAULTS.productImage;
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-7 col-lg-6 app-home-about-info-container">
                <div className="title">{specialProductData.name}</div>
                <div className="detail">{specialProductData.description}</div>
                <div className="price">{`₹ ${specialProductData.price}`}</div>
                <div>
                  <NavLink
                    to={`${specialProductData?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(specialProductData?.name)}/${specialProductData._id}`}>
                    <Button
                      buttonText={specialProductData.urlTitle}
                      className="app-about-btn-shop-now"
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
About.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  specialProductData: PropTypes.object
};
About.defaultProps = {
  specialProductData: {}
};
