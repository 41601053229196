/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react';
import './Loader.scss';
import '../../home/Home.scss';

const n = 5;
const infoBox = 6;
const Banner = 1;
const transform = 4;
const webinar = 4;
// eslint-disable-next-line no-unused-vars
function Loader(props) {
  return (
    <div>
      <div className="app-banner-container cw-skeleton-banner">
        <div>
          <div className="container align-container">
            {/* Home Banner */}
            <div className="row top-space-row">
              <div>
                <div className="home-lab-banner-image banner-cw-skeleton-bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-cw-top-space" />
      <div className="app-cw-top-space" />
      <div className="app-path-container">
        <div className="container" />
      </div>
      <div className="app-cw-top-space" />
      {/* webinar */}
      <div className="app-cw-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="cw-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-1-${index.toString()}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-10-${index.toString()}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-cw-top-space" />
      </div>
      {/* webinar */}
      <div className="app-cw-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="cw-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-2-${index.toString()}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-20-${index.toString()}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-cw-top-space" />
      </div>
      {/* webinar */}
      <div className="app-cw-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="cw-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item, index) => (
                <span className="" key={index} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item, index) => (
                <div key={`loading-3-${index.toString()}`} className="col">
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((item, index) => (
                        <p key={`loading-30-${index.toString()}`} className="description" />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-cw-top-space" />
      </div>
      <div className="app-cw-top-space" />
    </div>
  );
}

export default Loader;
