import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/multicellular/navigation/header';
import Footer from '../components/multicellular/navigation/footer';
import routeConfig from '../routes/routeconfig';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
function AppLayout() {
  return (
    <div className="main-wrapper">
      <Helmet>
        <title>YuviPeP Store</title>
        <meta
          name="description"
          content="YuviPep Innovation aims to transform education from the ground up. Our pedagogical approach, connected learning, and online global collaboration have broken boundaries to include a network of students beyond the immediate classroom environment."
        />
      </Helmet>
      <Header />
      <Wrapper>
        <Routes>
          {routeConfig.map((route) => {
            if (route.subPage) {
              return (
                <Route path={route.path} key={route.path} element={route.page}>
                  {route.subPage.map((subRoute) => (
                    <Route key={subRoute.path} exact path={subRoute.path} element={subRoute.page} />
                  ))}
                </Route>
              );
            }
            return <Route key={route.path} exact path={route.path} element={route.page} />;
          })}
          {/* <Route component={NotFound} /> */}
        </Routes>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default AppLayout;
