/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import Wishlist from '../wishlist';
import Bus from '../../../../assets/svgs/Bus';
import Rating from '../../../unicellular/rating';
import discountToPercentage from '../../../../utils/conversions/discountToPercentage';
// import ProductString from '../../../../constants/strings/product';
// import gstAmount from '../../../../utils/conversions/gstAmount';
import Button from '../../../unicellular/button';
import String from '../../../../constants/strings';
import CartString from '../../../../constants/strings/cart';
// import RoutesPaths from '../../../../constants/routes';
import './Style.scss';
import CircleChecked from '../../../../assets/svgs/CircleChecked';
import Auth from '../../../../containers/auth';
import STORAGE_KEYS from '../../../../constants/storageKey';

export default function KitCard(props) {
  // const navigate = useNavigate();
  const {
    title,
    description,
    loading,
    image,
    productId,
    wishlist,
    isWishlisted,
    calculatedPrices,
    rating,
    isAddedToCart,
    onClickCart,
    onClickBuyNow,
    loadingCart,
    isHideBuyNow,
    isFreeDelivery
  } = props;
  const { login } = useSelector((state) => state);
  const [isLogin, setisLogin] = useState(false);
  const { isUserLogin } = login;
  const { basePrice, netPrice, savedAmount } = calculatedPrices;
  const handleCart = () => {
    if (!isUserLogin) {
      sessionStorage.setItem(STORAGE_KEYS.SESSION_PRODUCT_ID, productId);
      setisLogin(true);
    } else {
      onClickCart();
    }
  };
  return loading ? (
    <div className="app-kc-container ">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  ) : (
    <div className="app-kc-container">
      <button type="button" onClick={onClickBuyNow} className="image-wrapper">
        <div className="image-wrapper">
          <img
            className="image"
            src={generateUrl(image)}
            alt={title}
            srcSet=""
            onError={({ currentTarget }) => {
              const currTrgt = currentTarget;
              currTrgt.onerror = null; // prevents looping
              currTrgt.src = PRODUCT_DEFAULTS.productImage;
            }}
          />
        </div>
      </button>
      <div className="inner-body">
        <button type="button" className="kc-btn" onClick={onClickBuyNow}>
          <div className="title">{title}</div>
          <div className="detail">
            {Array.isArray(description)
              ? description.slice(0, 3).map((d) => (
                  <div key={d}>
                    {d}
                    <br />
                  </div>
                ))
              : description}
          </div>
        </button>
        <div className="app-kit-wishlist-wrapper">
          {isUserLogin && (
            <Wishlist
              className="wishlist-kit"
              productId={productId}
              wishlist={wishlist}
              isWishlisted={isWishlisted}
            />
          )}
        </div>
        <div className="app-kit-rating">
          <div className="app-kit-rating-wrapper">
            {rating > 0 && <Rating avgRating={rating} />}
          </div>
        </div>
        <button type="button" onClick={onClickBuyNow}>
          <div className="btn-price-container">
            <div className="price">
              {`₹ ${netPrice}`}
              {basePrice !== netPrice && <span className="base-price">{`₹ ${basePrice}`}</span>}
              {savedAmount !== 0 && (
                <span className="discount">
                  {`(${discountToPercentage(savedAmount, basePrice)}% off)`}
                </span>
              )}
            </div>
          </div>
          {/* <div className="gst-number">
            {`₹ ${gstAmount(basePrice, ProductString.gstPrice)} (+${
              ProductString.gstPrice
            }% GST extra)`}
          </div> */}
          {isAddedToCart ? (
            <div className="btn-fd-container app-free-delivery-kit-card">
              <div className="fd-svg-success">
                <CircleChecked />
              </div>
              <div className="text-fd-success">Added to Cart</div>
            </div>
          ) : (
            (isFreeDelivery && (
              <div className="btn-fd-container app-free-delivery-kit-card">
                <div className="fd-svg">
                  <Bus />
                </div>
                <div className="text-fd">Free Delivery</div>
              </div>
            )) || <div className="app-free-delivery-kit-card" />
          )}
        </button>

        <div className="btn-wrap-container">
          <div className="inner-wrap" style={{ width: isHideBuyNow && '100%' }}>
            {/* {isUserLogin
              && (
                <Button
                  buttonText={CartString.ADD_TO_CART}
                  className="app-btn-add-to-cart"
                  // onClick={onClickCart}
                  onClick={handleCart}
                  loading={loadingCart}
                />
              )} */}
            <Button
              buttonText={CartString.ADD_TO_CART}
              className="app-btn-add-to-cart"
              // onClick={onClickCart}
              onClick={handleCart}
              loading={loadingCart}
            />
          </div>
          {isHideBuyNow ? null : (
            <div className="inner-wrap">
              <Button
                buttonText={String.BUTTON_BUYNOW}
                className="app-btn-buy-now"
                onClick={onClickBuyNow}
              />
            </div>
          )}
        </div>
      </div>
      {isLogin && !isUserLogin && <Auth show={isLogin} setShow={setisLogin} />}
    </div>
  );
}
KitCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  wishlist: PropTypes.objectOf(PropTypes.object),
  productId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  isWishlisted: PropTypes.bool,
  image: PropTypes.string,
  calculatedPrices: PropTypes.object,
  rating: PropTypes.number,
  onClickCart: PropTypes.func,
  isAddedToCart: PropTypes.bool,
  onClickBuyNow: PropTypes.func,
  loadingCart: PropTypes.bool,
  isHideBuyNow: PropTypes.bool,
  isFreeDelivery: PropTypes.bool
};
KitCard.defaultProps = {
  title: '',
  description: [],
  loading: false,
  image: '',
  wishlist: {},
  calculatedPrices: {},
  isWishlisted: false,
  isAddedToCart: false,
  rating: 0,
  onClickCart: null,
  onClickBuyNow: null,
  loadingCart: false,
  isHideBuyNow: false,
  isFreeDelivery: false
};
