import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall } from '../../adapters';
import STORAGE_KEYS from '../../constants/storageKey';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { CancelOrderTypes } from '../../reducers/order/cancelOrder';
import { OrderTypes } from '../../reducers/order/order';
import { ReturnOrderTypes } from '../../reducers/order/returnOrder';
import { OrderDetailTypes } from '../../reducers/order/orderDetails';
import { ReviewTypes } from '../../reducers/order/reviewDetails';

function* getOrderList(payload) {
  try {
    yield put({ type: OrderTypes.GET_ORDER_LIST_INDICATOR });
    const params = {
      userId: payload.userId,
      sortDays: payload.sortDays ? payload.sortDays : {},
      status: payload.status
    };
    const response = yield call(makePostApiCall, URLS.API_ORDER_LIST, params);
    if (response.status === 200) {
      yield put({
        type: OrderTypes.GET_ORDER_LIST_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: OrderTypes.GET_ORDER_LIST_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: OrderTypes.GET_ORDER_LIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getOrderDetails(payload) {
  try {
    yield put({ type: OrderDetailTypes.GET_ORDER_DETAIL_INDICATOR });
    const params = {};
    const response = yield call(
      makeGetApiCall,
      URLS.API_FETCH_ORDER_DETAIL(payload.orderId, payload.invoiceId),
      params
    );
    if (response.status === 200) {
      yield put({
        type: OrderDetailTypes.GET_ORDER_DETAIL_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: OrderDetailTypes.GET_ORDER_DETAIL_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: OrderDetailTypes.GET_ORDER_DETAIL_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getOrderCancel(payload) {
  try {
    yield put({ type: CancelOrderTypes.GET_ORDER_CANCELED_INDICATOR });
    const params = {
      products: payload.productsList,
      returnType: 'Cancel'
    };
    const response = yield call(makePostApiCall, URLS.API_CANCEL_ORDER(payload.orderId), params);
    if (response.status === 200) {
      yield put({
        type: OrderTypes.GET_ORDER_LIST,
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID),
        sortDays: payload?.sortDays,
        status: payload?.status
      });
      yield put({
        type: OrderDetailTypes.GET_ORDER_DETAIL,
        orderId: payload.orderId,
        invoiceId: payload.invoiceId
      });
      yield put({
        type: CancelOrderTypes.GET_ORDER_CANCELED_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: CancelOrderTypes.GET_ORDER_CANCELED_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: CancelOrderTypes.GET_ORDER_CANCELED_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getOrderReturn(payload) {
  try {
    yield put({ type: ReturnOrderTypes.GET_ORDER_RETURN_INDICATOR });
    const params = {
      products: payload.products,
      returnType: payload.returnType,
      reason: payload.reason
    };
    const response = yield call(makePostApiCall, URLS.API_CANCEL_ORDER(payload.orderId), params);
    if (response.status === 200) {
      yield put({
        type: ReturnOrderTypes.GET_ORDER_RETURN_SUCCESS,
        response: response.data
      });
      yield put({
        type: OrderTypes.GET_ORDER_LIST,
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID)
      });
    } else {
      yield put({
        type: ReturnOrderTypes.GET_ORDER_RETURN_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: ReturnOrderTypes.GET_ORDER_RETURN_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getOrderReturnOptions(payload) {
  try {
    yield put({ type: ReturnOrderTypes.GET_ORDER_RETURN_OPTIONS_INDICATOR });
    const params = {
      quantity: payload.quantity,
      returnType: payload.returnType,
      reason: payload.reason
    };
    const response = yield call(makeGetApiCall, URLS.API_RETURN_OPTIONS(payload.orderId), params);
    if (response.status === 200) {
      yield put({
        type: ReturnOrderTypes.GET_ORDER_RETURN_OPTIONS_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: ReturnOrderTypes.GET_ORDER_RETURN_OPTIONS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: ReturnOrderTypes.GET_ORDER_RETURN_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getOrderReview(payload) {
  try {
    yield put({ type: ReviewTypes.SET_REVIEW_INDICATOR });
    const params = {
      rating: payload.rating,
      title: payload.title,
      comments: payload.comments,
      productId: payload.productId,
      userId: payload.userId,
      images: payload.images
    };
    const response = yield call(makePostApiCall, URLS.API_REVIEW, params);
    if (response.status === 200) {
      yield put({
        type: ReviewTypes.SET_REVIEW_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: ReviewTypes.SET_REVIEW_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: ReviewTypes.SET_REVIEW_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* orderSaga() {
  try {
    yield all([
      takeLatest(OrderTypes.GET_ORDER_LIST, getOrderList),
      takeLatest(OrderDetailTypes.GET_ORDER_DETAIL, getOrderDetails),
      takeLatest(CancelOrderTypes.GET_ORDER_CANCELED, getOrderCancel),
      takeLatest(ReturnOrderTypes.GET_ORDER_RETURN, getOrderReturn),
      takeLatest(ReturnOrderTypes.GET_ORDER_RETURN_OPTIONS, getOrderReturnOptions),
      takeLatest(ReviewTypes.SET_REVIEW, getOrderReview)
    ]);
  } catch (ex) {
    console.warn('Error while Info Saga: InfoSaga', ex);
  }
}
export default orderSaga;
