import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import accountSidebar from '../../config/accountSidebar';
import './MyAccount.scss';

function Sidebar(props) {
  const location = useLocation();
  const accountPath = '/account/';
  const { onClick } = props;
  const handleClick = (path) => {
    onClick(path);
  };
  return (
    <div className="app-myaccount-sidebar">
      {accountSidebar.map((item, index) => (
        <div key={`account-sidebar-${index.toString()}`}>
          <div className="section-title">{item.sectionTitle}</div>
          <div className="list-box">
            {item.links.map((data, ind) => (
              <button
                type="button"
                className={`${accountPath + data.navigateTo === location.pathname ? 'link-flexbox-active' : 'link-flexbox'}`}
                key={`sidebar-item-${ind.toString()}`}
                onClick={() => handleClick(data.navigateTo)}>
                <div className="icon">{data.icon}</div>
                <div className="title">{data.title}</div>
              </button>
            ))}

            {index !== accountSidebar.length - 1 && <div className="sidebar-divider" />}
          </div>
        </div>
      ))}
    </div>
  );
}

Sidebar.propTypes = {
  onClick: PropTypes.func
};

Sidebar.defaultProps = {
  onClick: () => {}
};

export default Sidebar;
