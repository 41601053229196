import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './Banner.scss';

const initialState = {
  responsive: {
    0: { items: 1 }
  }
};
export default function Banner(props) {
  const { images } = props;
  return (
    images &&
    images.length > 0 && (
      <div className="app-banner-view">
        <OwlCarousel
          className="owl-theme"
          navText={[
            "<div className='nav-btn prev-slide'> <span class=' nav-icon-module-left'></span></div>",
            "<div className='nav-btn next-slide'><span class=' nav-icon-module-right'></span></div>"
          ]}
          loop
          margin={1}
          nav
          responsive={initialState.responsive}
          dotsClass="hidden-owl-dots">
          {images.map((item, index) => (
            <NavLink to={`${item.action?.navigateTo}/${item.action?.id}`} key={index?.toString()}>
              <div className="app-banner-wrapper">
                <div className="banner-container container-fluid">
                  <div
                    className="banner-img-wrapper wb-view"
                    style={{ backgroundImage: `url(${item.images?.web})` }}
                  />
                  <div
                    className="banner-img-wrapper mb-view"
                    style={{ backgroundImage: `url(${item.images?.mobile})` }}
                  />
                </div>
              </div>
            </NavLink>
          ))}
        </OwlCarousel>
      </div>
    )
  );
}
Banner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array
};
Banner.defaultProps = {
  images: []
};
