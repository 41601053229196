import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePostApiCall } from '../../adapters';
import { FaqTypes } from '../../reducers/faq/faq';
import String from '../../constants/strings';
import URLS from '../../constants/urls';

function* getFaqData(payload) {
  try {
    yield put({ type: FaqTypes.GET_FAQ_LIST_INDICATOR });
    const params = { componentType: payload.componentType };
    const response = yield call(makePostApiCall, URLS.API_FETCH_FAQ_LIST, params);
    if (response.status === 200) {
      yield put({
        type: FaqTypes.GET_FAQ_LIST_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: FaqTypes.GET_FAQ_LIST_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: FaqTypes.GET_FAQ_LIST_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* faqSaga() {
  try {
    yield all([takeLatest(FaqTypes.GET_FAQ_LIST, getFaqData)]);
  } catch (ex) {
    console.warn('Error while Faq Saga: faqSaga', ex);
  }
}
export default faqSaga;
