import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall } from '../../adapters';
import { PaymentTypes } from '../../reducers/payment/paymentGateway';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { PaymentMethodTypes } from '../../reducers/payment/paymentMethod';
import STORAGE_KEYS from '../../constants/storageKey';
import { PaymentStatusTypes } from '../../reducers/payment/paymentStatus';

function* getPaytmPaymentList(payload) {
  try {
    yield put({ type: PaymentTypes.PAYTM_PAYMENT_GATEWAY_INDICATOR });
    const params = {
      userId: payload.userId,
      onlineClassId: payload.onlineClassId,
      batchSlotId: payload.batchId,
      parentEmail: payload.parentEmail,
      courseUrl: payload.courseUrl,
      txnType: payload.txnType,
      id: payload.id,
      subscriptionPlanId: payload.subscriptionPlanId,
      formData: payload.formData,
      registerId: payload.registerId,
      identifier: payload.identifier
    };

    const response = yield call(makeGetApiCall, URLS.API_ALL_CATEGORY, params);
    if (response.status === 200) {
      yield put({
        type: PaymentTypes.PAYTM_PAYMENT_GATEWAY_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: PaymentTypes.PAYTM_PAYMENT_GATEWAY_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: PaymentTypes.PAYTM_PAYMENT_GATEWAY_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getRayzorPayPaymentList(payload) {
  try {
    yield put({ type: PaymentTypes.RAZORPAY_PAYMENT_GATEWAY_INDICATOR });
    const params = {
      isBuyNow: '',
      userId: payload.userId,
      txnType: payload.txnType,
      address: payload.addressDetails,
      deliveryCharge: {},
      deliveryEstimate: {}
    };

    const response = yield call(makePostApiCall, payload.apiUrl, params);
    if (response.status === 200) {
      yield put({
        type: PaymentTypes.RAZORPAY_PAYMENT_GATEWAY_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: PaymentTypes.RAZORPAY_PAYMENT_GATEWAY_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: PaymentTypes.RAZORPAY_PAYMENT_GATEWAY_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getPaymentMethod(payload) {
  const productId = sessionStorage.getItem(STORAGE_KEYS.PRODUCT_ID);
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  try {
    yield put({ type: PaymentMethodTypes.GET_PAYMENT_METHOD_INDICATOR });
    const params = {
      isBuyNow: '',
      ...(userId && { userId }),
      txnType: payload.txnType,
      address: payload.addressDetails,
      deliveryCharge: {},
      deliveryEstimate: {},
      ...(productId && { productId })
    };

    const response = yield call(makePostApiCall, URLS.API_GET_PAYMENT_METHOD, params);
    if (response.status === 200) {
      yield put({
        type: PaymentMethodTypes.GET_PAYMENT_METHOD_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: PaymentMethodTypes.GET_PAYMENT_METHOD_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: PaymentMethodTypes.GET_PAYMENT_METHOD_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getPaymentStatus(payload) {
  try {
    yield put({ type: PaymentStatusTypes.GET_PAYMENT_STATUS_INDICATOR });
    const params = {
      merchantId: payload.merchantId,
      transactionId: payload.transactionId,
      merchantTransactionId: payload.merchantTransactionId,
      isRefund: payload.isRefund
    };

    const response = yield call(makePostApiCall, URLS.API_GET_PAYMENT_STATUS, params);
    if (response.status === 200) {
      yield put({
        type: PaymentStatusTypes.GET_PAYMENT_STATUS_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: PaymentStatusTypes.GET_PAYMENT_STATUS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: PaymentStatusTypes.GET_PAYMENT_STATUS_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* PaymentSaga() {
  try {
    yield all([
      takeLatest(PaymentTypes.PAYTM_PAYMENT_GATEWAY, getPaytmPaymentList),
      takeLatest(PaymentTypes.RAZORPAY_PAYMENT_GATEWAY, getRayzorPayPaymentList),
      takeLatest(PaymentMethodTypes.GET_PAYMENT_METHOD, getPaymentMethod),
      takeLatest(PaymentStatusTypes.GET_PAYMENT_STATUS, getPaymentStatus)
    ]);
  } catch (ex) {
    console.warn('Error while Payment Saga: PaymentSaga', ex);
  }
}
export default PaymentSaga;
