import React from 'react';
import MyAccountString from '../../../constants/strings/myAccount';
import './CustomerCare.scss';

export default function CustomerCare() {
  return (
    <div className="app-account-customer-care ">
      <div className="cc-section-title-first">{MyAccountString.CUSTOMER_CARE_SECTION_TITLE}</div>
      <div className="cc-inner-section">
        <div className="label">
          Email Address: <span>info@yuvipep.com</span>
        </div>
        <div className="label">
          Mobile number: <span>+91-8660450262</span>
        </div>
      </div>
    </div>
  );
}
