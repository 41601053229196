import mirrorKeyValue from 'mirror-key-value';

/* ------------- Types ------------- */

export const PaymentMethodTypes = mirrorKeyValue([
  'GET_PAYMENT_METHOD',
  'GET_PAYMENT_METHOD_INDICATOR',
  'GET_PAYMENT_METHOD_SUCCESS',
  'GET_PAYMENT_METHOD_FAILED',
  'SET_ACTIVE_PAYMENT_OPTION'
]);

const {
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_INDICATOR,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAILED,
  SET_ACTIVE_PAYMENT_OPTION
} = PaymentMethodTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  paymentMethodData: [],
  error: false,
  errorMsg: '',
  activePaymentOption: {}
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_PAYMENT_METHOD:
      return { ...state, ...data };
    case GET_PAYMENT_METHOD_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_PAYMENT_METHOD_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const activeData = action.response.data.find((o) => o.isDefault);
      return {
        ...state,
        loading: false,
        paymentMethodData: action.response.data,
        activePaymentOption: activeData,
        error: false
      };
    case GET_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    case SET_ACTIVE_PAYMENT_OPTION:
      return {
        ...state,
        activePaymentOption: action.activePayment
      };
    default:
      return state;
  }
}
