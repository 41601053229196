import React from 'react';
import { connect, useSelector } from 'react-redux';
import Banner from '../../components/multicellular/home/banner';
// import Images from '../../themes/Image';
import './Home.scss';

function HomeBanner() {
  const { home } = useSelector((state) => state);
  const { homeproducts } = home;
  const { home: { mainBanner = [] } = {} } = homeproducts;
  return (
    <div>
      <div className="app-home-banner">
        <Banner images={mainBanner} />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { home } = state;
  return { home };
};

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner);
