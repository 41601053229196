import React from 'react';
import { connect, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import TestimonialCard from '../../../components/multicellular/products/testimonialCard';

import './ProductDetail.scss';

const initialState = {
  responsive: {
    0: { items: 1 },
    550: { items: 2 },
    600: { items: 3 },
    1000: { items: 3 }
  }
};

const renderCarousel = (testimonials) => (
  <div>
    <OwlCarousel
      className="owl-theme"
      navText={[
        "<div className='nav-btn prev-slide'> <span class=' nav-icon-module-left'></span></div>",
        "<div className='nav-btn next-slide'><span class=' nav-icon-module-right'></span></div>"
      ]}
      loop={false}
      margin={16}
      nav
      responsive={initialState}
      dotsClass="hidden-owl-dots">
      {testimonials?.length > 0 &&
        testimonials?.map((item, index) => (
          <div key={`testimonials-${index.toString()}`}>
            <TestimonialCard name={item.name} description={item.description} />
          </div>
        ))}
    </OwlCarousel>
  </div>
);

function Testimonial() {
  const { testimonial } = useSelector((state) => state);
  const { testimonialsData: { testimonials = [] } = {} } = testimonial;
  const { loading } = testimonial;
  const renderLoadingView = () => (
    <div className="app-testimonial-container">
      <div className="container">
        <div className="row">
          <div className="col-sm-4 col-lg-4">
            <div className="app-testiomial-wrapper loading-view">
              <div className="title-ld loading-animation" />
              <div className="sectiontitle-ld loading-animation" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return loading
    ? renderLoadingView()
    : testimonials && testimonials.length > 0 && (
        <div className="app-testimonial-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-lg-4">
                <div className="app-testiomial-wrapper">
                  <div className="section-title">Product Testimonials</div>
                  <div className="description">What our students felt after purchasing our Kit</div>
                </div>
              </div>
              <div className="col-sm-8 col-lg-8">{renderCarousel(testimonials)}</div>
            </div>
          </div>
        </div>
      );
}

const mapStateToProps = (state) => {
  const { testimonial } = state;
  return { testimonial };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Testimonial);
