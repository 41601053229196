/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import Bus from '../../../../assets/svgs/Bus';
import Rating from '../../../unicellular/rating';
import discountToPercentage from '../../../../utils/conversions/discountToPercentage';
// import ProductString from '../../../../constants/strings/product';
// import gstAmount from '../../../../utils/conversions/gstAmount';
// import RoutesPaths from '../../../../constants/routes';
import CircleChecked from '../../../../assets/svgs/CircleChecked';
import Auth from '../../../../containers/auth';
import './style.scss';

export default function FrequentlyBoughtItemCard(props) {
  const {
    title,
    description,
    loading,
    image,
    productId,
    calculatedPrices,
    rating,
    isAddedToCart,
    onClickBuyNow,
    isSelected,
    onToggleSelect,
    showBottomInfo,
    isFreeDelivery
  } = props;
  const { login } = useSelector((state) => state);
  const [isLogin, setisLogin] = useState(false);
  const { isUserLogin } = login;
  const { basePrice, netPrice, savedAmount } = calculatedPrices;

  return loading ? (
    <div className="app-kc-container frequently-bought-item-card">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  ) : (
    <div className="app-kc-container frequently-bought-item-card">
      <button type="button" onClick={onClickBuyNow} className="image-wrapper">
        <div className="image-wrapper">
          <img
            className="image"
            src={generateUrl(image)}
            alt={title}
            srcSet=""
            onError={({ currentTarget }) => {
              const currTrgt = currentTarget;
              currTrgt.onerror = null; // prevents looping
              currTrgt.src = PRODUCT_DEFAULTS.productImage;
            }}
          />
        </div>
      </button>
      <div className="inner-body">
        <button type="button" className="kc-btn" onClick={onClickBuyNow}>
          <div className="title">{title || productId}</div>
          <div className="detail">
            {Array.isArray(description)
              ? description.slice(0, 3).map((d) => (
                  <div key={d}>
                    {d}
                    <br />
                  </div>
                ))
              : description}
          </div>
        </button>
        <div className="app-kit-wishlist-wrapper">
          <Checkbox
            size="large"
            disableRipple
            checked={isSelected}
            onChange={(_, checked) => onToggleSelect(checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <div className="app-kit-rating">
          <div className="app-kit-rating-wrapper">
            {rating > 0 && <Rating avgRating={rating} />}
          </div>
        </div>
        <button type="button" onClick={onClickBuyNow}>
          <div className="btn-price-container">
            <div className="price">
              {`₹ ${netPrice}`}
              {basePrice !== netPrice && <span className="base-price">{`₹ ${basePrice}`}</span>}
              {savedAmount !== 0 && (
                <span className="discount">
                  {`(${discountToPercentage(savedAmount, basePrice)}% off)`}
                </span>
              )}
            </div>
          </div>
          {showBottomInfo && (
            <div className="btn-fd-container app-free-delivery-kit-card">
              {isAddedToCart ? (
                <>
                  <div className="fd-svg-success">
                    <CircleChecked />
                  </div>
                  <div className="text-fd-success">Added to Cart</div>
                </>
              ) : (
                isFreeDelivery && (
                  <>
                    <div className="fd-svg">
                      <Bus />
                    </div>
                    <div className="text-fd">Free Delivery</div>
                  </>
                )
              )}
            </div>
          )}
        </button>
      </div>
      {isLogin && !isUserLogin && <Auth show={isLogin} setShow={setisLogin} />}
    </div>
  );
}
FrequentlyBoughtItemCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  productId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  image: PropTypes.string,
  calculatedPrices: PropTypes.object,
  rating: PropTypes.number,
  isAddedToCart: PropTypes.bool,
  onClickBuyNow: PropTypes.func,
  isSelected: PropTypes.bool,
  onToggleSelect: PropTypes.func.isRequired,
  showBottomInfo: PropTypes.bool,
  isFreeDelivery: PropTypes.bool
};

FrequentlyBoughtItemCard.defaultProps = {
  title: '',
  description: [],
  loading: false,
  image: '',
  calculatedPrices: {},
  isAddedToCart: false,
  rating: 0,
  onClickBuyNow: null,
  isSelected: false,
  showBottomInfo: false,
  isFreeDelivery: false
};
