/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Star from '../../../assets/svgs/Star';
import UserRatingCard from '../../../components/multicellular/products/userRatingCard';
import CircleForwardArrow from '../../../assets/svgs/CircleForwardArrow';
import CircleBackArrow from '../../../assets/svgs/CircleBackArrow';
import { RatingTypes } from '../../../reducers/info/rating';
import STORAGE_KEYS from '../../../constants/storageKey';
import './ProductReview.scss';

function Rating(props) {
  const params = useParams();

  const { name } = props;
  const { rating } = useSelector((state) => state);
  const { ratingsData = {} } = rating;
  const {
    reviews: { avgRating = 0, totalRatings = 0, totalReviews = 0, comments = [], ratings = [] } = {}
  } = ratingsData;
  const checkRatingProgressBar = (value) => {
    switch (value) {
      case 5: {
        return 'gd-rating-progress-bar';
      }
      case 2: {
        return 'mid-rating-progress-bar';
      }
      case 1: {
        return 'low-rating-progress-bar';
      }
      default: {
        return 'gd-rating-progress-bar';
      }
    }
  };
  const handleChange = (event, value) => {
    const { callRatingApi } = props;
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const { id } = params;
    const size = 10;
    const page = value;
    callRatingApi(id, userId, size, page);
    window.scrollTo(0, 20);
  };
  const checkRatePercentage = (rate, total) => Number((rate / total) * 100).toFixed(0);
  const ratingTableView = (rateValue, rateCount, totalCount) => (
    <div className="rating-table-container">
      <div className="rating-text">
        <span className="rating-number">{rateValue}</span>
        <span>
          <Star color="#333" />
        </span>
      </div>
      <div className="progress-rating-bar-container ">
        <div className={`progress-bar-container ${checkRatingProgressBar(rateValue)}`}>
          <ProgressBar
            now={checkRatePercentage(rateCount, totalCount)}
            label={`${checkRatePercentage(rateCount, totalCount)}%`}
            visuallyHidden
          />
        </div>
      </div>
      <div className="rating-number-text">{rateCount}</div>
    </div>
  );

  const renderComments = () =>
    comments.map((item, index) => (
      <div key={item}>
        <UserRatingCard
          key={`UserRatingCard-${index.toString()}`}
          reviewId={item._id}
          username={item?.user?.name}
          isUserVerified={item?.user?.verified}
          title={item.title}
          comment={item.comments}
          date={item.createdAt}
          rating={item.rating}
          images={item.images}
          likes={item.likes}
          dislikes={item.dislikes}
          isLike={item?.isLiked}
          isDislike={item?.isDisliked}
        />
      </div>
    ));

  return (
    <div className="container app-product-review-pg-container">
      <div className="app-page-section-title">{name}</div>
      <div className="app-product-rating-inner-view">
        <div className="rating-start-view">
          <div className="rating">
            {Number(avgRating).toFixed(1)}
            <span>
              <Star color="#333" />
            </span>
          </div>
          <div className="rating-detail-text">{`${totalRatings} Reviews & ${totalReviews} Reviews`}</div>
        </div>
        <div className="rating-table-wrapper">
          {[...ratings]
            .reverse()
            .map((item) => ratingTableView(item?.value, item?.count || 0, totalRatings))}
        </div>
      </div>
      <div className="app-rating-detail-comment-container">{renderComments()}</div>
      <div className="product-review-pagination">
        <Pagination
          count={Math.round(totalReviews / 10)}
          totalPosts={comments.length}
          color="primary"
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: CircleBackArrow,
                next: CircleForwardArrow
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
}
Rating.propTypes = {
  name: PropTypes.string,
  callRatingApi: PropTypes.func.isRequired
};
Rating.defaultProps = {
  name: ''
};
const mapStateToProps = (state) => {
  const { rating } = state;
  return { rating };
};
const mapDispatchToProps = (dispatch) => ({
  callRatingApi: (productId, userId, size, page) =>
    dispatch({
      type: RatingTypes.FETCH_RATING,
      productId,
      userId,
      size,
      page
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
