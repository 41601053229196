import mirrorKeyValue from 'mirror-key-value';

export const SimiliarTypes = mirrorKeyValue([
  'GET_SIMILIAR_PRODUCT',
  'GET_SIMILIAR_PRODUCT_INDICATOR',
  'GET_SIMILIAR_PRODUCT_SUCCESS',
  'GET_SIMILIAR_PRODUCT_FAILED'
]);

const {
  GET_SIMILIAR_PRODUCT,
  GET_SIMILIAR_PRODUCT_INDICATOR,
  GET_SIMILIAR_PRODUCT_SUCCESS,
  GET_SIMILIAR_PRODUCT_FAILED
} = SimiliarTypes;

const INITIAL_STATE = {
  load: false,
  similarproducts: {},
  error: '',
  errorMsg: ''
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case GET_SIMILIAR_PRODUCT:
      return { ...state, ...data };
    case GET_SIMILIAR_PRODUCT_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case GET_SIMILIAR_PRODUCT_SUCCESS:
      return {
        ...state,
        load: false,
        similarproducts: action.response,
        error: false
      };
    case GET_SIMILIAR_PRODUCT_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
