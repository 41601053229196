import { useState, useEffect, useMemo } from 'react';

const defaultValues = {
  delay: 5 * 1000, // seconds
  interval: 3 * 60 * 60 * 1000
};

const useTimedPopup = ({
  id,
  delay = defaultValues.delay,
  interval = defaultValues.interval
} = {}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [lastPopupTime, setLastPopupTime] = useState(0);

  const key = useMemo(() => `yuvipep_last_timed_popup_${id}`, [id]);

  const shouldShowPopup = () => Date.now() - lastPopupTime > interval;

  const getStoredLastPopupTime = () => {
    const lastTime = localStorage.getItem(key);
    return lastTime ? parseInt(lastTime, 10) : 0;
  };

  const setStoredLastPopupTime = (value) => {
    localStorage.setItem(key, value);
  };

  const resetStoredLastPopupTime = () => setStoredLastPopupTime(0);

  useEffect(() => {
    setLastPopupTime(getStoredLastPopupTime());
  }, []);

  useEffect(() => {
    if (!shouldShowPopup()) return () => {};

    const timer = setTimeout(() => {
      setShowPopup(true);
      const now = Date.now();

      setLastPopupTime(now);
      setStoredLastPopupTime(now);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, lastPopupTime]);

  const closePopup = () => {
    setShowPopup(false);
  };

  return {
    showPopup,
    closePopup,
    key,
    shouldShowPopup,
    getStoredLastPopupTime,
    setStoredLastPopupTime,
    resetStoredLastPopupTime
  };
};

export default useTimedPopup;
