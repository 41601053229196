import React from 'react';

export default function Key() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.04158 3.87484L11.0833 1.83317M12.2499 0.666504L11.0833 1.83317L12.2499 0.666504ZM6.64408 6.27234C6.94528 6.56953 7.18472 6.92336 7.34861 7.31346C7.5125 7.70357 7.5976 8.12224 7.59902 8.54537C7.60043 8.9685 7.51814 9.38773 7.35687 9.77893C7.1956 10.1701 6.95853 10.5256 6.65933 10.8248C6.36013 11.124 6.0047 11.361 5.61351 11.5223C5.22231 11.6836 4.80308 11.7659 4.37995 11.7644C3.95682 11.763 3.53815 11.6779 3.14804 11.514C2.75794 11.3501 2.4041 11.1107 2.10692 10.8095C1.52249 10.2044 1.19911 9.39397 1.20642 8.55276C1.21373 7.71154 1.55115 6.90685 2.146 6.312C2.74085 5.71715 3.54554 5.37973 4.38675 5.37242C5.22797 5.36511 6.0384 5.6885 6.6435 6.27292L6.64408 6.27234ZM6.64408 6.27234L9.04158 3.87484L6.64408 6.27234ZM9.04158 3.87484L10.7916 5.62484L12.8333 3.58317L11.0833 1.83317L9.04158 3.87484Z"
          stroke="#888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
