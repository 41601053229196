import React from 'react';
import PropTypes from 'prop-types';

import URLS from '../../constants/urls';

import './Policy.scss';

export default function Policy(props) {
  const { url } = props;
  return (
    <div>
      <div>
        <div className="app-policy-header" />
        <div className="container">
          <div className="policy-body-container">
            <iframe
              src={URLS.BASE_URL + url}
              height="100%"
              width="100%"
              frameBorder="0"
              title="privacy-policy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Policy.propTypes = {
  url: PropTypes.string.isRequired
};
