import React from 'react';
import { useLocation } from 'react-router-dom';
import String from '../../../../constants/strings';
import Routes from '../../../../constants/routes';
import YuvipepLogo from '../../../../assets/svgs/YuvipepLogo';
import footerValue from '../../../../config/footerValue';
import './Footer.scss';
import Xtwitter from '../../../../assets/svgs/Xtwitter';

function FooterLinksSection(item, key) {
  const { sectionTitle, footerLinks } = item;
  return (
    <div className="ft-links-view col-sm-3 col-lg-3" key={key}>
      <h4 className="ft-links-title" style={{ paddingBottom: '15px' }}>
        {sectionTitle}
      </h4>
      {footerLinks.map((data, index) => (
        <p key={`footer-link-0-${index.toString()}`}>
          <a href={data.navigateTo} target={data?.isExternal && '_blank'}>
            {data.title}
          </a>
        </p>
      ))}
    </div>
  );
}
function renderSocilaLinks() {
  return (
    <div className="app-footer-social-container">
      <p className="social-media-title">Follow us on</p>
      <div className="yp-social-media-container">
        <a
          href="https://www.linkedin.com/company/yuvipep/"
          aria-label="linkedin"
          target="_blank"
          rel="noreferrer">
          <i className="fa fa-linkedin social-medial-link ln-hover" aria-hidden="true" />
        </a>
        <a
          href="https://www.facebook.com/YuviPep"
          aria-label="facebook"
          target="_blank"
          rel="noreferrer">
          <i className="fa fa-facebook social-medial-link fb-hover" aria-hidden="true" />
        </a>
        <a href="https://twitter.com/YuviPep" aria-label="twitter" target="_blank" rel="noreferrer">
          <div className="tt-social-medial-link tt-hover">
            <Xtwitter />
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCZaRAS87fO4bmSsgxtm9_PQ"
          target="_blank"
          aria-label="youtube"
          rel="noreferrer">
          <i className="fa fa-youtube-play social-medial-link yt-hover" aria-hidden="true" />
        </a>
        <a
          href="https://www.instagram.com/yuvipep"
          aria-label="instagram"
          target="_blank"
          rel="noreferrer">
          <i className="fa fa-instagram social-medial-link ig-hover" aria-hidden="true" />
        </a>
      </div>
    </div>
  );
}

function Footer() {
  const location = useLocation();
  const currentPath = location.pathname;
  const checkIsCart = () => {
    const cartRoutes = [
      Routes.CART,
      `${Routes.CART}/${Routes.ADDRESS}`,
      `${Routes.PAYMENT_SUMMARY}`
    ];
    return cartRoutes.includes(currentPath);
  };
  return checkIsCart() ? null : (
    <div className="app-footer-view">
      <div className="container-fluid">
        <div className="container footer-container">
          <div className="row">
            <div className="ft-start-section col-sm-4 col-lg-4">
              <div className="ft-logo-container">
                <a aria-label="home" href={Routes.HOME}>
                  <YuvipepLogo color="#FFFFFF" />
                </a>
              </div>
              <div>
                <p className="footer-description ">{String.FOOTER_DESCRIPTION}</p>
              </div>
              <div>{renderSocilaLinks()}</div>
            </div>
            <div className="col-sm-1 col-lg-1" />
            <div className="ft-end-section col-sm-7 col-lg-7">
              <div className="row">
                {footerValue.map((item, index) => FooterLinksSection(item, index.toString()))}
              </div>
            </div>
          </div>

          <div />
          <hr className="solid ft-separator" />
          <div className="row">
            <div className="col-md-12">
              <p className="footer-copyright-white">
                &copy;
                {String.FOOTER_COMPANY_TAG}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
