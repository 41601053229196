import React from 'react';
import PropTypes from 'prop-types';

import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import './Infocard.scss';

export default function InfoCard(props) {
  const { title, imageSrc, boderBottomColor } = props;

  return (
    <div className="app-info-card" style={{ borderBottomColor: boderBottomColor }}>
      <img
        src={generateUrl(imageSrc)}
        className="image"
        alt=""
        onError={({ currentTarget }) => {
          const currTrgt = currentTarget;
          currTrgt.onerror = null; // prevents looping
          currTrgt.src = PRODUCT_DEFAULTS.productImage;
        }}
      />
      <div className="text">{title}</div>
    </div>
  );
}
InfoCard.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  boderBottomColor: PropTypes.string
};
InfoCard.defaultProps = {
  title: '',
  imageSrc: '',
  boderBottomColor: '#FFA0C2'
};
