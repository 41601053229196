const Sorting = {
  ASC: 'asc',
  DESC: 'desc',
  ALL: 'All',
  CANCELLED: 'Cancelled',
  ORDER_CANCELLED: 'Order Cancelled',
  RETURNED: 'Returned',
  DISPATCHED: 'Dispatched',
  ORDERED: 'Ordered',
  CONFIRMED: 'Confirmed',
  REFUND_INITIATED: 'Refund/Return Initiated',
  DELIVERED: 'Delivered',
  REPLACED: 'Replaced',
  REQUESTED_REPLACEMENT: 'Requested for replacement',
  REQUESTED_RETURN: 'Requested for return'
};
export default Sorting;
