import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Address from './address';
import Profile from './profile';
import SectionHeader from './SectionHeader';
import Order from './order';
import Sidebar from './Sidebar';
import RoutesURL from '../../constants/routes';
import CustomerCare from './customerCare';
import InviteFriend from './inviteFriend';
import './MyAccount.scss';

export default function MyAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const myRef = useRef(null);
  const { login } = useSelector((state) => state);
  const { isUserLogin } = login;
  useEffect(() => {
    const authenticated = isUserLogin;
    const goToHomePage = () => navigate('/');
    if (!authenticated) {
      goToHomePage();
    }
  }, [isUserLogin, navigate]);
  useEffect(() => {
    const accountPath = '/account/';
    if (window.innerWidth < 600) {
      if (
        accountPath + RoutesURL.ORDERS === location.pathname ||
        accountPath + RoutesURL.ADDRESS === location.pathname ||
        accountPath + RoutesURL.PROFILE === location.pathname ||
        accountPath + RoutesURL.INVITE_FRIENDS === location.pathname
      ) {
        window.scrollTo(0, myRef.current.offsetTop - 100);
      }
    }
  }, [location]);

  const executeScroll = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="app-myaccount-header" />
      <div className="app-myaccount-layout">
        <div className="container mb-container-account">
          <SectionHeader />
          <div className="row">
            <div className="col-sm-3 col-lg-2">
              <Sidebar onClick={executeScroll} />
            </div>
            <div className="col-sm-9 col-lg-10">
              <div className="app-myaccount-main-wrapper" ref={myRef}>
                <Routes>
                  <Route index element={<Profile />} />
                  <Route index exact path={RoutesURL.ADDRESS} element={<Address />} />
                  <Route index exact path={RoutesURL.ORDERS} element={<Order />} />
                  <Route index exact path={RoutesURL.PROFILE} element={<Profile />} />
                  <Route index exact path={RoutesURL.CUSTOMER_CARE} element={<CustomerCare />} />
                  <Route index exact path={RoutesURL.INVITE_FRIENDS} element={<InviteFriend />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
