const PlaceholderString = {
  EMAIL: 'Enter email address (Optional)',
  MOBILE_NO: 'Enter mobile number*',
  PASSWORD: 'Enter password',
  EMAIL_MOBILE: 'Enter email address / mobile number',
  FULL_NAME: 'Enter full name*',
  NAME: 'Name*',
  ADDRESS_MOBILE_NUMBER: 'Mobile Number*',
  ADDRESS_PINCODE: 'Pin Code*',
  ADDRESS: 'Address (House No, Building, Street, Area)*',
  ADDRESS_LANDMARK: 'Landmark*',
  ADDRESS_CITY: 'City',
  ADDRESS_DISTRICT: 'Locality',
  ADDRESS_STATE: 'State*',
  DEFAULT_ADDRESS: 'Make this as default address',
  LOGIN_USER_ID_INPUT: 'Enter email address / mobile number',
  LOGIN_USER_ID_INPUT_FOR_MOBILE: 'Enter mobile number',
  SET_PASSWORD_OTP_INPUT: (type) => `Enter otp sent to your ${type}`,
  SET_PASSWORD_INPUT: 'Set new password',
  PINCODE: 'Delivery Pincode',
  CURRENT_PASSWORD: 'Enter current password*',
  NEW_PASSWORD: 'Enter new password*',
  RETYPE_PASSWORD: 'Confirm password*',
  EMAIL_ADDRESS: 'Enter Email address',
  COLLEGE_SCHOOL: 'Enter School/College/Business Name*'
};
export default PlaceholderString;
