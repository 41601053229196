const PaymentString = {
  APPLY_COUPON: 'Apply Coupon',
  APPLY_NOW: 'Apply Now',
  AMOUNT_PAYABLE: 'Amount Payable',
  CONTINUE: 'Continue',
  LOGIN_AND_CONTINUE: 'Login And Continue',
  COUPON_APPLIED: 'Coupon ',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  COD_PAYMENT_SUCCESSFUL: 'Your order has been placed successfully',
  PAYMENT_FAILURE: 'Payment Failure',
  COD_PAYMENT_FAILURE: 'Unable to place your order. Please Try again later.'
};
export default PaymentString;
