import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { InnovationTypes } from '../../reducers/home/home';
import Error from '../../components/multicellular/Error/Error';
import InfoBanner from '../../components/multicellular/home/infoBanner';
import STORAGE_KEYS from '../../constants/storageKey';
import String from '../../constants/strings';
import Images from '../../themes/Image';
import About from './About';
import Banner from './Banner';
import Benefits from './Benefits';
import FlashSale from './FlashSale';
import KitCarousel from './KitCarousel';
import Kits from './Kits';
import Loader from './loader/index';

import './Home.scss';
import { CartTypes } from '../../reducers/cart/cart';
import ProjectLink from './ProjectLink';
import DynamicTimedPopup from '../../components/multicellular/timed-popups/DynamicTimedPopup';

function Home(props) {
  const { callHomeApi, callCategoryWiseApi, resetAddToCart } = props;
  const { home, login, cart } = useSelector((state) => state);
  const { isUserLogin } = login;
  const { isSuccess } = cart;
  const { loading, homeproducts, homeCategoryWise, error, errorMsg, isCallApi } = home;
  const {
    home: {
      flashSale = {},
      mainCategories = [],
      firstSubBanner = {},
      secondSubBanner = {},
      offers = [],
      specialProduct = {},
      dealsOfTheDay: { products = [], title = '' } = {},
      popularProducts = {}
    } = {}
  } = homeproducts;
  const { shopByCategories = [] } = homeCategoryWise;
  useEffect(() => {
    callHomeApi();
  }, []);
  useEffect(() => {
    if (!isCallApi || isUserLogin) {
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      callCategoryWiseApi(userId);
    }
  }, [isUserLogin]);
  useEffect(() => {
    if (isSuccess) {
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      callCategoryWiseApi(userId);
      resetAddToCart();
    }
  }, [isSuccess]);
  return (
    <div>
      {loading ? (
        <Loader value={String.LOADING} />
      ) : (
        <div>
          {error ? (
            <Error description={String.SERVER_ERROR_TRY_LATER} title={errorMsg} />
          ) : (
            <div>
              <DynamicTimedPopup id="main" />
              <Banner />
              <div className="app-bg-about">
                <div className="app-bg-vector">
                  <Kits loading={loading} mainCategoriesData={mainCategories} />
                  <About loading={loading} specialProductData={specialProduct} />
                  <FlashSale
                    loading={loading}
                    flaseSaleData={flashSale}
                    popularProductsData={popularProducts}
                  />
                  <ProjectLink />
                </div>
              </div>
              {firstSubBanner?.isActive && (
                <>
                  <div className="wb-view">
                    <InfoBanner loading={loading} subBannerData={firstSubBanner} />
                  </div>
                  <div className="mb-view">
                    <InfoBanner loading={loading} isMobile subBannerData={firstSubBanner} />
                  </div>
                </>
              )}
              {shopByCategories?.length > 0 &&
                shopByCategories.map((item, index) => (
                  <div key={`shop-category-${index.toString()}`}>
                    {item.products?.length > 0 && (
                      <KitCarousel
                        loading={loading}
                        key={`shop-category-kit-${index.toString()}`}
                        products={item.products}
                        title={item.title}
                        category={item.category}
                      />
                    )}
                  </div>
                ))}
              <Benefits loading={loading} offersData={offers} />
              <KitCarousel
                loading={loading}
                products={products}
                title={title}
                bgImage={Images.BluePrimary}
              />
              {secondSubBanner?.isActive && (
                <>
                  <div className="wb-view">
                    <InfoBanner loading={loading} subBannerData={secondSubBanner} />
                  </div>
                  <div className="mb-view">
                    <InfoBanner loading={loading} isMobile subBannerData={secondSubBanner} />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
Home.propTypes = {
  callHomeApi: PropTypes.func.isRequired,
  callCategoryWiseApi: PropTypes.func.isRequired,
  resetAddToCart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { home } = state;
  return { home };
};

const mapDispatchToProps = (dispatch) => ({
  callHomeApi: () =>
    dispatch({
      type: InnovationTypes.GET_INNOVATION_PRODUCT
    }),
  callCategoryWiseApi: (userId) =>
    dispatch({
      type: InnovationTypes.GET_SHOP_BY_CATEGORY,
      userId
    }),
  resetAddToCart: () =>
    dispatch({
      type: CartTypes.RESET_CART
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
