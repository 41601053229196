import React from 'react';
import String from '../../../../constants/strings';
import './Footer.scss';

function CartFooter() {
  return (
    <div className="app-cart-footer-view ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <p className="footer-copyright">
              &copy;
              {String.FOOTER_COMPANY_TAG}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartFooter;
