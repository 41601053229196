/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Close from '../../../../assets/svgs/Close';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import './Styles.scss';
import { PaymentStatusTypes } from '../../../../reducers/payment/paymentStatus';

function RefundProductsDetail(props) {
  const { item, index } = props;
  const {
    data: {
      success = false,
      data: { transactionId, merchantId, merchantTransactionId, amount } = {}
    } = {}
  } = item;
  const dispatch = useDispatch();
  const { paymentStatus } = useSelector((state) => state);
  const { paymentStatusData: { data = {}, code = '' } = {} } = paymentStatus;
  const isRefund = true;
  const isSuccess = code === 'PAYMENT_SUCCESS';
  useEffect(() => {
    if (success) {
      dispatch({
        type: PaymentStatusTypes.GET_PAYMENT_STATUS,
        transactionId,
        merchantId,
        merchantTransactionId,
        isRefund
      });
    }
  }, []);
  return (
    <div className="app-refund-product-details" key={index?.toString()}>
      <div className="header-section">
        <div className="width-40">
          <div className="header-box">{transactionId}</div>
        </div>
        <div className="width-60 inner-wrap">
          <div className="header-box width-33 details">
            {amount ? `₹${(amount / 100)?.toFixed(2)}` : '₹0'}
          </div>
          <div className="header-box width-33 state">
            {isSuccess ? data?.state : 'Not Initiated'}
          </div>
        </div>
      </div>
      {item?.products?.map((value) => (
        <div key={`products-${value}`} className="product-detail-flex">
          <div className="image">
            <img
              src={generateUrl(value.product?.images ? value.product?.images[0] : '')}
              alt=""
              onError={({ currentTarget }) => {
                const currTrgt = currentTarget;
                currTrgt.onerror = null; // prevents looping
                currTrgt.src = PRODUCT_DEFAULTS.productImage;
              }}
            />
          </div>
          <div className="product-details">
            <div className="name">{value?.product?.name}</div>
            <div className="quantity">{`Quantity: ${value?.cancelledQuantity}`}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
function RefundModal(props) {
  const { show, onClose, refundInfo, paymentMethod } = props;

  const handleOnHideModal = () => {
    onClose();
  };
  const renderMainLayout = () => (
    <div className="app-refund-dialog">
      {refundInfo?.refundStatus &&
        refundInfo?.refundStatus?.length > 0 &&
        refundInfo?.refundStatus?.map((item, index) => (
          <div className="app-refund-product-details" key={index?.toString()}>
            <div className="header-section">
              <div className="width-40">
                <div className="header-box">{item?.payload?.refund?.entity?.id}</div>
              </div>
              <div className="width-60 inner-wrap">
                <div className="header-box width-33 details">{`₹${item?.amount?.toFixed(2)}`}</div>
                <div className="header-box width-33 state">
                  {item?.status && item?.status?.length > 0
                    ? `${item?.status[item.status.length - 1].status}`
                    : ''}
                </div>
                <div className="header-box width-33 details">
                  {item?.status && item?.status?.length > 0
                    ? moment(item?.status[item.status.length - 1].date).format('Do MMM YYYY')
                    : ''}
                </div>
              </div>
            </div>
            {item?.products?.map((value) => (
              <div key={`products-${value}`} className="product-detail-flex">
                <div className="image">
                  <img
                    src={generateUrl(value.product?.images ? value.product?.images[0] : '')}
                    alt=""
                    onError={({ currentTarget }) => {
                      const currTrgt = currentTarget;
                      currTrgt.onerror = null; // prevents looping
                      currTrgt.src = PRODUCT_DEFAULTS.productImage;
                    }}
                  />
                </div>
                <div className="product-details">
                  <div className="name">{value?.product?.name}</div>
                  <div className="quantity">{`Quantity: ${value?.cancelledQuantity}`}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
  const phonePeRefundLayout = () => (
    <div className="app-refund-dialog">
      {refundInfo?.refundInfo &&
        refundInfo?.refundInfo?.refunds?.length > 0 &&
        refundInfo?.refundInfo?.refunds?.map((item, index) => (
          <RefundProductsDetail key={`refund-products-${item}`} item={item} index={index} />
        ))}
    </div>
  );

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleOnHideModal}
      centered
      contentClassName="refund-model">
      <Modal.Body className="refund-modal-body">
        <div className="refund-modal-header-container">
          <div>
            <div className="modal-title-text">Refund Status</div>
            <div className="modal-title-info">
              Total Refund Amount:
              <span>{` ₹${refundInfo?.refundInfo?.totalAmount?.toFixed(2)}`}</span>
            </div>
          </div>

          <button
            aria-label="close"
            type="button"
            onClick={handleOnHideModal}
            className="refund-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>
        <div>{paymentMethod === 'PHONEPE' ? phonePeRefundLayout() : renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
}
RefundModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refundInfo: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired
};
RefundProductsDetail.propTypes = {
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired
};

export default RefundModal;
