import React from 'react';
import PropsTypes from 'prop-types';
import './ConfirmationBox.scss';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Close from '../../../../assets/svgs/Close';

function ConfirmationBox({ show, handleClose, actions, children }) {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <div className="confirmation-modal-container">
          {children}
          <div className="confirmation-model-close">
            <button type="button" aria-label="confirmation-box" onClick={handleClose}>
              <Close />
            </button>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="app-confirm-footer">{actions}</DialogActions>
    </Dialog>
  );
}

ConfirmationBox.propTypes = {
  children: PropsTypes.node.isRequired,
  actions: PropsTypes.node.isRequired,
  handleClose: PropsTypes.func.isRequired,
  show: PropsTypes.bool.isRequired
};

export default ConfirmationBox;
