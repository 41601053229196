import Verify from '../../../../constants/verify';
import { checkMobileNumber } from '../../../../containers/auth/FormValidation';

export const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateAddressForm = (name, mobile, pincode, address, landmark) => {
  let errors = '';
  if (!name) {
    errors = Verify.VERIFY_NAME_EMPTY;
    return errors;
  }
  if (!mobile) {
    errors = Verify.VERIFY_MOBILE_EMPTY;
    return errors;
  }
  if (mobile && mobile?.length < 10) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (mobile && !checkMobileNumber(mobile)) {
    errors = Verify.VERIFY_MOBILE_INVALID;
    return errors;
  }
  if (!pincode) {
    errors = Verify.VERIFY_PINCODE_EMPTY;
    return errors;
  }
  if (pincode.length !== 6) {
    errors = Verify.VERIFY_PINCODE_INVALID;
    return errors;
  }
  if (pincode.length === 6 && pincode === '000000') {
    errors = Verify.VERIFY_PINCODE_INVALID;
    return errors;
  }
  if (!address) {
    errors = Verify.VERIFY_ADDRESS_EMPTY;
    return errors;
  }
  if (!landmark) {
    errors = Verify.VERIFY_LANDMARK_EMPTY;
    return errors;
  }
  return errors;
};
