import React from 'react';

function Info() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="ionicon"
        width={20}
        height={20}
        viewBox="0 0 512 512">
        <path
          d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="32"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M220 220h32v116"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="32"
          d="M208 340h88"
        />
        <path d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z" />
      </svg>
    </div>
  );
}

export default Info;
