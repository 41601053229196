const STATE_CODES = [
  {
    value: 'AN',
    label: 'Andaman and Nicobar Islands'
  },
  {
    value: 'AP',
    label: 'Andhra Pradesh'
  },
  {
    value: 'AR',
    label: 'Arunachal Pradesh'
  },
  {
    value: 'AS',
    label: 'Assam'
  },
  {
    value: 'BR',
    label: 'Bihar'
  },
  {
    value: 'CG',
    label: 'Chandigarh'
  },
  {
    value: 'CH',
    label: 'Chhattisgarh'
  },
  {
    value: 'DH',
    label: 'Dadra and Nagar Haveli'
  },
  {
    value: 'DD',
    label: 'Daman and Diu'
  },
  {
    value: 'DL',
    label: 'Delhi'
  },
  {
    value: 'GA',
    label: 'Goa'
  },
  {
    value: 'GJ',
    label: 'Gujarat'
  },
  {
    value: 'HR',
    label: 'Haryana'
  },
  {
    value: 'HP',
    label: 'Himachal Pradesh'
  },
  {
    value: 'JK',
    label: 'Jammu and Kashmir'
  },
  {
    value: 'JH',
    label: 'Jharkhand'
  },
  {
    value: 'KA',
    label: 'Karnataka'
  },
  {
    value: 'KL',
    label: 'Kerala'
  },
  {
    value: 'LD',
    label: 'Lakshadweep'
  },
  {
    value: 'MP',
    label: 'Madhya Pradesh'
  },
  {
    value: 'MH',
    label: 'Maharashtra'
  },
  {
    value: 'MN',
    label: 'Manipur'
  },
  {
    value: 'ML',
    label: 'Meghalaya'
  },
  {
    value: 'MZ',
    label: 'Mizoram'
  },
  {
    value: 'NL',
    label: 'Nagaland'
  },
  {
    value: 'OR',
    label: 'Odisha'
  },
  {
    value: 'PY',
    label: 'Puducherry'
  },
  {
    value: 'PB',
    label: 'Punjab'
  },
  {
    value: 'RJ',
    label: 'Rajasthan'
  },
  {
    value: 'SK',
    label: 'Sikkim'
  },
  {
    value: 'TN',
    label: 'Tamil Nadu'
  },
  {
    value: 'TS',
    label: 'Telangana'
  },
  {
    value: 'TR',
    label: 'Tripura'
  },
  {
    value: 'UK',
    label: 'Uttarakhand'
  },
  {
    value: 'UP',
    label: 'Uttar Pradesh'
  },
  {
    value: 'WB',
    label: 'West Bengal'
  }
];
export default STATE_CODES;
