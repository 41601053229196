// leave off @2x/@3x
import Banner from '../assets/images/banners.png';
import Insurance from '../assets/images/Insurance.png';
import NotFound from '../assets/images/notFound.jpg';
import BluePrimary from '../assets/images/bluePrimary.png';
import BulkOrder from '../assets/images/bulkOrder.svg';
import BulkOrderBg from '../assets/images/bulkOrderBg.png';
import CheckGreen from '../assets/images/checkGreen.gif';

const Images = {
  Banner,
  Insurance,
  NotFound,
  BluePrimary,
  BulkOrder,
  BulkOrderBg,
  CheckGreen
};

export default Images;
