import Sorting from '../../constants/sort';

const {
  ALL,
  CANCELLED,
  RETURNED,
  DISPATCHED,
  ORDERED,
  ORDER_CANCELLED,
  CONFIRMED,
  REFUND_INITIATED,
  DELIVERED,
  REPLACED,
  REQUESTED_RETURN,
  REQUESTED_REPLACEMENT
} = Sorting;

const statusOptions = [
  { title: ALL, key: 'status', value: null },
  { title: ORDERED, key: 'status', value: ORDERED },
  { title: CANCELLED, key: 'status', value: ORDER_CANCELLED },
  { title: RETURNED, key: 'status', value: REQUESTED_RETURN },
  { title: REPLACED, key: 'status', value: REQUESTED_REPLACEMENT },
  { title: DISPATCHED, key: 'status', value: DISPATCHED },
  { title: CONFIRMED, key: 'status', value: CONFIRMED },
  { title: REFUND_INITIATED, key: 'status', value: REFUND_INITIATED },
  { title: DELIVERED, key: 'status', value: DELIVERED }
];

const daysOptions = [
  { title: 'Last 7 days', key: 'days', value: 7 },
  { title: 'Last 30 days', key: 'days', value: 30 },
  { title: 'Last 6 Months', key: 'days', value: 182.5 },
  {
    title: new Date().getFullYear() - 1,
    key: 'year',
    value: new Date().getFullYear() - 1
  },
  { title: 'older', key: 'year', value: 'older' }
];
export { statusOptions, daysOptions };
