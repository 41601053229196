import React, { useEffect } from 'react';
import moment from 'moment';
import { Link, NavLink, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../unicellular/button';
import RoutesPaths from '../../../../constants/routes';
import STORAGE_KEYS from '../../../../constants/storageKey';
import { PaymentStatusTypes } from '../../../../reducers/payment/paymentStatus';
import './Callback.scss';

export default function PaymentStatus() {
  const dispatch = useDispatch();
  const { merchantId, transactionId } = useParams();
  const { paymentStatus } = useSelector((state) => state);
  const {
    loading,
    orderData = {},
    paymentStatusData = {},
    orderData: { address = {}, user = {}, invoiceId = '', createdAt = '' } = {}
  } = paymentStatus;
  useEffect(() => {
    dispatch({
      type: PaymentStatusTypes.GET_PAYMENT_STATUS,
      transactionId,
      merchantId
    });
  }, []);
  const isSuccess = paymentStatusData?.code === 'PAYMENT_SUCCESS';

  const addressDetails =
    paymentStatusData?.code === 'PAYMENT_SUCCESS'
      ? [address?.street, address?.locality, address?.landmark, address?.city, address?.state]
      : null;
  const handleRedirect = () => {
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
  };
  const renderSuccessFull = () => (
    <div className="callback-inner-body">
      <div className="row">
        <div className="col-md-7 text-container ">
          <div className="callback-section-title">Payment Successful</div>
          <div className="callback-section-sub-title">{paymentStatusData?.message}</div>
        </div>
        <div className="col-md-5">
          <div className="images-section">
            <img
              src="https://image.freepik.com/free-vector/top-up-credit-concept-illustration_114360-7244.jpg?w=740"
              alt=""
            />
          </div>
        </div>
      </div>
      {orderData === null || orderData === undefined ? null : (
        <div className="callback-detail-container">
          <div className="callback-section-sub-title">Your shipment will be delivered to:</div>
          <div className="callback-section-description">
            <span>{user?.name},</span>
            {addressDetails?.join(', ')}
          </div>
          <div className="callback-section-description">
            <span> Mobile: </span>
            {address?.mobile || user?.mobile}
          </div>
          <div className="callback-section-description">
            <span> Order Id: </span>
            {invoiceId}
          </div>
          <div className="callback-section-description">
            <span> Date: </span>
            {moment(createdAt).format('Do MMM YYYY')}
          </div>
        </div>
      )}
      <div className="callback-action-btn-container">
        <NavLink to={`/account/${RoutesPaths.ORDERS}`} onClick={handleRedirect}>
          <Button className="callback-action-btn" buttonText="Go to My Orders" />
        </NavLink>
      </div>
    </div>
  );
  const renderFailure = () => (
    <div className="callback-inner-body">
      <div className="row">
        <div className="col-md-7 text-container ">
          {loading ? (
            <div className="ps-title-ld ps-loading-animation " />
          ) : (
            <div className="callback-section-title">Payment Failure</div>
          )}
          <div className="callback-section-sub-title">{paymentStatusData?.message}</div>
        </div>
        <div className="col-md-5">
          <div className="images-section">
            <img
              src="https://image.freepik.com/free-vector/top-up-credit-concept-illustration_114360-7244.jpg?w=740"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* {invoiceId ? (
        <div className="callback-detail-container">
          <div className="callback-section-description">
            <span> Order Id: </span>
            {invoiceId}
          </div>
        </div>
      ) : null} */}
      {loading ? (
        <div className="callback-action-btn-container">
          <Button className="callback-action-btn" buttonText="Loading ..." />
        </div>
      ) : (
        <div className="callback-action-btn-container">
          <Link to={RoutesPaths.CART}>
            <Button className="callback-action-btn" buttonText="Try Again" />
          </Link>
        </div>
      )}
    </div>
  );
  return (
    <div style={{ height: '100vh' }}>
      <Modal size="lg" show centered contentClassName="callback-modal">
        <Modal.Body className="callback-modal-body">
          {isSuccess ? renderSuccessFull() : renderFailure()}
        </Modal.Body>
      </Modal>
    </div>
  );
}
