import React from 'react';
// import ProgressBar from 'react-bootstrap/ProgressBar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Style.scss';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
// import ProductString from '../../../../constants/strings/product';
// import gstAmount from '../../../../utils/conversions/gstAmount';
import Button from '../../../unicellular/button';
import CartString from '../../../../constants/strings/cart';
import RoutesPaths from '../../../../constants/routes';

export default function PromoCard(props) {
  const navigate = useNavigate();
  const { title, price, image, description, onClick, loadingCart, isAddedToCart, onClickCart } =
    props;
  const { login } = useSelector((state) => state);
  const { isUserLogin } = login;
  const handleRedirection = () => {
    navigate(RoutesPaths.CART);
  };
  return (
    <div className="app-promo-card">
      <button type="button" className="promo-card-flex" onClick={onClick}>
        <img
          src={generateUrl(image)}
          className="image"
          alt={image}
          onError={({ currentTarget }) => {
            const currTrgt = currentTarget;
            currTrgt.onerror = null; // prevents looping
            currTrgt.src = PRODUCT_DEFAULTS.productImage;
          }}
        />
        <div className="card-inner-body">
          <div className="title">{title}</div>
          <div className="detail">
            {Array.isArray(description)
              ? description.slice(0, 3).map((d) => (
                  <div key={`description-${d}`}>
                    {d} <br />
                  </div>
                ))
              : description}
          </div>
        </div>
      </button>

      <div className="btn-price-container">
        <div className="inner-wrap">
          <div className="price">{`₹ ${price}`}</div>
          {/* <div className="gst-number">
            {`₹ ${gstAmount(
              price,
              ProductString.gstPrice,
            )} (+${ProductString.gstPrice}% GST extra)`}

          </div> */}
        </div>
        <div className="inner-wrap">
          {isUserLogin &&
            (isAddedToCart ? (
              <Button
                buttonText={CartString.GO_TO_CART}
                className="app-btn-buy-now"
                onClick={handleRedirection}
                loading={loadingCart}
              />
            ) : (
              <Button
                buttonText={CartString.ADD_TO_CART}
                className="app-btn-buy-now"
                onClick={onClickCart}
                loading={loadingCart}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
PromoCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClickCart: PropTypes.func,
  isAddedToCart: PropTypes.bool,
  onClick: PropTypes.func,
  loadingCart: PropTypes.bool
};
PromoCard.defaultProps = {
  title: '',
  price: 0,
  description: '',
  onClickCart: null,
  onClick: null,
  loadingCart: false,
  isAddedToCart: false
};
