const URLS = {
  BASE_URL: '/api/v1',
  BASE_MEDIA_URL: '/media',
  BASE_VIDEO_URL: '/video/stream/',
  BASE_IMAGE_URL: '/image/stream/',
  API_SCHEDULED_VIDEO_LIST: '/schedular/getScheduledVideos',
  API_ADD_TIMESHEET_LIST: '/timesheet/addTask',
  API_FETCH_TIMESHEET: '/timesheet/getTasks',
  API_DELETE_TIMESHEET: '/timesheet/deleteTask',
  API_UPDATE_TIMESHEET: '/timesheet/updateTask',
  API_USER_LOGIN: '/auth/login',
  API_VIDEO_LIST: '/timesheet/getVideos',
  API_CHANGE_PASSWORD: '/auth/password',
  API_USER_REGISTER: '/auth/register',
  API_CART: '/cart',
  API_BULK_CART: '/cart/bulk',
  API_USER_CART: (userId) => `/cart/user/${userId}`,
  API_SAVED_FOR_LATER: (userId) => `/saved-for-later/user/${userId}`,
  API_DELETE_CART: (cartId) => `/cart/${cartId}`,
  API_DELETE_SAVE_LATER: (cartId) => `/saved-for-later/${cartId}`,
  API_MOVE_CART: (cartId, saveforLaterId) => `/cart/${cartId}/saved-for-later/${saveforLaterId}`,
  API_UPDATE_CART: (cartId) => `/cart/${cartId}`,
  API_ALL_CATEGORY: '/categories',
  API_ADDRESS: '/address',
  API_USER: '/user/',
  API_ADDRESS_PINCODE: '/address/pincode',
  API_DELIVERY_ESTIMATE: '/address/delivery',
  API_USER_OTP: '/auth/verify/otp',
  API_USER_EMAIL_OTP: '/auth/email/verify',
  API_USER_OTP_RESEND: '/auth/checkMobileNumber',
  API_USER_DETAILS: (id) => `/user/${id}`,
  API_PRODUCT_DATA_TABLE: '/products/data-table',
  API_RAZORPAY_CALLBACK: '/payment/razorpayCallback',
  API_FETCH_PRODUCT: (id, userId) => `/products/${id}/user/${userId}`,
  API_FETCH_RELATED_PRODUCTS: (categoryId, productId) =>
    `/categories/${categoryId}/products/${productId}/suggestions`,
  API_FETCH_RECOMMENDED_PRODUCTS: (categoryId, productId) =>
    `/categories/${categoryId}/products/${productId}/related`,
  API_FETCH_FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS: (categoryId, productId) =>
    `/categories/${categoryId}/products/${productId}/frequentlyBoughtTogether`,
  API_GET_PAYMENT_METHOD: '/payment/getPaymentMethods',
  API_ORDER_LIST: '/order',
  API_FETCH_ORDER_DETAIL: (orderId, invoiceId) => `/order/${orderId}/invoice/${invoiceId}`,
  API_CANCEL_ORDER: (orderId) => `order/${orderId}/cancel`,
  API_RETURN_OPTIONS: (orderId) => `order/${orderId}/return-options`,
  API_DOWNLOAD_INVOICE: (orderId) => `/order/${orderId}/download-invoice`,
  API_PRODUCT_DELIVERY: (id) => `/products/${id}/delivery`,
  API_PAYTM_CALLBACK: '/payment/paytmCallback',
  API_FETCH_HOME: '/home',
  API_FETCH_CATEGORY_WISE: '/home/categories',
  API_WISHLIST: '/wishlist',
  API_DELETE_WISHLIST: () => '/wishlist',
  API_FETCH_WISHLIST: (userId) => `/user/${userId}/wishlist`,
  API_SEARCH_SUGGESTIONS: (queryString) => `/products/search/suggestions?q=${queryString}`,
  API_SEARCH_SUGGESTIONS_BULK: (queryString) =>
    `/order/bulk-order/search/suggestions?q=${queryString}`,
  API_FETCH_FAQ_LIST: '/faq',
  API_SUGGEST_PRODUCT: '/products/suggestions',
  API_FETCH_TERMS_OF_USAGES: '/terms-of-use',
  API_FETCH_PRIVACY_POLICY: '/privacy-policy',
  API_FETCH_RETURN_POLICY: '/return-policy',
  API_FETCH_SHIPPING_POLICY: '/shipping-policy',
  API_TESTIMONIALS: '/testimonials',
  API_FETCH_COUPON_LIST: '/coupons',
  API_ADD_IMAGE: '/image/upload',
  API_DELETE_IMAGE: (flieName) => `/image/unlink/${flieName}`,
  API_REVIEW: '/reviews/submit',
  API_DELETE_ALL_IMAGE: (flieName) => `/image/unlink/${flieName}`,
  API_RATING: (size, page) => `/reviews?size=${size}&page=${page}`,
  API_VOTE: (id) => `/reviews/${id}/vote`,
  API_USER_VERIFY_WITH_MOBILE: '/auth/send-otp',
  API_USER_VERIFY_WITH_EMAIL: '/auth/email/send-otp',
  API_USER_OTP_VALIDATE: '/auth/verify-otp',
  API_ALL_CONTACT_US: '/contact-us',
  API_EMAIL_OTP_VERIFY: '/auth/email/verify',
  API_POST_BULK_ORDER: '/order/bulk-order',
  API_GET_PAYMENT_STATUS: '/payment/status',
  API_GET_TIMED_POPUP_DATA: (id) => `/timed-popups?id=${id}`
};

export default URLS;
