import React from 'react';
import { useSelector } from 'react-redux';
import { generateUrl } from '../../utils/ImageUtils';
import Button from '../../components/unicellular/button/Button';

export default function BulkOrderBanner() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { promotion: { bulkOrder = {} } = {} } = {} } = productData;
  return (
    <div>
      {bulkOrder && (
        <div className="d-flex my-5 justify-content-between align-items-center bg-white rounded-5 book-bulk-order">
          {bulkOrder?.images?.length > 0 && bulkOrder?.images[0] && (
            <img src={generateUrl(bulkOrder?.images[0])} alt="" srcSet="" className="" />
          )}
          <div className="bb-info d-flex flex-column align-items-center justify-content-center">
            <div className="bb-text fw-semibold text-center">{bulkOrder?.title}</div>
            {bulkOrder?.action && (
              <a href={bulkOrder?.action?.url} className="bb-link">
                <Button variant="primary">{bulkOrder?.action?.text}</Button>
              </a>
            )}
          </div>
          {bulkOrder?.images?.length > 1 && bulkOrder?.images[1] && (
            <img src={generateUrl(bulkOrder?.images[1])} alt="" srcSet="" className="" />
          )}
        </div>
      )}
    </div>
  );
}
