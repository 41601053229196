/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Button from '../../../components/unicellular/button';
import Rating from '../../../components/unicellular/rating';
// import QuantityBox from '../../../components/multicellular/products/quantity';
import Cart from '../../../assets/svgs/Cart';
import STORAGE_KEYS from '../../../constants/storageKey';
import { DeliveryTypes } from '../../../reducers/info/delivery';
import { CartTypes } from '../../../reducers/cart/cart';
import Wishlist from '../../../components/multicellular/products/wishlist';
import Auth from '../../auth';
import Bag from '../../../assets/svgs/Bag';
import validatePincode from './FormValidation';
import PlaceholderString from '../../../constants/strings/PlaceholderString';
import Bus from '../../../assets/svgs/Bus';
import CartString from '../../../constants/strings/cart';
import RoutesPaths from '../../../constants/routes';
import ProductString from '../../../constants/strings/product';
import discountToPercentage from '../../../utils/conversions/discountToPercentage';
// import gstAmount from '../../../utils/conversions/gstAmount';
import DeliveryDate from '../../../assets/svgs/DeliveryDate';
// import Return from '../../../assets/svgs/Return';
import './ProductDetail.scss';
import SelectDropdown from '../../../components/unicellular/selectDropdown';
import { trackEvent } from '../../../analytics/googleAnalytics';

function Information(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const [pincode, setPincode] = useState('');
  const [err, setError] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quantityOfProduct, setCount] = useState(1);
  const {
    callPincodeApi,
    callAddToCartApi,
    resetAddToCart,
    resetPincode,
    resetIsError,
    resetPincodeReducer
  } = props;
  const {
    information,
    delivery,
    // cart: { isSuccess },
    login,
    login: { user: { mobile } = {} },
    rating,
    address
  } = useSelector((state) => state);
  const { addressData: { content = [] } = {} } = address;
  const activeAddress = content?.find((item) => item.isDefault);
  const {
    loading,
    productData,
    // cartExists = false,
    wishlistExists = false
  } = information;
  const { load, error, errorMsg, pincodeData } = delivery;
  const { isUserLogin } = login;
  const { ratingsData = {} } = rating;
  const { reviews: { avgRating = 0, totalRatings = 0, totalReviews = 0 } = {} } = ratingsData;
  const {
    product: {
      name,
      description,
      whatIsInTheBox = [],
      sku,
      calculatedPrices: { basePrice = 0, netPrice = 0, savedAmount = 0 } = {},
      categoryName,
      wishlist = {},
      isWishlisted = false,
      quantity: productQuantity = {}
    } = {},
    cart = {}
  } = productData;
  const [customQuantity, setCustomQuantity] = useState();
  const [errorQtyMsg, setErrorMsg] = useState('');
  const [errorQty, setErrorQty] = useState(false);
  const { data: { deliveryEstimate } = {} } = pincodeData;

  // useEffect(() => {
  //   const { quantity } = cart;
  //   setCount(quantity || 1);
  // }, []);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={() => setErrorQty(false)}>
      <Tooltip {...props} classes={{ popper: className }} />
    </ClickAwayListener>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }));
  const resetError = () => {
    setError('');
    resetIsError();
  };
  const handleCheck = (e) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regexp.test(e.target.value)) {
      setPincode(e.target.value);
      resetError();
    }
  };
  const isvalidatePincode = () => {
    let errors = '';
    errors = validatePincode(pincode);
    if (errors && errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };
  const onClickChange = () => {
    const valid = isvalidatePincode();
    if (valid) {
      callPincodeApi(id, pincode);
      setError('');
    }
  };
  const handleInput = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      // eslint-disable-next-line no-param-reassign
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    } else if (object.target.value.length === 0) {
      resetError();
      resetPincode();
    }
  };
  const addToCart = () => {
    callAddToCartApi(userId, id, customQuantity || quantityOfProduct, navigate);
  };
  const gotoCart = () => {
    navigate(RoutesPaths.CART);
  };

  const gotoWagon = (productId) => {
    navigate(`${RoutesPaths.CART_WAGON}?item=${productId}`);
  };

  // Check if user is logged in then get the user name from local storage otherwise set it as User
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME) || 'User';
  const userDetails =
    userName === 'User' ? userName : `${userName}${mobile ? `, Ph.No: ${mobile}` : ''}`;

  const handleCart = () => {
    trackEvent(
      'Add to Cart',
      'Product Add to Cart',
      `${userDetails}, clicked on Add to Cart for product ${name}`
    );

    if (!isUserLogin) {
      setIsOpenModal(true);
      sessionStorage.setItem(STORAGE_KEYS.SESSION_PRODUCT_ID, id);
    } else {
      addToCart();
      gotoWagon(id);
    }
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
  };
  const handleBuyNow = () => {
    trackEvent(
      'Buy Now',
      'Product Buy Now',
      `${userDetails}, clicked on Buy Now for product ${name}`
    );

    if (!isUserLogin) {
      setIsOpenModal(true);
    } else {
      if (cart) {
        const { quantity } = cart;
        if (!quantity || quantity === 0) {
          addToCart();
        }
      } else {
        addToCart();
      }
      gotoCart();
    }
    if (id && id !== '') {
      sessionStorage.setItem(STORAGE_KEYS.PRODUCT_ID, id);
    }
  };
  useEffect(() => {
    if (isUserLogin) {
      setIsOpenModal(false);
      if (activeAddress?.pincode) {
        setPincode(activeAddress?.pincode);
        callPincodeApi(id, activeAddress?.pincode);
      }
    }

    resetAddToCart();
    resetPincode();
  }, [isUserLogin, wishlistExists, activeAddress]);

  useEffect(() => {
    if (!isUserLogin) {
      setPincode('');
      resetPincode();
    }
    return () => {
      resetPincodeReducer();
    };
  }, []);
  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      onClickChange();
    }
  };
  const handleCustomQuantity = (e) => {
    setErrorQty(false);
    const numberPattern = /^[1-9]\d*$/;
    if (e.target.value === '' || numberPattern.test(e.target.value)) {
      if (productQuantity && e.target.value !== '') {
        // eslint-disable-next-line radix
        if (parseInt(e.target.value) < parseInt(productQuantity?.maximum) + 1) {
          setCustomQuantity(e.target.value);
        } else {
          setErrorQty(true);
          setErrorMsg(`Maximum quantity of this product per order is ${productQuantity?.maximum}.`);
        }
      } else {
        setCustomQuantity(e.target.value);
      }
    }
  };
  return (
    <div>
      <div className="app-product-detail-info-wrapper">
        <div className="app-product-info-detail">
          <div className="title">{loading ? '' : name}</div>
          <div className="description">{loading ? '' : categoryName}</div>
          {avgRating > 0 && (
            <a href="#reviews">
              <div className="rating-detail-wrapper">
                <div className="rating-box">
                  <Rating avgRating={avgRating} />
                </div>
                <div className="rating-text">
                  {`${totalRatings} ratings & ${totalReviews} Reviews`}
                </div>
              </div>
            </a>
          )}
        </div>
        <div className="wishlist-btn-wrapper">
          <Wishlist
            className="wishlist-btn"
            productId={id}
            productName={name}
            userDetails={userDetails}
            setIsOpenAuthModal={setIsOpenModal}
            wishlist={wishlist}
            isWishlisted={isWishlisted}
            isHideSnackbar={false}
          />
        </div>
      </div>
      <div className="app-product-shipping-wrapper">
        <div className="app-product-price-wrapper">
          {!loading && (
            <div>
              <span className="net-price">{`₹ ${netPrice}`}</span>
              <span className="base-price">
                {basePrice === netPrice ? '' : `MRP: ₹ ${basePrice}`}
              </span>
              <span className="discount">
                {savedAmount === 0 ? '' : `(${discountToPercentage(savedAmount, basePrice)}% off)`}
              </span>
            </div>
          )}
          <div>
            <span className="gst-tag">{ProductString.TAX_INCLUSIVE}</span>
            {/* <span className="gst-number">
              {`₹ ${gstAmount(
                basePrice,
                ProductString.gstPrice,
              )} (+${ProductString.gstPrice}% GST extra)`}

            </span> */}
          </div>
        </div>
        <div className="app-product-info-wrapper">
          <div className="section-title title-bar">{ProductString.PRODUCT_DETAIL_TITLE}</div>
          <div className="sku-wrapper">
            <span className="sku-title">{`${ProductString.PRODUCT_SKU_TITLE}: `}</span>
            <span className="sku-description">{loading ? '' : sku}</span>
          </div>
          <div className="section-description">
            {description && description?.length > 0 && Array.isArray(description) ? (
              description?.map((item, index) => (
                <p className="description" key={index.toString() + item}>
                  {item}
                </p>
              ))
            ) : (
              <p className="description">{description}</p>
            )}
          </div>
        </div>
        <div className="app-delivery-detail-wrapper">
          <div className="app-delivery-flexbox">
            <div className="title">{ProductString.QUANTITY}</div>

            {quantityOfProduct > 9 ? (
              <div>
                <HtmlTooltip
                  title={<div className="cart-error-tooltip">{`${errorQtyMsg}`}</div>}
                  onClose={() => setErrorQty(false)}
                  open={errorQty}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener>
                  <div className="qty-input">
                    <input
                      type="text"
                      maxLength={3}
                      onBlur={() => {
                        if (!customQuantity) {
                          setCustomQuantity(1);
                        }
                      }}
                      autoFocus
                      placeholder="Qty"
                      value={customQuantity}
                      onChange={handleCustomQuantity}
                    />
                  </div>
                </HtmlTooltip>
              </div>
            ) : (
              <div>
                <SelectDropdown
                  placeholder="Qty"
                  onChange={(changedValue) => {
                    setCount(changedValue.value);
                    setCustomQuantity(changedValue.value);
                  }}
                  options={Array.from([...Array(10).keys()].map((x) => x + 1)).map((e) => ({
                    label: e === 10 ? `${e}+` : `${e}`,
                    value: `${e}`
                  }))}
                  value={[{ label: quantityOfProduct, value: quantityOfProduct }]}
                  isSearchable={false}
                  isStyled
                  closeMenuOnSelect
                />
              </div>
            )}
          </div>
        </div>
        <div className="app-product-detail-btn-wrapper">
          <div className="app-btn-view-wrapper">
            <div
              className="app-btn-view"
              title={(error || err !== '') && ProductString.DISABLE_TOOLTIP}>
              <Button
                buttonText={ProductString.BUY_NOW}
                disabled={error || err !== ''}
                isIcon
                icon={<Bag />}
                className={error || err !== '' ? 'app-buy-now-btn-disable' : 'app-buy-now-btn'}
                onClick={error || err !== '' ? null : handleBuyNow}
              />
            </div>
            <div
              className="app-btn-view"
              title={(error || err !== '') && ProductString.DISABLE_TOOLTIP}>
              <Button
                buttonText={CartString.ADD_TO_CART}
                isIcon
                disabled={error || err !== ''}
                icon={<Cart color="#24379E" />}
                className={
                  error || err !== '' ? 'app-cart-btn app-cart-btn-disable' : 'app-cart-btn'
                }
                onClick={error || err !== '' ? null : handleCart}
              />
            </div>
          </div>
        </div>

        <div className="app-delivery-detail-wrapper">
          <div className="app-delivery-flexbox">
            <div className="title">{ProductString.SHIP_TO}</div>
            <div className="pincode-input">
              <input
                type="text"
                value={pincode}
                maxLength={6}
                onInput={handleInput}
                onChange={handleCheck}
                onKeyPress={handleKeypress}
                placeholder={PlaceholderString.PINCODE}
              />
              {pincode?.length === 6 && (
                <button type="button" className="change-btn" onClick={onClickChange}>
                  {load ? 'Checking' : 'Check'}
                </button>
              )}
            </div>
          </div>
          {err !== '' && <div className="app-information-error">{err}</div>}
          {error ? (
            <div className="app-information-error">{errorMsg}</div>
          ) : (
            <div className="app-delivery-flexbox">
              {deliveryEstimate && (
                <div className="title">
                  <DeliveryDate />
                </div>
              )}
              {deliveryEstimate && (
                <div className="detail">
                  Get it by
                  {'  '}
                  <span>
                    {load ? '...' : moment().add(deliveryEstimate, 'd').format('Do MMM')}
                  </span>{' '}
                </div>
              )}
            </div>
          )}
          <div className="app-delivery-flexbox">
            <div className="title">
              <Bus />
            </div>
            <div className="detail">
              Free shipping on orders above <span>₹ 500/-</span>
            </div>
          </div>
          {/* <div className="app-delivery-flexbox">
            <div className="title">
              <Return />
            </div>
            <div className="detail">
              Easy
              {' '}
              <span>30 days</span>
              {' '}
              return
            </div>
          </div> */}
        </div>
      </div>
      {whatIsInTheBox?.length > 0 && (
        <div className="app-product-info-wrapper">
          <div className="section-title title-bar">What is in the box?</div>
          <ul className="section-list-view">
            {whatIsInTheBox?.map((item, index) => (
              <li className="list-item" key={`whats-in-box${index.toString()}`}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      {isOpenModal && !isUserLogin && <Auth show={isOpenModal} setShow={setIsOpenModal} />}
    </div>
  );
}
Information.propTypes = {
  callPincodeApi: PropTypes.func.isRequired,
  callAddToCartApi: PropTypes.func.isRequired,
  resetAddToCart: PropTypes.func.isRequired,
  resetPincode: PropTypes.func.isRequired,
  resetIsError: PropTypes.func.isRequired,
  resetPincodeReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { information, delivery, rating } = state;
  return { information, delivery, rating };
};

const mapDispatchToProps = (dispatch) => ({
  callPincodeApi: (id, pincode) =>
    dispatch({
      type: DeliveryTypes.ADD_PINCODE,
      id,
      pincode
    }),
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    }),
  resetAddToCart: () =>
    dispatch({
      type: CartTypes.RESET_CART
    }),
  resetPincode: () => {
    dispatch({ type: DeliveryTypes.RESET_PINCODE });
  },
  resetPincodeReducer: () => {
    dispatch({ type: DeliveryTypes.RESET_PINCODE_REDUCER });
  },
  resetIsError: () => {
    dispatch({ type: DeliveryTypes.RESET_ERROR });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Information);
