import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const CategoryTypes = mirrorKeyValue([
  'GET_ALL_CATEGORY',
  'GET_ALL_CATEGORY_INDICATOR',
  'GET_ALL_CATEGORY_SUCCESS',
  'GET_ALL_CATEGORY_FAILED'
]);

const {
  GET_ALL_CATEGORY,
  GET_ALL_CATEGORY_INDICATOR,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAILED
} = CategoryTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  categoryData: {},
  error: '',
  errorMsg: ''
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_ALL_CATEGORY:
      return { ...state, ...data };
    case GET_ALL_CATEGORY_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: action.response,
        error: false
      };
    case GET_ALL_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message
      };
    default:
      return state;
  }
}
