import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Close from '../../../../assets/svgs/Close';

import './Callback.scss';
import Button from '../../../unicellular/button';
import RoutesPaths from '../../../../constants/routes';
import STORAGE_KEYS from '../../../../constants/storageKey';

export default function Callback(props) {
  const { show, onClose, address, isSuccess, transactionDetail, title } = props;
  const {
    invoiceId = '',
    responseMessage = '',
    txnDate = '',
    message = '',
    user: { name, mobile } = {}
  } = transactionDetail;
  const addressDetails = [
    address?.street,
    address?.locality,
    address?.landmark,
    address?.city,
    address?.state
  ];
  const handleRedirect = () => {
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
  };
  const renderSuccessFull = () => (
    <div className="callback-inner-body">
      <div className="row">
        <div className="col-md-7 text-container ">
          <div className="callback-section-title">{title}</div>
          <div className="callback-section-sub-title">{responseMessage}</div>
        </div>
        <div className="col-md-5">
          <div className="images-section">
            <img
              src="https://image.freepik.com/free-vector/top-up-credit-concept-illustration_114360-7244.jpg?w=740"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="callback-detail-container">
        <div className="callback-section-sub-title">Your shipment will be delivered to:</div>
        <div className="callback-section-description">
          <span>{name},</span>
          {addressDetails?.join(', ')}
        </div>
        <div className="callback-section-description">
          <span> Mobile: </span>
          {address?.mobile || mobile}
        </div>
        <div className="callback-section-description">
          <span> Order Id: </span>
          {invoiceId}
        </div>
        <div className="callback-section-description">
          <span> Date: </span>
          {moment(txnDate).format('Do MMM YYYY')}
        </div>
      </div>
      <div className="callback-action-btn-container">
        <NavLink to={`/account/${RoutesPaths.ORDERS}`} onClick={handleRedirect}>
          <Button className="callback-action-btn" buttonText="Go to My Orders" />
        </NavLink>
      </div>
    </div>
  );
  const renderFailure = () => (
    <div className="callback-inner-body">
      <div className="row">
        <div className="col-md-7 text-container ">
          <div className="callback-section-title">{title}</div>
          <div className="callback-section-sub-title">{message}</div>
        </div>
        <div className="col-md-5">
          <div className="images-section">
            <img
              src="https://image.freepik.com/free-vector/top-up-credit-concept-illustration_114360-7244.jpg?w=740"
              alt=""
            />
          </div>
        </div>
      </div>
      {invoiceId ? (
        <div className="callback-detail-container">
          <div className="callback-section-description">
            <span> Order Id: </span>
            {invoiceId}
          </div>
        </div>
      ) : null}
      <div className="callback-action-btn-container">
        <Button className="callback-action-btn" buttonText="Try Again" onClick={onClose} />
      </div>
    </div>
  );
  return (
    <Modal
      size="lg"
      show={show}
      onHide={!isSuccess ? onClose : null}
      centered
      backdrop={isSuccess ? 'static' : 'dynamic'}
      contentClassName="callback-modal">
      <Modal.Body className="callback-modal-body">
        <div className="callback-modal-header-container">
          {!isSuccess && (
            <button aria-label="close" type="button" onClick={onClose}>
              <Close color="#666" />
            </button>
          )}
        </div>
        {isSuccess ? renderSuccessFull() : renderFailure()}
      </Modal.Body>
    </Modal>
  );
}
Callback.propTypes = {
  show: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transactionDetail: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    txnDate: PropTypes.string,
    responseMessage: PropTypes.string,
    message: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      mobile: PropTypes.string.isRequired
    })
  }).isRequired,
  address: PropTypes.arrayOf(
    PropTypes.shape({
      street: PropTypes.string.isRequired,
      locality: PropTypes.string.isRequired,
      landmark: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired
};
