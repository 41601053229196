import React from 'react';

export default function Loader() {
  return (
    <div className="app-address-card">
      <div className="loading-view">
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  );
}
