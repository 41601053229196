/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '../../../unicellular/button';
import Close from '../../../../assets/svgs/Close';
import Edit from '../../../../assets/svgs/Edit';
import { couponTypes } from '../../../../reducers/payment/coupon';
import STORAGE_KEYS from '../../../../constants/storageKey';
import { CartTypes } from '../../../../reducers/cart/cart';
import './Coupon.scss';
import { trackEvent } from '../../../../analytics/googleAnalytics';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

function Coupon(props) {
  const { callGetCouponApi, priceSummary, callApplyCouponApi, userDetails } = props;
  const [show, setShow] = useState(false);
  const [activeCouponTerm, setActiveCouponTerm] = useState();
  const [isTermsModalActive, setTermsModalActive] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const onClose = () => {
    const { resetCouponCodeReducer } = props;
    setShow(false);
    setTermsModalActive(false);
    setInterval(() => {
      resetCouponCodeReducer();
    }, 3000);
  };
  const onOpen = () => {
    trackEvent(
      'Payment',
      'Apply Coupon',
      `${userDetails} clicked on apply coupon button in Cart page`
    );
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callGetCouponApi(userId, priceSummary?.totalPricePayable);
    setShow(true);
  };
  const { coupon, cart } = useSelector((state) => state);
  const { loading, error, couponData: { coupons = [], message } = {} } = coupon;
  const { isCouponAppliedSuccess, isCouponAppliedError, couponAppliedErrorMsg } = cart;

  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callGetCouponApi(userId, priceSummary?.totalPricePayable);
    if (isCouponAppliedSuccess) {
      onClose();
    }
  }, [isCouponAppliedSuccess]);

  const couponCode = sessionStorage.getItem(STORAGE_KEYS.COUPON_CODE);
  const handleCheck = (e) => {
    setIsCheck(false);
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(e.target.value)) {
      setCouponValue(e.target.value);
    }
    if (e.target.value?.length === 0) {
      callGetCouponApi(userId, priceSummary?.totalPricePayable);
    } else {
      setIsCheck(true);
      callGetCouponApi(userId, priceSummary?.totalPricePayable, e.target.value);
    }
  };
  const handleCheckCoupon = () => {
    setIsCheck(true);
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callGetCouponApi(userId, priceSummary?.totalPricePayable, couponValue);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCheckCoupon();
    }
  };
  const removeCoupon = () => {
    const { callRemoveCoupon } = props;
    sessionStorage.removeItem(STORAGE_KEYS.IS_COUPON_CODE);
    sessionStorage.removeItem(STORAGE_KEYS.COUPON_CODE);
    callRemoveCoupon();
  };
  const handleApplyCoupon = (item, summary) => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callApplyCouponApi(
      userId,
      true,
      item.couponCode,
      summary.totalPricePayable,
      summary.totalPrice,
      summary.totalSavedAmount
    );
    sessionStorage.setItem(STORAGE_KEYS.COUPON_CODE, item.couponCode);
    sessionStorage.setItem(STORAGE_KEYS.IS_COUPON_CODE, true);
  };
  const handleCollapse = (term) => {
    setActiveCouponTerm(term);
    setTermsModalActive(true);
  };
  const handleCollapseClose = () => {
    setActiveCouponTerm('');
    setTermsModalActive(false);
  };
  const renderCouponList = (item) => (
    <div
      className={`app-counpon-list-container ${item?.message && 'error-border'}`}
      key={item?.couponCode}>
      <div>
        <div className="coupon-title">{item?.title}</div>
        <div className="coupon-description">{item?.description}</div>
      </div>
      <div className="coupon-list-action-container">
        <div className="coupon-text">{item?.couponCode}</div>
        {item?.message ? null : (
          <button
            type="button"
            onClick={() => handleApplyCoupon(item, priceSummary)}
            className="apply-btn-text">
            APPLY
          </button>
        )}
      </div>
      {item?.message ? (
        <div className="coupon-description-er">{item?.message}</div>
      ) : (
        item?.terms &&
        item?.terms?.length > 0 && (
          <div className="mt-4">
            <div className="coupon-terms-and-condition">
              <button type="button" className="layout" onClick={() => handleCollapse(item?.terms)}>
                <div className="text">Terms and Conditions</div>
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
  const renderloading = () => (
    <div className="app-counpon-list-container">
      <div>
        <div className="coupon-title-ld" />
        <div className="coupon-description-ld" />
      </div>
    </div>
  );
  const checkCouponExpired = (couponsArr) =>
    couponsArr.filter((obj) => !Object.prototype.hasOwnProperty.call(obj, 'message'));
  const renderMainLayout = () => (
    <div className="coupon-modal-body-container">
      <div className="coupon-modal-input-view">
        <div className="coupon-input">
          <input
            type="text"
            value={couponValue}
            // onInput={handleInput}
            onChange={handleCheck}
            placeholder="Enter Coupon"
            onKeyPress={handleKeyPress}
            maxLength={12}
          />
          <button
            type="button"
            disabled={couponValue?.length === 0}
            className="change-btn"
            onClick={handleCheckCoupon}>
            Check
          </button>
        </div>
      </div>
      <div className="app-coupon-list-body ">
        {loading ? (
          <div>{renderloading()}</div>
        ) : couponValue?.length === 0 ? (
          (coupons.length > 0 && checkCouponExpired(coupons)?.length > 0 && (
            <div>{checkCouponExpired(coupons)?.map((item) => renderCouponList(item))}</div>
          )) || (
            <div className="app-counpon-list-container-error">
              <div>
                <div className="coupon-title-er">Coupon not available</div>
                <div className="coupon-description-er">
                  {couponValue?.length === 0
                    ? 'No other coupons available'
                    : (error && message) || 'Sorry this coupon is not valid for this user account'}
                </div>
              </div>
            </div>
          )
        ) : (
          (isCheck && coupons.length > 0 && (
            <div>{coupons?.map((item) => renderCouponList(item))}</div>
          )) || (
            <div className="app-counpon-list-container-error">
              <div>
                <div className="coupon-title-er">Coupon not available</div>
                <div className="coupon-description-er">
                  {couponValue?.length === 0
                    ? 'No other coupons available'
                    : (error && message) || 'Sorry this coupon is not valid for this user account'}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );

  const renderTermModal = () => (
    <div className="coupon-modal-body-container">
      <div className="coupon-terms-and-condition">
        <div className="layout">
          <div className="text-bg">Terms and Conditions</div>
          <button type="button" onClick={() => handleCollapseClose()}>
            <div className="hide">Hide</div>
          </button>
        </div>
      </div>
      <div className="collapsible-layout app-counpon-list-container">
        <div className="collapsible-text" dangerouslySetInnerHTML={{ __html: activeCouponTerm }} />
      </div>
    </div>
  );
  const renderModal = () => (
    <Modal
      size="md"
      show={show}
      onHide={() => {
        setCouponValue('');
        onClose();
      }}
      centered
      contentClassName="coupon-modal">
      <Modal.Body className="coupon-modal-body">
        <div className="coupon-modal-header-container">
          <div>Coupons</div>
          <button
            aria-label="close"
            type="button"
            onClick={() => {
              setCouponValue('');
              onClose();
            }}
            className="coupon-modal-close-btn">
            <Close color="#666" />
          </button>
        </div>

        <div>{isTermsModalActive ? renderTermModal() : renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
  return (
    <div className="coupon-btn-modal">
      {sessionStorage.getItem(STORAGE_KEYS.IS_COUPON_CODE) === 'true' ||
      sessionStorage.getItem(STORAGE_KEYS.IS_COUPON_CODE) ? (
        <div className="coupon-display-box">
          {couponCode}
          <button aria-label="close" type="button" className="close-btn" onClick={removeCoupon}>
            <Close />
          </button>
          <button aria-label="edit" type="button" onClick={onOpen} className="edit-btn">
            <Edit />
          </button>
        </div>
      ) : (
        <Button buttonText="Apply" onClick={onOpen} />
      )}
      {show && renderModal()}
      <Snackbar
        open={isCouponAppliedError}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          <div className="alet-message-success">{couponAppliedErrorMsg}</div>
        </Alert>
      </Snackbar>
      <Snackbar
        open={isCouponAppliedSuccess}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          <div className="alet-message-success">Coupon applied successfully</div>
        </Alert>
      </Snackbar>
    </div>
  );
}
Coupon.propTypes = {
  callGetCouponApi: PropTypes.func.isRequired,
  priceSummary: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  callApplyCouponApi: PropTypes.func.isRequired,
  resetCouponCodeReducer: PropTypes.func.isRequired,
  callRemoveCoupon: PropTypes.func.isRequired
};
Coupon.defaultProps = {};

const mapStateToProps = (state) => {
  const { coupon } = state;
  return { coupon };
};

const mapDispatchToProps = (dispatch) => ({
  callGetCouponApi: (userId, totalPayablePrice, couponCode) =>
    dispatch({
      type: couponTypes.FETCH_COUPON_LIST,
      userId,
      totalPayablePrice,
      couponCode
    }),
  resetCouponCodeReducer: () => dispatch({ type: CartTypes.RESET_COUPON_CODE }),
  callRemoveCoupon: () => dispatch({ type: CartTypes.GET_USER_CART_FETCH }),
  callApplyCouponApi: (userId, isCouponApplied, couponCode, netPrice, basePrice, savedAmount) =>
    dispatch({
      type: CartTypes.GET_USER_CART_FETCH,
      userId,
      isCouponApplied,
      couponCode,
      netPrice,
      basePrice,
      savedAmount
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
