/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '../../components/unicellular/button';
import InputField from '../../components/unicellular/inputField';
import Product from '../../assets/svgs/Product';
import PlaceholderString from '../../constants/strings/PlaceholderString';
import { validateAddressForm } from './FormValidation';
import STATE_CODES from '../../config/order/StateCodes';
import PRODUCT_DEFAULTS from '../../constants/defaults/product';
import { BulkProductTypes } from '../../reducers/product/productBulkList';
import { ProductTypes } from '../../reducers/product/productList';
import SearchIcon from '../../assets/svgs/SearchIcon';
import { generateUrl } from '../../utils/ImageUtils';
import './Styles.scss';
import embedScripts from '../../utils/scripts/recaptcha';
import { BulkOrderTypes } from '../../reducers/cart/bulkOrder';
import SuccessModal from '../../components/multicellular/general/successModal';
import DeleteIcon from '../../assets/svgs/Delete';
import String from '../../constants/strings';
import ConfirmationBox from '../../components/multicellular/cart/confirmationBox';
import CreatableDropdown from '../../components/unicellular/creatableDropdown';

const formValue = {
  name: '',
  mobile: '',
  email: ''
};
// const renderProducts = (item, index) => (
//   <div className="bulk-search-product-wapper" tabIndex={index}>
//     <div className="bulk-search-product-image-container">
//       <img
//         src={generateUrl(item?.images ? item?.images[0] : '')}
//         alt={item.name}
//         onError={({ currentTarget }) => {
//           const currTrgt = currentTarget;
//           currTrgt.onerror = null; // prevents looping
//           currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
//           currTrgt.className = 'img-error';
//         }}
//       />
//     </div>
//     <div className="product-details-wrapper">
//       <div className="bulk-search-product-name">{item.name}</div>
//       <div className="bulk-search-product-description">{item.description}</div>
//     </div>
//   </div>
// );

function ProductDetail(props) {
  const { product } = props;
  const { images, name, description } = product;
  return (
    <div className="bulk-search-product-wapper">
      <div className="bulk-search-product-image-container">
        <img
          src={generateUrl(images ? images[0] : '')}
          alt={name}
          onError={({ currentTarget }) => {
            const currTrgt = currentTarget;
            currTrgt.onerror = null; // prevents looping
            currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
            currTrgt.className = 'img-error';
          }}
        />
      </div>
      <div className="product-details-wrapper">
        <div className="bulk-search-product-name">{name}</div>
        <div className="bulk-search-product-description">{description}</div>
      </div>
    </div>
  );
}

const LazyProductDetail = React.lazy(() => Promise.resolve({ default: ProductDetail }));

const calculatePrice = (price, quantity) => (parseFloat(price) * quantity).toFixed(2);
const renderBulkProducts = (item, index, quantity, onClick) => (
  <div className="bsp-container" tabIndex={index}>
    <div className="product-image">
      <img
        src={item?.images ? generateUrl(item?.images[0]) : ''}
        alt={item.name}
        onError={({ currentTarget }) => {
          const currTrgt = currentTarget;
          currTrgt.onerror = null; // prevents looping
          currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
          currTrgt.className = 'img-error';
        }}
      />
    </div>
    <div className="product-detail">
      <button aria-label="delete" type="button" className="delete-btn" onClick={onClick}>
        <DeleteIcon />
      </button>
      <div className="product-name">{item.name}</div>
      <div className="product-description">{`Quantity: ${quantity}`}</div>
      <div className="product-price">
        {`₹ ${calculatePrice(item?.price, quantity)}`}
        {quantity > 1 ? <span>{` (${item?.price} / Unit)`}</span> : null}
      </div>
    </div>
  </div>
);
const renderNFBulkProducts = (item, index, quantity, onClick) => (
  <div className="bsp-container" tabIndex={index}>
    <div className="product-image">
      <Product />
    </div>
    <div className="product-detail">
      <div className="product-name">{item.name}</div>
      <div className="product-description">{`Quantity: ${quantity}`}</div>
      <button aria-label="delete" type="button" className="delete-btn" onClick={onClick}>
        <DeleteIcon />
      </button>
    </div>
  </div>
);
function Form(props) {
  const { callProductApi } = props;
  const dispatch = useDispatch();

  /* states */
  const [viewDropdown, setViewDropdown] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeProduct, setActiveProduct] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [err, setError] = useState('');
  const [errorForProductAdd, setErrorForProductAdd] = useState('');
  const [purchaserInfo, setPurchaserInfo] = useState(formValue);

  /* Reducers */
  const { productList, address, bulkOrder } = useSelector((state) => state);
  const { productListArr: products, loading: productLoading, isMoreProducts } = productList;

  const [page, setPage] = useState(1);
  const observer = useRef();
  const lastProductElementRef = useRef();

  useEffect(() => {
    if (productLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && isMoreProducts) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastProductElementRef.current) {
      observer.current.observe(lastProductElementRef.current);
    }
  }, [productLoading]);

  const { isSuccessFullyVerified, pincodeData, pincodeerror, pincodeerrorMsg } = address;

  const { loading, error, errorMsg, isSuccessFullOrdered } = bulkOrder;

  const initialState = () => {
    setViewDropdown(false);
    setInputValue('');
    setActiveProduct({});
    setSelectedProducts([]);
    setQuantity(1);
    setError('');
    setPurchaserInfo(formValue);
  };

  const changewithDebounce = (state) => {
    setTimeout(() => setViewDropdown(state), 500);
  };
  const onChangeQuantity = (changedQuantity) => {
    setQuantity(changedQuantity.value);
  };
  const onClickAddProduct = () => {
    setError('');
    setErrorForProductAdd('');
    const arrayProducts = selectedProducts;
    if (activeProduct && Object.keys(activeProduct)?.length > 0) {
      const arr = {
        _source: activeProduct?._source,
        name: inputValue?._source?.name,
        id: activeProduct?._source?.mongoId,
        quantity
      };
      const found = arrayProducts.some((el) => el.id === activeProduct?._source?.mongoId);
      if (!found) {
        arrayProducts?.push(arr);
        setSelectedProducts([...arrayProducts]);
        setActiveProduct({});
        setQuantity(1);
        setInputValue('');
      } else {
        setErrorForProductAdd('Product is already added');
      }
    } else {
      const arr = {
        name: inputValue,
        quantity
      };
      const found = arrayProducts.some((el) => el.name === inputValue);
      if (!found) {
        arrayProducts?.push(arr);
        setSelectedProducts([...arrayProducts]);
        setActiveProduct({});
        setQuantity(1);
        setInputValue('');
      } else {
        setErrorForProductAdd('Product is already added');
      }
    }
  };
  // functions
  useEffect(() => {
    if (isSuccessFullyVerified) {
      const { content: { stateCode = '' } = {} } = pincodeData;
      const fetchStateFromStateCode = STATE_CODES.find((x) => x.value === stateCode)?.label;
      setPurchaserInfo({ ...purchaserInfo, state: fetchStateFromStateCode });
    }
  }, [pincodeData]);

  useEffect(() => {
    if (isSuccessFullOrdered) {
      initialState();
    }

    return function () {
      return null;
    };
  }, [isSuccessFullOrdered]);

  const toggleCaptchaBadge = (show) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    embedScripts(
      'recaptcha-key',
      'https://www.google.com/recaptcha/api.js?render=6Leuq4EmAAAAAFCY-ddXcNfqtmYrRcVN8tRszi7W',
      () => {
        console.warn('Script loaded!');
      }
    );
  }, []);

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  const handleNameChange = (e) => {
    const regex = /^[A-Za-z ]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPurchaserInfo({ ...purchaserInfo, name: e.target.value });
      setError('');
    }
  };
  const handleMobileChange = (event) => {
    const regexp = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (event.target.value === '' || regexp.test(event.target.value)) {
      setPurchaserInfo({ ...purchaserInfo, mobile: event.target.value });
    }
    setError('');
  };

  const handleEmailChange = (event) => {
    setPurchaserInfo({ ...purchaserInfo, email: event.target.value });
    setError('');
  };

  // handleProductSelect
  const handleProductSelect = (prod) => {
    setActiveProduct(prod);
  };
  const isValidate = () => {
    let errors = '';
    errors = validateAddressForm(purchaserInfo, selectedProducts);
    if (errors !== '') {
      setError(errors);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const filterMap = {
        searchParams: inputValue
      };
      const sortMap = { name: 'asc' };
      callProductApi(filterMap, sortMap, false, page, null, 15);
    };

    fetchProducts();
  }, [page, inputValue]);

  const onChangeSearch = (searchValue) => {
    dispatch({ type: ProductTypes.RESET_PRODUCTS });
    setPage(1);
    setInputValue(searchValue.target?.value);
    if (!viewDropdown) {
      changewithDebounce(!viewDropdown);
    }
    setErrorForProductAdd('');
  };

  const handleDelete = (name, isUniqueProduct) => {
    const arr = selectedProducts;
    const index = isUniqueProduct
      ? arr.findIndex((x) => x.name === name)
      : arr.findIndex((x) => x?._source?.name === name);
    arr.splice(index, 1);
    setSelectedProducts([...arr]);
    setDeleteConfirmation(false);
  };

  // Submit functionality
  const submitForm = () => {
    const valid = isValidate();
    // const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);

    if (valid && !pincodeerror) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Leuq4EmAAAAAFCY-ddXcNfqtmYrRcVN8tRszi7W', {
            action: 'submit'
          })
          .then((token) => {
            const params = {
              purchaserInfo,
              shippingInfo: purchaserInfo,
              comment: '',
              gRecaptchaToken: token,
              products: selectedProducts
            };
            dispatch({ type: BulkOrderTypes.ADD_BULK_ORDER_LIST, params });
          });
      });
      setError('');
    }
  };
  const renderPurchaserLayout = () => (
    <div className="bulk-order-form-body-container">
      <div className="bulk-order-form-input-view">
        <div>Purchaser’s Information</div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="bulk-order-form-input-view">
            <InputField
              placeholder={PlaceholderString.NAME}
              value={purchaserInfo?.name}
              maxLength={60}
              onChange={handleNameChange}
              className="bulk-order-form-input"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="bulk-order-form-input-view">
            <InputField
              value={purchaserInfo?.mobile}
              maxLength={10}
              minLength={10}
              onChange={handleMobileChange}
              placeholder={PlaceholderString.ADDRESS_MOBILE_NUMBER}
              className="bulk-order-form-input"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="bulk-order-form-input-view">
            <InputField
              value={purchaserInfo?.email}
              maxLength={60}
              onChange={handleEmailChange}
              placeholder={`${PlaceholderString.EMAIL_ADDRESS} (Optional)`}
              className="bulk-order-form-input"
            />
          </div>
        </div>
      </div>
    </div>
  );
  const isValidNewOption = (validInputValue) =>
    /^\d+$/.test(validInputValue) && validInputValue?.length > 3 && validInputValue?.length < 5;
  const renderProductLayout = () => (
    <div className="bulk-order-form-body-container">
      <div className="bulk-order-form-input-view">
        <div>Product details</div>
      </div>
      <div className="bulk-order-form-display">
        <div className="row">
          {selectedProducts &&
            selectedProducts?.length > 0 &&
            selectedProducts?.map((item, index) => (
              <div className="col-md-3 col-sm-6" key={index?.toString()}>
                <div className="bulk-order-selected-box">
                  <div className="inner-item-box">
                    {item?._source && Object.keys(item?._source)?.length > 0
                      ? renderBulkProducts(item?._source, index, item?.quantity, () =>
                          setDeleteConfirmation(true)
                        )
                      : renderNFBulkProducts(item, index, item?.quantity, () =>
                          handleDelete(item?.name, true)
                        )}
                    <ConfirmationBox
                      title="Wanna Delete ?"
                      show={deleteConfirmation}
                      handleClose={() => setDeleteConfirmation(false)}
                      actions={
                        <div className="action-wrapper">
                          <Button
                            className="action-btn"
                            buttonText="Remove"
                            onClick={() =>
                              item?._source && Object.keys(item?._source)?.length > 0
                                ? handleDelete(item?._source?.name, false)
                                : handleDelete(item?.name, true)
                            }
                          />
                        </div>
                      }>
                      <div className="bo-confirmation-details">
                        <div className="bulk-order-image-container-confirmation">
                          <img
                            src={item?._source?.images ? generateUrl(item?._source?.images[0]) : ''}
                            alt={item?._source?.name}
                            onError={({ currentTarget }) => {
                              const currTrgt = currentTarget;
                              currTrgt.onerror = null; // prevents looping
                              currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
                              currTrgt.className = 'img-error';
                            }}
                            className="image"
                          />
                        </div>
                        <div className="confirmation-details-text">
                          <div className="confirmation-details-text-heading">Remove from list</div>
                          <div className="confirmation-details-text-label">
                            Are you sure you want to remove this item from list?
                          </div>
                        </div>
                      </div>
                    </ConfirmationBox>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="bulk-order-item-box">
          <Box sx={{ width: 1 }}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="inner-item-box">
                  <div className="item-title">Product name</div>
                  <div className="item-input">
                    <InputField
                      isIcon
                      icon={<SearchIcon />}
                      className="bulk-order-form-input"
                      placeholder="Search Item"
                      value={inputValue}
                      onFocus={() => changewithDebounce(true)}
                      onBlur={() => changewithDebounce(false)}
                      onChange={onChangeSearch}
                    />
                    <div className="bulk-search-product-view">
                      {viewDropdown && products?.length > 0 ? (
                        <div className="bulk-search-product-container" ref={lastProductElementRef}>
                          {products.map((p, index) => (
                            <button
                              type="button"
                              key={`search-result-${index.toString()}`}
                              aria-label="Select product"
                              onClick={() => handleProductSelect(p)}>
                              <Suspense fallback={<div>Loading more products...</div>}>
                                <LazyProductDetail product={p?._source} key={index?.toString()} />
                              </Suspense>
                            </button>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="inner-item-box">
                  <div className="item-title">Quantity</div>
                  <div>
                    <div className="item-dropdown">
                      <CreatableDropdown
                        placeholder="Qty"
                        onChange={(changedValue) => onChangeQuantity(changedValue)}
                        options={Array.from([...Array(999).keys()].map((x) => x + 1)).map((e) => ({
                          label: `${e}`,
                          value: `${e}`
                        }))}
                        value={{ label: quantity, value: quantity }}
                        isSearchable
                        isStyled
                        closeMenuOnSelect
                        isValidNewOption={isValidNewOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="note">{String.QUANTITY_NOTE}</div>
              </div>
              <div className="col-sm-12 col-md-3">
                <Button
                  buttonText="+ Add Product"
                  onClick={onClickAddProduct}
                  disabled={inputValue?.length === 0 && Object.keys(activeProduct)?.length === 0}
                  className="bul-order-add-product-btn"
                />
              </div>
              <div className="col-sm-12">
                {activeProduct && Object.keys(activeProduct)?.length > 0 && (
                  <div className="inner-item-box">
                    <div className="item-input">
                      <LazyProductDetail
                        product={activeProduct?._source}
                        key={activeProduct?._id}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>

          {errorForProductAdd !== '' && (
            <div className="bulk-order-form-error-message">{errorForProductAdd}</div>
          )}
        </div>
      </div>
    </div>
  );
  function roundToTwoDecimalPlaces(number) {
    const factor = 100;
    const roundedNumber = Math.round(number * factor) / factor;
    return roundedNumber;
  }
  function totalPrice() {
    return selectedProducts.reduce((accumulator, currentValue) => {
      const amount =
        parseFloat(currentValue?._source?.price || 0).toFixed(2) *
        parseFloat(currentValue.quantity);
      return roundToTwoDecimalPlaces(accumulator + amount);
    }, 0);
  }
  return (
    <div className="bulk-order-form-body">
      <div className="bulk-order-form-header-container">
        <div className="header-text">
          Please fill in the required information for your bulk enquiry
        </div>
      </div>
      <div>{renderPurchaserLayout()}</div>
      <div>{renderProductLayout()}</div>
      <div className="bulk-order-form-footer-container">
        <div>
          {pincodeerror ? (
            <div className="bulk-order-form-error-message">{pincodeerrorMsg}</div>
          ) : (
            <>
              {err !== '' && <div className="bulk-order-form-error-message">{err}</div>}
              {error && <div className="bulk-order-form-error-message">{errorMsg}</div>}
            </>
          )}
        </div>
        {totalPrice() !== 0 && (
          <div className="bo-total-price">
            <span>Total Price: </span>
            {`₹ ${totalPrice()}`}
          </div>
        )}
        <Button
          buttonText="Submit"
          className="bo-submit-btn"
          loading={loading}
          onClick={submitForm}
        />
        <SuccessModal
          show={isSuccessFullOrdered}
          onClose={() => {
            dispatch({ type: BulkOrderTypes.RESET_REDUCER });
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </div>
  );
}

Form.propTypes = {
  // searchProduct: PropTypes.func.isRequired,
  callProductApi: PropTypes.func.isRequired
};

ProductDetail.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

const mapStateToProps = (state) => {
  const { address } = state;
  return { address };
};

const mapDispatchToProps = (dispatch) => ({
  callProductApi: (filterMap, sortMap, isFiltered, page, userId, size) =>
    dispatch({
      type: ProductTypes.GET_PRODUCT,
      filterMap,
      sortMap,
      isFiltered,
      page,
      userId,
      size
    }),
  searchProduct: (queryString) =>
    dispatch({
      type: BulkProductTypes.BULK_PRODUCT_SEARCH_FETCH,
      queryString
    })
});
export default connect(mapStateToProps, mapDispatchToProps)(Form);
