/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from 'react-bootstrap/Accordion';

import { connect, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import DownArrow from '../../../../assets/svgs/DownArrow';
import Routes from '../../../../constants/routes';
import Close from '../../../../assets/svgs/Close';
import SearchField from '../../../unicellular/searchField';
import YuvipepLogo from '../../../../assets/svgs/YuvipepLogo';
import { CategoryTypes } from '../../../../reducers/category/allCategory';
import { ProductTypes } from '../../../../reducers/product/productList';
import { FilterTypes } from '../../../../reducers/filter/filter';
import './Header.scss';

function Sidebar(props) {
  const { onClose, callAllCategory, resetProductApi, resetFilterApi } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { allCategory } = useSelector((state) => state);
  const { categoryData: { content = [] } = {} } = allCategory;

  useEffect(() => {
    callAllCategory();
  }, []);

  const handleSearch = (querySearch) => {
    onClose();
    const encodeText = encodeURIComponent(querySearch);
    navigate(`/category?q=${encodeText}`, { replace: true });
  };
  const searchPath = new URLSearchParams(location.search).get('q') || '';

  const handleReset = () => {
    resetProductApi();
    resetFilterApi();
  };
  const handleClickLink = (productId) => {
    handleReset();
    navigate(`${Routes.PRODUCT_EXPLORER}/${productId}`);
    onClose();
  };

  const renderMenu = (array) =>
    array.map((item, index) =>
      item.children && item.children.length > 0 ? (
        <Accordion key={`accordion-${index.toString()}`} className="mobile-accordion">
          {/* <AccordionSummary
          expandIcon={<DownArrow />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>{item.name}</div>
        </AccordionSummary>
        <AccordionDetails>{renderMenu(item.children)}</AccordionDetails> */}
          <Accordion.Item eventKey={item.name}>
            <Accordion.Header>
              <div>{item.name}</div>
            </Accordion.Header>
            <Accordion.Body>{renderMenu(item.children)}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <button
          aria-label="accodion"
          key={`accordion-btn-${index.toString()}`}
          type="button"
          className="menu-items"
          onClick={() => handleClickLink(item._id)}>
          <div>{item.name}</div>
        </button>
      )
    );

  const renderMobileNav = () => (
    <div>
      <NavLink to={Routes.HOME}>
        <div className="side-bar-logo">
          <YuvipepLogo />
        </div>
      </NavLink>
      <NavLink to={`${Routes.SEARCH}`} onClick={onClose}>
        <SearchField
          onSearch={(e) => handleSearch(e)}
          value={searchPath}
          onCloseSidebar={onClose}
        />
      </NavLink>
      <ul className="side-bar-ul-list">
        <NavLink to={Routes.HOME} onClick={onClose}>
          <li className="app-sidebar-link sidebar-active-link">Home</li>
        </NavLink>
        <li className="sidebar-divider " />
        <li className="app-sidebar-link ">New at YuviPep</li>
        {content && content?.length > 0 && (
          <li>
            <li className="sidebar-divider " />
            <div className="app-sidebar-link sidebar-active-link">All Categories</div>
            <div className="app-sidebar-link sidebar-dropdown">{renderMenu(content)}</div>
          </li>
        )}
      </ul>
    </div>
  );

  return (
    <div className="siderbar-continer sidebar-active">
      <div
        className="sidebar-list-container"
        // onClick={(e) => {
        //   e.preventDefault();
        // }}
      >
        {renderMobileNav()}
      </div>
      <button aria-label="close" type="button" className="sidebar-close-button" onClick={onClose}>
        <Close color="#333" />
      </button>
    </div>
  );
}

Sidebar.propTypes = {
  callAllCategory: PropTypes.func.isRequired,
  resetProductApi: PropTypes.func.isRequired,
  resetFilterApi: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { allCategory } = state;
  return { allCategory };
};

const mapDispatchToProps = (dispatch) => ({
  callAllCategory: () => dispatch({ type: CategoryTypes.GET_ALL_CATEGORY }),
  resetProductApi: () => dispatch({ type: ProductTypes.GET_PRODUCT_RESET }),
  resetFilterApi: () => dispatch({ type: FilterTypes.RESET_FILTER_API_CALL })
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
