import React from 'react';
import { useSelector } from 'react-redux';

export default function Description() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { bookDetails: { longDescription } = {} } = {} } = productData;
  return longDescription ? (
    <div className="mt-5">
      <h3 className="text-secondary-300 fs-2 fw-semibold">Description</h3>
      <div className="fw-light">{longDescription}</div>
    </div>
  ) : null;
}
