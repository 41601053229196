import mirrorKeyValue from 'mirror-key-value';
/* ------------- LoginType ------------- */

export const RegisterTypes = mirrorKeyValue([
  'ADD_USER',
  'ADD_USER_INDICATOR',
  'ADD_USER_SUCCESS',
  'ADD_USER_FAILED',
  'UPDATE_OTP_VIEW_STATE',
  'RESET_REGISTER_VIEW',
  'RESET_ERROR_MSG',
  'GET_USER_FORM_DATA'
]);

const {
  ADD_USER,
  ADD_USER_INDICATOR,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  UPDATE_OTP_VIEW_STATE,
  RESET_REGISTER_VIEW,
  RESET_ERROR_MSG,
  GET_USER_FORM_DATA
} = RegisterTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: false,
  registerData: [],
  error: '',
  errorMsg: '',
  isRegisterSuccess: false,
  userFormData: {}
};

/* ------------- Reducer ------------- */

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case ADD_USER:
      return { ...state, ...data };
    case ADD_USER_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isRegisterSuccess: false
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerData: action.response,
        error: false,
        isRegisterSuccess: true
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isRegisterSuccess: false
      };
    case UPDATE_OTP_VIEW_STATE:
      return {
        ...state,
        isRegisterSuccess: action.otpView
      };
    case RESET_REGISTER_VIEW:
      return {
        ...state,
        isRegisterSuccess: false,
        errorMsg: '',
        error: false
      };
    case RESET_ERROR_MSG:
      return {
        ...state,
        errorMsg: '',
        error: false
      };
    case GET_USER_FORM_DATA:
      return {
        ...state,
        userFormData: action.formData
      };
    default:
      return state;
  }
}
