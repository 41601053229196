import React from 'react';
import { useSelector } from 'react-redux';
import { generateUrl } from '../../utils/ImageUtils';

export default function NewChapters() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { bookDetails } = {} } = productData;
  return (
    <section id="book-chapter-section">
      <div className="book-chapter-bg my-5">
        <div className="bcn-header">
          <div className="bc-section-title fw-semibold">
            {bookDetails?.chapters?.details?.title}
          </div>
        </div>
        <div className="bc-body d-flex flex-column position-relative all-chapter-section">
          {bookDetails?.chapters?.data.map((item, index) => {
            return (
              <div
                key={item.title}
                className={`chapter-container ${index % 2 === 0 ? '' : 'reverse'}`}>
                <div className="chapter-text">
                  <h2 className="fs-2 fw-semibold">{item.title}</h2>
                  <div className="fw-light bcb-description">{item.description}</div>
                </div>
                <div className="chapter-image">
                  <img src={generateUrl(item.image)} alt={item.title} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="bc-footer">
          <div className="d-flex justify-content-between align-items-end">
            {bookDetails?.chapters?.details?.footerImages?.map((item) => {
              return <img src={generateUrl(item)} alt={item} key={item} className="bc-icon" />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
