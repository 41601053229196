import React from 'react';
import { useSelector } from 'react-redux';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { generateUrl } from '../../utils/ImageUtils';

function StarRating({ rating }) {
  const fullStars = Math.floor(Number(rating));
  const hasHalfStar = rating % 1 !== 0;

  return (
    <span className="d-flex gap-1">
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar key={index} color="#FEE45A" />
      ))}
      {hasHalfStar && <FaStarHalfAlt color="#FEE45A" />}
    </span>
  );
}

function Courses() {
  const { information } = useSelector((state) => state);
  const { productData } = information;
  const { product: { promotion } = {} } = productData;
  return promotion?.course ? (
    <div
      className="mt-5 bg-gradient-velvet d-flex flex-column align-items-center gap-4 rounded-4 p-5 text-white flex-lg-row course-promotion"
      style={{ backgroundImage: `url(${generateUrl(promotion?.course?.backgroundImage)})` }}>
      <div className="cp-text">
        <h4 className="cp-text fs-2">
          {promotion?.course?.title}
          {promotion?.course?.logo && (
            <span className="cp-logo">
              <img src={generateUrl(promotion?.course?.logo)} alt="logo" srcSet="" />
            </span>
          )}
        </h4>
        <div className="fs-4">{promotion?.course?.description}</div>
        <div className="fs-4 fw-light d-flex align-items-center gap-lg-3 mt-3 flex-column flex-lg-row">
          <span className="d-flex gap-3 align-items-center">
            {promotion?.course?.ratings?.totalRating}{' '}
            <StarRating rating={promotion?.course?.ratings?.totalRating} />
          </span>

          <span>{`(${promotion?.course?.ratings?.numberOfRatings})`}</span>
          <span>{promotion?.course?.totalStudents}</span>
        </div>
      </div>
      <div className="w-full cp-wp">
        <a
          href={promotion?.course?.url}
          target="_blank"
          className="d-flex align-items-center justify-content-center gap-3 p-3 cp-button fs-3"
          rel="noreferrer">
          Register Now
        </a>
      </div>
    </div>
  ) : null;
}

StarRating.propTypes = {
  rating: PropTypes.string.isRequired
};

export default Courses;
