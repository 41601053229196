import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall, makePostApiCall, makePatchApiCall } from '../../adapters';
import { InfoTypes } from '../../reducers/info/information';
import { DeliveryTypes } from '../../reducers/info/delivery';
import { SimiliarTypes } from '../../reducers/info/similarproduct';
import { RecommendedTypes } from '../../reducers/info/recommended';
import { FrequentlyBoughtTogetherTypes } from '../../reducers/info/frequentlyBoughtTogether';
import { VoteTypes } from '../../reducers/vote/vote';
import { getRequest } from '../../adapters/request-page';
import { TestimonialsTypes } from '../../reducers/info/testimonial';
import String from '../../constants/strings';
import URLS from '../../constants/urls';
import { RatingTypes } from '../../reducers/info/rating';

function* getProductInfo(payload) {
  try {
    const { id, userId, productIds } = payload;
    yield put({ type: InfoTypes.FETCH_PRODUCT_INDICATOR });
    const params = {};
    const response = yield call(makeGetApiCall, URLS.API_FETCH_PRODUCT(id, userId), params);
    if (response.status === 200) {
      yield put({
        type: InfoTypes.FETCH_PRODUCT_SUCCESS,
        response: response.data
      });
      const { product: { _id, parentId } = {} } = response.data;
      yield put({
        type: SimiliarTypes.GET_SIMILIAR_PRODUCT,
        categoryId: parentId,
        productId: _id,
        userId,
        productIds
      });
      yield put({
        type: RecommendedTypes.GET_RECOMMENDED_PRODUCT,
        categoryId: parentId,
        productId: _id,
        userId,
        productIds
      });
      yield put({
        type: FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS,
        categoryId: parentId,
        productId: _id,
        userId,
        productIds
      });
      yield put({ type: TestimonialsTypes.FETCH_TESTIMONIALS, productId: _id });
      yield put({ type: RatingTypes.FETCH_RATING, productId: _id, userId });
      yield put({ type: VoteTypes.GET_VOTE, userId });
    } else {
      yield put({
        type: InfoTypes.FETCH_PRODUCT_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: InfoTypes.FETCH_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}

/* -----Get Pin Code -------- */
function* checkPinCode(payload) {
  try {
    yield put({ type: DeliveryTypes.ADD_PINCODE_INDICATOR });
    const { id } = payload;
    const params = {
      pincode: payload.pincode
    };
    const response = yield call(makePostApiCall, URLS.API_PRODUCT_DELIVERY(id), params);
    if (response.status === 200) {
      yield put({
        type: DeliveryTypes.ADD_PINCODE_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: DeliveryTypes.ADD_PINCODE_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: DeliveryTypes.ADD_PINCODE_FAILED,
      message: String.LOADINGERROR
    });
  }
}
/* -----Get Similiar Product -------- */
function* getSimiliarProduct(payload) {
  try {
    const { userId } = payload;
    yield put({ type: SimiliarTypes.GET_SIMILIAR_PRODUCT_INDICATOR });
    const response = yield call(
      makePostApiCall,
      URLS.API_FETCH_RECOMMENDED_PRODUCTS(payload.categoryId, payload.productId),
      {
        ...getRequest(),
        userId,
        productIds: payload.productIds,
        categoryIds: payload.categoryIds
      }
    );
    if (response.status === 200) {
      yield put({
        type: SimiliarTypes.GET_SIMILIAR_PRODUCT_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: SimiliarTypes.GET_SIMILIAR_PRODUCT_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: SimiliarTypes.GET_SIMILIAR_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}
/* get Recommended Products */
function* getRecommendedProducts(payload) {
  try {
    const { userId } = payload;
    yield put({ type: RecommendedTypes.GET_RECOMMENDED_PRODUCT_INDICATOR });
    const response = yield call(
      makePostApiCall,
      URLS.API_FETCH_RELATED_PRODUCTS(payload.categoryId, payload.productId),
      {
        ...getRequest(),
        userId,
        productIds: payload.productIds,
        categoryIds: payload.categoryIds
      }
    );
    if (response.status === 200) {
      yield put({
        type: RecommendedTypes.GET_RECOMMENDED_PRODUCT_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: RecommendedTypes.GET_RECOMMENDED_PRODUCT_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: RecommendedTypes.GET_RECOMMENDED_PRODUCT_FAILED,
      message: String.LOADINGERROR
    });
  }
}
/* get Frequently bought together Products */
function* getFrequentlyBoughtTogetherProducts(payload) {
  try {
    const { userId } = payload;
    yield put({
      type: FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_INDICATOR
    });
    const response = yield call(
      makePostApiCall,
      URLS.API_FETCH_FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS(payload.categoryId, payload.productId),
      {
        userId,
        productIds: payload.productIds,
        categoryIds: payload.categoryIds
      }
    );
    if (response.status === 200) {
      yield put({
        type: FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getTestimonials(payload) {
  try {
    yield put({ type: TestimonialsTypes.FETCH_TESTIMONIALS_INDICATOR });
    const { productId } = payload;
    const params = {
      productId
    };
    const response = yield call(makePostApiCall, URLS.API_TESTIMONIALS, params);
    if (response.status === 200) {
      yield put({
        type: TestimonialsTypes.FETCH_TESTIMONIALS_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: TestimonialsTypes.FETCH_TESTIMONIALS_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: TestimonialsTypes.FETCH_TESTIMONIALS_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* getRating(payload) {
  try {
    yield put({ type: RatingTypes.FETCH_RATING_INDICATOR });
    const { productId, userId } = payload;
    const params = {
      userId,
      productId
    };
    const getParam = {
      size: payload?.size || 10,
      page: payload?.page || 1
    };
    const response = yield call(
      makePostApiCall,
      URLS.API_RATING(getParam.size, getParam.page),
      params
    );
    if (response.status === 200) {
      yield put({
        type: RatingTypes.FETCH_RATING_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: RatingTypes.FETCH_RATING_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: RatingTypes.FETCH_RATING_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* getProductsVote(payload) {
  try {
    const { reviewId } = payload;
    const params = {
      userId: payload.userId,
      isLiked: payload.isLiked,
      isRemoved: payload.isRemoved
    };
    yield put({ type: VoteTypes.GET_VOTE_INDICATOR });
    const response = yield call(makePatchApiCall, URLS.API_VOTE(reviewId), params);
    if (response.status === 200) {
      yield put({
        type: VoteTypes.GET_VOTE_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: VoteTypes.GET_VOTE_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: VoteTypes.GET_VOTE_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* infoSaga() {
  try {
    yield all([
      takeLatest(InfoTypes.FETCH_PRODUCT, getProductInfo),
      takeLatest(DeliveryTypes.ADD_PINCODE, checkPinCode),
      takeLatest(SimiliarTypes.GET_SIMILIAR_PRODUCT, getSimiliarProduct),
      takeLatest(RecommendedTypes.GET_RECOMMENDED_PRODUCT, getRecommendedProducts),
      takeLatest(
        FrequentlyBoughtTogetherTypes.GET_FREQUENTLY_BOUGHT_TOGETHER_PRODUCTS,
        getFrequentlyBoughtTogetherProducts
      ),
      takeLatest(TestimonialsTypes.FETCH_TESTIMONIALS, getTestimonials),
      takeLatest(RatingTypes.FETCH_RATING, getRating),
      takeLatest(VoteTypes.GET_VOTE, getProductsVote)
    ]);
  } catch (ex) {
    console.warn('Error while Info Saga: InfoSaga', ex);
  }
}
export default infoSaga;
