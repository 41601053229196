import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import YuvipepLogo from '../../../../assets/svgs/YuvipepLogo';
import { RegisterTypes } from '../../../../reducers/auth/register/register';
import { LoginTypes } from '../../../../reducers/auth/login/login';
import { CartTypes } from '../../../../reducers/cart/cart';
import { OtpTypes } from '../../../../reducers/auth/otp/otp';
import { LoginWithMobileTypes } from '../../../../reducers/auth/login/loginWithMobile';
import HorizontalLinearStepper from '../../cart/stepper';
import Images from '../../../../themes/Image';
import './Header.scss';
import RoutesPaths from '../../../../constants/routes';
import Button from '../../../unicellular/button';
import STORAGE_KEYS from '../../../../constants/storageKey';

function renderStepper(currentPath) {
  return (
    <div>
      <HorizontalLinearStepper currentPath={currentPath} />
    </div>
  );
}
function renderSecureText() {
  return (
    <div className="app-cart-header-secure-text">
      <div className="img-secure">
        <img src={Images.Insurance} alt="" />
      </div>
      <div className="app-secure-text">100% secure</div>
    </div>
  );
}

function CartHeader(props) {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const { getUserCart, children } = props;
  const handleClose = () => {
    setOpenRemoveDialog(false);
  };
  const handleOpen = () => {
    setOpenRemoveDialog(true);
    sessionStorage.removeItem(STORAGE_KEYS.PRODUCT_ID);
  };
  const handleConfirmDelete = () => {
    navigate(RoutesPaths.HOME);
    setOpenRemoveDialog(false);
  };
  useEffect(() => {
    getUserCart();
  }, []);
  const renderConfirm = () => (
    <Dialog
      open={openRemoveDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {/* <DialogTitle id="alert-dialog-title">
        <div className="ch-confirm-text-title"> Navigate Confirmation</div>
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ch-confirm-text-description">
            Are you sure you want to leave the page?
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="app-confirm-footer">
        <Button
          buttonText="Stay in Checkout"
          className="ch-app-action-btn-cancel"
          onClick={handleClose}
        />
        <Button
          buttonText="Return to Home"
          className="ch-app-action-btn-delete"
          onClick={handleConfirmDelete}
        />
      </DialogActions>
    </Dialog>
  );
  const renderLogo = () => (
    <div className="app-logo">
      <button aria-label="Logo" type="button" onClick={handleOpen}>
        <YuvipepLogo color="#FFFFFF" />
      </button>
    </div>
  );

  return (
    <div className="app-header-fx">
      {children}
      <div className="app-store-header app-cart-header-mb app-cart-bg">
        <div className="container">
          <div className="web-view-header">
            <div className="cart-header-container">
              {renderLogo()}
              {renderConfirm()}
              {renderStepper(currentPath)}
              {renderSecureText()}
            </div>
          </div>
          <div className="mb-view-header">
            <div className="cart-header-container">
              {renderLogo()}
              {renderSecureText()}
            </div>
            <div>{renderStepper(currentPath)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
CartHeader.propTypes = {
  getUserCart: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

const mapStateToProps = (state) => {
  const { register, login } = state;
  return { register, login };
};

const mapDispatchToProps = (dispatch) => ({
  resetRegisterReducer: () =>
    dispatch({
      type: RegisterTypes.RESET_REGISTER_VIEW
    }),
  resetOtpReducer: () =>
    dispatch({
      type: OtpTypes.RESET_OTP_VIEW
    }),
  resetLoginReducer: () =>
    dispatch({
      type: LoginTypes.RESET_LOGIN_VIEW
    }),
  resetuserLoginState: () =>
    dispatch({
      type: LoginTypes.SET_USER_LOGIN
    }),
  resetLoginWithMobile: (mobile) =>
    dispatch({
      type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW,
      mobile
    }),
  getUserCart: () => {
    dispatch({ type: CartTypes.GET_USER_CART_FETCH });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CartHeader);
