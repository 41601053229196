/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import SearchInputField from './SearchInputField';
import InputField from '../inputField';
import SearchIcon from '../../../assets/svgs/SearchIcon';
// import PRODUCT_DEFAULTS from '../../../constants/defaults/product';
import { ProductTypes } from '../../../reducers/product/productList';
// import { generateUrl } from '../../../utils/ImageUtils';
import RoutesPaths from '../../../constants/routes';
import './SearchField.scss';
import { FilterTypes } from '../../../reducers/filter/filter';

function SearchField(props) {
  const {
    productList: { searchResults = [] }
  } = useSelector((state) => state);
  const {
    onSearch,
    setSearched,
    searchProduct,
    value,
    onCloseSidebar,
    resetFilterApi,
    resetProductApi
  } = props;
  const [viewDropdown, setViewDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const changewithDebounce = (state) => {
    setTimeout(() => setViewDropdown(state), 500);
  };
  const searchWithDebounce = _.debounce((searchQuery) => {
    onSearch(searchQuery);
  }, 500);
  const handleReset = () => {
    resetFilterApi();
    resetProductApi();
  };
  const handleChange = (searchValue) => {
    if (!viewDropdown) {
      changewithDebounce(!viewDropdown);
    }
    setInputValue(searchValue.target?.value);
    searchProduct(searchValue.target?.value);
  };
  return (
    <div className="search-field-container">
      <div className="sf-web">
        <SearchInputField
          isIcon
          icon={<SearchIcon />}
          onPress={(e) => {
            handleReset();
            changewithDebounce(false);
            searchWithDebounce(e.target.value);
            setSearched(true);
          }}
          onClick={() => {
            handleReset();
            changewithDebounce(false);
            searchWithDebounce(inputValue);
            setSearched(true);
          }}
          onClickClear={() => {
            setInputValue('');
          }}
          placeholder="Search..."
          value={inputValue}
          onFocus={() => changewithDebounce(true)}
          onBlur={() => changewithDebounce(false)}
          onChange={handleChange}
        />
      </div>
      <div className="sf-mb">
        <InputField
          isIcon
          icon={<SearchIcon />}
          onPress={(e) => {
            changewithDebounce(false);
            searchWithDebounce(e.target.value);
            setSearched(true);
          }}
          placeholder="Search..."
          defaultValue={value}
          onFocus={() => changewithDebounce(true)}
          onBlur={() => changewithDebounce(false)}
          onChange={(searchValue) => {
            if (!viewDropdown) {
              changewithDebounce(!viewDropdown);
            }

            searchProduct(searchValue.target?.value);
          }}
        />
      </div>

      {viewDropdown && searchResults.length > 0 ? (
        // && searchResults[0]
        // && searchResults[0].options.length > 0
        <div className="search-product-container">
          {/* <div className="other-search">All Others</div> */}
          {/* {(searchResults[0] ? searchResults[0].options : []).map( */}
          {searchResults.map(({ _source: item }, index) => (
            <NavLink
              key={`nav-link-${index.toString()}`}
              to={`${RoutesPaths.PRODUCT_EXPLORER}?q=${item.value}`}
              onClick={() => {
                handleReset();
                changewithDebounce(false);
                searchWithDebounce(item.value);
                setSearched(true);
                onCloseSidebar();
                setInputValue(item.value);
                window.scrollTo(0, 0);
              }}>
              {/* <div className="search-product-wapper" tabIndex={index}> */}
              {/* <div className="search-product-image-container">
                      <img
                        src={generateUrl(item.images ? item.images[0] : '')}
                        alt={item.name}
                        onError={({ currentTarget }) => {
                          const currTrgt = currentTarget;
                          currTrgt.onerror = null; // prevents looping
                          currTrgt.src = PRODUCT_DEFAULTS.productImageBig;
                          currTrgt.className = 'img-error';
                        }}
                      />
                    </div> */}
              <div className="product-details-wrapper">
                <div className="search-product-name">{item.value}</div>
                {/* <div className="search-product-name">{item.name}</div>
                      <div className="search-product-description">{item.description}</div> */}
              </div>
              {/* </div> */}
            </NavLink>
          ))}
        </div>
      ) : null}
    </div>
  );
}

SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  searchProduct: PropTypes.func.isRequired,
  setSearched: PropTypes.func.isRequired,
  onCloseSidebar: PropTypes.func,
  resetProductApi: PropTypes.func.isRequired,
  resetFilterApi: PropTypes.func.isRequired
};
SearchField.defaultProps = {
  onCloseSidebar: () => null
};

const mapStateToProps = (state) => {
  const { productList } = state;
  return { productList };
};

const mapDispatchToProps = (dispatch) => ({
  searchProduct: (queryString) =>
    dispatch({
      type: ProductTypes.PRODUCT_SEARCH_FETCH,
      queryString
    }),
  setSearched: (isSearched) =>
    dispatch({
      type: ProductTypes.SET_SEARCH_PRODUCT,
      isSearched
    }),
  resetProductApi: () => dispatch({ type: ProductTypes.RESET_PRODUCTS }),
  resetFilterApi: () => dispatch({ type: FilterTypes.RESET_INITIAL_FILTER })
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
