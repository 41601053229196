import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../../themes/Image';
import './Error.scss';

export default function Error(props) {
  const { title, description } = props;
  return (
    <div className="page-not-found-container">
      <div className="page-not-found-box">
        <div className="row">
          <div className="col-md-6 page-not-found-img">
            <img src={Images.NotFound} alt="" srcSet="" />
          </div>
          <div className=" col-md-6 page-not-found-textbox">
            <p className="page-not-found-title">{title}</p>
            <p className="page-not-found-description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
Error.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
Error.defaultProps = {
  title: "Oops!! You're lost",
  description: 'The page you are looking for does not exist.'
};
