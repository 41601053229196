import React from 'react';

const n = 2;
export default function Loader() {
  return (
    <div className="app-myorderpage">
      <div className="container loading-view">
        <div className="container title-ld loading-animation" />
        <div>
          <div className="app-od-top-space" />
          <div>
            <div className="container align-container">
              <div className="row top-space-row">
                <div className="col-md-2">
                  <div className="image loading-animation" />
                </div>
                <div className="col-md-6 ">
                  <div />
                  <div className="row">
                    {[...Array(n)].map((item, index) => (
                      <span
                        className="title-ld loading-animation "
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
