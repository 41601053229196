/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import discountToPercentage from '../../../../utils/conversions/discountToPercentage';
import './ProductCard.scss';
// import gstAmount from '../../../../utils/conversions/gstAmount';
// import ProductString from '../../../../constants/strings/product';

export default function ProductCard(props) {
  const { title, description, calculatedPrices, loading, image } = props;
  const { basePrice, netPrice, savedAmount } = calculatedPrices;
  const renderLoading = () => (
    <div className="app-productcard-container">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  );
  return loading ? (
    renderLoading()
  ) : (
    <div className="app-productcard-container">
      <img
        src={generateUrl(image)}
        alt=""
        srcSet=""
        onError={({ currentTarget }) => {
          const currTrgt = currentTarget;
          currTrgt.onerror = null; // prevents looping
          currTrgt.src = PRODUCT_DEFAULTS.productImage;
        }}
        className="image"
      />
      <div className="detail-body">
        <div className="title-container">
          <div className="title">{title}</div>
          <div className="price">
            {`₹ ${netPrice}`}
            {basePrice !== netPrice && <span className="base-price">{`₹ ${basePrice}`}</span>}
            {savedAmount !== 0 && (
              <span className="discount">{`(${discountToPercentage(savedAmount, basePrice)}% off)`}</span>
            )}
          </div>
        </div>
        {/* <span className="gst-number">{`₹ ${gstAmount(basePrice, ProductString.gstPrice)}
            (+${ProductString.gstPrice}% GST extra)`}</span> */}
        <div className="detail">{description}</div>
      </div>
    </div>
  );
}
ProductCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loading: PropTypes.bool,
  image: PropTypes.string,
  calculatedPrices: PropTypes.object
};
ProductCard.defaultProps = {
  title: '',
  description: [],
  loading: false,
  image: '',
  calculatedPrices: {}
};
