import React from 'react';

export default function LoginModalIcon() {
  return (
    <div>
      <svg
        width="164"
        height="122"
        viewBox="0 0 164 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M81.7991 121.72C116.836 121.72 145.239 120.062 145.239 118.016C145.239 115.971 116.836 114.312 81.7991 114.312C46.7622 114.312 18.3591 115.971 18.3591 118.016C18.3591 120.062 46.7622 121.72 81.7991 121.72Z"
          fill="#F5F5F5"
        />
        <path
          d="M95.7475 67.0757C95.4304 67.0764 95.1219 67.1797 94.8683 67.3702C94.6147 67.5607 94.4296 67.8281 94.3406 68.1325H93.84C92.8284 68.1389 91.8388 68.4282 90.983 68.9677C90.1272 69.5072 89.4395 70.2753 88.9975 71.1853L87.3157 74.6863C87.2985 74.7212 87.2884 74.7593 87.286 74.7982C87.2836 74.8371 87.2889 74.8761 87.3017 74.9129C87.3145 74.9498 87.3344 74.9837 87.3604 75.0127C87.3864 75.0418 87.4179 75.0654 87.4531 75.0822C87.493 75.1012 87.5366 75.1113 87.5807 75.1116C87.637 75.1115 87.692 75.0954 87.7395 75.0653C87.787 75.0352 87.8249 74.9923 87.849 74.9415L89.5341 71.4405C89.925 70.6286 90.5356 69.9425 91.2967 69.4602C92.0578 68.9778 92.9389 68.7185 93.84 68.7117H94.2915C94.3237 68.9934 94.4368 69.2598 94.6171 69.4788C94.7974 69.6977 95.0372 69.8597 95.3076 69.9453C95.5779 70.031 95.8673 70.0365 96.1407 69.9613C96.4142 69.8861 96.66 69.7333 96.8485 69.5215C97.0371 69.3097 97.1603 69.0478 97.2033 68.7674C97.2462 68.4871 97.2072 68.2003 97.0908 67.9417C96.9743 67.6831 96.7856 67.4638 96.5472 67.3101C96.3089 67.1564 96.0311 67.075 95.7475 67.0757Z"
          fill="#407BFF"
        />
        <path
          opacity="0.3"
          d="M95.7475 67.0757C95.4304 67.0764 95.1219 67.1797 94.8683 67.3702C94.6147 67.5607 94.4296 67.8281 94.3406 68.1325H93.84C92.8284 68.1389 91.8388 68.4282 90.983 68.9677C90.1272 69.5072 89.4395 70.2753 88.9975 71.1853L87.3157 74.6863C87.2985 74.7212 87.2884 74.7593 87.286 74.7982C87.2836 74.8371 87.2889 74.8761 87.3017 74.9129C87.3145 74.9498 87.3344 74.9837 87.3604 75.0127C87.3864 75.0418 87.4179 75.0654 87.4531 75.0822C87.493 75.1012 87.5366 75.1113 87.5807 75.1116C87.637 75.1115 87.692 75.0954 87.7395 75.0653C87.787 75.0352 87.8249 74.9923 87.849 74.9415L89.5341 71.4405C89.925 70.6286 90.5356 69.9425 91.2967 69.4602C92.0578 68.9778 92.9389 68.7185 93.84 68.7117H94.2915C94.3237 68.9934 94.4368 69.2598 94.6171 69.4788C94.7974 69.6977 95.0372 69.8597 95.3076 69.9453C95.5779 70.031 95.8673 70.0365 96.1407 69.9613C96.4142 69.8861 96.66 69.7333 96.8485 69.5215C97.0371 69.3097 97.1603 69.0478 97.2033 68.7674C97.2462 68.4871 97.2072 68.2003 97.0908 67.9417C96.9743 67.6831 96.7856 67.4638 96.5472 67.3101C96.3089 67.1564 96.0311 67.075 95.7475 67.0757Z"
          fill="white"
        />
        <path
          d="M51.3437 106.392C51.3102 106.393 51.2769 106.387 51.2456 106.375C51.1717 106.349 51.111 106.295 51.0767 106.225C51.0424 106.154 51.0372 106.073 51.0623 105.999L52.7932 101.009C52.806 100.973 52.826 100.939 52.8518 100.91C52.8777 100.881 52.909 100.858 52.944 100.841C52.9789 100.825 53.0169 100.815 53.0555 100.813C53.0942 100.811 53.133 100.816 53.1695 100.829C53.2434 100.855 53.304 100.91 53.3383 100.98C53.3726 101.05 53.3778 101.132 53.3527 101.206L51.6218 106.192C51.6021 106.25 51.5646 106.301 51.5148 106.337C51.4649 106.372 51.4051 106.392 51.3437 106.392Z"
          fill="#407BFF"
        />
        <path
          d="M76.2073 106.391H52.2075C52.1343 106.384 52.0666 106.349 52.0174 106.294C51.9682 106.24 51.9409 106.169 51.9409 106.095C51.9409 106.022 51.9682 105.951 52.0174 105.896C52.0666 105.841 52.1343 105.807 52.2075 105.799H75.7918L74.1983 101.218C74.1732 101.144 74.1784 101.063 74.2127 100.993C74.247 100.922 74.3077 100.868 74.3816 100.842C74.418 100.829 74.4568 100.823 74.4957 100.825C74.5345 100.826 74.5726 100.836 74.6076 100.853C74.6427 100.869 74.6741 100.893 74.6999 100.922C74.7257 100.951 74.7454 100.985 74.7578 101.022L76.4887 106.012C76.5036 106.056 76.5078 106.104 76.501 106.151C76.4942 106.197 76.4765 106.242 76.4494 106.28C76.4207 106.316 76.3837 106.345 76.3417 106.365C76.2996 106.384 76.2536 106.393 76.2073 106.391Z"
          fill="#407BFF"
        />
        <path
          d="M74.8626 101.405H44.826C44.7845 101.409 44.7425 101.405 44.7028 101.392C44.6632 101.379 44.6266 101.358 44.5956 101.33C44.5646 101.302 44.5398 101.268 44.5228 101.23C44.5058 101.192 44.4971 101.151 44.4971 101.109C44.4971 101.067 44.5058 101.026 44.5228 100.988C44.5398 100.95 44.5646 100.916 44.5956 100.888C44.6266 100.86 44.6632 100.839 44.7028 100.826C44.7425 100.813 44.7845 100.808 44.826 100.813H74.8626C76.1345 100.812 77.354 100.306 78.2534 99.4069C79.1527 98.5075 79.6584 97.288 79.6593 96.0161V92.5511C79.6549 92.5096 79.6593 92.4676 79.6722 92.4279C79.6851 92.3883 79.7062 92.3517 79.7341 92.3207C79.762 92.2897 79.7961 92.2649 79.8343 92.2479C79.8724 92.2309 79.9136 92.2222 79.9554 92.2222C79.9971 92.2222 80.0384 92.2309 80.0765 92.2479C80.1146 92.2649 80.1487 92.2897 80.1767 92.3207C80.2046 92.3517 80.2257 92.3883 80.2386 92.4279C80.2514 92.4676 80.2558 92.5096 80.2515 92.5511V96.0161C80.2497 97.4448 79.6814 98.8145 78.6712 99.8247C77.6609 100.835 76.2913 101.403 74.8626 101.405Z"
          fill="#407BFF"
        />
        <path
          d="M79.957 92.2695C79.878 92.2695 79.8023 92.3009 79.7465 92.3567C79.6906 92.4126 79.6593 92.4883 79.6593 92.5673V96.0323C79.6584 97.3042 79.1527 98.5237 78.2534 99.4231C77.354 100.322 76.1345 100.828 74.8626 100.829H44.826C44.7845 100.825 44.7425 100.829 44.7028 100.842C44.6632 100.855 44.6266 100.876 44.5956 100.904C44.5646 100.932 44.5398 100.966 44.5228 101.004C44.5058 101.042 44.4971 101.083 44.4971 101.125C44.4971 101.167 44.5058 101.208 44.5228 101.246C44.5398 101.284 44.5646 101.318 44.5956 101.346C44.6266 101.374 44.6632 101.395 44.7028 101.408C44.7425 101.421 44.7845 101.426 44.826 101.421H52.6787L51.0852 106.002C51.0601 106.076 51.0653 106.157 51.0996 106.228C51.1339 106.298 51.1946 106.352 51.2685 106.378C51.2998 106.39 51.3332 106.396 51.3666 106.395C51.428 106.394 51.4878 106.375 51.5377 106.339C51.5875 106.304 51.625 106.253 51.6447 106.195L53.3069 101.408H74.2736L75.7951 105.802H52.2075C52.1344 105.81 52.0666 105.845 52.0174 105.899C51.9682 105.954 51.9409 106.025 51.9409 106.098C51.9409 106.172 51.9682 106.243 52.0174 106.298C52.0666 106.352 52.1344 106.387 52.2075 106.395H76.2073C76.2547 106.395 76.3015 106.384 76.3436 106.362C76.3858 106.34 76.4221 106.309 76.4495 106.27C76.4765 106.232 76.4942 106.187 76.501 106.141C76.5078 106.094 76.5036 106.047 76.4887 106.002L74.8953 101.421C76.3183 101.413 77.6801 100.841 78.6835 99.8325C79.687 98.8235 80.2506 97.4586 80.2515 96.0356V92.5509C80.2481 92.4751 80.2156 92.4035 80.1607 92.3511C80.1059 92.2987 80.0329 92.2695 79.957 92.2695Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M79.957 92.2695C79.878 92.2695 79.8023 92.3009 79.7465 92.3567C79.6906 92.4126 79.6593 92.4883 79.6593 92.5673V96.0323C79.6584 97.3042 79.1527 98.5237 78.2534 99.4231C77.354 100.322 76.1345 100.828 74.8626 100.829H44.826C44.7845 100.825 44.7425 100.829 44.7028 100.842C44.6632 100.855 44.6266 100.876 44.5956 100.904C44.5646 100.932 44.5398 100.966 44.5228 101.004C44.5058 101.042 44.4971 101.083 44.4971 101.125C44.4971 101.167 44.5058 101.208 44.5228 101.246C44.5398 101.284 44.5646 101.318 44.5956 101.346C44.6266 101.374 44.6632 101.395 44.7028 101.408C44.7425 101.421 44.7845 101.426 44.826 101.421H52.6787L51.0852 106.002C51.0601 106.076 51.0653 106.157 51.0996 106.228C51.1339 106.298 51.1946 106.352 51.2685 106.378C51.2998 106.39 51.3332 106.396 51.3666 106.395C51.428 106.394 51.4878 106.375 51.5377 106.339C51.5875 106.304 51.625 106.253 51.6447 106.195L53.3069 101.408H74.2736L75.7951 105.802H52.2075C52.1344 105.81 52.0666 105.845 52.0174 105.899C51.9682 105.954 51.9409 106.025 51.9409 106.098C51.9409 106.172 51.9682 106.243 52.0174 106.298C52.0666 106.352 52.1344 106.387 52.2075 106.395H76.2073C76.2547 106.395 76.3015 106.384 76.3436 106.362C76.3858 106.34 76.4221 106.309 76.4495 106.27C76.4765 106.232 76.4942 106.187 76.501 106.141C76.5078 106.094 76.5036 106.047 76.4887 106.002L74.8953 101.421C76.3183 101.413 77.6801 100.841 78.6835 99.8325C79.687 98.8235 80.2506 97.4586 80.2515 96.0356V92.5509C80.2481 92.4751 80.2156 92.4035 80.1607 92.3511C80.1059 92.2987 80.0329 92.2695 79.957 92.2695Z"
          fill="white"
        />
        <path
          d="M79.9569 92.8457H44.8816C44.8151 92.8461 44.7505 92.824 44.6983 92.783C44.646 92.742 44.6091 92.6846 44.5936 92.62L39.4665 71.6794C39.4577 71.6354 39.4581 71.59 39.4677 71.546C39.4773 71.5021 39.4958 71.4607 39.5221 71.4242C39.5503 71.3898 39.5858 71.362 39.6259 71.3428C39.666 71.3236 39.7099 71.3134 39.7544 71.313H88.909C88.9579 71.3132 89.0061 71.3252 89.0494 71.348C89.0927 71.3708 89.1298 71.4037 89.1577 71.4439C89.1837 71.4855 89.1994 71.5328 89.2033 71.5818C89.2073 71.6307 89.1995 71.6799 89.1806 71.7252L80.2285 92.6658C80.2063 92.7194 80.1686 92.7652 80.1203 92.7973C80.0719 92.8294 80.015 92.8462 79.9569 92.8457ZM45.1139 92.2699H79.7606L88.4608 71.9347H40.134L45.1139 92.2699Z"
          fill="#407BFF"
        />
        <path
          d="M84.5279 82.1625H42.0578C41.9847 82.1548 41.9169 82.1203 41.8677 82.0656C41.8185 82.011 41.7913 81.94 41.7913 81.8664C41.7913 81.7929 41.8185 81.7219 41.8677 81.6672C41.9169 81.6125 41.9847 81.578 42.0578 81.5703H84.5279C84.601 81.578 84.6687 81.6125 84.718 81.6672C84.7672 81.7219 84.7944 81.7929 84.7944 81.8664C84.7944 81.94 84.7672 82.011 84.718 82.0656C84.6687 82.1203 84.601 82.1548 84.5279 82.1625Z"
          fill="#407BFF"
        />
        <path
          d="M44.8816 92.8456C44.8151 92.8459 44.7506 92.8238 44.6983 92.7828C44.646 92.7418 44.6091 92.6844 44.5936 92.6198L39.4665 71.6793C39.4529 71.6401 39.4476 71.5985 39.4509 71.5571C39.4543 71.5158 39.4663 71.4756 39.4861 71.4392C39.506 71.4027 39.5332 71.3708 39.5661 71.3455C39.5989 71.3202 39.6367 71.302 39.677 71.2922C39.7173 71.2824 39.7592 71.281 39.8001 71.2883C39.8409 71.2956 39.8798 71.3114 39.9142 71.3346C39.9486 71.3578 39.9777 71.3879 39.9998 71.423C40.0219 71.4582 40.0364 71.4975 40.0423 71.5386L45.1695 92.4791C45.1788 92.5171 45.1805 92.5566 45.1745 92.5953C45.1684 92.634 45.1549 92.6711 45.1345 92.7045C45.1142 92.7379 45.0874 92.767 45.0558 92.7901C45.0242 92.8132 44.9883 92.8298 44.9503 92.839L44.8816 92.8456Z"
          fill="#407BFF"
        />
        <path
          d="M53.6604 92.8456C53.5855 92.8458 53.5135 92.8175 53.4588 92.7664C53.4041 92.7154 53.3709 92.6454 53.3659 92.5708L51.8444 71.5648C51.8399 71.5251 51.8435 71.4848 51.8551 71.4466C51.8666 71.4083 51.8858 71.3727 51.9115 71.3421C51.9372 71.3115 51.9689 71.2865 52.0046 71.2685C52.0404 71.2505 52.0793 71.24 52.1193 71.2376C52.1589 71.2321 52.1992 71.2349 52.2377 71.2459C52.2762 71.2568 52.312 71.2757 52.3427 71.3012C52.3735 71.3268 52.3986 71.3585 52.4165 71.3943C52.4344 71.4301 52.4446 71.4692 52.4465 71.5092L53.9679 92.5151C53.972 92.5548 53.968 92.5948 53.9563 92.6329C53.9446 92.671 53.9253 92.7063 53.8997 92.7369C53.8741 92.7674 53.8426 92.7924 53.8071 92.8106C53.7716 92.8287 53.7328 92.8395 53.6931 92.8423L53.6604 92.8456Z"
          fill="#407BFF"
        />
        <path
          d="M62.4193 92.8457H62.3898C62.3507 92.842 62.3128 92.8306 62.2782 92.8121C62.2437 92.7935 62.2131 92.7683 62.1885 92.7378C62.1638 92.7073 62.1454 92.6722 62.1345 92.6346C62.1235 92.5969 62.1202 92.5574 62.1248 92.5185L64.209 71.434C64.2129 71.3953 64.2243 71.3578 64.2427 71.3235C64.2611 71.2893 64.286 71.259 64.3161 71.2344C64.3462 71.2098 64.3808 71.1913 64.418 71.1801C64.4552 71.1689 64.4943 71.1651 64.5329 71.1689C64.5716 71.1728 64.6091 71.1843 64.6434 71.2026C64.6776 71.221 64.7079 71.2459 64.7325 71.276C64.7571 71.3061 64.7756 71.3407 64.7868 71.3779C64.798 71.4151 64.8018 71.4542 64.798 71.4929L62.7137 92.597C62.7021 92.6666 62.6661 92.7299 62.6122 92.7754C62.5582 92.821 62.4898 92.8459 62.4193 92.8457Z"
          fill="#407BFF"
        />
        <path
          d="M71.1882 92.8457H71.1096C71.0339 92.825 70.9694 92.7752 70.9302 92.7072C70.891 92.6392 70.8802 92.5584 70.9002 92.4825L76.5902 71.3293C76.5971 71.2884 76.6125 71.2495 76.6354 71.2149C76.6583 71.1804 76.6881 71.151 76.723 71.1286C76.7579 71.1062 76.7971 71.0914 76.8381 71.0851C76.8791 71.0787 76.9209 71.081 76.9609 71.0917C77.001 71.1025 77.0383 71.1215 77.0706 71.1475C77.1028 71.1735 77.1293 71.206 77.1483 71.2428C77.1673 71.2796 77.1784 71.3201 77.1809 71.3614C77.1834 71.4028 77.1772 71.4442 77.1628 71.4831L71.4728 92.6428C71.4537 92.7029 71.4156 92.7552 71.3643 92.7917C71.313 92.8283 71.2512 92.8472 71.1882 92.8457Z"
          fill="#407BFF"
        />
        <path
          d="M79.9571 92.8457C79.9156 92.8458 79.8745 92.838 79.836 92.8228C79.7646 92.7899 79.709 92.7304 79.6808 92.6571C79.6527 92.5837 79.6544 92.5023 79.6855 92.4301L88.9779 71.1951C88.9912 71.1561 89.0125 71.1203 89.0404 71.0899C89.0683 71.0596 89.1022 71.0353 89.1399 71.0188C89.1777 71.0022 89.2185 70.9936 89.2597 70.9937C89.3009 70.9937 89.3417 71.0022 89.3795 71.0188C89.4172 71.0354 89.4511 71.0597 89.479 71.0901C89.5069 71.1205 89.5281 71.1563 89.5414 71.1953C89.5547 71.2344 89.5598 71.2757 89.5563 71.3168C89.5528 71.3579 89.5408 71.3978 89.521 71.434L80.2286 92.669C80.2053 92.7215 80.1672 92.7661 80.119 92.7975C80.0708 92.8288 80.0146 92.8456 79.9571 92.8457Z"
          fill="#407BFF"
        />
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M79.9569 92.8457H44.8816C44.8151 92.8461 44.7505 92.824 44.6983 92.783C44.646 92.742 44.6091 92.6846 44.5936 92.62L39.4665 71.6794C39.4577 71.6354 39.4581 71.59 39.4677 71.546C39.4773 71.5021 39.4958 71.4607 39.5221 71.4242C39.5503 71.3898 39.5858 71.362 39.6259 71.3428C39.666 71.3236 39.7099 71.3134 39.7544 71.313H88.909C88.9579 71.3132 89.0061 71.3252 89.0494 71.348C89.0927 71.3708 89.1298 71.4037 89.1577 71.4439C89.1837 71.4855 89.1994 71.5328 89.2033 71.5818C89.2073 71.6307 89.1995 71.6799 89.1806 71.7252L80.2285 92.6658C80.2063 92.7194 80.1686 92.7652 80.1203 92.7973C80.0719 92.8294 80.015 92.8462 79.9569 92.8457ZM45.1139 92.2699H79.7606L88.4608 71.9347H40.134L45.1139 92.2699Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M84.5279 82.1625H42.0578C41.9847 82.1548 41.9169 82.1203 41.8677 82.0656C41.8185 82.011 41.7913 81.94 41.7913 81.8664C41.7913 81.7929 41.8185 81.7219 41.8677 81.6672C41.9169 81.6125 41.9847 81.578 42.0578 81.5703H84.5279C84.601 81.578 84.6687 81.6125 84.718 81.6672C84.7672 81.7219 84.7944 81.7929 84.7944 81.8664C84.7944 81.94 84.7672 82.011 84.718 82.0656C84.6687 82.1203 84.601 82.1548 84.5279 82.1625Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M44.8816 92.8456C44.8151 92.8459 44.7506 92.8238 44.6983 92.7828C44.646 92.7418 44.6091 92.6844 44.5936 92.6198L39.4665 71.6793C39.4529 71.6401 39.4476 71.5985 39.4509 71.5571C39.4543 71.5158 39.4663 71.4756 39.4861 71.4392C39.506 71.4027 39.5332 71.3708 39.5661 71.3455C39.5989 71.3202 39.6367 71.302 39.677 71.2922C39.7173 71.2824 39.7592 71.281 39.8001 71.2883C39.8409 71.2956 39.8798 71.3114 39.9142 71.3346C39.9486 71.3578 39.9777 71.3879 39.9998 71.423C40.0219 71.4582 40.0364 71.4975 40.0423 71.5386L45.1695 92.4791C45.1788 92.5171 45.1805 92.5566 45.1745 92.5953C45.1684 92.634 45.1549 92.6711 45.1345 92.7045C45.1142 92.7379 45.0874 92.767 45.0558 92.7901C45.0242 92.8132 44.9883 92.8298 44.9503 92.839L44.8816 92.8456Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M53.6604 92.8456C53.5855 92.8458 53.5135 92.8175 53.4588 92.7664C53.4041 92.7154 53.3709 92.6454 53.3659 92.5708L51.8444 71.5648C51.8399 71.5251 51.8435 71.4848 51.8551 71.4466C51.8666 71.4083 51.8858 71.3727 51.9115 71.3421C51.9372 71.3115 51.9689 71.2865 52.0046 71.2685C52.0404 71.2505 52.0793 71.24 52.1193 71.2376C52.1589 71.2321 52.1992 71.2349 52.2377 71.2459C52.2762 71.2568 52.312 71.2757 52.3427 71.3012C52.3735 71.3268 52.3986 71.3585 52.4165 71.3943C52.4344 71.4301 52.4446 71.4692 52.4465 71.5092L53.9679 92.5151C53.972 92.5548 53.968 92.5948 53.9563 92.6329C53.9446 92.671 53.9253 92.7063 53.8997 92.7369C53.8741 92.7674 53.8426 92.7924 53.8071 92.8106C53.7716 92.8287 53.7328 92.8395 53.6931 92.8423L53.6604 92.8456Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M62.4193 92.8457H62.3898C62.3507 92.842 62.3128 92.8306 62.2782 92.8121C62.2437 92.7935 62.2131 92.7683 62.1885 92.7378C62.1638 92.7073 62.1454 92.6722 62.1345 92.6346C62.1235 92.5969 62.1202 92.5574 62.1248 92.5185L64.209 71.434C64.2129 71.3953 64.2243 71.3578 64.2427 71.3235C64.2611 71.2893 64.286 71.259 64.3161 71.2344C64.3462 71.2098 64.3808 71.1913 64.418 71.1801C64.4552 71.1689 64.4943 71.1651 64.5329 71.1689C64.5716 71.1728 64.6091 71.1843 64.6434 71.2026C64.6776 71.221 64.7079 71.2459 64.7325 71.276C64.7571 71.3061 64.7756 71.3407 64.7868 71.3779C64.798 71.4151 64.8018 71.4542 64.798 71.4929L62.7137 92.597C62.7021 92.6666 62.6661 92.7299 62.6122 92.7754C62.5582 92.821 62.4898 92.8459 62.4193 92.8457Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M71.1882 92.8457H71.1096C71.0339 92.825 70.9694 92.7752 70.9302 92.7072C70.891 92.6392 70.8802 92.5584 70.9002 92.4825L76.5902 71.3293C76.5971 71.2884 76.6125 71.2495 76.6354 71.2149C76.6583 71.1804 76.6881 71.151 76.723 71.1286C76.7579 71.1062 76.7971 71.0914 76.8381 71.0851C76.8791 71.0787 76.9209 71.081 76.9609 71.0917C77.001 71.1025 77.0383 71.1215 77.0706 71.1475C77.1028 71.1735 77.1293 71.206 77.1483 71.2428C77.1673 71.2796 77.1784 71.3201 77.1809 71.3614C77.1834 71.4028 77.1772 71.4442 77.1628 71.4831L71.4728 92.6428C71.4537 92.7029 71.4156 92.7552 71.3643 92.7917C71.313 92.8283 71.2512 92.8472 71.1882 92.8457Z"
            fill="#FAFAFA"
          />
          <path
            opacity="0.7"
            d="M79.9571 92.8457C79.9156 92.8458 79.8745 92.838 79.836 92.8228C79.7646 92.7899 79.709 92.7304 79.6808 92.6571C79.6527 92.5837 79.6544 92.5023 79.6855 92.4301L88.9779 71.1951C88.9912 71.1561 89.0125 71.1203 89.0404 71.0899C89.0683 71.0596 89.1022 71.0353 89.1399 71.0188C89.1777 71.0022 89.2185 70.9936 89.2597 70.9937C89.3009 70.9937 89.3417 71.0022 89.3795 71.0188C89.4172 71.0354 89.4511 71.0597 89.479 71.0901C89.5069 71.1205 89.5281 71.1563 89.5414 71.1953C89.5547 71.2344 89.5598 71.2757 89.5563 71.3168C89.5528 71.3579 89.5408 71.3978 89.521 71.434L80.2286 92.669C80.2053 92.7215 80.1672 92.7661 80.119 92.7975C80.0708 92.8288 80.0146 92.8456 79.9571 92.8457Z"
            fill="#FAFAFA"
          />
        </g>
        <path
          d="M53.515 108.269C54.7149 107.069 54.7149 105.124 53.515 103.924C52.3152 102.724 50.3699 102.724 49.1701 103.924C47.9702 105.124 47.9702 107.069 49.1701 108.269C50.3699 109.469 52.3152 109.469 53.515 108.269Z"
          fill="#407BFF"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M53.515 108.269C54.7149 107.069 54.7149 105.124 53.515 103.924C52.3152 102.724 50.3699 102.724 49.1701 103.924C47.9702 105.124 47.9702 107.069 49.1701 108.269C50.3699 109.469 52.3152 109.469 53.515 108.269Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.2"
          d="M53.2446 106.097C53.2446 106.473 53.1332 106.84 52.9243 107.153C52.7154 107.466 52.4185 107.709 52.0711 107.853C51.7238 107.997 51.3415 108.035 50.9728 107.961C50.604 107.888 50.2653 107.707 49.9994 107.441C49.7336 107.175 49.5525 106.836 49.4792 106.468C49.4058 106.099 49.4435 105.717 49.5873 105.369C49.7312 105.022 49.9749 104.725 50.2875 104.516C50.6001 104.307 50.9677 104.196 51.3436 104.196C51.8478 104.196 52.3313 104.396 52.6879 104.753C53.0444 105.109 53.2446 105.593 53.2446 106.097Z"
          fill="black"
        />
        <path
          d="M51.3438 106.967C51.7757 106.967 52.1258 106.617 52.1258 106.185C52.1258 105.753 51.7757 105.403 51.3438 105.403C50.9119 105.403 50.5618 105.753 50.5618 106.185C50.5618 106.617 50.9119 106.967 51.3438 106.967Z"
          fill="#263238"
        />
        <path
          d="M78.3317 106.795C78.7215 105.144 77.6987 103.489 76.0472 103.099C74.3958 102.71 72.741 103.732 72.3513 105.384C71.9615 107.035 72.9843 108.69 74.6357 109.08C76.2872 109.47 77.9419 108.447 78.3317 106.795Z"
          fill="#407BFF"
        />
        <g opacity="0.7">
          <path
            opacity="0.3"
            d="M77.4738 108.271C78.6736 107.071 78.6736 105.126 77.4738 103.926C76.2739 102.726 74.3286 102.726 73.1288 103.926C71.929 105.126 71.929 107.071 73.1288 108.271C74.3286 109.471 76.2739 109.471 77.4738 108.271Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.2"
          d="M77.2444 106.097C77.2444 106.473 77.1329 106.84 76.924 107.153C76.7151 107.466 76.4182 107.709 76.0709 107.853C75.7235 107.997 75.3413 108.035 74.9725 107.961C74.6038 107.888 74.265 107.707 73.9992 107.441C73.7333 107.175 73.5523 106.836 73.4789 106.468C73.4056 106.099 73.4432 105.717 73.5871 105.369C73.731 105.022 73.9746 104.725 74.2872 104.516C74.5999 104.307 74.9674 104.196 75.3434 104.196C75.593 104.196 75.8402 104.245 76.0709 104.341C76.3015 104.436 76.5111 104.576 76.6876 104.753C76.8641 104.929 77.0042 105.139 77.0997 105.369C77.1952 105.6 77.2444 105.847 77.2444 106.097Z"
          fill="black"
        />
        <path
          d="M76.1255 106.185C76.1255 106.34 76.0797 106.491 75.9937 106.62C75.9078 106.748 75.7857 106.849 75.6428 106.908C75.4999 106.967 75.3427 106.982 75.191 106.952C75.0393 106.922 74.8999 106.848 74.7906 106.738C74.6812 106.629 74.6067 106.49 74.5765 106.338C74.5464 106.186 74.5619 106.029 74.6211 105.886C74.6802 105.743 74.7805 105.621 74.9091 105.535C75.0377 105.449 75.1889 105.403 75.3435 105.403C75.5507 105.404 75.7491 105.487 75.8955 105.633C76.042 105.78 76.1247 105.978 76.1255 106.185Z"
          fill="#263238"
        />
        <path
          d="M98.4566 104.977H96.0288V104.732C100.086 104.732 104.241 104.732 108.371 104.667V104.912C105.063 104.954 101.735 104.977 98.4566 104.977Z"
          fill="#407BFF"
        />
        <path
          d="M94.0295 104.965C92.2855 104.965 90.5023 104.935 88.7322 104.909V104.664C90.5023 104.69 92.2855 104.706 94.0295 104.719V104.965Z"
          fill="#407BFF"
        />
        <path d="M86.9523 104.664H84.9695V104.909H86.9523V104.664Z" fill="#407BFF" />
        <path d="M73.7434 49.0107H61.2969V49.2561H73.7434V49.0107Z" fill="#407BFF" />
        <path d="M76.1024 49.0107H75.0881V49.2561H76.1024V49.0107Z" fill="#407BFF" />
        <path d="M96.706 30.583H91.0488V30.8284H96.706V30.583Z" fill="#407BFF" />
        <path d="M89.6058 30.583H86.4189V30.8284H89.6058V30.583Z" fill="#407BFF" />
        <path
          d="M44.9272 79.1745L31.7445 82.6984L28.7375 56.5653L37.4377 54.7788L44.9272 79.1745Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M44.9272 79.1745L31.7445 82.6984L28.7375 56.5653L37.4377 54.7788L44.9272 79.1745Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M31.7445 82.6984L35.913 81.586L30.5404 56.0811L28.7375 56.5653L31.7445 82.6984Z"
          fill="black"
        />
        <path
          d="M35.0688 56.6409C35.0812 56.6695 35.0878 56.7002 35.0883 56.7313C35.0887 56.7624 35.083 56.7934 35.0715 56.8223C35.0599 56.8512 35.0427 56.8775 35.0209 56.8997C34.9991 56.9219 34.9732 56.9396 34.9445 56.9517C34.9159 56.964 34.8852 56.9705 34.8541 56.9709C34.823 56.9712 34.7922 56.9655 34.7633 56.954C34.7344 56.9424 34.7081 56.9253 34.6859 56.9036C34.6636 56.8819 34.6459 56.856 34.6336 56.8274C34.6214 56.7988 34.6149 56.7681 34.6145 56.737C34.6141 56.706 34.6199 56.6751 34.6314 56.6462C34.643 56.6174 34.6601 56.5911 34.6818 56.5688C34.7035 56.5466 34.7294 56.5288 34.758 56.5166C34.7865 56.5042 34.8173 56.4976 34.8484 56.4971C34.8795 56.4966 34.9104 56.5024 34.9393 56.5139C34.9682 56.5255 34.9946 56.5427 35.0168 56.5645C35.039 56.5862 35.0567 56.6122 35.0688 56.6409Z"
          fill="#263238"
        />
        <path
          d="M26.6729 81.1412L13.4182 77.9019L23.3355 53.5356L31.823 56.1467L26.6729 81.1412Z"
          fill="#407BFF"
        />
        <path
          opacity="0.3"
          d="M26.6729 81.1412L13.4182 77.9019L23.3355 53.5356L31.823 56.1467L26.6729 81.1412Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M13.4182 77.9019L17.6096 78.926L25.1482 53.9774L23.3355 53.5356L13.4182 77.9019Z"
          fill="black"
        />
        <path
          d="M28.8523 56.6406C28.8494 56.6716 28.8404 56.7017 28.8258 56.7292C28.8113 56.7567 28.7914 56.7811 28.7673 56.8009C28.7433 56.8206 28.7156 56.8354 28.6858 56.8444C28.656 56.8534 28.6247 56.8565 28.5938 56.8533C28.5629 56.8508 28.5329 56.8422 28.5054 56.828C28.4779 56.8138 28.4535 56.7942 28.4337 56.7704C28.4139 56.7467 28.3991 56.7192 28.39 56.6896C28.381 56.66 28.3779 56.6289 28.3811 56.5981C28.3867 56.5356 28.417 56.4779 28.4651 56.4377C28.5133 56.3975 28.5755 56.3781 28.6379 56.3838C28.7004 56.3894 28.7581 56.4196 28.7983 56.4678C28.8385 56.516 28.8579 56.5781 28.8523 56.6406Z"
          fill="#263238"
        />
        <path
          d="M45.8565 39.7276C45.4377 41.5828 44.9731 43.3922 44.4594 45.2147C43.9457 47.0371 43.4156 48.8433 42.7874 50.6756L42.5289 51.3921C42.3681 51.8399 42.1201 52.2514 41.7993 52.6027C41.2806 53.1494 40.6373 53.5625 39.9244 53.8068C38.8705 54.1627 37.7648 54.3407 36.6525 54.3336C34.6651 54.3399 32.6854 54.088 30.7629 53.5843L31.0476 51.5361L32.4022 51.5099C32.857 51.4935 33.3118 51.4543 33.7601 51.4412C34.6369 51.3921 35.5138 51.2972 36.3776 51.1631C37.1368 51.0582 37.8772 50.8456 38.5764 50.5316C38.749 50.4634 38.9004 50.3505 39.0148 50.2044C39.0148 50.1815 38.9854 50.2044 38.9854 50.2044L39.1522 49.586C39.6038 47.8224 40.0357 45.9868 40.461 44.1938C40.8864 42.4008 41.3445 40.5652 41.8156 38.7656L45.8565 39.7276Z"
          fill="#FFB573"
        />
        <path
          d="M28.3843 53.7185C28.1402 53.7089 27.9002 53.6523 27.6776 53.5517C26.8694 53.0249 27.0788 51.8699 27.6056 51.0617C27.6285 51.0257 28.2338 50.2274 28.26 50.1947C28.8424 49.429 29.3528 50.0441 30.1184 50.6265L31.653 51.7946L31.1655 53.6073C30.442 53.7342 29.7073 53.7847 28.9733 53.7578C28.8358 53.7513 28.6133 53.7415 28.3843 53.7185Z"
          fill="#FFB573"
        />
        <path
          d="M40.3925 40.0157C39.3193 42.5809 40.1209 45.578 40.1209 45.578L43.8411 45.7056C43.8411 45.7056 47.0804 40.8304 45.9908 38.9425C44.7769 36.8419 41.5278 37.2999 40.3925 40.0157Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M43.8444 45.7025L41.4788 45.6207C41.735 43.9493 42.1909 42.3148 42.8366 40.752C43.8182 38.5074 44.6853 37.7418 45.8599 38.1344C46.6975 40.8469 43.8444 45.7025 43.8444 45.7025Z"
          fill="black"
        />
        <path
          d="M52.2892 35.6638C52.6655 35.6344 56.5231 35.8634 56.2156 39.528C54.8119 53.9246 57.0892 52.3344 59.9194 58.4825L45.7747 61.8002C45.7747 61.8002 44.9829 51.7782 44.0308 50.1455C42.4308 47.3971 42.1658 45.2376 42.722 42.0867C43.3371 38.6119 43.6447 37.6466 43.6447 37.6466C44.9535 37.0937 49.315 35.1141 52.2892 35.6638Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M53.5883 46.37C53.4738 44.5999 55.1752 37.5161 54.4619 36.8159C53.9777 36.6621 51.7004 36.1615 50.8039 37.6274C49.3282 44.0895 53.5883 46.37 53.5883 46.37Z"
          fill="black"
        />
        <path
          d="M35.5139 76.4392C39.113 77.0347 42.8038 75.3006 46.3931 75.9484C48.4119 76.3149 50.3195 77.4241 52.3677 77.2572C54.0692 77.123 55.5546 76.1251 57.1055 75.4118C62.056 73.1378 68.0535 72.8531 72.7226 70.0458C73.9005 69.339 74.9933 68.3902 75.5463 67.1337C76.0993 65.8773 76.0076 64.2675 75.0555 63.2761C74.6055 62.8569 74.0906 62.5132 73.5308 62.2585C69.8203 60.3304 65.8061 59.0543 61.6634 58.486C56.1272 57.6811 50.4602 59.3694 45.5981 62.1342C41.8877 64.2413 38.5699 66.9538 35.383 69.7742C34.4799 70.5726 32.0456 71.9959 31.8624 73.2654C31.5941 75.1141 34.1102 76.2069 35.5139 76.4392Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M35.5139 76.4392C39.113 77.0347 42.8038 75.3006 46.3931 75.9484C48.4119 76.3149 50.3195 77.4241 52.3677 77.2572C54.0692 77.123 55.5546 76.1251 57.1055 75.4118C62.056 73.1378 68.0535 72.8531 72.7226 70.0458C73.9005 69.339 74.9933 68.3902 75.5463 67.1337C76.0993 65.8773 76.0076 64.2675 75.0555 63.2761C74.6055 62.8569 74.0906 62.5132 73.5308 62.2585C69.8203 60.3304 65.8061 59.0543 61.6634 58.486C56.1272 57.6811 50.4602 59.3694 45.5981 62.1342C41.8877 64.2413 38.5699 66.9538 35.383 69.7742C34.4799 70.5726 32.0456 71.9959 31.8624 73.2654C31.5941 75.1141 34.1102 76.2069 35.5139 76.4392Z"
          fill="black"
        />
        <path
          d="M57.4557 59.8765C57.4557 59.8765 65.5701 79.8354 68.0634 85.4534C68.2116 85.7814 68.381 86.0994 68.5705 86.4055C73.4163 94.1568 84.9139 110.225 84.9139 110.225L82.918 112.002C82.918 112.002 68.2826 98.0242 63.0278 88.4636C58.2867 81.1769 49.3281 62.4711 49.3281 62.4711L57.4557 59.8765Z"
          fill="#FFB573"
        />
        <path
          d="M81.6191 111.603C81.5275 112.542 82.0837 114.309 80.0976 115.356C79.4171 115.716 79.6657 116.635 80.8404 116.38C82.015 116.124 83.9062 113.726 84.5442 113.14C85.5782 112.188 85.8334 111.855 86.6677 111.109C86.6901 111.088 86.7109 111.067 86.7299 111.043C86.7945 110.952 86.8207 110.839 86.803 110.729C86.7852 110.619 86.7249 110.52 86.635 110.454L84.5148 108.972C84.4664 108.937 84.4073 108.92 84.3477 108.923C84.288 108.927 84.2314 108.951 84.1876 108.992L81.835 111.161C81.7084 111.275 81.6309 111.433 81.6191 111.603Z"
          fill="#263238"
        />
        <path
          d="M80.9843 112.444C81.3638 112.424 81.7696 111.901 81.9659 111.59C81.9698 111.58 81.9719 111.57 81.9719 111.56C81.9719 111.55 81.9698 111.54 81.9659 111.531C81.9612 111.511 81.9492 111.493 81.9322 111.482C81.9152 111.47 81.8946 111.466 81.8743 111.469C81.6747 111.505 80.6833 111.708 80.5655 112.035C80.5495 112.073 80.5441 112.114 80.5498 112.155C80.5556 112.196 80.5723 112.235 80.5982 112.267C80.6117 112.286 80.627 112.303 80.644 112.319C80.6878 112.364 80.7411 112.399 80.8 112.42C80.8589 112.442 80.9219 112.45 80.9843 112.444ZM80.9614 112.283C80.9177 112.284 80.8746 112.274 80.8354 112.254C80.7962 112.235 80.762 112.207 80.7356 112.172C80.7287 112.146 80.7287 112.119 80.7356 112.094C80.7749 111.94 81.2984 111.766 81.7172 111.668C81.4358 112.048 81.1643 112.29 80.9614 112.283Z"
          fill="#407BFF"
        />
        <path
          d="M81.9268 111.622C81.9421 111.619 81.9559 111.611 81.966 111.599C81.98 111.584 81.9878 111.564 81.9878 111.544C81.9878 111.523 81.98 111.503 81.966 111.488C81.93 111.465 81.3116 110.902 80.8895 110.929C80.8364 110.93 80.7841 110.943 80.7362 110.966C80.6883 110.989 80.6458 111.022 80.6114 111.063C80.5723 111.099 80.5468 111.148 80.5391 111.201C80.5314 111.254 80.5419 111.308 80.5689 111.354C80.5689 111.374 80.5983 111.39 80.6114 111.406C80.8568 111.642 81.6126 111.704 81.9268 111.622ZM80.703 111.269C80.703 111.269 80.6769 111.226 80.7292 111.167C80.7497 111.143 80.7752 111.123 80.804 111.11C80.8328 111.096 80.8642 111.089 80.8961 111.089C81.2021 111.141 81.4863 111.281 81.7141 111.491C81.3542 111.518 80.7947 111.419 80.703 111.269Z"
          fill="#407BFF"
        />
        <path
          opacity="0.1"
          d="M55.175 74.2469L57.7403 79.181C57.7403 79.181 59.5038 79.4919 61.2969 77.8723C62.246 76.9652 63.3425 76.2261 64.5394 75.6866L61.2969 68.4556L55.175 74.2469Z"
          fill="black"
        />
        <path
          d="M53.1106 68.4324C53.1106 68.4324 45.6047 74.888 40.3958 81.7984C40.0081 82.314 39.7593 82.9206 39.6734 83.56C39.5874 84.1994 39.6672 84.8502 39.905 85.4499C42.7516 92.5926 46.4489 101.371 46.0824 101.201L44.6133 103.37C44.6133 103.37 31.8527 85.9276 34.1627 82.4299C37.1075 74.3579 45.3233 63.7012 45.3233 63.7012L53.1106 68.4324Z"
          fill="#FFB573"
        />
        <path
          opacity="0.1"
          d="M41.3346 69.2017L38.2786 74.1096C38.2786 74.1096 39.8655 77.8069 42.3816 77.8069C43.6642 77.8069 45.0384 76.2854 45.0384 76.2854L49.328 72.4311L41.3346 69.2017Z"
          fill="black"
        />
        <path
          d="M43.8443 103.138C43.8771 104.08 43.0362 106.083 41.9891 108.088C41.7568 108.53 42.7613 109.171 43.5302 108.259C44.2991 107.346 46.1936 104.957 46.7531 104.303C47.6594 103.223 48.0619 102.847 48.7948 102.012C48.8174 101.986 48.8371 101.958 48.8537 101.927C48.9056 101.828 48.9162 101.713 48.883 101.606C48.8499 101.499 48.7758 101.41 48.677 101.358L46.3867 100.154C46.336 100.127 46.2778 100.119 46.2218 100.13C46.1658 100.142 46.1154 100.172 46.0791 100.216L44.0178 102.664C43.9035 102.795 43.8417 102.964 43.8443 103.138Z"
          fill="#263238"
        />
        <path
          d="M43.3044 104.048C43.6774 103.986 44.0144 103.393 44.1813 103.066C44.1894 103.053 44.1937 103.038 44.1937 103.022C44.1937 103.006 44.1894 102.991 44.1813 102.978C44.1706 102.967 44.1569 102.959 44.142 102.955C44.127 102.951 44.1111 102.951 44.0962 102.955C43.9032 103.027 42.9445 103.341 42.866 103.685C42.8565 103.725 42.8585 103.767 42.8718 103.807C42.8851 103.846 42.9092 103.881 42.9412 103.907C42.9565 103.924 42.9741 103.938 42.9936 103.95C43.0348 103.988 43.0841 104.017 43.1379 104.034C43.1917 104.051 43.2486 104.056 43.3044 104.048ZM43.3044 103.891C43.2607 103.897 43.2162 103.892 43.1746 103.877C43.133 103.863 43.0956 103.838 43.0656 103.806C43.0479 103.786 43.0375 103.76 43.0361 103.734C43.0754 103.573 43.5563 103.341 43.9686 103.187C43.7167 103.59 43.4811 103.848 43.288 103.881L43.3044 103.891Z"
          fill="#407BFF"
        />
        <path
          d="M44.1453 103.096C44.1593 103.091 44.1716 103.082 44.1809 103.07C44.1902 103.059 44.1959 103.045 44.1976 103.03C44.1993 103.016 44.1971 103.001 44.1914 102.988C44.1856 102.975 44.1765 102.963 44.1649 102.955C44.1354 102.935 43.4581 102.454 43.0328 102.529C42.98 102.539 42.9298 102.559 42.8853 102.589C42.8408 102.619 42.803 102.658 42.7743 102.703C42.7397 102.744 42.7207 102.796 42.7207 102.85C42.7207 102.904 42.7397 102.956 42.7743 102.997L42.8234 103.043C43.0884 103.243 43.8442 103.21 44.1453 103.096ZM42.9117 102.896C42.8888 102.896 42.8692 102.857 42.9117 102.791C42.9292 102.765 42.9519 102.742 42.9783 102.725C43.0047 102.707 43.0344 102.696 43.0655 102.69C43.3768 102.703 43.6772 102.808 43.9293 102.991C43.5759 103.066 43.0066 103.03 42.9117 102.896Z"
          fill="#407BFF"
        />
        <path
          d="M47.6726 33.2231C47.6072 34.3028 46.7957 35.1862 45.9908 35.8995C45.3626 36.459 44.6624 37.0087 43.8314 37.1298C43.5008 37.1611 43.1672 37.125 42.851 37.0235C42.5349 36.9221 42.2426 36.7574 41.992 36.5395C41.7414 36.3217 41.5376 36.0551 41.3932 35.7561C41.2487 35.4571 41.1665 35.1319 41.1516 34.8001C41.0622 34.997 40.9324 35.1728 40.7707 35.3162C40.6089 35.4595 40.4188 35.5673 40.2126 35.6324C40.0065 35.6975 39.789 35.7185 39.5742 35.6941C39.3594 35.6696 39.1522 35.6003 38.9659 35.4905C38.2461 35.0095 38.0891 34.028 38.0923 33.1642C38.1137 30.3991 39.0606 27.7209 40.7819 25.5569C41.361 26.0869 42.2739 25.8023 42.6796 25.6158C42.824 25.5515 42.9602 25.4702 43.0853 25.3736C43.489 25.218 43.9325 25.1985 44.3483 25.318C45.0845 25.9888 45.4837 26.954 45.8534 27.893C46.3093 29.036 46.763 30.179 47.2146 31.322C47.5023 31.9154 47.6585 32.5638 47.6726 33.2231Z"
          fill="#263238"
        />
        <path
          d="M48.0259 31.2041C48.268 32.9284 48.7719 35.4086 50.277 36.112C50.277 36.112 50.7875 37.7873 46.8218 41.1541C44.3515 39.3545 45.2251 37.8593 45.2251 37.8593C46.8873 36.8024 46.5863 36.0826 45.837 34.9309L48.0259 31.2041Z"
          fill="#FFB573"
        />
        <path
          opacity="0.2"
          d="M47.136 32.7192L45.8567 34.9278C46.0356 35.193 46.191 35.4733 46.3213 35.7654C46.9757 35.4382 47.8624 34.4566 47.7512 33.6616C47.6277 33.3012 47.4162 32.9773 47.136 32.7192Z"
          fill="black"
        />
        <path
          d="M49.1841 27.6934C49.3739 30.5989 49.5113 31.8226 48.2451 33.4848C46.3343 35.9878 42.722 35.5821 41.6684 32.7944C40.7261 30.2815 40.5723 25.9233 43.2553 24.4476C43.8442 24.1131 44.5089 23.9347 45.1862 23.9293C45.8634 23.924 46.5308 24.0919 47.1249 24.4172C47.719 24.7424 48.22 25.2141 48.5804 25.7876C48.9409 26.361 49.1487 27.017 49.1841 27.6934Z"
          fill="#FFB573"
        />
        <path
          d="M44.6853 29.0285C44.7114 29.264 44.61 29.4702 44.4595 29.4931C44.309 29.516 44.1552 29.336 44.1323 29.1004C44.1094 28.8649 44.2043 28.6587 44.3548 28.6391C44.5053 28.6195 44.6427 28.8092 44.6853 29.0285Z"
          fill="#263238"
        />
        <path
          d="M42.0316 29.3719C42.0611 29.6107 41.9596 29.8103 41.8091 29.8332C41.6586 29.8561 41.5048 29.6762 41.4819 29.4406C41.459 29.205 41.5539 28.9989 41.7077 28.9825C41.8615 28.9662 42.0022 29.1363 42.0316 29.3719Z"
          fill="#263238"
        />
        <path
          d="M41.77 28.9857L41.1843 28.894C41.1843 28.894 41.5279 29.2998 41.77 28.9857Z"
          fill="#263238"
        />
        <path
          d="M42.9054 29.6924C42.7604 30.2548 42.5402 30.7952 42.251 31.2989C42.3828 31.3673 42.5277 31.407 42.676 31.4155C42.8243 31.4239 42.9727 31.4009 43.1115 31.348L42.9054 29.6924Z"
          fill="#ED893E"
        />
        <path
          d="M44.4986 32.1039C44.3916 32.1358 44.2822 32.1588 44.1714 32.1726C44.1536 32.1755 44.1355 32.1713 44.1208 32.1609C44.1061 32.1505 44.0961 32.1347 44.0929 32.117C44.092 32.108 44.0929 32.0989 44.0955 32.0902C44.0982 32.0815 44.1025 32.0734 44.1083 32.0664C44.114 32.0594 44.1212 32.0537 44.1292 32.0494C44.1372 32.0452 44.146 32.0426 44.155 32.0418C44.4474 32.0098 44.7283 31.91 44.9754 31.7504C45.2225 31.5908 45.4289 31.3757 45.5783 31.1223C45.5862 31.1059 45.6002 31.0932 45.6174 31.087C45.6346 31.0809 45.6535 31.0818 45.67 31.0896C45.6864 31.0974 45.6992 31.1115 45.7053 31.1287C45.7114 31.1458 45.7105 31.1648 45.7027 31.1812C45.5748 31.4085 45.4022 31.6074 45.1952 31.766C44.9883 31.9246 44.7513 32.0396 44.4986 32.1039Z"
          fill="#263238"
        />
        <path
          d="M45.6832 28.361C45.6546 28.3702 45.6237 28.3697 45.5953 28.3597C45.5669 28.3496 45.5426 28.3306 45.5262 28.3054C45.3766 28.1359 45.1936 27.9993 44.9886 27.9042C44.7836 27.809 44.5611 27.7573 44.3352 27.7524C44.3151 27.7555 44.2947 27.7542 44.2751 27.7486C44.2556 27.7431 44.2376 27.7333 44.2222 27.7201C44.2068 27.7069 44.1944 27.6905 44.186 27.6721C44.1775 27.6537 44.1731 27.6336 44.1731 27.6134C44.1731 27.5931 44.1775 27.573 44.186 27.5546C44.1944 27.5362 44.2068 27.5198 44.2222 27.5066C44.2376 27.4934 44.2556 27.4836 44.2751 27.4781C44.2947 27.4725 44.3151 27.4712 44.3352 27.4743C44.6069 27.4791 44.8743 27.5423 45.1194 27.6597C45.3644 27.7772 45.5813 27.9461 45.7552 28.1549C45.7754 28.1849 45.7829 28.2216 45.7762 28.2571C45.7694 28.2927 45.749 28.3241 45.7192 28.3446C45.7084 28.3524 45.6962 28.358 45.6832 28.361Z"
          fill="#263238"
        />
        <path
          d="M41.0828 28.2465C41.0592 28.2544 41.0337 28.2555 41.0095 28.2497C40.9852 28.2439 40.963 28.2315 40.9454 28.2138C40.9198 28.1875 40.9055 28.1523 40.9055 28.1156C40.9055 28.079 40.9198 28.0438 40.9454 28.0175C41.0937 27.8504 41.2807 27.7221 41.4901 27.644C41.6994 27.5658 41.9247 27.5402 42.1462 27.5692C42.1813 27.5741 42.2131 27.5927 42.2345 27.6209C42.256 27.6491 42.2654 27.6846 42.2607 27.7197C42.2576 27.7376 42.2509 27.7547 42.2411 27.77C42.2314 27.7852 42.2187 27.7984 42.2038 27.8088C42.1888 27.8191 42.172 27.8264 42.1543 27.8302C42.1366 27.834 42.1183 27.8343 42.1004 27.831C41.9269 27.8137 41.7517 27.8376 41.5891 27.9007C41.4265 27.9638 41.2811 28.0643 41.1646 28.1942C41.1447 28.221 41.1156 28.2396 41.0828 28.2465Z"
          fill="#263238"
        />
        <path
          d="M44.4267 28.6653L43.8411 28.5737C43.8411 28.5737 44.1715 28.9631 44.4267 28.6653Z"
          fill="#263238"
        />
        <path
          d="M45.0746 56.0191C43.4386 57.6551 42.6959 58.8395 40.965 60.518C39.372 62.0344 37.946 63.7171 36.7115 65.5372C34.9839 68.1188 30.7369 71.5936 32.1307 74.5089C32.7131 71.129 36.3385 70.9098 37.945 70.9916C39.0051 71.0439 39.8296 71.9306 40.3793 72.8402C40.929 73.7498 41.3609 74.774 42.1724 75.4578C43.4615 76.5179 45.3364 76.4099 46.9691 76.0697C48.6018 75.7294 50.2868 75.2059 51.8999 75.6214C53.2545 75.9682 54.403 76.9466 55.7837 77.169C58.2115 77.5551 60.2728 75.5691 62.3898 74.3159C64.7456 72.9253 67.4908 72.3789 70.0331 71.3711C72.5754 70.3634 75.0981 68.6358 75.8703 66.0149C76.2891 64.5982 75.9881 62.7986 74.6924 62.0886C73.5799 61.4833 65.8777 57.8187 64.6016 57.1316C62.6385 56.0715 57.1252 53.5586 57.1252 53.5586C50.2999 57.678 45.0746 56.0191 45.0746 56.0191Z"
          fill="#407BFF"
        />
        <path
          d="M69.6273 26.3062C69.3486 28.1736 68.4854 29.9047 67.1616 31.2509C65.8377 32.597 64.1214 33.4891 62.2589 33.799C61.3198 33.9332 60.1812 33.8187 59.7002 33.0007C59.4843 32.6277 59.4417 32.1598 59.1702 31.8228C58.699 31.2273 57.7436 31.3025 57.0957 31.6984C56.4479 32.0943 55.9833 32.7225 55.3812 33.1872C54.7792 33.6518 53.9285 33.9201 53.297 33.5144C52.4638 34.1855 51.5896 34.804 50.6794 35.3663C50.1232 35.6935 49.4557 35.9814 48.8308 35.7884C47.9637 35.5233 47.5776 34.4403 47.7739 33.5536C47.9114 32.945 47.8197 32.2448 49.3543 31.116C49.7306 30.8379 49.9236 29.8727 50.0643 28.8715C49.9749 28.9087 49.8797 28.9297 49.7829 28.9336C49.2365 28.9631 49.0402 29.4081 48.5723 29.6829C48.3956 29.7876 47.0476 30.8739 46.2198 27.6183C45.7093 25.606 45.0386 25.6715 44.695 25.4621C44.5918 25.3991 44.4821 25.3476 44.3678 25.3083C43.952 25.1888 43.5085 25.2083 43.1049 25.3639C42.9838 25.4588 42.8235 25.4981 42.6991 25.606C41.4885 26.64 40.3139 26.352 40.2844 26.3193C40.6705 24.6277 42.6795 23.0114 44.394 22.7463C45.8762 22.514 47.7805 22.8183 48.749 23.476C49.3983 23.9014 49.9305 24.4831 50.2966 25.1676C50.4382 25.4525 50.6787 25.6761 50.9732 25.7965C51.2677 25.917 51.5959 25.926 51.8966 25.822C52.3359 25.6926 52.7522 25.4949 53.1301 25.2363C56.0749 22.1443 56.569 25.0433 57.4328 24.7193C61.9023 23.0605 65.1088 25.3737 65.2527 25.4882C65.6976 25.7643 66.0206 26.1996 66.1558 26.7054C66.2736 27.1798 66.2245 27.7099 66.483 28.1156C66.9018 28.7471 67.903 28.7046 68.4952 28.2334C69.0875 27.7623 69.3656 27.0195 69.6273 26.3062Z"
          fill="#263238"
        />
        <path
          d="M50.7547 26.7479C50.6892 26.5156 50.6434 26.316 50.6238 26.231C50.6777 26.4005 50.7214 26.5731 50.7547 26.7479Z"
          fill="#263238"
        />
        <path
          d="M47.0769 29.8205C46.9907 29.8213 46.9049 29.8103 46.8217 29.7877C46.1935 29.6241 45.8401 28.852 45.4671 28.034C44.8618 26.7088 44.1583 25.2037 42.1395 25.8843L42.0872 25.7272C44.263 25.0009 45.0123 26.6467 45.6143 27.9653C45.9742 28.7473 46.3113 29.4867 46.8642 29.6307C47.211 29.7223 47.6299 29.5816 48.1501 29.2053C48.4249 29.0025 48.6474 27.8966 48.8437 26.8921C49.0237 25.9825 49.1709 25.1939 49.3869 24.9289C49.8417 24.3628 50.6401 23.9702 51.2846 24.317C51.7493 24.569 51.939 25.0859 52.119 25.5866C52.2989 26.0872 52.4658 26.5681 52.8879 26.771L52.8126 26.915C52.3349 26.6728 52.1582 26.1493 51.9619 25.6422C51.7656 25.135 51.6184 24.6835 51.2061 24.461C50.6466 24.16 49.917 24.5297 49.5145 25.0303C49.3313 25.2561 49.1644 26.1002 49.0041 26.915C48.7816 28.0307 48.5754 29.0875 48.245 29.3264C47.9165 29.6086 47.5082 29.7813 47.0769 29.8205Z"
          fill="#263238"
        />
        <path
          d="M50.2475 29.8108C50.1307 30.3482 49.8076 30.8182 49.3477 31.1196C48.726 31.509 48.2058 31.0084 48.1992 30.3082C48.1992 29.6799 48.5264 28.7212 49.2201 28.5969C49.3423 28.5798 49.4668 28.587 49.5862 28.6181C49.7057 28.6492 49.8179 28.7035 49.9163 28.7779C50.0148 28.8524 50.0976 28.9455 50.1601 29.052C50.2225 29.1585 50.2633 29.2763 50.2802 29.3985C50.3016 29.5366 50.2904 29.6778 50.2475 29.8108Z"
          fill="#FFB573"
        />
        <path
          d="M42.5355 36.9011C41.6881 36.9011 40.8047 36.7081 40.2452 36.0897C38.5896 34.313 39.8067 28.7278 41.9269 25.5703L42.0611 25.6619C39.9736 28.7703 38.7564 34.2508 40.363 35.9784C41.4754 37.1727 43.8999 36.6557 44.659 36.3809L44.7147 36.5379C44.0122 36.7739 43.2766 36.8964 42.5355 36.9011Z"
          fill="#263238"
        />
        <path
          d="M50.7155 27.0722H50.5519C50.5519 25.9695 49.976 24.8669 48.9159 23.9638C47.7773 22.9822 46.282 22.4522 45.1924 22.6288C43.9458 22.8284 42.2476 23.6104 41.446 24.8145L41.3086 24.7229C42.1331 23.4665 43.8869 22.6746 45.1662 22.4685C46.2983 22.2853 47.8492 22.835 49.0206 23.8395C50.1102 24.772 50.7155 25.9204 50.7155 27.0722Z"
          fill="#263238"
        />
        <path
          d="M50.8004 35.2126C50.3934 35.2244 49.9885 35.1494 49.6128 34.9926C49.237 34.8357 48.899 34.6006 48.6212 34.3029C48.434 34.0769 48.2939 33.8158 48.209 33.5348C48.1242 33.2539 48.0963 32.9588 48.1272 32.667C48.1451 32.4264 48.2124 32.1921 48.325 31.9787C48.4376 31.7653 48.5931 31.5775 48.7816 31.4269L48.883 31.5545C48.715 31.694 48.5766 31.8658 48.476 32.0597C48.3754 32.2535 48.3146 32.4656 48.2973 32.6833C48.2702 32.9515 48.2968 33.2224 48.3754 33.4803C48.4541 33.7381 48.5832 33.9777 48.7554 34.1852C49.1035 34.5448 49.5402 34.8064 50.0215 34.9437C50.5028 35.081 51.0118 35.0891 51.4973 34.9672C52.348 34.7872 53.1791 34.3128 54.1149 33.4948C55.084 32.5938 55.9832 31.6203 56.8044 30.5827C58.1132 29.0187 59.4612 27.3991 61.2706 26.4077L61.3492 26.5517C59.5659 27.5333 58.2277 29.1333 56.932 30.6874C56.1048 31.7362 55.1991 32.7206 54.2228 33.6322C53.2674 34.4764 52.4134 34.941 51.5333 35.1406C51.2923 35.191 51.0465 35.2151 50.8004 35.2126Z"
          fill="#263238"
        />
        <path
          d="M62.2031 30.6583C62.1164 30.6589 62.0298 30.6512 61.9446 30.6354L61.9708 30.475C63.0636 30.6648 64.5163 29.428 65.3834 28.6885C65.8644 28.2828 66.4991 26.889 66.0902 25.8419C65.7335 24.9193 64.6472 24.4612 62.9523 24.5332V24.3696C65.1904 24.291 65.9789 25.0992 66.2505 25.7929C66.702 26.9544 65.9887 28.4104 65.4979 28.826C64.6636 29.5164 63.3221 30.6583 62.2031 30.6583Z"
          fill="#263238"
        />
        <path
          d="M54.946 37.1499C55.6299 38.9855 57.6487 47.3355 57.7108 48.376C57.7108 48.5232 57.7108 48.6639 57.7108 48.8177C57.7108 48.9715 57.7108 49.122 57.7108 49.2889L57.6945 49.5441C57.6945 49.6553 57.6683 49.7731 57.6487 49.8713C57.6105 50.0719 57.5591 50.2697 57.4949 50.4635C57.2732 51.0911 56.948 51.6773 56.5329 52.1976C55.8595 53.0402 55.0835 53.7953 54.2229 54.4455C52.6455 55.6521 50.9352 56.6742 49.1252 57.4917L48.0684 55.7215L49.1187 54.8414C49.4688 54.5436 49.809 54.2328 50.1493 53.9318C50.8299 53.3199 51.4908 52.7015 52.0929 52.0668C52.6753 51.4844 53.6209 50.5944 54.056 49.904C54.2072 49.6654 54.3178 49.4033 54.3832 49.1285C54.3866 49.097 54.3866 49.0652 54.3832 49.0337V49.014V48.9191C54.3832 48.8406 54.3832 48.7588 54.3832 48.6803C54.3832 48.6018 52.1354 39.7969 51.5497 38.0726L54.946 37.1499Z"
          fill="#FFB573"
        />
        <path
          d="M49.6944 56.7781C49.5512 57.434 49.3316 58.071 49.04 58.6758C48.9114 58.9559 48.7089 59.1957 48.4544 59.3695C47.6658 59.9191 46.8184 60.4787 46.0298 58.931C46.0037 58.8983 45.4867 58.3257 45.4638 58.2766C44.973 57.4488 45.7386 56.9122 46.5664 56.4214L48.7946 55.1421L49.6944 56.7781Z"
          fill="#FFB573"
        />
        <path
          d="M50.6041 39.4464C51.1963 42.4763 52.1386 41.9331 52.8944 43.5724L56.4936 42.666C56.4936 42.666 57.4588 40.2612 56.7292 38.262C56.5395 37.7689 56.2363 37.3274 55.8442 36.9733C55.4521 36.6192 54.9821 36.3624 54.4723 36.2238C53.9624 36.0852 53.4271 36.0686 52.9097 36.1754C52.3923 36.2823 51.9073 36.5095 51.4941 36.8387C51.1088 37.1403 50.8196 37.5473 50.6616 38.0103C50.5036 38.4733 50.4836 38.9723 50.6041 39.4464Z"
          fill="#263238"
        />
        <path
          d="M54.0358 42.4922L52.949 42.7979L53.0373 43.112L54.1241 42.8063L54.0358 42.4922Z"
          fill="#407BFF"
        />
        <path
          d="M40.3432 45.0295L40.3303 45.3564L41.4615 45.4009L41.4744 45.0739L40.3432 45.0295Z"
          fill="#407BFF"
        />
        <path
          d="M56.2228 42.0108L55.0183 42.2271L55.0761 42.5491L56.2806 42.3328L56.2228 42.0108Z"
          fill="#407BFF"
        />
        <path
          d="M78.9 76.7174L66.0085 86.0261L51.8867 58.8165L60.6163 53.2607L78.9 76.7174Z"
          fill="#263238"
        />
        <path
          opacity="0.8"
          d="M78.9 76.7174L66.0085 86.0261L51.8867 58.8165L60.6163 53.2607L78.9 76.7174Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M66.0085 86.0263L70.0853 83.0848L53.6503 57.5439L51.8867 58.8167L66.0085 86.0263Z"
          fill="black"
        />
        <path
          d="M58.8102 56.2774C58.8358 56.303 58.8561 56.3333 58.87 56.3667C58.8839 56.4001 58.891 56.4359 58.891 56.4721C58.891 56.5083 58.8839 56.5441 58.87 56.5775C58.8561 56.6109 58.8358 56.6413 58.8102 56.6668C58.7717 56.7051 58.7227 56.7312 58.6694 56.7417C58.6161 56.7523 58.5609 56.7468 58.5108 56.7259C58.4606 56.7051 58.4177 56.6699 58.3876 56.6247C58.3574 56.5795 58.3413 56.5264 58.3413 56.4721C58.3413 56.4178 58.3574 56.3647 58.3876 56.3195C58.4177 56.2743 58.4606 56.2391 58.5108 56.2183C58.5609 56.1974 58.6161 56.192 58.6694 56.2025C58.7227 56.213 58.7717 56.2391 58.8102 56.2774Z"
          fill="#263238"
        />
        <path
          d="M45.6111 39.9176C45.4534 39.8965 45.2928 39.9131 45.1427 39.9659C44.9926 40.0188 44.8571 40.1065 44.7474 40.2219C44.5478 40.5331 44.4763 40.9094 44.5478 41.2722C44.5936 41.884 44.6688 42.4894 44.7703 43.0947C44.7851 43.2568 44.8439 43.4118 44.9404 43.5429C45.0327 43.6255 45.1484 43.6774 45.2715 43.6914C45.3945 43.7055 45.5189 43.681 45.6275 43.6214C45.8403 43.4947 46.0216 43.3213 46.1576 43.1143C46.4906 42.7135 46.7338 42.2461 46.8709 41.7433C47.077 40.8108 46.5666 40.0059 45.6111 39.9176Z"
          fill="#407BFF"
        />
        <path
          d="M47.0541 40.572C46.9311 40.7723 46.8544 40.9976 46.8295 41.2314C46.8045 41.4651 46.8321 41.7015 46.9101 41.9233C47.1555 42.509 47.8099 42.7903 48.4152 42.9834C48.7032 43.0783 49.0696 43.147 49.2725 42.9343C49.3427 42.8419 49.3936 42.7364 49.4222 42.624C49.4509 42.5116 49.4567 42.3946 49.4394 42.2799L49.4066 40.8664C49.4175 40.5345 49.3744 40.203 49.279 39.8848C48.7228 38.3241 47.4696 39.7998 47.0541 40.572Z"
          fill="#407BFF"
        />
        <path
          d="M46.8971 46.1346C46.6986 45.9925 46.5324 45.8099 46.4095 45.599C46.2866 45.3881 46.2097 45.1536 46.1838 44.9109C45.958 43.758 46.1347 42.5626 46.6844 41.5244L46.9723 41.6782C46.4592 42.6495 46.2941 43.7672 46.5044 44.8454C46.5232 45.0437 46.5829 45.236 46.6798 45.41C46.7766 45.584 46.9086 45.7361 47.0672 45.8565L46.8971 46.1346Z"
          fill="#407BFF"
        />
        <path
          d="M49.1252 45.3623C48.4839 45.049 47.945 44.5595 47.5719 43.9511C47.1987 43.3426 47.0066 42.6404 47.0181 41.9268H47.3453C47.3362 42.5774 47.5122 43.2172 47.8528 43.7716C48.1935 44.326 48.6847 44.7721 49.2692 45.058L49.1252 45.3623Z"
          fill="#407BFF"
        />
        <path
          d="M57.3673 55.3388C56.1557 53.9696 54.7747 52.7601 53.2577 51.7396C53.0568 51.5891 52.8292 51.478 52.5869 51.4124C52.473 51.3781 52.3519 51.3755 52.2366 51.4049C52.1213 51.4343 52.0162 51.4946 51.9326 51.5793C51.8573 51.6857 51.8135 51.8112 51.8059 51.9413C51.7984 52.0714 51.8276 52.2011 51.89 52.3155L51.7362 52.3744C51.6604 52.2328 51.6268 52.0724 51.6396 51.9123C51.6524 51.7522 51.7109 51.5991 51.8082 51.4713C51.9105 51.3671 52.0386 51.2918 52.1794 51.2533C52.3202 51.2148 52.4688 51.2144 52.6099 51.2521C52.8724 51.3238 53.1189 51.4449 53.3362 51.6087C54.8647 52.6385 56.2556 53.859 57.4753 55.2406L57.3673 55.3388Z"
          fill="#263238"
        />
        <path
          d="M58.5386 56.4998C57.6683 54.7919 55.3845 52.1939 53.8401 51.1534C53.6385 51.0042 53.4111 50.8932 53.1693 50.8262C53.0554 50.7919 52.9343 50.7893 52.8189 50.8187C52.7036 50.8481 52.5985 50.9084 52.5149 50.9931C52.4384 51.0991 52.394 51.2249 52.387 51.3554C52.38 51.486 52.4108 51.6158 52.4757 51.7293L52.3219 51.7882C52.2393 51.657 52.1991 51.5035 52.2067 51.3487C52.2143 51.1938 52.2694 51.045 52.3644 50.9225C52.4594 50.8 52.5898 50.7097 52.7379 50.6638C52.886 50.6179 53.0446 50.6186 53.1922 50.6659C53.4553 50.739 53.7026 50.8599 53.9219 51.0226C55.4826 52.0761 57.7959 54.7035 58.676 56.4311L58.5386 56.4998Z"
          fill="#263238"
        />
        <path
          d="M56.3103 55.9502C55.9926 55.7653 55.6649 55.5982 55.3287 55.4496C54.8281 55.2075 54.2555 54.9359 53.9578 54.7559L54.0396 54.6152C54.334 54.7919 54.9001 55.0635 55.4007 55.3023C55.7624 55.4508 56.1105 55.6303 56.4412 55.8389L56.3103 55.9502Z"
          fill="#263238"
        />
        <path
          d="M26.7679 55.8161L26.6501 55.7016C27.1876 55.0855 27.6289 54.3916 27.9589 53.6436L28.1127 53.7025C27.7726 54.4703 27.3193 55.1827 26.7679 55.8161Z"
          fill="#263238"
        />
        <path
          d="M28.5903 56.8403L28.4595 56.7421C29.6836 55.104 30.8069 53.3929 31.823 51.6183C32.0488 51.2223 32.2517 50.7708 32.0652 50.4011C31.9527 50.237 31.7882 50.1155 31.5983 50.0561C31.4084 49.9968 31.2041 50.003 31.0181 50.0739C30.6845 50.199 30.3836 50.3981 30.138 50.6563L30.0267 50.5352C30.2906 50.2612 30.6126 50.0499 30.9691 49.9168C31.1924 49.8369 31.4362 49.8344 31.6611 49.9097C31.886 49.9851 32.0791 50.134 32.2091 50.3324C32.4349 50.7708 32.2091 51.2714 31.9637 51.7033C30.9456 53.4831 29.819 55.1987 28.5903 56.8403Z"
          fill="#263238"
        />
        <path
          d="M29.8796 55.5575L29.7422 55.4692L31.8166 52.1449C32.0268 51.849 32.1789 51.5159 32.2649 51.1633C32.3016 50.9972 32.2903 50.8241 32.2324 50.6642C32.1745 50.5043 32.0723 50.3642 31.9377 50.2602C31.8056 50.1937 31.6587 50.1621 31.5109 50.1684C31.3632 50.1747 31.2195 50.2187 31.0935 50.2962C30.8373 50.4466 30.6119 50.6442 30.4293 50.8786L30.305 50.7739C30.49 50.5251 30.7211 50.3141 30.9855 50.1523C31.136 50.0595 31.308 50.0074 31.4847 50.0011C31.6613 49.9948 31.8366 50.0345 31.9933 50.1163C32.1598 50.2344 32.2882 50.3986 32.3626 50.5886C32.437 50.7787 32.4542 50.9864 32.4121 51.1862C32.3256 51.5618 32.1655 51.9166 31.9409 52.23L29.8796 55.5575Z"
          fill="#263238"
        />
        <path
          d="M27.9426 54.9687L27.8052 54.8837C28.0286 54.5055 28.2201 54.1093 28.3778 53.6992L28.5315 53.7581C28.372 54.1785 28.1749 54.5837 27.9426 54.9687Z"
          fill="#263238"
        />
        <path
          d="M34.8693 56.7846C34.8186 56.7833 34.7704 56.7622 34.7352 56.7257C34.7055 56.7 34.6851 56.6651 34.6774 56.6266C34.6697 56.5881 34.6751 56.5481 34.6926 56.513L34.8399 56.5915C35.1483 55.0405 35.343 53.4691 35.4223 51.8897C35.4848 51.4401 35.4237 50.982 35.2456 50.5646C35.0526 50.1981 34.5683 49.9102 34.1888 50.0999C33.7536 50.3061 33.7078 50.9605 33.7078 51.4349H33.5442C33.5442 50.9179 33.5998 50.1981 34.1201 49.9527C34.5912 49.7269 35.1573 50.0476 35.3896 50.486C35.5811 50.9298 35.649 51.4171 35.5859 51.8963C35.5065 53.5113 35.3053 55.118 34.9839 56.7028V56.7388L34.9479 56.7584C34.9246 56.7743 34.8974 56.7833 34.8693 56.7846Z"
          fill="#263238"
        />
        <path
          d="M33.8308 54.1503L33.6677 54.1636L33.7778 55.517L33.9409 55.5037L33.8308 54.1503Z"
          fill="#263238"
        />
        <path
          d="M35.01 56.8109L34.8464 56.788C34.9658 56.109 35.1386 55.4405 35.3633 54.7888C35.4746 54.4387 35.5891 54.0821 35.6905 53.7254C36.0177 52.4722 36.0177 51.4743 35.6905 50.6661C35.6539 50.542 35.5903 50.4276 35.5043 50.3309C35.4184 50.2342 35.3121 50.1576 35.1932 50.1066C35.0507 50.082 34.9042 50.1025 34.7739 50.1653C34.6437 50.2281 34.5363 50.33 34.4668 50.4567C34.303 50.7495 34.2184 51.0799 34.2214 51.4154H34.0284C34.0256 51.049 34.118 50.6882 34.2967 50.3684C34.3858 50.2118 34.5219 50.0871 34.6857 50.012C34.8495 49.9368 35.0327 49.915 35.2096 49.9496C35.3524 50.0068 35.4806 50.0953 35.5848 50.2085C35.689 50.3217 35.7665 50.4569 35.8116 50.604C36.1617 51.4449 36.1682 52.4788 35.8116 53.7647C35.7167 54.1279 35.6022 54.4878 35.4844 54.8379C35.2737 55.4818 35.1151 56.1416 35.01 56.8109Z"
          fill="#263238"
        />
        <path
          d="M34.2478 54.232L34.0845 54.2417L34.1537 55.411L34.317 55.4013L34.2478 54.232Z"
          fill="#263238"
        />
        <path
          d="M117.682 48.4447C117.067 46.9789 116.259 36.4661 113.412 33.7438C112.542 32.9095 109.571 31.7806 108.485 32.3107C107.536 32.7721 108.635 34.4964 108.58 35.563C108.524 36.6297 108.845 37.6669 109.165 38.6779C109.975 41.2148 110.816 43.7397 111.688 46.2525C112.112 47.5566 112.627 48.8293 113.229 50.0611L117.682 48.4447Z"
          fill="#E29471"
        />
        <g opacity="0.1">
          <path
            opacity="0.1"
            d="M117.682 48.4442C117.067 46.9784 116.259 36.4656 113.412 33.7433C112.542 32.909 109.571 31.7802 108.485 32.3102C107.536 32.7716 108.635 34.4959 108.58 35.5625C108.524 36.6292 108.845 37.6664 109.165 38.6774C109.975 41.2143 110.816 43.7392 111.688 46.252C112.112 47.5561 112.627 48.8288 113.229 50.0606L117.682 48.4442Z"
            fill="black"
          />
        </g>
        <path
          d="M115.68 46.3341C114.753 46.4105 113.892 46.8461 113.282 47.548C112.712 48.2711 112.578 49.3705 113.125 50.11C113.543 50.6825 114.26 50.9476 114.947 51.1243C117.09 51.674 121.246 51.0719 123.382 50.4993C123.983 50.3727 124.545 50.1021 125.018 49.7108C125.251 49.5109 125.421 49.248 125.508 48.9537C125.595 48.6595 125.596 48.3463 125.509 48.0519C125.26 47.3975 124.527 47.103 123.873 46.874C121.776 46.1345 117.941 46.0691 115.68 46.3341Z"
          fill="#E29471"
        />
        <path
          d="M123.382 50.4995L128.483 50.3196C128.483 50.3196 128.843 46.8971 128.742 46.5732C128.742 46.5732 126.834 46.2002 125.394 46.9625L124.122 47.7805L123.382 50.4995Z"
          fill="#E29471"
        />
        <path
          d="M127.753 50.3522L128.588 46.5796L130.315 46.6614C130.391 46.6648 130.465 46.6844 130.532 46.7188C130.6 46.7532 130.659 46.8016 130.706 46.8608C130.753 46.92 130.787 46.9886 130.805 47.062C130.824 47.1354 130.826 47.2119 130.813 47.2863L130.43 49.3542C130.39 49.5712 130.28 49.7691 130.117 49.9179C129.954 50.0667 129.747 50.1583 129.527 50.1787L127.753 50.3522Z"
          fill="#E29471"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M115.68 46.3341C114.753 46.4105 113.892 46.8461 113.282 47.548C112.712 48.2711 112.578 49.3705 113.125 50.11C113.543 50.6825 114.26 50.9476 114.947 51.1243C117.09 51.674 121.246 51.0719 123.382 50.4993C123.983 50.3727 124.545 50.1021 125.018 49.7108C125.251 49.5109 125.421 49.248 125.508 48.9537C125.595 48.6595 125.596 48.3463 125.509 48.0519C125.26 47.3975 124.527 47.103 123.873 46.874C121.776 46.1345 117.941 46.0691 115.68 46.3341Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M123.382 50.4995L128.483 50.3196C128.483 50.3196 128.843 46.8971 128.742 46.5732C128.742 46.5732 126.834 46.2002 125.394 46.9625L124.122 47.7805L123.382 50.4995Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M127.753 50.3522L128.588 46.5796L130.315 46.6614C130.391 46.6648 130.465 46.6844 130.532 46.7188C130.6 46.7532 130.659 46.8016 130.706 46.8608C130.753 46.92 130.787 46.9886 130.805 47.062C130.824 47.1354 130.826 47.2119 130.813 47.2863L130.43 49.3542C130.39 49.5712 130.28 49.7691 130.117 49.9179C129.954 50.0667 129.747 50.1583 129.527 50.1787L127.753 50.3522Z"
            fill="black"
          />
        </g>
        <path
          d="M86.4647 98.8426L77.156 102.494C76.8844 102.259 75.3008 99.1273 75.3008 99.1273L83.5559 95.3154L86.4647 98.8426Z"
          fill="#E29471"
        />
        <path
          d="M77.4568 103.358C77.4516 103.338 77.4528 103.316 77.46 103.297C77.4673 103.277 77.4804 103.261 77.4976 103.249C77.5147 103.237 77.5351 103.231 77.5559 103.231C77.5767 103.231 77.5969 103.238 77.6139 103.25C77.6728 103.289 79.0732 104.231 79.2106 104.941C79.227 105.007 79.2297 105.076 79.2185 105.143C79.2072 105.21 79.1823 105.274 79.1452 105.331C79.1101 105.401 79.0539 105.457 78.9845 105.493C78.9152 105.529 78.8363 105.542 78.7591 105.53C78.4711 105.494 78.1734 105.062 77.9378 104.575C77.7403 104.185 77.5792 103.777 77.4568 103.358ZM78.9619 104.807C78.6407 104.326 78.2283 103.913 77.748 103.59C77.9967 104.333 78.4744 105.285 78.8049 105.341C78.8507 105.341 78.9129 105.341 78.9947 105.223C79.037 105.152 79.051 105.068 79.0339 104.987C79.017 104.924 78.9951 104.863 78.9685 104.804L78.9619 104.807Z"
          fill="#407BFF"
        />
        <path
          d="M77.4767 103.292C77.4812 103.275 77.4907 103.26 77.5042 103.248C77.5176 103.236 77.5342 103.229 77.5519 103.227C77.5977 103.211 78.6578 102.808 79.2926 103.093C79.3811 103.136 79.4599 103.197 79.5241 103.272C79.5882 103.347 79.6364 103.434 79.6656 103.528C79.7077 103.602 79.7219 103.689 79.7057 103.773C79.6895 103.857 79.644 103.933 79.5773 103.986C79.1912 104.271 77.9413 103.773 77.5028 103.42L77.4734 103.384L77.4767 103.292ZM79.1977 103.292C78.7332 103.171 78.2421 103.198 77.794 103.371C78.3045 103.698 79.2664 104.025 79.4791 103.826C79.4987 103.806 79.5544 103.767 79.4955 103.603L79.4562 103.554C79.4091 103.433 79.3162 103.336 79.1977 103.283V103.292Z"
          fill="#407BFF"
        />
        <path
          d="M77.8725 102.432L77.2933 101.928C76.9733 101.651 76.716 101.309 76.5389 100.925C76.3617 100.541 76.2686 100.123 76.2659 99.6997V98.6985C76.2328 98.6216 76.1737 98.5588 76.0989 98.5211C76.0241 98.4834 75.9385 98.4732 75.8569 98.4923L72.4476 99.0944C72.1204 99.1794 72.0091 99.932 72.0255 99.9647C72.7878 102.343 74.8099 107.297 75.5428 108.904C76.2757 110.51 77.9575 109.993 77.8038 109.27C77.1036 106.031 77.4766 104.513 77.9052 103.188C77.9911 102.941 77.9794 102.67 77.8725 102.432Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M80.6667 96.6504L83.5559 95.3154L86.4647 98.8426L82.2111 100.511L81.5207 99.6737C81.1077 99.1714 80.8536 98.5574 80.7911 97.9101L80.6667 96.6504Z"
            fill="black"
          />
        </g>
        <path
          d="M97.4125 62.9196C97.1132 63.5964 96.8936 64.3058 96.7581 65.0333C96.0121 68.8615 94.9717 81.8381 94.2944 86.7722C94.2141 87.2625 94.01 87.7242 93.7013 88.1136C93.3927 88.5029 92.9898 88.807 92.5308 88.9971C89.8379 90.2666 88.0907 90.6527 82.0212 95.6327C82.7542 97.1901 83.4478 98.2829 84.1153 100.109C84.1153 100.109 95.8125 95.6097 98.489 93.411C102.415 90.1685 107.222 73.1249 108.632 68.7339C109.424 66.2341 110.337 63.2501 108.877 61.0939C108.046 59.8767 100.046 56.2023 97.4125 62.9196Z"
          fill="#263238"
        />
        <path
          d="M112.598 34.1003C113.579 35.3829 113.707 37.3526 113.874 38.4716C114.227 40.8471 113.619 52.6686 113.252 54.7758C112.886 56.8829 101.094 57.1643 98.9341 55.2208L94.6348 37.8794C94.4959 37.3171 94.476 36.7319 94.5763 36.1614C94.6767 35.5909 94.8951 35.0477 95.2177 34.5666C95.5402 34.0854 95.9596 33.6769 96.4492 33.3673C96.9387 33.0577 97.4876 32.8538 98.0605 32.7686C99.0421 32.6246 99.7292 32.5036 99.5427 32.4807C99.5427 32.4708 109.188 29.621 112.598 34.1003Z"
          fill="#407BFF"
        />
        <g opacity="0.3">
          <path
            d="M112.598 34.1003C113.579 35.3829 113.707 37.3526 113.874 38.4716C114.227 40.8471 113.619 52.6686 113.252 54.7758C112.886 56.8829 101.094 57.1643 98.9341 55.2208L94.6348 37.8794C94.4959 37.3171 94.476 36.7319 94.5763 36.1614C94.6767 35.5909 94.8951 35.0477 95.2177 34.5666C95.5402 34.0854 95.9596 33.6769 96.4492 33.3673C96.9387 33.0577 97.4876 32.8538 98.0605 32.7686C99.0421 32.6246 99.7292 32.5036 99.5427 32.4807C99.5427 32.4708 109.188 29.621 112.598 34.1003Z"
            fill="white"
          />
        </g>
        <path
          d="M113.252 54.7759C113.583 56.6943 113.663 58.6479 113.488 60.5869C113.199 63.9369 114.189 67.2722 116.259 69.9218C116.259 69.9218 100.792 67.6707 97.1311 63.6625L98.934 55.2209C103.706 55.6851 108.518 55.5356 113.252 54.7759Z"
          fill="#263238"
        />
        <path
          d="M102.262 28.1781C101.022 27.1311 99.9287 25.8452 99.4314 24.2878C99.1871 23.4147 99.1795 22.4924 99.4095 21.6155C99.6396 20.7386 100.099 19.9387 100.74 19.298C100.842 19.2031 101.005 19.2457 101.133 19.298C101.26 19.3504 100.95 19.4583 100.986 19.5663C101.022 19.6743 101.031 19.7136 101.051 19.7855C101.071 19.8575 101.1 19.9524 101.12 20.0342C100.945 20.3542 100.809 20.6935 100.714 21.0452C100.534 21.7159 100.511 22.4189 100.645 23.1C100.74 23.6137 100.822 24.0816 100.907 24.507C101.234 26.1757 101.519 27.2391 102.262 28.1781Z"
          fill="#263238"
        />
        <path
          d="M102.52 28.3906L102.268 28.1844C101.525 27.2454 101.241 26.1754 100.923 24.5133C100.838 24.0847 100.757 23.62 100.662 23.1064C100.527 22.4252 100.551 21.7222 100.73 21.0516C100.826 20.6999 100.962 20.3605 101.136 20.0405C101.532 21.5227 101.591 23.0605 101.728 24.5787C101.802 25.8816 102.069 27.1662 102.52 28.3906Z"
          fill="#263238"
        />
        <path
          d="M102.854 26.604C103.04 28.312 103.181 30.7038 102.245 32.0584C102.084 32.2849 101.994 32.5543 101.986 32.8322C101.979 33.1102 102.054 33.3841 102.203 33.6191C102.746 34.4403 103.996 35.6804 106.859 37.1037C107.706 36.0632 108.011 35.2158 108.069 34.5712C108.094 34.2045 108.007 33.8389 107.818 33.5236C107.629 33.2084 107.348 32.9585 107.013 32.8076C105.632 32.1532 105.782 31.332 106.211 30.0428L102.854 26.604Z"
          fill="#E29471"
        />
        <path
          opacity="0.2"
          d="M104.215 27.9917L106.178 30.0432C106.058 30.3824 105.968 30.7317 105.91 31.087C105.112 30.9037 103.904 30.1054 103.803 29.1925C103.841 28.7647 103.982 28.3526 104.215 27.9917Z"
          fill="black"
        />
        <path
          d="M110.085 26.5681C110 27.5537 109.551 28.4722 108.825 29.1439C108.099 29.8156 107.148 30.1922 106.159 30.2H106.132C105.365 30.2044 104.61 30.0069 103.944 29.6274C103.696 29.4895 103.461 29.3307 103.24 29.153C103.148 29.081 103.06 29.0057 102.978 28.9337C102.718 28.7065 102.479 28.4557 102.265 28.1845C101.522 27.2454 101.238 26.1755 100.92 24.5133C100.835 24.0847 100.753 23.6201 100.658 23.1064C100.524 22.4252 100.548 21.7223 100.727 21.0516C100.823 20.6999 100.959 20.3606 101.133 20.0406C101.21 19.8988 101.297 19.7622 101.391 19.6316C101.902 18.8909 102.631 18.3278 103.477 18.0205C104.322 17.7133 105.242 17.6771 106.11 17.9171C107.598 18.313 108.622 19.5203 109.267 21.0025C109.332 21.1498 109.391 21.3003 109.447 21.4508C110.034 23.0887 110.252 24.8362 110.085 26.5681Z"
          fill="#E29471"
        />
        <path
          d="M105.831 23.303C105.867 23.568 106.031 23.7643 106.198 23.7447C106.365 23.7251 106.479 23.4895 106.45 23.2277C106.42 22.966 106.25 22.7664 106.083 22.786C105.916 22.8056 105.815 23.051 105.831 23.303Z"
          fill="#263238"
        />
        <path
          d="M108.76 22.9364C108.796 23.2047 108.959 23.3978 109.126 23.3782C109.293 23.3585 109.408 23.123 109.378 22.8579C109.349 22.5929 109.179 22.3966 109.012 22.4228C108.845 22.4489 108.73 22.6747 108.76 22.9364Z"
          fill="#263238"
        />
        <path
          d="M107.916 23.5288C108.22 24.1018 108.602 24.6302 109.051 25.0993C108.93 25.211 108.786 25.2951 108.63 25.346C108.474 25.3968 108.308 25.4131 108.145 25.3938L107.916 23.5288Z"
          fill="#EA573F"
        />
        <path
          d="M106.862 26.6075C106.991 26.6114 107.12 26.6037 107.248 26.5846C107.258 26.5842 107.268 26.5817 107.278 26.5774C107.287 26.573 107.295 26.5668 107.302 26.5591C107.308 26.5515 107.314 26.5425 107.317 26.5329C107.32 26.5232 107.321 26.513 107.32 26.5028C107.316 26.4829 107.305 26.4651 107.289 26.453C107.272 26.4409 107.252 26.4353 107.232 26.4374C106.909 26.4854 106.58 26.4555 106.272 26.3502C105.963 26.2449 105.684 26.0672 105.458 25.8321C105.444 25.8182 105.425 25.8105 105.404 25.8105C105.384 25.8105 105.365 25.8182 105.35 25.8321C105.336 25.8472 105.329 25.867 105.329 25.8877C105.329 25.9083 105.336 25.9282 105.35 25.9433C105.545 26.1492 105.78 26.314 106.039 26.4281C106.299 26.5421 106.579 26.6032 106.862 26.6075Z"
          fill="#263238"
        />
        <path
          d="M104.582 22.8547C104.615 22.8563 104.647 22.8469 104.675 22.8279C104.702 22.809 104.722 22.7816 104.732 22.75C104.845 22.5252 105.002 22.326 105.196 22.1649C105.389 22.0039 105.613 21.8845 105.854 21.8142C105.874 21.8089 105.893 21.7997 105.91 21.7871C105.926 21.7745 105.94 21.7588 105.95 21.7408C105.96 21.7229 105.967 21.7031 105.97 21.6826C105.972 21.662 105.971 21.6412 105.966 21.6212C105.961 21.6018 105.953 21.5835 105.941 21.5673C105.929 21.5512 105.914 21.5376 105.897 21.5272C105.88 21.5169 105.861 21.51 105.842 21.507C105.822 21.5041 105.802 21.5051 105.782 21.5099C105.491 21.5913 105.222 21.735 104.992 21.9311C104.762 22.1271 104.577 22.3708 104.451 22.6453C104.443 22.6642 104.44 22.6843 104.441 22.7045C104.441 22.7247 104.446 22.7446 104.455 22.7629C104.463 22.7812 104.475 22.7977 104.491 22.8112C104.506 22.8247 104.523 22.8351 104.542 22.8416C104.554 22.8486 104.568 22.853 104.582 22.8547Z"
          fill="#263238"
        />
        <path
          d="M109.47 21.4475C109.497 21.4481 109.524 21.4414 109.549 21.4282C109.573 21.415 109.593 21.3957 109.607 21.3722C109.629 21.3372 109.636 21.2953 109.627 21.2551C109.619 21.2149 109.595 21.1796 109.561 21.1563C109.358 21.0143 109.122 20.9257 108.876 20.8988C108.63 20.872 108.38 20.9076 108.151 21.0025C108.116 21.0183 108.088 21.0473 108.073 21.0832C108.058 21.1192 108.058 21.1595 108.073 21.1956C108.081 21.2136 108.092 21.2299 108.107 21.2435C108.121 21.2571 108.138 21.2677 108.157 21.2746C108.175 21.2816 108.195 21.2849 108.215 21.2842C108.235 21.2836 108.254 21.279 108.272 21.2708C108.453 21.2035 108.647 21.1808 108.839 21.2047C109.03 21.2287 109.213 21.2985 109.372 21.4082C109.399 21.4318 109.434 21.4456 109.47 21.4475Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M102.52 25.4C102.383 25.58 101.944 24.618 101.722 24.5722C101.457 24.5162 101.187 24.4942 100.917 24.5068C100.832 24.0781 100.75 23.6135 100.655 23.0998C100.521 22.4186 100.544 21.7157 100.724 21.045C100.819 20.6933 100.955 20.354 101.13 20.034C101.11 19.9522 101.087 19.8671 101.061 19.7853C101.165 19.722 101.274 19.6683 101.388 19.625C101.645 19.5237 101.924 19.4943 102.196 19.5399C102.399 19.588 102.587 19.6837 102.745 19.8192C102.903 19.9546 103.027 20.1258 103.106 20.3187C101.983 22.4192 102.945 24.847 102.52 25.4Z"
          fill="black"
        />
        <path
          d="M100.096 25.7076C100.36 26.258 100.83 26.6824 101.404 26.8888C102.177 27.144 102.595 26.4504 102.412 25.6847C102.242 25.0009 101.64 24.0488 100.855 24.1044C100.578 24.138 100.326 24.2797 100.154 24.4986C99.9816 24.7175 99.903 24.9958 99.9353 25.2725C99.9564 25.4275 100.011 25.576 100.096 25.7076Z"
          fill="#E29471"
        />
        <path
          d="M136.369 103.508L137.141 113.478C136.836 113.671 133.512 115.222 133.378 114.27C132.976 111.364 132.148 105.262 132.148 105.262L136.369 103.508Z"
          fill="#E29471"
        />
        <path
          d="M138.054 113.445C138.033 113.442 138.014 113.434 137.998 113.422C137.982 113.409 137.969 113.392 137.962 113.373C137.958 113.353 137.958 113.332 137.965 113.313C137.971 113.293 137.983 113.275 137.998 113.262C138.054 113.216 139.362 112.153 140.082 112.231C140.15 112.234 140.216 112.251 140.277 112.281C140.338 112.311 140.392 112.353 140.436 112.405C140.492 112.459 140.529 112.53 140.543 112.606C140.557 112.683 140.547 112.762 140.514 112.833C140.396 113.098 139.893 113.255 139.359 113.34C138.928 113.416 138.491 113.451 138.054 113.445ZM139.883 112.428C139.328 112.595 138.812 112.868 138.361 113.232C139.147 113.213 140.197 113.036 140.347 112.735C140.364 112.693 140.377 112.63 140.285 112.519C140.259 112.488 140.227 112.463 140.191 112.445C140.155 112.427 140.116 112.416 140.076 112.414C140.01 112.408 139.945 112.408 139.879 112.414L139.883 112.428Z"
          fill="#407BFF"
        />
        <path
          d="M137.998 113.406C137.984 113.396 137.972 113.382 137.965 113.366C137.957 113.35 137.954 113.332 137.956 113.314C137.956 113.265 137.88 112.136 138.338 111.613C138.406 111.541 138.487 111.483 138.578 111.443C138.669 111.403 138.766 111.382 138.865 111.38C138.949 111.363 139.036 111.375 139.111 111.415C139.187 111.455 139.245 111.521 139.277 111.6C139.434 112.055 138.59 113.111 138.126 113.419L138.086 113.435L137.998 113.406ZM138.492 111.77C138.243 112.181 138.129 112.659 138.165 113.138C138.626 112.742 139.205 111.917 139.094 111.655C139.094 111.632 139.061 111.567 138.885 111.577L138.826 111.6C138.699 111.61 138.581 111.666 138.492 111.757V111.77Z"
          fill="#407BFF"
        />
        <path
          d="M137.288 112.781L133.247 113.233C133.163 113.24 133.084 113.277 133.024 113.337C132.964 113.397 132.927 113.476 132.92 113.56L132.514 116.999C132.498 117.346 133.188 117.653 133.224 117.653C135.721 117.62 141.047 117.133 142.798 116.904C144.548 116.675 144.548 114.911 143.812 114.849C140.508 114.568 139.169 113.76 138.024 112.974C137.813 112.818 137.549 112.749 137.288 112.781Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M132.56 108.279L132.148 105.262L136.369 103.508L136.637 107.592L132.56 108.279Z"
            fill="black"
          />
        </g>
        <path
          d="M106.617 66.2965C108.737 70.0429 118.396 77.5815 123.326 83.3107C124.933 85.1692 130.417 100.652 131.817 106.339C133.074 106.823 134.742 106.404 136.012 105.946C137.193 105.521 137.098 102.439 136.853 96.4378C136.669 92.7116 136.216 89.0036 135.498 85.3426C133.996 77.3525 123.922 67.2356 114.083 58.2115C112.447 56.6999 109.98 56.2483 107.507 57.7142C107.055 57.9759 104.562 62.6581 106.617 66.2965Z"
          fill="#263238"
        />
        <path
          d="M112.323 51.3372L117.156 50.9772C117.156 50.9772 117.316 47.5417 117.195 47.2243C117.195 47.2243 115.271 46.9593 113.874 47.8067L112.653 48.6967L112.323 51.3372Z"
          fill="#E29471"
        />
        <path
          d="M116.429 51.0423L117.048 47.2272L118.775 47.2076C118.851 47.2065 118.926 47.2217 118.995 47.2521C119.065 47.2826 119.127 47.3275 119.177 47.3839C119.228 47.4404 119.266 47.507 119.288 47.5793C119.311 47.6516 119.318 47.7279 119.309 47.8031L119.05 49.8873C119.022 50.1067 118.924 50.3112 118.77 50.4699C118.616 50.6286 118.415 50.7331 118.196 50.7675L116.429 51.0423Z"
          fill="#E29471"
        />
        <path
          d="M96.5912 53.6602C99.6505 55.0966 112.601 51.9228 112.647 51.1604C112.719 49.9727 112.931 48.3433 112.931 48.3433C111.868 47.7609 103.832 49.3085 100.537 48.9977C98.3057 48.7752 100.691 34.1495 97.0428 33.3708C91.1271 32.0849 92.9757 51.9523 96.5912 53.6602Z"
          fill="#E29471"
        />
        <path
          d="M105.03 14.6549C104.886 15.005 104.768 16.0553 104.961 16.3793C105.247 15.6202 105.53 14.8567 105.812 14.0889C105.9 14.6942 105.884 15.5155 105.972 16.1208C106.289 15.4132 106.497 14.6621 106.591 13.8926C106.797 15.3224 106.715 16.0848 107.31 17.014C107.906 17.9433 108.757 19.0066 108.946 20.3252C108.619 19.9835 108.325 19.611 108.069 19.2128C108.203 19.603 108.234 20.0212 108.158 20.4267C107.38 20.3874 106.636 20.0969 106.038 19.5989C106.156 19.9154 106.236 20.2449 106.276 20.5805C105.743 20.2173 104.264 19.877 103.351 20.0079C103.076 20.0471 103.351 20.1518 103.112 20.2925C102.563 20.6197 101.65 20.6001 101.015 20.5085C99.052 20.2336 101.506 18.686 101.997 18.2835C103.067 17.4165 104.179 15.7674 105.03 14.6549Z"
          fill="#263238"
        />
        <path
          d="M107.749 60.6328C107.346 60.6328 107.003 60.3056 106.826 59.7363C106.641 59.1469 106.602 58.5216 106.712 57.9138L105.331 59.0459C105.22 59.1684 105.08 59.2602 104.923 59.3124C104.767 59.3646 104.599 59.3753 104.438 59.3436C104.36 59.3164 104.29 59.2732 104.231 59.2168C104.171 59.1604 104.125 59.0921 104.094 59.0164C104.045 58.8783 104.023 58.7317 104.03 58.5852C104.037 58.4387 104.074 58.295 104.137 58.1624C104.384 57.6731 104.809 57.2963 105.324 57.1085C105.839 56.9208 106.407 56.9361 106.911 57.1514V57.0042L107.238 56.9878V57.0663L107.33 56.9911L107.284 57.1252C107.54 57.1371 107.787 57.2163 108.002 57.3547C108.217 57.4932 108.392 57.6861 108.508 57.9138C108.741 58.4203 108.801 58.989 108.681 59.5334C108.599 60.0405 108.308 60.6295 107.762 60.636L107.749 60.6328ZM107.17 57.4622L107.15 57.5244C106.929 58.2117 106.929 58.9508 107.15 59.6381C107.251 59.9653 107.454 60.2925 107.768 60.2925C108.135 60.2925 108.318 59.769 108.37 59.4647C108.477 58.9904 108.427 58.4942 108.226 58.0512C108.146 57.8939 108.031 57.7574 107.889 57.6524C107.747 57.5474 107.582 57.4767 107.408 57.4459H107.183L107.17 57.4622ZM106.083 57.2954C105.86 57.295 105.639 57.3337 105.429 57.4099C105.211 57.4836 105.009 57.6 104.836 57.7522C104.663 57.9044 104.522 58.0895 104.421 58.2966C104.333 58.4806 104.319 58.6915 104.382 58.8855C104.395 58.9196 104.415 58.9504 104.441 58.9759C104.467 59.0013 104.498 59.0207 104.532 59.0328C104.709 59.0982 104.925 58.9477 105.112 58.7939L106.731 57.4655H106.669V57.4034C106.482 57.3318 106.284 57.2952 106.083 57.2954Z"
          fill="white"
        />
        <path
          d="M102.962 64.1597L102.729 63.9274C104.554 62.1012 105.881 59.8382 106.584 57.354L106.911 57.4423C106.191 59.9823 104.831 62.295 102.962 64.1597Z"
          fill="white"
        />
        <path
          d="M109.283 65.5568L108.956 65.5208C109.242 62.7304 108.484 59.9331 106.829 57.6681L107.094 57.4751C108.797 59.8061 109.577 62.685 109.283 65.5568Z"
          fill="white"
        />
        <path
          d="M82.594 96.7811L82.4402 96.4898C86.1179 94.5692 95.6654 89.141 95.6654 87.119C95.6654 82.9538 99.739 55.5707 99.7816 55.2959L100.109 55.3417C100.066 55.6198 95.9959 82.9799 95.9959 87.119C95.9926 89.7529 83.1404 96.4931 82.594 96.7811Z"
          fill="white"
        />
        <path d="M119.839 42.8232H111.07V48.3267H119.839V42.8232Z" fill="#407BFF" />
        <path d="M132.396 42.8232H119.547V48.3267H132.396V42.8232Z" fill="#407BFF" />
        <path opacity="0.2" d="M132.396 42.8232H119.547V48.3267H132.396V42.8232Z" fill="black" />
        <path d="M120.15 41.6455H110.458V42.8234H120.15V41.6455Z" fill="#263238" />
        <path d="M132.887 41.6455H120.153V42.8234H132.887V41.6455Z" fill="#407BFF" />
        <path d="M132.887 41.6455H120.153V42.8234H132.887V41.6455Z" fill="#263238" />
        <path
          d="M116.256 42.6367C116.256 42.8997 116.151 43.1518 115.965 43.3377C115.779 43.5237 115.527 43.6281 115.264 43.6281C115.001 43.6281 114.749 43.5237 114.563 43.3377C114.377 43.1518 114.273 42.8997 114.273 42.6367H116.256Z"
          fill="#263238"
        />
        <path d="M119.325 45.6602H114.604V48.1239H119.325V45.6602Z" fill="white" />
        <path opacity="0.2" d="M132.887 41.6455H120.153V42.8234H132.887V41.6455Z" fill="black" />
        <path
          d="M120.343 35.4775L111.574 35.0391L111.299 40.5425L120.068 40.9809L120.343 35.4775Z"
          fill="#407BFF"
        />
        <path
          d="M133.009 35.4375L120.17 34.8545L119.921 40.3523L132.76 40.9353L133.009 35.4375Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M133.009 35.4375L120.17 34.8545L119.921 40.3523L132.76 40.9353L133.009 35.4375Z"
          fill="black"
        />
        <path
          d="M120.827 33.7033L111.146 33.2637L111.092 34.4404L120.774 34.88L120.827 33.7033Z"
          fill="#263238"
        />
        <path
          d="M133.552 34.2798L120.831 33.7021L120.777 34.8788L133.498 35.4565L133.552 34.2798Z"
          fill="#407BFF"
        />
        <path
          d="M133.552 34.2798L120.831 33.7021L120.777 34.8788L133.498 35.4565L133.552 34.2798Z"
          fill="#263238"
        />
        <path
          d="M116.891 34.5223C116.885 34.6525 116.853 34.7802 116.798 34.8982C116.742 35.0162 116.664 35.1221 116.568 35.2099C116.472 35.2977 116.359 35.3657 116.236 35.4099C116.114 35.4542 115.984 35.4739 115.853 35.4679C115.723 35.4619 115.595 35.4303 115.477 35.3749C115.36 35.3195 115.254 35.2414 115.166 35.1451C115.078 35.0488 115.01 34.9361 114.966 34.8135C114.921 34.691 114.902 34.5609 114.908 34.4307L116.891 34.5223Z"
          fill="#263238"
        />
        <path
          d="M119.822 37.6766L115.105 37.4624L114.994 39.9237L119.71 40.1378L119.822 37.6766Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M133.552 34.2798L120.831 33.7021L120.777 34.8788L133.498 35.4565L133.552 34.2798Z"
          fill="black"
        />
        <path
          d="M132.766 25.2232C132.753 25.2078 132.744 25.1889 132.74 25.1687C132.737 25.1485 132.739 25.1278 132.746 25.1087C132.756 25.0907 132.771 25.0757 132.789 25.0653C132.807 25.0549 132.827 25.0496 132.848 25.0498C132.92 25.0498 134.602 25.1578 135.083 25.7042C135.13 25.753 135.167 25.811 135.191 25.8746C135.215 25.9382 135.226 26.006 135.223 26.0739C135.228 26.1517 135.208 26.229 135.167 26.2948C135.125 26.3606 135.063 26.4116 134.991 26.4404C134.726 26.5549 134.248 26.3324 133.797 26.0314C133.429 25.7949 133.084 25.5243 132.766 25.2232ZM134.798 25.7107C134.278 25.4589 133.713 25.311 133.136 25.2756C133.725 25.7925 134.621 26.3717 134.935 26.2572C134.975 26.2375 135.027 26.2015 135.037 26.0608C135.038 26.0199 135.032 25.9792 135.017 25.9409C135.002 25.9027 134.98 25.8678 134.952 25.8384C134.905 25.7925 134.855 25.7509 134.801 25.714L134.798 25.7107Z"
          fill="#407BFF"
        />
        <path
          d="M132.75 25.1578C132.746 25.1405 132.747 25.1225 132.752 25.1057C132.758 25.0889 132.769 25.0741 132.782 25.0629C132.815 25.0269 133.525 24.1435 134.216 24.0813C134.314 24.0736 134.413 24.0859 134.506 24.1174C134.599 24.1489 134.685 24.1989 134.759 24.2646C134.832 24.3082 134.888 24.3763 134.917 24.457C134.946 24.5377 134.945 24.6259 134.916 24.7063C134.723 25.148 133.388 25.3607 132.838 25.2658H132.796L132.75 25.1578ZM134.232 24.2777C133.768 24.4073 133.357 24.6798 133.057 25.0564C133.663 25.076 134.651 24.8601 134.742 24.5918C134.742 24.5689 134.778 24.5067 134.644 24.3922L134.585 24.3725C134.483 24.298 134.357 24.2642 134.232 24.2777Z"
          fill="#407BFF"
        />
        <path
          d="M132.652 24.2123L129.38 21.8074C129.312 21.7585 129.23 21.7346 129.147 21.74C129.064 21.7454 128.985 21.7796 128.925 21.8369L126.298 24.088C126.049 24.3334 126.343 25.0336 126.373 25.0532C128.238 26.7186 132.485 29.9611 133.931 30.982C135.377 32.0028 136.568 30.6973 136.068 30.1542C133.823 27.7133 133.385 26.2082 133.077 24.8569C133.026 24.5943 132.873 24.3627 132.652 24.2123Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M132.652 24.2123L129.38 21.8074C129.312 21.7585 129.23 21.7346 129.147 21.74C129.064 21.7454 128.985 21.7796 128.925 21.8369L126.298 24.088C126.049 24.3334 126.343 25.0336 126.373 25.0532C128.238 26.7186 132.485 29.9611 133.931 30.982C135.377 32.0028 136.568 30.6973 136.068 30.1542C133.823 27.7133 133.385 26.2082 133.077 24.8569C133.026 24.5943 132.873 24.3627 132.652 24.2123Z"
          fill="white"
        />
        <path
          d="M131.454 29.4477C130.809 26.8749 129.748 24.4247 128.313 22.1938L128.588 22.0171C130.043 24.2767 131.118 26.7591 131.771 29.3659L131.454 29.4477Z"
          fill="#407BFF"
        />
        <path
          d="M132.622 30.2984C131.762 27.2309 130.489 24.2943 128.84 21.5688L129.115 21.3921C130.784 24.1501 132.07 27.1221 132.94 30.2264L132.622 30.2984Z"
          fill="#407BFF"
        />
        <path
          d="M124.927 26.8022L116.371 24.8818L115.166 30.2517L123.722 32.172L124.927 26.8022Z"
          fill="#407BFF"
        />
        <path
          d="M137.179 29.5517L124.642 26.7378L123.437 32.1076L135.974 34.9215L137.179 29.5517Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M137.179 29.5517L124.642 26.7378L123.437 32.1076L135.974 34.9215L137.179 29.5517Z"
          fill="black"
        />
        <path
          d="M119.515 25.5898C119.492 25.828 119.557 26.0663 119.697 26.26C119.838 26.4536 120.044 26.5894 120.278 26.6416C120.511 26.6938 120.756 26.659 120.965 26.5437C121.175 26.4283 121.335 26.2404 121.416 26.0152L119.515 25.5898Z"
          fill="#263238"
        />
        <path
          d="M123.809 29.4554L119.203 28.4214L118.663 30.8254L123.27 31.8593L123.809 29.4554Z"
          fill="white"
        />
        <path
          d="M135.855 18.5023L130.669 10.3149L129.674 10.9452L134.86 19.1326L135.855 18.5023Z"
          fill="#263238"
        />
        <path
          d="M142.668 29.2424L135.854 18.4844L134.859 19.1147L141.673 29.8727L142.668 29.2424Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M142.668 29.2424L135.854 18.4844L134.859 19.1147L141.673 29.8727L142.668 29.2424Z"
          fill="black"
        />
        <path
          d="M125.391 20.0932C125.371 20.0921 125.351 20.0849 125.335 20.0725C125.318 20.06 125.306 20.0429 125.3 20.0234C125.294 20.0039 125.293 19.983 125.299 19.9633C125.305 19.9436 125.316 19.9262 125.332 19.9132C125.388 19.8674 126.697 18.804 127.42 18.8826C127.487 18.8855 127.554 18.9024 127.615 18.9323C127.675 18.9621 127.729 19.0042 127.773 19.056C127.829 19.1106 127.866 19.1813 127.88 19.2582C127.893 19.3351 127.882 19.4143 127.848 19.4846C127.731 19.7496 127.23 19.9067 126.693 19.9918C126.264 20.0664 125.828 20.1004 125.391 20.0932ZM127.217 19.0789C126.664 19.2464 126.148 19.5197 125.699 19.8838C126.481 19.8641 127.531 19.6842 127.682 19.3864C127.682 19.3439 127.714 19.2817 127.623 19.1705C127.568 19.109 127.492 19.0705 127.41 19.0625C127.345 19.0576 127.279 19.0576 127.214 19.0625L127.217 19.0789Z"
          fill="#407BFF"
        />
        <path
          d="M125.332 20.0572C125.318 20.0474 125.306 20.0335 125.299 20.0172C125.292 20.0009 125.29 19.983 125.293 19.9656C125.293 19.9165 125.218 18.7877 125.676 18.2642C125.742 18.1917 125.823 18.1337 125.913 18.0937C126.003 18.0538 126.101 18.0327 126.199 18.0319C126.283 18.0141 126.37 18.0264 126.445 18.0664C126.521 18.1065 126.58 18.1719 126.612 18.2511C126.772 18.7059 125.928 19.7627 125.463 20.0703H125.424L125.332 20.0572ZM125.83 18.4212C125.581 18.832 125.466 19.3101 125.502 19.7889C125.96 19.393 126.543 18.5685 126.428 18.3067C126.428 18.2838 126.396 18.2184 126.222 18.2249L126.163 18.2511C126.036 18.2591 125.917 18.3152 125.83 18.4081V18.4212Z"
          fill="#407BFF"
        />
        <path
          d="M124.626 19.4322L120.581 19.8837C120.497 19.8905 120.417 19.9271 120.358 19.9871C120.298 20.047 120.261 20.1264 120.254 20.2109L119.835 23.6465C119.816 23.9933 120.49 24.3009 120.545 24.3009C123.042 24.2649 128.369 23.7806 130.119 23.5516C131.87 23.3226 131.866 21.559 131.13 21.4968C127.829 21.2154 126.487 20.4073 125.345 19.622C125.137 19.4713 124.881 19.4036 124.626 19.4322Z"
          fill="#263238"
        />
        <path
          opacity="0.8"
          d="M124.626 19.4322L120.581 19.8837C120.497 19.8905 120.417 19.9271 120.358 19.9871C120.298 20.047 120.261 20.1264 120.254 20.2109L119.835 23.6465C119.816 23.9933 120.49 24.3009 120.545 24.3009C123.042 24.2649 128.369 23.7806 130.119 23.5516C131.87 23.3226 131.866 21.559 131.13 21.4968C127.829 21.2154 126.487 20.4073 125.345 19.622C125.137 19.4713 124.881 19.4036 124.626 19.4322Z"
          fill="white"
        />
        <path
          d="M127.279 24.0818C125.063 22.6251 122.624 21.5398 120.058 20.8687L120.143 20.5415C122.743 21.2285 125.214 22.3336 127.459 23.8135L127.279 24.0818Z"
          fill="#407BFF"
        />
        <path
          d="M128.719 23.9276C126.009 22.2516 123.084 20.9506 120.025 20.0601L120.11 19.7329C123.204 20.6385 126.16 21.9593 128.899 23.6593L128.719 23.9276Z"
          fill="#407BFF"
        />
        <path
          d="M123.984 53.5522C123.984 53.5522 152.692 67.1374 136.843 76.8453C121.203 86.4256 124.383 53.549 123.984 53.5522Z"
          fill="white"
        />
        <path
          d="M134.15 76.1743C133.757 76.4687 133.328 76.7127 132.874 76.9006C132.234 77.1765 131.539 77.305 130.842 77.2769C129.846 77.0022 128.916 76.5268 128.109 75.8797C127.303 75.2327 126.638 74.4277 126.154 73.5142L126.281 73.632C126.379 73.7203 126.484 73.8054 126.592 73.8872C127.201 74.345 127.875 74.7099 128.591 74.9702C129.931 75.4481 131.33 75.7387 132.75 75.834C133.222 75.9223 133.69 76.0359 134.15 76.1743Z"
          fill="#407BFF"
        />
        <path
          d="M135.675 77.1886C134.56 77.5953 133.365 77.7343 132.187 77.5944C132.54 77.5264 132.885 77.4233 133.218 77.2868C133.765 77.0621 134.279 76.7614 134.742 76.3936C135.027 76.4728 135.288 76.6186 135.505 76.8189C135.591 76.9264 135.649 77.0533 135.675 77.1886Z"
          fill="#407BFF"
        />
        <path
          d="M138.361 75.4806C137.681 76.1321 136.884 76.6495 136.012 77.0053C135.968 76.844 135.891 76.6936 135.786 76.5636C135.582 76.3543 135.331 76.1972 135.053 76.1055C135.164 76.0074 135.276 75.9092 135.38 75.8045C135.637 75.5561 135.877 75.2916 136.1 75.0127C136.811 75.3317 137.582 75.4914 138.361 75.4806Z"
          fill="#407BFF"
        />
        <path
          d="M139.192 74.5548C139.055 74.7347 138.908 74.9082 138.757 75.0718V75.0456C138.378 75.1928 137.776 75.147 136.977 74.9114C136.784 74.8525 136.578 74.7838 136.369 74.702C136.709 74.2497 137.015 73.7729 137.285 73.2755C137.567 73.3725 137.865 73.413 138.163 73.3944C138.46 73.3759 138.751 73.2988 139.019 73.1675C139.65 72.8403 140.203 72.1859 140.655 71.165C140.457 72.3991 139.955 73.5643 139.192 74.5548Z"
          fill="#407BFF"
        />
        <path
          d="M138.993 72.739C138.768 72.8511 138.524 72.9177 138.273 72.9351C138.023 72.9526 137.771 72.9204 137.533 72.8404C137.589 72.739 137.638 72.6376 137.691 72.5329C138.361 71.2177 138.788 69.7922 138.95 68.3251C139.051 67.3197 138.951 66.3042 138.656 65.3378C138.487 64.7918 138.242 64.272 137.929 63.7935C139.26 64.8144 140.211 66.2504 140.632 67.8736C140.811 68.5278 140.85 69.2128 140.744 69.883C140.639 70.5531 140.392 71.1932 140.02 71.7607C139.766 72.1681 139.412 72.5046 138.993 72.739Z"
          fill="#407BFF"
        />
        <path
          d="M137.111 72.6374C136.434 72.3102 135.803 71.3024 135.58 70.6742C133.437 64.6996 132.082 62.8706 131.356 62.3471C133.26 61.8755 135.272 62.163 136.967 63.1487C137.558 63.7728 137.993 64.5272 138.237 65.3507C138.672 66.7413 138.859 69.0742 137.281 72.3102C137.226 72.4116 137.17 72.5229 137.111 72.6374Z"
          fill="#407BFF"
        />
        <path
          d="M135.943 74.4634C135.35 74.2181 134.773 73.9362 134.215 73.6192C132.243 72.5251 130.463 71.1147 128.948 69.4442C127.606 67.89 127.073 66.8463 126.863 66.2442C126.801 66.0713 126.756 65.8926 126.729 65.7109C126.837 65.544 126.952 65.3837 127.076 65.2201C128.028 63.9775 129.343 63.0626 130.839 62.6025C130.989 62.6483 131.303 62.8218 131.798 63.4991C132.763 64.8078 133.905 67.3142 135.197 70.9166C135.474 71.6612 135.936 72.3232 136.539 72.8405C136.64 72.9205 136.75 72.9895 136.866 73.0466C136.595 73.5416 136.286 74.0152 135.943 74.4634Z"
          fill="#407BFF"
        />
        <path
          d="M135.675 74.8065C135.348 75.2032 134.984 75.5681 134.588 75.8961C134.053 75.7217 133.507 75.584 132.952 75.4838C129.409 75.1861 127.58 74.175 126.67 73.3734C126.376 73.1179 126.118 72.8241 125.901 72.4998C125.53 71.47 125.386 70.3719 125.48 69.2811C125.574 68.1904 125.903 67.133 126.445 66.1816C126.461 66.2438 126.481 66.3093 126.507 66.3812C126.808 67.3007 127.537 68.4327 128.666 69.7415C129.471 70.6501 130.365 71.4765 131.333 72.2086C132.183 72.8615 133.078 73.453 134.013 73.9787C134.55 74.2862 135.105 74.5626 135.675 74.8065Z"
          fill="#407BFF"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M134.15 76.1743C133.757 76.4687 133.328 76.7127 132.874 76.9006C132.233 77.1765 131.539 77.305 130.842 77.2769C129.845 77.0022 128.915 76.5268 128.109 75.8797C127.303 75.2327 126.637 74.4277 126.153 73.5142L126.281 73.632C126.379 73.7203 126.484 73.8054 126.592 73.8872C127.201 74.345 127.875 74.7099 128.591 74.9702C129.931 75.4481 131.33 75.7387 132.75 75.834C133.222 75.9223 133.69 76.0359 134.15 76.1743Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M135.675 77.1886C134.56 77.5953 133.365 77.7343 132.187 77.5944C132.54 77.5264 132.885 77.4233 133.217 77.2868C133.765 77.0621 134.278 76.7614 134.742 76.3936C135.026 76.4728 135.288 76.6186 135.505 76.8189C135.591 76.9264 135.649 77.0533 135.675 77.1886Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M138.361 75.4806C137.681 76.1321 136.884 76.6495 136.012 77.0053C135.968 76.844 135.891 76.6936 135.786 76.5636C135.582 76.3543 135.33 76.1972 135.053 76.1055C135.164 76.0074 135.275 75.9092 135.38 75.8045C135.637 75.5561 135.877 75.2916 136.1 75.0127C136.811 75.3317 137.582 75.4914 138.361 75.4806Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M139.192 74.5548C139.055 74.7347 138.907 74.9082 138.757 75.0718V75.0456C138.377 75.1928 137.775 75.147 136.977 74.9114C136.784 74.8525 136.578 74.7838 136.368 74.702C136.709 74.2497 137.015 73.7729 137.285 73.2755C137.566 73.3725 137.865 73.413 138.162 73.3944C138.46 73.3759 138.751 73.2988 139.019 73.1675C139.65 72.8403 140.203 72.1859 140.655 71.165C140.457 72.3991 139.954 73.5643 139.192 74.5548Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M138.992 72.739C138.768 72.8511 138.523 72.9177 138.273 72.9351C138.023 72.9526 137.771 72.9204 137.533 72.8404C137.589 72.739 137.638 72.6376 137.69 72.5329C138.36 71.2177 138.787 69.7922 138.95 68.3251C139.051 67.3197 138.951 66.3042 138.655 65.3378C138.486 64.7918 138.242 64.272 137.929 63.7935C139.259 64.8144 140.211 66.2504 140.632 67.8736C140.811 68.5278 140.85 69.2128 140.744 69.883C140.639 70.5531 140.392 71.1932 140.02 71.7607C139.765 72.1681 139.412 72.5046 138.992 72.739Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M137.111 72.6374C136.434 72.3102 135.802 71.3024 135.58 70.6742C133.437 64.6996 132.082 62.8706 131.356 62.3471C133.259 61.8755 135.272 62.163 136.967 63.1487C137.557 63.7728 137.992 64.5272 138.237 65.3507C138.672 66.7413 138.858 69.0742 137.281 72.3102C137.226 72.4116 137.17 72.5229 137.111 72.6374Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M135.943 74.4629C135.35 74.2176 134.773 73.9357 134.215 73.6187C132.243 72.5247 130.463 71.1142 128.948 69.4437C127.606 67.8895 127.073 66.8458 126.863 66.2437C126.801 66.0708 126.756 65.8922 126.729 65.7104C126.837 65.5435 126.952 65.3832 127.076 65.2196C128.028 63.9771 129.343 63.0621 130.839 62.6021C130.989 62.6479 131.303 62.8213 131.798 63.4986C132.763 64.8074 133.905 67.3137 135.197 70.9161C135.474 71.6607 135.936 72.3227 136.539 72.84C136.64 72.92 136.75 72.9891 136.866 73.0461C136.595 73.5411 136.286 74.0147 135.943 74.4629Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M135.675 74.8065C135.348 75.2032 134.984 75.5681 134.588 75.8961C134.053 75.7217 133.507 75.584 132.952 75.4838C129.409 75.1861 127.58 74.175 126.67 73.3734C126.376 73.1179 126.118 72.8241 125.901 72.4998C125.53 71.47 125.386 70.3719 125.48 69.2811C125.574 68.1904 125.903 67.133 126.445 66.1816C126.461 66.2438 126.481 66.3093 126.507 66.3812C126.808 67.3007 127.537 68.4327 128.666 69.7415C129.471 70.6501 130.365 71.4765 131.333 72.2086C132.183 72.8615 133.078 73.453 134.013 73.9787C134.55 74.2862 135.105 74.5626 135.675 74.8065Z"
            fill="white"
          />
        </g>
        <path
          d="M131.736 78.7561C130.834 78.7691 129.946 78.5433 129.16 78.1017C123.428 74.8101 123.739 60.1059 123.84 55.2601C123.84 54.5272 123.87 53.8794 123.84 53.6831H123.814V53.6569C123.402 53.297 120.958 51.0655 120.896 48.2549H121.223C121.272 50.5256 123.039 52.4659 123.768 53.1628C123.655 52.2886 123.429 51.4329 123.094 50.6172L123.395 50.4896C123.787 51.4398 124.034 52.4435 124.128 53.4671C125.597 54.1706 141.332 61.9055 141.761 69.8727C141.905 72.5492 140.275 74.9508 136.918 77.0088C135.001 78.1638 133.264 78.7561 131.736 78.7561ZM124.184 53.8303C124.184 54.0822 124.184 54.5076 124.167 55.2536C124.066 60.0405 123.758 74.6072 129.324 77.8039C131.255 78.9131 133.758 78.5434 136.758 76.7046C140.004 74.7185 141.581 72.415 141.443 69.8662C141.044 62.4421 126.376 54.9133 124.184 53.8303Z"
          fill="#407BFF"
        />
      </svg>
    </div>
  );
}
