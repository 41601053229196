import React from 'react';
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { generateUrl } from '../../../utils/ImageUtils';
import RoutesPaths from '../../../constants/routes';

export default function Information(props) {
  const { avgRating, name, totalRatings, totalReviews, images, productId } = props;
  return (
    <NavLink to={`${RoutesPaths.PRODUCT_DETAIL}/${productId}`}>
      <div className="app-product-review-information">
        <div className="app-product-review-image">
          <img
            src={generateUrl(images ? images[0] : '')}
            alt=""
            srcSet=""
            className="product-image"
          />
        </div>
        <div>
          <div className="app-product-info-detail">
            <div className="title">{name}</div>
            <div className="rating-detail-pg-wrapper">
              <div className="rating-box">
                <Rating
                  name="read-only"
                  value={Number(avgRating).toFixed(1)}
                  readOnly
                  size="large"
                />
              </div>
              <div className="rating-text">{`${totalRatings} Reviews & ${totalReviews} Reviews`}</div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
Information.propTypes = {
  name: PropTypes.string,
  avgRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalRatings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalReviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.arrayOf(PropTypes.string),
  productId: PropTypes.string.isRequired
};
Information.defaultProps = {
  name: '',
  avgRating: 5,
  totalRatings: 0,
  totalReviews: 0,
  images: [0]
};
