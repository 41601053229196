import React from 'react';
import Form from './Form';
import './Styles.scss';

function Details() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <Form />
        </div>
      </div>
    </div>
  );
}

export default Details;
