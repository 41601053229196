import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Information from './Information';
import SpecificationCard from '../../../components/multicellular/products/specificationCard';
import './ProductDetail.scss';
import ProductImage from './ProductImage';
import Breadcrum from '../../../components/multicellular/navigation/breadcrum';
import Routes from '../../../constants/routes';

function Detail() {
  const { information } = useSelector((state) => state);
  const [selectedImage, setSelectedImage] = useState(null);
  const { productData } = information;
  const {
    product: {
      name,
      loading,
      specifications,
      technicalDetails,
      warranty,
      returnPolicy,
      parentId,
      categoryName,
      images = []
    } = {}
  } = productData;
  const breadCrumbs = [
    {
      label: 'Home',
      link: '/'
    },
    {
      label: categoryName,
      link: `${Routes.PRODUCT_EXPLORER}/${parentId}`
    },
    {
      label: name,
      link: '#'
    }
  ];

  function handleImageChange(image) {
    setSelectedImage(image);
  }
  function renderSpecification() {
    return (
      <div className="app-component-container">
        <div className="section-title">Components and Specifications</div>
        <div className="table-wrapper">
          {specifications && specifications?.length > 0 ? (
            <div className="table-row">
              {specifications?.map((specs, index) => (
                <div key={`specifications-${index.toString()}`} className="td-flex">
                  <div className="td-internal">
                    {specs.key}
                    {specs?.value && (specs.value?.length > 0 || !Number?.isNaN(specs.value)) && (
                      <span>{` - ${specs.value}`}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No Data found</div>
          )}
        </div>
      </div>
    );
  }
  function renderTechinalDetail() {
    return (
      <div className="app-specification-container">
        <div className="section-title">Technical Details & Additional Info</div>
        <div className="table-wrapper">
          {technicalDetails && Object.keys(technicalDetails)?.length > 0 ? (
            <table className="table-row">
              {Object.keys(technicalDetails).map((key) => (
                <tbody key={key}>
                  <tr>
                    <td>
                      <p className="row-title">{key}</p>
                    </td>
                    <td>
                      <p className="row-item">{technicalDetails[key]}</p>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          ) : (
            <div>No Data found</div>
          )}
        </div>
      </div>
    );
  }
  function renderWarranty() {
    return (
      <div className="app-specification-container">
        <div className="section-title">
          Warranty
          <span className="sub-title">{loading ? '...' : warranty?.description}</span>
        </div>
        <div className="detail-wrapper">
          <div className="description">{warranty?.termsAndConditions}</div>
        </div>
      </div>
    );
  }
  function renderReturnPolicy() {
    return (
      <div className="app-specification-container">
        <div className="section-title">Return & Refund</div>
        <ul className="detail-wrapper">
          {returnPolicy?.map((item, index) => (
            <li className="list-item" key={`return-policy-${index.toString()}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return (
    <div className="app-product-wrapper-detail">
      <div className="container">
        <div className="bread-crump-wrapper">
          <Breadcrum links={breadCrumbs} />
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <ProductImage
              images={images}
              selectedImage={selectedImage || images[0]}
              handleImageChange={(image) => handleImageChange(image)}
            />
          </div>
          <div className="col-sm-6 col-lg-6">
            <Information />
          </div>
          {specifications && specifications?.length > 0 && (
            <div className="col-sm-6 col-lg-6">
              <SpecificationCard>{renderSpecification()}</SpecificationCard>
            </div>
          )}
          {technicalDetails && Object.keys(technicalDetails)?.length > 0 && (
            <div className="col-sm-6 col-lg-6">
              <SpecificationCard>{renderTechinalDetail()}</SpecificationCard>
            </div>
          )}
          {returnPolicy && returnPolicy?.length > 0 && (
            <div className="col-sm-6 col-lg-6">
              <SpecificationCard>{renderReturnPolicy()}</SpecificationCard>
            </div>
          )}
          {warranty?.description && warranty?.description?.length > 0 && (
            <div className="col-sm-6 col-lg-6">
              <SpecificationCard>{renderWarranty()}</SpecificationCard>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { information } = state;
  return { information };
};

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
