import mirrorKeyValue from 'mirror-key-value';

/* ------------- Types ------------- */

export const PaymentTypes = mirrorKeyValue([
  'PAYTM_PAYMENT_GATEWAY',
  'PAYTM_PAYMENT_GATEWAY_INDICATOR',
  'PAYTM_PAYMENT_GATEWAY_SUCCESS',
  'PAYTM_PAYMENT_GATEWAY_FAILED',
  'RAZORPAY_PAYMENT_GATEWAY',
  'RAZORPAY_PAYMENT_GATEWAY_INDICATOR',
  'RAZORPAY_PAYMENT_GATEWAY_SUCCESS',
  'RAZORPAY_PAYMENT_GATEWAY_FAILED',
  'RAZORPAY_PAYMENT_GATEWAY_RESET'
]);

const {
  PAYTM_PAYMENT_GATEWAY,
  PAYTM_PAYMENT_GATEWAY_INDICATOR,
  PAYTM_PAYMENT_GATEWAY_SUCCESS,
  PAYTM_PAYMENT_GATEWAY_FAILED,
  RAZORPAY_PAYMENT_GATEWAY,
  RAZORPAY_PAYMENT_GATEWAY_INDICATOR,
  RAZORPAY_PAYMENT_GATEWAY_SUCCESS,
  RAZORPAY_PAYMENT_GATEWAY_FAILED,
  RAZORPAY_PAYMENT_GATEWAY_RESET
} = PaymentTypes;

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  loading: true,
  makePaymentData: [],
  error: false,
  errorMsg: '',
  paymentGatewayData: [],
  isView: false,
  isSuccessRazorpay: false,
  merchant: []
};

/* ------------- Reducer ------------- */

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    case PAYTM_PAYMENT_GATEWAY:
      return { ...state, ...data };
    case PAYTM_PAYMENT_GATEWAY_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isView: false
      };
    case PAYTM_PAYMENT_GATEWAY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { response } = action;
      return {
        ...state,
        loading: false,
        paymentGatewayData: response.data.data,
        merchant: response.data.merchant,
        style: response.data.style,
        error: false,
        isView: true
      };
    case PAYTM_PAYMENT_GATEWAY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isView: false
      };
    case RAZORPAY_PAYMENT_GATEWAY:
      return { ...state, ...data };
    case RAZORPAY_PAYMENT_GATEWAY_INDICATOR:
      return {
        ...state,
        loading: true,
        error: false,
        isSuccessRazorpay: false
      };
    case RAZORPAY_PAYMENT_GATEWAY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      return {
        ...state,
        loading: false,
        paymentGatewayData: action.response,
        error: false,
        isSuccessRazorpay: true
      };
    case RAZORPAY_PAYMENT_GATEWAY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        isSuccessRazorpay: false
      };
    case RAZORPAY_PAYMENT_GATEWAY_RESET:
      // eslint-disable-next-line no-case-declarations
      return {
        ...state,
        loading: false,
        error: false,
        isSuccessRazorpay: false
      };
    default:
      return state;
  }
}
