const RoutesPaths = {
  HOME: '/',
  PRODUCT_DETAIL: '/products',
  PRODUCT_EXPLORER: '/category',
  ACCOUNT: '/account/*',
  ADDRESS: 'address',
  DIRECT_TO_ADDRESS: '/account/profile',
  PROFILE: 'profile',
  ORDERS: 'order',
  TRACK_ORDER: '/track-order',
  CART: '/cart',
  CART_WAGON: '/cart/wagon',
  ADDRESS_LIST: '/cart/address',
  PAYMENT_SUMMARY: '/cart/payment-summary',
  FAQ: '/faqs',
  TERMS_OF_USAGE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  RETURN_POLICY: '/return-policy',
  CUSTOMER_CARE: 'customer-care',
  INVITE_FRIENDS: 'invite-friends',
  REVIEWS: '/review',
  CONTACT_US: '/contact-us',
  SHIPPING_POLICY: '/shipping-policy',
  SEARCH: '/search',
  BULK_ORDERS: '/bulk-orders',
  PAYMENT_STATUS: '/payment-status',
  BOOKS: '/books'
};
export default RoutesPaths;
