import mirrorKeyValue from 'mirror-key-value';

export const CancelOrderTypes = mirrorKeyValue([
  'GET_ORDER_CANCELED',
  'GET_ORDER_CANCELED_INDICATOR',
  'GET_ORDER_CANCELED_SUCCESS',
  'GET_ORDER_CANCELED_FAILED',
  'RESET_ORDER_CANCEL'
]);

const {
  GET_ORDER_CANCELED,
  GET_ORDER_CANCELED_INDICATOR,
  GET_ORDER_CANCELED_SUCCESS,
  GET_ORDER_CANCELED_FAILED,
  RESET_ORDER_CANCEL
} = CancelOrderTypes;

const INITIAL_STATE = {
  load: false,
  cancelOrderData: {},
  error: '',
  errorMsg: '',
  isOrderedCancel: false
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  const { type, data } = action;
  switch (type) {
    // GET HOME PAGE LIST
    case GET_ORDER_CANCELED:
      return { ...state, ...data };
    case GET_ORDER_CANCELED_INDICATOR:
      return {
        ...state,
        load: true,
        error: false
      };
    case GET_ORDER_CANCELED_SUCCESS:
      return {
        ...state,
        load: false,
        cancelOrderData: action.response,
        error: false,
        isOrderedCancel: true
      };
    case GET_ORDER_CANCELED_FAILED:
      return {
        ...state,
        load: false,
        error: true,
        errorMsg: action.message,
        isOrderedCancel: false
      };
    case RESET_ORDER_CANCEL:
      return {
        ...state,
        isOrderedCancel: false,
        error: false
      };
    default:
      return state;
  }
}
