/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputField from '../../../components/unicellular/inputField';
import { LoginTypes } from '../../../reducers/auth/login/login';
import ChangePasswordModel from '../../../components/multicellular/account/changePassword/ChangePassword';
import { editProfileTypes } from '../../../reducers/auth/editUser/editUser';
import Verify from '../../../constants/verify';
import { FaqTypes } from '../../../reducers/faq/faq';
import { PasswordTypes } from '../../../reducers/changepassword/changepassword';
import Close from '../../../assets/svgs/Close';
import LoginModalIcon from '../../../assets/svgs/LoginModalIcon';
import AuthString from '../../../constants/strings/auth/Auth';
import EmailOtpVerify from './emailOtpVerify';
import './Profile.scss';
import { OtpTypes } from '../../../reducers/auth/otp/otp';
import { LoginWithMobileTypes } from '../../../reducers/auth/login/loginWithMobile';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

function Profile(props) {
  const [changepassword, setPassword] = useState(false);
  const { resetPasswordReducer, resetOtpReducer } = props;
  const [disableName, setDisableName] = useState(true);
  const [editName, setEditName] = useState('');
  const [isNameEdit, setNameEdit] = useState(false);
  const [isUpdateProccessStarted, setUpdateProccessStarted] = useState(false);
  const [isEmailEdit, setisEmailEdit] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [disableEmail, setDisableEmail] = useState(true);
  const [editEmail, setEditEmail] = useState('');
  const [err, setError] = useState('');
  const {
    changepassword: { isSuccessFullySubmited, updatepasswordData: { message } = {} }
  } = useSelector((state) => state);
  useEffect(() => {
    if (isSuccessFullySubmited) {
      setPassword(false);
      setInterval(() => {
        resetPasswordReducer();
      }, 3000);
    }
  }, [isSuccessFullySubmited]);
  const {
    login: { user: { name, email, mobile, isEmailVerified } = {} },
    editUser: { loading, isEditSuccess },
    otp: { isEmailOtpVerified },
    fetchFaq
  } = useSelector((state) => state);
  const { editUserEmailInfo, resetEmailReducer } = props;

  useEffect(() => {
    const { getUserInfo, resetEditReducer, getFaqList } = props;
    getUserInfo();
    getFaqList('User');
    if (isEditSuccess) {
      getUserInfo();
      setDisableName(true);
      setEditName('');
      setEditEmail('');
      setNameEdit(false);
      resetEditReducer();
      setisEmailEdit(false);
      setDisableEmail(true);
      setopenModal(false);
      resetOtpReducer();
      resetEmailReducer();
    }
  }, [isEditSuccess]);
  useEffect(() => {
    if (isEmailOtpVerified) {
      setopenModal(false);
      resetOtpReducer();
    }
    if (isEmailOtpVerified && isUpdateProccessStarted) {
      editUserEmailInfo(editEmail);
      setUpdateProccessStarted(false);
      resetOtpReducer();
      resetEmailReducer();
    }
  }, [isEmailOtpVerified, isUpdateProccessStarted]);
  const changePassword = () => {
    setPassword(true);
  };
  const closepasswordModal = () => {
    setPassword(false);
  };
  const handleClickEditName = () => {
    setDisableName(false);
  };
  const handleClickUpdateName = () => {
    const { editUserNameInfo } = props;
    if (editName.length > 0) {
      editUserNameInfo(editName);
    }
  };
  const handleonChangeName = (e) => {
    const regex = /^[a-zA-Z ]*$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setEditName(e.target.value);
      setNameEdit(true);
      setError('');
    }
  };
  const handleClickEditEmail = () => {
    setDisableEmail(false);
  };
  const handleClickUpdateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (editEmail.length > 0 && re.test(editEmail)) {
      setUpdateProccessStarted(true);
      setopenModal(true);
    } else {
      setError(Verify.VERIFY_EMAIL_INVALID);
    }
  };
  const handleVerifyEmail = () => {
    setopenModal(!openModal);
  };
  const handleOpenModal = () => {
    setopenModal(false);
  };
  const handleonChangeEmail = (e) => {
    setEditEmail(e.target.value);
    setisEmailEdit(true);
    setError('');
  };
  const renderPersonalInfo = () => (
    <div className="app-profile-header-flexbox">
      <div className="app-profile-label">
        <div>Name</div>
      </div>
      <div className={`app-input-wrapper ${disableName ? 'disabled-input' : ''}`}>
        <InputField
          value={isNameEdit ? editName : name}
          disabled={disableName}
          onChange={handleonChangeName}
        />
      </div>
      <div className="app-action-section">
        {(disableName && (
          <button type="button" onClick={handleClickEditName}>
            Edit
          </button>
        )) || (
          <button
            type="button"
            className={`${editName === name || editName.length === 0 ? 'disabled-input' : ''}`}
            onClick={editName === name || editName.length === 0 ? null : handleClickUpdateName}>
            {loading ? '...' : 'Update'}
          </button>
        )}
      </div>
    </div>
  );
  const renderEmailInfo = () => (
    <div className="app-profile-header-flexbox">
      <div className="app-profile-label">
        <div>Email address</div>
      </div>
      <div className={`app-input-wrapper ${disableEmail ? 'disabled-input' : ''}`}>
        <InputField
          value={isEmailEdit ? editEmail : email}
          disabled={disableEmail}
          onChange={handleonChangeEmail}
        />
      </div>
      <div className="app-action-section">
        {(disableEmail && (
          <button type="button" onClick={handleClickEditEmail}>
            Edit
          </button>
        )) || (
          <button
            type="button"
            className={`${editEmail === email || editEmail.length === 0 ? 'disabled-input' : ''}`}
            onClick={editEmail === email || editEmail.length === 0 ? null : handleClickUpdateEmail}>
            {loading ? '...' : 'Update'}
          </button>
        )}
        {!isEmailVerified && (
          <button type="button" onClick={handleVerifyEmail}>
            Verify
          </button>
        )}
      </div>
    </div>
  );
  const renderMobileNumberInfo = () => (
    <div className="app-profile-header-flexbox">
      <div className="app-profile-label">
        <div>Mobile Number</div>
      </div>
      <div className="app-input-wrapper disabled-input">
        <InputField value={mobile} disabled />
      </div>
    </div>
  );
  const renderSectionTitle = (title, className, node) => (
    <div className="profile-title-layout">
      <div className={`${className}`}>{title}</div>
      <div className="app-title-action-section">{node}</div>
    </div>
  );
  const renderChangePasswordBtn = () => (
    <div>
      <button type="button" onClick={changePassword}>
        {' '}
        Change Password{' '}
      </button>
      {changepassword && (
        <ChangePasswordModel show={changepassword} mobile={mobile} onClose={closepasswordModal} />
      )}
      <Snackbar
        open={isSuccessFullySubmited}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          <div className="alet-message-success">{message}</div>
        </Alert>
      </Snackbar>
    </div>
  );
  const faqSection = () => {
    const { faqData: { faq = [] } = {} } = fetchFaq;
    return fetchFaq?.loading ? (
      <div>...</div>
    ) : (
      faq &&
        faq.length > 0 &&
        faq.map(
          (item) =>
            item.categories &&
            item.categories.length > 0 &&
            item.categories.map(
              (data) =>
                data.faqs &&
                data.faqs.length > 0 &&
                data.faqs.map((value, index) => (
                  <div className="app-profile-faq" key={`faqs-${index.toString()}`}>
                    <div className="question">{value.question}</div>
                    <div className="answer">{value.answer}</div>
                  </div>
                ))
            )
        )
    );
  };
  const renderMainLayout = () => (
    <div className="app-auth-wrapper">
      <div className="image-container">
        <div>
          <div className="title">{AuthString.VERIFY_EMAIL}</div>
          <div className="description">{AuthString.VERIFY_EMAIL_DESCRIPTION}</div>
        </div>
        <div>
          <LoginModalIcon />
        </div>
      </div>
      <div className="form-container">
        <EmailOtpVerify editedEmail={isUpdateProccessStarted && editEmail} />
      </div>
    </div>
  );
  const renderEmailVerifyModal = () => (
    <Modal
      size="lg"
      show={openModal}
      onHide={handleOpenModal}
      centered
      contentClassName="auth-modal">
      <Modal.Body className="auth-modal-body">
        <button
          aria-label="close"
          type="button"
          onClick={handleOpenModal}
          className="auth-modal-close-btn">
          <Close color="#666" />
        </button>
        <div>{renderMainLayout()}</div>
      </Modal.Body>
    </Modal>
  );
  return (
    <div>
      {renderSectionTitle(
        'Personal Information',
        'profile-section-title-first',
        renderChangePasswordBtn()
      )}
      {renderPersonalInfo()}
      {renderEmailInfo()}
      {renderEmailVerifyModal()}
      {renderMobileNumberInfo()}
      {err !== '' && <div className="error-message-profile">{err}</div>}
      {renderSectionTitle('FAQs', 'profile-section-title')}
      {faqSection()}
    </div>
  );
}
Profile.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getFaqList: PropTypes.func.isRequired,
  editUserNameInfo: PropTypes.func.isRequired,
  resetEditReducer: PropTypes.func.isRequired,
  editUserEmailInfo: PropTypes.func.isRequired,
  resetPasswordReducer: PropTypes.func.isRequired,
  resetOtpReducer: PropTypes.func.isRequired,
  resetEmailReducer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { login, editUser, fetchFaq, changepassword } = state;
  return {
    login,
    editUser,
    fetchFaq,
    changepassword
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch({ type: LoginTypes.GET_USER_FETCH }),
  editUserNameInfo: (name) => dispatch({ type: editProfileTypes.EDIT_USER_PROFILE, name }),
  editUserEmailInfo: (email) => dispatch({ type: editProfileTypes.EDIT_USER_PROFILE, email }),
  verifyUserEmailInfo: (email) => dispatch({ type: editProfileTypes.VERIFY_USER_PROFILE, email }),
  resetEditReducer: () => dispatch({ type: editProfileTypes.RESET_EDIT_USER_PROFILE }),
  getFaqList: (componentType) => dispatch({ type: FaqTypes.GET_FAQ_LIST, componentType }),
  resetOtpReducer: () => dispatch({ type: OtpTypes.RESET_OTP_REDUCER }),
  resetEmailReducer: () => dispatch({ type: LoginWithMobileTypes.RESET_LOGIN_WTIH_MOBILE_VIEW }),
  resetPasswordReducer: () =>
    dispatch({
      type: PasswordTypes.RESET_GET_PASSWORD
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
