import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TawkTo from 'tawkto-react';
import RoutesPaths from './constants/routes';
import String from './constants/strings';

import './App.scss';
import AppLayout from './containers/AppLayout';
import PrimaryLayout from './containers/PrimaryLayout';
// import Router from './routes';

function App() {
  useEffect(() => {
    const tawk = new TawkTo(String.TAWK_PROPERTY_ID, String.TAWK_ACCOUNT_ID);
    if (tawk) {
      tawk.showWidget();
    }
    return () => {
      if (tawk) {
        tawk.hideWidget();
      }
    };
  }, []);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route exact path="/*" element={<AppLayout />} />
          <Route exact path={`${RoutesPaths.BOOKS}/*`} element={<PrimaryLayout />} />
          {/* <Route exact path="/" component={Login}>
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login" component={Login} /> */}
          {/* {routeConfig.map((routeObj) => (
            <Route
              key={routeObj.path}
              exact
              path={routeObj.path}
              element={routeObj.primaryRouteComponent}
            />
          ))} */}
          {/* <Route component={Home} /> */}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
