/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SelectDropdown from '../../../unicellular/selectDropdown';
import { generateUrl } from '../../../../utils/ImageUtils';
import RoutesPaths from '../../../../constants/routes';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import './CartItem.scss';
import createSlug from '../../../../utils/conversions/createSlug';
import { trackEvent } from '../../../../analytics/googleAnalytics';

function renderSpecs(specs) {
  if (!specs) {
    return <div />;
  }
  if (typeof specs === 'string') {
    return specs;
  }
  if (typeof specs === 'object') {
    return Object.entries(specs)
      .slice(0, 2)
      .map(([key, value]) => <div key={key}>{`${key} - ${value}`}</div>);
  }
  return <div>{specs}</div>;
}
function CartItem({
  cart,
  cartLength,
  userDetails,
  onCartValueChange,
  removeFromList,
  changeFromList,
  isSaveforLater = false,
  isValueMore,
  onCustomCartValueChange,
  onEditCartQuantity
}) {
  const { product = [{}] } = cart;
  const [customQuantity, setCustomQuantity] = useState(cart?.quantity);
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);
  const specifications = Array.isArray(product)
    ? product[0]?.specifications
    : product?.specifications;
  const handleCustomQuantity = (e) => {
    setError(false);
    const numberPattern = /^[1-9]\d*$/;
    if (e.target.value === '' || numberPattern.test(e.target.value)) {
      if (product?.quantity && e.target.value !== '') {
        // eslint-disable-next-line radix
        if (parseInt(e.target.value) < parseInt(product.quantity.maximum) + 1) {
          setCustomQuantity(e.target.value);
        } else {
          setError(true);
          setErrorMsg(`Maximum quantity of this product per order is ${product.quantity.maximum}.`);
        }
      } else {
        setCustomQuantity(e.target.value);
      }
    }
  };
  const handleCustomUpdateBtn = () => {
    if (customQuantity) {
      onCustomCartValueChange(customQuantity, cart);
    } else {
      setError(true);
      setErrorMsg('* Please enter quantity.');
    }
  };

  useEffect(() => {
    let timeout;
    if (error) {
      timeout = setTimeout(() => {
        setError(false);
        setErrorMsg('');
      }, 2000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={() => setError(false)}>
      <Tooltip {...props} classes={{ popper: className }} />
    </ClickAwayListener>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }));

  return (
    <div className="cart-item-body">
      <div className="cart-item-wrapper">
        <div key={product._id}>
          <NavLink
            to={`${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product.name)}/${product._id}`}>
            <div className="cart-item-image-container">
              <img
                src={generateUrl(product.images ? product.images[0] : '')}
                alt=""
                srcSet=""
                onError={({ currentTarget }) => {
                  const currTrgt = currentTarget;
                  currTrgt.onerror = null; // prevents looping
                  currTrgt.src = PRODUCT_DEFAULTS.productImage;
                }}
                className="image"
              />
            </div>
          </NavLink>
        </div>
        <div className="cart-item-content-container">
          <div className="cart-item-content-details">
            <div className="cart-item-content-heading1">
              {Array.isArray(product) ? product[0]?.name : product?.name}
            </div>
            <div className="cart-item-content-heading3">
              {Array.isArray(product) ? product[0]?.categoryName : product?.categoryName}
            </div>
            {specifications?.slice(0, 2).map((specs, index) => (
              <div
                className="cart-item-content-heading3"
                key={`specifications-${index.toString()}`}>
                {renderSpecs({ [specs.key]: specs.value })}
              </div>
            ))}
          </div>
          <div className="cart-item-price-details">
            <div className="cart-item-content-heading3 center-text">
              <span className="cart-net-price align-right">
                {'\u20B9'}
                {cart?.calculatedPrices.netPrice}
              </span>
              {cart?.calculatedPrices.netPrice !== cart?.calculatedPrices.basePrice && (
                <span className="cart-actual-price align-right">
                  {'\u20B9'}
                  {cart?.calculatedPrices.basePrice}
                </span>
              )}
            </div>
            <div className="cart-item-content-heading3 center-text align-right">
              {'\u20B9'}
              {/* {Array.isArray(product) ? product[0]?.price : product?.price} */}
              {Array.isArray(product) ? product[0]?.netPrice : product?.netPrice} / Unit
            </div>
          </div>
        </div>
      </div>
      <div className="cart-item-action-wrapper">
        <div className="cart-item-action">
          <button
            type="button"
            className="card-item-action-label label-action"
            onClick={() => {
              trackEvent(
                'Cart',
                'Save for Later',
                `${userDetails} clicked on Save for Later for ${product.name} (cart qty now: ${cartLength - 1})`
              );
              changeFromList(cart, isSaveforLater);
            }}>
            {isSaveforLater ? 'Move to cart' : 'Save for Later'}
          </button>
          <div className="card-item-action-label">|</div>
          {isValueMore || cart?.quantity > 9 ? (
            <div className="action-btn-wrappper">
              <div className="cart-update-input">
                <input
                  type="text"
                  maxLength={3}
                  onBlur={() => {
                    if (!customQuantity) {
                      setCustomQuantity(cart?.quantity);
                    }
                    if (customQuantity === cart?.quantity) {
                      onEditCartQuantity(cart, false);
                    }
                  }}
                  onFocus={() => {
                    if (!isValueMore) {
                      onEditCartQuantity(cart, true);
                    }
                  }}
                  autoFocus
                  placeholder="Qty"
                  value={customQuantity}
                  onChange={handleCustomQuantity}
                />
              </div>
              {!isValueMore || parseInt(customQuantity) === cart?.quantity ? (
                <button
                  className="delete-action-btn"
                  type="button"
                  onClick={() => removeFromList(cart, isSaveforLater)}>
                  Remove
                </button>
              ) : (
                <HtmlTooltip
                  title={<div className="cart-error-tooltip">{`${errorMsg}`}</div>}
                  onClose={() => setError(false)}
                  open={error}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener>
                  <button
                    className="update-action-btn"
                    type="button"
                    onClick={handleCustomUpdateBtn}>
                    Update
                  </button>
                </HtmlTooltip>
              )}
            </div>
          ) : (
            <div className="action-btn-wrappper">
              {!isSaveforLater && (
                <SelectDropdown
                  placeholder="Qty"
                  onChange={(changedValue) => {
                    setCustomQuantity(changedValue.value);
                    onCartValueChange(changedValue, cart);
                  }}
                  options={Array.from([...Array(10).keys()].map((x) => x + 1)).map((e) => ({
                    label: e === 10 ? `${e}+` : `${e}`,
                    value: `${e}`
                  }))}
                  value={[{ label: `${cart?.quantity}`, value: `${cart?.quantity}` }]}
                  isSearchable={false}
                  isStyled
                  isDisabled={isSaveforLater}
                  closeMenuOnSelect
                />
              )}
              <button
                className="delete-action-btn"
                type="button"
                onClick={() => removeFromList(cart, isSaveforLater)}>
                Remove
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
CartItem.propTypes = {
  cart: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  cartLength: PropTypes.number,
  userDetails: PropTypes.string,
  isSaveforLater: PropTypes.bool,
  onCartValueChange: PropTypes.func,
  onCustomCartValueChange: PropTypes.func,
  onEditCartQuantity: PropTypes.func,
  removeFromList: PropTypes.func,
  changeFromList: PropTypes.func,
  isValueMore: PropTypes.bool
};

CartItem.defaultProps = {
  isSaveforLater: false,
  onCartValueChange: () => {},
  onCustomCartValueChange: () => {},
  onEditCartQuantity: () => {},
  removeFromList: () => {},
  changeFromList: () => {},
  cart: {},
  cartLength: 0,
  userDetails: 'User',
  isValueMore: false
};

export default CartItem;
