/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RoutesPaths from '../../../constants/routes';
import ProductCard from '../../../components/multicellular/products/productCard';
import KitCard from '../../../components/multicellular/products/kitCardV2';
import ProductString from '../../../constants/strings/product';
import './ProductDetail.scss';
import STORAGE_KEYS from '../../../constants/storageKey';
import { CartTypes } from '../../../reducers/cart/cart';
import createSlug from '../../../utils/conversions/createSlug';

const initialState = {
  responsive: {
    0: { items: 1 },
    550: { items: 2 },
    600: { items: 3 },
    1000: { items: 3 },
    1400: { items: 4 }
  },
  activeProductId: [],
  activeId: ''
};

class ProductsClass extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  handleCart = (id) => {
    const { activeProductId } = this.state;
    const { callAddToCartApi, navigate } = this.props;
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    const arr = activeProductId;
    arr.push(id);
    this.setState({ activeProductId: [...arr], activeId: id });
    callAddToCartApi(userId, id, 1, navigate);
  };

  handleRedirection = (product) => {
    // eslint-disable-next-line react/prop-types
    const { navigate } = this.props;
    navigate(
      `${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product?.name)}/${product._id}`
    );
  };

  renderCarousel = () => {
    const { responsive, activeProductId } = this.state;
    const { similarproduct: { similarproducts: { content = [] } = {} } = {} } = this.props;
    return (
      <div>
        <OwlCarousel
          className="owl-theme"
          navText={[
            "<div className='nav-btn prev-slide'> <span class=' nav-icon-module-left'></span></div>",
            "<div className='nav-btn next-slide'><span class=' nav-icon-module-right'></span></div>"
          ]}
          loop={false}
          nav
          responsive={responsive}
          dotsClass="hidden-owl-dots"
          stagePadding={70}
          stageClass="product-owl-stage owl-stage">
          {content.length > 0 &&
            content.map((item, index) => (
              <div key={`content-${index.toString()}`} className="app-product-padding-section">
                <KitCard
                  title={item.name}
                  rating={item.avgRating}
                  price={item.calculatedPrices?.netPrice}
                  isFreeDelivery={item.isFreeDelivery}
                  discount={item.discount}
                  description={item.description}
                  image={item.images ? item.images[0] : ''}
                  productId={item._id}
                  wishlist={item.wishlist}
                  isWishlisted={item.isWishlisted}
                  calculatedPrices={item.calculatedPrices}
                  onClickCart={() => this.handleCart(item._id)}
                  onClickBuyNow={() => this.handleRedirection(item)}
                  isAddedToCart={activeProductId.includes(item._id) || item.isAddedToCart}
                  isHideBuyNow
                  // loadingCart={activeId === item._id && loading}
                />
              </div>
            ))}
        </OwlCarousel>
      </div>
    );
  };

  render() {
    const { similarproduct: { load, similarproducts: { content = [] } = {} } = {} } = this.props;
    return (
      <div>
        <div className="app-product-carousel-wrapper">
          {content.length > 0 && (
            <div className="section-title container">
              {ProductString.RELATED_PRODUCT_SECTION_TITLE}
            </div>
          )}
          <div className="container">
            {load ? (
              <div className="col-md-3">
                <ProductCard loading={load} />
              </div>
            ) : (
              this.renderCarousel()
            )}
          </div>
        </div>
      </div>
    );
  }
}

function Products(props) {
  const params = useParams();
  const navigate = useNavigate();
  return <ProductsClass {...props} params={params} navigate={navigate} />;
}

ProductsClass.propTypes = {
  similarproduct: PropTypes.shape({
    similarproducts: PropTypes.shape({
      content: PropTypes.array
    }),
    load: PropTypes.bool.isRequired
  }),
  cart: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired
  }),
  callAddToCartApi: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
};
ProductsClass.defaultProps = {
  similarproduct: {
    similarproducts: [],
    content: []
  },
  cart: {
    loading: false,
    isSuccess: false
  }
};
const mapStateToProps = (state) => {
  const { similarproduct, cart } = state;
  return { similarproduct, cart };
};
const mapDispatchToProps = (dispatch) => ({
  callAddToCartApi: (userId, productId, quantity, navigate) =>
    dispatch({
      type: CartTypes.ADD_TO_CART_FETCH,
      userId,
      productId,
      quantity,
      navigate
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
