/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { connect, useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '../../../components/unicellular/button';
import Card from '../../../components/multicellular/order/trackCard';
import Header from '../../../components/multicellular/navigation/header';
import MyAccountString from '../../../constants/strings/myAccount';
import RoutesPaths from '../../../constants/routes';
import URLS from '../../../constants/urls';
import Share from '../../../components/multicellular/order/share';
import TrackingStatus from './TrackingStatus';
import { OrderDetailTypes } from '../../../reducers/order/orderDetails';
import { generateUrl } from '../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../constants/defaults/product';
import String from '../../../constants/strings';
import ReturnModal from '../../../components/multicellular/order/returnModal/ReturnModal';
import { ReturnOrderTypes } from '../../../reducers/order/returnOrder';
import './TrackOrder.scss';
import RatingModal from '../../../components/multicellular/order/ratingModal/RatingModal';
import { ReviewTypes } from '../../../reducers/order/reviewDetails';
import Info from '../../../assets/svgs/Info';
import RefundModal from '../../../components/multicellular/order/refundModal';
import { CancelOrderTypes } from '../../../reducers/order/cancelOrder';
import Loader from './loader';
import AmountBreakDownModal from '../../../components/multicellular/order/amountBreakDownModal';
import createSlug from '../../../utils/conversions/createSlug';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

function TrackOrder(props) {
  const { getOrderDetail, resetReturnReducer, callCancelOrderApi, resetCancelOrder } = props;
  const [isShare, setIsShare] = useState(false);
  const [reviewDetails, setShowRating] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [cancelProductList, setCancelProductList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openAmountModal, setOpenAmountModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { orderDetails, returnOrder, cancelOrder } = useSelector((state) => state);
  const { isOrderedCancel, error, errorMsg, load: loadCancel } = cancelOrder;

  const { isReturnedSuccess } = returnOrder;
  const [returnorreplace, setShowReturn] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});
  const { login } = useSelector((state) => state);
  const { isUserLogin } = login;
  const { resetReviewReducer } = props;

  const {
    reviewDetails: { isSuccessFullySubmited, reviewData: { message } = {} }
  } = useSelector((state) => state);
  useEffect(() => {
    if (isSuccessFullySubmited) {
      setShowRating(false);
      setInterval(() => {
        resetReviewReducer();
      }, 3000);
    }
  }, [isSuccessFullySubmited]);
  useEffect(() => {
    if (isOrderedCancel) {
      setInterval(() => {
        resetCancelOrder();
      }, 2000);
      setOpenRemoveDialog(false);
      setShowConfirmation(false);
      setCancelProductList([]);
    }
    if (error) {
      setInterval(() => {
        resetCancelOrder();
      }, 2000);
    }
  }, [isOrderedCancel, error]);
  const {
    load,
    orderDetailData: { order = {}, order: { orderStatus: { isDelivered } = {} } = {} } = {}
  } = orderDetails;

  const {
    address,
    address: { mobile, name } = {},
    productSummary: { products = {} } = {},
    orderStatus = {},
    shipmentInfo = []
  } = order;
  const addressDetails = [
    address?.street,
    address?.locality,
    address?.landmark,
    address?.city,
    address?.state,
    address?.pincode
  ];
  const { id, invoiceId } = params;
  useEffect(() => {
    const authenticated = isUserLogin;
    const goToHomePage = () => navigate('/');
    if (!authenticated) {
      goToHomePage();
    }
    getOrderDetail(id, invoiceId);
    if (isReturnedSuccess) {
      setShowReturn(false);
      resetReturnReducer();
    }
  }, [isReturnedSuccess, isUserLogin, navigate]);
  const handleShareBtn = (value, item) => {
    setActiveProduct(item);
    setIsShare(value);
  };
  const confrimReturnProduct = () => {
    setShowReturn(true);
  };
  const confrimReviewProduct = (item) => {
    setActiveProduct(item);
    setShowRating(true);
  };
  const closereturnModal = () => {
    setShowReturn(false);
    setShowRating(false);
  };
  const handleOrderCancel = () => {
    const productsList = cancelProductList.map((item) => ({
      id: item.product._id,
      quantity: item.quantity
    }));
    callCancelOrderApi(productsList, orderStatus.orderId, invoiceId);
  };
  const handleCloseCancel = () => {
    setOpenRemoveDialog(false);
    setShowConfirmation(false);
  };
  const handleCancelItems = useCallback(
    (orderedProductId, data) => {
      const arr = cancelProductList;
      const index = arr.findIndex((item) => item._id === orderedProductId);

      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(data);
      }
      setCancelProductList([...arr]);
    },
    [cancelProductList]
  );
  const handleConfirmation = useCallback(() => {
    setShowConfirmation(!showConfirmation);
  }, [showConfirmation]);
  const handleCloseOpen = () => {
    setOpenRemoveDialog(true);
  };
  const checkIsCancelled = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isCancelled === true);
  const checkIsReplace = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isReplace === true);
  const checkIsReturn = () =>
    products && products?.length > 0 && products.every((obj) => obj.orderDetails.isReturn === true);
  const checkIsDispatched = () =>
    products &&
    products?.length > 0 &&
    products.every((obj) => obj.orderDetails.isDispatched === true);
  const confirmReturn = () => (
    <div>
      {returnorreplace && (
        <ReturnModal
          show={returnorreplace}
          onClose={closereturnModal}
          orderId={orderStatus.orderId}
          products={products}
          createdAt={order.createdAt}
          isAvailableToReturn={isDelivered}
        />
      )}
    </div>
  );
  const confirmRating = () => (
    <div>
      {reviewDetails && (
        <RatingModal
          show={reviewDetails}
          onClose={closereturnModal}
          productId={activeProduct.product._id}
          name={activeProduct?.product?.name}
          image={activeProduct.product.images ? activeProduct.product.images[0] : ''}
          categoryName={activeProduct.categoryName}
          productQuantity={activeProduct.quantity}
          createdAt={activeProduct.createdAt}
        />
      )}
      <Snackbar
        open={isSuccessFullySubmited}
        autoHideDuration={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          <div className="alet-message-success">{message}</div>
        </Alert>
      </Snackbar>
    </div>
  );
  const refundModal = () => (
    <div>
      {openRefundModal && (
        <RefundModal
          show={openRefundModal}
          onClose={() => setOpenRefundModal(false)}
          refundInfo={orderStatus}
          paymentMethod={order?.paymentMethod}
        />
      )}
    </div>
  );

  const renderAmountBreakDownModal = () => (
    <div>
      {openAmountModal && (
        <AmountBreakDownModal
          show={openAmountModal}
          onClose={() => setOpenAmountModal(false)}
          priceSummary={order}
        />
      )}
    </div>
  );
  const BreadcrumbLink = [
    {
      route: `/account/${RoutesPaths.ADDRESS}`,
      isParentLink: true,
      textColor: '#333',
      text: `${MyAccountString.MY_ACCOUNT}`
    },
    {
      route: `/account/${RoutesPaths.ORDERS}`,
      isParentLink: true,
      textColor: '#333',
      text: `${MyAccountString.ORDER}`
    },
    {
      route: '',
      isParentLink: false,
      textColor: '#333',
      text: `${MyAccountString.TRACK_ORDER} ${id}`
    }
  ];
  const renderBreadCrumb = () => (
    <Breadcrumbs
      aria-label="breadcruMyAccountString.ORDER}mb"
      className="app-track-breadcrumb-text">
      {BreadcrumbLink.map((item, index) => {
        if (item.isParentLink) {
          return (
            <Link
              color={item.textColor}
              key={`link-${index.toString()}`}
              className="app-track-breadcrumb-link"
              href={item.route}>
              {item.text}
            </Link>
          );
        }
        return (
          <div className="app-track-breadcrumb-text" key={`breadcrumbs-${index.toString()}`}>
            {item.text}
          </div>
        );
      })}
    </Breadcrumbs>
  );
  const renderaddress = () => (
    <div className="app-track-card">
      <div className="section-title">{MyAccountString.DELIVERY_ADDRESS_TITLE}</div>
      <div className="title">{name}</div>
      <div className="description description-ellipsis">{addressDetails?.join(', ')}</div>
      <div>
        <span className="title">{MyAccountString.PHONE_TITLE}</span>
        <span className="description">{mobile}</span>
      </div>
    </div>
  );
  const trackingDetail = () => (
    <div className="app-track-card">
      <div className="section-title">{MyAccountString.TRACK_DETAIL_TITLE}</div>
      {!checkIsCancelled() ? (
        <>
          {shipmentInfo?.packages && shipmentInfo?.packages.length > 0 && (
            <div className="description">
              {MyAccountString.TRACK_ID}
              <span className="title">{shipmentInfo?.packages.map((item) => item?.waybill)}</span>
            </div>
          )}
          <div className="description">
            {MyAccountString.TRACK_DETAIL_DESCRIPTION}
            <span className="title">{` ${MyAccountString.DELIVERY}`}</span>
          </div>
        </>
      ) : null}
      <div className="sub-section-title">{MyAccountString.PAYMENT_DETAILS}</div>
      {order?.paymentMethod !== 'PAID' && (
        <div className="sub-description">
          {`${MyAccountString.PAYMENT_METHOD}:`}
          <span className="title">{` ${order?.paymentMethod}`}</span>
        </div>
      )}
      <div className="sub-description">
        {MyAccountString.PAYMENT_PAYABLE(order?.paymentMethod === 'COD')}
        <span className="title">
          {` ${order?.price}`}
          <button aria-label="info" type="button" onClick={() => setOpenAmountModal(true)}>
            <Info />
          </button>
        </span>
        {renderAmountBreakDownModal()}
      </div>
      {order?.orderStatus?.refundInfo &&
      Object?.keys(order?.orderStatus?.refundInfo)?.length !== 0 ? (
        <>
          <div className="sub-section-title">{MyAccountString.REFUND_DETAILS}</div>
          {/* <div className="sub-description">
            {`${MyAccountString.REFUND_STATUS}:`}
            <span className="title">
              {` ${
                order?.orderStatus?.refundStatus?.length > 0
                  ? (order?.orderStatus?.refundStatus[
                    order?.orderStatus?.refundStatus?.length - 1
                  ]?.status !== 'failed'
                      // eslint-disable-next-line max-len
                      && order?.orderStatus?.refundStatus[
                        order?.orderStatus?.refundStatus?.length - 1
                      ]?.status)
                    || order?.orderStatus?.refundInfo?.status
                  : order?.orderStatus?.refundInfo?.status
              }`}
            </span>
          </div> */}
          <div className="sub-description">
            {`${MyAccountString.REFUND_AMOUNT}:`}
            <span className="title">
              {` ₹${order?.orderStatus?.refundInfo?.totalAmount?.toFixed(2)}`}
              <button aria-label="info" type="button" onClick={() => setOpenRefundModal(true)}>
                <Info />
              </button>
              {refundModal()}
            </span>
          </div>
        </>
      ) : null}
      {order?.paymentMethod === 'COD' && (
        <div className="sub-description">
          {`${MyAccountString.PAY_ON_DELIVERY}:`}
          <span className="title">{` ${MyAccountString.PAYMENT_OPTION_COD}`}</span>
        </div>
      )}
    </div>
  );
  const renderOrderDetail = () => (
    <div className="app-track-card">
      <div className="track-card-flex">
        <div className="section-title">{MyAccountString.ORDER}</div>
        {isDelivered && (
          <a href={URLS.BASE_URL + URLS.API_DOWNLOAD_INVOICE(id)} download>
            <button type="button" className="link-text">
              {MyAccountString.DOWNLOAD_INVOICE}
            </button>
          </a>
        )}
      </div>
      {!checkIsCancelled() && !checkIsReplace() && !checkIsReturn() && !isDelivered && (
        <div className="action-btn-container">
          <Button buttonText="CANCEL" className="app-track-action-btn" onClick={handleCloseOpen} />
        </div>
      )}
      {isDelivered && (
        <div className="action-btn-container">
          <Button
            buttonText={MyAccountString.RETURN_AND_REFUND_TITLE}
            className="app-track-action-btn"
            onClick={confrimReturnProduct}
          />
        </div>
      )}
    </div>
  );
  const renderTrack = () => (
    <div className="row">
      <div className="col-md-4">
        <Card>{renderaddress()}</Card>
      </div>
      <div className="col-md-4">
        <Card>{trackingDetail()}</Card>
      </div>
      {checkIsCancelled() && !checkIsDispatched() && !isDelivered ? null : (
        <div className="col-md-4">
          <Card>{renderOrderDetail()}</Card>
        </div>
      )}
    </div>
  );
  const renderProductDetail = () => (
    <div className="app-track-product-detail-wrapper">
      {products &&
        products?.length > 0 &&
        products?.map((item) => (
          <div className="product-detail-flexbox" key={item.product?._id}>
            <NavLink
              to={`${item.product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(item.product?.name)}/${item.product?._id}`}
              className="pd-w-25">
              <img
                src={generateUrl(item.product?.images ? item.product?.images[0] : '')}
                alt=""
                srcSet=""
                onError={({ currentTarget }) => {
                  const currTrgt = currentTarget;
                  currTrgt.onerror = null; // prevents looping
                  currTrgt.src = PRODUCT_DEFAULTS.productImage;
                }}
                className="product-image"
              />
            </NavLink>
            <div className="pd-w-75">
              <div className="product-detail-text">{item?.product.name}</div>
              <div className="product-detail-sub-text">{item?.product.categoryName}</div>
              <div className="product-detail-sub-text">
                Quantity
                {': '}
                {item?.quantity}
                <span>
                  {(item?.orderDetails?.isCancelled && 'Cancelled') ||
                    (item?.orderDetails?.isReplace && 'Replace requested') ||
                    (item?.orderDetails?.isReturn && 'Return requested')}
                </span>
              </div>
              <div className="box-flex">
                {isDelivered && !checkIsReplace() && !checkIsReturn() && !checkIsCancelled() && (
                  <div>
                    <Button
                      buttonText={MyAccountString.WRITE_PRODUCT_REVIEW}
                      className="app-track-action-btn"
                      onClick={() => confrimReviewProduct(item)}
                    />
                  </div>
                )}
                {isDelivered && (
                  <div>
                    <Button
                      buttonText="RECOMMEND"
                      onClick={() => handleShareBtn(true, item)}
                      className="app-track-action-btn"
                    />
                    {isShare && (
                      <Share
                        show={isShare}
                        qoute={`${activeProduct?.product?.name}: ${String.PRODUCT_SHARE_MESSAGE}`}
                        link={`${RoutesPaths.PRODUCT_DETAIL}/${activeProduct?._id}`}
                        onClose={() => handleShareBtn(false, {})}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
  const renderConfirmCancel = () => (
    <Dialog
      open={openRemoveDialog}
      onClose={handleCloseCancel}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="order-cancel-text-title">Cancellation</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="order-cancel-text-description">
          {showConfirmation ? (
            <div className="app-order-cancel-container">
              <div className="title-section">Are you sure you want to cancel below products ?</div>
              <div className="app-order-cancel-container">
                {cancelProductList &&
                  cancelProductList?.length > 0 &&
                  cancelProductList?.map((item) => (
                    <div className="app-cancel-product-flex" key={item?.product?.name}>
                      <div className="product-detail-layout mt-4">
                        <img
                          className="product-image"
                          src={
                            item?.product?.images && item?.product?.images?.length > 0
                              ? generateUrl(item?.product?.images[0])
                              : ''
                          }
                          alt=""
                          srcSet=""
                          onError={({ currentTarget }) => {
                            const currTrgt = currentTarget;
                            currTrgt.onerror = null; // prevents looping
                            currTrgt.src = PRODUCT_DEFAULTS.productImage;
                          }}
                        />
                        <div>
                          <div className="product-name text-start">{item?.product?.name}</div>
                          <div className="title-section text-start">
                            Quantity:
                            {item?.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="app-order-cancel-container">
              {products &&
                products?.length > 0 &&
                products?.map((item) => (
                  <button
                    onClick={
                      item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched
                        ? null
                        : () => handleCancelItems(item?._id, item)
                    }
                    type="button"
                    className={`app-cancel-product-flex ${
                      item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched
                        ? 'not-allowed'
                        : 'allowed'
                    }`}
                    key={item?.product?.name}>
                    <div className="product-detail-layout ">
                      {item?.orderDetails?.isCancelled ||
                      item?.orderDetails?.isReplace ||
                      item?.orderDetails?.isReturn ||
                      item?.orderDetails?.isDispatched ? (
                        <div className="empty-check" />
                      ) : (
                        <Checkbox
                          checked={
                            cancelProductList &&
                            cancelProductList?.length > 0 &&
                            cancelProductList?.some((val) => val._id === item?._id)
                          }
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      )}
                      <img
                        className="product-image"
                        src={
                          item?.product?.images && item?.product?.images?.length > 0
                            ? generateUrl(item?.product?.images[0])
                            : ''
                        }
                        alt=""
                        srcSet=""
                        onError={({ currentTarget }) => {
                          const currTrgt = currentTarget;
                          currTrgt.onerror = null; // prevents looping
                          currTrgt.src = PRODUCT_DEFAULTS.productImage;
                        }}
                      />
                      <div>
                        <div className="product-name text-start">{item?.product?.name}</div>
                        <div className="title-section text-start">
                          Quantity:
                          {item?.quantity}
                        </div>
                      </div>
                    </div>
                    <div className="product-detail-layout">
                      <div>
                        <div>
                          {(item?.orderDetails?.isCancelled && (
                            <div className="app-delivered-status">Cancelled</div>
                          )) ||
                            (item?.orderDetails?.isReplace && (
                              <div className="app-delivered-status">Replace requested</div>
                            )) ||
                            (item?.orderDetails?.isReturn && (
                              <div className="app-delivered-status">Return requested</div>
                            )) ||
                            (item?.orderDetails?.isDispatched && (
                              <div className="app-delivered-status">Dispatched</div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="app-order-cancel-footer">
        <div className="app-action-btn-layout">
          <Button
            buttonText="Back"
            className="app-action-btn-cancel app-btn-width "
            onClick={showConfirmation ? handleConfirmation : handleCloseCancel}
          />
          <Button
            buttonText={showConfirmation ? 'Yes' : 'Cancel Order'}
            className="app-action-btn-delete app-btn-width "
            loading={loadCancel}
            disabled={showConfirmation ? false : cancelProductList?.length === 0}
            onClick={showConfirmation ? handleOrderCancel : handleConfirmation}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
  return (
    <div>
      <div className="app-track-order-header">
        <Header />
      </div>
      {load ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="app-breadcrumb-wrapper ">{renderBreadCrumb()}</div>
          {renderTrack()}
          <div className="app-track-status-container">
            <div className="row">
              {!checkIsCancelled() ? (
                <div className="col-md-8">
                  <TrackingStatus orderStatus={orderStatus} />
                </div>
              ) : (
                <div className="col-md-8 cancel-order-text-container">
                  <div className="text">Order is Cancelled</div>
                </div>
              )}
              <div className="col-md-4">{renderProductDetail()}</div>
              {renderConfirmCancel()}
              {confirmReturn()}
              {confirmRating()}
              <Snackbar
                open={isOrderedCancel}
                autoHideDuration={50}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}>
                <Alert severity="success" sx={{ width: '20%' }}>
                  <div className="alet-message-success">Item Cancelled Successfully</div>
                </Alert>
              </Snackbar>
              <Snackbar
                open={error}
                autoHideDuration={50}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}>
                <Alert severity="error" sx={{ width: '20%' }}>
                  <div className="alet-message-success">{errorMsg}</div>
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TrackOrder.propTypes = {
  getOrderDetail: PropTypes.func.isRequired,
  resetReturnReducer: PropTypes.func.isRequired,
  resetReviewReducer: PropTypes.func.isRequired,
  callCancelOrderApi: PropTypes.func.isRequired,
  resetCancelOrder: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { orderDetails, returnOrder, reviewDetails } = state;
  return { orderDetails, returnOrder, reviewDetails };
};

const mapDispatchToProps = (dispatch) => ({
  getOrderDetail: (orderId, invoiceId) =>
    dispatch({
      type: OrderDetailTypes.GET_ORDER_DETAIL,
      orderId,
      invoiceId
    }),
  callCancelOrderApi: (productsList, orderId, invoiceId) =>
    dispatch({
      type: CancelOrderTypes.GET_ORDER_CANCELED,
      productsList,
      orderId,
      invoiceId
    }),
  resetCancelOrder: () =>
    dispatch({
      type: CancelOrderTypes.RESET_ORDER_CANCEL
    }),
  resetReturnReducer: () =>
    dispatch({
      type: ReturnOrderTypes.SET_GET_ORDER_RETURN
    }),
  resetReviewReducer: () =>
    dispatch({
      type: ReviewTypes.RESET_SET_REVIEW
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrder);
