import React from 'react';
import PropTypes from 'prop-types';
import { generateUrl } from '../../utils/ImageUtils';
import './Home.scss';

function DetailInfo(icon, title, description, index) {
  return (
    <div className={`detail-info-view dif-bg-${index}`}>
      <div>
        <img src={generateUrl(icon)} alt="" srcSet="" className="image" />
      </div>
      <div className="detail-section">
        <div className="title">{title}</div>
        <div className="detail-text">{description}</div>
      </div>
    </div>
  );
}
function MobileInfoCard(icon, title, description, index) {
  return (
    <div className={`detail-info-view-mb mb-card-bg-${index}`}>
      <div className={`image-wrapper dif-bg-${index}`}>
        <img src={generateUrl(icon)} alt="" srcSet="" className="image" />
      </div>
      <div className="title-section">
        <div className="title">{title}</div>
      </div>
      <div className="detail-section">
        <div className="detail-text">{description}</div>
      </div>
    </div>
  );
}
export default function Benefits(props) {
  const { offersData, loading } = props;
  return (
    <div className="app-benefits-wrapper">
      <div className="container-fluid">
        <div className="wb-view">
          <div className="app-benefits-container-nw">
            {loading ? (
              <div className="col">{loading}</div>
            ) : (
              offersData?.map((offer, index) =>
                DetailInfo(offer.image, offer.name, offer.value, index)
              )
            )}
          </div>
        </div>

        <div className="mb-view">
          <div className="app-benefits-container-nw">
            {offersData?.map((offer, index) =>
              MobileInfoCard(offer.image, offer.name, offer.value, index)
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
Benefits.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  offersData: PropTypes.array,
  loading: PropTypes.bool
};
Benefits.defaultProps = {
  offersData: [],
  loading: false
};
