import Verify from '../../../../constants/verify';

export const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateAddressForm = (oldPassword, newPassword, retypepassword) => {
  let errors = '';
  if (!oldPassword) {
    errors = Verify.VERIFY_PASSWORD_EMPTY;
    return errors;
  }
  if (!newPassword) {
    errors = Verify.VERIFY_NEW_PASSWORD_EMPTY;
    return errors;
  }
  if (!retypepassword) {
    errors = Verify.VERIFY_RETYPE_PASSWORD;
    return errors;
  }
  if (newPassword !== retypepassword) {
    errors = Verify.VERIFY_SAME_PASSWORD;
    return errors;
  }
  if (oldPassword === newPassword) {
    errors = Verify.VERIFY_DIFFERENT_PASSWORD;
    return errors;
  }
  if (oldPassword?.length < 6) {
    errors = Verify.VERIFY_PASSWORD_LIMIT;
    return errors;
  }
  if (newPassword.length < 6) {
    errors = Verify.VERIFY_NEW_PASSWORD_LIMIT;
    return errors;
  }
  if (retypepassword.length < 6) {
    errors = Verify.VERIFY_NEW_PASSWORD_LIMIT;
    return errors;
  }
  return errors;
};
