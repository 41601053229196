import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makePutApiCall } from '../../adapters';
import URLS from '../../constants/urls';
import { PasswordTypes } from '../../reducers/changepassword/changepassword';

function* changePassword(payload) {
  try {
    yield put({ type: PasswordTypes.UPDATE_PASSWORD_INDICATOR });
    const params = {
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
      mobile: payload.mobile
    };
    const response = yield call(makePutApiCall, URLS.API_CHANGE_PASSWORD, params);
    if (response.status === 200) {
      yield put({
        type: PasswordTypes.UPDATE_PASSWORD_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: PasswordTypes.UPDATE_PASSWORD_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: PasswordTypes.UPDATE_PASSWORD_FAILED,
      message: String.LOADINGERROR
    });
  }
}

function* changepasswordSaga() {
  try {
    yield all([takeLatest(PasswordTypes.UPDATE_PASSWORD, changePassword)]);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn('Error while Info Saga: InfoSaga', ex);
  }
}
export default changepasswordSaga;
