/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { RatingTypes } from '../../../reducers/info/rating';
import STORAGE_KEYS from '../../../constants/storageKey';
import Star from '../../../assets/svgs/Star';
import UserRatingCard from '../../../components/multicellular/products/userRatingCard';
import ProductString from '../../../constants/strings/product';
import RoutesPaths from '../../../constants/routes';
import './ProductDetail.scss';

function Rating(props) {
  const { callRatingApi } = props;
  const { rating } = useSelector((state) => state);
  const { ratingsData = {} } = rating;
  const {
    reviews: {
      productId,
      avgRating = 0,
      totalRatings = 0,
      totalReviews = 0,
      comments = [],
      ratings = []
    } = {}
  } = ratingsData;
  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
    callRatingApi(userId, productId);
  }, []);

  const checkRatingProgressBar = (value) => {
    switch (value) {
      case 5: {
        return 'gd-rating-progress-bar';
      }
      case 2: {
        return 'mid-rating-progress-bar';
      }
      case 1: {
        return 'low-rating-progress-bar';
      }
      default: {
        return 'gd-rating-progress-bar';
      }
    }
  };
  const checkRatePercentage = (rate, total) => Number((rate / total) * 100).toFixed(0);
  const ratingTableView = (rateValue, rateCount, totalCount) => (
    <div className="rating-table-container">
      <div className="rating-text">
        <span className="rating-number">{rateValue}</span>
        <span>
          <Star color="#333" />
        </span>
      </div>
      <div className="progress-rating-bar-container ">
        <div className={`progress-bar-container ${checkRatingProgressBar(rateValue)}`}>
          <ProgressBar
            now={checkRatePercentage(rateCount, totalCount)}
            label={`${checkRatePercentage(rateCount, totalCount)}%`}
            visuallyHidden
          />
        </div>
      </div>
      <div className="rating-number-text">{rateCount}</div>
    </div>
  );
  const size = 5;
  const items = comments.slice(0, size);
  const renderComments = () =>
    avgRating > 0 &&
    items.length > 0 &&
    items.map((item, index) => (
      <div key={item}>
        <UserRatingCard
          key={`UserRatingCard-${index.toString()}`}
          username={item?.user?.name}
          reviewId={item._id}
          isUserVerified={item?.user?.isEmailVerified}
          title={item.title}
          comment={item.comments}
          date={item.createdAt}
          rating={item.rating}
          images={item.images}
          likes={item.likes}
          dislikes={item.dislikes}
          isLike={item?.isLiked}
          isDislike={item?.isDisliked}
        />
      </div>
    ));
  return (
    avgRating > 0 && (
      <div
        className="container app-product-carousel-wrapper app-product-rating-container"
        id="reviews">
        <div className="section-title">{ProductString.RATING_REVIEW_SECTION_TITLE}</div>
        <div className="app-product-rating-inner-view">
          <div className="rating-start-view">
            <div className="rating">
              {Number(avgRating).toFixed(1)}
              <span>
                <Star color="#333" />
              </span>
            </div>
            <div className="rating-detail-text">
              {`${totalRatings} Reviews & ${totalReviews} Reviews`}
            </div>
          </div>
          <div className="rating-table-wrapper">
            {[...ratings]
              .reverse()
              .map((item) => ratingTableView(item?.value, item?.count || 0, totalRatings))}
          </div>
        </div>
        <div className="app-rating-detail-comment-container">{renderComments()}</div>
        {totalReviews > 5 && (
          <div>
            <NavLink className="app-link-view-all" to={`${RoutesPaths.REVIEWS}/${productId}`}>
              View all reviews
            </NavLink>
          </div>
        )}
      </div>
    )
  );
}
Rating.propTypes = {
  callRatingApi: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { rating } = state;
  return { rating };
};
const mapDispatchToProps = (dispatch) => ({
  callRatingApi: (productId, userId, size, page) =>
    dispatch({
      type: RatingTypes.FETCH_RATING,
      productId,
      userId,
      size,
      page
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
