import React from 'react';

export default function ScrollTopIcon() {
  return (
    <div>
      <svg
        width="33"
        height="36"
        viewBox="0 0 33 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.9073 27.7974L16.9756 7.35449"
          stroke="#0D6AE8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2748 15.607L16.9756 5.3544L7.60821 15.5447"
          stroke="#0D6AE8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
