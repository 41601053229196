import { combineReducers } from 'redux';
import login from './auth/login/login';
import register from './auth/register/register';
import cart from './cart/cart';
import allCategory from './category/allCategory';
import address from './account/address/address';
import productList from './product/productList';
import otp from './auth/otp/otp';
import loginWithMobile from './auth/login/loginWithMobile';
import forgetPassword from './auth/login/forgetPassword';
import resendOtp from './auth/login/resendOtp';
import filter from './filter/filter';
import makePayment from './payment/makePayment';
import paymentGateway from './payment/paymentGateway';
import information from './info/information';
import delivery from './info/delivery';
import changepassword from './changepassword/changepassword';
import similarproduct from './info/similarproduct';
import recommended from './info/recommended';
import frequentlyBoughtTogether from './info/frequentlyBoughtTogether';
import paymentMethod from './payment/paymentMethod';
import order from './order/order';
import orderDetails from './order/orderDetails';
import cancelOrder from './order/cancelOrder';
import returnOrder from './order/returnOrder';
import home from './home/home';
import wishlist from './wishlist/wishlist';
import reviewDetails from './order/reviewDetails';
import fetchFaq from './faq/faq';
import suggestion from './product/suggestion';
import editUser from './auth/editUser/editUser';
import testimonial from './info/testimonial';
import coupon from './payment/coupon';
import rating from './info/rating';
import vote from './vote/vote';
import contactUs from './contactUs/contactUs';
import bulkOrder from './cart/bulkOrder';
import paymentStatus from './payment/paymentStatus';
import productBulkList from './product/productBulkList';

const appReducer = combineReducers({
  login,
  register,
  cart,
  allCategory,
  address,
  productList,
  otp,
  loginWithMobile,
  forgetPassword,
  resendOtp,
  filter,
  makePayment,
  paymentGateway,
  information,
  delivery,
  changepassword,
  similarproduct,
  recommended,
  frequentlyBoughtTogether,
  paymentMethod,
  order,
  orderDetails,
  cancelOrder,
  returnOrder,
  home,
  wishlist,
  fetchFaq,
  suggestion,
  editUser,
  testimonial,
  coupon,
  reviewDetails,
  rating,
  vote,
  contactUs,
  bulkOrder,
  paymentStatus,
  productBulkList
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
