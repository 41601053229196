/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { generateUrl } from '../../../../utils/ImageUtils';
import PRODUCT_DEFAULTS from '../../../../constants/defaults/product';
import discountToPercentage from '../../../../utils/conversions/discountToPercentage';
// import ProductString from '../../../../constants/strings/product';
// import gstAmount from '../../../../utils/conversions/gstAmount';
import Button from '../../../unicellular/button';
import CartString from '../../../../constants/strings/cart';
import RoutesPaths from '../../../../constants/routes';
import './Style.scss';
import createSlug from '../../../../utils/conversions/createSlug';

export default function CartCard(props) {
  const { cart, removeFromList, changeFromList, loading, isSaveforLater } = props;
  const { product = {} } = cart;
  const { calculatedPrices: { basePrice, netPrice, savedAmount } = {} } = cart;
  return loading ? (
    <div className="app-cc-container ">
      <div className="loading-view">
        <div className="image-ld loading-animation" />
        <div className="title-ld loading-animation" />
        <div className="detail-ld loading-animation" />
      </div>
    </div>
  ) : (
    <div className="app-cc-container">
      <NavLink
        to={`${product?.isBook ? RoutesPaths.BOOKS : RoutesPaths.PRODUCT_DETAIL}/${createSlug(product.name)}/${product._id}`}
        className="image-wrapper">
        <div className="image-wrapper">
          <img
            className="image"
            src={generateUrl(product.images ? product.images[0] : '')}
            alt={product?.name}
            srcSet=""
            onError={({ currentTarget }) => {
              const currTrgt = currentTarget;
              currTrgt.onerror = null; // prevents looping
              currTrgt.src = PRODUCT_DEFAULTS.productImage;
            }}
          />
        </div>
      </NavLink>
      <div className="inner-body">
        <div className="kc-btn">
          <div className="title">{product?.name}</div>
          <div className="detail">
            {Array.isArray(product?.description)
              ? product?.description.slice(0, 3).map((d) => (
                  <div key={d}>
                    {d}
                    <br />
                  </div>
                ))
              : product?.description}
          </div>
        </div>
        <div className="btn-price-container">
          <div className="price">
            {`₹ ${netPrice}`}
            {basePrice !== netPrice && <span className="base-price">{`₹ ${basePrice}`}</span>}
            {savedAmount !== 0 && (
              <span className="discount">
                {`(${discountToPercentage(savedAmount, basePrice)}% off)`}
              </span>
            )}
          </div>
        </div>

        <div className="btn-wrap-container mt-4">
          <div className="inner-wrap">
            <Button
              buttonText={CartString.MOVE_TO_CART}
              className="app-btn-add-to-cart"
              onClick={() => changeFromList(cart, isSaveforLater)}
            />
          </div>
          <div className="inner-wrap">
            <Button
              buttonText={CartString.REMOVE_FROM_CART}
              className="app-btn-buy-now"
              onClick={() => removeFromList(cart, isSaveforLater)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
CartCard.propTypes = {
  cart: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  removeFromList: PropTypes.func,
  changeFromList: PropTypes.func,
  loading: PropTypes.bool,
  isSaveforLater: PropTypes.bool
};
CartCard.defaultProps = {
  removeFromList: () => {},
  changeFromList: () => {},
  cart: {},
  loading: false,
  isSaveforLater: false
};
