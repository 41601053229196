const MyAccountString = {
  ADDRESS_MODAL_HEADER_TEXT: 'ADD NEW ADDRESS',
  ADDRESS_MODAL_HEADER_TEXT_EDIT: 'EDIT ADDRESS',
  REFUND_DETAILS: 'Refund Details',
  REFUND_STATUS: 'Refund Status',
  REFUND_AMOUNT: 'Refund Amount',
  SAVE_ADDRESS_SECTION_TITLE: 'Save address as',
  CHANGE_PASSWORD: 'CHANGE PASSWORD',
  CHANGE_PASSWORD_SECTION_TITLE: 'Your new password must: ',
  CHANGE_PASSWORD_GUIDE: [
    'Be at least 6 characters in length',
    'Not be same as your current password'
  ],
  CHANGE_PASSWORD_SMALL_TEXT: 'Change Password ',
  MY_ACCOUNT: 'My account',
  ORDER: 'Order',
  TRACK_ORDER: 'Track Order - ',
  TRACK_ID: 'Tracking ID: ',
  DELIVERY_ADDRESS_TITLE: 'Delivery Address',
  PHONE_TITLE: 'Phone number:',
  DOWNLOAD_INVOICE: 'Download invoice',
  RETURN_AND_REFUND_TITLE: 'Return or replace items',
  WRITE_PRODUCT_REVIEW: 'Review',
  RECOMMENDED_TO_FRIEND: 'Recommend to a friend',
  TRACK_DETAIL_TITLE: 'Tracking Detail',
  TRACK_DETAIL_DESCRIPTION: 'Product will be delivered to you by our delivery partner',
  DELIVERY: 'Delhivery',
  CUSTOMER_CARE_SECTION_TITLE: 'Customer care',
  PAYMENT_DETAILS: 'Payment Details',
  PAYMENT_METHOD: 'Payment Method',
  PAYMENT_PAYABLE: (isCod) => `${isCod ? 'Amount Payable:' : 'Amount Paid:'}`,
  PAYMENT_OPTION_COD: 'Cash, Card, UPI',
  PAY_ON_DELIVERY: 'Pay On Delivery'
};
export default MyAccountString;
