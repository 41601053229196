import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import './Share.scss';
import Close from '../../../../assets/svgs/Close';

export default function Share(props) {
  const { show, onClose, link, qoute } = props;
  const baseurl = window.location.origin;
  const redirectUrl = baseurl + link;
  return (
    <Modal size="md" show={show} onHide={onClose} centered contentClassName="share-modal">
      <Modal.Body className="share-modal-body">
        <div className="share-modal-head">
          <div className="head-title ">Share</div>
          <button type="button" onClick={onClose}>
            <div>
              {' '}
              <Close />
            </div>
          </button>
        </div>
        <div className="share-body ">
          <FacebookShareButton url={redirectUrl} quote={qoute}>
            <FacebookIcon size={42} round className="share-webinar-icon" />
          </FacebookShareButton>
          <TwitterShareButton url={redirectUrl} title={qoute}>
            <TwitterIcon size={42} round className="share-webinar-icon" />
          </TwitterShareButton>
          <WhatsappShareButton url={redirectUrl} title={qoute}>
            <WhatsappIcon size={42} round className="share-webinar-icon" />
          </WhatsappShareButton>
          <LinkedinShareButton url={redirectUrl} summary={qoute}>
            <LinkedinIcon size={42} round className="share-webinar-icon" />
          </LinkedinShareButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}
Share.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  qoute: PropTypes.string.isRequired
};
