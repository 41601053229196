import React from 'react';
import './Loader.scss';
import '../ProductDetail.scss';

const n = 5;
const Banner = 2;
const webinar = 4;
function ProductDetailLoader() {
  return (
    <div>
      <div>
        <div className="container-fluid align-container" />
      </div>
      {/* transform section */}
      <div className="app-home-banner  ">
        <div className="app-pd-top-space" />
        <div>
          <div className="container align-container">
            <div className="row top-space-row">
              <div className="col-md-6">
                <div className="home-lab-banner-image banner-home-skeleton-bg" />
                <div className="col">
                  <div className="row">
                    {[...Array(webinar)].map((item) => (
                      <div className="col" key={item}>
                        <div className="top-course-card skel-webinar-card">
                          <p className="image" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6 skel-right-align-center ">
                <div />
                <div className="row home-skeleton-box">
                  {[...Array(n)].map((item) => (
                    <span className="home-skeleton-loader-description " key={item} />
                  ))}
                  <div className="home-skel-register-button" />
                </div>
                <div className="app-top-space" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Specification */}

      <div className="app-banner-container skel-home-banner">
        <div>
          <div className="container-fluid align-container" />
        </div>
      </div>
      {/* transform section */}
      <div className="app-home-banner  ">
        <div className="app-top-space" />
        <div>
          <div className="container align-container">
            <div className="row top-space-row">
              <div className="col-md-6">
                <div className="col">
                  <div className="row">
                    {[...Array(Banner)].map((item) => (
                      <div className="col" key={item}>
                        <div className="top-course-card skel-webinar-card">
                          <p className="specfication-skeleton-bg" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6 skel-right-align-center ">
                <div />
                <div className="row home-skeleton-box">
                  {[...Array(Banner)].map((item) => (
                    <div className="col" key={item}>
                      <div className="top-course-card skel-webinar-card">
                        <p className="specfication-skeleton-bg" />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="app-top-space" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-space" />
      <div className="app-top-space" />
      <div className="app-path-container">
        <div className="container" />
      </div>
      <div className="app-top-space" />
      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item) => (
                <span className="" key={item} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item) => (
                <div className="col" key={item}>
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((value) => (
                        <p className="description" key={value} />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>
      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item) => (
                <span key={item} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item) => (
                <div className="col" key={item}>
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((value) => (
                        <p className="description" key={value} />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>

      {/* webinar */}
      <div className="app-top-space" />
      <div className="container">
        <div className="col">
          <div className="col">
            <div className="home-skeleton-box">
              <div className="home-skel-register-button" />
              {[...Array(n)].map((item) => (
                <span key={item} />
              ))}
            </div>
          </div>
          <div className="col">
            <div className="row">
              {[...Array(webinar)].map((item) => (
                <div className="col" key={item}>
                  <div className="top-course-card skel-webinar-card">
                    <p className="icon" />
                    <div>
                      <p className="title" />
                      {[...Array(n)].map((value) => (
                        <p className="description" key={value} />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="app-top-space" />
      </div>
    </div>
  );
}

export default ProductDetailLoader;
