import React from 'react';

import './Style.scss';

function Loader() {
  const infoBox = 2;
  const containerNumber = 3;

  return (
    <div>
      <div className="container-fluid align-container cu-skeleton-hg ">
        {[...Array(containerNumber)].map((index) => (
          <div key={`loading-${index?.toString()}`} className="cu-skel-inner-wrapper">
            <div className="cu-skeleton-loader-title" />
            {[...Array(infoBox)].map((ind) => (
              <div key={`loading-0-${ind?.toString()}`}>
                <div className="cu-skeleton-loader-description" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loader;
