import React from 'react';
import Details from './Details';

export default function ProductReview() {
  return (
    <div className="container">
      <div className="product-review-head" />
      <Details />
    </div>
  );
}
