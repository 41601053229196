import React from 'react';
import { NavLink } from 'react-router-dom';
import Routes from '../../../../constants/routes';
import Button from '../../../unicellular/button';
import './EmptyCart.scss';

function EmptyCart() {
  return (
    <div className="empty-cart-container">
      <div className="empty-cart-image" />
      <div>
        <div className="empty-cart-heading">
          Cart is empty, move items from saved from later or continue shopping
        </div>
        <NavLink to={Routes.HOME}>
          <div className="emapty-cart-action">
            <Button className="app-btn app-gradient-btn" buttonText="Continue Shopping" />
          </div>
        </NavLink>
      </div>
    </div>
  );
}
export default EmptyCart;
