import React from 'react';
import PropTypes from 'prop-types';

export default function Wishlist(props) {
  const { color } = props;
  return (
    <div>
      <svg
        width="25"
        height="22"
        viewBox="0 0 25 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.747 21.7307C12.1822 21.7312 11.6291 21.5554 11.1536 21.2243C9.80464 20.2825 7.2103 18.3497 5.0158 15.9897C2.32273 13.0911 0.957031 10.3612 0.957031 7.87404C0.957031 6.14577 1.42755 4.56226 2.31787 3.29521C3.16214 2.09044 4.35477 1.22886 5.70416 0.848888C7.05354 0.468913 8.48136 0.5926 9.75823 1.20008C10.9011 1.73573 11.9204 2.63884 12.747 3.83731C13.5737 2.63591 14.5929 1.7328 15.7358 1.20008C17.0127 0.5926 18.4405 0.468913 19.7899 0.848888C21.1392 1.22886 22.3319 2.09044 23.1761 3.29521C24.0665 4.56226 24.537 6.14577 24.537 7.87404C24.537 10.3612 23.1713 13.0899 20.4782 15.9873C18.2837 18.3474 15.6905 20.2802 14.3404 21.222C13.8652 21.5539 13.3121 21.7305 12.747 21.7307ZM7.25185 2.10143C6.82507 2.10101 6.40012 2.1621 5.98814 2.2831C4.95409 2.59144 4.04152 3.25921 3.39003 4.18426C2.68101 5.19344 2.306 6.46986 2.306 7.87639C2.306 9.95804 3.5368 12.3374 5.96494 14.9489C8.06933 17.2145 10.5768 19.0805 11.8826 19.9889C12.1404 20.1678 12.44 20.2629 12.7459 20.2629C13.0519 20.2629 13.3514 20.1678 13.6093 19.9889C14.9151 19.0782 17.4204 17.2122 19.5269 14.9489C21.9551 12.3374 23.1859 9.95804 23.1859 7.87639C23.1859 6.46986 22.8108 5.19578 22.1018 4.18426C21.4503 3.25921 20.5378 2.59144 19.5037 2.2831C17.6599 1.73925 15.0694 2.30772 13.3265 5.53452C13.2671 5.64479 13.1822 5.73625 13.0801 5.79981C12.9781 5.86337 12.8625 5.89681 12.7448 5.89681C12.6272 5.89681 12.5116 5.86337 12.4096 5.79981C12.3075 5.73625 12.2226 5.64479 12.1632 5.53452C10.7937 2.99457 8.89651 2.10143 7.25185 2.10143Z"
          fill="#FE0909"
        />
        <path
          d="M12.747 21.7307C12.1822 21.7312 11.6291 21.5554 11.1536 21.2243C9.80464 20.2825 7.2103 18.3497 5.0158 15.9897C2.32273 13.0911 0.957031 10.3612 0.957031 7.87404C0.957031 6.14577 1.42755 4.56226 2.31787 3.29521C3.16214 2.09044 4.35477 1.22886 5.70416 0.848888C7.05354 0.468913 8.48136 0.5926 9.75823 1.20008C10.9011 1.73573 11.9204 2.63884 12.747 3.83731C13.5737 2.63591 14.5929 1.7328 15.7358 1.20008C17.0127 0.5926 18.4405 0.468913 19.7899 0.848888C21.1392 1.22886 22.3319 2.09044 23.1761 3.29521C24.0665 4.56226 24.537 6.14577 24.537 7.87404C24.537 10.3612 23.1713 13.0899 20.4782 15.9873C18.2837 18.3474 15.6905 20.2802 14.3404 21.222C13.8652 21.5539 13.3121 21.7305 12.747 21.7307ZM7.25185 2.10143C6.82507 2.10101 6.40012 2.1621 5.98814 2.2831C4.95409 2.59144 4.04152 3.25921 3.39003 4.18426C2.68101 5.19344 2.306 6.46986 2.306 7.87639C2.306 9.95804 3.5368 12.3374 5.96494 14.9489C8.06933 17.2145 10.5768 19.0805 11.8826 19.9889C12.1404 20.1678 12.44 20.2629 12.7459 20.2629C13.0519 20.2629 13.3514 20.1678 13.6093 19.9889C14.9151 19.0782 17.4204 17.2122 19.5269 14.9489C21.9551 12.3374 23.1859 9.95804 23.1859 7.87639C23.1859 6.46986 22.8108 5.19578 22.1018 4.18426C21.4503 3.25921 20.5378 2.59144 19.5037 2.2831C17.6599 1.73925 15.0694 2.30772 13.3265 5.53452C13.2671 5.64479 13.1822 5.73625 13.0801 5.79981C12.9781 5.86337 12.8625 5.89681 12.7448 5.89681C12.6272 5.89681 12.5116 5.86337 12.4096 5.79981C12.3075 5.73625 12.2226 5.64479 12.1632 5.53452C10.7937 2.99457 8.89651 2.10143 7.25185 2.10143Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
Wishlist.propTypes = {
  color: PropTypes.string
};
Wishlist.defaultProps = {
  color: '#435FFD'
};
