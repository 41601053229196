import { call, put, all, takeLatest } from 'redux-saga/effects';
import { makeGetApiCall } from '../../adapters';
import { ContactUsTypes } from '../../reducers/contactUs/contactUs';
import String from '../../constants/strings';
import URLS from '../../constants/urls';

function* getContactUsDetail() {
  try {
    yield put({ type: ContactUsTypes.GET_CONTACT_US_INDICATOR });
    const params = {};

    const response = yield call(makeGetApiCall, URLS.API_ALL_CONTACT_US, params);
    if (response.status === 200) {
      yield put({
        type: ContactUsTypes.GET_CONTACT_US_SUCCESS,
        response: response.data
      });
    } else {
      yield put({
        type: ContactUsTypes.GET_CONTACT_US_FAILED,
        message: response.data.message || 'Something went wrong. Please try again later.'
      });
    }
  } catch (e) {
    yield put({
      type: ContactUsTypes.GET_CONTACT_US_FAILED,
      message: String.LOADINGERROR
    });
  }
}
function* ContactUsSaga() {
  try {
    yield all([takeLatest(ContactUsTypes.GET_CONTACT_US, getContactUsDetail)]);
  } catch (ex) {
    console.warn('Error while Address Saga: CartSaga', ex);
  }
}

export default ContactUsSaga;
