import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FilterTypes } from '../../../reducers/filter/filter';
import options from '../../../config/product/sort';
import SelectDropdown from '../../../components/unicellular/selectDropdown';
import './ProductExplorer.scss';

const convertDropdownOptions = (array) =>
  array.reduce((arr, item) => {
    arr.push({
      label: item.title,
      key: item.key,
      originalValue: item.value,
      value: item.title && item.title !== null ? item.title : ''
    });
    return arr;
  }, []);

function SortBy(props) {
  const [value, setValue] = useState(convertDropdownOptions([options[0]]));
  const { setSortMap, children } = props;
  const onChangeOption = (e) => {
    setValue(e);
    setSortMap({ [e.key]: e.originalValue });
  };

  return (
    <div className="app-product-sort-by">
      {children}
      <div className="app-product-dropdown-width">
        <SelectDropdown
          placeholder=" Select option"
          onChange={onChangeOption}
          options={convertDropdownOptions(options)}
          value={value !== '' && value}
          isStyled
          isSortView
          closeMenuOnSelect
        />
      </div>
    </div>
  );
}

SortBy.propTypes = {
  setSortMap: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

const mapStateToProps = (state) => {
  const { filter } = state;
  return { filter };
};

const mapDispatchToProps = (dispatch) => ({
  setSortMap: (sortMap) => dispatch({ type: FilterTypes.SET_SORT_MAP_FILTER, sortMap })
});
export default connect(mapStateToProps, mapDispatchToProps)(SortBy);
